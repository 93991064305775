import React from 'react';
import ReactDOM from 'react-dom';
import './Assets/css/index.css';
import App from './App';
import { AppProvider } from './Store/AppContext';
import { ReactBootstrapAlert } from "rct-bs-alert";
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
// import { msalConfig } from "./Components/Screens/Auth/authConfig";
// import { PublicClientApplication, EventType } from '@azure/msal-browser';

// const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
// if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
//   // Account selection logic is app dependent. Adjust as needed for different use cases.
//   msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
// }

// Listen for sign-in event and set active account
// msalInstance.addEventCallback((event) => {
//   if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
//     const account = event.payload.account;
//     msalInstance.setActiveAccount(account);
//   }
// });


ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ReactBootstrapAlert>
        <AppProvider>
          <App />
        </AppProvider>
      </ReactBootstrapAlert>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

