import { useState, useEffect, useContext } from 'react';
import SkeletonLoader from "../../Common/SkeletonLoader/SkeletonLoader";
import { BtnPrimary } from '../../Common/Buttons/Buttons';
import { useNavigate } from "@reach/router";
import RestService from '../../../Services/api.service';
import AppContext from '../../../Store/AppContext';

const Project = () => {
    const [categorieList, setCategorieList] = useState([]);
    const { spinner } = useContext(AppContext);
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();

    //categories list
    const getAllProjectCategories = () => {
        try {
            RestService.getAllProjectCategories().then(
                response => {
                    if (response.status === 200) {
                        setCategorieList(response.data);
                        setTimeout(() => {
                            setLoader(false);
                        }, 2000);
                    }
                },
                err => {
                    // spinner.hide();
                    console.error(err);
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getAllProjectCategories()", err)
        }
    }

    useEffect(() => {
        getAllProjectCategories();
    }, []);

    return (
        <div className='table-shadow p-3'>
            <div className="catalog-container">
                {categorieList.map((item) => (
                    <div key={item.categoryName} className="catalogBox">
                        <div className="catalogBox-info">
                            {
                                loader ?
                                    <SkeletonLoader skeletonCount={1} />
                                    :
                                    <div>
                                        <img src={item.thumbnail} width="100%" height="230px" alt="Lab" style={{ borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }} />
                                    </div>
                            }
                            <div className="catalog-content">
                                {
                                    loader ?
                                        <SkeletonLoader skeletonCount={1} />
                                        :
                                        <div className="cat-title-md">
                                            {item.categoryName}
                                        </div>
                                }
                                <div className="text-right">
                                    {
                                        loader ?
                                            <SkeletonLoader skeletonCount={1} />
                                            :
                                            <button
                                                onClick={() => {
                                                    navigate('project/projectdetails', { state: { categoryName: item.categoryName } });
                                                }}
                                                className="labbutton"
                                            >
                                                Show All Project
                                            </button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};



export default Project