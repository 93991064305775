import { useState, useEffect, useContext } from "react";
import './../Batches/batches.css'
import DynamicTable from "../../Common/DynamicTable/DynamicTable";
import { Modal, Form } from 'react-bootstrap'
import { Formik } from 'formik';
import { ICN_TRASH, ICN_EDIT, ICN_CLONE } from "../../Common/Icon";
import { Button } from "../../Common/Buttons/Buttons";
import { TextInput, DateInput, SelectInput, TextArea } from "../../Common/InputField/InputField";
import { Link, Router } from "../../Common/Router";
import { BsModal, Toggle } from "../../Common/BsUtils";
import CardHeader from "../../Common/CardHeader";
import CourseDetails from "./CourseDetails";
import RestService from "../../../Services/api.service";
import useFetch from "../../../Store/useFetch";
import GLOBELCONSTANT from "../../../Constant/GlobleConstant";
import useToast from "../../../Store/ToastHook";
import moment from 'moment'
import AppContext from "../../../Store/AppContext";
import { getAllCourse } from "../../../Services/service";
import * as Yup from 'yup';
import { WithContext as ReactTags } from "react-tag-input";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill';
import ClassNotes from "../ClassLab/ClassNotes/ClassNotes";
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from "react-tooltip";
const Courses = ({ location }) => {
    const { user, spinner, setCourse, ROLE } = useContext(AppContext)
    const Toast = useToast();
    const [show, setShow] = useState(false);
    const [courseList, setCourseList] = useState([])
    const [count, setCount] = useState(0);
    const [categorieList, setCategorieList] = useState({});
    const [subcategorieList, setSubCategorieList] = useState(null);
    const [courseTag, setCourseTag] = useState(null);
    const [categorySid, setCategorySid] = useState('');
    const [subCategorySid, setSubCategorySid] = useState('');
    const [createCategory, setCreateCategory] = useState('');
    const [createSubCategory, setCreateSubCategory] = useState('');
    const [newCategorySid, setNewCategorySid] = useState('');
    // const [tagsSid, setTagsSid] = useState([]);

    const [courseType, setCourseType] = useState('');

    const [fieldValueDescription, setFieldValueDescription] = useState('');
    const [fieldValueRequirements, setFieldValueRequirements] = useState('');
    const [fieldValueLearn, setfieldValueLearn] = useState('');

    const [initialValues, setInitialValues] = useState({
        name: '',
        description: '',
        previewVideo: '',
        courseDurationInHours: 11,
        coursePrice: 10000,
        internationalCoursePrice: 500
    })
    const [isEdit, setIsEdit] = useState(false);
    const [tags, setTags] = useState([]);
    const [tagWithName, setTagWithName] = useState([]);
    const [languages, setLanguages] = useState([]);

    const [isTagCountValid, setIsTagCountValid] = useState(false);

    const [model, setModel] = useState("Example Set");
    const [showCreateCategory, setShowCreateCategory] = useState(false);

    const handleModelChange = (event) => {
        setModel(event)
    }

    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    // const handleChangeAudience = (e) => {
    //     setTargetAudience(e.target.value);
    // }


    //validation
    const schemaBusiness = Yup.object().shape({
        name: Yup.string().required('Required!'),
    });
    const schemaConsumer = Yup.object().shape({
        name: Yup.string().required('Required!'),
        coursePrice: Yup.number().required('Required!')
            .moreThan(0, 'Must be greater than 0')
            .integer('Must be an integer')
            .typeError('Must be a number'),
        internationalCoursePrice: Yup.number().required('Required!')
            .moreThan(0, 'Must be greater than 0')
            .integer('Must be an integer')
            .typeError('Must be a number'),
        courseDurationInHours: Yup.number().required('Required!')
            .moreThan(0, 'Must be greater than 0')
            .integer('Must be an integer')
            .typeError('Must be a number'),
        previewVideo: Yup.string(),
        codingWeightage: Yup
            .mixed()
            .required('Required!')
            .test(
                'is-valid-range',
                'The value must be a positive integer between 0 and 100, inclusive, without decimal places.',
                value => {
                    // First, ensure the input is a number or a string that represents a number
                    if (typeof value !== 'number' && typeof value !== 'string') {
                        return false;
                    }
                    // If it's a string, ensure that it represents a valid number (not NaN) and doesn't contain a decimal point
                    if (typeof value === 'string' && (value.includes('.') || isNaN(value))) {
                        return false;
                    }
                    // Parse the value to a number for further checks
                    const numberValue = Number(value);
                    // Check if it's an integer
                    if (!Number.isInteger(numberValue)) {
                        return false;
                    }
                    // Check if it's between 0 and 100, inclusive
                    if (numberValue < 0 || numberValue > 100) {
                        return false;
                    }
                    // If all checks pass, return true
                    return true;
                }
            ),
        documentWeightage: Yup
            .mixed()
            .required('Required!')
            .test(
                'is-valid-range',
                'The value must be a positive integer between 0 and 100, inclusive, without decimal places.',
                value => {
                    // First, ensure the input is a number or a string that represents a number
                    if (typeof value !== 'number' && typeof value !== 'string') {
                        return false;
                    }
                    // If it's a string, ensure that it represents a valid number (not NaN) and doesn't contain a decimal point
                    if (typeof value === 'string' && (value.includes('.') || isNaN(value))) {
                        return false;
                    }
                    // Parse the value to a number for further checks
                    const numberValue = Number(value);
                    // Check if it's an integer
                    if (!Number.isInteger(numberValue)) {
                        return false;
                    }
                    // Check if it's between 0 and 100, inclusive
                    if (numberValue < 0 || numberValue > 100) {
                        return false;
                    }
                    // If all checks pass, return true
                    return true;
                }
            ),
        labWeightage: Yup
            .mixed()
            .required('Required!')
            .test(
                'is-valid-range',
                'The value must be a positive integer between 0 and 100, inclusive, without decimal places.',
                value => {
                    // First, ensure the input is a number or a string that represents a number
                    if (typeof value !== 'number' && typeof value !== 'string') {
                        return false;
                    }
                    // If it's a string, ensure that it represents a valid number (not NaN) and doesn't contain a decimal point
                    if (typeof value === 'string' && (value.includes('.') || isNaN(value))) {
                        return false;
                    }
                    // Parse the value to a number for further checks
                    const numberValue = Number(value);
                    // Check if it's an integer
                    if (!Number.isInteger(numberValue)) {
                        return false;
                    }
                    // Check if it's between 0 and 100, inclusive
                    if (numberValue < 0 || numberValue > 100) {
                        return false;
                    }
                    // If all checks pass, return true
                    return true;
                }
            ),
        mcqWeightage: Yup
            .mixed()
            .required('Required!')
            .test(
                'is-valid-range',
                'The value must be a positive integer between 0 and 100, inclusive, without decimal places.',
                value => {
                    // First, ensure the input is a number or a string that represents a number
                    if (typeof value !== 'number' && typeof value !== 'string') {
                        return false;
                    }
                    // If it's a string, ensure that it represents a valid number (not NaN) and doesn't contain a decimal point
                    if (typeof value === 'string' && (value.includes('.') || isNaN(value))) {
                        return false;
                    }
                    // Parse the value to a number for further checks
                    const numberValue = Number(value);
                    // Check if it's an integer
                    if (!Number.isInteger(numberValue)) {
                        return false;
                    }
                    // Check if it's between 0 and 100, inclusive
                    if (numberValue < 0 || numberValue > 100) {
                        return false;
                    }
                    // If all checks pass, return true
                    return true;
                }
            ),
        meetingWeightage: Yup
            .mixed()
            .required('Required!')
            .test(
                'is-valid-range',
                'The value must be a positive integer between 0 and 100, inclusive, without decimal places.',
                value => {
                    // First, ensure the input is a number or a string that represents a number
                    if (typeof value !== 'number' && typeof value !== 'string') {
                        return false;
                    }
                    // If it's a string, ensure that it represents a valid number (not NaN) and doesn't contain a decimal point
                    if (typeof value === 'string' && (value.includes('.') || isNaN(value))) {
                        return false;
                    }
                    // Parse the value to a number for further checks
                    const numberValue = Number(value);
                    // Check if it's an integer
                    if (!Number.isInteger(numberValue)) {
                        return false;
                    }
                    // Check if it's between 0 and 100, inclusive
                    if (numberValue < 0 || numberValue > 100) {
                        return false;
                    }
                    // If all checks pass, return true
                    return true;
                }
            ),
        videoWeightage: Yup
            .mixed()
            .required('Required!')
            .test(
                'is-valid-range',
                'The value must be a positive integer between 0 and 100, inclusive, without decimal places.',
                value => {
                    // First, ensure the input is a number or a string that represents a number
                    if (typeof value !== 'number' && typeof value !== 'string') {
                        return false;
                    }
                    // If it's a string, ensure that it represents a valid number (not NaN) and doesn't contain a decimal point
                    if (typeof value === 'string' && (value.includes('.') || isNaN(value))) {
                        return false;
                    }
                    // Parse the value to a number for further checks
                    const numberValue = Number(value);
                    // Check if it's an integer
                    if (!Number.isInteger(numberValue)) {
                        return false;
                    }
                    // Check if it's between 0 and 100, inclusive
                    if (numberValue < 0 || numberValue > 100) {
                        return false;
                    }
                    // If all checks pass, return true
                    return true;
                }
            ),
    })
        .test('total-weightage-sum', 'The total weightage must not exceed 100', function (values) {
            const { codingWeightage, documentWeightage, labWeightage, mcqWeightage, meetingWeightage, videoWeightage } = values;

            // Convert all values to numbers, treating non-numeric and empty values as 0
            const totalWeightage = [codingWeightage, documentWeightage, labWeightage, mcqWeightage, meetingWeightage, videoWeightage]
                .reduce((sum, value) => sum + (Number(value) || 0), 0);

            // Check if the total weightage exceeds 100
            return totalWeightage <= 100 || new Yup.ValidationError('The total weightage must not exceed 100', values, 'totalWeightage');
        });




    // const handleDelete = (index) => {
    //     const newTags = [...tags];
    //     const newTagWithName = [...tagWithName];
    //     newTags.splice(index, 1);
    //     setTags(newTags);

    //     newTagWithName.splice(index, 1);
    //     setTagWithName(newTagWithName);
    // };

    const handleDropdownSelect = (selectedValue) => {

        const value = selectedValue.split(',')[0];
        const name = selectedValue.split(',')[1];
        if (
            value &&
            !tags.some((t) => t.toLowerCase() === value.toLowerCase())
        ) {
            // Check for uniqueness before adding
            setTags([...tags, value]);
            setTagWithName([...tagWithName, name]);
        }
    };

    // const handleAddition = tag => {
    //     setTags([...tags, tag]);
    // };

    const handleDelete1 = i => {
        setLanguages(languages.filter((tag, index) => index !== i));
    };

    const handleAddition1 = tag => {
        setLanguages([...languages, tag]);
    };

    const handleDelete = (i) => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = (tag) => {
        if (tags.length < 3) {
            setTags([...tags, tag]);
        }
    };

    const [configuration, setConfiguration] = useState({
        columns: {

            "name": {
                "title": "Course Name",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
                render: (data) => <Link to={'course-details'} state={{ title: "Course", subTitle: data.name, path: "course", rowData: data, sid: data.sid }} className="dt-name">{data.name}</Link>

            },
            // "description": {
            //     "title": "Description",
            //     "sortDirection": null,
            //     "sortEnabled": true,
            //     isSearchEnabled: false,

            // },
            "noOfTrainings": {
                "title": "No. of Trainings",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false
            }
            ,
            "createdOn": {
                "title": "Creation Date",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
                render: (data) => moment(data.createdOn).format('DD/MM/YYYY')
            }
        },
        headerTextColor: '#454E50', // user can change table header text color
        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configuration.sortBy = sortKey;
            Object.keys(configuration.columns).map(key => configuration.columns[key].sortDirection = (key === sortKey) ? !configuration.columns[key].sortDirection : false);
            configuration.sortDirection = configuration.columns[sortKey].sortDirection;
            setConfiguration({ ...configuration });
        },
        actions: [
            {
                "title": "Clone",
                "icon": ICN_CLONE,
                "onClick": (data) => cloneCourseAndContents(data.sid)
            },
            {
                "title": "Edit",
                "icon": ICN_EDIT,
                "onClick": (data, i) => { setIsEdit(true); setShow(true); setInitialValues({ name: data.name, description: data.description, sid: data.sid }) }
            },
            {
                "title": "Delete",
                "icon": ICN_TRASH,
                "onClick": (data) => deleteCourse(data.sid)
            }

        ],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table sort-enabled", // table custom class
        showCheckbox: false,
        clearSelection: false
    });

    // get all course list
    const createCourse = (data) => {
        let tagsString, languagesString;
        const nationalCoursePrice = Number(data.coursePrice);
        const internationalCoursePrice = Number(data.internationalCoursePrice);
        const modulatedCourseDetails = {
            "codingWeightage": data.codingWeightage,
            "documentWeightage": data.documentWeightage,
            "labWeightage": data.labWeightage,
            "mcqWeightage": data.mcqWeightage,
            "meetingWeightage": data.meetingWeightage,
            "videoWeightage": data.videoWeightage
        };
        try {
            let payload = {
                // "description": data.description,
                "name": (data.name).trim(),
                "status": "ENABLED"
                // "targetConsumer": targetAudience
            }
            if (user.role === ROLE.CONSUMER_SUPERVISOR) {
                payload.targetConsumer = 'CONSUMER'
            }
            else {
                payload.targetConsumer = 'BUSINESS'
            }
            if (user.role === ROLE.CONSUMER_SUPERVISOR) {
                if (tags.length > 0) {
                    let tagsCsv = tags.map((item) => item.text);
                    tagsString = tagsCsv
                }
                else {
                    tagsString = [tags[0].text];
                }
                if (languages.length > 0) {
                    let tagsCsv = languages.map((item) => item.text);
                    languagesString = tagsCsv
                }
                else {
                    languagesString = [languages[0].text];
                }

                // if (nationalCoursePrice !== internationalCoursePrice) {
                //     payload.coursePrice = internationalCoursePrice * 20;
                // }
                // else {
                //     payload.coursePrice = nationalCoursePrice;
                // }
            }
            if (user.role === ROLE.CONSUMER_SUPERVISOR) {
                payload.description = fieldValueDescription;
                payload.coursePrice = nationalCoursePrice;
                payload.internationalCoursePrice = internationalCoursePrice;
                // payload.coursePrice = Number(data.coursePrice);
                // payload.internationalCoursePrice = Number(data.internationalCoursePrice)
                payload.courseDurationInHours = Number(data.courseDurationInHours);
                payload.languages = languagesString;
                // payload.skillName = tagsString;
                payload.requirements = fieldValueRequirements;
                payload.whatWeWillLearn = fieldValueLearn;
                payload.categorySid = categorySid;
                payload.subCategorySid = subCategorySid;
                payload.tagNames = tagsString;
                payload.courseType = courseType;
                payload.modulatedCourseDetails = modulatedCourseDetails;
                if (data.previewVideo.length !== 0) {
                    // https://www.youtube.com/watch?v=nX9ShZo0COc
                    payload.thumbnail = data.previewVideo;
                }
                else {
                    payload.thumbnail = 'https://www.youtube.com/watch?v=nX9ShZo0COc';
                }

            }
            else {
                payload.description = data.description;
            }
            RestService.CreateCourse(payload).then(res => {
                getCourse();
                // getAllCourse(setCourse);
                Toast.success({ message: `Course is Successfully Created` });
                setShow(false);
                setTags([]);
                setLanguages([]);
                setFieldValueDescription('');
                setFieldValueRequirements('');
                setfieldValueLearn('');
            }, err => {
                console.error(err)
                setShow(false);
                Toast.error({ message: `${err.response.data.message}` });
            }
            );
        }
        catch (err) {
            console.error('error occur on createCourse', err)
            Toast.error({ message: `${err.response.data.message}` });
        }
    }



    //edit course list
    const editCourse = (data) => {
        try {
            let payload = {
                "sid": data.sid,
                "description": data.description,
                "name": data.name,
                "status": 'ENABLED',
            }
            RestService.editCourse(payload).then(res => {
                Toast.success({ message: `Course is Successfully updated` });
                getCourse()
                setShow(false)
            }, err => console.error(err)
            );
        }
        catch (err) {
            console.error('error occur on editCourse', err)
            Toast.error({ message: `Something wrong!!` });
        }
    }


    //clone

    const cloneCourseAndContents = async (courseId) => {
        try {
            spinner.show()
            await RestService.cloneCourseAndContents(courseId).then(res => {

                Toast.success({ message: `Course clone Successfully ` });
                getCourse()
                spinner.hide();
            }, err => console.error(err)

            )
        }
        catch (err) {
            console.error('error occur on deleteCourse', err)
            Toast.error({ message: `Something wrong!!` });
        }
    }




    // get all course list
    const deleteCourse = (courseId) => {
        try {
            RestService.deleteCourse(courseId).then(res => {
                Toast.success({ message: `Course is Deleted Successfully ` });
                getCourse()
            }, err => console.error(err)
            )
        }
        catch (err) {
            console.error('error occur on deleteCourse', err)
            Toast.error({ message: `Something wrong!!` });
        }
    }


    // get all course
    const getCourse = async () => {
        try {
            RestService.getAllCourse().then(
                response => {
                    const sortedCourses = response.data.sort((a, b) => {

                        return b.createdOn - a.createdOn;
                    });


                    setCourseList(sortedCourses);
                    setCount(sortedCourses.length);
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("Error occurred in getCourse():", err);
        }
    }

    // const getCourse = async (pagination = 1) => {
    //     try {
    //         let pageNo= 10
    //         spinner.show()
    //         RestService.getCourseByPage(pageNo , pagination).then(
    //             response => {
    //                 setCourseList(response.data);
    //             },
    //             err => {
    //                 spinner.hide();
    //             }
    //         ).finally(() => {
    //             spinner.hide();
    //         });
    //     } catch (err) {
    //         console.error("error occur on getAllBatch()", err)
    //     }
    // }
    // search searchCourse
    const searchCourse = (name) => {
        try {
            spinner.show();
            RestService.searchCourse(name).then(res => {
                setCourseList(res.data)
                spinner.hide();
            }, err => {
                spinner.hide();
            }
            );
        }
        catch (err) {
            console.error('error occur on searchCourse()', err)
            spinner.hide();
        }
    }

    // get all categories
    const getAllConsumerCourseCategories = () => {
        try {
            // spinner.show();
            RestService.getAllConsumerCourseCategories().then(
                response => {
                    setCategorieList(response.data);
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getAllConsumerCourseCategories()", err)
        }
    }

    // get all sub-categories
    const getAllConsumerCourseSubCategories = () => {
        try {
            // spinner.show();
            // const categorySid = categorySid;
            RestService.getAllConsumerCourseSubCategories(categorySid).then(
                response => {
                    setSubCategorieList(response.data);
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getAllConsumerCourseSubCategories()", err)
        }
    }

    // get sub-categories tag
    const getAllConsumeCourseTags = () => {
        try {
            // spinner.show();
            // const subCategorySid = subCategorySid;
            RestService.getAllConsumeCourseTags(subCategorySid).then(
                response => {
                    setCourseTag(response.data);
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getAllConsumeCourseTags()", err)
        }
    }

    //create course category
    const createConsumerCourseCategories = () => {
        try {
            // spinner.show();
            // const subCategorySid = subCategorySid;
            const payload = {
                "names": [
                    createCategory
                ]
            }
            RestService.createConsumerCourseCategories(payload).then(
                response => {
                    setCategorieList(response.data);
                    Toast.success({ message: `Category Created` });
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on createConsumerCourseCategories()", err)
        }
    }

    //create course sub category
    const createConsumerCourseSubCategories = () => {
        if (newCategorySid.length === 0) {
            Toast.warning({ message: `Create Category First` });
        }
        else {
            try {
                // spinner.show();
                // const subCategorySid = subCategorySid;
                const payload = {
                    "names": [
                        createSubCategory
                    ]
                }
                RestService.createConsumerCourseSubCategories(payload, newCategorySid).then(
                    response => {
                        // setCategorieList(response.data);
                        setCreateCategory('');
                        setCreateSubCategory('');
                        Toast.success({ message: `Sub-Category Created` });
                    },
                    err => {
                        spinner.hide();
                    }
                ).finally(() => {
                    spinner.hide();
                });
            } catch (err) {
                console.error("error occur on createConsumerCourseSubCategories()", err)
            }
        }

    }

    // get course count
    // const getBatchCount = async () => {
    //     try {
    //         RestService.getCount("vw_course").then(
    //             response => {
    //                 setCount(response.data);
    //             },
    //             err => {
    //                 spinner.hide();
    //             }
    //         ).finally(() => {
    //             spinner.hide();
    //         });
    //     } catch (err) {
    //         console.error("error occur on getAllBatch()", err)
    //     }
    // }

    // useEffect(() => {
    //     // getBatchCount()
    //     getCourse();
    // }, []);

    useEffect(() => {
        if (user.role === ROLE.CONSUMER_SUPERVISOR && show) {
            getAllConsumerCourseCategories();
        }
    }, [show]);

    useEffect(() => {
        if (!show) {
            setCategorieList({});
            setSubCategorieList(null);
            setCourseTag(null);
            setTags([]);
            setTagWithName([]);
            setLanguages([]);
            setFieldValueDescription('');
            setFieldValueRequirements('');
            setfieldValueLearn('');
        }
    }, [show]);

    useEffect(() => {
        if (user.role === ROLE.CONSUMER_SUPERVISOR) {
            getAllConsumerCourseSubCategories();
            setCourseTag(null);
        }
    }, [categorySid]);

    useEffect(() => {
        setIsTagCountValid(tags.length === 3);
    }, [tags]);


    // useEffect(() => {
    //     if (targetAudience === 'CONSUMER') {
    //         getAllConsumeCourseTags();
    //     }
    // }, [subCategorySid]);


    return (<>
        {/*  <div className="table-shadow">*/}
        <div>
            <div className="p-3">
                <CardHeader {...{
                    location,
                    count,
                    onChange: (e) => e.length === 0 && getCourse(e),
                    onEnter: (e) => searchCourse(e),
                    actionClick: () => { setShow(true); setIsEdit(false); setInitialValues({ name: '', description: '', coursePrice: 10000, internationalCoursePrice: 500, courseDurationInHours: 11, previewVideo: '' }) },
                    showAction: ((user.role === ROLE.SUPERVISOR || user.role === ROLE.SUPER_USER || user.role === ROLE.CONSUMER_SUPERVISOR) ? true : false)
                }} />
            </div>

            <BsModal {...{ show, setShow, headerTitle: isEdit ? "Update Course" : "Add new Course", size: "lg" }}>
                <div className="form-container">
                    <Formik
                        onSubmit={(value) => { !isEdit ? createCourse(value) : editCourse(value) }}
                        initialValues={initialValues}
                        validationSchema={(user.role === ROLE.SUPERVISOR || user.role === ROLE.SUPER_USER) ? schemaBusiness : schemaConsumer}
                    >
                        {({ handleSubmit, dirty, errors }) => <form onSubmit={handleSubmit} className="create-batch" >
                            <div>
                                <div className="row form-group">
                                    {/* <div className='col-md-12 mb-4'>
                                    <label className="mb-2 label form-label">Select Target Audience</label>
                                  
                                    <select name="usertype" className="form-control" onChange={(e) => (handleChangeAudience(e))} style={{ borderRadius: "30px", backgroundColor: "rgb(248, 250, 251)" }}>
                                        <option value="">--Select Type--</option>
                                        <option value="BUSINESS">Business</option>
                                        <option value="CONSUMER">Consumer</option>
                                    </select>
                                </div> */}
                                </div>
                                {
                                    (user.role === ROLE.SUPERVISOR || user.role === ROLE.SUPER_USER) ?
                                        <Form.Group className="row">
                                            <div className="col-12">
                                                <TextInput label="Course Name" name="name" />
                                            </div>
                                            <div className="col-12">
                                                <TextArea name="description" label="Description" />
                                            </div>
                                        </Form.Group>
                                        :
                                        user.role === ROLE.CONSUMER_SUPERVISOR &&
                                        <Form.Group className="row">
                                            <div className="col-12">
                                                <TextInput label="Course Name*" name="name" />
                                            </div>
                                            <div className="col-6">
                                                <TextInput label="National Course Price*" name="coursePrice" />

                                            </div>

                                            <div className="col-6 ">
                                                <TextInput label="International Course Price*" name="internationalCoursePrice" />
                                            </div>

                                            <InfoIcon className="mx-3" style={{ marginTop: "-10px" }} data-tooltip-id="contentname" data-tooltip-content="National Course Price must be 20 times of International Course Price" />

                                            <Tooltip
                                                id="contentname"
                                                place='right'
                                                style={{ fontFamily: "Inter" }}
                                            />

                                            <div className="col-12 mt-3">
                                                <TextInput label="Duration in Hours*" name="courseDurationInHours" />
                                            </div>
                                            <div className="col-12">
                                                <TextInput label="Preview Video" name="previewVideo" />
                                            </div>
                                            {/* <button onClick={() => setShowCreateCategory(!showCreateCategory)}>Category +</button> */}


                                            <div className="p-2" style={{ background: "#EAECF0", width: "100%", borderRadius: "8px" }}>
                                                <div className="row">
                                                    <div className="col-9">
                                                        <label className="form-label">Create Category</label>
                                                        <input type="text" className="form-control" placeholder="Create Category" value={createCategory} onChange={(e) => setCreateCategory(e.target.value)} />
                                                    </div>
                                                    <div className="col-3 mt-4">
                                                        <button onClick={() => createConsumerCourseCategories()} className="btn btn-primary" disabled={createCategory.length === 0}>Submit</button>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="input-wrapper col-5 mt-2">
                                                        <label className="mb-2 label form-label ">Select Category</label>
                                                        <select className="form-control" style={{ borderRadius: "30px", backgroundColor: "rgb(248, 250, 251)" }}
                                                            onChange={(e) => setNewCategorySid(e.target.value)}>
                                                            <option value="" disabled selected hidden>Select Category</option>
                                                            {
                                                                Object.keys(categorieList).map((item) => {
                                                                    return (
                                                                        <>
                                                                            <option value={item}>{categorieList[item]}</option>
                                                                        </>
                                                                    )
                                                                })
                                                            }

                                                        </select>
                                                    </div>
                                                    <div className="col-5 mt-2">
                                                        <label className="form-label">Create Sub-Category</label>
                                                        <input type="text" className="form-control" placeholder="Create Sub-Category" value={createSubCategory} onChange={(e) => setCreateSubCategory(e.target.value)} />
                                                    </div>
                                                    <div className="col-2 mt-3">
                                                        <button onClick={() => createConsumerCourseSubCategories()} className="btn btn-primary" disabled={createSubCategory.length === 0}>Submit</button>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="col-12 mt-3">

                                                <label className="mb-2 label form-label ">Course Type <sup style={{ fontWeight: '600', fontSize: '13px' }}>*</sup></label>
                                                <div className="input-wrapper">

                                                    <select className="form-control" style={{ borderRadius: "30px", backgroundColor: "rgb(248, 250, 251)" }}
                                                        onChange={(e) => setCourseType(e.target.value)}>
                                                        <option value="" disabled selected hidden>Select Category Type</option>
                                                        <option value="INSTRUCTOR_LED">Instructor Led</option>
                                                        <option value="SELF_PACED">Self Paced</option>
                                                    </select>
                                                </div>

                                            </div>
                                            {/* <div className="col-12">
                                            <TextArea name="description" label="Description" />
                                        </div> */}
                                            <div className="row mt-2 mx-2  ">

                                                {/* <TextInput name="assets" label="Assets" /> */}

                                                <div className="col-md-6" >
                                                    <label className="mb-2 label form-label ">Select Category</label>
                                                    <div className="input-wrapper">

                                                        <select className="form-control" style={{ borderRadius: "30px", backgroundColor: "rgb(248, 250, 251)" }}
                                                            onChange={(e) => setCategorySid(e.target.value)}>
                                                            <option value="" disabled selected hidden>Select Category</option>
                                                            {
                                                                Object.keys(categorieList).map((item) => {
                                                                    return (
                                                                        <>
                                                                            <option value={item}>{categorieList[item]}</option>
                                                                        </>
                                                                    )
                                                                })
                                                            }

                                                        </select>
                                                    </div>
                                                </div>
                                                {
                                                    subcategorieList !== null &&

                                                    <div className="col-6" >
                                                        <label className="mb-2 label form-label ">SubCategory</label>
                                                        <div className="input-wrapper">

                                                            <select className="form-control" style={{ borderRadius: "30px", backgroundColor: "rgb(248, 250, 251)" }}
                                                                onChange={(e) => setSubCategorySid(e.target.value)}>
                                                                <option value="" disabled selected hidden>Select SubCategory</option>
                                                                {
                                                                    Object.keys(subcategorieList).map((item) => {
                                                                        return (
                                                                            <>
                                                                                <option value={item}>{subcategorieList[item]}</option>
                                                                            </>
                                                                        )
                                                                    })
                                                                }

                                                            </select>
                                                        </div>
                                                    </div>
                                                }
                                                {/* {
                                                courseTag !== null &&
                                                <div className="col-4" >
                                                    <label className="mb-2 label form-label ">Tags</label>
                                                    <div className="input-wrapper">

                                                        <select className="form-control" style={{ borderRadius: "30px", backgroundColor: "rgb(248, 250, 251)" }}
                                                            onChange={(e) => handleDropdownSelect(e.target.value)}>
                                                            <option value="" disabled selected hidden>Select Tags</option>
                                                            {
                                                                Object.keys(courseTag).map((item) => {
                                                                    return (
                                                                        <>
                                                                            <option value={`${item},${courseTag[item]}`}>{courseTag[item]}</option>
                                                                        </>
                                                                    )
                                                                })
                                                            }

                                                        </select>
                                                    </div>
                                                </div>
                                            } */}
                                                {
                                                    tagWithName.length !== 0 &&

                                                    <div className='category_tags' style={{ cursor: "pointer" }}>
                                                        {tagWithName.map((tag, index) => (
                                                            <span key={index} className="tag" onClick={() => handleDelete(index)}>
                                                                {tag} &#x2716;
                                                            </span>
                                                        ))}
                                                    </div>
                                                }
                                            </div>
                                            {/* <div className="col-12">
                                            <label className='label form-label'>Enter Skills</label>
                                            <ReactTags
                                                tags={tags}
                                                placeholder="Skills..."
                                                minQueryLength={3}
                                                delimiters={tags.length < 10 ? [KeyCodes.enter] : []}
                                                handleDelete={handleDelete}
                                                handleAddition={handleAddition}

                                                inputFieldPosition="bottom"

                                            />

                                            <p>{10 - tags.length} tags remaining</p>
                                        </div> */}
                                            <div className="col-12">
                                                <label className='label form-label'>Enter Tags <sup style={{ fontWeight: '600', fontSize: '13px' }}>*</sup></label>
                                                <ReactTags
                                                    tags={tags}
                                                    placeholder={tags.length < 3 ? "Tags..." : ""}
                                                    minQueryLength={3}
                                                    delimiters={tags.length < 10 ? [KeyCodes.enter] : []}
                                                    handleDelete={handleDelete}
                                                    handleAddition={handleAddition}
                                                    inputFieldPosition="bottom"
                                                />

                                                {tags.length !== 3 && <p>Please enter exactly 3 tags.</p>}
                                            </div>
                                            <div className="col-12">
                                                <label className='label form-label'>Enter Languages <sup style={{ fontWeight: '600', fontSize: '13px' }}>*</sup></label>
                                                <ReactTags
                                                    tags={languages}
                                                    placeholder="Languages..."
                                                    minQueryLength={3}
                                                    delimiters={languages.length < 10 ? [KeyCodes.enter] : []}
                                                    handleDelete={handleDelete1}
                                                    handleAddition={handleAddition1}

                                                    inputFieldPosition="bottom"

                                                />

                                                <p>{10 - languages.length} languages remaining</p>
                                            </div>

                                            <div className="col-12">
                                                <label className="label form-label">Description <sup style={{ fontWeight: '600', fontSize: '13px' }}>*</sup></label>
                                                <ReactQuill
                                                    modules={GLOBELCONSTANT.QUILL_EVENTS}
                                                    value={fieldValueDescription}
                                                    onChange={setFieldValueDescription}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <label className='label form-label'>Requirements <sup style={{ fontWeight: '600', fontSize: '13px' }}>*</sup></label>
                                                <ReactQuill
                                                    modules={GLOBELCONSTANT.QUILL_EVENTS}
                                                    value={fieldValueRequirements}
                                                    onChange={setFieldValueRequirements}
                                                />
                                            </div><div className="col-12">
                                                <label className='label form-label'>What We Will Learn <sup style={{ fontWeight: '600', fontSize: '13px' }}>*</sup></label>
                                                <ReactQuill
                                                    modules={GLOBELCONSTANT.QUILL_EVENTS}
                                                    value={fieldValueLearn}
                                                    onChange={setfieldValueLearn}
                                                />
                                            </div>
                                            <div className="p-2" style={{ background: "#EAECF0", width: "100%", borderRadius: "8px" }}>
                                                <div className="d-flex" style={{ justifyContent: "space-between" }}>       <label className="label form-label">Course Modulation (% Weightage)</label>
                                                    {errors.totalWeightage && <div className="mx-3"> <p style={{ color: 'red', fontSize: "14px" }}>{errors.totalWeightage}</p></div>}</div>
                                                <hr />
                                                <div className="row">
                                                    <div className="col-4">
                                                        <TextInput label="Coding*" name="codingWeightage" />
                                                    </div>
                                                    <div className="col-4">
                                                        <TextInput label="Document*" name="documentWeightage" />
                                                    </div>
                                                    <div className="col-4">
                                                        <TextInput label="Lab*" name="labWeightage" />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-4">
                                                        <TextInput label="Mcq*" name="mcqWeightage" />
                                                    </div>
                                                    <div className="col-4">
                                                        <TextInput label="Meeting*" name="meetingWeightage" />
                                                    </div>
                                                    <div className="col-4 pb-4">
                                                        <TextInput label="Video*" name="videoWeightage" />
                                                    </div>
                                                </div>
                                                {/* Display the custom error message */}

                                                {/* <div className="mx-3" style={{ marginTop: "-20px" }}> <p style={{ color: 'red' }}>Total Course Modulation Weightage should not exceed 100 %</p></div> */}
                                            </div>



                                        </Form.Group>
                                }
                            </div>
                            {/* modal footer which contains action button to save data or cancel current action */}
                            <footer className="jcb mt-3">
                                <div>
                                </div>
                                <div>
                                    {
                                        isEdit ?
                                            <Button type="submit" >Update Course</Button>
                                            :
                                            (user.role === ROLE.SUPERVISOR || user.role === ROLE.SUPER_USER) ?
                                                <Button type="submit">
                                                    Create Course
                                                </Button>
                                                :
                                                <Button type="submit" disabled={user.role === ROLE.CONSUMER_SUPERVISOR && (tags.length === 0 ||
                                                    languages.length === 0
                                                    || courseType.length === 0 ||
                                                    categorySid.length === 0 ||
                                                    subCategorySid.length === 0)} >Create Course</Button>
                                    }

                                </div>
                            </footer>
                        </form>
                        }
                    </Formik>
                </div>
            </BsModal>

            <DynamicTable {...{ configuration, sourceData: courseList }} />
            <div>
            </div>
        </div>
    </>)
}


const Course = () => {
    return (
        <Router>
            <Courses path="/" />
            <CourseDetails path="course-details" />
        </Router>
    )
}
export default Course