import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

// ChartJS.register(ArcElement, Tooltip, Legend);



export function PieChart({ sumOfPieData, pieData }) {
    const newData = sumOfPieData === 0 ? [0, 0, 0, 0, 0, 0] : pieData.map(i => ((i / sumOfPieData) * 100).toFixed(1));
    const data = {
        labels: ['Passed', 'Failed', 'In Progress', 'Completed', 'Overdue', 'Not Started'],
        datasets: [
            {
                label: 'Overall Training Status in %',
                data: newData,
                
     backgroundColor : [
        `#33186B`,  // Lighter shade (20% opacity)
        `#7360DF`,  // Lighter shade (40% opacity)
        `#C499F3`,  // Lighter shade (60% opacity)
        `#F2AFEF`,  // Lighter shade (80% opacity)
        '#DC84F3',         // Base color (100% opacity)
        `#864AF9`,  // Darker shade (80% opacity)
    ],
                borderColor: [
                    `#33186B`,  // Lighter shade (20% opacity)
                    `#7360DF`,  // Lighter shade (40% opacity)
                    `#C499F3`,  // Lighter shade (60% opacity)
                    `#F2AFEF`,  // Lighter shade (80% opacity)
                    '#DC84F3',         // Base color (100% opacity)
                    `#864AF9`,  // Darker shade (80% opacity)
                ],
                borderWidth: 1,
            },
        ],
    };
    return <Pie data={data} />;
}
