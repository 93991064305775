import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

import RestService from '../../../../Services/api.service';

const BarChartComponent = ({ totalContent }) => {


    // Chart options
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                backgroundColor: '#7F56D9'
            },
            title: {
                display: true,
                text: 'Chart.js Bar Chart',
            },
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                },
            }],
        },
    };

    // Labels for the chart
    const labels = ['Document', 'Video', 'Practice Lab', 'Assessment Lab', 'MCQ', 'Coding'];

    // Data for the chart
    const data = {
        labels,
        datasets: [
            {
                label: 'Content Completion by Content Type',
                data: totalContent,
                backgroundColor: '#7F56D9',
            },
        ],
    };

    return (
        <>

            <Bar options={options} data={data} />
        </>

    )
};

export default BarChartComponent;