import { useContext } from "react";
import { navigate } from "../../../Common/Router";
import { AssessmentContext } from "../AssesementContext";
import styles from "./Sidebar.module.css";
import QuestionItem from "./QuestionItem";
import LeaderBoard from "./LeaderBoard";
import AppUtils from "../../../../Services/Utils";
import TRAINSOFT from '../../../../Assets/Images/trainsoft.png';
import AppContext from "../../../../Store/AppContext";
import { ICN_LOGO } from "../../../Common/Icon";

const Sidebar = () => {
  const {
    setQuestion,
    selectedAnswers,
    setQuestionIndex,
    questionIndex,
    finished,
    setSelectedAnswer,
    questions,
    setInReview
  } = useContext(AssessmentContext);

  /** this method trigger when select particular
   * @param {Object} _question = selected question
   * @param {Number} index = index of selected question 
  */

  const { user, ROLE } = useContext(AppContext);
  const onSelectQuestion = (_question, index) => {
    try {
      setQuestion(_question);
      setSelectedAnswer({});
      setQuestionIndex(index);
      if (selectedAnswers[_question && _question?.sid]?.answerId) setInReview(true);
    } catch (error) {
      console.error("Error occurred in sendBeacon --", error);
    }
  }

  return <div style={{
    flex: 3,
    background: "#EAEAEA",
    padding: "35px 25px",
    height: '100vh',
    overflowY: 'scroll'
  }}
  >
    <div
      className={`${styles.container} pointer `}
      // onClick={() => {
      //   if (user.role === ROLE.CONSUMER_LEARNER) {
      //     navigate('/training/training-details/')
      //   }
      //   else {
      //     navigate("/");
      //   }

      // }}
    >
      {
        // user.role === ROLE.CONSUMER_LEARNER ?
          ICN_LOGO
          // :

          // <img src={TRAINSOFT} height="150px" />
      }

    </div>

    {
      !finished ? <>
        {
          AppUtils.isNotEmptyArray(questions)
          && questions.map((_question, index) => (
            <div onClick={() => onSelectQuestion(_question, index)}>
              <QuestionItem
                {..._question}
                key={index}
                number={index + 1}
                // done={selectedAnswers[_question && _question?.sid]?.answerId}
                done={selectedAnswers[_question && _question?.sid]?.answerId || 'answer' in localStorage && JSON.parse(localStorage.getItem('answer'))[_question && _question?.sid]?.answerId}
                active={questionIndex === index}
              />
            </div>
          ))
        }
        <div onClick={() => { setQuestion(null); setQuestionIndex(-1); setInReview(false); }}>
          <QuestionItem number={-1} active={questionIndex === -1} />
        </div>
      </> : <LeaderBoard />
    }
  </div>;
};

export default Sidebar;
