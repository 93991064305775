import { useContext } from "react";
import { AssessmentContext, AssessmentProvider } from "./AssesementContext";
import { AssessmentDialog } from "./AssesmentDialog";
import AssessmentBody from "./AssessmentBody/AssesmentBody";
import Sidebar from "./Sidebar/Sidebar";

const Assessment = () => {
  return (
    <AssessmentProvider>
      <Content />
    </AssessmentProvider>
  );
};

const Content = ({ location }) => {
  const { dialogOpen, finished } = useContext(AssessmentContext);
  const viewResult = localStorage.getItem("showMCQAssessmentResult");
  return <>
    {
      dialogOpen
        ? <AssessmentDialog {...{ location }} />
        : <div style={{ display: "flex" }}>
          {!finished ?
            <Sidebar />
            :
            finished &&
            viewResult === "resultVisible" &&
            <Sidebar />
          }
          <AssessmentBody {...{ location }} />
        </div>
    }
  </>;
};

export default Assessment;
