import React from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Dropdown, Modal, ProgressBar } from 'react-bootstrap';
import { CustomToggle } from '../../Services/MethodFactory';
import { ICN_CLOSE, ICN_EXPAND } from './Icon'
import "./bsUtils.css";


// model
export const CodingModal = ({ children, setShow, show, headerTitle, size = "md", headerAction = null }) => {
    return (
        <Modal
            size={size}
            show={show}
            // onHide={() => setShow(false)}
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Body className="px-5 py-4">
                <div className="jcb mb-3 aic">
                    <div className="title-md mb-0">{headerTitle}</div>
                    <div className="flx">
                        <div className="mx-2">
                            {headerAction}
                        </div>
                        {/* <div className="circle-md" onClick={() => setShow(false)}>
                            {ICN_CLOSE}
                        </div> */}

                    </div>
                </div>
                {children}
            </Modal.Body>

        </Modal>
    )
}


