import React from 'react';
import Countdown from "react-countdown";
import { useState, useEffect } from "react";
import { ICN_TIMER, IcnTimer } from '../Icon';
import styles from "../../Screens/Assessment/AssessmentBody/AssessmentBody.module.css";

const CountdownTimer = ({ timeLimit, type, labId, labName, callback = () => { } }) => {
    const [time, setTime] = useState(false);
    const [data, setData] = useState(
        { date: Date.now(), delay: timeLimit * 60000 } // Time in milliseconds
    );
    const wantedDelay = timeLimit * 60000;

    const renderer = ({ hours, minutes, seconds, completed }) => {
        // Update the remaining time in minutes in real-time
        // if (evaluatedLab) {
        //     if (hours === 0) {
        //         setCheckTimeLeftInMinutes(minutes);
        //     }
        // }

        return (
            <>
                {completed ? "" :
                    type === 'LAB' ?
                        timeLimit > 60 ?
                            <button className='btntimer'>
                                <span className='mr10' style={{ verticalAlign: "text-bottom" }}>{ICN_TIMER}</span>
                                {hours}:{minutes}:{seconds}
                            </button>
                            :
                            <button className='btntimer'>
                                <span className='mr10' style={{ verticalAlign: "text-bottom" }}>{ICN_TIMER}</span>
                                {minutes}:{seconds}
                            </button>
                        :
                        <div style={{ width: "130px", color: "#fff", background: "#2d0a52" }} className={minutes >= 4 ? styles.timer : (minutes >= 2 ? styles.fourMinLeft : styles.twoMinLeft)}>
                            <div className="aic">
                                <div className="mr10">
                                    <IcnTimer {...{ color: minutes >= 4 ? "#917618" : (minutes >= 2 ? "#A86F3E" : "#ffffff") }} />
                                </div>
                                {timeLimit > 60 ? <div className="pt2">{hours}:{minutes}:{seconds}</div> : <div className="pt2">{minutes}:{seconds}</div>}
                            </div>
                        </div>
                }
            </>
        );
    };

    const getLocalStorageValue = (s) => localStorage.getItem(s);

    //[START] componentDidMount
    useEffect(() => {
        const savedDate = (type === "LAB") ? getLocalStorageValue(`end_date_${labId}_${labName}`) : getLocalStorageValue("end_date");
        if (savedDate != null && !isNaN(savedDate)) {
            const currentTime = Date.now();
            const delta = parseInt(savedDate, 10) - currentTime;
            if (delta > wantedDelay) {
                if (type === "LAB") {
                    if (localStorage.getItem(`end_date_${labId}_${labName}`).length > 0) {
                        console.log("");
                    }
                } else {
                    if (localStorage.getItem("end_date").length > 0) {
                        console.log("");
                    }
                }
            } else {
                setData({ date: currentTime, delay: delta });
            }
        }
    }, [wantedDelay]);

    useEffect(() => {
        if (time) {
            callback();
        }
    }, [time]);

    return (
        <div>
            <Countdown
                date={data.date + data.delay}
                renderer={renderer}
                onStart={() => {
                    if (type === 'LAB') {
                        if (localStorage.getItem(`end_date_${labId}_${labName}`) == null)
                            localStorage.setItem(
                                `end_date_${labId}_${labName}`,
                                JSON.stringify(data.date + data.delay)
                            );
                    } else {
                        if (localStorage.getItem("end_date") == null)
                            localStorage.setItem(
                                "end_date",
                                JSON.stringify(data.date + data.delay)
                            );
                    }
                }}
                onComplete={() => {
                    if (type === 'LAB') {
                        if (localStorage.getItem(`end_date_${labId}_${labName}`) != null) {
                            setTime(true);
                        }
                    } else {
                        if (localStorage.getItem("end_date") != null) {
                            setTime(true);
                        }
                    }
                }}
            />
        </div>
    );
}

export default CountdownTimer;
