import { useState, useEffect, useContext } from "react";
import DynamicTable from "../../Common/DynamicTable/DynamicTable";
import { Button } from "../../Common/Buttons/Buttons";
import { BsModal, Toggle } from "../../Common/BsUtils";
import CardHeader from "../../Common/CardHeader";
import RestService from "../../../Services/api.service";
import * as Yup from 'yup';
import moment from 'moment'
import useToast from "../../../Store/ToastHook";
import GLOBELCONSTANT from "../../../Constant/GlobleConstant";
import AppContext from "../../../Store/AppContext";
import { getAllBatches } from "../../../Services/service";
import './batches.css'
import SearchBox from "../../Common/SearchBox/SearchBox";
import { TextInput } from "../../Common/InputField/InputField";

const AddGroup = ({ show, setShow, sid, getAllGroupDetails }) => {
    const { user, spinner, ROLE } = useContext(AppContext)
    const [count, setCount] = useState(0)
    const Toast = useToast()
    const [participant, setParticipant] = useState([]);
    const [selectedSid, setSelectedSid] = useState([]);
    const [searchValue, setSearchValue] = useState([]);
    const [groupName, setGroupName] = useState('');
    const [configuration, setConfiguration] = useState({
        columns: {
            "learnerName": {
                "title": "Name",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,

            },
            "learnerEmail": {
                "title": "Email Id",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false
            }
        },
        headerTextColor: '#454E50', // user can change table header text color
        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configuration.sortBy = sortKey;
            Object.keys(configuration.columns).map(key => configuration.columns[key].sortDirection = (key === sortKey) ? !configuration.columns[key].sortDirection : false);
            configuration.sortDirection = configuration.columns[sortKey].sortDirection;
            setConfiguration({ ...configuration });
        },
        actions: [],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table sort-enabled", // table custom class
        showCheckbox: true,
        clearSelection: false
    });


    // get all unassigned batch participants
    const getUnassignedBatchParticipants = () => {
        try {
            // let pageSize = 500
            // spinner.show();
            RestService.getUnassignedBatchParticipants(sid).then(
                response => {
                    setParticipant(response.data)
                    setSearchValue(response.data)
                    spinner.hide();
                },
                err => {
                    console.error("error occur on getUnassignedBatchParticipants()", err)
                    spinner.hide();
                }
            )
        } catch (err) {
            console.error("error occur on getUnassignedBatchParticipants()", err)
            spinner.hide();
        }
    }


    //create group
    const createGroup = () => {
        try {
            let payload = {
                "batchSid": sid,
                "groupName": groupName,
                "userIds": selectedSid
            };

            RestService.createGroup(payload).then(
                response => {
                    Toast.success({ message: "Group created successfully" })
                    getAllGroupDetails();
                    setShow(false)
                },
                err => {
                    spinner.hide();
                    setShow(false)
                }
            ).finally(() => {
                spinner.hide();
                setShow(false)
            });
        } catch (err) {
            setShow(false)
            console.error("error occur on createGroup()", err)
        }
    }

    // on search
    const onSearch = (e) => {
        try {
            setSearchValue(participant.filter(res => res.learnerEmail.toUpperCase().indexOf(e.toUpperCase()) > -1))
        } catch (err) {
            console.error("error occur on onSearch()", err)
        }
    }

    useEffect(() => {
        getUnassignedBatchParticipants();
    }, []);

    return (<>
        <BsModal {...{
            show,
            setShow,
            headerTitle: "Add Group",
            headerAction: <SearchBox {...{ onChange: (e) => onSearch(e), onEnter: () => { }, clearField: () => { } }} />,
            size: "xl"
        }}>

            <div className="partiContainer">
                <div>
                    <label for="name" className="label">Group Name</label>

                    <input className="input-field" type="text" onChange={(e) => setGroupName(e.target.value)} />
                </div>
                <br />
                <div>
                    <label for="name" className="label">Select Candidate</label>
                    <DynamicTable {...{ configuration, sourceData: searchValue, onSelected: (e) => { setSelectedSid(e.map(r => r.learnerSid)); } }} />
                </div>


            </div>
            <div className="jce mt-2">
                <Button className="mx-2" onClick={() => { setShow(true); createGroup() }}>Create</Button>
            </div>
        </BsModal>
    </>)

}
export default AddGroup