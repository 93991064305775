import React, { useState } from "react";

import { ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; 

export default function CompleteProgressBar({newpercent}) {

  return (
    
<> 
<div className="newtext" style={{marginTop:'15%'}}>
     
     <h2>
     

       <div className="container mt-5 align-item-center ">
       <p className='mt-2 mb-2 title-md text-center'>Your Assessment is Submitted, You will automically get redirected to Training Page</p>
        
        
        
       </div>
       
     </h2>
     
   </div>
 
    
</>
    
  );
}
