import React, { useState, useEffect } from 'react';
import RestService from '../Services/api.service';
import GLOBELCONSTANT from '../Constant/GlobleConstant';

const AssessmentContext = React.createContext({});
export default AssessmentContext;


export const AssessmentProvider = (props) => {
    const [topicSid, setTopicSid] = useState(null)
    const [assessmentCon, setAssessmentCon] = useState(null)
    const [initialAssessment, setInitialAssessment] = useState(null)
    const [assessmentVal, setAssessmentVal] = useState()
    const [category, setCategory] = useState([])
    const [bookmark, setBookmark] = useState([])
    const [myAssessment, setMyAssessment] = useState([])
    const [codingAssessmentSid, setCodingAssessmentSid] = useState('');
    const [assessmentType, setAssessmentType] = useState('MCQ');
    const [showAddedCodingAssessmentQuestion, setShowAddedCodingAssessmentQuestion] = useState([]);
    const [codingAssessmentQuestion, setCodingAssessmentQuestion] = useState([]);
    const [trainingId, setTrainingId] = useState('');

    // get All topic
    const getAllCategory = async () => {
        try {
            let { data } = await RestService.getAllCategory()
            setCategory(data)
        } catch (err) {
            console.error("error occur on getAllTopic()", err)
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('REACTAPP.TOKEN');
        let user = JSON.parse(localStorage.getItem('user'));
        if (token !== null && (user?.role === GLOBELCONSTANT.ROLE.SUPERVISOR ||
            user?.role === GLOBELCONSTANT.ROLE.CONSUMER_SUPERVISOR ||
            user?.role === GLOBELCONSTANT.ROLE.SUPER_USER)) {
            getAllCategory();
        }
    }, [])

    const appData = {
        category,
        bookmark,
        setBookmark,
        setCategory,
        assessmentCon,
        setAssessmentCon,
        topicSid,
        setTopicSid,
        initialAssessment,
        setInitialAssessment,
        assessmentVal,
        setAssessmentVal,
        myAssessment,
        setMyAssessment,
        codingAssessmentSid,
        setCodingAssessmentSid,
        assessmentType,
        setAssessmentType,
        showAddedCodingAssessmentQuestion,
        setShowAddedCodingAssessmentQuestion,
        codingAssessmentQuestion,
        setCodingAssessmentQuestion,
        trainingId,
        setTrainingId
    };

    return <AssessmentContext.Provider value={{
        ...appData,
    }} > {props.children} </ AssessmentContext.Provider>
}