import { useEffect, useState, useContext } from "react";
import { ErrorMessage, Formik } from "formik";
import { Checkbox, TextInput, SelectInput, TextArea, RadioBoxKey, DateInput } from "../../../../Common/InputField/InputField";
import { Form } from "react-bootstrap";
import AssessmentContext from "../../../../../Store/AssessmentContext";
import Submit from "../../../Assessment/common/SubmitButton";
import RestService from "../../../../../Services/api.service";
import AppContext from "../../../../../Store/AppContext";
import useToast from "../../../../../Store/ToastHook";
import { navigate } from "../../../../Common/Router";
import * as Yup from 'yup';
import "../topic.css";
import { Button } from "../../../../Common/Buttons/Buttons";
import Select from "react-dropdown-select";

const CreateStep1 = ({ assesmentType, handleNext }) => {
  const { initialAssessment, setAssessmentVal, category, setInitialAssessment, topicSid, setCodingAssessmentSid } = useContext(AssessmentContext)
  const timeFormatRegex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;
  const Toast = useToast()
  const { spinner } = useContext(AppContext)

  //validation
  const schema = Yup.object().shape({
    title: Yup.string().required('Required!'),
    description: Yup.string().required('Required!'),
    premium: Yup.string().required('Required!'),
    categorySid: Yup.object().required('Required!'),
    tagSid: Yup.array().required("Tag is required")
  });

  const schemaCodingAssessment = Yup.object().shape({
    name: Yup.string().required('Required!'),
    duration: Yup.number().required('Required!'),
    startDate: Yup.string().required('Required!'),
    startTime: Yup.string()
      .matches(timeFormatRegex, 'Invalid time format (HH:MM)')
      .required("Required!"),
    endTime: Yup.string()
      .matches(timeFormatRegex, 'Invalid time format (HH:MM)')
      .required("Required!"),
  })

  const [multipleAttempts, setMultipleAttempts] = useState(false);
  const [tabSwitch, setTabSwitch] = useState(true);
  const [mouseInAssessment, setMouseInAssessment] = useState(false);
  const [disableCopyPaste, setDisableCopyPaste] = useState(false);
  const [enableFullScreen, setEnableFullScreen] = useState(true);
  const [multipleFD, setMultipleFD] = useState(true);
  const [specialKeys, setSpecialKeys] = useState(false);
  const [disableScreenRefreshKeys, setDisableScreenRefreshKeys] = useState(false);

  // Create Coding Assessment
  const createCodingAssessment = async (val) => {
    let date = new Date(val.startDate);
    let month = date.getMonth() + 1;
    let resultantMonth = month >= 10 ? month : `0${month}`;
    let resultantDay = date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`;
    let dateFormat = date.getFullYear() + "-" + (resultantMonth) + "-" + resultantDay;
    let payloadDetails = [];
    spinner.show("Loading... wait");
    try {
      let payload =
      {
        "duration": val.duration,
        "multipleAttempts": multipleAttempts,
        "name": val.name,
        // "proctoringDetails": [
        //   "string"
        // ],
        "slotDetails": formGroups,
        "topicSid": topicSid
      }

      if (tabSwitch) {
        payloadDetails.push('TAB_SWITCH')
      }
      if (mouseInAssessment) {
        payloadDetails.push('MOUSE_IN_ASSESSMENT');
      }
      if (disableCopyPaste) {
        payloadDetails.push("DISABLE_COPY_PASTE");
      }
      if (enableFullScreen) {
        payloadDetails.push("ENABLE_FULL_SCREEN");
      }
      if (multipleFD) {
        payloadDetails.push("MULTIPLE_FACE_DETECTIONS");
      }
      if (specialKeys) {
        payloadDetails.push("SPECIAL_KEYS");
      }
      if (disableScreenRefreshKeys) {
        payloadDetails.push("DISABLE_SCREEN_REFRESH_KEYS");
      }
      // let payload = {
      //   "duration": val.duration,
      //   "name": val.name,
      //   "startDate": dateFormat,
      //   "startTime": val.startTime,
      //   "topicSid": topicSid
      // }
      payload.proctoringDetails = payloadDetails;

      let res = await RestService.createCodingAssessment(payload)
      Toast.success({ message: "Assessment Created successfully", time: 2000 })

      setCodingAssessmentSid(res?.data)
      setInitialAssessment(initialAssessment)

      handleNext()
      spinner.hide()
    } catch (err) {
      spinner.hide()
      Toast.error({ message: err.response?.data?.message, time: 2000 })
      console.error("error occur on createCodingAssessment()", err)
    }
  }

  const [formGroups, setFormGroups] = useState([
    { id: 1, assessmentDate: '', endTime: '', startTime: '' },
  ]);
  const [isFormValid, setIsFormValid] = useState(false);

  const handleAdd = () => {
    // Validation check - Ensure all values in the new group are greater than 1
    const isValidNewGroup = formGroups.every((group) => {
      return (
        group.assessmentDate !== null &&
        group.startTime !== null &&
        group.endTime !== null &&
        group.assessmentDate !== '' &&
        group.startTime !== '' &&
        group.endTime !== ''
      );
    });
  
    if (isValidNewGroup) {
      const newFormGroup = {
        id: formGroups.length + 1,
        assessmentDate: '', // or any default value
        startTime: '', // or any default value
        endTime: '', // or any default value
      };
      setFormGroups([...formGroups, newFormGroup]);
    } else {
      // Handle case where one or more values are not greater than 1
      console.error("Cannot add a new group with values less than 1.");
    }
  };
  


  const handleRemove = (id) => {
    if (formGroups.length > 1) {
      const updatedFormGroups = formGroups.filter((group) => group.id !== id);
      setFormGroups(updatedFormGroups);
    } else {
      // Handle case where there is only one group (e.g., show an error message)
      console.error("Cannot remove the last group.");
    }
  };
  

  function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();

    // Add leading zero if month or day is less than 10
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }

 const areAllValuesFilled = (groups) => {
  return groups.some(group =>
    group.assessmentDate !== null &&
    group.startTime !== null &&
    group.endTime !== null &&
    group.assessmentDate !== '' &&
    group.startTime !== '' &&
    group.endTime !== ''
  );
};const handleChange = (id, fieldName, value) => {

  let updatedFormGroups;

  if (fieldName === "startTime" || fieldName === "endTime") {
    // Validation check using the regular expression
    const isValidTimeFormat = timeFormatRegex.test(value);

    if (!isValidTimeFormat) {
      // Handle invalid input for startTime (e.g., show an error message)
      console.error("Invalid time format for startTime");
      // You can also set a state for displaying an error message to the user
    }

    // Update the formGroups regardless of validation result
    updatedFormGroups = formGroups.map((group) =>
      group.id === id ? { ...group, [fieldName]: value } : group
    );
  } else {
    // Update the formGroups for non-time fields
    updatedFormGroups = formGroups.map((group) =>
      group.id === id ? { ...group, [fieldName]: value } : group
    );
  }

  // Set the updated formGroups
  setFormGroups(updatedFormGroups);
};

  useEffect(() => {
    setIsFormValid(areAllValuesFilled(formGroups));
  }, [formGroups]);

  return (
    <>
      {
        assesmentType === "CODING" ?
          <Formik
            onSubmit={(value) => {
              createCodingAssessment(value)
            }}
            initialValues={{
              ...initialAssessment,
              name: '',
              duration: '',
              startDate: '',
              startTime: '',
              endTime: ''
            }}
          // validationSchema={schemaCodingAssessment}
          >
            {({ handleSubmit, isSubmitting, dirty, setFieldValue, values }) => (
              <form onSubmit={handleSubmit} className="create-batch">

                <div>
                  <Form.Group>
                    <TextInput
                      label="Assessment Title"
                      name="name"
                    />
                  </Form.Group>

                  <Form.Group>
                    <TextInput name="duration" label="Enter Duration in Minutes" placeholder="10" />
                  </Form.Group>
                  <div>
                    <input type="checkbox" id="multipleAttempts" name="multipleAttempts" checked={multipleAttempts} onClick={(e) => setMultipleAttempts(e.target.checked)} />
                    <label for="multipleAttempts">Multiple Attempts</label>

                  </div>
                  <div>
                    <input type="checkbox" id="tabSwitch" name="tabSwitch" checked={tabSwitch} onClick={(e) => setTabSwitch(e.target.checked)} />
                    <label for="tabSwitch">Tab Switch</label>
                  </div>

                  <div>
                    <input type="checkbox" id="mouseInAssessment" name="mouseInAssessment" checked={mouseInAssessment} onClick={(e) => setMouseInAssessment(e.target.checked)} />
                    <label for="mouseInAssessment">Mouse In Assessment</label>
                  </div>

                  <div>
                    <input type="checkbox" id="disableCopyPaste" name="disableCopyPaste" checked={disableCopyPaste} onClick={(e) => setDisableCopyPaste(e.target.checked)} />
                    <label for="disableCopyPaste">Disable Copy Paste</label>
                  </div>

                  <div>
                    <input type="checkbox" id="enableFullScreen" name="enableFullScreen" checked={enableFullScreen} onClick={(e) => setEnableFullScreen(e.target.checked)} />
                    <label for="enableFullScreen">Enable Full Screen</label>
                  </div>

                  <div>
                    <input type="checkbox" id="multipleFD" name="multipleFD" checked={multipleFD} onClick={(e) => setMultipleFD(e.target.checked)} />
                    <label for="multipleFD">Multiple Face Detection</label>
                  </div>

                  <div>
                    <input type="checkbox" id="specialKeys" name="specialKeys" checked={specialKeys} onClick={(e) => setSpecialKeys(e.target.checked)} />
                    <label for="specialKeys">Special Keys</label>
                  </div>

                  <div>
                    <input type="checkbox" id="disableScreenRefreshKeys" name="disableScreenRefreshKeys" checked={disableScreenRefreshKeys} onClick={(e) => setDisableScreenRefreshKeys(e.target.checked)} />
                    <label for="disableScreenRefreshKeys">Disable Screen Refresh</label>
                  </div>
                  <div>
                    <div>
                      {formGroups.map((group) => (
                        <div key={group.id}>
                          <Form.Group>
                            <label for="assessmentDate">Date</label>
                            <input type="date" id="birthday" name={`assessmentDate${group.id}`}
                              onChange={(e) => handleChange(group.id, 'assessmentDate', e.target.value)}
                              min={getCurrentDate()} />
                            {/* <DateInput
                              name={`assessmentDate${group.id}`}
                              label="Start Date"
                              setFieldValue={setFieldValue} values={values}
                              // value={startDate}
                              onChange={(e) => handleChange(group.id, 'assessmentDate', e.target.value)}
                            /> */}
                          </Form.Group>

                          <Form.Group>
                            <TextInput
                              name={`startTime${group.id}`}
                              label="Enter Start Time in HH:MM Format"
                              placeholder="HH:MM"
                              // value={startTime}
                              onChange={(e) => handleChange(group.id, 'startTime', e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group>
                            <TextInput
                              name={`endTime${group.id}`}
                              label="Enter End Time in HH:MM Format"
                              placeholder="HH:MM"
                              // value={startTime}
                              onChange={(e) => handleChange(group.id, 'endTime', e.target.value)}
                            />
                          </Form.Group>
                          <Button variant="danger" onClick={() => handleRemove(group.id)}>
                            Remove
                          </Button>
                        </div>
                      ))}
                      <Button variant="primary" onClick={handleAdd}>
                        Add
                      </Button>
                    </div>
                  </div>

                </div>

                <div className="ass-foo-border">
                  <div>
                    {/* <Submit onClick={handleBack} style={{background: "#0000003E", color: "black",marginRight: "10px", }}> Back</Submit> */}
                  </div>

                  <div>
                    <Submit onClick={() => {
                      navigate("topic-details", {
                        state: {
                          title: "Topics",
                          subTitle: "Assessment",
                          path: "topicAssesment",
                        }
                      })
                    }} style={{ background: "#0000003E", color: "black", marginRight: "10px", }}>
                      Cancel
                    </Submit>
                    <Button type="submit" className="px-5" disabled={!isFormValid} onClick={() => { setAssessmentVal(values); setInitialAssessment(values) }}>Next</Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
          :
          <Formik
            onSubmit={(value) => {
              setAssessmentVal(value);
              handleNext();
            }}
            initialValues={{
              ...initialAssessment,
              "tagSid": category.tagSid && category.tagSid.split(",").map(r => ({ "sid": r })),
              data: initialAssessment.validUpto === 0 ? '' : initialAssessment.validUpto
            }}
            validationSchema={schema}
          >
            {({ handleSubmit, isSubmitting, dirty, setFieldValue, values }) => (
              <form onSubmit={handleSubmit} className="create-batch">

                <div>
                  <Form.Group>
                    <TextInput
                      label="Assessment Title"
                      name="title"
                    />
                  </Form.Group>
                  <Form.Group>
                    <TextArea label="Description" name="description" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="label">
                      Type
                    </Form.Label>
                    <div style={{ marginBottom: "10px" }}>
                      <RadioBoxKey name="premium" options={[{ label: "Free", value: false }, { label: "Premium", value: true, disabled: true }]} />
                    </div>
                  </Form.Group>
                  <Form.Group style={{ width: "60%" }}>
                    <SelectInput label="Category" option={category} bindKey="name" name="category" value={values.category ? values.category : ""} />
                    <Form.Label className="label">
                      Tag
                    </Form.Label>
                    <Select
                      options={values.category?.tags}
                      values={values.tagSid ? values.tagSid : []}
                      multi
                      // name="tagSid"
                      onChange={(data) => { setFieldValue("tagSid", data) }}
                      labelField="name"
                      valueField="name"
                      color="#B1FFFF"
                      className="input-field"
                    />
                    <ErrorMessage component="span" name="tagSid" className="text-danger mb-2 small-text" />
                    {/* <SelectInput label="Tag" value={values.tagSid} option={values.categorySid?.tags} bindKey="name" name="tagSid" /> */}
                    <Form.Group>
                      <Form.Label className="label">
                        Difficulty
                      </Form.Label>
                      <div style={{ marginBottom: "10px" }}>
                        <RadioBoxKey name="difficulty" options={[{ label: "Beginner", value: "BEGINNER" }, { label: "Intermediate", value: "INTERMEDIATE" }, { label: "Expert", value: "EXPERT" }]} />
                      </div>
                    </Form.Group>

                    <Form.Group className="flx">
                      <div>
                        <Form.Label className="label">
                          Assessment Validity
                        </Form.Label>
                        <div style={{ marginBottom: "10px" }}>
                          <RadioBoxKey name="validUpto" options={[{ label: "No Limit", value: true }, { label: "Till", value: false, disabled: true }]} />
                        </div>
                      </div>
                      <div>
                        {!values.validUpto && <DateInput name="date" setFieldValue={setFieldValue} values={values} label="" />}
                      </div>
                    </Form.Group>
                  </Form.Group>
                </div>
                <div className="ass-foo-border">
                  <div>
                    {/* <Submit onClick={handleBack} style={{background: "#0000003E", color: "black",marginRight: "10px", }}> Back</Submit> */}
                  </div>

                  <div>
                    <Submit onClick={() => {
                      navigate("topic-details", {
                        state: {
                          title: "Topics",
                          subTitle: "Assessment",
                          path: "topicAssesment",
                        }
                      })
                    }} style={{ background: "#0000003E", color: "black", marginRight: "10px", }}>
                      Cancel
                    </Submit>
                    <Button type="submit" className="px-5" onClick={() => { setAssessmentVal(values); setInitialAssessment(values) }}>Next</Button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
      }
    </>
  );
};


export default CreateStep1;
