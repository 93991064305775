import React, { useState, useContext } from 'react';
import "react-circular-progressbar/dist/styles.css";
import Confetti from "react-confetti";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "./progressbar.css";
import FastForwardIcon from '@mui/icons-material/FastForward';
import GenerateCertificate from '../../Screens/Training/GenerateCertificate/GenerateCertificate';
import AppContext from "../../../Store/AppContext";
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal d-block" : "modal d-none";

  return (
    <div className={showHideClassName}>
      <div className="modalprogress">
        <div style={{ marginLeft: "95%", marginTop: "5px" }}>
          <a href="javascript:;" className="modal-close" onClick={handleClose}>
            X
          </a>
        </div>
        {children}
      </div>
    </div>
  );
};

function Example(props) {
  const [modal, setModal] = useState(false);
  const { user, ROLE, spinner } = useContext(AppContext);
  const progressBackgroundColor = 'red';

  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row-reverse", justifyContent: "space-between", textAlign: "center", alignItems: "center" }}>
        <div style={{ width: "15%" }}>
          {props.children}
        </div>

        {modal ? (
          <Modal show={modal} handleClose={() => setModal(false)}>
            <GenerateCertificate trainingSid={props.trainingSid} />
            {modal ? <Confetti numberOfPieces={100} width={350} height={500} /> : ''}
          </Modal>
        ) : ''}

        <div className="product" style={{ display: "flex" }}>
          {/* {user.role !== ROLE.INSTRUCTOR && (
            <h6 style={{ color: "#3f51b5", fontSize: "14px" }}>
              {props.progress === props.totalSection ? (
                <button onClick={() => setModal(true)}>Get Certificate</button>
              ) : (
                props.label
              )}
              <span><FastForwardIcon /></span>
            </h6>
          )} */}

          <div className="product-text">
            Your overall progress
          </div>
        </div>
      </div>
    </div>
  );
}

const ProgressBar = (props) => {
  const progressBackgroundColor = '#7f56d9';

  return (
    <>
      <Example progress={props.progress} totalSection={props.totalSection} trainingSid={props.trainingSid}>
        {
          props.loaderTraining ?
          <SkeletonLoader skeletonCount={1} />
          :
        <CircularProgressbarWithChildren
          value={props.progress}
          // value={(props.progress / props.totalSection) * 100}
          styles={{
            root: {},
            path: {
              stroke: progressBackgroundColor,
            },
           
          }}
        >
          <div style={{ fontWeight: "700", fontSize: "18px" }}>
            {/* {Math.round((props.progress / props.totalSection) * 100)}% */}
            {props.progress}%
          </div>
        </CircularProgressbarWithChildren>
        }
      </Example>
    </>
  );
}

export default ProgressBar;
