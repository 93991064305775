import React, { useState, useEffect, useContext, useRef, useMemo } from 'react'
import { ICN_ASSESSMT, ICN_EDIT, ICN_EXPAND_NEW, ICN_MORE_DETAILS, ICN_NOTE_EDIT, ICN_OPEN_SANDBOX, ICN_START_ASSESS, ICN_STRAT_SESSION, ICN_COMING_BATCHES, ICN_COPY, REPORT_ICON_BATCH, REPORT_ICON_GO_SOMEWHERE, REPORT_ICON_GROUP, REPORT_ICON_LEARNER, ICN_STD_MATERIAL, ICN_VDO, ICN_LABASS, ICN_CODINGASS, EMAIL_ICON, ICN_TRAINING_SESSION } from '../../Common/Icon';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Checkbox } from '@mui/material';
import { LinearProgress } from '@mui/material';
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Link, useNavigate } from '@reach/router';
import TimerIcon from '@mui/icons-material/Timer';
import ScienceIcon from '@mui/icons-material/Science';
import RestService from '../../../Services/api.service';
import AppContext from '../../../Store/AppContext';
import InfoIcon from '@mui/icons-material/Info';
import LoadingSpinner from '../Training/LoadingSpinner';
import { Button } from '../../Common/Buttons/Buttons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MailIcon from '@mui/icons-material/Mail';
import { WithContext as ReactTags } from "react-tag-input";
import useToast from '../../../Store/ToastHook';
import SendIcon from '@mui/icons-material/Send';
import SkeletonLoader from '../../Common/SkeletonLoader/SkeletonLoader';
import TrainingPercentageTrendChart from './TrainingPercentageTrendChart';
import EnrollmentBarChart from './EnrollmentBarChart';
import ScrollableBarChart from './NewCharts/ScrollableBarChart';

//error component
const ErrorComponent = () => {
    return (
        <div class="row justify-content-center">
            <div class="col-md-12 col-sm-12">
                <div class="card shadow-lg border-0 rounded-lg mt-5 mx-auto" style={{ width: "30rem" }}>
                    <h3 class="card-header display-1 text-muted text-center">
                        404
                    </h3>

                    <span class="card-subtitle mb-2 text-muted text-center">
                        Something went wrong
                    </span>

                    {/* <div class="card-body mx-auto">
                      <button class="btn btn-sm btn-info text-white" onClick={() => navigate("/training", { state: { title: "Training" } })}>
                          Back To Training
                      </button>
                    
                  </div> */}
                </div>
            </div>
        </div>
    )
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal d-block" : "modal d-none";

    return (

        <div className={showHideClassName}  >

            <div className="modal-container modal-xl" >
                <div style={{ float: "right" }} className='circle-md'> <div className="modal-close" onClick={handleClose}>
                    X
                </div></div>
                {children}

            </div>
        </div>
    );
};

const TrainingReport = () => {

    //training
    const [trainingDataAll, setTrainingDataAll] = useState([]);
    const [trainingDetails, setTrainingDetails] = useState({});
    const [trainingHeaderDetails, setTrainingHeaderDetails] = useState({});

    const [reportTrainings, setReportTrainings] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const { setCourse, setBatches, ROLE, course, spinner, user, batches } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [tags, setTags] = useState([]);
    const [loader, setLoader] = useState(true);
    const [reportType, setReportType] = useState('');
    const [trainingCompletionData, setTrainingCompletionData] = useState([]);

    const trainingData = [40, 42, 33, 27, 44, 35];
    const Toast = useToast();
    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    const navigate = useNavigate();



    const openSpecificTraining = () => {
        // Replace 'your_route_here' with the actual route you want to navigate to
        navigate(`/report/training/${trainingDetails.trainingName}`, { state: { progress: trainingDetails, subTitle: `${trainingDetails.trainingName}`, subPath: `${trainingDetails.trainingName}` } });
    };


    const [activeTab, setActiveTab] = useState('learner'); // Set the default active tab
    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    const [counter, setCounter] = useState(20);

    //send mail
    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    const goBack = () => {
        localStorage.removeItem("progress");
    }


    //training table details

    const columnsTraining = useMemo(
        () => [
            // {
            //   accessorKey: 'id',
            //   enableColumnPinning: false,
            //   header: 'ID',
            //   size: 50,
            // },
            {
                accessorKey: 'select',
                header: 'Select',
                Cell: ({ row }) => (
                    <Checkbox
                        checked={selectedRows.includes(row.original.id)}
                        onChange={() => {
    
                            setTrainingDetails(row.original);
                            const newSelectedRows = selectedRows.includes(row.original.id)
                                ? selectedRows.filter((id) => id !== row.original.id)
                                : [...selectedRows, row.original.id];

                            setSelectedRows(newSelectedRows);

                        }}
                    />
                ),
                size: 50,
            },
            {
                accessorKey: 'trainingName',
                header: 'Training',
            },
            {
                accessorKey: 'trainingStatus',
                header: 'Status',
            },
            {
                accessorKey: 'duration',
                header: 'Duration',
                size: 300
            },
            {
                accessorKey: 'traningDurationCompletion',
                header: 'Training Duration Completion',
                Cell: ({ renderedCellValue, row }) => <div

                >{`${renderedCellValue.toFixed(2)}%`}

                </div>
            },
            {
                accessorKey: 'instructor',
                header: 'Instructor',
            },
            {
                accessorKey: 'courseName',
                header: 'Course',
            },
            {
                accessorKey: 'batchCount',
                header: 'Batch Count',
            },
            {
                accessorKey: 'enrollments',
                header: 'Enrollments',
            },
            {
                accessorKey: 'trainedPercentage',
                header: 'Trained Percentage',
            },
            {
                accessorKey: 'passed',
                header: 'Passed'
            },
            {
                accessorKey: 'failed',
                header: 'Failed',
            },
            {
                accessorKey: 'inProgress',
                header: 'Progress',
            },
            {
                accessorKey: 'notStarted',
                header: 'Not Started'
            },
            {
                accessorKey: 'completed',
                header: 'Completed'
            },
            {
                accessorKey: 'overdue',
                header: 'Overdue'
            }
        ],
        [selectedRows],
    );

    const tableTraining = useMaterialReactTable({
        columns: columnsTraining,
        data: trainingDataAll,
        enableColumnPinning: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        layoutMode: 'grid-no-grow',
        initialState: {
            columnPinning: {
                left: ['select', 'trainingName'],
                //  right: ['city'] 
            },

        },
    });



    //export as pdf
    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = async () => {
        const pdf = new jsPDF("portrait", "pt", "a4");
        const data = await html2canvas(document.querySelector("#capture"));
        const img = data.toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        spinner.hide();
        pdf.save("overall_training_report.pdf");

        // html2canvas(document.querySelector("#capture")).then(canvas => {
        //   document.body.appendChild(canvas);  // if you want see your screenshot in body.
        //   const imgData = canvas.toDataURL('image/png');
        //   const pdf = new jsPDF({
        //     format: 'a4',
        //     unit: 'px',
        //   });
        //   pdf.addImage(imgData, 'PNG', 0, 0);
        //   pdf.save("download.pdf");
        // });
        // const doc = new jsPDF({
        //   format: 'a4',
        //   unit: 'px',
        // });

        // Adding the fonts.
        // doc.setFont('Inter-Regular', 'normal');

        // doc.html(reportTemplateRef.current, {
        //   async callback(doc) {
        //     await doc.save('document');
        //   },
        // });
        // doc.html(document.body, {
        //   callback: function () {
        //     doc.save('page');
        //   },
        // });
    };


    //send mail
    const sendSupervisorReportMail = () => {
        try {
            spinner.show();
            let tagsCsv = tags.map((item) => item.text);
            const payload = {
                "recipients": tagsCsv,
                "reportType": "SUPERVISOR_MAIN"
            }
            RestService.sendSupervisorReportMail(payload).then(
                response => {
                    Toast.success({ message: `Mail Sent Successfully`, time: 2000 });
                    setTags([])
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: `Try Again`, time: 2000 });
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on sendSupervisorReportMail()", err)
        }
    }

    //all training details
    const allTrainingScreenDetails = () => {
        try {
            // spinner.show();
            RestService.allTrainingScreenDetails().then(
                response => {
                    if (response.status === 200) {
                        const updatedData = response.data.trainingDetails.map((item, index) => ({
                            ...item,
                            id: index + 1,
                        }));
                        const trainingCompletionData = response.data.trainingDetails.map(i => [`${i.trainingName}`, parseFloat(i.traningDurationCompletion.toFixed(2))]);

                        setTrainingHeaderDetails(response.data.headerDetails);
                        setTrainingDataAll(updatedData);
 
                        setTrainingCompletionData(trainingCompletionData);
                        setTimeout(() => {
                            setLoader(false);
                        }, 2000);
                    }
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: `Try Again`, time: 2000 });
                }
            )
        } catch (err) {
            console.error("error occur on allTrainingScreenDetails()", err)
        }
    }



    useEffect(() => {
        allTrainingScreenDetails();
    }, []);


    return (
        <div>
            {
                !isLoading ?

                    <ErrorComponent />
                    :
                    <>
                        {/* New ui */}
                        <div class="tabset" >
                            <nav aria-label="breadcrumb " className='bbottom pb-2 ' >
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><Link to="/report" onClick={() => goBack()} state={{ title: "Report" }}>Reports</Link></li>
                                    {/* <li class="breadcrumb-item"><Link  onClick={() => goBack()} state={{ title: "Learner Progress" }}>Learner Progress</Link></li> */}

                                    {/* <li class="breadcrumb-item active" aria-current="page">{props.location.state.learnerName}</li> */}

                                </ol>
                            </nav>


                            <div class="tab-panels">


                                {/* Training report */}

                                <div className='specificreportheader my-4'>Training Progress</div>

                                <div className='row ' style={{ justifyContent: "space-between" }}>

                                    <div className='col-md-6 '>
                                        {
                                            loader ?
                                                <SkeletonLoader skeletonCount={2.5} />
                                                :
                                                <div className='reportcard'>
                                                    <ScrollableBarChart trainingCompletionData={trainingCompletionData} />
                                                </div>
                                        }



                                    </div>
                                    {/* <div className='col-md-3 '>
                                        {
                                            loader ?
                                                <SkeletonLoader skeletonCount={2.5} />
                                                :
                                                <div className='reportcard'>
                                                    <TrainingPercentageTrendChart data={trainingData} />
                                                </div>
                                        }



                                    </div> */}
                                    {/* <div className='col-md-3'>
                                        {
                                            loader ?
                                                <SkeletonLoader skeletonCount={2.5} />
                                                :
                                                <div className='reportcard'>
                                                    <EnrollmentBarChart />
                                                </div>
                                        }

                                    </div> */}
                                    <div className='col-md-6'>
                                        {
                                            loader ?
                                                <SkeletonLoader skeletonCount={2.7} />
                                                :
                                                <div className='reportcard d-flex h-100 ' style={{ justifyContent: "space-between" }}>

                                                    <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Passed

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                trainingHeaderDetails?.passed
                                                            }
                                                        </div>
                                                    </div>


                                                    <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Failed

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                trainingHeaderDetails?.failed
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            In Progress

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                trainingHeaderDetails?.inProgress
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='d-flex m-auto px-1' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Completed

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                trainingHeaderDetails?.completed
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='d-flex m-auto px-1 mx-1' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Overdue

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                trainingHeaderDetails?.overdue
                                                            }
                                                        </div>
                                                    </div>


                                                </div>
                                        }
                                    </div>


                                </div>

                                <div className='my-4'>

                                    <div className='d-flex' style={{ justifyContent: "space-between" }}>
                                        <div className='mb-2'>
                                            {
                                                selectedRows.length === 1 &&

                                                <>
                                                    <button className='emailbtn' onClick={openSpecificTraining}>Training Results</button>

                                                </>
                                            }
                                        </div>

                                    </div>
                                    {

                                        loader ?
                                            <SkeletonLoader skeletonCount={10} />
                                            :
                                            trainingDataAll.length !== 0 &&
                                            <MaterialReactTable table={tableTraining} />
                                    }


                                </div>

                            </div>

                        </div>
                    </>


            }

        </div>
    )
}

export default TrainingReport