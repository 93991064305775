import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Text
} from 'recharts';

const CustomizedAxisTick = ({ x, y, stroke, payload }) => {
    return (
        <Text x={x} y={y + 15} fill="#666" textAnchor="end" angle={-35}>
            {payload.value}
        </Text>
    );
};

export const StackedBarChartComponent = ({stackBarReport}) => {

    // Example data
    const data = [
        {
            contentTypes: 'Document',
            Total: 80,
            Completed: 60
        },
        {
            contentTypes: 'Video',
            Total: 85,
            Completed: 65

        },
        {
            contentTypes: 'Practice',
            Total: 78,
            Completed: 68
        },
        {
            contentTypes: 'Assessment Lab',
            Total: 78,
            Completed: 68
        },
        {
            contentTypes: 'MCQ',
            Total: 78,
            Completed: 68
        },
        {
            contentTypes: 'Coding Assessment',
            Total: 78,
            Completed: 68
        },
        // Add as many types as you have in your data
    ];

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart
                data={stackBarReport}
                margin={{
                    top: 20, right: 30, left: 20, bottom: 35, // Increase bottom margin if needed
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="contentTypes"
                    interval={0}
                    tick={<CustomizedAxisTick />} // Custom tick component
                    height={60} // Adjust height to give more space for rotated labels
                />
                <YAxis />
                <Tooltip />
                <Legend verticalAlign='top' height={36}/>
                <Bar dataKey="Total" stackId="a" fill="#7F56D9" />
                <Bar dataKey="Completed" stackId="a" fill="#82ca9d" />

            </BarChart>
        </ResponsiveContainer>
    );

}
