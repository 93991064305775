import React, { useState, useContext, useEffect, useCallback } from 'react';
import AppContext from '../../../../Store/AppContext';
import RestService from '../../../../Services/api.service';
import { PieChart, Pie, Sector, Line } from "recharts";
import { FILTER_ICON } from '../../Icon';


import $ from 'jquery';
import SkeletonLoader from '../../SkeletonLoader/SkeletonLoader';

const Trainingpichart = () => {

  const [completedTraining, setCompletedTraining] = useState('');
  const [onGoingTraining, setOnGoingTraining] = useState('');
  const [upComingTraining, setUpComingTraining] = useState('');

  const [activeIndex, setActiveIndex] = useState(0);
  const { spinner } = useContext(AppContext);

  var lastWeek = getLastWeek();
  var lastWeekMonth = (lastWeek.getMonth() + 1).toString();
  lastWeekMonth = lastWeekMonth.length === 1 ? '0' + lastWeekMonth : lastWeekMonth;
  var lastWeekDay = (lastWeek.getDate()).toString();
  lastWeekDay = lastWeekDay.length === 1 ? '0' + lastWeekDay : lastWeekDay;
  var lastWeekYear = lastWeek.getFullYear();
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + '-' + mm + '-' + dd;

  var lastWeekDisplay = lastWeekYear + "-" + lastWeekMonth + "-" + lastWeekDay;
  const [fromDate, setFromDate] = useState(lastWeekDisplay);
  const [toDate, setToDate] = useState(today);
  const [loader, setLoader] = useState(true);

  

  //filter training based on date
  const filterTrainingsBasedOnDateRange = () => {
    try {

      // spinner.show();
      RestService.filterTrainingsBasedOnDateRange(fromDate, toDate).then(
        response => {
          if (response.status === 200) {
            setCompletedTraining(response.data.COMPLETED.length);
            setOnGoingTraining(response.data.ONGOING.length);
            setUpComingTraining(response.data.UPCOMING.length);
            setLoader(false);
          }

        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on filterTrainingsBasedOnDateRange()", err)
    }
  }

  const onPieEnter = useCallback(
    (_, index) => {
      setActiveIndex(index);
    },
    [setActiveIndex]
  );

  const data = [
    { name: "Completed", value: completedTraining, fill: "#BFCBF7" },
    { name: "Ongoing", value: onGoingTraining, fill: "#C8A3DF" },
    { name: "Upcoming", value: upComingTraining, fill: "#D0EFFA" }
  ];
  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path
          d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
          stroke={fill}
          fill="none"
        />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill="#333"
        >{`Total ${value}`}</text>
        {/* <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill="#999"
        >
          {`(Rate ${(percent * 100).toFixed(2)}%)`}
        </text> */}
      </g>
    );
  }
  function getLastWeek() {
    var today = new Date();
    var lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return lastWeek;
  }

  useEffect(() => {
    filterTrainingsBasedOnDateRange();
  }, [])




  $(function () {
    $("#datepicker").datepicker({
      format: 'mm-dd-yyyy',
      endDate: '+0d',
      autoclose: true
    });
  })

  return (
    <>
      <div className='row p-1'>
        <div className='col-5'>
        <label htmlFor="from" className='title-sm'>From Date:</label>
          {
            loader ?
              <SkeletonLoader skeletonCount={1} />
              :
              <input
                type="date"
                className='form-control'
                value={fromDate}
                max={toDate} // Ensure the maximum value is set
                defaultValue={lastWeekDisplay}
                onChange={(e) => setFromDate(e.target.value)}
                id="fromDatepicker"
              />
          }
        </div>
        <div className='col-5'>
        <label htmlFor="to" className='title-sm'>To Date:</label>
          {
            loader ?
              <SkeletonLoader skeletonCount={1} />
              :
              <input
                type="date"
                className='form-control'
                value={toDate}
                min={fromDate} // Ensure the minimum value is set
                defaultValue={today}
                onChange={(e) => setToDate(e.target.value)}
                id="toDatepicker"
              />
          }


        </div>
        <div className='col-2 mt-4'>
          {
            loader ? <SkeletonLoader skeletonCount={1} />
              :
              <button className=' btn btn-primary mt-1' onClick={() => filterTrainingsBasedOnDateRange()}>{FILTER_ICON}</button>
          }

        </div>
        {
          loader ?
          <SkeletonLoader skeletonCount={1} />
          :
          <div className='title-sm m-3'>
            Note : Please select Date to Filter Training Details
          </div>
        }
      </div>

      <PieChart width={400} height={250}>
        <Pie
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          data={data}
          cx={200}
          cy={150}
          innerRadius={60}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
          onMouseEnter={onPieEnter}
        />
        <Line name="pv of pages" type="monotone" dataKey="value" stroke="#8884d8" />
        <Line name="uv of pages" type="monotone" dataKey="value" stroke="#82ca9d" />
      </PieChart>
    </>

  )
}

export default Trainingpichart