import React, { useEffect, useState, useRef } from "react";
import "./labuser.css";
import RestService from "../../../Services/api.service";
import SkeletonLoader from "../../Common/SkeletonLoader/SkeletonLoader";
import { Modal, Spinner } from "react-bootstrap";
import { ICN_CLOSE } from "../../Common/Icon";
import useToast from "../../../Store/ToastHook";
import DOMPurify from 'dompurify';
import { Document, Page, pdfjs } from 'react-pdf'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const LabUser = () => {

  pdfjs.GlobalWorkerOptions.workerSrc =
    `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [labList, setLabList] = useState([]);
  const [htmlContent, setHtmlContent] = useState("");
  const [viewLoader, setViewLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedLab, setSelectedLab] = useState("");
  const [selectedFileUrl, setSelectedFileUrl] = useState("");
  const [fileType, setFileType] = useState("");
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(true);
  const [affiliation, setAffiliation] = useState('Company');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [companyName, setCompanyName] = useState('Gnosislabs');
  const [jobTitle, setJobTitle] = useState('');
  const [jobLevel, setJobLevel] = useState('');
  const [companySize, setCompanySize] = useState('15-20');
  const [traineeSize, setTraineeSize] = useState('15-20');
  const [organizationTrainingNeeds, setOrganizationTrainingNeeds] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const Toast = useToast();
  const topRef = useRef(null);

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);
    const isValid = /^[A-Za-z]+$/.test(value);
    setFirstNameError(isValid ? '' : 'Only alphabetic characters');
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
    setEmailError(isValidEmail ? '' : 'Invalid email address');
  };

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    const isValidLength = value.length === 10;
    const containsOnlyNumbers = /^\d+$/.test(value);
    if (!containsOnlyNumbers) {
      setPhoneNumberError('Phone number must contain only digits');
    } else if (!isValidLength) {
      setPhoneNumberError('Phone number must be exactly 10 digits');
    } else {
      setPhoneNumberError('');
    }
  };

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const changePage = (offset) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  };

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const contactUsForm = () => {
    setLoading(true);
    try {
      let payload = {
        affiliation,
        companyName,
        companySize,
        email,
        firstName,
        jobLevel,
        jobTitle,
        lastName,
        organizationTrainingNeeds,
        phoneNumber,
        traineeSize
      };

      RestService.contactUsForm(payload).then(res => {
        setEmail('');
        setFirstName('');
        setJobLevel('');
        setJobTitle('');
        setLastName('');
        setPhoneNumber('');
        setLoading(false);
        setShow(false);
        Toast.success({ message: res.data, time: 2000 });
      }, err => {
        setLoading(false);
      });
    } catch (err) {
      console.error();
    }
  };

  const handleLabClick = async (labName, fileUrl) => {
    setViewLoader(true);
    const type = fileUrl.split('.').pop().toLowerCase();
    setSelectedLab(labName);
    setFileType(type);

    if (type === 'pdf') {
      setSelectedFileUrl(fileUrl);
      setHtmlContent('');
    } else if (type === 'html') {
      setSelectedFileUrl('');
      try {
        const response = await fetch(fileUrl);
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const html = await response.text();
        const cleanHtml = DOMPurify.sanitize(html, {
          ALLOWED_TAGS: [
            'b', 'i', 'em', 'strong', 'a', 'p', 'div', 'span', 'style', 'br',
            'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li', 'img', 'table',
            'thead', 'tbody', 'tr', 'th', 'td', 'blockquote', 'pre', 'code'
          ],
          ALLOWED_ATTR: [
            'href', 'title', 'style', 'src', 'alt', 'class', 'id', 'name', 'value',
            'width', 'height', 'align', 'target', 'rel'
          ]
        });
        setHtmlContent(cleanHtml);
      } catch (error) {
        setHtmlContent('');
        console.error('Error fetching HTML content:', error);
      }
    }

    setTimeout(() => {
      setViewLoader(false);
    }, 1500);
  };

  const getLabUsersLabs = () => {
    try {
      RestService.getLabUsersLabs().then(res => {
        if (res.status === 200) {
          const combineArray = Object.values(res.data).flat();
          setLabList(combineArray);
          setTimeout(() => {
            setLoader(false);
          }, 1500);
        }
      }, err => {
        setTimeout(() => {
          setLoader(false);
        }, 1500);
      });
    } catch (err) {
      console.error('error occur on getLabUsersLabs', err);
    }
  };

  useEffect(() => {
    getLabUsersLabs();
  }, []);

  useEffect(() => {
    if (show) {
      setEmail('');
      setFirstName('');
      setJobLevel('');
      setJobTitle('');
      setLastName('');
      setPhoneNumber('');
      setFirstNameError('');
      setEmailError('');
      setPhoneNumberError('');
    }
  }, [show]);

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleCopy = (e) => {
      e.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('copy', handleCopy);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('copy', handleCopy);
    };
  }, []);

  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [pageNumber, selectedLab]);


  return (
    <>
      {loader ? (
        <SkeletonLoader skeletonCount={10} />
      ) : (
        <div ref={topRef} className="row" style={{ height: "100vh" }}>
          <div className="col-md-4 col-sm-4 col-lg-4 col-xl-4">
            <div className="labname-filter" style={{ minHeight: "100vh", overflowY: "auto" }}>
              {labList?.map((lab) => (
                <div
                  key={lab.id}
                  className={`labname-in ${selectedLab === lab.fileName ? 'selected' : ''}`}
                  onClick={() => handleLabClick(lab.fileName, lab.fileUrl)}
                >
                  {lab.fileName}
                </div>
              ))}
            </div>
          </div>

          <div className="col-md-8 col-sm-8 col-lg-8 col-xl-8">
            {selectedLab?.length === 0 ? (
              <div className="no-lab-message" style={{ background: "#f9f5ff" }}>
                <p>Please select a lab to view its content.</p>
              </div>
            ) : viewLoader ? (
              <SkeletonLoader skeletonCount={9.5} />
            ) : selectedLab && (
              <>
                <div>
                  <div className="labname-in" style={{ padding: "0px 0px 10px 0px" }}>{selectedLab}</div>
                  {fileType === 'pdf' ? (
                    <div>
                      <div className="document-container">
                        <Document
                          file={selectedFileUrl}
                          onLoadSuccess={onDocumentLoadSuccess}
                          className="react-pdf__Document"
                        >
                          <Page pageNumber={pageNumber} className="page" />
                        </Document>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div className="title-md">
                          Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                        </div>
                        <div>
                          <button
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                            className="Pre mx-4"
                          >
                            <ArrowBackIosNewIcon fontSize="small" />
                          </button>
                          <button
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                          >
                            <ArrowForwardIosIcon fontSize="small" />
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : fileType === 'html' && (
                    <div className="labname-filter" style={{ maxHeight: '450px', overflowY: 'auto' }}>
                      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                    </div>
                  )}
                </div>

                <div className="d-flex justify-content-end mt-3">
                  <button className="create-lab-user-btn mx-3" onClick={() => setShow(true)}>Practice Assessment</button>
                  <button className="lab-user-archive" onClick={() => setShow(true)}>Buy Environment</button>
                </div>
              </>
            )}
          </div>
        </div>
      )}

      <Modal
        size="lg"
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-100w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body className="px-5 py-4">
          <div className="jcb mb-3">
            <div className="title-md">Practice Assessment</div>
            <div>
              <div className="circle-md" onClick={() => setShow(false)}>
                {ICN_CLOSE}
              </div>
            </div>
          </div>

          <div className="signup-form">
            <div className="row">
              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>First Name<sup style={{ fontSize: "14px" }}>*</sup></label>
                  <div className="input-group">
                    <input
                      type="text"
                      onChange={handleFirstNameChange}
                      value={firstName}
                      className="form-control"
                      name="firstName"
                      placeholder="First Name"
                      required
                      style={{ borderRadius: "8px" }}
                    />
                  </div>
                  {firstNameError && <div className="error-message text-danger">{firstNameError}</div>}
                </div>
              </div>

              <div className="col-md-6 col-lg-6 col-sm-12">
                <div className="form-group">
                  <label>Last Name</label>
                  <div className="input-group">
                    <input
                      type="text"
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      className="form-control"
                      name="username"
                      placeholder="Last Name"
                      style={{ borderRadius: "8px" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="form-group mt-3">
              <label>Email<sup style={{ fontSize: "14px" }}>*</sup></label>
              <div className="input-group">
                <input
                  type="email"
                  onChange={handleEmailChange}
                  className="form-control"
                  name="email"
                  placeholder="Email Address"
                  value={email}
                  style={{ borderRadius: "8px" }}
                />
              </div>
              {emailError && <div className="error-message text-danger">{emailError}</div>}
            </div>
            <div className="form-group mt-3">
              <label>Phone Number<sup style={{ fontSize: "14px" }}>*</sup></label>
              <div className="input-group">
                <input
                  type="text"
                  inputMode="numeric"
                  onChange={handlePhoneNumberChange}
                  value={phoneNumber}
                  className="form-control"
                  name="phoneNumber"
                  placeholder="Enter your Number"
                  style={{ borderRadius: "8px" }}
                />
              </div>
              {phoneNumberError && <div className="error-message text-danger">{phoneNumberError}</div>}
            </div>

            <div className="form-group mt-3">
              <label>Job Title</label>
              <div className="input-group">
                <input
                  type="text"
                  onChange={(e) => setJobTitle(e.target.value)}
                  value={jobTitle}
                  className="form-control"
                  name="jobTitle"
                  placeholder="Job Title"
                  required="required"
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </div>
            <div className="form-group mt-3">
              <label>Job level</label>
              <div className="input-group">
                <input
                  type="text"
                  onChange={(e) => setJobLevel(e.target.value)}
                  value={jobLevel}
                  className="form-control"
                  name="jobLevel"
                  placeholder="Job Level"
                  required="required"
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </div>
            <div className="form-group mt-3">
              <button
                type="submit"
                className="create-lab-user-btn"
                onClick={() => contactUsForm()}
                disabled={
                  firstName.length === 0 || email.length === 0 ||
                  phoneNumber.length === 0 || firstNameError.length > 0 ||
                  emailError.length > 0 || phoneNumberError.length > 0
                }
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="md"
                    role="status"
                    aria-hidden="true"
                    className="mx-2 mt-1 loader-button"
                    style={{ border: '2px dotted #ffffff' }}
                  />
                ) : (
                  'Submit'
                )}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LabUser;
