import React, { useState, useEffect, useContext, useRef, useMemo } from 'react'
import { ICN_ASSESSMT, ICN_EDIT, ICN_EXPAND_NEW, ICN_BACK, ICN_MORE_DETAILS, ICN_NOTE_EDIT, ICN_OPEN_SANDBOX, ICN_START_ASSESS, ICN_STRAT_SESSION, ICN_COMING_BATCHES, ICN_COPY, REPORT_ICON_BATCH, REPORT_ICON_GO_SOMEWHERE, REPORT_ICON_GROUP, REPORT_ICON_LEARNER, ICN_STD_MATERIAL, ICN_VDO, ICN_LABASS, ICN_CODINGASS, EMAIL_ICON } from '../../Common/Icon';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Checkbox } from '@mui/material';
import { LinearProgress } from '@mui/material';
import "react-tooltip/dist/react-tooltip.css";
import { Link, useNavigate } from '@reach/router';
import TimerIcon from '@mui/icons-material/Timer';
import ScienceIcon from '@mui/icons-material/Science';
import RestService from '../../../Services/api.service';
import AppContext from '../../../Store/AppContext';
import InfoIcon from '@mui/icons-material/Info';
import LoadingSpinner from '../Training/LoadingSpinner';
import { Button } from '../../Common/Buttons/Buttons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MailIcon from '@mui/icons-material/Mail';
import { WithContext as ReactTags } from "react-tag-input";
import useToast from '../../../Store/ToastHook';
import SendIcon from '@mui/icons-material/Send';
import SkeletonLoader from '../../Common/SkeletonLoader/SkeletonLoader';
import { Tooltip } from "react-tooltip";
import TrainingPercentageTrendChart from './TrainingPercentageTrendChart';
import AverageAssessmentPercentage from './AverageAssessmentPercentage';
import BarChartComponent from './NewCharts/CompletionBarChart';
import Select from 'react-select';

//error component
const ErrorComponent = () => {
    return (
        <div class="row justify-content-center">
            <div class="col-md-12 col-sm-12">
                <div class="card shadow-lg border-0 rounded-lg mt-5 mx-auto" style={{ width: "30rem" }}>
                    <h3 class="card-header display-1 text-muted text-center">
                        404
                    </h3>

                    <span class="card-subtitle mb-2 text-muted text-center">
                        Something went wrong
                    </span>

                    {/* <div class="card-body mx-auto">
                      <button class="btn btn-sm btn-info text-white" onClick={() => navigate("/training", { state: { title: "Training" } })}>
                          Back To Training
                      </button>
                    
                  </div> */}
                </div>
            </div>
        </div>
    )
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal d-block" : "modal d-none";

    return (

        <div className={showHideClassName}  >

            <div className="modal-container modal-xl" >
                <div style={{ float: "right" }} className='circle-md'> <div className="modal-close" onClick={handleClose}>
                    X
                </div></div>
                {children}

            </div>
        </div>
    );
};

const NewTrainingSpecificReport = (props) => {

    //learner
    const [data, setData] = useState([]);
    const [trainingHeaderDetails, setTrainingHeaderDetails] = useState({});

    const [reportTrainings, setReportTrainings] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const { setCourse, setBatches, ROLE, course, spinner, user, batches } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [tags, setTags] = useState([]);
    const [loader, setLoader] = useState(true);
    const Toast = useToast();
    const [totalContent, setTotalContent] = useState([]);
    const [learnerDropDown, setLearnerDropDown] = useState([]);

    const [selectedOption, setSelectedOption] = useState(null);
    const [totalContent1, setTotalContent1] = useState([]);

    const trainingData = [28, 29, 31, 33, 30, 32];
    const trainingAssessmentData = [10, 11, 10, 11, 11, 11, 12, 13, 12, 11];
    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('learner'); // Set the default active tab
    const [counter, setCounter] = useState(20); const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    //send mail
    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    const goBack = () => {
        localStorage.removeItem("progress");
    }

    const previousroute = () => {
        // Navigate to the previous page
        navigate(-1);
    };

    const handleSelectChange = (selectedOption) => {
        // Do something with the selected option
        setSelectedOption(selectedOption);

    };

    const options = [

        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' },
    ];

    //learner table details

    const columns = useMemo(
        () => [
            // {
            //     accessorKey: 'id',
            //     enableColumnPinning: false,
            //     header: 'ID',
            //     size: 50,
            // },
            {
                accessorKey: 'select',
                header: 'Select',
                Cell: ({ row }) => (
                    <Checkbox
                        checked={selectedRows.includes(row.original.id)}
                        onChange={() => {
                            const newSelectedRows = selectedRows.includes(row.original.id)
                                ? selectedRows.filter((id) => id !== row.original.id)
                                : [...selectedRows, row.original.id];

                            setSelectedRows(newSelectedRows);
                        }}
                    />
                ),
                size: 50,
            },
            {
                accessorKey: 'learnerName',
                header: 'Name',
            },
            {
                accessorKey: 'learnerEmail',
                header: 'Learner Email',
            },
            {
                accessorKey: 'notStarted',
                header: 'Not Started',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue ? 'Yes' : 'No'}</>
            },
            {
                accessorKey: 'inProgress',
                header: 'In Progress',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue ? 'Yes' : 'No'}</>
            },
            {
                accessorKey: 'completed',
                header: 'Completed',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue ? 'Yes' : 'No'}</>
            },
            {
                accessorKey: 'overdue',
                header: 'Overdue',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue ? 'Yes' : 'No'}</>
            },
            {
                accessorKey: 'passed',
                header: 'Passed',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue ? 'Yes' : 'No'}</>
            },
            {
                accessorKey: 'failed',
                header: 'Failed',
                Cell: ({ renderedCellValue }) => <>{renderedCellValue ? 'Yes' : 'No'}</>
            },
            {
                accessorKey: 'avgAssessmentScore',
                header: 'Avg AssessmentScore',
                Cell: ({ renderedCellValue }) => <>{Math.round(renderedCellValue)}</>
            },
            {
                accessorKey: 'totalContent',
                header: 'Total Content'
            },
            {
                accessorKey: 'documents',
                header: 'Documents',
                Cell: ({ renderedCellValue, row }) => <div
                    data-tooltip-id="contentname" data-tooltip-content={Math.round(row.original.documentCompletion)}
                >{renderedCellValue}
                    <Tooltip
                        id="contentname"
                        data-tooltip-place="right"
                        variant="info"
                    />
                </div>
            },
            {
                accessorKey: 'videos',
                header: 'Videos',
                Cell: ({ renderedCellValue, row }) => <div
                    data-tooltip-id="contentname" data-tooltip-content={Math.round(row.original.videoCompletion)}
                >{renderedCellValue}
                    <Tooltip
                        id="contentname"
                        data-tooltip-place="right"
                        variant="info"
                    />
                </div>
            },
            {
                accessorKey: 'practiceLabs',
                header: 'Practice Labs',
                Cell: ({ renderedCellValue, row }) => <div
                    data-tooltip-id="contentname" data-tooltip-content={Math.round(row.original.practiceLabCompletion)}
                >{renderedCellValue}
                    <Tooltip
                        id="contentname"
                        data-tooltip-place="right"
                        variant="info"
                    />
                </div>
            },
            {
                accessorKey: 'assessmentLabs',
                header: 'Assessment Labs',
                Cell: ({ renderedCellValue, row }) => <div
                    data-tooltip-id="contentname" data-tooltip-content={Math.round(row.original.assessmentLabCompletion)}
                >{renderedCellValue}
                    <Tooltip
                        id="contentname"
                        data-tooltip-place="right"
                        variant="info"
                    />
                </div>
            },
            {
                accessorKey: 'mcqAssessments',
                header: 'Mcq Assessments',
                Cell: ({ renderedCellValue, row }) => <div
                    data-tooltip-id="contentname" data-tooltip-content={Math.round(row.original.mcqAssessmentCompletion)}
                >{renderedCellValue}
                    <Tooltip
                        id="contentname"
                        data-tooltip-place="right"
                        variant="info"
                    />
                </div>
            },
            {
                accessorKey: 'codingAssessments',
                header: 'Coding Assessments',
                Cell: ({ renderedCellValue, row }) => <div
                    data-tooltip-id="contentname" data-tooltip-content={Math.round(row.original.codingAssessmentCompletion)}
                >{renderedCellValue}
                    <Tooltip
                        id="contentname"
                        data-tooltip-place="right"
                        variant="info"
                    />
                </div>
            }

        ],
        [selectedRows],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnPinning: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        layoutMode: 'grid-no-grow',
        initialState: {
            columnPinning: {
                left: ['select', 'learnerName'],
                //  right: ['city'] 
            },

        },
    });


    //export as pdf
    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = async () => {
        const pdf = new jsPDF("portrait", "pt", "a4");
        const data = await html2canvas(document.querySelector("#capture"));
        const img = data.toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        spinner.hide();
        pdf.save("overall_training_report.pdf");

        // html2canvas(document.querySelector("#capture")).then(canvas => {
        //   document.body.appendChild(canvas);  // if you want see your screenshot in body.
        //   const imgData = canvas.toDataURL('image/png');
        //   const pdf = new jsPDF({
        //     format: 'a4',
        //     unit: 'px',
        //   });
        //   pdf.addImage(imgData, 'PNG', 0, 0);
        //   pdf.save("download.pdf");
        // });
        // const doc = new jsPDF({
        //   format: 'a4',
        //   unit: 'px',
        // });

        // Adding the fonts.
        // doc.setFont('Inter-Regular', 'normal');

        // doc.html(reportTemplateRef.current, {
        //   async callback(doc) {
        //     await doc.save('document');
        //   },
        // });
        // doc.html(document.body, {
        //   callback: function () {
        //     doc.save('page');
        //   },
        // });
    };


    //send mail
    const sendSupervisorReportMail = () => {
        try {
            spinner.show();
            let tagsCsv = tags.map((item) => item.text);
            const payload = {
                "recipients": tagsCsv,
                "reportType": "SUPERVISOR_MAIN"
            }
            RestService.sendSupervisorReportMail(payload).then(
                response => {
                    Toast.success({ message: `Mail Sent Successfully`, time: 2000 });
                    setTags([])
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: `Try Again`, time: 2000 });
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on sendSupervisorReportMail()", err)
        }
    }

    //training specific details
    const allTrainingTrainingScreenDetails = () => {
        try {
            // spinner.show();
            const trainingSid = props.location.state.progress.trainingSid;
            RestService.allTrainingTrainingScreenDetails(trainingSid).then(
                response => {
                    if (response.status === 200) {
                        const updatedData = response.data.trainingDetails.map(item => ({
                            ...item,
                            ...item.contenDetails,
                            id: item.learnerSid,
                            contenDetails: null
                        }));
                        const learnerDropDown = response.data.trainingDetails.map(learner => ({

                            "label": learner.learnerName,
                            "value": learner.learnerSid
                        }))
                        // { label: batches[i].name, value: batches[i].sid }
                        const totalContent = {
                            "documentCompletionPercentage": Math.round(response.data.totalContent.documentCompletionPercentage),
                            "videoCompletionPercentage": Math.round(response.data.totalContent.videoCompletionPercentage),
                            "practiceLabCompletionPercentage": Math.round(response.data.totalContent.practiceLabCompletionPercentage),
                            "assessmentLabCompletionPercentage": Math.round(response.data.totalContent.assessmentLabCompletionPercentage),
                            "mcqCompletionPercentage": Math.round(response.data.totalContent.mcqCompletionPercentage),
                            "codingAssessmentCompletionPercentage": Math.round(response.data.totalContent.codingAssessmentCompletionPercentage)
                        }
                        setData(updatedData);
                        setTotalContent(Object.values(totalContent));
                        setTrainingHeaderDetails(response.data.headerDetails);
                        setLearnerDropDown(learnerDropDown);
                        setTimeout(() => {
                            setLoader(false);
                        }, 2000);
                    }
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: `Try Again`, time: 2000 });
                }
            )
        } catch (err) {
            console.error("error occur on allTrainingTrainingScreenDetails()", err)
        }
    }

    const allTrainingTrainingScreenDetailsFilter = () => {
        try {
            // spinner.show();
            const learnerSid = selectedOption.value;
            const trainingSid = props.location.state.progress.trainingSid;
            RestService.allTrainingTrainingScreenDetailsFilter(learnerSid, trainingSid).then(
                response => {
                    if (response.status === 200) {
                        const totalContent = {
                            "documentCompletionPercentage": Math.round(response.data.contenDetails.documentCompletion),
                            "videoCompletionPercentage": Math.round(response.data.contenDetails.videoCompletion),
                            "practiceLabCompletionPercentage": Math.round(response.data.contenDetails.practiceLabCompletion),
                            "assessmentLabCompletionPercentage": Math.round(response.data.contenDetails.assessmentLabCompletion),
                            "mcqCompletionPercentage": Math.round(response.data.contenDetails.mcqAssessmentCompletion),
                            "codingAssessmentCompletionPercentage": Math.round(response.data.contenDetails.codingAssessmentCompletion)
                        }

                        setTotalContent(Object.values(totalContent));
                    }
                },
                err => {
                    console.error(err);
                    // spinner.hide();
                    // Toast.error({ message: `Try Again`, time: 2000 });
                }
            )
        } catch (err) {
            console.error("error occur on allTrainingTrainingScreenDetailsFilter()", err)
        }
    }

    useEffect(() => {
        if (selectedOption !== null) {
            allTrainingTrainingScreenDetailsFilter();
        }
    }, [selectedOption]);

    useEffect(() => {
        allTrainingTrainingScreenDetails();
    }, []);

    return (
        <div>
            {
                !isLoading ?

                    <ErrorComponent />
                    :
                    <>
                        <div id="capture">
                            <div className="p-3">

                                <nav aria-label="breadcrumb " className='bbottom pb-2 ' >
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to="/report" onClick={() => goBack()} state={{ title: "Report" }}>Reports</Link></li>
                                        {/* <li class="breadcrumb-item"><Link  onClick={() => goBack()} state={{ title: "Learner Progress" }}>Learner Progress</Link></li> */}

                                        <li class="breadcrumb-item active" aria-current="page">{props.location.state.progress.trainingName}</li>

                                    </ol>
                                </nav>

                                <div className='specificreportheader my-4'><span onClick={previousroute} className='c-pointer'>{ICN_BACK}</span>Training Results</div>

                                <div className='row ' style={{ justifyContent: "space-between" }}>

                                    <div className='col-md-6'>
                                        {
                                            loader ?
                                                <SkeletonLoader skeletonCount={2.7} />
                                                :

                                                <div className='reportcard'>
                                                    
                                                    <Select
                                                        defaultValue={selectedOption}
                                                        onChange={setSelectedOption}
                                                        options={learnerDropDown}
                                                    />
                                                    <BarChartComponent totalContent={totalContent} learnerDropDown={learnerDropDown} trainingSid={props.location.state.progress.trainingSid} />
                                                </div>
                                        }

                                    </div>
                                    {/* <div className='col-md-3'>
                                        {
                                            loader ?
                                                <SkeletonLoader skeletonCount={2.7} />
                                                :

                                                <div className='reportcard'>
                                                    <TrainingPercentageTrendChart data={trainingData} />
                                                </div>
                                        }

                                    </div>
                                    <div className='col-md-3 '>
                                        {
                                            loader ?
                                                <SkeletonLoader skeletonCount={2.7} />
                                                :

                                                <div className='reportcard'>
                                                    <AverageAssessmentPercentage newData={trainingAssessmentData} />
                                                </div>
                                        }

                                    </div> */}
                                    <div className='col-md-6'>
                                        {
                                            loader ?
                                                <SkeletonLoader skeletonCount={2.7} />
                                                :
                                                <div className='reportcard d-flex h-100 ' style={{ justifyContent: "space-between" }}>

                                                    <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Passed

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                trainingHeaderDetails?.passed
                                                            }
                                                        </div>
                                                    </div>


                                                    <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Failed

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                trainingHeaderDetails?.failed
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            In Progress

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                trainingHeaderDetails?.inProgress
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='d-flex m-auto px-1' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Completed

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                trainingHeaderDetails?.completed
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='d-flex m-auto px-1 mx-1' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Overdue

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                trainingHeaderDetails?.overdue
                                                            }
                                                        </div>
                                                    </div>


                                                </div>
                                        }
                                    </div>


                                </div>

                            </div>
                            {

                                loader ?
                                    <SkeletonLoader skeletonCount={10} />
                                    : data.length !== 0 &&
                                    <MaterialReactTable table={table} />
                            }
                        </div>
                    </>


            }

        </div>
    )
}

export default NewTrainingSpecificReport

