import React, { useState, useContext, useEffect } from 'react'
import { BtnPrimary, TabBtn } from "../../Common/Buttons/Buttons";
import SearchBox from "../../Common/SearchBox/SearchBox"
import { ICN_TRASH, ICN_EDIT, ICN_BOOK, ICN_LIBRARY, ICN_PROGRESS, ICN_STORE } from "../../Common/Icon";
import { navigate, Router, Link } from "../../Common/Router";
import CardHeader from '../../Common/CardHeader'
import { TextInput, SelectInput } from '../../Common/InputField/InputField'
import ReportChart from '../../Charts/ReportChart';
import ReportDownload from './ReportDownload';
import AppContext from '../../../Store/AppContext';
import './report.css'
import { Button, Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import RestService from '../../../Services/api.service';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import InstructorReports from './InstructorReports';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DynamicTable from '../../Common/DynamicTable/DynamicTable';
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { ICN_COMING_BATCHES, ICN_COPY } from '../../Common/Icon';
import ScienceIcon from '@mui/icons-material/Science';
import { LinearProgress } from '@mui/material';
import DropdownHeader from './DropdownHeader';

import InfoIcon from '@mui/icons-material/Info';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';

import DuoIcon from '@mui/icons-material/Duo';
import CodeIcon from '@mui/icons-material/Code';
import SkeletonLoader from '../../Common/SkeletonLoader/SkeletonLoader';
import ReportLearnerDetails from './ReportLearnerDetails';
import LearnerSpecificReport from './LearnerSpecificReport';
import NewTrainingSpecificReport from './NewTrainingSpecificReport';
import AssessmentSpecificReport from './AssessmentSpecificReport';
import AssessmentAllSpecific from './AssessmentAllSpecific';
import LearnerReport from './LearnerReport';
import TrainingReport from './TrainingReport';

const Report = ({ location }) => {
  const { user, ROLE, spinner } = useContext(AppContext);
  const [trainingList, setTrainingList] = useState([]);
  const [showReport, setShowReport] = useState(null);
  const [studentSid, setStudentSid] = useState('');

  const learner = JSON.parse(localStorage.getItem('user'));
  const [trainingSid, setTrainingSid] = useState('');
  const learnerSid = learner.sid;
  const [scrollLeftInterval, setScrollLeftInterval] = useState(null);
  const [scrollRightInterval, setScrollRightInterval] = useState(null);

  const [alignment, setAlignment] = useState('PROGRESS');

  //assessment
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [assessmentCardDetails, setAssessmentCardDetails] = useState({});
  const [assessmentTraining, setAssessmentTraining] = useState({});
  const [assessmentTrainingDetails, setAssessmentTrainingDetails] = useState([]);
  const [assessmentName, setAssessmentName] = useState('');
  const [attemptDate, setAttemptDate] = useState(false);
  let newAttemptDate = attemptDate ? "Date" : '';

  //capstone
  const [capstoneDetails, setCapstoneDetails] = useState([]);
  const [capstoneCardDetails, setCapstoneCardDetails] = useState({});
  const [capstoneTraining, setCapstoneTraining] = useState({});
  const [capstoneTrainingDetails, setCapstoneTrainingDetails] = useState([]);
  const [capstoneName, setCapstoneName] = useState('');

  //coding
  const [codingDetails, setCodingDetails] = useState([]); //get learner coding details api TODO
  const [codingCardDetails, setCodingCardDetails] = useState({});
  // const [codingTraining, setCodingTraining] = useState({}); //get learner coding training api TODO
  const [codingTrainingDetails, setCodingTrainingDetails] = useState([]); //get learner traing coding details 
  const [codingName, setCodingName] = useState('');

  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(true);
  const [assessmentLoader, setAssessmentLoader] = useState(true);
  const [capstoneLoader, setCapstoneLoader] = useState(true);
  const [codingLoader, setCodingLoader] = useState(true);

  const [configuration, setConfiguration] = useState({

    columns: {
      "trainingName": {
        "title": "Training Name",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,

        render: (data) => <>

          {/* <Link to={`${data.trainingName}`} state={{ trainingName: data.trainingName, trainingSid: data.trainingSid, subTitle: `${data.trainingName}`, subPath: `${data.trainingName}` }}> */}
          <div> {data.trainingName.length > 12 ?
            <div data-tooltip-id="tooltip" data-tooltip-content={data.trainingName}><InfoIcon fontSize="small" color="primary" />{`${data.trainingName.substring(0, 12)}`}</div> : data.trainingName}</div>



          {data.trainingName.length > 12 && <Tooltip
            id="tooltip"
            data-tooltip-place="right"
            variant="info"

          />}
          {/* </Link> */}
        </>
      },

      "assessmentName": {
        "title": "Assessment Name",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: true,
        render: (data) => <>
          <div> {data.assessmentName.length > 12 ?
            <div data-tooltip-id="tooltip" data-tooltip-content={data.assessmentName}><InfoIcon fontSize="small" color="primary" />{`${data.assessmentName.substring(0, 12)}`}</div> : data.assessmentName}</div>
          {data.assessmentName.length > 12 && <Tooltip
            id="tooltip"
            data-tooltip-place="right"
            variant="info"

          />}
        </>
      },
      "attemptedOn": {
        "title": "Date",
        "sortDirection": null,
        "sortEnabled": false,
        isSearchEnabled: false,
        render: (data) => <>

          {data.hasOwnProperty('attemptedOn') ?
            data.attemptedOn.substring(0, 10) : "---"}

        </>

      },
      "rank": {
        "title": "Rank",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
      },
      "score": {
        "title": "Mark Scored",
        "sortDirection": null,
        "sortEnabled": false,
        isSearchEnabled: false,


      },
      "total": {
        "title": "Total Score",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,


      },
      "percentage": {
        "title": "Percentage",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) => <>


          {`${data.percentage.toFixed(2)}%`}  <LinearProgress color={data.percentage > 50 ? "success" : "secondary"} variant="determinate" value={data.percentage} />

        </>


      },
      "topperName": {
        "title": "Leader",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
      },
      "topperPercentage": {
        "title": "Leader Percentage",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) => <>
          {`${data.topperPercentage.toFixed(2)}%`}
        </>
      }


    },
    headerTextColor: '#454E50', // user can change table header text color
    sortBy: null,  // by default sort table by name key
    sortDirection: true, // sort direction by default true
    updateSortBy: (sortKey) => {
      configuration.sortBy = sortKey;
      Object.keys(configuration.columns).map(key => configuration.columns[key].sortDirection = (key === sortKey) ? !configuration.columns[key].sortDirection : true);
      configuration.sortDirection = configuration.columns[sortKey].sortDirection;
      setConfiguration({ ...configuration });
    },

    // user can pass their own custom className name to add/remove some css style on action button
    actionVariant: "", // user can pass action button variant like primary, dark, light,
    actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
    // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
    // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
    searchQuery: "",
    tableCustomClass: "reporttable sort-enabled", // table custom class
    showCheckbox: false,
    clearSelection: false
  });

  const [configuration1, setConfiguration1] = useState({

    columns: {
      "trainingName": {
        "title": "Training Name",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,

        render: (data) => <>

          {/* <Link to={`${data.trainingName}`} state={{ trainingName: data.trainingName, trainingSid: data.trainingSid, subTitle: `${data.trainingName}`, subPath: `${data.trainingName}` }}> */}
          <div> {data.trainingName.length > 12 ?
            <div data-tooltip-id="tooltip" data-tooltip-content={data.trainingName}><InfoIcon fontSize="small" color="primary" />{`${data.trainingName.substring(0, 12)}`}</div> : data.trainingName}</div>



          {data.trainingName.length > 12 && <Tooltip
            id="tooltip"
            data-tooltip-place="right"
            variant="info"

          />}
          {/* </Link> */}
        </>
      },

      "labName": {
        "title": "Lab Name",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: true,
        render: (data) => <>
          <div> {data.labName.length > 12 ?
            <div data-tooltip-id="tooltip" data-tooltip-content={data.labName}><InfoIcon fontSize="small" color="primary" />{`${data.labName.substring(0, 12)}`}</div> : data.labName}</div>
          {data.labName.length > 12 && <Tooltip
            id="tooltip"
            data-tooltip-place="right"
            variant="info"

          />}
        </>
      },
      "score": {
        "title": "Score",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) => <>

          {data.score !== null ? data.score.toFixed(2) : "---"}

        </>

      },
      "rank": {
        "title": "Rank",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) =>
          <>
            {data.status === "NOT_STARTED" ? "---" : data.rank}
          </>
      },
      "total": {
        "title": "Total Score",
        "sortDirection": null,
        "sortEnabled": false,
        isSearchEnabled: false,
        render: (data) =>
          <>
            {data.total !== null ? data.total.toFixed(2) : "---"}
          </>
      },
      "percentage": {
        "title": "Percentage",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) => <>

          {data.percentage !== null ?
            <>
              {`${data.percentage.toFixed(2)}%`} < LinearProgress color={data.percentage > 50 ? "success" : "secondary"} variant="determinate" value={data.percentage} />
            </>


            : '---'}

        </>


      },
      "topperName": {
        "title": "Leader",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) =>
          <>
            {data.topperName !== null ? data.topperName : '---'}
          </>
      },
      "topperPercentage": {
        "title": "Leader Percentage",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) => <>
          {data.topperPercentage !== null ? `${data.topperPercentage.toFixed(2)}%` : '---'}
        </>
      },
      "status": {
        "title": "Status",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) =>
          <>

            {
              data.status === "PRESENT" ? <div className='presentstatus'>COMPLETED</div>
                : data.status === "NOT_STARTED" ? <div className='presentstatus' style={{ color: "#FAF9F6" }}>NOT STARTED</div>
                  : data.status === "UNDER_EVALUATION" ? <div className='presentstatus' style={{ color: "#FFEA00" }}>UNDER EVALUATION</div>
                    : <div className='presentstatus' style={{ color: "red" }}>{data.status}</div>
            }
          </>

      }
    },
    headerTextColor: '#454E50', // user can change table header text color
    sortBy: null,  // by default sort table by name key
    sortDirection: true, // sort direction by default true
    updateSortBy: (sortKey) => {
      configuration1.sortBy = sortKey;
      Object.keys(configuration1.columns).map(key => configuration1.columns[key].sortDirection = (key === sortKey) ? !configuration1.columns[key].sortDirection : true);
      configuration1.sortDirection = configuration1.columns[sortKey].sortDirection;
      setConfiguration1({ ...configuration1 });
    },

    // user can pass their own custom className name to add/remove some css style on action button
    actionVariant: "", // user can pass action button variant like primary, dark, light,
    actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
    // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
    // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
    searchQuery: "",
    tableCustomClass: "reporttable sort-enabled", // table custom class
    showCheckbox: false,
    clearSelection: false
  });

  const [configuration2, setConfiguration2] = useState({

    columns: {
      "trainingName": {
        "title": "Training Name",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,

        render: (data) => <>

          {/* <Link to={`${data.trainingName}`} state={{ trainingName: data.trainingName, trainingSid: data.trainingSid, subTitle: `${data.trainingName}`, subPath: `${data.trainingName}` }}> */}
          <div> {data.trainingName.length > 12 ?
            <div data-tooltip-id="tooltip" data-tooltip-content={data.trainingName}><InfoIcon fontSize="small" color="primary" />{`${data.trainingName.substring(0, 12)}`}</div> : data.trainingName}</div>



          {data.trainingName.length > 12 && <Tooltip
            id="tooltip"
            data-tooltip-place="right"
            variant="info"

          />}
          {/* </Link> */}
        </>
      },

      "codingAssessmentName": {
        "title": "Coding Assessment Name",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: true,
        render: (data) => <>
          <div> {data.codingAssessmentName.length > 12 ?
            <div data-tooltip-id="tooltip" data-tooltip-content={data.codingAssessmentName}><InfoIcon fontSize="small" color="primary" />{`${data.codingAssessmentName.substring(0, 12)}`}</div> : data.codingAssessmentName}</div>
          {data.codingAssessmentName.length > 12 && <Tooltip
            id="tooltip"
            data-tooltip-place="right"
            variant="info"

          />}
        </>
      },
      "submittedOn": {
        "title": "Submitted On",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) => <>

          {data.submittedOn.substring(0, 10)}

        </>

      },
      "marksScored": {
        "title": "Score",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        // render: (data) => <>

        //   {data.marksScored !== null ? data.score.toFixed(2) : "---"}

        // </>

      },
      "rank": {
        "title": "Rank",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        // render: (data) =>
        //   <>
        //     {data.status === "NOT_STARTED" ? "---" : data.rank}
        //   </>
      },
      "totalScore": {
        "title": "Total Score",
        "sortDirection": null,
        "sortEnabled": false,
        isSearchEnabled: false,
        // render: (data) =>
        //   <>
        //     {data.total !== null ? data.total.toFixed(2) : "---"}
        //   </>
      },
      "percentage": {
        "title": "Percentage",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) => <>

          {data.percentage !== null ?
            <>
              {`${data.percentage.toFixed(2)}%`} < LinearProgress color={data.percentage > 50 ? "success" : "secondary"} variant="determinate" value={data.percentage} />
            </>


            : '---'}

        </>


      },
      "topperName": {
        "title": "Leader",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) =>
          <>
            {data.topperName !== null ? data.topperName : '---'}
          </>
      },
      "topperPercentage": {
        "title": "Leader Percentage",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) => <>
          {data.topperPercentage !== null ? `${data.topperPercentage.toFixed(2)}%` : '---'}
        </>
      }
    },
    headerTextColor: '#454E50', // user can change table header text color
    sortBy: null,  // by default sort table by name key
    sortDirection: true, // sort direction by default true
    updateSortBy: (sortKey) => {
      configuration2.sortBy = sortKey;
      Object.keys(configuration2.columns).map(key => configuration2.columns[key].sortDirection = (key === sortKey) ? !configuration2.columns[key].sortDirection : true);
      configuration2.sortDirection = configuration2.columns[sortKey].sortDirection;
      setConfiguration2({ ...configuration2 });
    },

    // user can pass their own custom className name to add/remove some css style on action button
    actionVariant: "", // user can pass action button variant like primary, dark, light,
    actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
    // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
    // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
    searchQuery: "",
    tableCustomClass: "reporttable sort-enabled", // table custom class
    showCheckbox: false,
    clearSelection: false
  });

  const handleChange = (event, newAlignment) => {
    if (newAlignment === "MCQ") {
      setTimeout(() => {
        setAssessmentLoader(false);
      }, 2000)
    }
    else if (newAlignment === "CAPSTONE") {
      setTimeout(() => {
        setCapstoneLoader(false);
      }, 2000)
    }
    else if (newAlignment === "CODING") {
      setTimeout(() => {
        setCodingLoader(false);
      }, 2000)
    }
    setAlignment(newAlignment);
    setShowReport(null);
  };


  function scrollLeft() {
    const tableWrapper = document.querySelector(".table-wrapper");
    if (scrollRightInterval > 0) {
      tableWrapper.scrollLeft -= 50;
    }


    const table = document.querySelector(".table-wrapper table");
    table.querySelectorAll("tr").forEach((row) => {
      row.insertBefore(row.lastElementChild, row.firstElementChild);
    });
  }

  function scrollRight() {
    const tableWrapper = document.querySelector(".table-wrapper");
    tableWrapper.scrollLeft += 50;

    const table = document.querySelector(".table-wrapper table");
    table.querySelectorAll("tr").forEach((row) => {
      row.appendChild(row.firstElementChild);
    });
  }

  function handleLeftMouseDown() {
    scrollLeft();
    setScrollLeftInterval(setInterval(scrollLeft, 500));
  }

  function handleRightMouseDown() {
    scrollRight();
    setScrollRightInterval(setInterval(scrollRight, 500));
  }

  function handleMouseUp() {
    clearInterval(scrollLeftInterval);
    clearInterval(scrollRightInterval);
  }

  //modal 
  const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal d-block" : "modal d-none";

    return (

      <div className={showHideClassName}  >

        <div className="modal-container modal-xl" style={{ height: "600px", overflowY: "scroll" }}>
          <div style={{ float: "right" }} className='circle-md'> <div className="modal-close" onClick={handleClose}>
            X
          </div></div>
          {children}

        </div>
      </div>
    );
  };

  // get learner trainings
  const getLearnerTrainings = () => {
    try {
      RestService.getAllTrainingByPage().then(
        response => {
          if (response.status === 200) {
            setTimeout(() => {
              setLoader(false);
              setTrainingList(response.data.filter(item => item.status === 'ENABLED' || item.status === 'ARCHIVED'));
            }, 2000);
          }
        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getLearnerTrainings()", err)
    }
  }

  // get supervisor training
  const getTrainings = async (pagination = "1") => {
    try {
      let pageSize = 30;
      // spinner.show();
      const status = 'ENABLED'
      RestService.getTrainingWithPaginationAndStatusSuperVisor(pagination, pageSize, status).then(
        response => {
          if (response.status === 200) {
            setTimeout(() => {
              setLoader(false);
              setTrainingList(response.data);
            }, 2000)

          }

        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getTrainings()", err)
    }
  }
  //show reports for learner only
  const getSupervisorReportTrainingDetails = (value) => {
    try {
      spinner.show();
      RestService.getSupervisorReportTrainingDetails(learnerSid, value).then(
        response => {
          if (response.status === 200) {
            setShowReport(response.data.sectionDetails);

          }
        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getTrainings()", err)
    }
  }

  //show reports for supervisor only
  const getSupervisorReportTrainingDetailsSupervisor = () => {
    try {
      spinner.show();
      RestService.getSupervisorReportTrainingDetails(studentSid, trainingSid).then(
        response => {
          if (response.status === 200) {
            setShowReport(response.data.sectionDetails);
            // setTrainingSid('');
            // setStudentSid('');

          }
        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getTrainings()", err)
    }
  }

  //learner reporting screen
  //assessment

  //get learner assessmnent details
  const getLearnerAssessmentDetails = () => {
    try {
      // spinner.show();
      RestService.getLearnerAssessmentDetails().then(
        response => {
          if (response.status === 200) {

            setAssessmentDetails(response.data.assessmentDetails);
            setAssessmentCardDetails(response.data.cardDetails);
            setAttemptDate(response.data.assessmentDetails[0].hasOwnProperty('attemptedOn'))
          }
          else {
            setAssessmentDetails([]);
          }
        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getLearnerAssessmentDetails()", err)
    }
  }

  //get learner assessment trainings
  const getLearnerAssessmentTrainings = () => {
    try {
      // spinner.show();
      RestService.getLearnerAssessmentTrainings().then(
        response => {
          if (response.status === 200) {

            setAssessmentTraining(response.data);
          }
          else {
            setAssessmentTraining({});
          }
        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getLearnerAssessmentTrainings()", err)
    }
  }

  //get learner assessment training details
  const getLearnerTrainingAssessmentDetails = (trainingSid) => {
    try {
      spinner.show();
      RestService.getLearnerTrainingAssessmentDetails(trainingSid).then(
        response => {
          if (response.status === 200) {
            // setHighestScore(response.data.highestScore);
            // setHighestScoreBy(response.data.highestScoreBy);
            // setAverageBatchPercentage(response.data.averageBatchPercentage);
            setAssessmentTrainingDetails(response.data);
            setShow(true);
            setAttemptDate(response.data[0].hasOwnProperty('attemptedOn'))
          }
          else {
            setAssessmentTrainingDetails([]);
          }
        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getLearnerTrainingAssessmentDetails()", err)
    }

  }
  // capstone

  //get learner capstone details
  const getLearnerCapstoneDetails = () => {
    try {
      // spinner.show();
      RestService.getLearnerCapstoneDetails().then(
        response => {
          if (response.status === 200) {

            setCapstoneDetails(response.data);

          }
          else {
            setCapstoneDetails([]);
          }
        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getLearnerCapstoneDetails()", err)
    }

  }

  //get learner capstone trainings
  const getLearnerCapstoneTrainings = () => {
    try {
      // spinner.show();
      RestService.getLearnerCapstoneTrainings().then(
        response => {
          if (response.status === 200) {

            setCapstoneTraining(response.data);
          }
          else {
            setCapstoneTraining({});
          }
        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getLearnerCapstoneTrainings()", err)
    }
  }

  //get learner training capstone details
  const getLearnerTrainingCapstoneDetails = (trainingSid) => {
    try {
      spinner.show();
      RestService.getLearnerTrainingCapstoneDetails(trainingSid).then(
        response => {
          if (response.status === 200) {
            // setHighestScore(response.data.highestScore);
            // setHighestScoreBy(response.data.highestScoreBy);
            // setAverageBatchPercentage(response.data.averageBatchPercentage);
            setCapstoneTrainingDetails(response.data);
            setShow(true);
          }
          else {
            setCapstoneTrainingDetails([]);
          }
        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getLearnerTrainingCapstoneDetails()", err)
    }
  }

  //get learner coding assessment details
  const getLearnerCodingAssessmentDetails = () => {
    try {
      // spinner.show();
      RestService.getLearnerCodingAssessmentDetails().then(
        response => {
          if (response.status === 200) {

            setCodingDetails(response.data.assessmentDetails);
            setCodingCardDetails(response.data.cardDetails);
            // setAttemptDate(response.data.assessmentDetails[0].hasOwnProperty('attemptedOn'))
          }
          else {
            setCodingDetails([]);
          }
        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getLearnerCodingAssessmentDetails()", err)
    }
  }

  //get learner training coding details
  const getLearnerTrainingCodingAssessmentDetails = (trainingSid) => {
    try {
      spinner.show();
      RestService.getLearnerTrainingCodingAssessmentDetails(trainingSid).then(
        response => {
          if (response.status === 200) {
            // setHighestScore(response.data.highestScore);
            // setHighestScoreBy(response.data.highestScoreBy);
            // setAverageBatchPercentage(response.data.averageBatchPercentage);
            setCodingTrainingDetails(response.data);
            setShow(true);
          }
          else {
            setCodingTrainingDetails([]);
          }
        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getLearnerTrainingCodingAssessmentDetails()", err)
    }
  }



  useEffect(() => {
    if (user.role === ROLE.SUPERVISOR || user.role === ROLE.CONSUMER_SUPERVISOR) {
      getTrainings();
    }
    else {
      getLearnerTrainings();
    }

  }, []);

  useEffect(() => {
    if (user.role === ROLE.LEARNER) {
      getLearnerAssessmentDetails();
      getLearnerAssessmentTrainings();
      getLearnerCapstoneDetails();
      getLearnerCapstoneTrainings();
      getLearnerCodingAssessmentDetails();
    }
  }, []);

  return (
    <div className=" pl-3 pr-3 pt-1 pb-1 main-report" >


      <Router>
        {/* <Batch path="/" />
                <Course path="course" />
                <Participants path="participants" /> */}
        <ReportDownload path="/" />
        {/* <LearnerReport path="/by-learner" /> */}
        {/* <LearnerSpecificReport path="/learner" /> */}
        <NewTrainingSpecificReport path="/training/*" />
        <AssessmentSpecificReport path="/assessment/*" />
        <ReportLearnerDetails path="/:reportType/*" />
        <AssessmentAllSpecific path="/learner-details/*" />
      </Router>


      {
        user.role === ROLE.INSTRUCTOR ?
          <InstructorReports />
          :
          user.role === ROLE.LEARNER ?

            <>
              {
                loader ?
                  <SkeletonLoader skeletonCount={0.4} />
                  :

                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    onChange={handleChange}
                    aria-label="Platform"
                    className=' mb-1'
                  >
                    <ToggleButton value="PROGRESS">Training Progress</ToggleButton>
                    <ToggleButton value="MCQ">Mcq Assessment</ToggleButton>
                    <ToggleButton value="CAPSTONE">Capstone</ToggleButton>
                    <ToggleButton value="CODING">CODING</ToggleButton>
                  </ToggleButtonGroup>
              }
              {
                alignment === "PROGRESS" ?
                  <div style={{ marginBottom: "120px" }}>

                    <div className='row py-2 ' >
                      <div className='col-6 '>
                        <label className="col-3 mt-2 label form-label ">Training Name</label>
                        {
                          loader ?
                            <SkeletonLoader skeletonCount={1} />
                            :

                            <select className="form-control col-6" style={{ borderRadius: "10px", backgroundColor: "rgb(248, 250, 251)" }}
                              onChange={(e) => {
                                getSupervisorReportTrainingDetails(e.target.value);
                              }}
                            >
                              <option hidden>Select Training</option>
                              {
                                trainingList.map((item) => {
                                  return (
                                    <>
                                      <option value={item.sid}>

                                        {item.name}

                                      </option>
                                    </>
                                  )
                                })
                              }

                            </select>
                        }

                      </div>
                    </div>

                    <div>
                      {
                        showReport !== null ?
                          <>
                            <table style={{ width: "100%" }}>
                              <div class="row h-100 pt-2 pb-2">
                                <div class="col-2">
                                  <table className='c h-100 reporttable sort-enabled table' style={{ width: "100%" }}>
                                    <tr>
                                      <th className='h-100' style={{ backgroundColor: "#F5F8FB" }}>Section</th>
                                    </tr>

                                    <tr>
                                      <td style={{ textAlign: "left" }}><DuoIcon /> Study Material</td>
                                    </tr>
                                    <tr>
                                      <td style={{ textAlign: "left" }}><PlayCircleIcon /> Videos</td>

                                    </tr>
                                    <tr>
                                      <td style={{ textAlign: "left" }}> <AssessmentIcon /> Assessments</td>

                                    </tr>
                                    <tr>
                                      <td style={{ textAlign: "left" }}><ScienceIcon /> Labs</td>

                                    </tr>
                                    <tr>
                                      <td style={{ textAlign: "left" }}><CodeIcon /> Challenges</td>

                                    </tr>
                                  </table>
                                </div>
                                <div class="col-10 table-wrapper">
                                  <table className='c reporttable sort-enabled table' style={{ width: "100%" }}>
                                    <tr>
                                      {
                                        showReport.DOCUMENTS.map((item) => {
                                          return (
                                            // <th>{item.sectionName.split("", 15)}...</th>
                                            <th style={{ backgroundColor: "#F5F8FB" }}> {item.sectionName.length > 10 ?
                                              <div data-tooltip-id="tooltip" data-tooltip-content={item.sectionName}>{`${item.sectionName.substring(0, 10)} `}<InfoIcon fontSize="small" color="primary" /></div> : item.sectionName}



                                              {item.sectionName.length > 10 && <Tooltip
                                                id="tooltip"
                                                data-tooltip-place="bottom"
                                                variant="info"

                                              />}
                                            </th>
                                          )
                                        })

                                      }




                                    </tr>
                                    <tr>
                                      {
                                        showReport.DOCUMENTS.map((item) => {
                                          return (
                                            <td>{item.documentCompletion}</td>
                                          )
                                        })

                                      }

                                    </tr>
                                    <tr>
                                      {
                                        showReport.VIDEO.map((item) => {
                                          return (
                                            <td>{item.videoCompletion}</td>
                                          )
                                        })

                                      }

                                    </tr>
                                    <tr>
                                      {
                                        showReport.ASSESSMENT.map((item) => {
                                          return (
                                            <td>{item.assessmentCompletion}</td>
                                          )
                                        })

                                      }

                                    </tr>
                                    <tr>
                                      {
                                        showReport.LAB.map((item) => {
                                          return (
                                            <td>{item.labCompletion}</td>
                                          )
                                        })

                                      }

                                    </tr>
                                    <tr>
                                      {
                                        showReport.CODING.map((item) => {
                                          return (
                                            <td>{item.codingCompletion}</td>
                                          )
                                        })

                                      }

                                    </tr>
                                  </table>
                                </div>
                              </div>
                            </table>
                            <div style={{ marginTop: "-135px", position: "relative" }}>
                              <button

                                onMouseDown={handleLeftMouseDown}
                                onMouseUp={handleMouseUp}
                                style={{ marginLeft: "230px" }}
                              >
                                <ArrowBackIosIcon />
                              </button>
                              <button
                                style={{ float: "right", marginRight: "-20px" }}
                                onMouseDown={handleRightMouseDown}
                                onMouseUp={handleMouseUp}
                              >

                                <ArrowForwardIosIcon />
                              </button>

                            </div>


                          </>
                          : ""
                      }

                    </div>

                  </div>
                  :
                  alignment === "MCQ" ?
                    <div id="capture">
                      <div className='row mb-4'>
                        <div className="col-md-3 ">

                          <div className="grid-batch1 ">
                            <div className="mb10">{ICN_COMING_BATCHES}</div>
                            <div>
                              {
                                assessmentLoader ?
                                  <SkeletonLoader skeletonCount={1} />
                                  :
                                  <div className="batch-title">{assessmentCardDetails.totalAssessmentGiven}</div>
                              }
                              <div className="batch-label">Total Attempted</div>
                            </div>
                            <div className="jce">
                              <div className="grid-batch-icon">
                                <i className="bi bi-arrows-angle-expand"></i>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="col-md-3 ">

                          <div className="grid-batch2">
                            <div className="mb10">{ICN_COPY}</div>
                            <div>
                              {
                                assessmentLoader ?
                                  <SkeletonLoader skeletonCount={1} />
                                  :
                                  <div className="batch-title">{assessmentCardDetails.avgPercentage.toFixed(2)}%</div>
                              }
                              <div className="batch-label">Avg Score</div>
                            </div>
                            <div className="jce">
                              <div className="grid-batch-icon">
                                <i className="bi bi-arrows-angle-expand"></i>
                              </div>
                            </div>
                          </div>

                        </div>


                        <div className='col-md-3 '>
                          <div style={{
                            height: "112px",
                            borderRadius: "20px",
                            backgroundColor: "#D0EFFA",
                            padding: "15px",
                            color: "#000000",
                            display: "flex",
                            /* flex-direction: column; */
                            justifyContent: "space-between",
                            fontSize: "20px"
                          }} >
                            <div className="mb10">{ICN_COPY}</div>
                            <div>
                              {
                                assessmentLoader ?
                                  <SkeletonLoader skeletonCount={1} />
                                  :
                                  <>
                                    <div className="batch-title">{assessmentCardDetails.learnerPersonalBestScoreDetails === null ? 0 : Number(assessmentCardDetails.learnerPersonalBestScoreDetails[1]).toFixed(2)}%</div>
                                    <div className="batch-label" style={{ wordBreak: 'break-all' }}>{assessmentCardDetails.learnerPersonalBestScoreDetails === null ? 0 : assessmentCardDetails.learnerPersonalBestScoreDetails[0]}
                                    </div>
                                  </>
                              }

                              <div className="batch-label"> Personal Best</div>
                            </div>
                            <div className="jce">
                              <div className="grid-batch-icon">
                                <i className="bi bi-arrows-angle-expand"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className='col-md-3'>
                        <div className="grid-batch1">
                          <div className="mb10"><TimerIcon /></div>
                          <div>
                            <div className="batch-title">{Math.round(reportTrainingsCard.totalLabHours / 60)} hr</div>
                            <div className="batch-label">Total Lab Hours</div>
                          </div>
                          <div className="jce">
                            <div className="grid-batch-icon">
                              <i className="bi bi-arrows-angle-expand"></i>
                            </div>
                          </div>
                        </div>
                      </div> */}
                      </div>
                      {/* <div className='row mt-4 mb-4'>
                        <DropdownHeader assessment={assessmentTraining} style={{ borderRadius: "30px" }} />
                      </div> */}
                      {
                        assessmentLoader ?
                          <SkeletonLoader skeletonCount={0.5} />
                          :
                          <select className='form-control mt-1 mb-3 ' style={{ borderRadius: "30px", marginRight: "-20px" }}
                            onChange={(e) => {
                              getLearnerTrainingAssessmentDetails(e.target.value.split(',')[0]);
                              setAssessmentName(e.target.value.split(',')[1]);
                            }}>
                            <option value="">Overall</option>
                            {
                              Object.keys(assessmentTraining).map(function (key, index) {
                                return (
                                  <option value={`${key},${assessmentTraining[key]}`}>{assessmentTraining[key]}</option>
                                )
                              })

                            }
                          </select>
                      }
                      <DynamicTable  {...{ configuration, sourceData: assessmentDetails }} />

                      <Modal show={show} handleClose={() => { setShow(false); setAttemptDate(true) }}>
                        {/* <div className='title-md'>{assessmentName}</div> */}
                        <div className='row mt-4 mb-4'>

                          {/* <div className="col-md-4 ">

                            <div className="grid-batch1 ">
                              <div className="mb10">{ICN_COMING_BATCHES}</div>
                              <div>
                                <div className="batch-title">{highestScore}</div>
                                <div className="batch-label">Highest Score</div>
                              </div>
                              <div className="jce">
                                <div className="grid-batch-icon">
                                  <i className="bi bi-arrows-angle-expand"></i>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col-md-4 ">

                            <div className="grid-batch2">
                              <div className="mb10">{ICN_COPY}</div>
                              <div>
                                <div className="batch-title title-sm">{highestScoreBy === null ? 0 : highestScoreBy}</div>
                                <div className="batch-label ">Top Scorer</div>
                              </div>
                              <div className="jce">
                                <div className="grid-batch-icon">
                                  <i className="bi bi-arrows-angle-expand"></i>
                                </div>
                              </div>
                            </div>

                          </div>


                          <div className='col-md-4'>
                            <div className="grid-batch3">
                              <div className="mb10">{ICN_COMING_BATCHES}</div>
                              <div>
                                <div className="batch-title">{averageBatchPercentage === "NaN" ? "0" : averageBatchPercentage.toFixed(2)}%</div>
                                <div className="batch-label">Average Batch Percentage</div>
                              </div>
                              <div className="jce">
                                <div className="grid-batch-icon">
                                  <i className="bi bi-arrows-angle-expand"></i>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {/* <div className='col-md-3'>
                        <div className="grid-batch1">
                            <div className="mb10"><TimerIcon /></div>
                            <div>
                                <div className="batch-title"></div>
                                <div className="batch-label">Assessment</div>
                            </div>
                            <div className="jce">
                                <div className="grid-batch-icon">
                                    <i className="bi bi-arrows-angle-expand"></i>
                                </div>
                            </div>
                        </div>
                    </div> */}
                        </div>
                        <div class="card mt-2 mb-2" >
                          <div class="card-header">
                            {assessmentName}
                          </div>
                        </div>


                        <DynamicTable  {...{ configuration, sourceData: assessmentTrainingDetails }} />
                      </Modal>
                    </div>
                    :
                    alignment === "CAPSTONE" ?
                      <div id="capture">
                        {
                          capstoneLoader ?
                            <SkeletonLoader skeletonCount={0.5} />
                            :
                            <select className='form-control mt-1 mb-3 ' style={{ borderRadius: "30px", marginRight: "-20px" }}
                              onChange={(e) => {
                                getLearnerTrainingCapstoneDetails(e.target.value.split(',')[0]);
                                setCapstoneName(e.target.value.split(',')[1]);
                              }}>
                              <option value="">Overall</option>
                              {
                                Object.keys(capstoneTraining).map(function (key, index) {
                                  return (
                                    <option value={`${key},${capstoneTraining[key]}`}>{capstoneTraining[key]}</option>
                                  )
                                })

                              }
                            </select>
                        }
                        <DynamicTable  {...{ configuration: configuration1, sourceData: capstoneDetails }} />

                        <Modal show={show} handleClose={() => setShow(false)}>
                          {/* <div className='title-md'>{assessmentName}</div> */}
                          <div className='row mt-4 mb-4'>

                            {/* <div className="col-md-4 ">

                          <div className="grid-batch1 ">
                            <div className="mb10">{ICN_COMING_BATCHES}</div>
                            <div>
                              <div className="batch-title">{highestScore}</div>
                              <div className="batch-label">Highest Score</div>
                            </div>
                            <div className="jce">
                              <div className="grid-batch-icon">
                                <i className="bi bi-arrows-angle-expand"></i>
                              </div>
                            </div>
                          </div>

                        </div>
                        <div className="col-md-4 ">

                          <div className="grid-batch2">
                            <div className="mb10">{ICN_COPY}</div>
                            <div>
                              <div className="batch-title title-sm">{highestScoreBy === null ? 0 : highestScoreBy}</div>
                              <div className="batch-label ">Top Scorer</div>
                            </div>
                            <div className="jce">
                              <div className="grid-batch-icon">
                                <i className="bi bi-arrows-angle-expand"></i>
                              </div>
                            </div>
                          </div>

                        </div>


                        <div className='col-md-4'>
                          <div className="grid-batch3">
                            <div className="mb10">{ICN_COMING_BATCHES}</div>
                            <div>
                              <div className="batch-title">{averageBatchPercentage === "NaN" ? "0" : averageBatchPercentage.toFixed(2)}%</div>
                              <div className="batch-label">Average Batch Percentage</div>
                            </div>
                            <div className="jce">
                              <div className="grid-batch-icon">
                                <i className="bi bi-arrows-angle-expand"></i>
                              </div>
                            </div>
                          </div>
                        </div> */}
                            {/* <div className='col-md-3'>
                      <div className="grid-batch1">
                          <div className="mb10"><TimerIcon /></div>
                          <div>
                              <div className="batch-title"></div>
                              <div className="batch-label">Assessment</div>
                          </div>
                          <div className="jce">
                              <div className="grid-batch-icon">
                                  <i className="bi bi-arrows-angle-expand"></i>
                              </div>
                          </div>
                      </div>
                  </div> */}
                          </div>
                          <div class="card mt-2 mb-2" >
                            <div class="card-header">
                              {capstoneName}
                            </div>
                          </div>

                          <DynamicTable  {...{ configuration: configuration1, sourceData: capstoneTrainingDetails }} />
                        </Modal>
                      </div>
                      :
                      <div id="capture">
                        <div className='row mb-4'>
                          <div className="col-md-3 ">

                            <div className="grid-batch1 ">
                              <div className="mb10">{ICN_COMING_BATCHES}</div>
                              <div>
                                {
                                  codingLoader ?
                                    <SkeletonLoader skeletonCount={1} />
                                    :
                                    <div className="batch-title">{codingCardDetails.totalAttempted}</div>
                                }
                                <div className="batch-label">Total Attempted</div>
                              </div>
                              <div className="jce">
                                <div className="grid-batch-icon">
                                  <i className="bi bi-arrows-angle-expand"></i>
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="col-md-3 ">

                            <div className="grid-batch2">
                              <div className="mb10">{ICN_COPY}</div>
                              <div>
                                {
                                  codingLoader ?
                                    <SkeletonLoader skeletonCount={1} />
                                    :
                                    <div className="batch-title">{codingCardDetails.averagePercentage === 'NaN' ? 0 : codingCardDetails.averagePercentage.toFixed(2)}%</div>
                                }
                                <div className="batch-label">Avg Score</div>
                              </div>
                              <div className="jce">
                                <div className="grid-batch-icon">
                                  <i className="bi bi-arrows-angle-expand"></i>
                                </div>
                              </div>
                            </div>

                          </div>


                          <div className='col-md-3 '>
                            <div style={{
                              height: "112px",
                              borderRadius: "20px",
                              backgroundColor: "#D0EFFA",
                              padding: "15px",
                              color: "#000000",
                              display: "flex",
                              /* flex-direction: column; */
                              justifyContent: "space-between",
                              fontSize: "20px"
                            }} >
                              <div className="mb10">{ICN_COPY}</div>
                              <div>
                                {
                                  codingLoader ?
                                    <SkeletonLoader skeletonCount={1} />
                                    :
                                    <>
                                      <div className="batch-title">{codingCardDetails.personalBestAssessmentName}</div>
                                      {/* <div className="batch-label" style={{ wordBreak: 'break-all' }}>{assessmentCardDetails.learnerPersonalBestScoreDetails === null ? 0 : assessmentCardDetails.learnerPersonalBestScoreDetails[0]}
                                      </div> */}
                                    </>
                                }

                                <div className="batch-label"> Personal Best</div>
                              </div>
                              <div className="jce">
                                <div className="grid-batch-icon">
                                  <i className="bi bi-arrows-angle-expand"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className='col-md-3'>
                            <div className="grid-batch1">
                              <div className="mb10">{ICN_COPY}</div>
                              <div className='px-4'>
                                {
                                  codingLoader ?
                                    <SkeletonLoader skeletonCount={1} />
                                    :

                                    <div className="batch-title">{codingCardDetails.personalBestAssessmentPercentage.toFixed(2)}%</div>
                                }
                                <div className="batch-label" style={{ width: "100%" }}>Personal Best Assessment Percentage</div>


                              </div>
                              <div className="jce">
                                <div className="grid-batch-icon">
                                  <i className="bi bi-arrows-angle-expand"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          codingLoader ?
                            <SkeletonLoader skeletonCount={0.5} />
                            :
                            <select className='form-control mt-1 mb-3 ' style={{ borderRadius: "30px", marginRight: "-20px" }}
                              onChange={(e) => {
                                getLearnerTrainingCodingAssessmentDetails(e.target.value.split(',')[0]);
                                setCodingName(e.target.value.split(',')[1]);
                              }}>
                              <option value="">Overall</option>
                              {
                                Object.keys(assessmentTraining).map(function (key, index) {
                                  return (
                                    <option value={`${key},${assessmentTraining[key]}`}>{assessmentTraining[key]}</option>
                                  )
                                })

                              }
                            </select>
                        }
                        <DynamicTable  {...{ configuration: configuration2, sourceData: codingDetails }} />

                        <Modal show={show} handleClose={() => setShow(false)}>
                          {/* <div className='title-md'>{assessmentName}</div> */}
                          <div className='row mt-4 mb-4'>

                            {/* <div className="col-md-4 ">

                        <div className="grid-batch1 ">
                          <div className="mb10">{ICN_COMING_BATCHES}</div>
                          <div>
                            <div className="batch-title">{highestScore}</div>
                            <div className="batch-label">Highest Score</div>
                          </div>
                          <div className="jce">
                            <div className="grid-batch-icon">
                              <i className="bi bi-arrows-angle-expand"></i>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="col-md-4 ">

                        <div className="grid-batch2">
                          <div className="mb10">{ICN_COPY}</div>
                          <div>
                            <div className="batch-title title-sm">{highestScoreBy === null ? 0 : highestScoreBy}</div>
                            <div className="batch-label ">Top Scorer</div>
                          </div>
                          <div className="jce">
                            <div className="grid-batch-icon">
                              <i className="bi bi-arrows-angle-expand"></i>
                            </div>
                          </div>
                        </div>

                      </div>


                      <div className='col-md-4'>
                        <div className="grid-batch3">
                          <div className="mb10">{ICN_COMING_BATCHES}</div>
                          <div>
                            <div className="batch-title">{averageBatchPercentage === "NaN" ? "0" : averageBatchPercentage.toFixed(2)}%</div>
                            <div className="batch-label">Average Batch Percentage</div>
                          </div>
                          <div className="jce">
                            <div className="grid-batch-icon">
                              <i className="bi bi-arrows-angle-expand"></i>
                            </div>
                          </div>
                        </div>
                      </div> */}
                            {/* <div className='col-md-3'>
                    <div className="grid-batch1">
                        <div className="mb10"><TimerIcon /></div>
                        <div>
                            <div className="batch-title"></div>
                            <div className="batch-label">Assessment</div>
                        </div>
                        <div className="jce">
                            <div className="grid-batch-icon">
                                <i className="bi bi-arrows-angle-expand"></i>
                            </div>
                        </div>
                    </div>
                </div> */}
                          </div>
                          <div class="card mt-2 mb-2" >
                            <div class="card-header">
                              {codingName}
                            </div>
                          </div>

                          <DynamicTable  {...{ configuration: configuration2, sourceData: codingTrainingDetails }} />
                        </Modal>
                      </div>
              }


            </>

            :
            ''
        // <div style={{ marginBottom: "120px" }}>

        //   <div className='row py-2 ' style={{ background: "#49167E", margin: "0", borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
        //     <div className='col-6 d-flex ' style={{ alignContent: "center", alignItems: "center" }}>
        //       <label className="col-3 mt-2 label form-label text-white ">Training Name</label>

        //       <select className="form-control col-6" style={{ borderRadius: "10px", backgroundColor: "rgb(248, 250, 251)" }}
        //         onChange={(e) => {
        //           setTrainingSid(e.target.value);
        //         }}
        //       >
        //         <option hidden>Select Training</option>
        //         {
        //           trainingList.map((item) => {
        //             return (
        //               <>
        //                 <option value={item.sid}>

        //                   {item.name}

        //                 </option>
        //               </>
        //             )
        //           })
        //         }

        //       </select>

        //     </div>

        //     {
        //       trainingSid.length > 0 ?

        //         <div className='col-6 d-flex '>
        //           <label className="mt-2 col-3 label form-label text-white">Learner</label>
        //           <select className="form-control col-6" style={{ borderRadius: "10px", backgroundColor: "rgb(248, 250, 251)" }}
        //             onChange={(e) => setStudentSid(e.target.value)}
        //           >
        //             <option hidden>Select Learner</option>
        //             <option value={hrithikSid}>Hrithik</option>
        //             <option value={newLearnSid}>Learner</option>
        //           </select>
        //           <button className='p-2 mt-2' style={{ marginLeft: "10px", borderRadius: "10px", height: "30px",backgroundColor: "rgb(248, 250, 251)", width: "75px" }} disabled={trainingSid.length === 0 || studentSid.length === 0} onClick={() => getSupervisorReportTrainingDetailsSupervisor()}>Submit</button>
        //         </div>
        //         :
        //         ''
        //     }
        //   </div>

        //   <div>
        //     {
        //       showReport !== null ?
        //         <>
        //           <table>
        //             <div class="row">
        //               <div class="col-2">
        //                 <table className='c'>
        //                   <tr>
        //                     <th>Section</th>
        //                   </tr>

        //                   <tr>
        //                     <td>Study Material</td>
        //                   </tr>
        //                   <tr>
        //                     <td>Videos</td>

        //                   </tr>
        //                   <tr>
        //                     <td>Assessments</td>

        //                   </tr>
        //                   <tr>
        //                     <td>Labs</td>

        //                   </tr>
        //                   <tr>
        //                     <td>Challenges</td>

        //                   </tr>
        //                 </table>
        //               </div>
        //               <div class="col-10 table-wrapper">
        //                 <table className='c'>
        //                   <tr>
        //                     {
        //                       showReport.DOCUMENTS.map((item) => {
        //                         return (
        //                           <th>{item.sectionName.split("", 8)}</th>
        //                         )
        //                       })

        //                     }




        //                   </tr>
        //                   <tr>
        //                     {
        //                       showReport.DOCUMENTS.map((item) => {
        //                         return (
        //                           <td>{item.documentCompletion}</td>
        //                         )
        //                       })

        //                     }

        //                   </tr>
        //                   <tr>
        //                     {
        //                       showReport.VIDEO.map((item) => {
        //                         return (
        //                           <td>{item.videoCompletion}</td>
        //                         )
        //                       })

        //                     }

        //                   </tr>
        //                   <tr>
        //                     {
        //                       showReport.ASSESSMENT.map((item) => {
        //                         return (
        //                           <td>{item.assessmentCompletion}</td>
        //                         )
        //                       })

        //                     }

        //                   </tr>
        //                   <tr>
        //                     {
        //                       showReport.LAB.map((item) => {
        //                         return (
        //                           <td>{item.labCompletion}</td>
        //                         )
        //                       })

        //                     }

        //                   </tr>
        //                   <tr>
        //                     {
        //                       showReport.CODING.map((item) => {
        //                         return (
        //                           <td>{item.codingCompletion}</td>
        //                         )
        //                       })

        //                     }

        //                   </tr>
        //                 </table>
        //               </div>
        //             </div>
        //           </table>
        //           <div style={{ marginTop: "-135px", position: "relative" }}>
        //             <button

        //               onMouseDown={handleLeftMouseDown}
        //               onMouseUp={handleMouseUp}
        //               style={{ marginLeft: "230px" }}
        //             >
        //               <ArrowBackIosIcon />
        //             </button>
        //             <button
        //               style={{ float: "right", marginRight: "-20px" }}
        //               onMouseDown={handleRightMouseDown}
        //               onMouseUp={handleMouseUp}
        //             >

        //               <ArrowForwardIosIcon />
        //             </button>

        //           </div>


        //         </>
        //         : ""
        //     }

        //   </div>

        // </div>
      }

    </div>)
}
export default Report

// const Batch = ({ label = "Progress Overview" }) => {
//     return (<>
//         <div className="report-container">
//             <div className="chart-report">
//                 <div className="mb-3">{label}</div>
//                 <ReportChart ChartType="report" labelLeft="Employee percentile" />
//             </div>
//             <div className="report-action">
//                 <div className="action-list action-list-top ">
//                     <div className="aic">{ICN_BOOK}</div>
//                     <div className="text-center">
//                         <div className="title-lg">ITU_1</div>
//                         <div>Explore More</div>
//                     </div>
//                 </div>
//                 <div className="action-list">
//                     <div className="text-center">
//                         <div>Progress</div>
//                         <div>{ICN_PROGRESS}</div>
//                     </div>
//                     <div>
//                         <div className="title-lg">60%</div>
//                     </div>
//                 </div>
//                 <div className="action-list">
//                     <div className="text-center">
//                         <div>Score</div>
//                         <div>{ICN_STORE}</div>
//                     </div>
//                     <div className="text-center">
//                         <div className="title-lg">65%</div>
//                     </div>
//                 </div>
//                 <div className="action-list">
//                     <div className="text-center">
//                         <div >Lab</div>
//                         <div>{ICN_LIBRARY}</div>
//                     </div>
//                     <div>
//                         <div className="title-lg">60%</div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </>)
// }

// const Course = () => {
//     return (<>
//         <Batch label="Technology Overview" />
//     </>)
// }

// const Participants = () => {
//     return (<>
//         <Batch label="Participants Overview" />
//     </>)
// }

