import React, { useEffect, useState, useContext } from 'react';
import AppContext from '../../../Store/AppContext';
import RestService from '../../../Services/api.service';
import SkeletonLoader from '../../Common/SkeletonLoader/SkeletonLoader';
import { navigate } from '../../Common/Router';
import { BtnPrimary } from '../../Common/Buttons/Buttons';
import VideoFileIcon from '@mui/icons-material/VideoFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import StarIcon from '@mui/icons-material/Star';
import VisibilityIcon from '@mui/icons-material/Visibility';
import "./Bookmark.css"
import { Tooltip } from 'react-tooltip';
import ReactPlayer from 'react-player';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import ScienceIcon from '@mui/icons-material/Science';
import { useNavigate } from "@reach/router";

const Bookmark = () => {
    const [isShownDocument, setIsShownDocument] = useState(false);
    const [isShownVideos, setIsShownVideos] = useState(false);
    const [isShownPracticeLab, setIsShownPracticeLab] = useState(false);

    const [documentBookmark, setDocumentBookmark] = useState(null);
    const [videoBookmark, setVideoBookmark] = useState(null);
    const [practicelabBookmark, setPracticelabBookmark] = useState(null);

    const [contentLink, setContentLink] = useState('')


    const [show, setShow] = useState(false);

    const [loader, setLoader] = useState(true);

    const navigate = useNavigate();

   // bookmark get api
   const getAllBookmarks = () => {

    try {
        // let trainingSid = location.state.sid;
        // spinner.show();
        RestService.getAllBookmarks().then(
            response => {
                if (response.status === 200) {
   
                    if (response.data.VIDEO === undefined) {
                        setVideoBookmark(null);

                    }
                    else {
                        setVideoBookmark(response.data.VIDEO);
                    }

                    if (response.data.DOCUMENTS === undefined) {
                        setDocumentBookmark(null);
                    }
                    else {
                        setDocumentBookmark(response.data.DOCUMENTS);
                    }

                    if (response.data.LAB === undefined) {
                        setPracticelabBookmark(null);
                    }
                    else {
                        setPracticelabBookmark(response.data.LAB);
                    }
                }
            },
            err => {
                // spinner.hide();
                console.error(err);
            }
        ).finally(() => {
            // spinner.hide();
        });
    } catch (err) {
        console.error("error occur on getTrainingBySid()", err)
    }
}



    const handleClickDocument = event => {

        //   setIsShown(current => !current);


        setIsShownDocument(true);
        setIsShownVideos(false);
        setIsShownPracticeLab(false);

    };
    const handleClickVideo = event => {

        //   setIsShown(current => !current);


        setIsShownVideos(true);
        setIsShownPracticeLab(false);
        setIsShownDocument(false);

    };
    const handleClickPractice = event => {

        //   setIsShown(current => !current);


        setIsShownPracticeLab(true);
        setIsShownDocument(false);
        setIsShownVideos(false);

    };


    // Model 
    const Modal = ({ handleClose, show, children }) => {
        const showHideClassName = show ? "modal d-block" : "modal d-none";

        return (

            <div className={showHideClassName}  >

                <div className="modal-container-bookmark  odal-dialog-scrollable" >
                    <div style={{ float: "right" }} className='circle-md'> <div className="modal-close" onClick={handleClose}>
                        X
                    </div></div>
                    {children}

                </div>


            </div>
        );
    };

    useEffect(() => {
        getAllBookmarks();
        setTimeout(()=>{
            setLoader(false);
        }, 2000);
    }, []);


    return (
        <>
            <Modal show={show} handleClose={() => setShow(false)}>
                {
                    isShownDocument && <iframe style={{ marginTop: "-2px" }} src={`${contentLink}#toolbar=0`} width="100%" height="100%" />
                }
                {
                    isShownVideos && <ReactPlayer
                        className='react-player '
                        url={contentLink}
                        width='98%'
                        height="100%"
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload'  //<- this is the important bit
                                }
                            }
                        }}
                        playing={true}
                        // loop={true}
                        muted={true}
                        controls

                    />
                }
            </Modal>

{
    loader ?
    <SkeletonLoader skeletonCount={5} />:

            <div className='pl-3 pr-3 pt-1 pb-1 ' style={{ boxShadow: "none", borderRadius: "10px" }}>
                {
                    documentBookmark === null && videoBookmark === null && practicelabBookmark === null ? <div className='text-center title-lg'>Your Bookmark Collection is Empty !!</div> :

                      
<div className='row mb-1' style={{justifyContent:"space-between"}} >

{
    documentBookmark === null ? "" :
        <div className="col-md-3 ">

            <div className="grid-batch2 bookmarkcard" onClick={handleClickDocument}  >
                <div className="mb10"><PictureAsPdfIcon /></div>
                <div>
                <div className="title-lg batch-title text-center">{documentBookmark.length}</div>
                    <div className="batch-label title-lg">Document</div>
                
                </div>

                <div className="jce">
                    <div className="grid-batch-icon">
                        <i className="bi bi-arrows-angle-expand"></i>
                    </div>
                </div>
            </div>

        </div>
}


{
    videoBookmark === null ? "" :
        <div className='col-md-3'>
            <div className="grid-batch3 bookmarkcard" onClick={handleClickVideo}  >
                <div className="mb10"><VideoFileIcon /></div>
                <div>
                <div className="title-lg batch-title text-center">{videoBookmark.length}</div>
                    <div className="batch-label title-lg">Videos</div>
                    
                </div>
                <div className="jce">
                    <div className="grid-batch-icon">
                        <i className="bi bi-arrows-angle-expand"></i>
                    </div>
                </div>
            </div>
        </div>
}

{
    practicelabBookmark === null ? "" :
        <div className='col-md-3 bookmarkcard' onClick={handleClickPractice}>
            <div className="grid-batch1" >
                <div className="mb10"><ScienceIcon /></div>
                <div>
                <div className=" title-lg batch-title text-center">{practicelabBookmark.length}</div>
                    <div className="batch-label title-lg">Practice Lab</div>
                    
                </div>
                <div className="jce">
                    <div className="grid-batch-icon">
                        <i className="bi bi-arrows-angle-expand"></i>
                    </div>
                </div>
            </div>
        </div>
}
</div>

                       

                }
                <hr />

                {/* showDocument Content*/}

                {
                    isShownDocument && (
                        <>
                            {
                                documentBookmark === null ? <h1>Document Bookmark not found </h1> :
                                    <div className='card mb-2 pb-2 rounded'>
                                        {
                                            documentBookmark.map((item) => {
                                                return (
                                                    <>
                                                        <div className='ml-3 mt-3 d-flex' style={{ alignItems: "center" }}>

                                                            <img src='https://cdn.pixabay.com/animation/2023/06/13/15/13/15-13-39-266_512.gif' height="60px" width="60px" />


                                                            <div className='pl-3' onClick={() => { setShow(true); setContentLink(item.contentDetails.documentDetails.contentLink) }} style={{ cursor: "pointer" }} data-tooltip-id="notestooltip" data-tooltip-content="Click Here to show your Bookmark Content...">

                                                                <h6 className='title-sm' >{item.contentName} <span data-tooltip-id="notestooltip" data-tooltip-content="Click Here to show your Bookmark Content..." ><StarIcon fontSize='small' /></span></h6>



                                                                <p>{item.trainingName}</p>
                                                            </div>


                                                            <Tooltip
                                                                id="notestooltip"
                                                                data-tooltip-place="right"
                                                                variant="info"
                                                            />




                                                        </div>

                                                    </>
                                                )
                                            })
                                        }

                                    </div>
                            }
                        </>


                    )
                }


                {/* showVideo Content */}

                {
                    isShownVideos && (
                        <>
                            {videoBookmark === null ? <h1>Document Bookmark not found </h1> :
                                <div className='card pb-2'>
                                    {
                                        videoBookmark.map((item) => {
                                            return (
                                                <>
                                                    <div className='ml-3 mt-3 d-flex' style={{ alignItems: "center" }}>

                                                        <img src='https://img.freepik.com/free-vector/digital-social-marketing_24877-49328.jpg?size=626&ext=jpg&ga=GA1.1.1406700243.1687936719&semt=ais' height="60px" width="60px" />
                                                     

                                                        <div className='pl-3' style={{ cursor: "pointer" }} onClick={() => { setShow(true); setContentLink(item.contentDetails.videoDetails.contentLink) }} data-tooltip-id="bookmarks" data-tooltip-content="Click Here to show your Bookmark Content...">

                                                            <h6 className='title-sm' >{item.contentName} <span data-tooltip-id="bookmarks" data-tooltip-content="Click Here to show your Bookmark Content..." ><StarIcon fontSize='small' /></span></h6>



                                                            <p>{item.trainingName}</p>
                                                        </div>


                                                        <Tooltip
                                                            id="bookmarks"
                                                            data-tooltip-place="right"
                                                            variant="info"
                                                        />




                                                    </div>

                                                </>
                                            )
                                        })
                                    }

                                </div>
                            }
                        </>
                    )
                }

                {/* showPracticelab Content */}

                {
                    isShownPracticeLab && (
                        <>
                            {practicelabBookmark === null ? <h1>Lab Bookmark not found </h1> :
                                <div className='card pb-2'>
                                    {
                                        practicelabBookmark.map((item) => {
                                            return (
                                                <>
                                                    <div className='ml-3 mt-3 d-flex' style={{ alignItems: "center" }}>

                                                        <img src='https://cdn.dribbble.com/users/1141571/screenshots/3978202/dribbble_4.gif' height="60px" width="60px" />


                                                        <div className='pl-3' style={{ cursor: "pointer" }}
                                                            onClick={() => navigate("/training/training-details/labs", {
                                                                state: {
                                                                    labDescription: item.contentDetails.labDetails.labDescription, 
                                                                    labOverview: item.contentDetails.labDetails.labOverview, 
                                                                    labSolution: item.contentDetails.labDetails.labSolution, 
                                                                    labId: item.contentDetails.labDetails.labId, 
                                                                    contentSid: item.contentDetails.labDetails.sectionSid, 
                                                                    trainingSid: item.contentDetails.labDetails.trainingSid, 
                                                                    labDuration: item.contentDetails.labDetails.labDuration, 
                                                                    showcoursename: item.contentName, 
                                                                    type: "LAB", 
                                                                    codingQuestionId: '', 
                                                                    codingQuestiondesc: '', 
                                                                    evaluatedLab: item.contentDetails.labDetails.assessmentLab,
                                                                    bookmark: true,
                                                                    buttonText: item.contentDetails.labDetails.buttonText
                                                                }
                                                            })
                                                            }
                                                            data-tooltip-id="notestooltip" data-tooltip-content="Click Here to show your Bookmark Content...">

                                                            <h6 className='title-sm' >{item.contentName} <span data-tooltip-id="notestooltip" data-tooltip-content="Click Here to show your Bookmark Content..." ><StarIcon fontSize='small' /></span></h6>



                                                            <p>{item.trainingName}</p>
                                                        </div>

                                                        <Tooltip
                                                            id="notestooltip"
                                                            data-tooltip-place="right"
                                                            variant="info"
                                                        />

                                                    </div>

                                                </>
                                            )
                                        })
                                    }

                                </div>
                            }
                        </>
                    )
                }














            </div>
            }
        </>
    )
}

export default Bookmark