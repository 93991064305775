import React from "react";
import { Collapse } from "react-collapse";
import { css } from "@emotion/css";

class DropdownItem extends React.Component {
  state = {
    isDropdownOpen: this.props.isOpen,
    dropdownToggleIcon: this.props.isOpen ? "^" : "˅"
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setState({
        isDropdownOpen: this.props.isOpen,
        dropdownToggleIcon: this.props.isOpen ? "^" : "˅"
      });
    }
  }

  onDropdownClicked = () => {
    const newState = !this.state.isDropdownOpen;
    this.setState({
      isDropdownOpen: newState,
      dropdownToggleIcon: newState ? "^" : "˅"
    });
    this.props.toggleDropdown(newState);
  };

  render() {
    const { title, total, theme, children } = this.props;
    const { isDropdownOpen, dropdownToggleIcon } = this.state;

    return (
      <div style={{ backgroundColor: isDropdownOpen ? "#fff" : "inherit" }}
        className={theme === "dark" ? accordionContainerDark : accordionContainerLight}
        id={this.props.id}
      >
        <div style={{ backgroundColor: isDropdownOpen ? "#F5F8FB" : "inherit", }}
          className={theme === "dark" ? headerBarDark : headerBarLight}
          onClick={this.onDropdownClicked}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div  >{title}</div>
            <p className="pt-1 pb-1" style={{ fontSize: "16px", fontWeight: "400", color: "#475467" }}>{total}</p>
          </div>
          <button className={theme === "dark" ? openAccordionToggleDark : openAccordionToggleLight}>
            {dropdownToggleIcon}
          </button>
        </div>
        <Collapse isOpened={isDropdownOpen}>
          <div className={displayText}>{children}</div>
        </Collapse>
      </div>
    );
  }
}

export default DropdownItem;

//Light Theme
const headerBarLight = css`
  display: flex;
  justify-content: space-between;
  border-bottom: 2px  #FAFAFA;
  // margin-bottom: 12px;
  padding: 5px;
  font-size: 20px;
`;

const openAccordionToggleLight = css`
  background-color: transparent;
  border: none;
  font-size: 18px !important;
  font-family: 'Inter' !important;
  cursor: pointer;
  color: #272D3B;
    font-weight: 500
`;

const displayText = css`
  text-align: left;
`;

const accordionContainerLight = css`
  margin: 10px;
  color: black;
`;
const color = 'darkgreen'
//Dark Theme
const headerBarDark = css`
  display: flex;
  justify-content: space-between;
  border-bottom: 0.1px ridge #EAECF0;
  cursor:pointer;
  margin-bottom: 10px;
 
  padding: 5px 14px !important;
  font-size: 16px !important;
  font-family: 'Inter';
  color: #272D3B;
    font-weight: bold;
    &:hover{
      background-color: #ececec;
border-radius:1px
    }`;

const accordionContainerDark = css`
  // margin: 10px;
  // color: #272D3B;
  // font-weight: bold
  // font-size: 12px !important;
  // font-family: 'Inter';

  justify-content: space-between;
// border-bottom: 0.1px ridge #EAECF0;
cursor: pointer;
// margin-bottom: 10px;
// padding: 5px;
font-size: 16px!important;
font-family: 'Inter';
color: #101828;
font-weight: 500;
`;

const openAccordionToggleDark = css`
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
-webkit-box-pack: justify;
-webkit-justify-content: space-between;
justify-content: space-between;
// border-bottom: 0.1px ridge #EAECF0;
cursor: pointer;
margin-bottom: 10px;
padding: 5px;
font-size: 20px!important;
font-family: 'Inter';
color: #101828;
font-weight: 900;
`;
