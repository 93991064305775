import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

export function ScrollableStackBarCharts({ trainingStacked, series }) {
  const categories = [
    'Category 1', 'Category 2', 'Category 3', 'Category 4', 'Category 5',
    'Category 6', 'Category 7', 'Category 8', 'Category 9', 'Category 10',
    // ... Add more categories as needed
  ];

  const chartHeight = 50 * trainingStacked.length;

  // Define colors for each series
  const seriesColors = ['#3D30A2', '#B15EFF', '#DFCCFB', '#B15EFF', '#B15EFF', '#B15EFF'];

  // Assign colors to each series in the 'series' array
  const coloredSeries = series.map((s, index) => ({
    ...s,
    color: seriesColors[index % seriesColors.length],
  }));

  const options = {
    chart: {
      type: 'bar',
      height: chartHeight,
    },
    title: {
      text: 'Average Assessment Percentage',
      y: 10,
    },
    xAxis: {
      categories: trainingStacked,
      title: {
        text: null,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Total value',
        align: 'high',
      },
    },
    legend: {
      align: 'right',
      x: -30,
      verticalAlign: 'bottom',
      y: 20,
      floating: true,
      backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false,
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
    },
    plotOptions: {
      bar: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
        pointPadding: 0.1,
        groupPadding: 0.1,
      },
    },
    series: coloredSeries, // Use the modified series array with colors
  };

  return (
    <div style={{ overflowY: 'auto', height: '400px' }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
}
