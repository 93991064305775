import { Link } from '@reach/router';
import { useEffect } from 'react';
import { useNavigate } from '@reach/router';


const DefaultPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const url = `https://learnlytica.com/mycourses`;
    window.location.href = url;
    // navigate('/training/training-details/');
  }, []);

  return (
    <></>
    // <div style={{ textAlign: 'center', marginTop: '50px' }}>
    //   <h1>Page Not Found</h1>
    //   <p>The page you're looking for doesn't exist or has been moved.</p>
    //   <Link to="/training/training-details/">Go to Training Page</Link>
    // </div>
  );
};

export default DefaultPage;
