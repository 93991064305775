import React, { useState, useEffect, useContext, useRef, useMemo } from 'react'
import { ICN_ASSESSMT, ICN_EDIT, ICN_EXPAND_NEW, ICN_MORE_DETAILS, ICN_NOTE_EDIT, ICN_OPEN_SANDBOX, ICN_START_ASSESS, ICN_STRAT_SESSION, ICN_COMING_BATCHES, ICN_COPY, REPORT_ICON_BATCH, REPORT_ICON_GO_SOMEWHERE, REPORT_ICON_GROUP, REPORT_ICON_LEARNER, ICN_STD_MATERIAL, ICN_VDO, ICN_LABASS, ICN_CODINGASS, EMAIL_ICON, ICN_TRAINING_SESSION } from '../../Common/Icon';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Checkbox } from '@mui/material';
import { LinearProgress } from '@mui/material';
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Link, useNavigate } from '@reach/router';
import TimerIcon from '@mui/icons-material/Timer';
import ScienceIcon from '@mui/icons-material/Science';
import RestService from '../../../Services/api.service';
import AppContext from '../../../Store/AppContext';
import InfoIcon from '@mui/icons-material/Info';
import LoadingSpinner from '../Training/LoadingSpinner';
import { Button } from '../../Common/Buttons/Buttons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MailIcon from '@mui/icons-material/Mail';
import { WithContext as ReactTags } from "react-tag-input";
import useToast from '../../../Store/ToastHook';
import SendIcon from '@mui/icons-material/Send';
import SkeletonLoader from '../../Common/SkeletonLoader/SkeletonLoader';
import TrainingOverviewRadar from './TrainingOverviewRadar';
import AverageAssessmentPercentagesChart from './AverageAssessmentPercentagesChart';
import { ScrollableStackBarCharts } from './NewCharts/ScrollableStackBarCharts';

//error component
const ErrorComponent = () => {
    return (
        <div class="row justify-content-center">
            <div class="col-md-12 col-sm-12">
                <div class="card shadow-lg border-0 rounded-lg mt-5 mx-auto" style={{ width: "30rem" }}>
                    <h3 class="card-header display-1 text-muted text-center">
                        404
                    </h3>

                    <span class="card-subtitle mb-2 text-muted text-center">
                        Something went wrong
                    </span>

                    {/* <div class="card-body mx-auto">
                      <button class="btn btn-sm btn-info text-white" onClick={() => navigate("/training", { state: { title: "Training" } })}>
                          Back To Training
                      </button>
                    
                  </div> */}
                </div>
            </div>
        </div>
    )
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal d-block" : "modal d-none";

    return (

        <div className={showHideClassName}  >

            <div className="modal-container modal-xl" >
                <div style={{ float: "right" }} className='circle-md'> <div className="modal-close" onClick={handleClose}>
                    X
                </div></div>
                {children}

            </div>
        </div>
    );
};

const NewSupervisorReport = () => {

    //training
    const [trainingDataAll, setTrainingDataAll] = useState([]);
    const [trainingDetails, setTrainingDetails] = useState({});
    const [trainingHeaderDetails, setTrainingHeaderDetails] = useState({});

    //learner
    const [learnerDataAll, setLearnerDataAll] = useState([]);
    const [learnerDetails, setLearnerDetails] = useState({});
    const [learnerHeaderDetails, setLearnerHeaderDetails] = useState({});

    //assessment
    const [assessmentDataAll, setAssessmentdataAll] = useState([]);
    const [assessmentDetails, setAssessmentDetails] = useState({});
    const [assessmentHeaderDetails, setAssessmentHeaderDetails] = useState({});

    const [reportTrainings, setReportTrainings] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const { setCourse, setBatches, ROLE, course, spinner, user, batches } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [tags, setTags] = useState([]);
    const [loader, setLoader] = useState(true);
    const [reportType, setReportType] = useState('');
    const [trainingStacked, setTrainingStacked] = useState([]);
    const [series, setSeries] = useState([]);
    const Toast = useToast();

    const data = [25, 5, 10, 15];
    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    const navigate = useNavigate();

    const openSpecificLearner = () => {
        // Replace 'your_route_here' with the actual route you want to navigate to
        navigate(`/report/learner/${learnerDetails.learnerName}`, { state: { progress: learnerDetails, subTitle: `${learnerDetails.learnerName}`, subPath: `${learnerDetails.learnerName}` } });
    };

    const openSpecificTraining = () => {
        // Replace 'your_route_here' with the actual route you want to navigate to
        navigate(`/report/training/${trainingDetails.trainingName}`, { state: { progress: trainingDetails, subTitle: `${trainingDetails.trainingName}`, subPath: `${trainingDetails.trainingName}` } });
    };
    const openSpecificAssessment = () => {
        // Replace 'your_route_here' with the actual route you want to navigate to
        navigate(`/report/assessment/${assessmentDetails.trainingName}`, { state: { progress: assessmentDetails, subTitle: `${assessmentDetails.trainingName}`, subPath: `${assessmentDetails.trainingName}` } });
    };

    const [activeTab, setActiveTab] = useState('learner'); // Set the default active tab
    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    const [counter, setCounter] = useState(20);

    //send mail
    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    //learner table details


    const columnsLearner = useMemo(
        () => [
            //   {
            //     accessorKey: 'id',
            //     enableColumnPinning: false,
            //     header: 'ID',
            //     size: 50,
            //   },
            {
                accessorKey: 'select',
                header: 'Select',
                Cell: ({ row }) => (
                    <Checkbox
                        checked={selectedRows.includes(row.original.id)}
                        onChange={() => {
                            const newSelectedRows = selectedRows.includes(row.original.id)
                                ? selectedRows.filter((id) => id !== row.original.id)
                                : [...selectedRows, row.original.id];

                            setSelectedRows(newSelectedRows);
                            setLearnerDetails(row.original);
                        }}
                    />
                ),
                size: 50,
            },
            {
                accessorKey: 'learnerName',
                header: 'Name',
            },
            {
                accessorKey: 'learnerEmail',
                header: 'Email',
                size: 400,
            },
            {
                accessorKey: 'createdOn',
                header: 'CreatedOn',
                size: 400,
            },
            {
                accessorKey: 'userType',
                header: 'Type',
            },
            {
                accessorKey: 'passed',
                header: 'Passed',
            },
            {
                accessorKey: 'failed',
                header: 'Failed',
            },
            {
                accessorKey: 'inProgress',
                header: 'Progress',
            },
            {
                accessorKey: 'completed',
                header: 'Completed',
            },
            {
                accessorKey: 'overdue',
                header: 'Overdue',
            },
            {
                accessorKey: 'notStarted',
                header: 'Not Started',
            },
            {
                accessorKey: 'enrolledBy',
                header: 'Enrolled By',
                size: 300
            },
            {
                accessorKey: 'lastLoggedIn',
                header: 'Last LoggedIn',
                size: 400
            }
        ],
        [selectedRows],
    );

    const tableLearner = useMaterialReactTable({
        columns: columnsLearner,
        data: learnerDataAll,
        enableColumnPinning: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        layoutMode: 'grid-no-grow',
        initialState: {
            columnPinning: {
                left: ['select', 'learnerName'],
                //  right: ['city'] 
            },

        },
    });

    //training table details

    const columnsTraining = useMemo(
        () => [
            // {
            //   accessorKey: 'id',
            //   enableColumnPinning: false,
            //   header: 'ID',
            //   size: 50,
            // },
            {
                accessorKey: 'select',
                header: 'Select',
                Cell: ({ row }) => (
                    <Checkbox
                        checked={selectedRows.includes(row.original.id)}
                        onChange={() => {
                            setTrainingDetails(row.original);
                            const newSelectedRows = selectedRows.includes(row.original.id)
                                ? selectedRows.filter((id) => id !== row.original.id)
                                : [...selectedRows, row.original.id];

                            setSelectedRows(newSelectedRows);
                        }}
                    />
                ),
                size: 50,
            },
            {
                accessorKey: 'trainingName',
                header: 'Training',
            },
            {
                accessorKey: 'trainingStatus',
                header: 'Status',
            },
            {
                accessorKey: 'duration',
                header: 'Duration',
                size: 300
            },
            {
                accessorKey: 'traningDurationCompletion',
                header: 'Training Duration Completion',
                Cell: ({ renderedCellValue, row }) => <div

                >{`${renderedCellValue.toFixed(2)}%`}

                </div>
            },
            {
                accessorKey: 'instructor',
                header: 'Instructor',
            },
            {
                accessorKey: 'courseName',
                header: 'Course',
            },
            {
                accessorKey: 'batchCount',
                header: 'Batch Count',
            },
            {
                accessorKey: 'enrollments',
                header: 'Enrollments',
            },
            {
                accessorKey: 'trainedPercentage',
                header: 'Trained Percentage',
            },
            {
                accessorKey: 'passed',
                header: 'Passed'
            },
            {
                accessorKey: 'failed',
                header: 'Failed',
            },
            {
                accessorKey: 'inProgress',
                header: 'Progress',
            },
            {
                accessorKey: 'notStarted',
                header: 'Not Started'
            },
            {
                accessorKey: 'completed',
                header: 'Completed'
            },
            {
                accessorKey: 'overdue',
                header: 'Overdue'
            }
        ],
        [selectedRows],
    );

    const tableTraining = useMaterialReactTable({
        columns: columnsTraining,
        data: trainingDataAll,
        enableColumnPinning: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        layoutMode: 'grid-no-grow',
        initialState: {
            columnPinning: {
                left: ['select', 'trainingName'],
                //  right: ['city'] 
            },

        },
    });

    //assessment table details
    const columnsAssessment = useMemo(
        () => [
            //   {
            //     accessorKey: 'id',
            //     enableColumnPinning: false,
            //     header: 'ID',
            //     size: 50,
            //   },
            {
                accessorKey: 'select',
                header: 'Select',
                Cell: ({ row }) => (
                    <Checkbox
                        checked={selectedRows.includes(row.original.id)}
                        onChange={() => {
                            const newSelectedRows = selectedRows.includes(row.original.id)
                                ? selectedRows.filter((id) => id !== row.original.id)
                                : [...selectedRows, row.original.id];

                            setSelectedRows(newSelectedRows);
                            setAssessmentDetails(row.original);
                        }}
                    />
                ),
                size: 50,
            },
            {
                accessorKey: 'trainingName',
                header: 'Training',
            },
            {
                accessorKey: 'totalParticipants',
                header: 'Total Participants'
            },
            {
                accessorKey: 'totalAssessments',
                header: 'Total Assessments',
            },
            {
                accessorKey: 'mcqAssessments',
                header: 'Mcq Assessments'
            },
            {
                accessorKey: 'avgMcqPercentage',
                header: 'Avg Mcq Percentage',
                Cell: ({ renderedCellValue, row }) => <div

                >{`${renderedCellValue.toFixed(2)}%`}

                </div>
            },
            {
                accessorKey: 'codingAssessments',
                header: 'Coding Assessments'
            },
            {
                accessorKey: 'avgCodingPercentage',
                header: 'Avg CodingPercentage',
                Cell: ({ renderedCellValue, row }) => <div

                >{`${renderedCellValue.toFixed(2)}%`}

                </div>
            },
            {
                accessorKey: 'labAssessments',
                header: 'Lab Assessments'
            },
            {
                accessorKey: 'labAssessmentPercentage',
                header: 'Lab Assessment Percentage',
                Cell: ({ renderedCellValue, row }) => <div

                >{`${renderedCellValue.toFixed(2)}%`}

                </div>
            }
        ],
        [selectedRows],
    );

    const tableAssessment = useMaterialReactTable({
        columns: columnsAssessment,
        data: assessmentDataAll,
        enableColumnPinning: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        layoutMode: 'grid-no-grow',
        initialState: {
            columnPinning: {
                left: ['select', 'trainingName'],
                //  right: ['city'] 
            },

        },
    });



    //export as pdf
    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = async () => {
        const pdf = new jsPDF("portrait", "pt", "a4");
        const data = await html2canvas(document.querySelector("#capture"));
        const img = data.toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        spinner.hide();
        pdf.save("overall_training_report.pdf");

        // html2canvas(document.querySelector("#capture")).then(canvas => {
        //   document.body.appendChild(canvas);  // if you want see your screenshot in body.
        //   const imgData = canvas.toDataURL('image/png');
        //   const pdf = new jsPDF({
        //     format: 'a4',
        //     unit: 'px',
        //   });
        //   pdf.addImage(imgData, 'PNG', 0, 0);
        //   pdf.save("download.pdf");
        // });
        // const doc = new jsPDF({
        //   format: 'a4',
        //   unit: 'px',
        // });

        // Adding the fonts.
        // doc.setFont('Inter-Regular', 'normal');

        // doc.html(reportTemplateRef.current, {
        //   async callback(doc) {
        //     await doc.save('document');
        //   },
        // });
        // doc.html(document.body, {
        //   callback: function () {
        //     doc.save('page');
        //   },
        // });
    };


    //send mail
    const sendSupervisorReportMail = () => {
        try {
            spinner.show();
            let tagsCsv = tags.map((item) => item.text);
            const payload = {
                "recipients": tagsCsv,
                "reportType": "SUPERVISOR_MAIN"
            }
            RestService.sendSupervisorReportMail(payload).then(
                response => {
                    Toast.success({ message: `Mail Sent Successfully`, time: 2000 });
                    setTags([])
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: `Try Again`, time: 2000 });
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on sendSupervisorReportMail()", err)
        }
    }

    //all training details
    const allTrainingScreenDetails = () => {
        try {
            // spinner.show();
            RestService.allTrainingScreenDetails().then(
                response => {
                    if (response.status === 200) {
                        const updatedData = response.data.trainingDetails.map((item, index) => ({
                            ...item,
                            id: index + 1,
                        }));
                        setTrainingHeaderDetails(response.data.headerDetails);
                        setTrainingDataAll(updatedData);
                    }
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: `Try Again`, time: 2000 });
                }
            )
        } catch (err) {
            console.error("error occur on allTrainingScreenDetails()", err)
        }
    }

    //all learner details
    const getAllLearnerScreenDetails = () => {
        try {
            // spinner.show();

            RestService.getAllLearnerScreenDetails().then(
                response => {
                    if (response.status === 200) {
                        const updatedData = response.data.learnerDetails.map((item, index) => ({
                            ...item,
                            id: index + 1,
                        }));
                        setLearnerHeaderDetails(response.data.headerDetails);
                        setLearnerDataAll(updatedData);
                        setTimeout(() => {
                            setLoader(false);
                        }, 2000);
                    }
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: `Try Again`, time: 2000 });
                }
            )
        } catch (err) {
            console.error("error occur on getAllLearnerScreenDetails()", err)
        }
    }

    //all assessment details
    const allAssessmentScreenDetails = () => {
        try {
            // spinner.show();

            RestService.allAssessmentScreenDetails().then(
                response => {
                    if (response.status === 200) {
                        const updatedData = response.data.assessmentDetails.map((item, index) => ({
                            ...item,
                            id: index + 1,
                        }));
                        const trainingStacked = response.data.assessmentDetails.map(assessment => assessment.trainingName);
                        setTrainingStacked(trainingStacked);
                        const mcqAssessment = response.data.assessmentDetails.map(mcq => parseFloat(mcq.avgMcqPercentage.toFixed(2)));
                        const avgCodingPercentage = response.data.assessmentDetails.map(mcq => parseFloat(mcq.avgCodingPercentage.toFixed(2)));
                        const labAssessmentPercentage = response.data.assessmentDetails.map(mcq => parseFloat(mcq.labAssessmentPercentage.toFixed(2)));
                        const series =  [{
                            name: 'MCQ Assessment',
                            data: mcqAssessment // Replace with actual data
                        }, {
                            name: 'Lab Assessment',
                            data: avgCodingPercentage // Replace with actual data
                        }, {
                            name: 'Coding Assessment',
                            data: labAssessmentPercentage // Replace with actual data
                        }]
                        setSeries(series);
                        setAssessmentHeaderDetails(response.data.headerDetails);
                        setAssessmentdataAll(updatedData)
                    }
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: `Try Again`, time: 2000 });
                }
            )
        } catch (err) {
            console.error("error occur on allAssessmentScreenDetails()", err)
        }
    }


    useEffect(() => {
        // allTrainingScreenDetails();
        // getAllLearnerScreenDetails();
        allAssessmentScreenDetails();
    }, []);

    return (
        <div>
            {
                !isLoading ?

                    <ErrorComponent />
                    :
                    <>
                        {/* New ui */}
                        <div class="tabset" >

                            <input
                                type="radio"
                                name="tabset"
                                id="tab1"
                                aria-controls="learner"
                                checked={activeTab === 'learner'}
                                onChange={() => handleTabChange('learner')}
                            />
                            <label htmlFor="tab1" style={{ marginBottom: "0px" }}>By Learner</label>

                            <input
                                type="radio"
                                name="tabset"
                                id="tab2"
                                aria-controls="assessment"
                                checked={activeTab === 'assessment'}
                                onChange={() => handleTabChange('assessment')}
                            />
                            <label htmlFor="tab2" style={{ marginBottom: "0px" }}>By Training</label>

                            <input
                                type="radio"
                                name="tabset"
                                id="tab3"
                                aria-controls="training"
                                checked={activeTab === 'training'}
                                onChange={() => handleTabChange('training')}
                            />
                            <label htmlFor="tab3" style={{ marginBottom: "0px" }}>By Assessment</label>

                            <div class="tab-panels">
                                {/* bylearner */}

                                <section id="learner" className={`tab-panel ${activeTab === 'learner' ? 'active' : ''}`}>
                                    <div className='row'>
                                        <div className='col-md-4'>
                                            <div className='parrentreportcard' >
                                                <div className='reportcard'>
                                                    <div className='d-flex' style={{ justifyContent: "space-between" }}> <div className="mb10 reprtcard-icon">{REPORT_ICON_LEARNER}</div>
                                                        <div className="mb10 go-somewhere" onClick={() => navigate('/report/by-learner')}>{REPORT_ICON_GO_SOMEWHERE}</div>
                                                    </div>

                                                    <div className='reportcardheader'>Learner Progress</div>

                                                    <div className='reportcardtitle'>Track completion, engagement, and performance scores for individual learners.</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-4'>
                                            <div className='parrentreportcard' >
                                                <div className='reportcard'>
                                                    <div className='d-flex' style={{ justifyContent: "space-between" }}> <div className="mb10 reprtcard-icon">{REPORT_ICON_GROUP}</div>
                                                        <div className="mb10 go-somewhere">{REPORT_ICON_GO_SOMEWHERE}</div>
                                                    </div>

                                                    <div className='reportcardheader'>Group Progress</div>

                                                    <div className='reportcardtitle'>Analyze collective achievements and challenges of grouped learners.</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-4'>
                                            <div className='parrentreportcard' >
                                                <div className='reportcard'>
                                                    <div className='d-flex' style={{ justifyContent: "space-between" }}> <div className="mb10 reprtcard-icon">{REPORT_ICON_BATCH}</div>
                                                        <div className="mb10 go-somewhere">{REPORT_ICON_GO_SOMEWHERE}</div>
                                                    </div>
                                                    <div className='reportcardheader'>Batch Progress</div>

                                                    <div className='reportcardtitle'>View how multiple batches progress through training programs.</div>
                                                </div>
                                            </div>
                                        </div>


                                    </div>

                                    {/* <>
                                    <div className='specificreportheader my-4'>Learner Progress</div>

                                    <div className='row ' style={{ justifyContent: "space-between" }}>

                                        <div className='col-md-3 '>

                                            <div className='reportcard'>
                                                graph1
                                            </div>

                                        </div>
                                        <div className='col-md-3 '>

                                            <div className='reportcard'>
                                                graph1
                                            </div>

                                        </div>
                                        <div className='col-md-6 '>

                                            <div className='reportcard'>
                                                others

                                            </div>
                                        </div>

                                    </div>

                                    <div className='my-4'>

                                        <div className='d-flex' style={{ justifyContent: "space-between" }}>
                                            <div className='mb-2'>
                                                {
                                                    selectedRows.length === 1 &&

                                                    <>
                                                        <button className='emailbtn' onClick={openSpecificLearner}><span className='mr-2'>{EMAIL_ICON}</span>Learner Results</button>
                                                        <button className='emailbtn mx-2'><span className='mr-2'>{EMAIL_ICON}</span>Learner's Enrollment History</button>

                                                    </>
                                                }
                                            </div>

                                        </div>

                                        {
                                            learnerDataAll.length !== 0 ?
                                                <MaterialReactTable table={tableLearner} />
                                                :
                                                <p className='title-md text-center'>No Result Found</p>
                                        }


                                    </div>
                                    </> */}

                                </section>

                                {/* Training report */}

                                <section id="training" className={`tab-panel ${activeTab === 'training' ? 'active' : ''}`}>
                                    <div className='row p-30' style={{ justifyContent: "space-between" }}>

                                        <div className='col-md-4 '>

                                            <div className='reportcard-course' >

                                                <div className='d-flex align-items-center'>
                                                    <div className="mb10 reprtcard-icon">{ICN_STD_MATERIAL}</div>
                                                    <div className='reportcardheader mx-5'>Reading Material</div>
                                                    <div className="mb10 go-somewhere" style={{ marginLeft: 'auto' }}>{REPORT_ICON_GO_SOMEWHERE}</div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-md-4 '>

                                            <div className='reportcard-course' >

                                                <div className='d-flex align-items-center'>
                                                    <div className="mb10 reprtcard-icon">{ICN_VDO}</div>
                                                    <div className='reportcardheader mx-5'>Videos</div>
                                                    <div className="mb10 go-somewhere" style={{ marginLeft: 'auto' }}>{REPORT_ICON_GO_SOMEWHERE}</div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-md-4 '>

                                            <div className='reportcard-course' >

                                                <div className='d-flex align-items-center'>
                                                    <div className="mb10 reprtcard-icon">{ICN_ASSESSMT}</div>
                                                    <div className='reportcardheader mx-5'>MCQ Assessment</div>
                                                    <div className="mb10 go-somewhere" style={{ marginLeft: 'auto' }}>{REPORT_ICON_GO_SOMEWHERE}</div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                    <div className='row p-30' style={{ justifyContent: "space-between" }}>

                                        <div className='col-md-4 '>

                                            <div className='reportcard-course' >

                                                <div className='d-flex align-items-center'>
                                                    <div className="mb10 reprtcard-icon">{ICN_LABASS}</div>
                                                    <div className='reportcardheader mx-5'>Practice Labs</div>
                                                    <div className="mb10 go-somewhere" style={{ marginLeft: 'auto' }}>{REPORT_ICON_GO_SOMEWHERE}</div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-md-4 '>

                                            <div className='reportcard-course' >

                                                <div className='d-flex align-items-center'>
                                                    <div className="mb10 reprtcard-icon">{ICN_LABASS}</div>
                                                    <div className='reportcardheader mx-5'>Lab Assessment</div>
                                                    <div className="mb10 go-somewhere" style={{ marginLeft: 'auto' }}>{REPORT_ICON_GO_SOMEWHERE}</div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-md-4 '>

                                            <div className='reportcard-course' >

                                                <div className='d-flex align-items-center'>
                                                    <div className="mb10 reprtcard-icon">{ICN_CODINGASS}</div>
                                                    <div className='reportcardheader mx-5'>Coding Assessment</div>
                                                    <div className="mb10 go-somewhere" style={{ marginLeft: 'auto' }}>{REPORT_ICON_GO_SOMEWHERE}</div>
                                                </div>


                                            </div>
                                        </div>
                                        <div className='row p-30 col-md-4 '>

                                            <div className='reportcard-course' >

                                                <div className='d-flex align-items-center'>
                                                    <div className="mb10 reprtcard-icon">{ICN_TRAINING_SESSION}</div>
                                                    <div className='reportcardheader mx-5'>Training Progress</div>
                                                    <div className="mb10 go-somewhere" style={{ marginLeft: 'auto' }} onClick={() => navigate('/training-report/by-training')}>{REPORT_ICON_GO_SOMEWHERE}</div>
                                                </div>


                                            </div>
                                        </div>

                                    </div>
                                    {/* 
                                    <div className='specificreportheader my-4'>Training Progress</div>

                                    <div className='row ' style={{ justifyContent: "space-between" }}>

                                        <div className='col-md-3 '>

                                            <div className='reportcard'>
                                                graph1
                                            </div>

                                        </div>
                                        <div className='col-md-3 '>

                                            <div className='reportcard'>
                                                graph1
                                            </div>

                                        </div>
                                        <div className='col-md-6 '>

                                            <div className='reportcard'>
                                                others

                                            </div>
                                        </div>

                                    </div>

                                    <div className='my-4'>

                                        <div className='d-flex' style={{ justifyContent: "space-between" }}>
                                            <div className='mb-2'>
                                                {
                                                    selectedRows.length === 1 &&

                                                    <>
                                                        <button className='emailbtn' onClick={openSpecificTraining}><span className='mr-2'>{EMAIL_ICON}</span>Training Results</button>

                                                    </>
                                                }
                                            </div>

                                        </div>
                                        {
                                            trainingDataAll.length !== 0 &&
                                            <MaterialReactTable table={tableTraining} />
                                        }


                                    </div> */}

                                </section>
                                {/* <section id="training" className={`tab-panel ${activeTab === 'training' ? 'active' : ''}`}>
                                    <div className='specificreportheader my-4'>Training Progress</div>

                                    <div className='row ' style={{ justifyContent: "space-between" }}>

                                        <div className='col-md-3 '>

                                            <div className='reportcard'>
                                                graph1
                                            </div>

                                        </div>
                                        <div className='col-md-3 '>

                                            <div className='reportcard'>
                                                graph1
                                            </div>

                                        </div>
                                        <div className='col-md-6 '>

                                            <div className='reportcard'>
                                                others

                                            </div>
                                        </div>

                                    </div>

                                    <div className='my-4'>

                                        <div className='d-flex' style={{ justifyContent: "space-between" }}>
                                            <div className='mb-2'>
                                                {
                                                    selectedRows.length === 1 &&

                                                    <>
                                                        <button className='emailbtn' onClick={openSpecificTraining}><span className='mr-2'>{EMAIL_ICON}</span>Training Results</button>

                                                    </>
                                                }
                                            </div>

                                        </div>
                                        {
                                            trainingDataAll.length !== 0 &&
                                            <MaterialReactTable table={tableTraining} />
                                        }


                                    </div>
                                </section> */}


                                {/* assessment report */}


                                <section id="assessment" className={`tab-panel ${activeTab === 'assessment' ? 'active' : ''}`}>
                                    <div className='specificreportheader my-4'>Assessment Progress</div>

                                    <div className='row ' style={{ justifyContent: "space-between" }}>

                                        {/* <div className='col-md-3 '>
                                            {
                                                loader ?
                                                    <SkeletonLoader skeletonCount={2.7} />
                                                    :

                                                    <div className='reportcard'>
                                                        <TrainingOverviewRadar radarData={data} />
                                                    </div>
                                            }

                                        </div> */}
                                        <div className='col-md-6 '>
                                            

                                                    <div className='reportcard'>
                                                        <ScrollableStackBarCharts trainingStacked={trainingStacked} series={series} />
                                                    </div>
                                            

                                        </div>
                                        <div className='col-md-6'>
                                           

                                                    <div className='reportcard d-flex h-100 ' style={{ justifyContent: "space-between" }}>
                                                        <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                            <div className='reportcardheader'>
                                                                Total Assessment

                                                            </div>
                                                            <div className='text-center reportcardtitle'>
                                                                {
                                                                    assessmentHeaderDetails?.totalAssessments
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                            <div className='reportcardheader'>
                                                                Mcq Assessment

                                                            </div>
                                                            <div className='text-center reportcardtitle'>
                                                                {
                                                                    assessmentHeaderDetails?.totalMcqAssessments
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                            <div className='reportcardheader'>
                                                                Coding Assessment

                                                            </div>
                                                            <div className='text-center reportcardtitle'>
                                                                {
                                                                    assessmentHeaderDetails?.totalCodingAssessments
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className='d-flex m-auto px-1' style={{ flexDirection: "column" }}>
                                                            <div className='reportcardheader'>
                                                                Labs Assessment

                                                            </div>
                                                            <div className='text-center reportcardtitle'>
                                                                {
                                                                    assessmentHeaderDetails?.totalLabAssessments
                                                                }
                                                            </div>
                                                        </div>


                                                    </div>
                                            
                                        </div>


                                    </div>

                                    <div className='my-4'>

                                        <div className='d-flex' style={{ justifyContent: "space-between" }}>
                                            <div className='mb-2'>
                                                {
                                                    selectedRows.length === 1 &&

                                                    <>
                                                        <button className='emailbtn' onClick={openSpecificAssessment}>Assessment Results</button>

                                                    </>
                                                }
                                            </div>

                                        </div>
                                        {

                                            
                                                assessmentDataAll.length !== 0 &&
                                                <MaterialReactTable table={tableAssessment} />
                                        }


                                    </div>
                                </section>


                            </div>

                        </div>
                    </>


            }

        </div>
    )
}

export default NewSupervisorReport