import { useState,useEffect } from "react";
import FadeLoader from "react-spinners/FadeLoader";

const override = {
  display: "block",
  margin: "auto",
};

const centerContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};


function NewLabspinner({ loading }) {
    
const [facts, setFacts] = useState([
    "The first computer programmer was a woman named Ada Lovelace.",
    "The first graphical web browser was called Mosaic",
    "The first computer mouse was made of wood.",
    "The world's first computer was the size of a room.",
    "The concept of a computer virus was inspired by biology.",
    "The internet weighs approximately the same as a strawberry.",
    "The world's first website is still online.",
    "The first known computer game was created in 1962.",
    "The QWERTY keyboard layout was designed to slow typists down",
    "The term \"byte\" was coined by computer scientist Werner Buchholz.",
    "The world's first webcam was used to monitor a coffee pot.",
    "The concept of a computer virus was inspired by biology.",
    "The ENIAC computer consumed enough electricity to power a small town.",
    "The first domain name ever registered was symbolics.com.",
    "The first computer password was \"login.\"",
    "The first computer mouse had only one button.",
    "The world's first computer virus was created in 1986."
  ]);

  const [currentFact, setCurrentFact] = useState('');

  useEffect(() => {
    if (facts.length > 0) {
      const interval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * facts.length);
        const fact = facts[randomIndex];
        setCurrentFact(fact);
        setFacts(prevFacts => prevFacts.filter(item => item !== fact));
      }, 4000); // Change the interval duration (in milliseconds) as desired

      return () => {
        clearInterval(interval); // Clean up the interval on component unmount
      };
    }
  }, [facts]);
  useEffect(() => {
    const isLast = (word) => {
      return word.nextElementSibling ? false : true;
    };

    const getNext = (word) => {
      return word.nextElementSibling;
    };

    const getVisible = () => {
      return document.getElementsByClassName('is-visible')[0];
    };

    const getFirst = () => {
      return document.querySelector('.words-wrapper').firstElementChild;
    };

    const switchWords = (current, next) => {
      current.classList.remove('is-visible');
      current.classList.add('is-hidden');
      next.classList.remove('is-hidden');
      next.classList.add('is-visible');
    };

    const getStarted = () => {
      const first = getVisible();
      const next = getNext(first);

      if (next) {
        switchWords(first, next);
      } else {
        first.classList.remove('is-visible');
        first.classList.add('is-hidden');
        const newEl = getFirst();
        newEl.classList.remove('is-hidden');
        newEl.classList.add('is-visible');
      }
    };

    const init = () => {
      setInterval(() => {
        getStarted();
      }, 5000);
    };

    init();
  }, []);
  return (
    <div style={centerContainerStyle}>
      <div className="sweet-loading">
        <FadeLoader
          color="#7F56D9"
          loading={loading}
          cssOverride={override}
          aria-label="Loading Spinner"
          data-testid="loader"
          height={20}
          width={6}
        />
        <div className="vmloading mt-3">Loading....</div>
          <div className="vmloader-text mt-3">{currentFact}</div>
      </div>
    </div>
  );
}

export default NewLabspinner;
