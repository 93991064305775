import React, { useContext, useEffect, useState, useRef } from "react";
import { AssessmentContext } from "../AssesementContext";
import Submit from "../common/SubmitButton";
import AssessmentCard from "./AssesmentCard";
import styles from "./AssessmentBody.module.css";
import FinishScreen from "./FinishScreen";
import AppContext from '../../../../Store/AppContext';
import RestService from '../../../../Services/api.service';
import AppUtils from '../../../../Services/Utils';
import useToast from "../../../../Store/ToastHook";
import TimesUpModal from "./TimesUpModal";
import RecordRTC from "recordrtc";
import AWS from 'aws-sdk';
import { useNavigate } from "@reach/router";
import * as faceapi from "face-api.js";
import { BsModal } from "../../../Common/BsUtils";
import Webcam from "react-webcam";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_REGION;
// const bucketUrl = process.env.REACT_APP_BUCKET_URL;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})


const Main = ({ questions }) => {
    const {
        questionIndex,
        activeQuestion,
        selectedAnswers,
        setFinished,
        finished,
        instruction,
        assUserInfo,
        hasExamEnd,
        introDialog
    } = useContext(AssessmentContext);
    const { user, ROLE, spinner } = useContext(AppContext);
    const [review, setReview] = useState(true);
    const [show, setShow] = useState(false);
    const [showTimesModal, setShowTimesModal] = useState(false);
    //start of recording
    const [recordedVideoUrl, setRecordedVideoUrl] = useState(null);
    const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);
    const [screen, setScreen] = useState(null);
    const [camera, setCamera] = useState(null);
    const [recorder, setRecorder] = useState(null);
    const [startDisable, setStartDisable] = useState(false);
    const [stopDisable, setStopDisable] = useState(true);
    const [loadModal, setLoadModal] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);
    //end of recording

    const [countab, setcountab] = useState(0);

    //variables for face detections 
    const [faceDetections, setFaceDetections] = useState(0);
    const [faceviolationcount, setFaceviolationcount] = useState(0);

    const answerKeys = 'answer' in localStorage && JSON.parse(localStorage.getItem('answer'));

    const videoRef = useRef();
    const streamRef = useRef();

    const webcamRef = useRef(null);

    const Toast = useToast();
    const navigate = useNavigate();
    // mark violation 
    const markViolation = async (violationMessage) => {
        try {
            await handleSubmitAssessment();

            let trainingSid = localStorage.getItem("trainingSid");
            let assessmentType = "ASSESSMENT";
            let payload = {
                "assessmentSid": instruction.sid,
                "labId": 0,
                "trainingSid": trainingSid,
                "violationCode": violationMessage
            }

            spinner.show();
            RestService.markViolation(assessmentType, payload).then(
                response => {

                    if (response.status === 200) {
                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on markViolation()", err)
        }
    }

    //update content mark as completed
    const markCourseAsCompleted = () => {
        try {
            let trainingSid = localStorage.getItem("trainingSid");
            let contentSid = localStorage.getItem("sid");
            let sectionSid = localStorage.getItem("sectionSid");
            let payload = {
                "completedInDuration": 0,
                "totalDuration": 0
            }
            spinner.show();
            RestService.markCourseAsCompleted(contentSid, sectionSid, trainingSid, payload).then(
                response => {

                    if (response.status === 200) {
                        // localStorage.removeItem("trainingSid");
                        localStorage.removeItem("sid");
                        localStorage.removeItem("sectionSid");

                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on markCourseAsCompleted()", err)
        }
    }

    //update content mark as completed
    const changeAssessmentStatus = () => {
        try {
            let trainingSid = localStorage.getItem("trainingSid");
            let payload = {
                "assessmentSid": instruction.sid
            }
            if (trainingSid === null) {
                payload.trainingSid = null;
            }
            else {
                payload.trainingSid = trainingSid;
            }
            spinner.show();
            RestService.changeAssessmentStatus(payload).then(
                response => {

                    if (response.status === 200) {
                        // localStorage.removeItem("trainingSid");
                        localStorage.removeItem("sid");
                        localStorage.removeItem("sectionSid");
                        // exitFullScreen();

                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on changeAssessmentStatus()", err)
        }
    }

    // this method to submit your answer for both assessment and via training
    const handleSubmitAssessment = async () => {
        try {

            spinner.show("Submitting assessment.. Please wait...");
            let payload;
            let trainingSid = localStorage.getItem("trainingSid");
            if (trainingSid !== null) {
                payload = {
                    "quizSetSid": instruction.sid,
                    "trainingSid": trainingSid,
                    "virtualAccountSid": assUserInfo.sid
                }
            }
            else {
                payload = {
                    "quizSetSid": instruction.sid,
                    "virtualAccountSid": assUserInfo.sid
                }
            }

            await RestService.submitAssessment(payload).then(
                response => {
                    spinner.hide();
                    Toast.success({ message: `Congratulation! You have submitted your assessment successfully`, time: 3000 });
                    if (trainingSid !== null) {
                        markCourseAsCompleted();
                    }
                    localStorage.removeItem('answer');
                    setFinished(true);
                    // stop();
                    // if (user.role !== ROLE.CONSUMER_LEARNER) {

                    //     stopProctorCamera();
                    // }
                    // webcamRef.current = null;
                    // exitFullScreen();
                    localStorage.removeItem("end_date");
                },
                err => {
                    spinner.hide();
                    if (err && err.response && err.response.status === 403) {
                        Toast.error({ message: `You have already submitted your assessment.` });
                        // exitFullScreen();
                    }

                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("Error occur in handleSubmitAssessment--", err);
        }

    }

    // face detection methods starts
    const startVideo = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
                streamRef.current = stream;
                loadModels();
            })
            .catch((error) => {
                if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
                    console.error(
                        "You need to grant this page permission to access your camera and microphone.",
                    );
                    navigate("/");
                    changeAssessmentStatus();
                    Toast.success({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
                    // stopProctorCamera();
                } else if (error.name == "NotReadableError" || error.name == "TrackStartError") {

                    console.error('Webcam or mic are already in use');

                    navigate("/");
                    changeAssessmentStatus();
                    Toast.success({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
                    // stopProctorCamera();
                }
            });
    };

    const loadModels = () => {
        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
            faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
            faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
            faceapi.nets.faceExpressionNet.loadFromUri('/models'),
        ]).then(() => {
            faceDetection();
        })
    };

    const faceDetection = async () => {
        setInterval(async () => {
            const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();
            setFaceDetections(detections.length);
        }, 1000)
    }

    const stopProctorCamera = () => {
        if (streamRef.current) {
            const tracks = streamRef.current.getTracks();
            tracks.forEach((track) => track.stop());
        }
    };

    const exitFullScreen = () => {
        if (!document.fullscreenElement) {
            return;
        }

        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { // Safari
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) { // Firefox
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) { // IE11
            document.msExitFullscreen();
        }
    };

    function handleFullscreenChange() {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen(); // re-enter fullscreen if user exits fullscreen
        }
    }

    // useEffect(() => {
    //     if (!introDialog && user.role !== ROLE.CONSUMER_LEARNER) {
    //         document.documentElement.requestFullscreen();
    //         const handleBeforeUnload = (e) => {

    //             e.preventDefault();
    //             e.returnValue = '';
    //         };

    //         window.addEventListener('beforeunload', handleBeforeUnload);
    //         document.addEventListener('fullscreenchange', handleFullscreenChange); // add event listener for fullscreen change

    //         return () => {
    //             window.removeEventListener('beforeunload', handleBeforeUnload);
    //             document.removeEventListener('fullscreenchange', handleFullscreenChange); // remove event listener when component unmounts
    //             document.exitFullscreen(); // exit fullscreen when component unmounts
    //         };
    //     }

    // }, [introDialog]);


    useEffect(() => {
        const violationMessage = "Multiple faces were detected for 5 times.";
        if (faceDetections >= 50000) {
            setShow(true);
            setFaceviolationcount(prevCount => prevCount + 1)
        }
        // if (faceviolationcount >= 50000) {
        //     markViolation(violationMessage);
        // }

    }, [faceDetections])

    // face detection methods starts ends

    // start of recording
    //recording method starts
    const captureCamera = (cb) => {
        navigator.mediaDevices
            .getUserMedia({
                audio: true,
                video: true, //make it true for video
            })
            .then(cb)
            .catch((error) => {
                if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
                    console.error(
                        "You need to grant this page permission to access your camera and microphone.",
                    );
                    navigate("/");
                    changeAssessmentStatus();
                    Toast.success({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
                    // stop();
                } else if (error.name == "NotReadableError" || error.name == "TrackStartError") {

                    console.error('Webcam or mic are already in use');

                    navigate("/");
                    changeAssessmentStatus();
                    Toast.success({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
                    // stop();
                }
            })
    };

    const startScreenRecord = async () => {
        await setStopDisable(false);
        setStartDisable(true);

        captureScreen((screen) => {
            captureCamera(async (camera) => {
                screen.width = window.screen.width;
                screen.height = window.screen.height;
                screen.fullcanvas = true;
                camera.width = 320;
                camera.height = 240;
                camera.top = screen.height - camera.height;
                camera.left = screen.width - camera.width;

                setScreen(screen);
                setCamera(camera);

                if (camera !== null) {

                    const newRecorder = RecordRTC([screen, camera], {
                        type: "video",
                    });

                    newRecorder.startRecording();
                    newRecorder.screen = screen;

                    setRecorder(newRecorder);
                    startVideo();
                }
            });
        });
    };

    const captureScreen = (callback) => {
        invokeGetDisplayMedia(
            (screen) => {
                addStreamStopListener(screen, () => { });
                callback(screen);
            },
            (error) => {
                console.error(error);
                navigate("/");
                changeAssessmentStatus();
                Toast.success({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
                // alert(
                //     "Unable to capture your screen. Please check console logs.\n" + error
                // );
                setStopDisable(true);
                setStartDisable(false);
            }
        );
    };

    const stopLocalVideo = async (screen, camera) => {
        [screen, camera].forEach(async (stream) => {
            stream.getTracks().forEach(async (track) => {
                track.stop();
            });
        });
    };

    const invokeGetDisplayMedia = (success, error) => {
        var displaymediastreamconstraints = {
            video: {
                displaySurface: "monitor", // monitor, window, application, browser
                logicalSurface: true,
                cursor: "always", // never, always, motion
            },
        };
        displaymediastreamconstraints = {
            video: true,
            audio: true,
        };
        if (navigator.mediaDevices.getDisplayMedia) {
            navigator.mediaDevices
                .getDisplayMedia(displaymediastreamconstraints)
                .then(success)
                .catch(error);
        } else {
            navigator
                .getDisplayMedia(displaymediastreamconstraints)
                .then(success)
                .catch(error);
        }
    };

    const addStreamStopListener = (stream, callback) => {
        if (camera !== null) {
            stream.addEventListener(
                "ended",
                () => {
                    callback();
                    callback = () => { };
                },
                false
            );
            stream.addEventListener(
                "inactive",
                () => {
                    callback();
                    callback = () => { };
                },
                false
            );
            stream.getTracks().forEach((track) => {
                track.addEventListener(
                    "ended",
                    () => {
                        callback();
                        callback = () => { };
                    },
                    false
                );
                track.addEventListener(
                    "inactive",
                    () => {
                        callback();
                        callback = () => { };
                    },
                    false
                );
            });
            stream.getVideoTracks()[0].onended = () => {
                stop();
            };
        }
    };

    //stop share screen prompt
    const permission = (screen) => {
        [screen].forEach(async (stream) => {
            stream.getTracks().forEach(async (track) => {
                track.stop();
            });
        });
    }

    const stop = () => {
        if (camera === null) {
            recorder.stopRecording(permission(screen));
            recorder.screen.stop();
            recorder.destroy();
            recorder = null;
        }
        else {
            setStartDisable(true);
            recorder.stopRecording(stopRecordingCallback);
        }
    };

    const stopRecordingCallback = async () => {
        await stopLocalVideo(screen, camera);

        let recordedVideoUrl;
        let blob = recorder.getBlob();

        if (recorder.getBlob()) {
            setRecordedVideoUrl(URL.createObjectURL(recorder.getBlob()));
        }

        setScreen(null);
        setIsOpenVideoModal(true);
        setStartDisable(false);
        setStopDisable(true);
        setCamera(null);

        recorder.screen.stop();
        recorder.destroy();
        setRecorder(null);

        // stopProctorCamera();

        var file = new File([blob], getFileName("mp4"), {
            type: "video/mp4",
        });

        uploadFile(file);
    };

    const videoModalClose = () => {
        setIsOpenVideoModal(false);
    };

    const openModal = async () => {
        await setLoadModal(false);
    };

    const getFileName = (fileExtension) => {
        let userSid = JSON.parse(localStorage.getItem('user'))
        userSid = userSid.sid;
        let trainingSid = localStorage.getItem("trainingSid");

        return (
            `assessmentrecording_${instruction.sid}_${trainingSid}_${userSid}.${fileExtension}`
        );
    };

    const uploadFile = async (file) => {
        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: file.name
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {
                setProgress(Math.round((evt.loaded / evt.total) * 100));
                // if (evt.loaded === evt.total) {

                //     insertRecordingSeedDetails();
                // }
            })
            .send((err, data) => {
                if (err) console.error(err);
            });
    };

    //save link to db

    // const insertRecordingSeedDetails = () => {
    //     axios.post(`https://trainsoft.live/insled/v2/insert-seed-recording-details`, {}, {
    //         params: {
    //             recording_link: `${bucketUrl}${getFileName("mp4")}`
    //         }
    //     })
    //         .then(response =>)
    //         .catch(err => console.warn(err));
    // }
    // end of recording    

    // listening when time's up to submit assessment automatically
    useEffect(() => {
        if (hasExamEnd) {
            setShow(true);
            setShowTimesModal(true);
        }
    }, [hasExamEnd]);

    // useEffect(() => {
    //     if (!introDialog) {
    //         if (user.role !== ROLE.CONSUMER_LEARNER) {
    //             startVideo();
    //         }
    //         // startScreenRecord();
    //     }
    // }, [introDialog]);

    useEffect(() => {
        if (user.role !== ROLE.CONSUMER_LEARNER) {
            const handleVisibilityChange = () => {
                if (document.visibilityState === 'visible') {
                    // Tab is visible

                } else {
                    // Tab is hidden
                    setcountab(prevCount => prevCount + 1)


                }
            };

            document.addEventListener('visibilitychange', handleVisibilityChange);

            return () => {
                document.removeEventListener('visibilitychange', handleVisibilityChange);
            };
        }
    }, []);

    useEffect(() => {
        if (user.role !== ROLE.CONSUMER_LEARNER) {
            const violationMessage = "More than 5 tab switches detected";
            // if (countab >= 50000) {
            //     markViolation(violationMessage);

            // }
        }
    }, [countab])

    useEffect(() => {

        // Disable right click ;
        document.addEventListener('contextmenu', (e) => {
            e.preventDefault();
        })

        const disableShortcuts = (event) => {
            // Disable Ctrl+Shift+I or Command+Option+I (to prevent opening Developer Tools)
            if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === 'I') {
                event.preventDefault();
            }
            // Disable Command+Alt+I on Mac (alternative to the above for opening Developer Tools)
            else if (event.metaKey && event.altKey && event.key === 'I') {
                event.preventDefault();
            }
            // Disable Ctrl+R or Command+R (to prevent page refresh)
            else if ((event.ctrlKey || event.metaKey) && (event.key === 'r' || event.key === 'R')) {
                event.preventDefault();
            }
            // Disable Ctrl+Shift+R or Command+Shift+R (to prevent hard reload)
            else if ((event.ctrlKey || event.metaKey) && event.shiftKey && (event.key === 'r' || event.key === 'R')) {
                event.preventDefault();
            }
        };

        window.addEventListener('keydown', disableShortcuts);

        return () => {
            window.removeEventListener('keydown', disableShortcuts);
        };
    }, []);

    return (
        <div className={styles.main}>
            {faceDetections >= 2 &&
                <BsModal {...{ show, setShow, headerTitle: "Attention: Multiple faces detected!", size: "md" }} />
            }
            {finished && <FinishScreen {...{ questions }} />}
            {
                !finished
                && AppUtils.isNotEmptyArray(questions)
                && <>
                    {
                        questions
                        // if all questions are mandatory
                        //TO DO && Object.keys(selectedAnswers).length === questions.length 
                        && questionIndex === -1
                        && <div className={styles.doneBox}>
                            <div>
                                <div style={{ font: "normal normal 600 14px/26px Montserrat" }}>
                                    Awesome! You have attended {AppUtils.isNotEmptyObject(selectedAnswers) && Object.keys(selectedAnswers).length || 'answer' in localStorage && Object.keys(answerKeys).length} /
                                    {questions.length} questions in your assessment!
                                </div>
                                <div>
                                    You can either Submit your assessment now or review your
                                    answers & then submit
                                </div>
                            </div>

                            <Submit onClick={() => { handleSubmitAssessment() }}>Submit Assessment</Submit>
                        </div>
                    }
                    {
                        questionIndex === -1
                            ? <>
                                {
                                    AppUtils.isNotEmptyArray(questions)
                                    && questions.map((question, index) => <AssessmentCard {...{
                                        question,
                                        index,
                                        review,
                                        setReview,
                                        questions
                                    }} />)
                                }
                            </>
                            : <AssessmentCard {...{ question: activeQuestion, questions }} />
                    }
                </>
            }
            {showTimesModal && <TimesUpModal {...{ show, setShow, callBack: () => handleSubmitAssessment() }} />}
            {/* {!introDialog &&
                user.role !== ROLE.CONSUMER_LEARNER &&
                <Webcam
                    height={1}
                    width={1}
                    ref={webcamRef}
                    audio={true}
                    muted={true}
                />
            } */}
            {/* {
                user.role !== ROLE.CONSUMER_LEARNER &&
                <video crossOrigin='anonymous' ref={videoRef} height="1" width="1" autoPlay></video>
            } */}

        </div>
    );
}

export default Main;