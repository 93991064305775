import  React ,{useState} from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField'
import { WithContext as ReactTags } from "react-tag-input";

export default function SendEmail({open,setOpen, title}) {
    const [tags, setTags] = useState([]);
    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
      };
    
      const handleAddition = tag => {
        setTags([...tags, tag]);
      };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog open={open} onClose={handleClose}  disableEscapeKeyDown >
      <DialogTitle sx={{ m: 0, textAlign: 'left' }} id="customized-dialog-title">
                    {title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
        <DialogContent >
          <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText>
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
          /> */}

<ReactTags
                  tags={tags}
                  placeholder="info@learnlytica.com"
                  handleDelete={handleDelete}
                  handleAddition={handleAddition}
                  inputFieldPosition="top"

                />
        </DialogContent>
        <DialogContent dividers>
        <DialogActions >
        <Button autoFocus onClick={handleClose} style={{ padding: "10px 18px", backgroundColor: "#D0D5DD", color: "#344054", fontSize: "16px", fontWeight: "600", boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)", borderRadius: "8px", border: "1px solid #D0D5DD" }}>
                        Cancel
                    </Button>

                    <Button autoFocus onClick={handleClose} style={{ padding: "10px 18px", backgroundColor: "#7F56D9", color: "#fff", fontSize: "16px", fontWeight: "600", boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)", borderRadius: "8px", border: "1px solid #D0D5DD" }}>
                        
                    Send
                    </Button>
        </DialogActions>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}