import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { Spinner } from 'react-bootstrap';
// import './alertDialog.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));

export default function AlertDialog({ open, setOpen, title, body, apiMethod, loadingStart }) {
    //   const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open dialog
      </Button> */}
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
            >
                <DialogTitle sx={{ m: 0, textAlign: 'left' }} id="customized-dialog-title">
                    {title}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        {body}
                    </Typography>


                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleClose} style={{ padding: "10px 18px", backgroundColor: "#D0D5DD", color: "#344054", fontSize: "16px", fontWeight: "600", boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)", borderRadius: "8px", border: "1px solid #D0D5DD" }}>
                        Cancel
                    </Button>

                    <Button autoFocus onClick={apiMethod} style={{ padding: "10px 18px", backgroundColor: "#7F56D9", color: "#fff", fontSize: "16px", fontWeight: "600", boxShadow: " 0px 1px 2px 0px rgba(16, 24, 40, 0.05)", borderRadius: "8px", border: "1px solid #D0D5DD" }}>
                        {
                            title === 'Pause Your Lab' ?
                                'Pause' :
                                loadingStart ? (
                                    <>
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="md"
                                        role="status"
                                        aria-hidden="true"
                                        className="mr-2 loader-button" // Add margin to separate spinner from text
                                        style={{ border: '5px dotted #fff', width: "20px", height: "20px" }}
                                      />
                                      {' '}
                                      Submitting
                                    </>
                                  ) :
                                'Submit'
                        }
                    </Button>
                </DialogActions>

            </BootstrapDialog>
        </React.Fragment>
    );
}
