import { Link } from "@reach/router";
import { Fragment } from "react";
import { useState } from "react";
import { useContext } from "react";
import AppContext from "../../../Store/AppContext";
import "./sideBar.css";
import { AdminConfig } from './SidebarConfig';
import GLOBELCONSTANT from "../../../Constant/GlobleConstant";
const Sidebar = ({ location }) => {
  const { user, ROLE } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  return (
    <>
      {/* <button
        onClick={toggleSidebar}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={`toggle-button ${isOpen ? 'open' : 'toggle-button-close'}`}
      >
        ☰
      </button> */}
      {
        user.role === ROLE.CONSUMER_LEARNER ?
          <></>
          :
          <div
            className={`sideBarNav ${isOpen ? 'open' : ''}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {!isOpen ? (
              <div className="nav-title-ts">{GLOBELCONSTANT.BRANDING.LOGO}</div>
            ) : (
              <div className="nav-title">{GLOBELCONSTANT.BRANDING.TITLE}</div>
            )}
            {location &&
              AdminConfig.map((res) => (
                <Fragment key={res.pathname}>
                  {res &&
                    res.role.some((e) => e === user.role) && (
                      <div
                        className={`navMenu ${res.title === (location.state && location.state.title)
                          ? 'active'
                          : ''
                          }`}
                        key={res.title}
                        onClick={closeSidebar} // Close the sidebar when a menu item is clicked
                      >
                        <Link
                          state={{ title: res.title, subPath: res.subPath }}
                          className="aic"
                          to={res.pathname}
                        >
                          {!isOpen ? (
                            <div className="mr-3">{res.icon}</div>
                          ) : (
                            <>
                              <div className="mr-3">{res.icon}</div>
                              <div className="">{res.title}</div>
                            </>
                          )}
                        </Link>
                      </div>
                    )}
                </Fragment>
              ))}
          </div>
      }
    </>
  );
};


export default Sidebar
