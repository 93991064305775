import React, { useState, useRef, useEffect, useContext } from 'react'
import { Language } from './Language';
import { Dropdown, Spinner } from 'react-bootstrap';
import { CustomToggle } from '../../../../Services/MethodFactory';
import axios from 'axios';
import { ICN_ARROW_DOWN, ICN_DOWNLOAD, ICN_FULL_SCREEN, ICN_PLAY, ICN_STAR_HALF } from '../../../Common/Icon';
import AppContext from '../../../../Store/AppContext';
import Editors from './Editor';
import './codeEditor.css'
import OutputIcon from '@mui/icons-material/Output';
import RestService from '../../../../Services/api.service';
import SkeletonLoader from '../../../Common/SkeletonLoader/SkeletonLoader';
import CountdownTimer from '../../../Common/CountdownTimer/CountdownTimer';
import GLOBELCONSTANT from '../../../../Constant/GlobleConstant';
import { useNavigate } from "@reach/router";
import useToast from '../../../../Store/ToastHook';
import DynamicTable from '../../../Common/DynamicTable/DynamicTable';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import LoadingSpinner from '../../../Common/LoadingSpinner/LoadingSpinner';
import Editor from "@monaco-editor/react";

const CodeEditor = ({ trainingSid, codingQuestionId, sectionSid, labType, labDuration, codingAssessmentSid, submitCodingAssessment }) => {
    const json = {
        "score": 13.75,
        "cpuTime": 0.11,
        "memory": 44410,
        "language": "java",
        "testCaseEvaluationDetails": [
            {
                "testCaseName": "Test Case: 1",
                "testCaseStatus": "PASSED",
                "executionTime": 0.17,
                "memory": 44952,
                "score": 0
            },
            {
                "testCaseName": "Test Case: 2",
                "testCaseStatus": "PASSED",
                "executionTime": 0.1,
                "memory": 44108,
                "score": 0
            },
            {
                "testCaseName": "Test Case: 3",
                "testCaseStatus": "PASSED",
                "executionTime": 0.09,
                "memory": 44128,
                "score": 3.75
            },
            {
                "testCaseName": "Test Case: 4",
                "testCaseStatus": "PASSED",
                "executionTime": 0.08,
                "memory": 44520,
                "score": 3.75
            },
            {
                "testCaseName": "Test Case: 5",
                "testCaseStatus": "PASSED",
                "executionTime": 0.08,
                "memory": 44416,
                "score": 2.5
            },
            {
                "testCaseName": "Test Case: 6",
                "testCaseStatus": "PASSED",
                "executionTime": 0.1,
                "memory": 44612,
                "score": 3.75
            },
            {
                "testCaseName": "Test Case: 7",
                "testCaseStatus": "PASSED",
                "executionTime": 0.15,
                "memory": 44140,
                "score": 0
            }
        ]
    };

    const languageExtensions = {
        'Python': '.py',
        'Java (JDK 1.8.0)': '.java',
        'C': '.c',
        'C++ (GCC 5.3.0)': '.cpp',
        'C#': '.cs',
        'Sql': '.sql',
        'NodeJs': '.js',
        'Swift': '.swift',
        'Ruby': '.rb',
    };

    const [configuration, setConfiguration] = useState({
        columns: {
            "testCaseName": {
                "title": "Test Case",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
            },
            "testCaseStatus": {
                "title": "Status",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
                // render: (data) => user.role === ROLE.SUPERVISOR || user.role === ROLE.SUPER_USER ? data.noOfBatches
                //     : <p style={{ display: "none" }}></p>
            },
            "executionTime": {
                "title": "Execution Time",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
                render: (data) => <>{`${data.executionTime} ms`}</>

            },
            "memory": {
                "title": "Memory",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false
            },
            "score": {
                "title": "Score",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
            }
        },
        headerTextColor: '#454E50', // user can change table header text color

        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configuration.sortBy = sortKey;
            Object.keys(configuration.columns).map(key => configuration.columns[key].sortDirection = (key === sortKey) ? !configuration.columns[key].sortDirection : false);
            configuration.sortDirection = configuration.columns[sortKey].sortDirection;
            setConfiguration({ ...configuration });
        },
        actions: [],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table sort-enabled", // table custom class
        showCheckbox: false,
        clearSelection: false,
    });

    const [userAnswers, setUserAnswers] = useState({});
    const [loading, setLoading] = useState(false);

    const [codingSubmissionResponse, setCodingSubmissionResponse] = useState(null);

    const { spinner } = useContext(AppContext);
    const [inputData, setInputData] = useState('');
    const editorRef = useRef(null);
    const [lang, setLang] = useState(Language[3]);
    const [lightTheams, setLightTheams] = useState(false);
    const [output, setOutput] = useState('');
    const [submitoutput, setSubmitoutput] = useState('');
    const [results, setResults] = useState('');
    const [inputTab, setInputTab] = useState(true);
    const [spinners, setSpinners] = useState(false);
    const [codeTemplate, setCodeTemplate] = useState('');
    const [loader, setLoader] = useState(true);
    const navigate = useNavigate();
    const Toast = useToast();

    const handleEditorDidMount = (editor, monaco) => {
        const editorValue = editor.getValue();
        editorRef.current = editor;
    }

    // Function to handle user's answer selection
    const handleAnswerSelect = (codingId) => {
        setUserAnswers((prevUserAnswers) => {
            const updatedAnswers = { ...prevUserAnswers };
            updatedAnswers[codingId] = editorRef.current.getValue();
            return updatedAnswers;
        });

    };

    const handleDownload = () => {
        const fileContent = editorRef.current.getValue();
        const fileExtension = languageExtensions[lang?.label] || '';
        const fileName = `sample${fileExtension}`;

        const blob = new Blob([fileContent], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();

        // Clean up
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };


    //mark labs as complete
    const markCourseAsCompletedCode = () => {
        try {
            let payload = {
                "completedInDuration": 0,
                "totalDuration": 0
            }
            // spinner.show();
            RestService.markCourseAsCompletedCode(codingQuestionId, sectionSid, trainingSid, payload).then(
                response => {
                    if (response.status === 200) {
                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on markCourseAsCompletedCode()", err)
        }
    }

    //execute compiler
    const runCode = () => {
        if (editorRef.current.getValue().length > 0) {
            setSpinners(true);
            const url = GLOBELCONSTANT.HARD_CODED_API.RUN_CODE;
            const payload = {
                "stdin": inputData,
                "script": editorRef.current.getValue(),
                "language": lang.language,
            }
            axios.post(url, payload)
                .then(({ data }) => {
                    if (typeof (data) === "object" && 'codeAnalysis' in data) {
                        setResults(data.codeAnalysis);
                    }
                    setOutput(data.output);
                    setInputTab(false);
                    setSpinners(false);
                })
                .catch(() => {
                    setSpinners(false);
                    Toast.error({ message: "Try Again", time: 2000 })
                })
        }
        else {
            Toast.warning({ message: 'Write Your code', time: 5000 });
        }
    }

    //submit code for test cases
    const submitCode = async () => {
        const url = GLOBELCONSTANT.HARD_CODED_API.SUBMIT_CODE;
        setSpinners(true);
        const payload = {
            "code": editorRef.current.getValue(),

        }

        await axios.post(url + `?coding_question_id=${codingQuestionId}&section_sid=${sectionSid}&training_sid=${trainingSid}`, payload)
            .then(({ data }) => {
                setSubmitoutput(data);
                markCourseAsCompletedCode();
                setSpinners(false);
            })
    }

    //get code template
    const getCodeTemplate = () => {
        try {
            const language = lang.language;
            RestService.getCodeTemplate(codingQuestionId, language).then(
                response => {
                    if (response.status === 200) {
                        setCodeTemplate(response.data);

                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getCodeTemplate()", err)
        }
    }

    //compile and test
    const codeCompileAndTest = () => {
        try {
            const language = lang.language;
            const versionIndex = lang.versionIndex
            let payload = {
                "codingQuestionId": codingQuestionId,
                "languageCode": language,
                "sectionSid": sectionSid,
                "stdin": inputData,
                "trainingSid": trainingSid,
                "userCode": editorRef.current.getValue(),
                "versionIndex": versionIndex
            }
            spinner.show();
            RestService.codeCompileAndTest(payload).then(
                response => {
                    if (response.status === 200) {
                        setSubmitoutput(response.data);
                        setInputTab(false);
                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on codeCompileAndTest()", err)
        }
    }

    //coding assessment compile question 
    const codingAssessmentCompileQuestion = () => {
        try {
            const language = lang.language;
            const versionIndex = lang.versionIndex;

            let payload = {
                "codingAssessmentSid": codingAssessmentSid,
                "codingQuestionId": codingQuestionId,
                "languageCode": language,
                "sectionSid": sectionSid,
                "stdin": inputData,
                "trainingSid": trainingSid,
                "userCode": editorRef.current.getValue(),
                "versionIndex": versionIndex
            }
            spinner.show();
            RestService.codingAssessmentCompileQuestion(payload).then(
                response => {
                    if (response.status === 200) {
                        setCodingSubmissionResponse(null);
                        setSubmitoutput(response.data);
                        setInputTab(false);
                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on codingAssessmentCompileQuestion()", err)
        }
    }

    //submit code
    const codeSubmit = () => {
        try {
            const language = lang.language;
            const versionIndex = lang.versionIndex
            let payload = {
                "codingQuestionId": codingQuestionId,
                "languageCode": language,
                "sectionSid": sectionSid,
                "stdin": '',
                "trainingSid": trainingSid,
                "userCode": editorRef.current.getValue(),
                "versionIndex": versionIndex
            }
            spinner.show();
            RestService.codeSubmit(payload).then(
                response => {
                    if (response.status === 200) {
                        Toast.success({ message: 'Code Submitted Successfully', time: 2000 });
                        markCourseAsCompletedCode();
                        navigate('/training/training-details');
                    }
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: 'Try Again', time: 2000 });
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on codeSubmit()", err)
        }
    }

    //coding assessment submit question
    const codingAssessmentSubmitQuestion = () => {
        try {
            // setSpinners(true);
            setLoading(true);
            const language = lang.language;
            const versionIndex = lang.versionIndex
            let payload = {
                "codingAssessmentSid": codingAssessmentSid,
                "codingQuestionId": codingQuestionId,
                "languageCode": language,
                "sectionSid": sectionSid,
                "stdin": "",
                "trainingSid": trainingSid,
                "userCode": editorRef.current.getValue(),
                "versionIndex": versionIndex
            }
            // spinner.show();
            RestService.codingAssessmentSubmitQuestion(payload).then(
                response => {
                    if (response.status === 200) {
                        Toast.success({ message: 'Code Submitted Successfully', time: 2000 });
                        setSubmitoutput('');
                        setCodingSubmissionResponse(response.data);

                        // setSpinners(false);
                        setLoading(false);
                        setInputTab(false);
                    }
                },
                err => {
                    // spinner.hide();
                    // setSpinners(false);
                    setLoading(false);
                    Toast.error({ message: 'Try Again', time: 2000 });
                }
            ).finally(() => {
                // spinner.hide();
            });
        } catch (err) {
            console.error("error occur on codingAssessmentSubmitQuestion()", err)
        }
    }

    //submit coding assessment
    // const submitCodingAssessment = () => {
    //     try {

    //         let payload = {
    //             "codingAssessmentSid": codingAssessmentSid,
    //             "sectionSid": sectionSid,
    //             "trainingSid": trainingSid
    //         }
    //         spinner.show();
    //         RestService.submitCodingAssessment(payload).then(
    //             response => {
    //                 if (response.status === 200) {
    //                     Toast.success({ message: 'Assessment Submitted Successfully', time: 2000 });
    //                     // markCourseAsCompletedCode();
    //                     navigate('/training/training-details');
    //                 }
    //             },
    //             err => {
    //                 spinner.hide();
    //                 Toast.error({ message: 'Try Again', time: 2000 });
    //             }
    //         ).finally(() => {
    //             spinner.hide();
    //         });
    //     } catch (err) {
    //         console.error("error occur on submitCodingAssessment()", err)
    //     }
    // }
    useEffect(() => {
        if (codingQuestionId !== undefined) {
            getCodeTemplate();
        }
        setOutput('');
        setInputTab(true);
        setTimeout(() => {
            setLoader(false);
        }, 2000);

    }, [lang]);
    return (<>
        <div className="editor-wrapper">
            {
                loader ?
                    <SkeletonLoader skeletonCount={1} />
                    :
                    <>
                        {
                            labType === "CODING_ASSESSMENT" &&
                            <div className='d-flex '>

                                <button className="class-mode bg-primary my-3" onClick={() => submitCodingAssessment()}>Submit Assessment</button>
                            </div>
                        }
                        <div className="jcb">
                            {
                                labType === "CODING_ASSESSMENT" ?
                                    <CountdownTimer {...{
                                        timeLimit: labDuration,
                                        callback: () => { }
                                    }} />
                                    :
                                    <CountdownTimer {...{
                                        timeLimit: labDuration,
                                        callback: () => { }
                                    }} />
                            }

                            <div className=""></div>

                            <div className="editor-tab">
                                <div>
                                    <Dropdown className="dropdown-menus">
                                        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                                            <div className="editor-tab-w">{lang.label} {ICN_ARROW_DOWN}</div>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu as="div" align="right">
                                            {Language.map(res => <Dropdown.Item onClick={() => setLang(res)}>{res.label}</Dropdown.Item>)}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="editor-btn">
                                    {
                                        labType === "CODING" || labType == "CODING_ASSESSMENT" ?
                                            ""
                                            :

                                            <div onClick={() => runCode()}>
                                                {spinners ? <><Spinner animation="border" size="sm" /></> : <div>{ICN_PLAY}</div>}
                                            </div>
                                    }
                                    <div onClick={handleDownload}>
                                        {ICN_DOWNLOAD}
                                    </div>
                                    <div className={`rotate-45 ${lightTheams ? '' : 'color-blue'}`} onClick={() => setLightTheams(!lightTheams)}>
                                        <span >{ICN_STAR_HALF}</span>
                                    </div>
                                    {/* <div>
                                        {ICN_FULL_SCREEN}
                                    </div> */}

                                </div>
                            </div>
                        </div>
                    </>
            }
            {/* <Editor
            height="100%"
            width="100%"
            defaultLanguage={lang.language}
            defaultValue={language}
            theme={lightTheams ? "vs-light" : "vs-dark"}
            onMount={handleEditorDidMount}
        /> */}
            {

                loader ?
                    <SkeletonLoader skeletonCount={1} />
                    :
                    <Editors {...{ lang, codeTemplate, lightTheams, handleEditorDidMount }} />

            }
            {/* {
                labType === 'CODING_ASSESSMENT' &&
                    loader ?
                    <SkeletonLoader skeletonCount={1} />
                    :
                    <Editor
                        height="400px"
                        width="100%"
                        defaultLanguage={lang.language}
                        theme={lightTheams ? "vs-light" : "vs-dark"}
                        value={codeTemplate}
                        onMount={()=>handleEditorDidMount}
                        
                        />


            } */}

            <div className="py-2 column">
                {
                    loader ?
                        <SkeletonLoader skeletonCount={0.3} />
                        :

                        <div className="flx">
                            <div onClick={() => setInputTab(true)} className={`class-mode ${inputTab === true ? 'bg-primary' : ''}`}>Input</div>
                            <div onClick={() => setInputTab(false)} className={`class-mode ${inputTab === false ? 'bg-primary' : ''}`}>Output</div>
                            {/* <div onClick={() => setInputTab(review)} className={`class-mode ${inputTab === review ? 'bg-danger' : ''}`}>CodeReview</div> */}
                        </div>
                }
                {inputTab ?
                    <div className="editor-output">
                        {
                            loader ?
                                <SkeletonLoader skeletonCount={2.7} />
                                :
                                <div><textarea rows="3" placeholder="Enter input" className="form-control" onChange={(e) => setInputData(e.target.value)} type="text" value={inputData} /></div>
                        }
                    </div >
                    :
                    codingQuestionId !== undefined ?
                        <div>

                            {submitoutput.length !== 0 &&
                                <textarea rows="3" className="form-control" disabled type="text" >
                                    {submitoutput}
                                </textarea>
                            }
                            {
                                codingSubmissionResponse !== null &&
                                <>

                                    {codingSubmissionResponse.testCaseEvaluationDetails.filter(e => e.testCaseStatus === 'TLE' ||
                                        e.testCaseStatus === 'FAILED').length > 0 ?
                                        <div className='p-3 mt-2' style={{ background: "#FBE9E7", borderLeft: "5px solid red" }}>Result: <ClearIcon />Wrong Answer</div>

                                        :
                                        <p className='p-3 mt-2' style={{ background: "#50C878", borderLeft: "5px solid red" }}>Result: <CheckIcon />Correct Answer</p>
                                    }
                                    <div className='row mt-2 mb-2'>
                                        {/* <div className='title-sm'>Score <br/>{codingSubmissionResponse.score}</div>
                                    <div className='title-sm'>Time <br/>{codingSubmissionResponse.cpuTime}</div>
                                    <div className='title-sm'>Memory <br/>{codingSubmissionResponse.memory}</div>
                                    <div className='title-sm'>Language<br/>{codingSubmissionResponse.language}</div> */}

                                        <div className='col-3'>
                                            <div className='title-sm'>Score</div>
                                            <div>{codingSubmissionResponse.score}</div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='title-sm'>Time (sec)</div>
                                            <div >{codingSubmissionResponse.cpuTime}</div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='title-sm'>Memory (KiB)</div>
                                            <div >{codingSubmissionResponse.memory}</div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='title-sm'>Language</div>
                                            <div>{codingSubmissionResponse.language}</div>
                                        </div>




                                    </div>
                                    <hr />

                                    <DynamicTable  {...{ configuration, sourceData: codingSubmissionResponse.testCaseEvaluationDetails }} />
                                </>
                            }
                        </div>
                        :
                        <div className="editor-output p-3" style={{ height: "100%", background: "#ffffcc" }}>
                            <div>
                                <div ><h6> Your output : </h6></div><div className='text-md'>  {output}</div>
                                {
                                    results.length !== 0 &&

                                    <div className='p-2 outputList' >
                                        <p style={{ fontSize: "15px", fontWeight: "bold", color: '#00000094' }}>Code Review</p>

                                        <p className='py-2' style={{ fontSize: "13px", fontWeight: "bold", color: '#00000094' }} >{results.split('\n')[2]}</p>
                                        <p className='px-4' ><OutputIcon /> {results.split('\n')[3]}</p>
                                        <hr />
                                        <p className='py-2' style={{ fontSize: "13px", fontWeight: "bold", color: '#00000094' }}>{results.split('\n')[5]}</p>
                                        <p className='px-4'> <OutputIcon /> {results.split('\n')[6]}</p>
                                    </div>
                                }

                            </div>

                        </div>
                }
                {
                    labType === "CODING" ?
                        <div className='d-flex ' style={{ justifyContent: "space-between" }}>
                            <button className="class-mode bg-primary my-3" onClick={codeCompileAndTest}>Compile & Test</button>
                            <button className="class-mode bg-primary my-3" onClick={codeSubmit}>Submit</button>
                            {/* <h4 className='title-md mt-3 ' >Hidden Test Cases :- {submitoutput}</h4> */}
                        </div>
                        :
                        labType === "CODING_ASSESSMENT" ?
                            <>
                                <div className='d-flex ' style={{ justifyContent: "space-between" }}>
                                    <button className="class-mode bg-primary my-3" onClick={codingAssessmentCompileQuestion}>Compile & Test</button>

                                    {
                                        loading ? <button>Please wait, Initializing<LoadingSpinner loading={loading} /></button> : <button className="class-mode bg-primary my-3" onClick={codingAssessmentSubmitQuestion}
                                        >
                                            Submit Question</button>
                                    }

                                </div>
                                {/* {
                            loading &&
                            <>
                            <p>Please wait, Initializing</p>
                            <LoadingSpinner loading={loading} />
                            </>
                           } */}

                            </>
                            :
                            ""
                }

            </div>
        </div>
    </>)

}

export default CodeEditor