import React from 'react'
import Countdown from "react-countdown";
import { useState, useEffect } from "react";
import { IcnTimer } from '../Icon';
import styles from "../../Screens/Assessment/AssessmentBody/AssessmentBody.module.css"

const PauseCountdownTimer = ({ timeLimit, callback = () => { } }) => {
    const [time, setTime] = useState(false);
    const [data, setData] = useState(
        { date: Date.now(), delay: timeLimit * 60000 } //10 seconds
    );
    const wantedDelay = timeLimit * 60000; //10 ms

    const renderer = ({ hours, minutes, seconds }) => {
        return (
            <>
                {
                   <div style={{ width: "130px", color: "#fff", background: "#2d0a52" }} className={styles.timer}>
                        {


                            <div className="aic" >
                                <div className="mr10">
                                    <IcnTimer {...{ color: minutes >= 4 ? "#917618" : (minutes >= 2 ? "#A86F3E" : "#ffffff") }} />
                                </div>
                                {
                                    timeLimit >= 60 ? <div className="pt2">{hours}:{minutes}:{seconds}</div>
                                        : <div className="pt2">{minutes}:{seconds}</div>
                                }

                            </div>
                        }
                    </div>
                }
            </>
        )

    }

    return (
        <div>
            <Countdown
                date={data.date + data.delay}
                renderer={renderer}
                autoStart={false}
            />
        </div>
    );
}

export default PauseCountdownTimer