import React from 'react';
import { Line } from 'react-chartjs-2';

const TrainingPercentageTrendChart = ({data}) => {

    const chartData = {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5', 'Week 6'], // Replace with actual week labels
        datasets: [
            {
                label: 'Training Completion (%)',
                data: data, // Replace with actual percentage data points
                fill: true,
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
            },
        ],
    };

    const chartOptions = {
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    callback: function (value, index, values) {
                        return `${value}%`; // This will add '%' to y-axis tick labels
                    }
                }
            },
            x: {
                title: {
                    display: true,
                    text: 'Weeks'
                }
            }
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += `${context.parsed.y}%`;
                        }
                        return label;
                    }
                }
            }
        },
        maintainAspectRatio: false
    };

    return <Line data={chartData} options={chartOptions} />;
};

export default TrainingPercentageTrendChart