import { useState, CSSProperties } from "react";
import PropagateLoader from "react-spinners/PropagateLoader";

const override = {
  display: "block",
  // margin: "3px",
  background: "rgba(255, 255, 255, 0.5)", // Adjust the background color and opacity as needed.
};

const centerContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
};

function LoadingSpinner({loading}) {
  
  return (
    <div>
      <div className="sweet-loading">
        {/* <button onClick={() => setLoading(!loading)}>Toggle Loader</button> */}
       
        <PropagateLoader
          height={60}
          width={6}
          color="#36d7b7"
          loading={loading}
          cssOverride={override}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
}

export default LoadingSpinner;


// import  './spinner.css';

// const Spinner = ({value})=> {
//     return(<>
//     { value && value.spinner &&  <div className="fullpage-spinner flx flx-center">
//         <div className="loading-spinner-container">
//             <div className="spinner-border text-primary" role="status">
//                 </div><div className="mt-3 text-dark">{value.message}</div>
//                 </div>
//             </div>}
//         </>)
// }
// export default Spinner
