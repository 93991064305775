import { Breadcrumb } from "react-bootstrap";
import { Link, navigate } from "./Router";
import { SearchInputBox } from "react-bs-search";
import SearchBox from "./SearchBox/SearchBox"
import { Button } from "./Buttons/Buttons";
import parse from 'html-react-parser';

const CardHeader = ({ location, onChange = () => { }, count, onEnter, clearField = false, actionClick, showAction, children, hideSearch = false }) => {
    const containsHtmlTags = (str) => {
        const regex = /<\/?[a-z][\s\S]*>/i;
        return regex.test(str);
    };

    return (<>
        <div className="jcb aic py-2">
            <div className="flx1">
                {location && location.state && <Breadcrumb>
                    <Breadcrumb.Item className=""><Link state={{ title: location.state.title }} to={`${location.pathname.split('/').slice(0, 2).join('/')}`}>{location.state.title}</Link></Breadcrumb.Item>
                    {location.state.subTitle && <Breadcrumb.Item active>
                        {containsHtmlTags(location.state.subTitle) ? parse(location.state.subTitle) : location.state.subTitle}
                    </Breadcrumb.Item>}
                    {count && <Breadcrumb.Item >{count}</Breadcrumb.Item>}

                </Breadcrumb>}

            </div>
            <div className="aic ">
                {!hideSearch && <SearchBox {...{ onChange, onEnter, clearField }} />}
                {children}
                <div>{showAction && actionClick && <Button className="ml-2" onClick={actionClick}>+ Add New</Button>}</div>
            </div>
        </div>
    </>)
}
export default CardHeader;