import React, { useState, useEffect, useContext } from 'react'
import { Formik } from "formik"
import { Link, Router } from "../../Common/Router";
import { BsModal } from "../../Common/BsUtils";
import moment from 'moment';
import { ICN_TRASH, ICN_EDIT, ICN_UPLOAD } from "../../Common/Icon";
import { Button, Cancel, ButtonDelete } from "../../Common/Buttons/Buttons"
import { TextArea, DateInput, TimeInput, TextInput, SelectInput } from "../../Common/InputField/InputField"
import CardHeader from '../../Common/CardHeader'
import SessionList from '../../Common/SessionList/SessionList'
import { WithContext as ReactTags } from "react-tag-input";
import RestService from '../../../Services/api.service'
import useFetch from '../../../Store/useFetch'
import GLOBELCONSTANT from '../../../Constant/GlobleConstant'
import useToast from "../../../Store/ToastHook";
import * as Yup from 'yup';
import AppContext from '../../../Store/AppContext'
import DynamicTable from '../../Common/DynamicTable/DynamicTable'
import DropdownItem from '../../Common/DropdownItem/DropdownItem';
import { Dropdown, DropdownButton } from "react-bootstrap";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import '../../Common/InputField/inputField.css';
import { string } from 'prop-types';
import "./styles.css";
import SkeletonLoader from '../../Common/SkeletonLoader/SkeletonLoader';
import Select from 'react-select';

const CourseDetails = ({ location }) => {
    const [showhide, setShowhide] = useState('');
    const handleshowhide = (event) => {
        const getuser = event.target.value.split("_");
        setShowhide(getuser[0]);
        setContentCategory(getuser[1])
        setCapstoneLabs('PRACTICE');
        setUnLocked(false);
        setShowResult(false);
        setAssessmentSid('');
        setCodingAssessmentSid('');
        setQuestionBasket('0');
        setTotalQuestionCount(0);
        setBasketErrors('');
    }
    const { user, spinner, ROLE } = useContext(AppContext)
    const [isEdit, setIsEdit] = useState(false)
    const [contentCategory, setContentCategory] = useState('');
    const [initialValues, setInitialValues] = useState({
        topic: '',
        contentTitle: '',
        contentLink: '',
        sessionDate: '',
        startTime: '',
        endTime: ''

    })
    const Toast = useToast();
    const [show, setShow] = useState(false);
    const [sessionList, setSessionList] = useState([]);
    const [document, setDocument] = useState();
    const [contentType, setContentType] = useState('');
    const [trainingList, setTrainingList] = useState([])
    const [sectionSid, setSectionSid] = useState('');
    const [fileName, setFileName] = useState('');
    const [type, setType] = useState('');
    const [contentSid, setContentSid] = useState('');
    const [files, setFiles] = useState();
    const [accountLabs, setAccountLabs] = useState([]);
    const [categorieList, setCategorieList] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [topics, setTopics] = useState([]);
    const [topicsCodingAssessment, setTopicsCodingAssessmen] = useState({});
    const [assessment, setAssessments] = useState([]);
    const [assessmentCoding, setAssessmentsCoding] = useState([]);
    const [codingQuestion, setCodingQuestion] = useState([]);
    const [instructorDocuments, setInstructorDocuments] = useState(false);
    const [instructorVideo, setInstructorVideo] = useState(false);
    const [unLocked, setUnLocked] = useState(false);
    const [showResult, setShowResult] = useState(false);
    const [questionBasket, setQuestionBasket] = useState('0');
    const [totalQuestionCount, setTotalQuestionCount] = useState(0);
    const [assessmentSid, setAssessmentSid] = useState('');
    const [codingAssessmentSid, setCodingAssessmentSid] = useState('');

    const [feedbackSurveyRequired, setFeedbackSurveyRequired] = useState(false);
    const [capstoneLabs, setCapstoneLabs] = useState('PRACTICE');
    const [tags, setTags] = useState([]);
    const [tags1, setTags1] = useState([]);
    const [tags2, setTags2] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    const timeFormatRegex = /^(?:[01]\d|2[0-3]):[0-5]\d$/;

    const [uploadvideo, setUploadvideo] = useState('LINK');
    const [loader, setLoader] = useState(true);
    const [assessmentType, setAssessmentType] = useState('')
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [timeZoneFormat, setTimeZoneFormat] = useState('');
    const [passingPercentage, setPassingPercentage] = useState(30);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorTime, setErrorTime] = useState('');
    const [dateError, setDateError] = useState('');
    const [basketErrors, setBasketErrors] = useState('');

    const validateBasket = (e) => {
        const value = e.target.value;

        // Check if the value contains only numbers
        const isNumber = /^\d+$/.test(value);

        if (!isNumber) {
            setBasketErrors('Please enter only numbers.');
        } else if (parseInt(value, 10) > totalQuestionCount) {
            setBasketErrors('Value cannot be greater than the total question count.');
        } else if (value === '' || parseInt(value, 10) < 0) {
            setBasketErrors('Duration must be a positive number.');
        } else {
            setBasketErrors('');
        }
    };


    function validateTimeDifference(startDate, startTime, endDate, endTime) {
        const timeDifference = getTimeDifference(startDate, startTime, endDate, endTime);

        if (isNaN(timeDifference)) {
            setErrorTime('Select Time.');
        } else if (timeDifference === -1) {
            setErrorTime('Start time cannot be greater than end time for the same date.');
        }
        else if (startDate > endDate) {
            setDateError('Start date must be less than or equal to end date.');
        }
        else {
            setErrorTime('');
        }
    }

    function getTimeDifference(startDate, startTime, endDate, endTime) {
        // Check if dates are valid
        if (!startDate || !endDate) {
            return NaN;
        }

        const [startHour, startMinute] = startTime.split(':').map(Number);
        const [endHour, endMinute] = endTime.split(':').map(Number);

        const startDateTime = new Date(startDate);
        startDateTime.setHours(startHour, startMinute, 0);

        const endDateTime = new Date(endDate);
        endDateTime.setHours(endHour, endMinute, 0);

        // Check if start time is greater than end time for the same date
        if (startDateTime > endDateTime && startDate === endDate) {
            return -1;
        }

        // Calculate difference in minutes
        const difference = (endDateTime - startDateTime) / (1000 * 60);

        return difference;
    }

    const handleOnchangeStartDate = (e) => {
        const newStartDate = e.target.value;
        setStartDate(e.target.value);
        // Validate against the current end date
        if (endDate && new Date(newStartDate) > new Date(endDate)) {
            setDateError('Start date must be less than or equal to end date.');
        } else {
            setDateError('');
        }
    }

    const handleOnchangeEndDate = (e) => {
        const newEndDate = e.target.value;
        setEndDate(e.target.value);
        // Validate against the current start date
        if (startDate && new Date(newEndDate) < new Date(startDate)) {
            setDateError('End date must be greater than or equal to start date.');
        } else {
            setDateError('');
        }

    }

    const handleOnchangeStartTime = (e) => {
        const newStartTime = e.target.value;
        setStartTime(newStartTime);
    };

    const handleOnchangeEndTime = (e) => {
        const newEndTime = e.target.value;
        setEndTime(newEndTime);
    }

    const handleOnchangeTimeZone = (event) => {

        const label = event.target.value;
        setTimeZoneFormat(label);
        const matchedZone = GLOBELCONSTANT.timezone.find(item => item.label === label);
        setTimeZone(matchedZone.zone);
    }

    const handleOnchangePassingPercentage = (e) => {
        const value = e.target.value;
        const numberPattern = /^[0-9]*$/; // Regex to check if it's a number

        // Check if the input is a number
        if (numberPattern.test(value)) {
            const numericValue = Number(value);
            // Validate if the number is less than 20
            if (numericValue < 30) {
                setErrorMessage('Passing Percentage must be 30 or greater.');
            } else {
                setErrorMessage(''); // Clear error message when the input is valid
            }
            setPassingPercentage(value); // Update state only if it's a number (can be less than 20, but shows error)
        } else if (value === '') {
            setPassingPercentage(value); // Allow clearing the input
            setErrorMessage(''); // Clear error message when input is empty
        } else {
            setErrorMessage('Please enter a valid number.'); // Set error message for non-numeric input
        }
    }

    const currentDate = new Date().toISOString().split('T')[0];
    const KeyCodes = {
        comma: 188,
        enter: 13
    };


    //validation
    const schema = Yup.object().shape({
        topicDescription: Yup.string()
            .min(2, 'Too Short!')
            .required("Required!"),
        topicName: Yup.string()
            .min(2, 'Too Short!')
            .required("Required!"),
    });

    const videoDocumentSchema = Yup.object().shape({
        contentTitle: Yup.string()
            .min(3, 'Too Short!')
            .required("Required!"),
        contentLink: Yup.string()
            .min(2, 'Too Short!')

    });

    const schemaMeeting = Yup.object().shape({
        topic: Yup.string()
            .required("Required!"),
        endTime: Yup.string()
            .required("Required!"),
        startTime: Yup.string()
            .required("Required!"),
        sessionDate: Yup.string()
            .required("Required!")
    });


    const [configuration, setConfiguration] = useState({
        columns: {

            "contentName": {
                "title": "Content Name",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
                render: (data) =>
                    data.contentName

            },
            // "description": {
            //     "title": "Description",
            //     "sortDirection": null,
            //     "sortEnabled": true,
            //     isSearchEnabled: false,

            // },
            "type": {
                "title": "Content Type",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
                render: (data) => <button className='assesmentlab title-md p-2 px-4' style={{ width: "30%" }}>{data.type}</button>
                //     render: (data) => <button
                //     className={`assesmentlab title-md p-2 px-4${data.type === "LAB" ? " lab" : ""}`}

                //   >
                //     {data.type}
                //   </button>
                // }
            }
        },
        headerTextColor: '#454E50', // user can change table header text color
        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configuration.sortBy = sortKey;
            Object.keys(configuration.columns).map(key => configuration.columns[key].sortDirection = (key === sortKey) ? !configuration.columns[key].sortDirection : false);
            configuration.sortDirection = configuration.columns[sortKey].sortDirection;
            setConfiguration({ ...configuration });
        },
        actions: [
            {
                "title": "Edit",
                "icon": ICN_EDIT,
                "editForCourseDetails": true,
                "onClick": (data, i) => { setIsEdit(true); setShow(true); setInitialValues(() => editValues(data)) }
            },
            {
                "title": "Delete",
                "icon": ICN_TRASH,
                "onClick": (data) => data.labId !== null ? deleteCourseContent(data.labId, data.sectionSid, data.type)
                    : data.codingQuestionId !== null ? deleteCourseContent(data.codingQuestionId, data.sectionSid, data.type)
                        : deleteCourseContent(data.sid, data.sectionSid, data.type)
            }
        ],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table sort-enabled", // table custom class
        showCheckbox: false,
        clearSelection: false
    });

    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };


    const handleDelete1 = i => {
        setTags1(tags1.filter((tag, index) => index !== i));
    };

    const handleAddition1 = tag => {
        setTags1([...tags1, tag]);
    };
    const handleDelete2 = i => {
        setTags2(tags2.filter((tag, index) => index !== i));
    };

    const handleAddition2 = tag => {
        setTags2([...tags2, tag]);
    };
    // get All section
    const getSection = async () => {
        try {
            let courseSid = location.state.sid;
            // spinner.show();
            RestService.getAllSectionsAndCourseContents(courseSid).then(
                response => {
                    if (response.status === 200) {
                        setSessionList(response.data.courseSectionResponseTO);
                        setTimeout(() => {
                            setLoader(false);
                        }, 1000);
                    }
                    else if (response.status === 204) {
                        setSessionList([]);
                        setTimeout(() => {
                            setLoader(false);
                        }, 1000);
                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getSession()", err)
        }
    }

    // get All session
    const getSessionByPage = async (pagination = "1") => {
        try {
            let pageSize = 10;
            spinner.show();
            RestService.getCourseSessionByPage(location.state.sid, pageSize, pagination).then(
                response => {
                    setSessionList(response.data);
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getSession()", err)
        }
    }

    // create new Section
    const createSection = (data) => {
        const courseSid = location.state.sid;
        try {
            spinner.show();

            let payload = {
                // "courseSid": location.state.sid,
                "description": data.topicDescription,
                "sectionName": data.topicName
            }

            RestService.createCourseSection(payload, courseSid).then(res => {
                setShow(false);
                getSection();
                // setSessionList([...sessionList, res.data])
                Toast.success({ message: `Section is Successfully Created` });
                spinner.hide();
            }, err => console.error(err)
            );
        }
        catch (err) {
            console.error('error occur on createCourse', err)
        }
    }

    //create new upload in course section
    const createUploadCourseSection = (data) => {

        const courseSectionSid = sectionSid;
        try {
            spinner.show();
            let formData = new FormData();

            formData.append("content-title", data.contentTitle);
            formData.append("unlocked", unLocked);
            if (instructorDocuments) {
                formData.append("instructor_specific", instructorDocuments);
            }
            else if (instructorVideo) {
                formData.append("instructor_specific", instructorVideo);
            }
            else {
                formData.append("instructor_specific", false);
            }


            typeof document === 'object' ? formData.append("file", document) : formData.append("content-link", data.contentLink);

            RestService.uploadCourseContent(formData, courseSectionSid).then(res => {
                getSection();
                setShow(false);
                setInstructorDocuments(false);
                setInstructorVideo(false);
                setUnLocked(false);
                // setSessionList([...sessionList, res.data])
                Toast.success({ message: `Section Content is Successfully Created` });
                spinner.hide();
            }, err => {
                console.error(err);
                Toast.error({ message: `${err.response.data.message}` || `Try Again` });
                spinner.hide();
            }
            );
        }
        catch (err) {
            console.error('error occur on createCourse', err)
        }
    }

    // create new session
    const editSession = (data) => {
        try {
            spinner.show();
            let formData = new FormData();
            formData.append("content-title", data.contentTitle);
            formData.append("content-link", data.contentLink);
            formData.append("course-content-sid", data.sid);
            if (typeof document === 'object') {
                formData.append("file", document);
            }
            // let payload = {
            //     "course-content-sid": data.sid,
            //     "courseSid": data.courseSid,
            //     "topicDescription": data.topicDescription,
            //     "topicName": data.topicName
            // }

            RestService.updateCourseContent(formData).then(res => {
                getSection();
                setShow(false)
                spinner.hide();
                Toast.success({ message: `Successfully Updated` });
            }, err => spinner.hide()
            );
        }
        catch (err) {
            spinner.hide();
            console.error('error occur on createCourse', err)
        }
    }


    // search session
    const searchSession = (name) => {
        try {
            spinner.show();
            RestService.searchSession(name).then(res => {
                setSessionList(res.data)
                spinner.hide();
            }, err => {
                spinner.hide();
            }
            );
        }
        catch (err) {
            console.error('error occur on searchSession()', err)
            spinner.hide();
        }
    }

    // search session
    const deleteSession = (e) => {
        try {
            spinner.show();
            RestService.deleteSession(e.sid).then(res => {
                getSessionByPage()
                spinner.hide();
            }, err => {
                spinner.hide();
            }
            );
        }
        catch (err) {
            console.error('error occur on deleteSession()', err)
            spinner.hide();
        }
    }


    //create Training session
    const createTrainingSession = (data) => {

        let endTime = setTimes(data.sessionDate, data.endTime);
        let startTime = setTimes(data.sessionDate, data.startTime);

        try {
            spinner.show();
            let endTime = setTimes(data.sessionDate, data.endTime)
            let startTime = setTimes(data.sessionDate, data.startTime)
            let payload = {
                "agenda": data.agenda,
                "topic": data.topic,
                "assets": data.assets,
                "courseSid": location.state.sid,
                "endTime": endTime,
                "duration": (endTime - startTime) / (1000 * 60),
                "recording": "",
                "sessionDate": data.sessionDate,
                "sectionSid": sectionSid,
                "startTime": startTime,
                "trainingSid": data.name.sid,
                "feedbackSurveyRequired": feedbackSurveyRequired
            }
            payload.status = "ENABLED"
            RestService.CreateTrainingSession(payload).then(res => {
                Toast.success({ message: `Agenda is Successfully Created`, time: 2000 });
                getSessionByPage()
                setShow(false)
                spinner.hide();
                getSection();
            }, err => { console.error(err); spinner.hide(); Toast.error({ message: `Something wrong!!`, time: 2000 }); }
            );
        }
        catch (err) {
            spinner.hide();
            console.error('error occur on createTrainingSession', err)
            Toast.error({ message: `Something wrong!!`, time: 2000 });
        }
    }

    //edit training Session
    const editTrainingSession = (data) => {
        let endTime = setTimes(data.sessionDate, data.endTime);
        let startTime = setTimes(data.sessionDate, data.startTime);
        try {
            spinner.show();
            let payload = {
                "sessionDescription": data.agenda,
                "sessionAgenda": data.topic,
                "sessionDate": data.sessionDate,
                "sessionSid": data.sid,
                "sessionStartTime": startTime,
                "sessionEndTime": endTime,
                // "endTime": endTime,
                // "duration": (endTime - startTime) / (1000 * 60),
                // "recording": "",


                // "startTime": startTime,
                // "trainingSid": data.name.sid,
                // "feedbackSurveyRequired": feedbackSurveyRequired
            }
            payload.status = "ENABLED"
            RestService.editTrainingSession(location.state.sid, payload).then(res => {
                Toast.success({ message: `Agenda is Successfully Updated`, time: 2000 });
                getSessionByPage()
                setShow(false)
                spinner.hide();
                getSection();
            }, err => { console.error(err); spinner.hide(); Toast.error({ message: `Something wrong!!`, time: 2000 }); }
            );
        }
        catch (err) {
            spinner.hide();
            console.error('error occur on editTrainingSession', err)
            Toast.error({ message: `Something wrong!!`, time: 2000 });
        }
    }

    const setTimes = (date, timeDate) => {
        let val = new Date(date)
        let times = new Date(timeDate)
        val.setHours(times.getHours(), times.getMinutes(), times.getSeconds())
        return val.getTime()
    }

    // upload attachment
    const UploadAttachmentsAPI = async (val) => {
        return new Promise((resolve, reject) => {
            let data = new FormData();
            for (let i = 0, l = val.length; i < l; i++)
                data.append("files", val[i])
            let xhr = new XMLHttpRequest();
            xhr.addEventListener("readystatechange", function () {
                let response = null;
                try {
                    response = JSON.parse(this.responseText);
                } catch (err) {
                    response = this.responseText
                }
                if (this.readyState === 4 && this.status >= 200 && this.status <= 299) {
                    resolve([response, this.status, this.getAllResponseHeaders()]);
                } else if (this.readyState === 4 && !(this.status >= 200 && this.status <= 299)) {
                    reject([response, this.status, this.getAllResponseHeaders()]);
                }
            });
            xhr.open("POST", GLOBELCONSTANT.TRAINING.UPLOAD_ASSETS);
            xhr.send(data);
        })
    }
    /** upload attachments file
    *   @param {Object} file = selected files
    *   @param {string} token = user auth token 
    *   @param {string} bucketName = bucket name 
    */
    const uploadAttachments = async (
        file,
        setFieldValue
    ) => {
        try {
            spinner.show();
            let data = await UploadAttachmentsAPI(file);
            setFieldValue("assets", JSON.stringify(data[0]))
            spinner.hide();
            Toast.success({ message: `Assets is successfully uploaded ` });
        } catch (err) {
            spinner.hide();
            Toast.error({ message: `Something Went Wrong` });
            console.error("Exception occurred in uploadAttachments -- ", err);
        }
    }

    // get all training
    const getTrainings = async (pagination = "1") => {
        try {
            let pageSize = 30;
            // spinner.show();
            let status = "ENABLED";
            RestService.getAllTrainingByPage(user.role, pagination, pageSize, status).then(
                response => {
                    setTrainingList(response.data);
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getTrainings()", err)
        }
    }

    //delete content file
    // const deleteCourseContentFile = () => {
    //     try {

    //         // spinner.show();
    //         RestService.deleteCourseContentFile(contentSid).then(
    //             response => {

    //                 setShow(true);
    //                 setIsEdit(true);
    //                 getSection();

    //             },
    //             err => {
    //                 // spinner.hide();
    //             }
    //         ).finally(() => {
    //             // spinner.hide();
    //         });
    //     } catch (err) {
    //         console.error("error occur on deleteCourseContentFile()", err)
    //     }

    // }

    // get labs after filter from account
    const filterAccountLabs = () => {
        try {

            spinner.show();
            let labType;
            if (capstoneLabs === 'PRACTICE') {
                labType = 'No';
            }
            else {
                labType = 'Yes';
            }
            RestService.filterAccountLabs(categoryName, labType).then(
                response => {
                    const labs = response.data.labDetails.map((lab) => ({
                        value: lab.labId,
                        label: lab.labName
                    }))
                    setAccountLabs(labs);
                    setCategoryName('');
                    spinner.hide();
                    // setCapstoneLabs(false);
                },
                err => {
                    spinner.hide();
                    setCategoryName('');
                }
            )
        } catch (err) {
            spinner.hide();
            console.error("error occur on filterAccountLabs()", err)
        }
    }
    const bulkCreateCourseSectionAndContents = () => {
        const courseSid = location.state.sid;
        try {
            spinner.show("Please wait...");
            let formData = new FormData();
            formData.append("file", files);
            RestService.bulkCreateCourseSectionAndContents(formData, courseSid).then(res => {
                getSection();
                setShow(false)
                spinner.hide();
                Toast.success({ message: 'Section uploaded successfully', time: 2000 });
            }, err => spinner.hide()
            );

        } catch (err) {
            Toast.error({ message: err.response?.data?.message });
        }
    }

    const editValues = (data) => {
        setType(data.type);
        setContentSid(data.sid);
        setDocument('');

        setInitialValues({
            contentTitle: data.contentName, contentLink: data.contentLink, sid: data.sid,
            endTime: data.sessionEndTimeTimestamp,
            startTime: data.sessionStartTimeTimestamp,
            sessionDate: data.sessionDateTimestamp,
            topic: data.contentName
        });

        // dateTime(data.sessionDate, data.sessionEndTime, data.sessionDate, data.sessionStartTime);        

        if (data.contentLink !== null && data.contentLink.includes("s3")) {
            setFileName(data.contentLink.split("/")[3].split("_")[1]);
        }
        else {
            setFileName('')
        }
    }

    // add lab to course selectLabInCourseWithTimeInCapstone
    // if (capstoneLabs === 'Yes') {
    //     payload = {
    //         "labStartTIme": labStartTIme
    //     }
    // }
    const addLabsInCourse = (data) => {
        // const labId = data.labName.labId
        const courseSid = location.state.sid;
        const courseSectionSid = sectionSid;
        let tagsString;
        if (tags.length > 0) {
            tagsString = tags.map((item) => item.text);
            // tagsString = tagsCsv.toString();
        }
        else {
            tagsString = tags;
        }
        // let payload = {
        //     "labStartTIme": data.labStartTIme
        // }
        let payload = {
            "courseSid": courseSid,
            "labIds": selectedOption.map(i => i.value),
            "sectionSid": courseSectionSid,
            "tags": tagsString,
            // "timeDetails": {
            //     "labStartTIme": "string"
            // },
            // "unlocked": true,
            // "labStartTIme": data.labStartTIme
        }
        if (capstoneLabs === 'CAPSTONE') {
            payload.timeDetails = {
                // "labStartTIme": data.labStartTIme
                "endDate": endDate,
                "endTime": endTime,
                "startDate": startDate,
                "startTime": startTime,
                "timeZone": timeZone,

            }
            payload.passingPercentage = passingPercentage;
            payload.unlocked = false;
            payload.showResult = showResult;
        }
        else {
            payload.unlocked = unLocked;
            payload.passingPercentage = 0;
            payload.showResult = false;

        }
        try {
            spinner.show();
            // if (capstoneLabs === 'CAPSTONE') {
            RestService.addLabsInCourse(payload).then(res => {
                if (res.status === 200) {
                    setShow(false);
                    setTags([]);
                    setTags1([]);
                    setTags2([]);
                    setSectionSid('');
                    setSelectedOption([]);
                    setStartDate('');
                    setEndDate('');
                    setStartTime('');
                    setEndTime('');
                    setTimeZone('');
                    setTimeZoneFormat('');
                    setPassingPercentage(30);
                    setShowResult(false);
                    getSection();
                    Toast.success({ message: `${res.data}` });
                    spinner.hide();
                }

            },
                err => {
                    console.error(err);
                    Toast.error({ message: `${err.response.data.message}` })
                    spinner.hide();
                }
            );
            // }
            // else {
            //     RestService.addLabsInCourse(labId, courseSid, courseSectionSid, tagsString, unLocked).then(res => {
            //         if (res.status === 200) {
            //             setShow(false);
            //             setUnLocked(false);
            //             getSection();
            //             setTags([]);
            //             setTags1([]);
            //             setTags2([]);
            //             setSectionSid('');
            //             Toast.success({ message: `Lab added successfully` });
            //             spinner.hide();
            //         }

            //     }, err => {
            //         Toast.error({ message: `${err.response.data.message}` })
            //         spinner.hide();
            //     }
            //     );

            // }
        }
        catch (err) {
            console.error('error occur on addLabsInCourse', err)
        }
    }

    // get all labs categories
    const getAllLabCategories = () => {
        try {

            // spinner.show();
            RestService.getAllLabCategories().then(
                response => {
                    setCategorieList(response.data);
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getAllLabCategories()", err)
        }
    }

    // get All Topics
    const getAllTopics = () => {
        try {
            // spinner.show();
            RestService.getAllTopics().then(
                response => {
                    if (response.status === 200) {
                        setTopics(response.data);
                        setAssessments([]);
                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getAllTopics()", err)
        }
    }

    // get All Coding Assessment Topics
    const getTopics = () => {
        try {
            // spinner.show();
            RestService.getTopics().then(
                response => {
                    if (response.status === 200) {
                        setTopicsCodingAssessmen(response.data);
                        setAssessmentsCoding([]);
                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getTopics()", err)
        }
    }

    // get All Coding questions
    const getAllCodingQuestions = async () => {
        try {

            // spinner.show();
            RestService.getAllCodingQuestions().then(
                response => {
                    if (response.status === 200) {
                        setCodingQuestion(response.data);
                    }

                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getAllCodingQuestions()", err)
        }
    }

    // get All Assessments
    const getAllAssessmentsInATopic = (topicSid) => {
        try {
            spinner.show();
            RestService.getAllAssessmentsInATopic(topicSid).then(
                response => {
                    if (response.status === 200) {
                        setAssessments(response.data);
                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getAllAssessmentsInATopic()", err)
        }
    }

    // get All Coding Assessment
    const getTopicCodingAssessment = (topicSid) => {
        try {
            spinner.show();
            RestService.getTopicCodingAssessment(topicSid).then(
                response => {
                    if (response.status === 200) {
                        setAssessmentsCoding(response.data);
                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getTopicCodingAssessment()", err)
        }
    }

    //get question count for mcq and coding assessment
    const getAssessmentQuestionCount = (assessmentType, sid) => {
        try {
            spinner.show();
            RestService.getAssessmentQuestionCount(assessmentType, sid).then(res => {
                if (res.status === 200) {
                    setTotalQuestionCount(res.data);
                    spinner.hide();
                }
            }, err => {
                console.error(err);
                spinner.hide();
            }
            );
        }
        catch (err) {
            spinner.hide();
            console.error('error occur on getAssessmentQuestionCount', err);
        }

    }

    // add assessment to course
    const addAssessmentToCourse = (data) => {
        const assessmentSid = data.assessmentName.assessmentSid
        const courseSid = location.state.sid;
        const courseSectionSid = sectionSid;
        let tagsString;
        const payload = {
            "endDate": endDate,
            "endTime": endTime,
            "questionCount": questionBasket,
            "showResult": showResult,
            "startDate": startDate,
            "startTime": startTime,
            "timeZone": timeZone
        }
        if (tags1.length > 0) {
            let tagsCsv = tags1.map((item) => item.text);
            tagsString = tagsCsv.toString();
        }
        else {
            tagsString = tags1;
        }

        // let tagsCsv = tags.map((item) => item.text);
        try {
            spinner.show();
            RestService.addAssessmentToCourse(assessmentSid, courseSid, passingPercentage, courseSectionSid, tagsString, payload).then(res => {
                if (res.status === 200) {
                    setShow(false);
                    getSection();
                    setTags([]);
                    setTags1([]);
                    setTags2([]);
                    setEndDate('');
                    setEndTime('');
                    setStartDate('');
                    setStartTime('');
                    setTimeZone('');
                    setTimeZoneFormat('');;
                    setPassingPercentage(30);
                    setTotalQuestionCount(0);
                    Toast.success({ message: `Assessment added successfully` });
                    spinner.hide();
                }

            }, err => {
                Toast.error({ message: `${err.response.data.message}` })
                spinner.hide();

                console.error(err);
            }

            );
        }
        catch (err) {
            console.error('error occur on addAssessmentToCourse', err)
        }
    }

    // add coding assessment to course
    const addCodingAssessmentToCourse = (data) => {
        const codingAssessmentSid = data.codingAssessmentName.codingAssessmentSid
        const courseSid = location.state.sid;
        const courseSectionSid = sectionSid;
        const payload = {
            "codingAssessmentSid": codingAssessmentSid,
            "courseSid": courseSid,
            "passingPercentage": passingPercentage,
            "sectionSid": courseSectionSid,
            "questionCount": questionBasket,
            "showResult": showResult,
            "timelineDetails": {
                "endDate": endDate,
                "endTime": endTime,
                "startDate": startDate,
                "startTime": startTime,
                "timeZone": timeZone
            }
        }
        try {
            spinner.show();
            RestService.addCodingAssessmentToCourse(payload).then(res => {
                if (res.status === 200) {
                    setShow(false);
                    setAssessmentsCoding([]);
                    setEndDate('');
                    setEndTime('');
                    setStartDate('');
                    setStartTime('');
                    setTimeZone('');
                    setTimeZoneFormat('');
                    setPassingPercentage(30);
                    setTotalQuestionCount(0);
                    getSection();
                    Toast.success({ message: `Coding Assessment added successfully` });
                    spinner.hide();
                }

            }, err => {
                console.error(err);
                Toast.error({ message: `${err.response.data.message}` })
                spinner.hide();
            }
            );
        }
        catch (err) {
            console.error('error occur on addCodingAssessmentToCourse', err)
        }
    }

    //add coding question to course section
    const addCodingQuestionToSection = (data) => {
        const courseSid = location.state.sid;
        const courseSectionSid = sectionSid;
        const questionId = data.question.questionId;
        let tagsString;
        if (tags2.length > 0) {
            let tagsCsv = tags2.map((item) => item.text);
            tagsString = tagsCsv.toString();
        }
        else {
            tagsString = tags2;
        }
        try {
            spinner.show();
            RestService.addCodingQuestionToSection(courseSid, courseSectionSid, questionId, tagsString).then(res => {
                if (res.status === 200) {
                    setShow(false)
                    getSection();
                    setTags([]);
                    setTags1([]);
                    setTags2([]);
                    Toast.success({ message: `Coding question added successfully` });
                    spinner.hide();
                }

            }, err => {
                console.error(err);
                Toast.error({ message: `${err.response.data.message}` })
                spinner.hide();
            }
            );
        }
        catch (err) {
            console.error('error occur on addCodingQuestionToSection', err)
        }

    }
    //delete course content
    const deleteCourseContent = (contentSid, sectionSid, type) => {
        const response = window.confirm("Are you sure you want to delete?");
        let courseSid = location.state.sid;
        if (response) {
            try {
                spinner.show();

                let payload = {
                    "contentType": type
                }

                if (type === "LAB") {

                    payload.codingQuestionId = 0;
                    payload.contentSid = null;
                    payload.labId = contentSid;

                }
                else if (type === "CODING") {

                    payload.codingQuestionId = contentSid;
                    payload.contentSid = null;
                    payload.labId = 0;
                }
                else {
                    payload.codingQuestionId = 0;
                    payload.contentSid = contentSid;
                    payload.labId = 0;
                }

                RestService.deleteCourseContent(courseSid, sectionSid, payload).then(res => {
                    if (res.status === 200) {
                        Toast.success({ message: `Content deleted successfully` });
                        spinner.hide();
                        getSection();
                    }
                    // else {
                    //     Toast.error({ message: `${err.response.data.message}` })
                    //     spinner.hide();
                    // }

                }, err => {
                    console.error(err);
                    Toast.error({ message: `${err.response.data.message}` || `Try Again` })
                    spinner.hide();
                }
                );
            }
            catch (err) {
                console.error('error occur on deleteCourseContent', err)
            }
        }

    }

    //delete course section after confirmation
    const deleteCourseSection = (sectionSid) => {
        const response = window.confirm("Are you sure you want to delete?");
        let courseSid = location.state.sid;
        if (response) {
            try {
                spinner.show();
                RestService.deleteCourseSection(courseSid, sectionSid).then(res => {
                    Toast.success({ message: `Section deleted successfully` });
                    spinner.hide();
                    //window.location.reload(true);
                    getSection();
                }, err => console.error(err)
                );
            }
            catch (err) {
                console.error('error occur on deleteCourseSection', err)
            }
        }

    }

    //edit course section
    const editCourseSection = (data) => {

        try {
            const sectionSid = data.sid;
            const payload = {
                "sectionDescription": data.topicDescription,
                "sectionName": data.topicName
            }
            spinner.show();
            RestService.editCourseSection(sectionSid, payload).then(res => {
                Toast.success({ message: `Section Updated Successfully` });
                setSessionList(res.data.courseSectionResponseTO);
                setShow(false);
                setContentType('');
                spinner.hide();
            }, err => console.error(err)
            );
        }
        catch (err) {
            console.error('error occur on editCourseSection', err)
        }

    }

    useEffect(() => {
        getSection();
        getTrainings();
        getAllLabCategories();
        getAllTopics();
        getTopics();
        getAllCodingQuestions();
    }, []);

    useEffect(() => {
        if (categoryName.length > 0 && capstoneLabs) {
            filterAccountLabs();
        }

    }, [capstoneLabs, categoryName]);

    useEffect(() => {
        if (startDate && startTime && endDate && endTime) {
            validateTimeDifference(startDate, startTime, endDate, endTime);
        }
    }, [startDate, startTime, endDate, endTime]);

    useEffect(() => {
        if (assessmentSid?.length !== 0) {
            getAssessmentQuestionCount('MCQ', assessmentSid);
        }
    }, [assessmentSid]);

    useEffect(() => {
        if (codingAssessmentSid?.length !== 0) {
            getAssessmentQuestionCount('CODING', codingAssessmentSid);
        }
    }, [codingAssessmentSid]);

    return (<>
        <div className="table-shadow p-3 pb-5">
            <CardHeader {...{
                location,
                onChange: (e) => e.length === 0 && getSessionByPage(),
                onEnter: (e) => searchSession(e),
                actionClick: () => { setShow(true); setIsEdit(false); setInitialValues({ contentTitle: '', contentLink: '' }) },
            }} >
                {/* <Button className=" ml-2" onClick={() => { setShow(true); setIsEdit(false); setContentType("Add Section") }}>+ Add Section</Button> */}
                <DropdownButton className="btn-sm f13" title="+ Add Section">
                    <Dropdown.Item onClick={() => { setShow(true); setIsEdit(false); setContentType("Add Section") }}>Add Section</Dropdown.Item>
                    <Dropdown.Item onClick={() => { setShow(true); setContentType("Upload Section") }}>Upload in Bulk</Dropdown.Item>
                </DropdownButton>
            </CardHeader>

            {/* <SessionList {...{
                sessionList: sessionList.slice().reverse(),
                onDelete: (e) => deleteSession(e),
                onEdit: (data) => { setIsEdit(true); setShow(true); setInitialValue(data) }
            }}
            /> */}
            <div className="full-w mt-2"></div>
            {contentType === "Add Section" ?

                <BsModal {...{ show, setShow, headerTitle: "Add Section", size: "lg" }}>
                    <div className="">
                        <div>
                            <Formik
                                initialValues={
                                    !isEdit ? { "topicName": '', "topicDescription": '', }
                                        : initialValues}
                                validationSchema={schema}
                                onSubmit={(values) => { !isEdit ? createSection(values) : editSession(values) }}>
                                {({ handleSubmit }) => (<>
                                    <form onSubmit={handleSubmit}>
                                        <TextInput name="topicName" label="Section Name" />
                                        <TextArea name="topicDescription" label="Description" />
                                        <div className="text-right mt-2">
                                            <Button type="submit" className=" px-4">Add  </Button>
                                        </div>

                                    </form>
                                </>)}

                            </Formik>
                        </div>
                    </div>
                </BsModal>
                :
                contentType === "Upload Section" ?
                    <BsModal {...{ show, setShow, headerTitle: "Upload Course in Bulk", size: "lg" }}>
                        <div>
                            <input name="file" onChange={(e) => setFiles(e.target.files[0])} type="file" />
                        </div>
                        <hr />
                        <a href='https://course-content-storage.s3.amazonaws.com/Upload-Template.xlsx' className="mt-5 link">Download Template</a>
                        <div className="jce">
                            <div>
                                <Cancel onClick={() => setShow(false)}>Cancel</Cancel>
                                <Button onClick={() => bulkCreateCourseSectionAndContents()}>Upload</Button>
                            </div>

                        </div>
                    </BsModal>
                    :
                    contentType === "Edit Section" ?
                        <BsModal {...{ show, setShow, headerTitle: "Update Section", size: "lg" }}>
                            <div className="">
                                <div>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={schema}
                                        onSubmit={(values) => { editCourseSection(values) }}>
                                        {({ handleSubmit }) => (<>
                                            <form onSubmit={handleSubmit}>
                                                <TextInput name="topicName" label="Section Name" />
                                                <TextArea name="topicDescription" label="Description" />
                                                <div className="text-right mt-2">
                                                    <Button type="submit" className=" px-4">Update  </Button>
                                                </div>

                                            </form>
                                        </>)}

                                    </Formik>
                                </div>
                            </div>
                        </BsModal>
                        :
                        <BsModal {...{ show, setShow, headerTitle: isEdit ? 'Update Content' : `Add ${contentCategory?.length !== 0 ? contentCategory : 'Content'}`, size: "lg" }}>
                            <div className="">
                                <Formik
                                    initialValues={isEdit ? initialValues : {
                                        "contentTitle": '', "contentLink": '', "assets": ''
                                    }}
                                    validationSchema={videoDocumentSchema}
                                    onSubmit={(values) => { !isEdit ? createUploadCourseSection(values) : editSession(values) }}>
                                    {({ handleSubmit, values }) => (<>
                                        <form onSubmit={handleSubmit}>

                                            {
                                                isEdit === true ? '' :

                                                    <div className="row mb-3  mx-1">

                                                        <div className='col-md-12'>
                                                            <label className=" label form-label">Content Type</label>
                                                            {/* <TextInput name="topicName" label="Content Title" /> */}
                                                            <select name="usertype" className="form-control mt-2" onChange={(e) => (handleshowhide(e))} style={{ borderRadius: "30px", backgroundColor: "rgb(248, 250, 251)" }}>
                                                                <option value="" >--Select Type--</option>
                                                                <option value="1_Video">Video</option>
                                                                <option value="2_Document">Document</option>
                                                                <option value="3_Meeting">Meeting</option>
                                                                <option value="4_Lab">Lab</option>
                                                                <option value="5_Assessment">Assessment</option>
                                                                <option value="6_Coding Question">Coding Question</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                            }

                                            {showhide === "1" || showhide === "2" || type === "VIDEO" || type === "EXTERNAL_LINK" || type === "DOCUMENTS" ?
                                                <div className='row mx-2 mt-3' >
                                                    <div className='col-12 mt-3 '>  <TextInput name="contentTitle" label="Content Title" /></div>
                                                </div>
                                                : ''
                                            }

                                            {
                                                (showhide === '1' || type === "VIDEO" || type === "EXTERNAL_LINK") && (
                                                    <>

                                                        <div className="row mt-2 mb-3 mx-2" >


                                                            <div className='col-md-6'>
                                                                <input type="radio" name="Option" value="LINK" onChange={(e) => (setUploadvideo(e.target.value))} id="LINK" defaultChecked />
                                                                <label className="form-check-label mx-2 title-md">Insert Link</label>
                                                            </div>

                                                            <div className='col-md-6'>
                                                                <input type="radio" name="Option" value="FILE" onChange={(e) => (setUploadvideo(e.target.value))} id="FILE" />
                                                                <label className=" form-check-label mx-2 title-md">Upload File</label>
                                                            </div>







                                                        </div>






                                                        <div className="row mx-1">
                                                            {
                                                                uploadvideo === "FILE" && <>
                                                                    <label className="mb-2 label form-label">Video Upload</label>
                                                                    <br />

                                                                    <input name="file" onChange={(e) => { setDocument(e.target.files[0]) }} type="file" className='form-control' style={{ background: "#f4ebff", border: "1px solid #f4ebff ", borderRadius: "8px", height: "40px" }} />

                                                                </>

                                                            }
                                                        </div>
                                                        <div className='row mb-3'>
                                                            {
                                                                uploadvideo === "LINK" && <>

                                                                    <div className="col-md-11 mx-3" >
                                                                        <TextInput name="contentLink" label="Video Link" />


                                                                    </div>

                                                                </>
                                                            }
                                                            {
                                                                <div style={{ color: "blue" }}>
                                                                    {isEdit ? fileName : ''}
                                                                    {/* <button onClick={deleteCourseContentFile}>Delete </button> */}
                                                                </div>

                                                            }

                                                        </div>
                                                        <div className='row mx-2 mb-3'>
                                                            <div className='col-md-6'>  <FormGroup>
                                                                <FormControlLabel control={<Switch checked={instructorVideo} onChange={(e) => setInstructorVideo(e.target.checked)} />} label="Instructor Specific" style={{ color: '#49167E' }} />
                                                            </FormGroup></div>
                                                            {
                                                                user.role === ROLE.CONSUMER_SUPERVISOR &&
                                                                <div className='col-md-6'>
                                                                    <FormGroup>
                                                                        <FormControlLabel control={<Switch checked={unLocked} onChange={(e) => setUnLocked(e.target.checked)} />} label="Unlocked" style={{ color: '#49167E' }} />
                                                                    </FormGroup>
                                                                </div>
                                                            }

                                                        </div>








                                                    </>
                                                )
                                            }

                                            {
                                                (showhide === '2' || type === "DOCUMENTS") && (
                                                    <>
                                                        <div className="row  mt-4 mx-4 mb-3" >
                                                            <div className='p-2' style={{ background: "#f4ebff", border: "1px solid #f4ebff ", borderRadius: "8px", width: "100%" }}>
                                                                <label className=" label form-label">Document</label>
                                                                <input name="file" onChange={(e) => { setDocument(e.target.files[0]) }} type="file" className='mt-2' />
                                                                {
                                                                    <div style={{ color: "blue" }}>
                                                                        {isEdit ? fileName : ''}

                                                                        {/* <button onClick={deleteCourseContentFile}>Delete </button> */}
                                                                    </div>

                                                                }

                                                            </div>






                                                        </div>
                                                        <div className='row mx-2 mb-3'>
                                                            <div className='col-md-6'>  <FormGroup>
                                                                <FormControlLabel control={<Switch checked={instructorDocuments} onChange={(e) => setInstructorDocuments(e.target.checked)} />} label="Instructor Specific" style={{ color: '#49167E' }} />
                                                            </FormGroup></div>
                                                            {

                                                                user.role === ROLE.CONSUMER_SUPERVISOR &&
                                                                <div className='col-md-6'>
                                                                    <FormGroup>
                                                                        <FormControlLabel control={<Switch checked={unLocked} onChange={(e) => setUnLocked(e.target.checked)} />} label="Unlocked" style={{ color: '#49167E' }} />
                                                                    </FormGroup>
                                                                </div>

                                                            }

                                                        </div>
                                                    </>

                                                )}
                                            {
                                                isEdit && type === 'TRAINING_SESSION' ?
                                                    ''
                                                    :


                                                    <div className="text-right mt-2" >
                                                        {showhide === '3' || showhide === '4' || showhide === '5' || showhide === '6' ? "" : <Button type="submit" className=" px-4">{isEdit ? "Update" : "Add"} </Button>}

                                                    </div>
                                            }
                                        </form>

                                    </>)}
                                </Formik>
                                {
                                    (showhide === '3' || type === 'TRAINING_SESSION') && (
                                        <div className="col-md-12 form-group mx-0">
                                            <Formik
                                                initialValues={isEdit ? initialValues : {
                                                    agenda: '',
                                                    topic: "",
                                                    assets: "",
                                                    endTime: '',
                                                    sessionDate: '',
                                                    startTime: '',

                                                }}
                                                onSubmit={(value) => { isEdit ? editTrainingSession(value) : createTrainingSession(value) }}
                                                validationSchema={schemaMeeting}
                                            >
                                                {({ handleSubmit, setFieldValue, isValid, values }) => (
                                                    <>
                                                        <form onSubmit={handleSubmit}>
                                                            <div className="row">

                                                                {/* <TextInput name="assets" label="Assets" /> */}
                                                                {
                                                                    !isEdit &&

                                                                    <div className="col-md-12 mt-3" >
                                                                        <SelectInput label="Training" bindKey="name" value={values.sid} payloadKey="sid" name="name" option={trainingList} />
                                                                    </div>
                                                                }
                                                                {/* {<div className="col-6  mt-3">
                                                                <div><span className="title-sm ">Assets</span></div>
                                                                <div><input multiple placeholder="Browse File" onChange={(e) => { uploadAttachments(e.target.files, setFieldValue) }} type="file" /></div>
                                                            </div>
                                                            } */}

                                                                <div className="col-md-12 ">
                                                                    {<TextInput name="topic" label="Agenda" />}
                                                                </div>
                                                                {
                                                                    !isEdit &&
                                                                    <>
                                                                        {/* <div className='col-md-12  mb-3 mx-2'>
                                                                        <input type="checkbox" id="closed" name="status" value="closed" onChange={(e) => setFeedbackSurveyRequired(e.target.checked)} />
                                                                        <label class="form-check-label form-label mx-2 title-sm">Submit Feedback</label>
                                                                    </div>*/}
                                                                        <div className='col-md-12  mb-3 mx-2'>   <FormGroup>
                                                                            <FormControlLabel control={<Switch onChange={(e) => setFeedbackSurveyRequired(e.target.checked)} />} label="Submit Feedback" style={{ color: '#49167E' }} />
                                                                        </FormGroup></div>
                                                                    </>
                                                                }

                                                                <div className="col-md-12 mb-5 mt-3">
                                                                    <TextArea name="agenda" label="Description" />
                                                                </div>

                                                                <div className="col-md-4 ">
                                                                    {/* <input type="date" id="start" name="sessionDate"  /> */}
                                                                    <DateInput name="sessionDate" label="Start date" />

                                                                </div>
                                                                <div className="col-md-4">
                                                                    <TimeInput name="startTime" placeholder="Select Time" label="Start Time" />
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <TimeInput name="endTime" placeholder="Select Time" label="End Time" />
                                                                </div>


                                                            </div>
                                                            <div>
                                                                <Button className="btn-block py-2 mt-3" type="submit">Confirm</Button>
                                                            </div>
                                                        </form>
                                                    </>
                                                )}
                                            </Formik>
                                        </div>
                                    )}
                                {
                                    showhide === '4' && isEdit === false && (
                                        <Formik
                                            initialValues={{
                                                labId: ''

                                            }}
                                            validationSchema={''}
                                            onSubmit={(value) => { addLabsInCourse(value) }}

                                        >
                                            {({ handleSubmit, setFieldValue, isValid, values }) => (

                                                <>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="row mt-4 mb-4 mx-2" >

                                                            {/* <input type="checkbox" id="capstone" name="capstone" checked={capstoneLabs}
                                                            onChange={(e) => setCapstoneLabs(e.target.checked)} /> */}
                                                            {/* <div className='col-md-11 mx-4' >
                                                            <label className="mb-2 label form-label ">Capstone Labs </label>


                                                            <select className="form-control" style={{ borderRadius: "30px", backgroundColor: "rgb(248, 250, 251)" }} onChange={(e) => setCapstoneLabs(e.target.value)}>
                                                                <option value="" disabled selected hidden>Select</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>

                                                        </div> */}

                                                            <div className='col'>
                                                                <input type="radio" name="Option" value="PRACTICE" onChange={(e) => setCapstoneLabs(e.target.value)} id="PRACTICE" defaultChecked />
                                                                <label className="form-check-label mx-3 title-md">Practice Lab</label>
                                                            </div>

                                                            <div className='col'>
                                                                <input type="radio" name="Option" value="CAPSTONE" onChange={(e) => setCapstoneLabs(e.target.value)} id="CAPSTONE" />
                                                                <label className=" form-check-label mx-3 title-md">Capstone Lab</label>
                                                            </div>

                                                            {/* <div className='title-sm'>(Select this option to narrow down the list of Labs suitable for assessment purposes.)</div> */}
                                                        </div>
                                                        <div className="row mt-2 mx-2  ">

                                                            {/* <TextInput name="assets" label="Assets" /> */}

                                                            <div className="col-6" >
                                                                <label className="mb-2 label form-label ">Lab Category</label>
                                                                <div className="input-wrapper">

                                                                    <select className="form-control" style={{ borderRadius: "30px", backgroundColor: "rgb(248, 250, 251)" }}
                                                                        onChange={(e) => setCategoryName(e.target.value)}>
                                                                        <option value="" disabled selected hidden>Select Lab Category</option>
                                                                        {
                                                                            categorieList.map((item) => {
                                                                                return (
                                                                                    <>
                                                                                        <option value={item.categoryName}>{item.categoryName}</option>
                                                                                    </>
                                                                                )
                                                                            })
                                                                        }

                                                                    </select>

                                                                </div>
                                                                {/* <SelectInput label="Lab-Category" option={categorieList} /> */}
                                                            </div>
                                                            <div className="col-6 " >
                                                                <div className="input-wrapper mt-3">
                                                                    <Select

                                                                        defaultValue={selectedOption}
                                                                        onChange={setSelectedOption}
                                                                        options={accountLabs}
                                                                        isMulti
                                                                        className="basic-multi-select"
                                                                        classNamePrefix="select"
                                                                        required
                                                                    // isValid={selectedOption !== null && selectedOption.length > 0}
                                                                    />
                                                                </div>
                                                                {/* <SelectInput label="Lab-Details" bindKey="labName" payloadKey="labId" name="labName" value={values.labId} option={accountLabs} /> */}
                                                            </div>
                                                        </div>
                                                        {
                                                            capstoneLabs === 'PRACTICE' && user.role === ROLE.CONSUMER_SUPERVISOR &&

                                                            <div className='mx-3'>
                                                                <FormGroup>
                                                                    <FormControlLabel control={<Switch checked={unLocked} onChange={(e) => setUnLocked(e.target.checked)} />} label="Unlocked" style={{ color: '#49167E' }} />
                                                                </FormGroup>
                                                            </div>
                                                        }

                                                        <div className='col-md-12 mt-4 mb-3 '>
                                                            <label className='label form-label'>Enter Technology Tags</label>
                                                            <ReactTags
                                                                tags={tags}
                                                                placeholder="Tags..."
                                                                minQueryLength={3}
                                                                delimiters={tags.length < 10 ? [KeyCodes.enter] : []}
                                                                handleDelete={handleDelete}
                                                                handleAddition={handleAddition}

                                                                inputFieldPosition="bottom"

                                                            />

                                                            <p className='mx-2'>{10 - tags.length} tags remaining</p>
                                                        </div>

                                                        {
                                                            capstoneLabs === 'CAPSTONE' &&
                                                            <>


                                                                <div className='row '>
                                                                    <div className="col-6 mb-3 mx-4" >
                                                                        <FormGroup>
                                                                            <FormControlLabel control={<Switch checked={showResult} onChange={(e) => setShowResult(e.target.checked)} />} label="Show Result" style={{ color: '#49167E' }} />
                                                                        </FormGroup>
                                                                    </div>

                                                                    <div className='form-group mx-4'>
                                                                        <div className="col-lg-6 col-md-6 col-xl-6">
                                                                            <label className="label form-label">
                                                                                Start date
                                                                            </label>
                                                                            <div className="input-wrapper">
                                                                                <div className="input-field">
                                                                                    <input
                                                                                        className="form-control form-control-sm"
                                                                                        type="date"
                                                                                        value={startDate}
                                                                                        min={currentDate}
                                                                                        onChange={handleOnchangeStartDate}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                {dateError && <div style={{ color: 'red' }}>{dateError}</div>}
                                                                            </div>
                                                                        </div>


                                                                        <div className="col-lg-6 col-md-6 col-xl-6">
                                                                            <label className="label form-label">
                                                                                End date
                                                                            </label>
                                                                            <div className="input-wrapper">
                                                                                <div className="input-field">
                                                                                    <input
                                                                                        className="form-control form-control-sm"
                                                                                        type="date"
                                                                                        value={endDate}
                                                                                        min={currentDate}
                                                                                        onChange={handleOnchangeEndDate}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                {/* {dateError && <div style={{ color: 'red' }}>{dateError}</div>} */}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-6 col-md-6 col-xl-6">
                                                                            <label className="label form-label">
                                                                                Start time
                                                                            </label>
                                                                            <div className="input-wrapper">
                                                                                <div className="input-field">
                                                                                    <input
                                                                                        className="form-control form-control-sm"
                                                                                        type="time"
                                                                                        value={startTime}
                                                                                        onChange={handleOnchangeStartTime}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                {errorTime && <div style={{ color: 'red' }}>{errorTime}</div>}
                                                                            </div>
                                                                        </div>


                                                                        <div className="col-lg-6 col-md-6 col-xl-6">
                                                                            <label className="label form-label">
                                                                                End time
                                                                            </label>
                                                                            <div className="input-wrapper">
                                                                                <div className="input-field">
                                                                                    <input
                                                                                        className="form-control form-control-sm"
                                                                                        type="time"
                                                                                        value={endTime}
                                                                                        onChange={handleOnchangeEndTime}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                {errorTime && <div style={{ color: 'red' }}>{errorTime}</div>}
                                                                            </div>
                                                                        </div>


                                                                        <div className="col-lg-6 col-md-6 col-xl-6">
                                                                            <label className="label form-label">
                                                                                Timezone
                                                                            </label>
                                                                            <div className="input-wrapper">
                                                                                <div className="input-field">
                                                                                    <select id="timezone" class="form-control" value={timeZoneFormat} onChange={handleOnchangeTimeZone}>
                                                                                        <option value="" disabled selected>Select</option>
                                                                                        {
                                                                                            GLOBELCONSTANT.timezone.map(zone =>
                                                                                                <option value={zone.label}>{zone.label}</option>)
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>


                                                                        <div className="col-lg-6 col-md-6 col-xl-6">
                                                                            <label className="label form-label">
                                                                                Passing percentage (%)
                                                                            </label>
                                                                            <div className="input-wrapper">
                                                                                <div className="input-field">
                                                                                    <input
                                                                                        className="form-control form-control-sm"
                                                                                        type="text"
                                                                                        placeholder="Passing Percentage"
                                                                                        onChange={handleOnchangePassingPercentage}
                                                                                        value={passingPercentage}
                                                                                    />

                                                                                </div>
                                                                                {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </>
                                                        }
                                                        <div>
                                                            <Button className="btn-block py-2 mt-3" type="submit">Confirm</Button>
                                                        </div>
                                                    </form>
                                                </>
                                            )}

                                        </Formik>

                                    )}
                                {
                                    showhide === '5' && isEdit === false && (
                                        <Formik
                                            initialValues={{
                                                assessmentSid: ''
                                            }}
                                            onSubmit={(value) => {
                                                if (assessmentType === "MCQ") {
                                                    addAssessmentToCourse(value)
                                                }
                                                else {
                                                    addCodingAssessmentToCourse(value);
                                                }
                                            }}

                                        >
                                            {({ handleSubmit, setFieldValue, isValid, values }) => (

                                                <>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="row mb-3 mx-1">

                                                            {/* <TextInput name="assets" label="Assets" /> */}
                                                            <div className="col-12" >
                                                                <label className="mb-2 label form-label ">Assessment Type</label>
                                                                {/* <div className="col-6" >
                                                                <SelectInput label="Topic" bindKey="topicName" value={values.topicSid} payloadKey="topicSid" name="topicName" option={topics} onChange={e => {hello(e.target.value)}}/>
                                                            </div> */}
                                                                <div className="input-wrapper">

                                                                    <select className="form-control" style={{ borderRadius: "30px", backgroundColor: "rgb(248, 250, 251)" }}
                                                                        onChange={(e) => { setAssessmentType(e.target.value); setShowResult(false); setQuestionBasket('0'); setAssessmentSid(''); setCodingAssessmentSid(''); setTotalQuestionCount(0); setBasketErrors(''); }}>
                                                                        <option value="" disabled selected hidden>Select Type</option>
                                                                        <option value="MCQ">Mcq Assessment</option>
                                                                        <option value="CODING">Coding Assessment</option>
                                                                    </select>
                                                                </div>

                                                                {/* <SelectInput label="Lab-Category" option={categorieList} /> */}
                                                            </div>
                                                            {
                                                                assessmentType === "MCQ" &&
                                                                <>
                                                                    <div className="col-6" >
                                                                        <label className="mb-2 label form-label ">Topics</label>
                                                                        {/* <div className="col-6" >
                                                                <SelectInput label="Topic" bindKey="topicName" value={values.topicSid} payloadKey="topicSid" name="topicName" option={topics} onChange={e => {hello(e.target.value)}}/>
                                                            </div> */}
                                                                        <div className="input-wrapper">

                                                                            <select className="form-control" style={{ borderRadius: "30px", backgroundColor: "rgb(248, 250, 251)" }} onChange={(e) => getAllAssessmentsInATopic(e.target.value)}>
                                                                                <option value="" disabled selected hidden>Select Topic</option>
                                                                                {
                                                                                    topics.map((item) => {
                                                                                        return (
                                                                                            <>
                                                                                                <option value={item.topicSid}>{item.topicName}</option>
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>

                                                                        {/* <SelectInput label="Lab-Category" option={categorieList} /> */}
                                                                    </div>
                                                                    <div className="col-6" >
                                                                        <SelectInput label="Assessment" bindKey="assessmentName" payloadKey="assessmentSid" name="assessmentName" value={values.assessmentSid} option={assessment} setAssessmentSid={setAssessmentSid} />
                                                                    </div>
                                                                    <div className="col-6" >
                                                                        <FormGroup>
                                                                            <FormControlLabel control={<Switch checked={showResult} onChange={(e) => setShowResult(e.target.checked)} />} label="Show Result" style={{ color: '#49167E' }} />
                                                                        </FormGroup>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-xl-6">
                                                                        <label className="label form-label">
                                                                            Basket Question
                                                                        </label>
                                                                        <div className="input-wrapper">
                                                                            <div className="input-field">
                                                                                <input
                                                                                    className="form-control form-control-sm"
                                                                                    type="text"
                                                                                    value={questionBasket}
                                                                                    onChange={(e) => { validateBasket(e); setQuestionBasket(e.target.value) }}
                                                                                />
                                                                                <span class="input-group-text common-class" style={{ fontSize: "14px" }}>/ {totalQuestionCount}</span>
                                                                            </div>
                                                                            {basketErrors?.length !== 0 && <div style={{ color: 'red' }}>{basketErrors}</div>}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            }
                                                            {
                                                                assessmentType === "CODING" &&
                                                                <>

                                                                    <div className="col-6" >
                                                                        <label className="mb-2 label form-label ">Topics</label>
                                                                        {/* <div className="col-6" >
                                                                <SelectInput label="Topic" bindKey="topicName" value={values.topicSid} payloadKey="topicSid" name="topicName" option={topics} onChange={e => {hello(e.target.value)}}/>
                                                            </div> */}
                                                                        <div className="input-wrapper">

                                                                            <select className="form-control" style={{ borderRadius: "30px", backgroundColor: "rgb(248, 250, 251)" }} onChange={(e) => getTopicCodingAssessment(e.target.value)}>
                                                                                <option value="" disabled selected hidden>Select Topic</option>
                                                                                {
                                                                                    Object.keys(topicsCodingAssessment).map((item) => {
                                                                                        return (
                                                                                            <>
                                                                                                <option value={item}>{topicsCodingAssessment[item]}</option>
                                                                                            </>
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </select>
                                                                        </div>

                                                                        {/* <SelectInput label="Lab-Category" option={categorieList} /> */}
                                                                    </div>
                                                                    <div className="col-6" >
                                                                        <SelectInput label="Assessment" bindKey="codingAssessmentName" payloadKey="codingAssessmentSid" name="codingAssessmentName" value={values.codingAssessmentSid} option={assessmentCoding} setCodingAssessmentSid={setCodingAssessmentSid} />
                                                                    </div>
                                                                    <div className="col-6" >
                                                                        <FormGroup>
                                                                            <FormControlLabel control={<Switch checked={showResult} onChange={(e) => setShowResult(e.target.checked)} />} label="Show Result" style={{ color: '#49167E' }} />
                                                                        </FormGroup>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6 col-xl-6">
                                                                        <label className="label form-label">
                                                                            Basket Question
                                                                        </label>
                                                                        <div className="input-wrapper">
                                                                            <div className="input-field">
                                                                                <input
                                                                                    className="form-control form-control-sm"
                                                                                    type="text"
                                                                                    value={questionBasket}
                                                                                    onChange={(e) => { validateBasket(e); setQuestionBasket(e.target.value) }}
                                                                                />
                                                                                <span class="input-group-text common-class" style={{ fontSize: "14px" }}>/ {totalQuestionCount}</span>
                                                                            </div>
                                                                            {basketErrors?.length !== 0 && <div style={{ color: 'red' }}>{basketErrors}</div>}
                                                                        </div>
                                                                    </div>
                                                                    <div className='row '>

                                                                        <div className='form-group mx-4'>
                                                                            <div className="col-lg-6 col-md-6 col-xl-6">
                                                                                <label className="label form-label">
                                                                                    Start date
                                                                                </label>
                                                                                <div className="input-wrapper">
                                                                                    <div className="input-field">
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="date"
                                                                                            value={startDate}
                                                                                            min={currentDate}
                                                                                            onChange={handleOnchangeStartDate}
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                    {dateError && <div style={{ color: 'red' }}>{dateError}</div>}
                                                                                </div>
                                                                            </div>


                                                                            <div className="col-lg-6 col-md-6 col-xl-6">
                                                                                <label className="label form-label">
                                                                                    End date
                                                                                </label>
                                                                                <div className="input-wrapper">
                                                                                    <div className="input-field">
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="date"
                                                                                            value={endDate}
                                                                                            min={currentDate}
                                                                                            onChange={handleOnchangeEndDate}
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                    {/* {dateError && <div style={{ color: 'red' }}>{dateError}</div>} */}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-lg-6 col-md-6 col-xl-6">
                                                                                <label className="label form-label">
                                                                                    Start time
                                                                                </label>
                                                                                <div className="input-wrapper">
                                                                                    <div className="input-field">
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="time"
                                                                                            value={startTime}
                                                                                            onChange={handleOnchangeStartTime}
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                    {errorTime && <div style={{ color: 'red' }}>{errorTime}</div>}
                                                                                </div>
                                                                            </div>


                                                                            <div className="col-lg-6 col-md-6 col-xl-6">
                                                                                <label className="label form-label">
                                                                                    End time
                                                                                </label>
                                                                                <div className="input-wrapper">
                                                                                    <div className="input-field">
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="time"
                                                                                            value={endTime}
                                                                                            onChange={handleOnchangeEndTime}
                                                                                            required
                                                                                        />
                                                                                    </div>
                                                                                    {errorTime && <div style={{ color: 'red' }}>{errorTime}</div>}
                                                                                </div>
                                                                            </div>


                                                                            <div className="col-lg-6 col-md-6 col-xl-6">
                                                                                <label className="label form-label">
                                                                                    Timezone
                                                                                </label>
                                                                                <div className="input-wrapper">
                                                                                    <div className="input-field">
                                                                                        <select id="timezone" class="form-control" value={timeZoneFormat} onChange={handleOnchangeTimeZone}>
                                                                                            <option value="" disabled selected>Select</option>
                                                                                            {
                                                                                                GLOBELCONSTANT.timezone.map(zone =>
                                                                                                    <option value={zone.label}>{zone.label}</option>)
                                                                                            }
                                                                                        </select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            <div className="col-lg-6 col-md-6 col-xl-6">
                                                                                <label className="label form-label">
                                                                                    Passing percentage (%)
                                                                                </label>
                                                                                <div className="input-wrapper">
                                                                                    <div className="input-field">
                                                                                        <input
                                                                                            className="form-control form-control-sm"
                                                                                            type="text"
                                                                                            placeholder="Passing Percentage"
                                                                                            onChange={handleOnchangePassingPercentage}
                                                                                            value={passingPercentage}
                                                                                        />

                                                                                    </div>
                                                                                    {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                                                                                </div>
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                        {
                                                            assessmentType === "MCQ" &&
                                                            <>

                                                                <div className='row '>
                                                                    <div className='form-group mx-4'>
                                                                        <div className="col-lg-6 col-md-6 col-xl-6">
                                                                            <label className="label form-label">
                                                                                Start date
                                                                            </label>
                                                                            <div className="input-wrapper">
                                                                                <div className="input-field">
                                                                                    <input
                                                                                        className="form-control form-control-sm"
                                                                                        type="date"
                                                                                        value={startDate}
                                                                                        min={currentDate}
                                                                                        onChange={handleOnchangeStartDate}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                {dateError && <div style={{ color: 'red' }}>{dateError}</div>}
                                                                            </div>
                                                                        </div>


                                                                        <div className="col-lg-6 col-md-6 col-xl-6">
                                                                            <label className="label form-label">
                                                                                End date
                                                                            </label>
                                                                            <div className="input-wrapper">
                                                                                <div className="input-field">
                                                                                    <input
                                                                                        className="form-control form-control-sm"
                                                                                        type="date"
                                                                                        value={endDate}
                                                                                        min={startDate}
                                                                                        onChange={handleOnchangeEndDate}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                {/* {dateError && <div style={{ color: 'red' }}>{dateError}</div>} */}
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-6 col-md-6 col-xl-6">
                                                                            <label className="label form-label">
                                                                                Start time
                                                                            </label>
                                                                            <div className="input-wrapper">
                                                                                <div className="input-field">
                                                                                    <input
                                                                                        className="form-control form-control-sm"
                                                                                        type="time"
                                                                                        value={startTime}
                                                                                        onChange={handleOnchangeStartTime}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                {errorTime && <div style={{ color: 'red' }}>{errorTime}</div>}
                                                                            </div>
                                                                        </div>


                                                                        <div className="col-lg-6 col-md-6 col-xl-6">
                                                                            <label className="label form-label">
                                                                                End time
                                                                            </label>
                                                                            <div className="input-wrapper">
                                                                                <div className="input-field">
                                                                                    <input
                                                                                        className="form-control form-control-sm"
                                                                                        type="time"
                                                                                        value={endTime}
                                                                                        onChange={handleOnchangeEndTime}
                                                                                        required
                                                                                    />
                                                                                </div>
                                                                                {errorTime && <div style={{ color: 'red' }}>{errorTime}</div>}
                                                                            </div>
                                                                        </div>


                                                                        <div className="col-lg-6 col-md-6 col-xl-6">
                                                                            <label className="label form-label">
                                                                                Timezone
                                                                            </label>
                                                                            <div className="input-wrapper">
                                                                                <div className="input-field">
                                                                                    <select id="timezone" class="form-control" value={timeZoneFormat} onChange={handleOnchangeTimeZone}>
                                                                                        <option value="" disabled selected>Select</option>
                                                                                        {
                                                                                            GLOBELCONSTANT.timezone.map(zone =>
                                                                                                <option value={zone.label}>{zone.label}</option>)
                                                                                        }
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-lg-6 col-md-6 col-xl-6">
                                                                            <label className="label form-label">
                                                                                Passing percentage (%)
                                                                            </label>
                                                                            <div className="input-wrapper">
                                                                                <div className="input-field">
                                                                                    <input
                                                                                        className="form-control form-control-sm"
                                                                                        type="text"
                                                                                        placeholder="Passing Percentage"
                                                                                        onChange={handleOnchangePassingPercentage}
                                                                                        value={passingPercentage}
                                                                                    />

                                                                                </div>
                                                                                {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                                <div className='col-md-12  mb-3 mx-1'>
                                                                    <label className='label form-label'>Enter Technology Tags</label>
                                                                    <ReactTags
                                                                        tags={tags1}
                                                                        placeholder="Tags..."
                                                                        minQueryLength={3}
                                                                        delimiters={tags1.length < 10 ? [KeyCodes.enter] : []}
                                                                        handleDelete={handleDelete1}
                                                                        handleAddition={handleAddition1}

                                                                        inputFieldPosition="bottom"

                                                                    />

                                                                    <p className='mx-2'>{10 - tags1.length} tags remaining</p>
                                                                </div>
                                                            </>
                                                        }
                                                        <div>
                                                            <Button className="btn-block py-2 mt-3" type="submit">Confirm</Button>
                                                        </div>
                                                    </form>
                                                </>
                                            )}

                                        </Formik>

                                    )}
                                {
                                    showhide === '6' && isEdit === false && (
                                        <Formik
                                            initialValues={{
                                                questionSid: ''
                                            }}
                                            onSubmit={(value) => { addCodingQuestionToSection(value) }}

                                        >
                                            {({ handleSubmit, setFieldValue, isValid, values }) => (

                                                <>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="row mb-3 mx-1">

                                                            {/* <TextInput name="assets" label="Assets" /> */}

                                                            <div className='col-md-12'>
                                                                <SelectInput className="form-control" label="Coding Question" bindKey="question" payloadKey="questionId" name="question" value={values.questionId} option={codingQuestion} />
                                                            </div>



                                                        </div>


                                                        <div className='col-md-12'>
                                                            <label className='label form-label'>Enter Technology Tags</label>
                                                            <ReactTags
                                                                tags={tags2}
                                                                placeholder="Tags..."
                                                                minQueryLength={3}
                                                                delimiters={tags2.length < 10 ? [KeyCodes.enter] : []}
                                                                handleDelete={handleDelete2}
                                                                handleAddition={handleAddition2}

                                                                inputFieldPosition="bottom"

                                                            />

                                                            <p>{10 - tags2.length} tags remaining</p>
                                                        </div>


                                                        <div>
                                                            <Button className="btn-block py-2 mt-3" type="submit">Confirm</Button>
                                                        </div>
                                                    </form>
                                                </>
                                            )}

                                        </Formik>

                                    )}

                            </div>
                        </BsModal>
            }

            {/* <DynamicTable {...{ configuration, sourceData: sessionList, onPageChange: (e) => getSection(e) }} /> */}
            {
                loader ?
                    <SkeletonLoader skeletonCount={15.5} />
                    :
                    sessionList?.length !== 0 &&
                    <div style={{ width: "100%", background: "#FAFAFA", borderRadius: "10px" }}>
                        {sessionList?.map((item) => {
                            return (
                                <>
                                    <DropdownItem title={item.sectionName} theme="dark">
                                        <Button className=" ml-2 mb-2 mt-2" onClick={() => {

                                            setShow(true); setContentType("Add Content"); setSectionSid(item.sid); setShowhide(""); setContentCategory(""); setContentType(""); setIsEdit(false); setType(''); setAccountLabs('');
                                            setCapstoneLabs('PRACTICE'); setUnLocked(false); setStartDate(''); setStartTime(''); setEndDate(''); setEndTime(''); setPassingPercentage(30);
                                            setTimeZone(''); setTimeZoneFormat(''); setAssessmentType(''); setErrorMessage(''); setErrorTime(''); setDateError('');
                                            setSelectedOption(null); setCategoryName(''); setShowResult(false); setQuestionBasket('0'); setAssessmentSid('');
                                            setCodingAssessmentSid(''); setTotalQuestionCount(0); setBasketErrors('')

                                        }}> + Add Content</Button>

                                        <ButtonDelete className="mb-2 mx-3 mt-2 float-right" onClick={() => deleteCourseSection(item.sid)}>Delete Section</ButtonDelete>

                                        <Button className="float-right mb-2 mt-2" onClick={() => {
                                            setShow(true); setIsEdit(false); setContentType("Edit Section"); setInitialValues({
                                                topicName: item.sectionName,
                                                topicDescription: item.description,
                                                sid: item.sid
                                            })
                                        }}>Edit Section</Button>




                                        <DynamicTable  {...{ configuration, sourceData: item.courseContentResposeTOList, onPageChange: (e) => getSection(e) }} />
                                    </DropdownItem>

                                </>
                            )
                        })}
                    </div>
            }
        </div >

    </>)
}
export default CourseDetails