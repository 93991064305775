import React, { useState, useEffect } from 'react'

import GLOBELCONSTANT from "../../../Constant/GlobleConstant"
import { Button } from '../../Common/Buttons/Buttons'
import SkeletonLoader from '../../Common/SkeletonLoader/SkeletonLoader';

const VsCodePage = () => {

    const [loader, setLoader] = useState(true);

    useEffect(() => {

        setTimeout(() => {
            setLoader(false);
        }, 2000);

    }, []);

    const onOpen = () => {
        window.open('https://gnosislabs.cloud/')
    }

    return (<>
        {

            loader ?
                <SkeletonLoader skeletonCount={1} />
                :
                <div className="table-shadow mt-2 py-5 aic">
                    <div className="">
                        <Button onClick={() => onOpen()}>Open VS Code Editor</Button>
                    </div>
                </div>
        }
        {/* <object type="text/html" data={GLOBELCONSTANT.VSCODE_PATH} style={{ width: "100%", height: "100%" }}>
            <p className="px-4">Loading... VsCode</p>
        </object>  */}
    </>)
}
export default VsCodePage 