import { Chart, registerables } from 'chart.js';
import { useState } from 'react';

import { Bar } from 'react-chartjs-2';

// Registering the required components
// ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
// Chart.register(...registerables);
const DistributionBarChart = ({ assessmentDetails, avgData }) => {
    const data = {
        labels: ['MCQ', 'Lab', 'Coding'],
        datasets: [
            {
                label: 'Average Percentage',
                data: avgData, // Replace with your actual median values
                // We'll use error to indicate the variability (this would be the IQR or std deviation)
                error: [10, 15, 20], // Replace with your actual variability measures
                backgroundColor: '#7F56D9',
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: 'Distribution of Assessment Percentages',
            },
            tooltip: {
                callbacks: {
                    afterLabel: function (context) {
                        const error = context.dataset.error[context.dataIndex];
                        return `+/- ${error}%`;
                    },
                },
            },

        },
        legend: {
            backgroundColor: "#7F56D9"
        },
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                },
            }],
        }
    };
    return (
        <Bar data={data} options={options} />
    )
}

export default DistributionBarChart