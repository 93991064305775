import React from 'react';
import ReactApexChart from 'react-apexcharts';

const DonutChart = () => {
  const chartOptions = {
    labels: ['Current Lab usage', 'Remaining Lab usage', ],
    colors: ['#C8A3DF', '#BFCBF7', ],
    chart: {
      type: 'donut',
    },
    plotOptions: {
      pie: {
        donut: {
          size: '60%',
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const chartSeries = [45, 55];

  return (
    <div>
      <ReactApexChart
        options={chartOptions}
        series={chartSeries}
        type="donut"
        width="390"
        height="400"
      />
    </div>
  );
};

export default DonutChart;
