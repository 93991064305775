import React, { useState, useEffect, useContext } from 'react';
import "./Consumerheader.css"
import { ICN_HEADER_EXPAND, ICN_LOGO, ICN_HEADER_LOGO1, ICN_HEADER_LOGO2, ICN_HEADER_LOGO3, ICN_HEADER_LOGO4, ICN_HEADER_LOGO13, ICN_HEADER_LOGO14, ICN_HEADER_LOGO15, ICN_HEADER_LOGO16, ICN_LEARNLYTICA_BG_WHITE, ICN_NEXT, ADD_CART_ICON, PURCHASE_HISTORY, USER } from '../../Common/Icon';


// import CartContext from '../../Store/CartContext';
// import RestService from '../../Services/api.service';
import moment from 'moment';
// import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';


import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { Link, useLocation, useNavigate } from '@reach/router';
import RestService from '../../../Services/api.service';
import { TokenService } from '../../../Services/storage.service';
import AppContext from '../../../Store/AppContext';

const ConsumerHeader = () => {
  const { setUserValue, spinner, ROLE } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [show, setShow] = useState(false);
  const [isDropdownActive, setDropdownActive] = useState(false);
  //   const { cartResponse } = useContext(CartContext);




  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : '';
  const firstLetter = user.appuser?.name ? user.appuser?.name.charAt(0).toUpperCase() : '';

  let location = useLocation();
  const navigate = useNavigate();

  const purchasehistory = () => {
    navigate("https://learnlytica.com/purchase-history")
  }
  const mycourses = () => {
    navigate("https://learnlytica.com/mycourses")
  }

  const accountsettings = () => {
    navigate("https://learnlytica.com/settings")
  }

  //   calculate timespend in portal
  const getLogoutTimes = () => {
    return new Promise((resolve, reject) => {
      try {
        const dateNow = moment().format();
        const dateLarger = moment(dateNow);
        const dateStored = localStorage.getItem("timestamp");
        let timeSpent = dateLarger.diff(dateStored);
        RestService.getLogoutTimes(timeSpent).then(res => {
          TokenService.removeToken();
          setUserValue("LOGOUT");
          resolve(); // Resolve the promise here
        }, err => {
          TokenService.removeToken();
          setUserValue("LOGOUT");
          resolve(); // Resolve the promise here even if there's an error
        });
      }
      catch (err) {
        console.error('error occur on getLogoutTimes()', err);
        reject(err); // Reject the promise if a synchronous error occurs
      }
    });
  }


  //logout
  const LogOut = async () => {
    const url = `https://learnlytica.com/logout`;
    try {
      await getLogoutTimes(); // Wait for this to complete
    } catch (err) {
      console.error("error occur on LogOut()", err);
    } finally {
      navigate("/logout-user");
      window.location.href = url; // This will execute regardless of the try block success or catch block error
    }
  }


  useEffect(() => {
    const navToggle = document.querySelector('.nav_toggle');
    const navToggleIcon = document.querySelector('.nav_toggle ion-icon');
    const navMenu = document.querySelector('.nav_menu');


    const handleToggleClick = () => {
      setDropdownActive((prevIsDropdownActive) => !prevIsDropdownActive);
    };

    navToggle.addEventListener('click', handleToggleClick);

    return () => {
      navToggle.removeEventListener('click', handleToggleClick);
    };
  }, []); // Empty dependency array ensures that the effect runs only once on component mount



  return (
    <>
      <header>
        <div class="newcontainer ">
          <nav class="navigation">


            <a href="https://learnlytica.com/" class="logo">
              {/* <img src="images/logo.svg" alt="Logo" class="logo-img" /> */}
              {ICN_LOGO}
            </a>

            <ul className={`nav_menu${isDropdownActive ? ' active' : ''}`}>
              {/* <li class="nav_list">
                <Link to="/" class="nav_link">
                  <span>Home</span>
                </Link>
              </li> */}
              <li class="nav_list nav_list_menu" >
                <a href="#!" class="nav_link">
                  <span>Solutions</span>
                  {/* <ion-icon name={isDropdownActive ? 'ICN_HEADER_EXPAND' : 'menu-outline'}></ion-icon> */}
                  <div className='ion-icon'>{ICN_HEADER_EXPAND}</div>
                </a>
                <div class="dropdown-header ">
                  <div class="dropdown-header-inner">
                    <div class="dropdown-item" >
                      <h3 class="item-heading">Corporate Training</h3>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-1.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO1}
                        </div>
                        <div class="item-list-info">
                          <h4>Virtual Training</h4>
                          <p>Digital Skill Workshops.</p>
                        </div>
                      </div>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-2.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO2}
                        </div>
                        <div class="item-list-info">
                          <h4>VILT</h4>
                          <p>Engaging Virtual Sessions.</p>
                        </div>
                      </div>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-3.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO3}
                        </div>
                        <div class="item-list-info">
                          <h4>Onboarding</h4>
                          <p>Employee Integration</p>
                        </div>
                      </div>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-4.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO4}
                        </div>
                        <div class="item-list-info">
                          <span class="info-badge">New</span>
                          <h4>Trainers Pool</h4>
                          <p>Diverse Training Specialists</p>
                        </div>
                      </div>

                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-4.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO4}
                        </div>
                        <div class="item-list-info">
                          {/* <span class="info-badge">New</span> */}
                          <h4>Automated Reports</h4>
                          <p>Set-and-Forget Convenience</p>
                        </div>
                      </div>

                      <div class="item-list">
                        <div class="item-img" style={{ background: "transparent" }}>
                          {/* <img src="images/icon-8.svg" alt="Icon" /> */}
                          {/* {ICN_HEADER_LOGO4} */}
                        </div>
                        <div class="item-list-info">
                          {/* <h4>Grow</h4> */}
                          {/* <Link to="/training" class="item-heading">Explore Catalog.{ICN_NEXT}</Link> */}
                          <a href="https://learnlytica.com/training" onClick={() => window.location.reload()} class="item-heading">Explore More.{ICN_NEXT}</a>



                        </div>

                      </div>
                    </div>




                    <div class="dropdown-item" >
                      <h3 class="item-heading">Self Paced Courses</h3>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-5.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO1}
                        </div>
                        <div class="item-list-info">
                          <h4>Role based Learning Paths</h4>
                          <p>Guided Learning Journeys.</p>
                        </div>
                      </div>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-6.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO2}
                        </div>
                        <div class="item-list-info">
                          <h4>Interactive Modules</h4>
                          <p>Self-Assessment Opportunities.</p>
                        </div>
                      </div>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-7.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO3}
                        </div>
                        <div class="item-list-info">
                          <h4>Video Library</h4>
                          <p>Expert-led Sessions.</p>
                        </div>
                      </div>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-8.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO4}
                        </div>
                        <div class="item-list-info">
                          <h4>Resource Repository</h4>
                          <p>Engaging Multimedia Content.</p>



                        </div>

                      </div>

                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-8.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO4}
                        </div>
                        <div class="item-list-info">
                          <h4>Interactive Virtual Labs</h4>
                          <p>Hands-On Learning.</p>



                        </div>

                      </div>

                      <div class="item-list">
                        <div class="item-img" style={{ background: "transparent" }}>
                          {/* <img src="images/icon-8.svg" alt="Icon" /> */}
                          {/* {ICN_HEADER_LOGO4} */}
                        </div>
                        <div class="item-list-info">
                          {/* <h4>Grow</h4> */}
                          <a href="https://learnlytica.com/all-courses" class="item-heading">Explore all Courses.{ICN_NEXT}</a>
                          {/* <Link to="/all-courses" class="item-heading">Explore all Courses.{ICN_NEXT}</Link> */}



                        </div>

                      </div>
                    </div>

                    <div class="dropdown-item" >
                      <h3 class="item-heading">Sandbox</h3>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-5.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO1}
                        </div>
                        <div class="item-list-info">
                          <h4>Development Labs</h4>
                          <p>Software Development Projects.</p>
                        </div>
                      </div>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-6.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO2}
                        </div>
                        <div class="item-list-info">
                          <h4>Cloud Labs</h4>
                          <p>Deploy and Experiment with Cloud Services.</p>
                        </div>
                      </div>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-7.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO3}
                        </div>
                        <div class="item-list-info">
                          <h4>Coding Labs</h4>
                          <p>Algorithmic Problem Solving.</p>
                        </div>
                      </div>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-8.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO4}
                        </div>
                        <div class="item-list-info">
                          <h4>Security Labs</h4>
                          <p>Security Protocols and Practices.</p>
                        </div>
                      </div>

                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-8.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO4}
                        </div>
                        <div class="item-list-info">
                          <h4>Networking Labs</h4>
                          <p>Troubleshooting and Optimization Exercises.</p>
                        </div>
                      </div>

                      <div class="item-list">
                        <div class="item-img" style={{ background: "transparent" }}>
                          {/* <img src="images/icon-8.svg" alt="Icon" /> */}
                          {/* {ICN_HEADER_LOGO4} */}
                        </div>
                        <div class="item-list-info">
                          {/* <h4>Grow</h4> */}
                          <a href="https://learnlytica.com/sandbox" class="item-heading">Explore Sandbox.{ICN_NEXT}</a>



                        </div>

                      </div>
                    </div>

                    <div class="dropdown-item" >
                      <h3 class="item-heading">Content</h3>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-5.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO13}
                        </div>
                        <div class="item-list-info">
                          <h4>Convert</h4>
                          <p>Analyze conversion rates.</p>
                        </div>
                      </div>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-6.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO14}
                        </div>
                        <div class="item-list-info">
                          <h4>Engage</h4>
                          <p>Measure active usage.</p>
                        </div>
                      </div>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-7.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO15}
                        </div>
                        <div class="item-list-info">
                          <h4>Retain</h4>
                          <p>Find retention drivers.</p>
                        </div>
                      </div>
                      <div class="item-list">
                        <div class="item-img">
                          {/* <img src="images/icon-8.svg" alt="Icon" /> */}
                          {ICN_HEADER_LOGO16}
                        </div>
                        <div class="item-list-info">
                          <span class="info-badge">New</span>
                          <h4>Grow</h4>
                          <p>Grow your user base faster.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              {/* <li class="nav_list">
                <a href="#!" class="nav_link" style={{ color: (location.pathname === "/all-courses" || location.pathname === "/training") && "#fff" }}>
                  <span>About us</span>
                </a>
              </li> */}
              <li class="nav_list">
                <a href="https://learnlytica.com/blog" class="nav_link">
                  <span>Blog</span>
                </a>
              </li>

              <li class="nav_list">
                <a href="https://learnlytica.com/contactus" class="nav_link">
                  <span>Contact us</span>
                </a>
              </li>
            </ul>
            <div class="nav_action" >
              {
                'REACTAPP.TOKEN' in localStorage === false &&

                <>
                  <a href="/contactus" class="btn-primary" style={{ padding: "10px 18px" }}>Get in Touch</a>

                  <a href="/login" class="btn-primary" style={{ padding: "10px 18px" }}>Log in</a>
                </>
              }

              {'REACTAPP.TOKEN' in localStorage &&
                <>

                  {/* <button class="btn-primary" style={{ padding: "10px 18px" }} onClick={getLogoutTimes}>Logout</button> */}
                  {/* {
                    'user' in localStorage &&
                    <div>
                      {`Hi, ${user.appuser?.name}`} 
                    </div>
                  } */}

                  <Box>
                    <Tooltip title="Account settings">
                      <IconButton
                        onClick={handleClick}
                        size="medium"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <Avatar sx={{ width: 55, height: 55, mr: 1 }}> {
                          user?.consumerProfileDetails?.profilePic !== null ?
                            <img
                              style={{ width: 55, height: 55 }}
                              src={user?.consumerProfileDetails?.profilePic}
                              alt="profile_pic" />
                            :
                            firstLetter

                        }</Avatar>
                      </IconButton>
                    </Tooltip>
                  </Box>

                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    {/* Add other menu items or components as needed */}
                    <MenuItem onClick={handleClose}>
                      {
                        user?.consumerProfileDetails?.profilePic !== null ?
                          <img
                            style={{ width: 40, height: 40, borderRadius: "50%" }}
                            src={user?.consumerProfileDetails?.profilePic}
                            alt="profile_pic" />
                          :
                          <Avatar />
                      }
                      <div>
                        <div>{user.appuser?.name}</div>
                        <div>{user.appuser?.emailId}</div>
                      </div>
                    </MenuItem>
                    <Divider />

                    <MenuItem onClick={mycourses}>
                      <ListItemIcon>
                        {/* <PersonAdd fontSize="large" /> */}
                        {USER}
                      </ListItemIcon>
                      My courses
                    </MenuItem>
                    <MenuItem onClick={accountsettings}>
                      <ListItemIcon>
                        <Settings fontSize="small" />
                      </ListItemIcon>
                      Account Settings
                    </MenuItem>
                    <MenuItem onClick={purchasehistory} >
                      <ListItemIcon> {PURCHASE_HISTORY}</ListItemIcon>
                      {/* <Link to ="/purchase-history">Purchase History</Link> */}
                      Purchase History
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => LogOut()}>
                      <ListItemIcon>
                        <Logout fontSize="small" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>


                </>
              }

              <div class="nav_toggle">
                <ion-icon name="menu-outline"></ion-icon>
              </div>
            </div>
          </nav>



        </div>
      </header>



    </>
  )
}

export default ConsumerHeader