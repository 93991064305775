import React, { useState, useEffect } from 'react'
import Skeleton from 'react-loading-skeleton';
import SkeletonLoader from '../../../SkeletonLoader/SkeletonLoader';

const Expert = () => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {

    setTimeout(() => {
      setLoader(false);
    }, 2000)

  }, []);
  return (
    <div>
      {
        loader ?
          <SkeletonLoader skeletonCount={2} />
          :
          <h4 className='title-lg text-center'>You are not subscribed to this Module</h4>
      }
    </div>
  )
}

export default Expert