import { useState, useEffect, useContext } from "react";
import { BtnPrimary, TabBtn } from "../../Common/Buttons/Buttons";
import SearchBox from "../../Common/SearchBox/SearchBox"
import { ICN_ON_GOING } from "../../Common/Icon"
import { navigate, Router } from "../../Common/Router";
import AppContext from "../../../Store/AppContext";
import RestService from "../../../Services/api.service";
import LabList from "./LabList";
import "./Styles.css";
import CardHeader from "../../Common/CardHeader";
import MyLab from "./MyLab/MyLab";
import SkeletonLoader from "../../Common/SkeletonLoader/SkeletonLoader";
import SyncIcon from '@mui/icons-material/Sync';
import useToast from "../../../Store/ToastHook";
// const LabContainer = ({ location }) => {

//     return (<div className="catalog-container">
//         {categorieList.map(res => <div className="catalogBox">
//             <div className="catalogBox-info">
//                 <div>
//                     <div className="cat-title-md">{res}</div>
//                     {/* <div className="cat-title-sm">{res.desc}</div> */}
//                 </div>
//                 <div className="text-right">
//                     <BtnPrimary onClick={() => navigate('labstore/lab-list', { state: { title: 'LAB STORE', subTitle: "Catalog", subPath: "labstore" } })}>Show All 50 Labs</BtnPrimary>
//                 </div>
//             </div>
//         </div>)}

//     </div>)
// }

const LabStore = ({ location }) => {

    const [categorieList, setCategorieList] = useState([]);
    const [labsFromCategory, setLabsFromCategory] = useState([]);
    // const [labsFromCategoryCount, setLabsFromCategoryCount] = useState('');
    const [accountLabs, setAccountLabs] = useState([]);
    const { user, spinner, setCourse, ROLE } = useContext(AppContext);
    const [loader, setLoader] = useState(true);
    const Toast = useToast();
    const LabContainer = ({ location }) => {

        return (
            <>
                {loader ?
                    <SkeletonLoader skeletonCount={10} />
                    :
                    <div className={categorieList?.length === 0 ? "" : "catalog-container"} style={{ margin: categorieList?.length === 0 && "0 auto" }}>
                        {
                            categorieList?.length !== 0 ? categorieList.map(res => <div className="catalogBox">
                                <div className="catalogBox-info">
                                    {
                                        loader ?
                                            <SkeletonLoader skeletonCount={1} />
                                            :

                                            <div >
                                                <img src={res.thumbnail} width="100%" height="230px" alt="Lab" title={res.categoryName} style={{ borderTopLeftRadius: "15px", borderTopRightRadius: "15px" }} />
                                            </div>
                                    }
                                    <div className="catalog-content" >
                                        {
                                            <div className="cat-title-md">
                                                {loader ?
                                                    <SkeletonLoader skeletonCount={1} />
                                                    :
                                                    res.categoryName}
                                            </div>
                                        }
                                        {/* <div className="cat-title-sm">{res.desc}</div> */}
                                        {
                                            <div className="text-right">
                                                {loader ?
                                                    <SkeletonLoader skeletonCount={1} />
                                                    :
                                                    <BtnPrimary onClick={() => {
                                                        navigate('labstore/lab-list', {
                                                            state: {
                                                                title: 'LAB STORE', subTitle: "Catalog",
                                                                subPath: "labstore", categoryName: res.categoryName
                                                            }
                                                        });
                                                        // filterLabs(res.categoryName);
                                                    }} className="labbutton">Show All Lab(s)</BtnPrimary>
                                                }
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>)
                                :
                                <div className='title-md'>No Lab Found !!</div>
                        }

                    </div>
                }
            </>)
    }

    // get all labs categories
    const getAllLabCategories = () => {
        setLoader(true);
        try {

            // spinner.show();
            RestService.getAllLabCategories().then(
                response => {
                    setCategorieList(response.data);
                    setTimeout(() => {
                        setLoader(false);
                    }, 1000)

                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getAllLabCategories()", err)
        }
    }

    // get all labs from account
    const getAccountLabs = () => {
        try {

            // spinner.show();
            RestService.getAccountLabs().then(
                response => {
                    setAccountLabs(response.data.labDetails);
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getAccountLabs()", err)
        }
    }

    // search labstore
    const getAllLabCategoriesForSearching = (search) => {
        setLoader(true);
        try {
            // spinner.show();
            RestService.getAllLabCategoriesForSearching(search).then(
                response => {
                    setCategorieList(response.data);
                    setTimeout(() => {
                        setLoader(false);
                    }, 2000)

                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getAllLabCategoriesForSearching()", err)
        }
    }

    // SyncLab
    const syncLabStore = () => {
        try {

            // spinner.show();
            RestService.syncLabStore().then(
                response => {
                    // setAccountLabs(response.data.labDetails);
                    Toast.success({ message: `${response.data}` });
                    getAllLabCategories();
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on syncLabStore()", err)
        }
    }


    useEffect(() => {
        // getAllLabCategories();
        getAccountLabs();
    }, [])

    return (
        <div className="table-shadow p-3">
            <CardHeader {...{
                location,
                onChange: (e) => e.length === 0 && getAllLabCategories(),
                onEnter: (e) => e.length > 0 && getAllLabCategoriesForSearching(e),
            }} />
            <div className="flx tab-btn-group mb-3">
                <TabBtn active={location.state.subPath === "labstore"} onClick={() => navigate("/labstore", { state: { title: 'Lab Store', subTitle: "Catalog", subPath: "labstore" } })}>
                    <span className="mx-2" onClick={() => syncLabStore()}><SyncIcon /></span>
                    Catalog </TabBtn>
                <TabBtn active={location.state.subPath === "mylab"} onClick={() => navigate("/labstore/mylab", { state: { title: 'Lab Store', subTitle: "My Lab", subPath: "mylab" } })}>My Labs</TabBtn>
            </div>
            <Router>
                <LabContainer path="/" />
                {/* <LabList {...{ list: labsFromCategory}} path="lab-list" /> */}
                <LabList path="lab-list" />
                <MyLab  {...{ accountLabs }} path="mylab" />
            </Router>
        </div>)
}
export default LabStore
