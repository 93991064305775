import React, { useContext, useState, useEffect } from 'react'
import { Formik } from 'formik';
import { Checkbox, TextInput } from '../../Common/InputField/InputField';
import { Button } from '../../Common/Buttons/Buttons';
import { navigate } from '../../Common/Router';
import AppContext from '../../../Store/AppContext';
import useToast from "../../../Store/ToastHook";
import RestService from '../../../Services/api.service';
import AxiosService from '../../../Services/axios.service';
import { TokenService } from '../../../Services/storage.service';
import './auth.css'
import { ICN_TRAINSOFT, ICN_BACK, ICN_EDFORCE } from '../../Common/Icon';
import TRAINSOFT from './../../../Assets/Images/trainsoft.png'
import EDFORCE from './../../../Assets/Images/edforce.png';
import moment from 'moment';
import GLOBELCONSTANT from '../../../Constant/GlobleConstant';
import { Link, useNavigate } from '@reach/router';
import AssessmentContext from '../../../Store/AssessmentContext';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import hidePwdImg from "./hide-password.svg"
import showPwdImg from "./show-password.svg"
import LoadingSpinner from '../Training/LoadingSpinner';
import * as Yup from 'yup';
import { BsModal } from '../../Common/BsUtils';
import CryptoJS from 'crypto-js';
import { Spinner } from 'react-bootstrap';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const Login = () => {
    const [loading, setLoading] = useState(false);
    const { setUserValue, spinner } = useContext(AppContext)
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isRevealPwdnew, setIsRevealPwdnew] = useState(false);
    const [isRevealPwdretype, setIsRevealPwdretype] = useState(false);
    const [isRevealPwdold, setIsRevealPwdold] = useState(false);
    // const {setCategory} = useContext(AssessmentContext);
    const [tabPanel, setTabPanel] = useState("login");
    const Toast = useToast();
    const [agree, setAgree] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [show, setShow] = useState(false);
    const [isAllowed, setIsAllowed] = useState(true)
    const [isCapsLockOn, setIsCapsLockOn] = useState(false);
    const [password, setPassword] = useState('');
    const [reTypePassword, setReTypePassword] = useState('');
    const { setCategory } = useContext(AssessmentContext);

    const [validation, setValidation] = useState({
        isValid: false,
        errors: {}
    });

    const handlePasswordChange = (event) => {
        const newPassword = event.target.value;
        setPassword(newPassword);
        const validationResults = validatePassword(newPassword);
        setValidation(validationResults);
    };


    // Password Validation Function
    const validatePassword = (password) => {
        // Length Check
        const isLengthValid = password.length >= 12 && password.length <= 16;

        // Complexity Check
        const hasUppercase = /[A-Z]/.test(password);
        const hasLowercase = /[a-z]/.test(password);
        const hasDigit = /[0-9]/.test(password);
        const hasSpecialChar = /[!@#$%^&*]/.test(password);


        // Overall Validity
        const isValid =
            isLengthValid &&
            hasUppercase &&
            hasLowercase &&
            hasDigit &&
            hasSpecialChar;

        return {
            isValid,
            errors: {
                isLengthValid,
                hasUppercase,
                hasLowercase,
                hasDigit,
                hasSpecialChar,

            }
        };
    };

    const checkmark = <span style={{ color: 'green', marginRight: '8px' }}><CheckIcon fontSize='small' /></span>;
    const crossmark = <span style={{ color: 'red', marginRight: '8px' }}><CloseIcon fontSize='small' /></span>;

    const secretKey = process.env.REACT_APP_SECRET;
    const navigate = useNavigate();

    //validation for login
    const schema = Yup.object().shape({
        email: Yup.string().email()
            .required("Required!"),
        password: Yup.string()
            .required("Required!")

    });

    //validation for change password
    const schemaPassword = Yup.object().shape({
        email: Yup.string().email()
            .required("Required!"),
        oldPassword: Yup.string()
            .required("Required!")
    });

    const checkCapsLock = (event) => {
        if (event.getModifierState('CapsLock')) {
            setIsCapsLockOn(true);
        } else {
            setIsCapsLockOn(false);
        }
    };


    // on login the user
    const onLogin = (value) => {
        setLoading(true);
        const passwordEncrypt = CryptoJS.AES.encrypt(value.password, secretKey).toString();
        const payload = {
            "email": value.email,
            "password": passwordEncrypt
        }
        try {
            RestService.login(payload).then(
                response => {
                    if (response.data.firstLogin) {
                        setTabPanel("updatePassword");
                    }
                    else if (!response.data.takeOverRequired) {
                        let data = response.data
                        data.name = response.data.appuser.name
                        data.vaRole = response.data.role
                        data.role = response.data.departmentVA?.departmentRole
                        data.accessType = response.data.appuser.accessType
                        data.employeeId = response.data.appuser.accessType
                        setUserValue("LOGIN", data)
                        AxiosService.init('', response.data?.jwtToken);
                        TokenService.saveToken(response.data?.jwtToken);
                        if (data.role === GLOBELCONSTANT.ROLE.ASSESS_USER) {
                            navigate('/assessment', { state: { title: 'Dashboard' } })
                        } else {
                            let Role = data.role;
                            switch (Role) {
                                case GLOBELCONSTANT.ROLE.LEARNER:
                                    navigate('/home', { state: { title: 'Home' } });
                                    localStorage.setItem("timestamp", moment().format());
                                    break;
                                case GLOBELCONSTANT.ROLE.SUPERVISOR:
                                    navigate('/dashboard', { state: { title: 'Dashboard' } })
                                    localStorage.setItem("timestamp", moment().format());
                                    break;
                                case GLOBELCONSTANT.ROLE.CONSUMER_SUPERVISOR:
                                    navigate('/dashboard', { state: { title: 'Dashboard' } })
                                    localStorage.setItem("timestamp", moment().format());
                                    break;
                                case GLOBELCONSTANT.ROLE.INSTRUCTOR:
                                    navigate('/instdashboard', { state: { title: 'Instructor' } })
                                    localStorage.setItem("timestamp", moment().format());
                                    break;
                                case GLOBELCONSTANT.ROLE.TECH_SUPPORT:
                                    navigate('/ticket', { state: { title: 'Support' } })
                                    break;
                                case GLOBELCONSTANT.ROLE.OPERATION_SUPPORT:
                                    navigate('/ticket', { state: { title: 'Support' } })
                                    break;
                                case GLOBELCONSTANT.ROLE.SUPER_USER:
                                    navigate('/superuser', { state: { title: 'Dashboard' } })
                                    localStorage.setItem("timestamp", moment().format());
                                    break;
                                case GLOBELCONSTANT.ROLE.LAB_USER:
                                    navigate('/labusers', { state: { title: 'Lab User' } })
                                    localStorage.setItem("timestamp", moment().format());
                                    break;
                                default:
                                    navigate('/dashboard', { state: { title: 'Dashboard' } })
                            }

                            // data.role === GLOBELCONSTANT.ROLE.LEARNER ?  navigate('/home', { state: { title: 'Home'} }): data.role === GLOBELCONSTANT.ROLE.SUPERVISOR? navigate('/dashboard', { state: { title:'Dashboard'} }):
                            // data.role === GLOBELCONSTANT.ROLE.INSTRUCTOR? navigate('/instdashboard', { state: { title: 'Instructor'} }):null

                        }
                    }
                    else {
                        setShow(true);
                        AxiosService.init('', response.data?.jwtToken);
                        TokenService.saveToken(response.data?.jwtToken);

                    }
                    setLoading(false);
                },
                err => {

                    if (err.request.status === 0) {
                        // setShow(true);
                        // setError('Please Check Your Network Connection')
                        Toast.error({ message: 'Please Check Your Network Connection' })
                    }
                    else if (err.response.status === 401) {
                        // setShow(true);
                        // setError('Invalid User Name / Password!');
                        Toast.error({ message: 'Invalid Email / Password!' })
                    }
                    // else if (err.response.status === 500) {
                    //     // setShow(true);
                    //     // setError(`${err.response.data.message}`);
                    //     Toast.error({ message: `${err.response.data.message}` })
                    // }
                    else {
                        // setShow(true);
                        // setError('Something went wrong');
                        Toast.error({ message: 'Something went wrong' })
                    }

                    spinner.hide();
                    setLoading(false);
                }
            )
        } catch (err) {
            // Toast.error({ message: 'Invalid User Name / Password!' })
            console.error("Error occured on login page", err);
            setLoading(false);
        }
    }

    //login take over for loggedin user
    const loginTakeOver = () => {
        try {
            // const token = localStorage.getItem('REACTAPP.TOKEN');
            RestService.loginTakeOver().then(
                response => {
                    let data = response.data
                    data.name = response.data.appuser.name
                    data.vaRole = response.data.role
                    data.role = response.data.departmentVA?.departmentRole
                    data.accessType = response.data.appuser.accessType
                    data.employeeId = response.data.appuser.accessType
                    setUserValue("LOGIN", data)
                    setShow(false);
                    AxiosService.init('', response.data?.jwtToken);
                    TokenService.saveToken(response.data?.jwtToken);
                    if (data.role === GLOBELCONSTANT.ROLE.ASSESS_USER) {
                        navigate('/assessment', { state: { title: 'Dashboard' } })
                    } else {
                        let Role = data.role;
                        switch (Role) {
                            case GLOBELCONSTANT.ROLE.LEARNER:
                                navigate('/home', { state: { title: 'Home' } });
                                localStorage.setItem("timestamp", moment().format());
                                break;
                            case GLOBELCONSTANT.ROLE.SUPERVISOR:
                                navigate('/dashboard', { state: { title: 'Dashboard' } })
                                localStorage.setItem("timestamp", moment().format());
                                break;
                            case GLOBELCONSTANT.ROLE.CONSUMER_SUPERVISOR:
                                navigate('/dashboard', { state: { title: 'Dashboard' } })
                                localStorage.setItem("timestamp", moment().format());
                                break;
                            case GLOBELCONSTANT.ROLE.INSTRUCTOR:
                                navigate('/instdashboard', { state: { title: 'Instructor' } })
                                localStorage.setItem("timestamp", moment().format());
                                break;
                            case GLOBELCONSTANT.ROLE.TECH_SUPPORT:
                                navigate('/ticket', { state: { title: 'Support' } })
                                break;
                            case GLOBELCONSTANT.ROLE.OPERATION_SUPPORT:
                                navigate('/ticket', { state: { title: 'Support' } })
                                break;
                            case GLOBELCONSTANT.ROLE.SUPER_USER:
                                navigate('/superuser', { state: { title: 'Dashboard' } })
                                localStorage.setItem("timestamp", moment().format());
                                break;
                            case GLOBELCONSTANT.ROLE.LAB_USER:
                                navigate('/labusers', { state: { title: 'Lab User' } })
                                localStorage.setItem("timestamp", moment().format());
                                break;
                            default:
                                navigate('/dashboard', { state: { title: 'Dashboard' } })
                        }

                        // data.role === GLOBELCONSTANT.ROLE.LEARNER ?  navigate('/home', { state: { title: 'Home'} }): data.role === GLOBELCONSTANT.ROLE.SUPERVISOR? navigate('/dashboard', { state: { title:'Dashboard'} }):
                        // data.role === GLOBELCONSTANT.ROLE.INSTRUCTOR? navigate('/instdashboard', { state: { title: 'Instructor'} }):null

                    }

                },
                err => {

                    if (err.request.status === 0) {
                        Toast.error({ message: 'Please Check Your Network Connection' })
                    }
                    else if (err.response.status === 401) {
                        Toast.error({ message: 'Invalid Email / Password!' })
                    }
                    else if (err.response.status === 500) {
                        Toast.error({ message: `${err.response.data.message}` })
                    }
                    else {
                        Toast.error({ message: 'Something went wrong' })
                    }

                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            // Toast.error({ message: 'Invalid User Name / Password!' })
            console.error("Error occured on loginTakeOver page", err)
        }
    }

    //change password for first time logged in
    const updatePasswordOnFirstLogin = (value) => {
        setLoading(true);
        const passwordEncrypt = CryptoJS.AES.encrypt(value.oldPassword, secretKey).toString();
        const newPasswordEncrypt = CryptoJS.AES.encrypt(password, secretKey).toString();
        const reTypepasswordEncrypt = CryptoJS.AES.encrypt(reTypePassword, secretKey).toString();

        const payload = {
            "email": value.email,
            "newPassword": newPasswordEncrypt,
            "oldPassword": passwordEncrypt,
            "retyepNewPassword": reTypepasswordEncrypt
        }

        try {
            RestService.updatePasswordOnFirstLogin(payload).then(
                response => {
                    if (!response.data.takeOverRequired) {
                        let data = response.data
                        data.name = response.data.appuser.name
                        data.vaRole = response.data.role
                        data.role = response.data.departmentVA?.departmentRole
                        data.accessType = response.data.appuser.accessType
                        data.employeeId = response.data.appuser.accessType
                        setUserValue("LOGIN", data)
                        AxiosService.init('', response.data?.jwtToken);
                        TokenService.saveToken(response.data?.jwtToken);
                        if (data.role === GLOBELCONSTANT.ROLE.ASSESS_USER) {
                            navigate('/assessment', { state: { title: 'Dashboard' } })
                        } else {
                            let Role = data.role;
                            switch (Role) {
                                case GLOBELCONSTANT.ROLE.LEARNER:
                                    navigate('/home', { state: { title: 'Home' } });
                                    localStorage.setItem("timestamp", moment().format());
                                    break;
                                case GLOBELCONSTANT.ROLE.SUPERVISOR:
                                    navigate('/dashboard', { state: { title: 'Dashboard' } })
                                    localStorage.setItem("timestamp", moment().format());
                                    break;
                                case GLOBELCONSTANT.ROLE.CONSUMER_SUPERVISOR:
                                    navigate('/dashboard', { state: { title: 'Dashboard' } })
                                    localStorage.setItem("timestamp", moment().format());
                                    break;
                                case GLOBELCONSTANT.ROLE.INSTRUCTOR:
                                    navigate('/instdashboard', { state: { title: 'Instructor' } })
                                    localStorage.setItem("timestamp", moment().format());
                                    break;
                                case GLOBELCONSTANT.ROLE.TECH_SUPPORT:
                                    navigate('/ticket', { state: { title: 'Support' } })
                                    break;
                                case GLOBELCONSTANT.ROLE.OPERATION_SUPPORT:
                                    navigate('/ticket', { state: { title: 'Support' } })
                                    break;
                                case GLOBELCONSTANT.ROLE.SUPER_USER:
                                    navigate('/superuser', { state: { title: 'Dashboard' } })
                                    localStorage.setItem("timestamp", moment().format());
                                    break;
                                case GLOBELCONSTANT.ROLE.LAB_USER:
                                    navigate('/labusers', { state: { title: 'Lab User' } })
                                    localStorage.setItem("timestamp", moment().format());
                                    break;
                                default:
                                    navigate('/dashboard', { state: { title: 'Dashboard' } })
                            }

                            // data.role === GLOBELCONSTANT.ROLE.LEARNER ?  navigate('/home', { state: { title: 'Home'} }): data.role === GLOBELCONSTANT.ROLE.SUPERVISOR? navigate('/dashboard', { state: { title:'Dashboard'} }):
                            // data.role === GLOBELCONSTANT.ROLE.INSTRUCTOR? navigate('/instdashboard', { state: { title: 'Instructor'} }):null

                        }
                    }
                    else {
                        setShow(true);
                        AxiosService.init('', response.data?.jwtToken);
                        TokenService.saveToken(response.data?.jwtToken);

                    }
                    setLoading(false);
                },
                err => {

                    if (err.request.status === 0) {
                        // setShow(true);
                        // setError('Please Check Your Network Connection')
                        Toast.error({ message: 'Please Check Your Network Connection' })
                    }
                    else {
                        // setShow(true);
                        // setError('Invalid User Name / Password!');
                        Toast.error({ message: err?.response?.data?.message })
                    }
                    spinner.hide();
                    setLoading(false);
                }
            )
        } catch (err) {
            // Toast.error({ message: 'Invalid User Name / Password!' })
            console.error("Error occured on updatePasswordOnFirstLogin page", err);
            setLoading(false);
        }

    }

    // takeover modal
    const Modal = ({ handleClose, show, children }) => {
        const showHideClassName = show ? "modal d-block" : "modal d-none";

        return (
            <div class={showHideClassName}>
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">

                        <div class="modal-body">
                            {children}
                        </div>
                        <div class="modal-footer" style={{ justifyContent: "space-between" }}>
                            <button type="button" class="btn btn-danger" data-dismiss="modal" style={{ width: "180px", borderRadius: "15px" }} onClick={takeOverNo}>No</button>
                            <button type="button" class="btn btn-primary" style={{ width: "180px" }} onClick={loginTakeOver}>Yes</button>
                        </div>
                    </div>
                </div>
            </div>




        );
    };

    // forgot password
    const forgetPwd = (value) => {
        try {
            spinner.show();
            RestService.forgetPwd(value.email).then(
                response => {
                    Toast.success({ message: 'Forget password link is successfully send to your email' })
                    setTabPanel("mailSend")
                },
                err => {
                    Toast.error({ message: 'Email not exist!' })
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            Toast.error({ message: 'Email not exist!' })
            console.error("Error occured on login page", err)
        }
    }

    const takeOverNo = () => {
        setShow(false);
        localStorage.clear();
    }
    const handleGoback = () => {
        navigate("/");
    }

    return (
        <>
            <Modal show={show} handleClose={() => setShow(false)}  >
                <p className='title-sm'>Already logged in at another place. Want to log in here?</p>
            </Modal>
            <div className='login-navigation'>
                <div className="text-center mb-3" onClick={handleGoback} >

                    <div className='pt-3' style={{ cursor: "poniter" }} > {GLOBELCONSTANT.BRANDING.ICON} </div>

                </div>

            </div>
            <div className="loginScreen" style={{ height: "100vh" }} >

                <div class="d-flex p-5 align-items-center justify-content-center col-12 loginbackground" style={{
                    height
                        : "100vh"
                }} >


                    <div className="login-container">



                        {tabPanel === "updatePassword" ?

                            <>
                                <Formik
                                    initialValues={{
                                        "email": '',
                                        "oldPassword": ''
                                    }}
                                    validationSchema={schemaPassword}
                                    onSubmit={(values) => updatePasswordOnFirstLogin(values)}>
                                    {({ handleSubmit }) => (<>
                                        <form onSubmit={handleSubmit} style={{

                                            maxWidth: "400px",
                                            padding: "30px 0"
                                        }}>


                                            <p className=' text-center login-text mb-5'>You must change your password to continue.</p>
                                            <TextInput name="email" label="Email" disabled />
                                            <div className='pwd-container '>

                                                <TextInput name="oldPassword" type={isRevealPwdold ? "text" : "password"} label="Old Password" />
                                                <img
                                                    title={isRevealPwdold ? "Hide password" : "Show password"}
                                                    src={isRevealPwdold ? hidePwdImg : showPwdImg}
                                                    onClick={() => setIsRevealPwdold(prevState => !prevState)}
                                                />
                                            </div>


                                            <label className="label form-label">
                                                New Password
                                            </label>
                                            <div className="input-wrapper mt-2">
                                                <div className='pwd-container'>
                                                    <div className="input-field">
                                                        <input
                                                            className="form-control"
                                                            type={isRevealPwdnew ? "text" : "password"}
                                                            value={password}
                                                            onChange={handlePasswordChange}
                                                        />

                                                    </div>
                                                    <img
                                                        style={{ top: "10px" }}
                                                        title={isRevealPwdnew ? "Hide password" : "Show password"}
                                                        src={isRevealPwdnew ? hidePwdImg : showPwdImg}
                                                        onClick={() => setIsRevealPwdnew(prevState => !prevState)}
                                                    />
                                                </div>

                                                {password?.length !== 0 &&
                                                    < div >
                                                        <ul style={{ listStyle: "none", margin: "5px 0px" }}>
                                                            <li style={{ color: validation.errors.isLengthValid ? 'green' : 'red' }}>
                                                                {validation.errors.isLengthValid ? checkmark : crossmark}
                                                                Length: 12-16 characters
                                                            </li>
                                                            <li style={{ color: validation.errors.hasUppercase ? 'green' : 'red' }}>
                                                                {validation.errors.hasUppercase ? checkmark : crossmark}
                                                                Must include at least one uppercase letter
                                                            </li>
                                                            <li style={{ color: validation.errors.hasLowercase ? 'green' : 'red' }}>
                                                                {validation.errors.hasLowercase ? checkmark : crossmark}
                                                                Must include at least one lowercase letter
                                                            </li>
                                                            <li style={{ color: validation.errors.hasDigit ? 'green' : 'red' }}>
                                                                {validation.errors.hasDigit ? checkmark : crossmark}
                                                                Must include at least one digit
                                                            </li>
                                                            <li style={{ color: validation.errors.hasSpecialChar ? 'green' : 'red' }}>
                                                                {validation.errors.hasSpecialChar ? checkmark : crossmark}
                                                                Must include at least one special character (!@#$%^&*)
                                                            </li>
                                                        </ul>
                                                    </div>
                                                }
                                            </div>

                                            <label className="label form-label">
                                                Confirm Password
                                            </label>
                                            <div className="input-wrapper mt-2">
                                                <div className='pwd-container'>
                                                    <div className="input-field">
                                                        <input
                                                            className="form-control"
                                                            type={isRevealPwdretype ? "text" : "password"}
                                                            value={reTypePassword}
                                                            onChange={(e) => setReTypePassword(e.target.value)}
                                                        />

                                                    </div>
                                                    <img
                                                        style={{ top: "10px" }}
                                                        title={isRevealPwdretype ? "Hide password" : "Show password"}
                                                        src={isRevealPwdretype ? hidePwdImg : showPwdImg}
                                                        onClick={() => setIsRevealPwdretype(prevState => !prevState)}
                                                    />
                                                </div>
                                                {
                                                    password?.length !== 0 && reTypePassword?.length !== 0 &&

                                                    <ul style={{ listStyle: "none", margin: "5px 0px" }}>
                                                        <li style={{ color: password === reTypePassword ? 'green' : 'red' }}>
                                                            {password === reTypePassword ? checkmark : crossmark}
                                                            New Password and Confirm Password must match.
                                                        </li>
                                                    </ul>
                                                }
                                            </div>




                                            <div className="text-right">
                                                <button className="btn btn-primary mt-3" disabled={!validation.isValid || password !== reTypePassword} type="submit"
                                                    style={{ width: "100%", cursor: (!validation.isValid || password !== reTypePassword) ? "not-allowed" : "pointer" }}
                                                >
                                                    {loading ? (
                                                        <>
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="md"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className="mr-2 loader-button" // Add margin to separate spinner from text
                                                                style={{ border: '5px dotted #fff', width: "20px", height: "20px" }}
                                                            />

                                                        </>
                                                    ) : 'Confirm Password Change'}</button>

                                            </div>





                                        </form>
                                    </>)}
                                </Formik>
                            </>
                            :
                            tabPanel === 'login' &&
                            <>
                                <Formik
                                    initialValues={{
                                        "email": '',
                                        "password": '',
                                        "term": ''
                                    }}
                                    validationSchema={schema}
                                    onSubmit={(values) => onLogin(values)}>
                                    {({ handleSubmit }) => (<>
                                        <form onSubmit={handleSubmit} style={{
                                            margin: "0 auto",
                                            maxWidth: "430px",
                                            padding: "30px 0"
                                        }}>
                                            <div className="text-center mb-3">

                                                <div className='pt-3'> {GLOBELCONSTANT.BRANDING.ICON} </div>


                                            </div>
                                            <h2 className=' login-header'>Log in to your account</h2>
                                            <p className=' text-center login-text'>Welcome back! Please enter your details.</p>
                                            <TextInput name="email" label="Email" />
                                            <div className='pwd-container '>
                                                <TextInput onKeyUp={checkCapsLock} name="password" type={isRevealPwd ? "text" : "password"} label="Password" />

                                                <img
                                                    title={isRevealPwd ? "Hide password" : "Show password"}
                                                    src={isRevealPwd ? hidePwdImg : showPwdImg}
                                                    onClick={() => setIsRevealPwd(prevState => !prevState)}
                                                />
                                            </div>
                                            {isCapsLockOn && (
                                                <p style={{ color: "red", marginTop: "-20px", marginBottom: "10px", fontSize: "12px" }}>Caps Lock is on</p>
                                            )}
                                            <div className='d-flex justify-content-between' style={{ width: "100%" }}>

                                                <Checkbox className="mb-3 tnc-label" name="term" label="I accept the terms of service and privacy policy" id="agree" onClick={(e) => setAgree(e.target.checked)} />
                                                <Link to="/resetlink">Forgot Password</Link>
                                            </div>
                                            <div className="text-right">
                                                <button className="btn btn-primary" disabled={!agree} type="submit" style={{ width: "100%", cursor: !agree ? "not-allowed" : "pointer" }}


                                                >
                                                    {loading ? (
                                                        <>
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="md"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className="mr-2 loader-button" // Add margin to separate spinner from text
                                                                style={{ border: '5px dotted #fff', width: "20px", height: "20px" }}
                                                            />

                                                        </>
                                                    ) : 'login'}</button>

                                            </div>

                                            <hr />

                                            <div className='card shadow-sm bg-white rounded disablediv' >
                                                <div className='title-sm btn text-center'><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRu1PJmT_THldF0n5APcmt9p10utgu6KSw4cH2fQ5Xhpw&s' alt="google" height="20" width
                                                    ="20" /><span className="mx-2">Continue with Google</span> </div>
                                            </div>
                                            <div className='card shadow-sm bg-white rounded mt-2 disablediv'>
                                                <div className='title-sm btn text-center'><img src='https://upload.wikimedia.org/wikipedia/commons/thumb/4/44/Microsoft_logo.svg/2048px-Microsoft_logo.svg.png' alt="Microsoft" height="20" width
                                                    ="20" /><span className="mx-2" >Continue with Microsoft</span> </div>
                                            </div>
                                            {/* <div className="text-right mt-2 f13 link" onClick={() => setTabPanel("forget")}>
                                    Forgot Password
                                </div> */}
                                            {/* <div className="text-center mt-3 f13">
                                            Not registered? Contact us
                                        </div> */}
                                            <div>
                                                {/* <button className="btn btn-primary btn-am btn-block" type="submit" onClick={()=> navigate('/dashboard',{ replace: true })}>Login</button> */}
                                            </div>
                                        </form>
                                    </>)}
                                </Formik>

                            </>
                        }



                    </div>
                </div>
                {/* <BsModal {...{ show, setShow, headerTitle: error, size: "md" }} /> */}

            </div >
        </>

    )
}

export default Login