import { useState, useEffect, useContext } from 'react'
import { MultiSelect } from "react-multi-select-component";
import RestService from '../../../Services/api.service';
import AppContext from '../../../Store/AppContext';
import useToast from '../../../Store/ToastHook';
import parse from 'html-react-parser';

const AssignProject = ({ projectDescription, projectId}) => {
  const [trainingList, setTrainingList] = useState({});
  const [options, setOptions] = useState([]);
  const [selectTraining, setSelectTraining] = useState('');
  const [selectDate, setSelectDate] = useState('');
  const [selectGroupList, setSelectGroupList] = useState([]);
  const { spinner } = useContext(AppContext);
  const Toast = useToast();
  const currentDate = new Date().toISOString().split('T')[0];

  // get all training
  const getSupervisorTrainings = () => {
    try {
      // spinner.show();
      RestService.getSupervisorTrainings().then(
        response => {
          if (response.status === 200) {
            setTrainingList(response.data);
            // setTrainingListLoader(false);
          }
          else {
            setTrainingList({});
          }

        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getSupervisorTrainings()", err)
    }
  }

  //projects group
  const projectGroupsDropdown = (date) => {
    try {
      RestService.projectGroupsDropdown(projectId, date, selectTraining).then(
        response => {
          if (response.status === 200) {
            let newOptions = response.data.map(r => {
              return ({ label: r.groupName, value: r.groupSid })

            });
            setOptions(newOptions);
          }
        },
        err => {
          // spinner.hide();
          console.error(err);
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on projectGroupsDropdown()", err)
    }
  }

  //assign Project
  const assignProjectsToGroups = () => {
    try {
      let groupSids = selectGroupList.map(r => r.value);
      let payload = {
        "groupSids": groupSids,
        "projectId": projectId,
        "startDate": selectDate,
        "trainingSid": selectTraining,
        "all": false
      }
      // if (options.length === groupSids.length) {
      //   payload["all"] = true;
      // }
      // else {
      //   payload["all"] = false;
      // }
      RestService.assignProjectsToGroups(payload).then(
        response => {
          if (response.status === 200) {
            Toast.success({ message: `Project Assigned Successfully`, time: 2000 });
            setSelectDate('');
          }
        },
        err => {
          // spinner.hide();
          console.error(err);
          Toast.error({ message: `Try Again`, time: 2000 });
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on assignProjectsToGroups()", err)
    }
  }

  useEffect(() => {
    getSupervisorTrainings();
  }, []);
  

  return (
    <>

      <div className='row'>
        <div className='col-md-4'>
          <label className="label form-label ">Training</label>
          <div class="input-wrapper"><div class="input-field ">

            {/* <input name="name" label="Batch Name" class="form-control form-control-sm" value=""/> */}
            <select name="cars" id="cars" class="form-control form-control-sm"
              onChange={(e) => {
                setSelectTraining(e.target.value.split(',')[0])
              }}>
              <option hidden>Select Training</option>
              {
                Object.keys(trainingList).length > 0 ? Object.keys(trainingList).map(function (key, index) {
                  return (
                    <option value={`${key},${trainingList[key]}`}>{trainingList[key]}</option>
                  )
                })
                :
                <option>Training Not Found</option>
              }
            </select>

          </div></div>

        </div>
        {
          selectTraining.length > 0 ?

            <div className='col-md-4'>
              <label className="label form-label ">Start Date</label>
              <div class="input-wrapper"><div class="input-field ">
                <input name="name" type='date' label="Batch Name" class="form-control form-control-sm" value={selectDate} min={currentDate}
                  onChange={(e) => { setSelectDate(e.target.value); projectGroupsDropdown(e.target.value) }} />

              </div></div>

            </div>
            : ''
        }
        {
          selectDate.length > 0 ?
            <div className='col-md-4'>
              <label className="label form-label ">Select Group</label>
              {/* <label>
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
                Select All
              </label> */}
              {/* <Multiselect
                options={groupList}
                selectedValues={selectGroupList}
                onSelect={handleSelect}
                onRemove={handleSelect}
                displayValue="groupName"
              /> */}
              <MultiSelect
                options={options}
                value={selectGroupList}
                onChange={setSelectGroupList}
                labelledBy="Select"
              />
            </div>
            :
            ''
        }
      </div>
      <div className='row'>
        <div className='col-md-12 d-flex justify-content-end mt-1'>
          <button className='btn btn-primary px-3'
            onClick={() => assignProjectsToGroups()}
            disabled={selectTraining.length === 0 || selectDate.length === 0 || selectGroupList.length === 0}>
            Assign
          </button>
        </div>
      </div>

      <div class="card mt-3">
        <div class="card-header title-sm">
          Project Description
        </div>
        <div class="card-body">
          {/* <h5 class="card-title">Special title treatment</h5> */}
          {/* <p class="card-text">In this hands-on lab, we will use the Azure Portal to create and use a virtual machine.  We will log in to the Azure Portal and create a virtual machine, a virtual network, and a network interface card for the virtual machine. Then, we will connect to the virtual machine via RDP. Finally, we will use the Azure Portal to turn the virtual machine off.  After completing this lab, you will have gained the experience required to create and use your first virtual machine using the Azure Portal.</p> */}
          <div class="card-text">{parse(projectDescription)}</div>
        </div>
      </div>

    </>
  )
}

export default AssignProject