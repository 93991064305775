import { useEffect } from 'react';

const DisableBeforeUnloadWarning = () => {
  useEffect(() => {
    // Save the current onbeforeunload function to restore later if needed
    const originalBeforeUnload = window.onbeforeunload;

    // Disable the beforeunload warning
    window.onbeforeunload = null;

    // Optional: Restore the original onbeforeunload function when the component unmounts
    return () => {
      window.onbeforeunload = originalBeforeUnload;
    };
  }, []);

  return null; // This component does not render anything
};


export default DisableBeforeUnloadWarning;