import { useContext, useState, useEffect, useRef, useCallback } from "react";
import '../Batches/batches.css'
import './training.css'
import { useNavigate } from "@reach/router";
import AppContext from "../../../Store/AppContext";
import RestService from "../../../Services/api.service";
import DropdownItem from "../../Common/DropdownItem/DropdownItem";
import ReactPlayer from 'react-player';
import "./TrainingMediaPlayer/MediaPlayer.css";
import DynamicTable from "../../Common/DynamicTable/DynamicTable";
import Feedback from "../../Common/Feedback/Feedback";
import { Link } from "@material-ui/core";
import Qa from "../../Common/QA/Qa";
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import DuoIcon from '@mui/icons-material/Duo';
import ProgressBar from "../../Common/ProgressBar/ProgressBar";
import ScienceIcon from '@mui/icons-material/Science';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CodeIcon from '@mui/icons-material/Code';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import useToast from "../../../Store/ToastHook";
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import LoadingSpinner from "./LoadingSpinner";
import WhiteBoard from "../ClassLab/WhiteBoard/WhiteBoard";
import { BsModal } from "../../Common/BsUtils";
import ClassNotes from "../ClassLab/ClassNotes/ClassNotes";
import TrainingObjective from "./TrainingObjective";
import axios from 'axios';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button } from "../../Common/Buttons/Buttons";
import { ButtonToolbar, DropdownButton, Dropdown } from "react-bootstrap";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LinkIcon from '@mui/icons-material/Link';
import { Tooltip } from "react-tooltip";
import SkeletonLoader from "../../Common/SkeletonLoader/SkeletonLoader";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import HoverVideoPlayer from 'react-hover-video-player';
import Sidebar from "../Assessment/Sidebar/Sidebar";
import GLOBELCONSTANT from "../../../Constant/GlobleConstant";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DownloadIcon from '@mui/icons-material/Download';
import moment from 'moment';
import { ICN_ASSESSMT, ICN_MCQ, ICN_DIM_LIGHT, ICN_EDIT, ICN_EXPAND_NEW, ICN_MORE_DETAILS, ICN_NOTE_EDIT, ICN_OPEN_SANDBOX, ICN_START_ASSESS, ICN_STRAT_SESSION, ICN_DOWNLOAD_BUTTON, PASS_TASK, FAIL_TASK, ICN_CALENDER, CALENDAR_ICON, ICN_LIVE } from "../../Common/Icon";
import ReactMarkdown from "react-markdown";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import AssessmentContext from "../../../Store/AssessmentContext";
import LockIcon from '@mui/icons-material/Lock';
import { Spinner } from 'react-bootstrap';
import { VariableSizeList as List } from 'react-window';

const TrainingDetails = ({ location }) => {
    const timeZoneMapping = GLOBELCONSTANT.timeZoneMapping;
    const [loading, setLoading] = useState(false);
    const [labLoading, setLabLoading] = useState(false);
    const [codingAssessmentLoading, setCodingAssessmentLoading] = useState(false);
    const [reportsloading, setReportsLoading] = useState(false);
    const [attendanceloading, setAttendanceLoading] = useState(false);
    const [trainingDetailsList, setTrainingDetailsList] = useState([]);
    const [trainingObjective, setTrainingObjective] = useState({});
    // const [sessionDescriptions, setSessionDescriptions] = useState([]);
    // const [sessions, setSessions] = useState({});
    const { user, ROLE, spinner } = useContext(AppContext);
    const { setTrainingId } = useContext(AssessmentContext);
    const [vdlink, setVdlink] = useState("");
    const [assessmentUrl, setAssessmentUrl] = useState("");
    const [urlBasedAssessment, setUrlBasedAssessment] = useState(false);
    const Toast = useToast();
    const [feed, setFeed] = useState(false);
    const [modal, setModal] = useState(false);
    const [showcoursename, setShowcoursename] = useState('');
    const [zoomInfo, setZoomInfo] = useState({});
    const [contentLength, setContentLength] = useState(0);
    const [sid, setSid] = useState('');
    const [contentSid, setContentSid] = useState('');
    const [markCompleted, setMarkAsCompleted] = useState(0);
    const [trainingBySid, setTrainingBySid] = useState({});
    const [labId, setLabId] = useState('');
    const [type, setType] = useState('OBJECTIVE');
    const [labDescription, setLabDescription] = useState('');
    const [labOverview, setLabOverview] = useState('');
    const [labSolution, setLabSolution] = useState('');
    const [labDuration, setLabDuration] = useState('');
    const [labTimeDetails, setLabTimeDetails] = useState('');
    const [codingQuestionId, setCodingQuestionId] = useState('');
    const [codingQuestiondesc, setCodingQuestiondesc] = useState('');
    const [played, setPlayed] = useState(0);
    const [duration, setDuration] = useState(0);
    const [call, setCall] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [loader, setLoader] = useState(true);
    const [loaderTraining, setLoaderTraining] = useState(false);
    const [completeContent, setCompleteContent] = useState();
    const [codingAssessmentTimeOverInSeconds, setCodingAssessmentTimeOverInSeconds] = useState(0);
    const [instructorEvaluationAll, setInstructorEvaluationAll] = useState(null);
    // const [instructorScreenRecording, setInstructorScreenRecording] = useState(null);
    const [currentTimeInMinutes, setCurrentTimesInMinutes] = useState(0);
    const [totalLabTimeInMinutes, setTotalLabTimeInMinutes] = useState(0);
    const [codingAssessmentCheatingDetails, setCodingAssessmentCheatingDetails] = useState(null);
    const [codingAssessmentStartTime, setCodingAssessmentStartTime] = useState('');
    const [codingAssessmentDate, setCodingAssessmentDate] = useState('');
    const [codingAssessmentAllover, setCodingAssessmentAllover] = useState('');
    const [assessmentTimeline, setAssessmentTimeline] = useState(null);
    const [message, setMessage] = useState('');
    // const intervalRef = useRef(null);
    // const [seconds, setSeconds] = useState(0);
    // const [previousSid, setPreviousSid] = useState('');
    const navigate = useNavigate();
    // let trainingSid = location.state.sid;
    let [trainingSid, setTrainingSid] = useState(

        location?.state?.sid ? location.state.sid
            :
            // location.state.upcomingContent !== null && location.state.upcomingContent.trainingSid !== null ?
            //     location.state.upcomingContent.trainingSid
            //     :
            localStorage.getItem("trainingSid")
    );
    let username = JSON.parse(localStorage.getItem('user'));
    localStorage.setItem("trainingSid", trainingSid);
    // const userSid = JSON.parse(localStorage.getItem('user'))
    const [show, setShow] = useState(false);
    const [showAsset, setShowAsset] = useState(false);
    const [showViewAsset, setShowViewAsset] = useState(false);
    // const [showRecording, setShowRecording] = useState(false);
    const [sectionObjective, setSectionObjective] = useState({});
    const [evaluatedLab, setEvaluatedLab] = useState();
    const [parentLab, setParentLab] = useState();
    const [pageNo, setPageNo] = useState(0);
    const [pages, setPages] = useState();
    // const [labRecordingLink, setLabRecordingLink] = useState();
    const [labRecordingFileName, setLabRecordingFileName] = useState();
    const [labAssessment, setLabAssessment] = useState(null);
    const [viewAsset, setViewAsset] = useState([]);
    const [assetResource, setAssetResource] = useState();
    const [assetName, setAssetName] = useState('');
    const [uploadasset, setUploadasset] = useState('LINK');
    const [assetType, setAssetType] = useState('');
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [durationInDays, setDurationInDays] = useState(0);
    const [category, setCategory] = useState('');
    const [projectMarks, setProjectMarks] = useState(0);
    const [projectId, setProjectId] = useState('');
    const [projectSubmissionResponse, setProjectSubmissionResponse] = useState(null);
    // const [projectCardDetails, setProjectCardDetails] = useState(null);
    // const [projectGroupDetails, setProjectGroupDetails] = useState([]);
    const [projectScoreResponse, setProjectScoreResponse] = useState(null);
    const [groupProjectDetails, setGroupProjectDetails] = useState(null);
    const [codingQuestionScoreDetails, setCodingQuestionScoreDetails] = useState(null);
    const [codingAssessmentDetails, setCodingAssessmentDetails] = useState(null);
    const [userCodingAssessmentSubmissionDetails, setUserCodingAssessmentSubmissionDetails] = useState(null);
    const [startCodingAssessment, setStartCodingAssessment] = useState(false);
    const [trainingSessionList, setTrainingSessionList] = useState();
    const [presentPercentage, setPresentPercentage] = useState('75');
    const [meetingRecordingLink, setMeetingRecordingLink] = useState('');
    const [cloudLabUserUniqueId, setCloudLabUserUniqueId] = useState(null);
    const [buttonText, setButtonText] = useState(null);
    const [cloudLab, setCloudLab] = useState(false);
    const [devLab, setDevLab] = useState(false);
    const [cloudLabEvaluationLab, setCloudLabEvaluationLab] = useState(null);
    const [sessionDate, setSessionDate] = useState(null);
    const [sessionStartTime, setSessionStartTime] = useState(null);
    const [sessionEndTime, setSessionEndTime] = useState(null);
    const [currentMinutesTrainingSession, setCurrentMinutesTrainingSession] = useState(0);
    const [startMinutesTrainingSession, setStartMinutesTrainingSession] = useState(0);
    const [endMinutesTrainingSession, setEndMinutesTrainingSession] = useState(0);

    const [mcqSubmissionDetails, setMcqSubmissionDetails] = useState(null);
    const [showMCQAssessmentResult, setShowMCQAssessmentResult] = useState();
    const [showCodingAssessmentResult, setShowCodingAssessmentResult] = useState();
    const [reminderAdded, setReminderAdded] = useState({});
    const [courseSid, setCourseSid] = useState('');
    const [submissionDetailsForTrainer, setSubmissionDetailsForTrainer] = useState(null);
    const [unlocked, setUnlocked] = useState();
    const [hasAsset, setHasAsset] = useState();
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [external, setExternal] = useState();

    const currentDate = moment().format("YYYY-MM-DD");
    const token = localStorage.getItem('REACTAPP.TOKEN');

    const [alignment, setAlignment] = useState('FINAL');
    // const handle = useFullScreenHandle();

    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const [isCollapsed, setIsCollapsed] = useState(true);

    const toggleCodeVisibility = () => {
        setIsCollapsed(!isCollapsed);
    };

    const [activeTab, setActiveTab] = useState('marzen'); // Set the default active tab

    const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };
    // let yellow = '#ffc800';
    const [bgColor, setBgColor] = useState(false);
    const changeColor = () => {
        let color = 'rgba(0, 0, 0, 0.7)';
        setBgColor(!bgColor);
    }

    const [isExpanded, setIsExpanded] = useState(false);

    // const handleButtonClick = () => {
    //     // setIsExpanded((prev) => !prev);
    //     handle.enter();
    // };

    const styles = {
        // background: bgColor ? 'rgba(0, 0, 0, 0.7)' : '',

        // borderColor: bgColor ? "rgba(0,0,0,.6)":'',


        top: bgColor ? "1%" : '',
        bottom: bgColor ? "0" : '',

        /* for IE */
        boxShadow: bgColor ? " 0 0 0 1000px rgba(0,0,0,.7)" : '',
        /* for real browsers */
        boxShadow: bgColor ? "0 0 0 100vmax rgba(0,0,0,.7)" : '',
        zIndex: bgColor ? "9999" : ''

    };
    const stylescontent = {
        opacity: bgColor ? ".5" : ''
    }

    const commonClassName = 'player-wrapper';

    // Determine the specific class based on the user's role
    const specificClassName =
        user.role === ROLE.CONSUMER_LEARNER ? 'showcontentconsumer' : 'showcontentdiv';


    const LockedContent = () => {
        return (
            <div className="showcontentdiv row mx-0" style={{ display: "flex", flexDirection: "column" }} >

                <div className="mt-auto training-session-parrent text-center p-5 ">
                    <div className="training-session-header">

                        <span><LockIcon /></span>

                        You can Access this content from

                        <div>{trainingObjective?.trainingStartDate}</div>
                    </div>
                </div>
            </div>
        )
    }

    //error component
    const ErrorComponent = () => {
        return (
            <div class="row justify-content-center">
                <div class="col-md-12 col-sm-12">
                    <div class="card shadow-lg border-0 rounded-lg mt-5 mx-auto" style={{ width: "30rem" }}>
                        <h3 class="card-header display-1 text-muted text-center">
                            404
                        </h3>

                        <span class="card-subtitle mb-2 text-muted text-center">
                            Something went wrong
                        </span>

                        <div class="card-body mx-auto">
                            <button class="btn btn-sm btn-info text-white" onClick={() => navigate("/training", { state: { title: "Training" } })}>
                                Back To Training
                            </button>
                            {/* <a type="button" href="#"
                                class="btn btn-sm btn-info text-white"> Back To Training </a> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const OpenLinkInNewTab = ({ to, state, style, children }) => {
        const handleClick = (event) => {
            // Prevent the default navigation behavior
            event.preventDefault();
            const zoom = JSON.stringify(state);
            localStorage.setItem("zoom", zoom);

            // Open the link in a new tab
            const url = window.location.origin + to; // Construct the URL if it's a relative path
            window.open(url, '_blank', 'noopener,noreferrer');
        };

        return (
            <a href={to} onClick={handleClick} style={style}>
                {children}
            </a>
        );
    };

    const VideoMediaPlayer = (vdlink) => {
        return (
            <>
                <div className={`${commonClassName} ${specificClassName}`} style={styles}  >
                    <ReactPlayer
                        // style={{ background: user.role === ROLE.CONSUMER_LEARNER ? "red" : "" }}
                        className='react-player'
                        url={vdlink}
                        width='100%'
                        height="100%"
                        config={{
                            file: {
                                attributes: {
                                    controlsList: 'nodownload'  //<- this is the important bit
                                }
                            }
                        }}
                        playing={true}
                        // loop={true}
                        muted={true}
                        controls
                        light

                        onProgress={(progress) => {
                            if (Math.ceil(progress.playedSeconds) >= Math.ceil(0.8 * duration)) {
                                setPlayed(progress.playedSeconds);
                            }
                        }}
                        onDuration={(duration) => {
                            setDuration(duration);
                        }}

                    />
                </div>
            </>
        )
    }

    const RecordingPlayer = (vdlink) => {
        return (

            <div className='player-wrapper ' >
                <ReactPlayer
                    className='react-player '
                    url={vdlink}
                    width='100%'
                    height="90%"
                    config={{
                        file: {
                            attributes: {
                                controlsList: 'nodownload'  //<- this is the important bit
                            }
                        }
                    }}
                    playing={true}
                    // loop={true}
                    muted={true}
                    controls={true}
                />
            </div>

        )
    }

    const compareTimes = (startDate, endDate, startTime, endTime, timeZone) => {
        const format = 'YYYY-MM-DD HH:mm';
        const mappedOffset = timeZoneMapping[timeZone] || '+00:00';

        const dateTimeString1 = `${startDate} ${startTime}${mappedOffset}`;
        const dateTimeString2 = `${endDate} ${endTime}${mappedOffset}`;


        const dateTime1 = moment(dateTimeString1, format);
        const dateTime2 = moment(dateTimeString2, format);

        const now = moment().utcOffset(mappedOffset);
        if (now.isBefore(dateTime1)) {
            setMessage('NOT_STARTED'); //currentDate < dateTime1
        }
        else if (now.isAfter(dateTime2)) {
            setMessage('EXPIRED'); //currentDate > dateTime2
        }
        else {
            setMessage('NOT_EXPIRED');
        }
    };

    function Show(url) {
        setVdlink(url);
        setCall(true);
    }
    // function showFeedBack(val) {
    //     setFeed(val);
    // }
    function modalF(val) {
        setModal(val)
    }
    function storeLabId(val) {
        setLabId(val)
    }
    function storeType(val) {
        setType(val)
    }


    const Modal = ({ handleClose, show, children }) => {
        const showHideClassName = show ? "modal d-block" : "modal d-none";

        return (

            <div className={showHideClassName} >

                <div className="modal-container modal-xl">
                    <div className="circle-md"> <div className="modal-close" onClick={handleClose}>
                        X
                    </div></div>
                    {children}

                </div>
            </div>
        );
    };

    // FullScreen Lab 
    const handleFullscreenChange = () => {
        const isFullscreenNow =
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement;
        setIsFullscreen(isFullscreenNow);
    };

    const toggleFullscreen = useCallback(() => {
        const element = document.getElementById('container');

        if (isFullscreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
        } else {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
        }
    }, [isFullscreen]);


    const [configuration, setConfiguration] = useState({
        columns: {

            "contentName": {
                // "title": "Content Name",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
                render: (data) =>
                    <>


                        <Link onClick={() => {
                            if (data.type === "PROJECT") {
                                setContentSid(data.sectionSid);

                                if (user.role === ROLE.INSTRUCTOR || user.role === ROLE.SUPERVISOR) {
                                    setProjectId(data.projectId);
                                    // instructorAndSupervisorView(data.projectId, data.sectionSid, trainingSid);

                                }
                                else if (user.role === ROLE.LEARNER) {
                                    if (data.projectContent !== null) {
                                        setProjectName(data.projectContent.projectName);
                                        setProjectDescription(data.projectContent.projectDescription);
                                        setDurationInDays(data.projectContent.durationInDays);
                                        setCategory(data.projectContent.category);
                                        setProjectMarks(data.projectContent.marks);
                                        setProjectId(data.projectId);
                                    }
                                    if (data.projectSubmissionResponse !== null) {
                                        setProjectSubmissionResponse(data.projectSubmissionResponse);
                                    }
                                    if (data.projectScoreResponse !== null) {
                                        setProjectScoreResponse(data.projectScoreResponse);
                                    }
                                }

                            }

                            if (data.unlocked) {
                                setUnlocked(data.unlocked);
                                // setHasAsset(data.hasAsset);
                                setContentSid(data.sectionSid);
                                setShowcoursename(data.contentName);

                                setShowCodingAssessmentResult(data.showCodingAssessmentResult);
                                setShowMCQAssessmentResult(data.showMCQAssessmentResult);
                                if (data.showMCQAssessmentResult) {
                                    localStorage.setItem("showMCQAssessmentResult", "resultVisible");
                                }
                                else {
                                    localStorage.setItem("showMCQAssessmentResult", "resultNotVisible");
                                }

                                setReminderAdded(reminderAdded => ({
                                    ...reminderAdded,
                                    ...{
                                        "sid": data.sid,
                                        "value": data.reminderAdded
                                    }
                                }));
                                if (data.urlBasedAssessment) {
                                    const token = username?.assessmentLoginBody;
                                    //   const tokenStringify = JSON.stringify(token);
                                    const newUrl = `${data.assessmentUrl}?newUser=${token}`
                                    setUrlBasedAssessment(data.urlBasedAssessment);
                                    setAssessmentUrl(newUrl);
                                }
                                else {
                                    setUrlBasedAssessment(data.urlBasedAssessment);
                                    setAssessmentUrl("");
                                }
                                if (data.contentLink) {
                                    Show(data.contentLink);
                                }
                                if (data.sid !== null) {
                                    setSid(data.sid);
                                    localStorage.setItem("sid", data.sid);
                                    localStorage.setItem("sectionSid", data.sectionSid);
                                }

                                if (data.labId !== null) {

                                    if (data.labContent.evaluatedLab) {
                                        //current time
                                        const currentDate = new Date();
                                        const currentHour = currentDate.getHours();
                                        const currentMinutes = currentDate.getMinutes();
                                        const totalMinutes = (currentHour * 60) + currentMinutes;
                                        setCurrentTimesInMinutes(totalMinutes);
                                        //Calculating end time for Lab

                                        const starttime = data.labContent.labTimeDetails ? Number(data.labContent.labTimeDetails.split(':')[0]) * 60 + Number(data.labContent.labTimeDetails.split(':')[1]) : 0

                                        setLabTimeDetails(starttime)

                                        const totalLabTime = Number(data.durationInMinutes.split('.')[0]) + starttime

                                        setTotalLabTimeInMinutes(totalLabTime);
                                        const labDuration = totalLabTime - totalMinutes;
                                        setLabDuration(labDuration);
                                        setCloudLabUserUniqueId(data.labContent.cloudLabUserUniqueId);
                                        setCloudLabEvaluationLab(data.labContent.cloudLabEvaluationLab)
                                    }
                                    else if (data.labContent.cloudLabUserUniqueId !== null) {
                                        setCloudLabUserUniqueId(data.labContent.cloudLabUserUniqueId);
                                    }
                                    else {
                                        setLabDuration(Number(data.durationInMinutes.split('.')[0]));
                                        setCloudLabUserUniqueId(null);
                                        setCloudLabEvaluationLab(null);
                                    }

                                    if (data.labContent.buttonText !== null || data.labContent.buttonText?.length > 0) {
                                        setButtonText(data.labContent.buttonText);
                                    }
                                    else {
                                        setButtonText(null);
                                    }
                                    setCloudLab(data.labContent.cloudLab);
                                    setDevLab(data.labContent.devLab);
                                    storeLabId(data.labId);
                                    // setContentSid(data.sectionSid);
                                    setLabDescription(data.labContent.labDescription);
                                    // setLabOverview(data.labContent.labOverview);
                                    setLabSolution(data.labContent.labSolution);

                                    setEvaluatedLab(data.labContent.evaluatedLab);
                                    setParentLab(data.labContent.parent);
                                    setPageNo(data.labContent.pageNo);
                                    setPages(data.labContent.pages);
                                    setExternal(data.external);
                                    // setLabTimeDetails(data.labContent.labTimeDetails);
                                    // setLabRecordingLink(data.labContent.labRecordingLink);
                                    // setLabRecordingFileName(data.labContent.labRecordingFileName)
                                    getLearnerLabCredentials();
                                }
                                if (data.type) {
                                    storeType(data.type);
                                }
                                if ((data.sid != null && (data.type === "DOCUMENTS" || data.type === "MS_OFFICE"))
                                    && data.completed === false) {
                                    markCourseAsCompleted(data.sid, data.sectionSid);
                                }

                                if (data.assessmentTimeline !== null) {
                                    const parse = JSON.parse(data.assessmentTimeline);
                                    compareTimes(parse?.startDate, parse?.endDate, parse?.startTime, parse?.endTime, parse?.timeZone);
                                    setAssessmentTimeline(parse);

                                }
                                else {
                                    setAssessmentTimeline(null);
                                }
                                if (data.codingQuestionId !== null) {
                                    setCodingQuestionId(data.codingQuestionId);
                                    setCodingQuestiondesc(data.codingQuestionDescription);
                                    if (data.codingQuestionScoreDetails !== null) {
                                        setCodingQuestionScoreDetails(data.codingQuestionScoreDetails);
                                    }
                                }
                                if (user.role === ROLE.INSTRUCTOR && data.labId !== null && data.labContent.evaluatedLab) {


                                    evaluateLabDetails(data.labId, trainingSid)
                                }
                                if (data.durationInMinutes !== null) {
                                    setLabDuration(Number(data.durationInMinutes));
                                }
                                if (data.labAssessment !== null) {
                                    setLabAssessment(data.labAssessment);
                                }
                                if (data.labAssessment === null) {
                                    setLabAssessment(null);
                                }
                                if (user.role === ROLE.INSTRUCTOR) {
                                    if (data.type === "CODING_ASSESSMENT" || data.type === "ASSESSMENT" || (data.type === "LAB" && data.labContent.evaluatedLab)
                                    ) {
                                        getTrainingInstructorSubmissionDetails(data.sid, data.labId, data.sectionSid, data.type);
                                    }
                                }
                                else if (data.type === "CODING_ASSESSMENT" && user.role !== ROLE.INSTRUCTOR) {
                                    if (data.type === "CODING_ASSESSMENT") {

                                        getCodingAssessmentDetails(data.sid, data.sectionSid);
                                    }
                                    if (data.type === "CODING_ASSESSMENT" && data.userCodingAssessmentSubmissionDetails !== null) {
                                        const detailsWithArray = data.userCodingAssessmentSubmissionDetails.questionDetails.map((result, index) => ({
                                            ...result,
                                            "index": index + 1

                                        }))

                                        data.userCodingAssessmentSubmissionDetails.questionDetails = detailsWithArray;

                                        setUserCodingAssessmentSubmissionDetails(data.userCodingAssessmentSubmissionDetails);
                                    }
                                    if (data.type === "CODING_ASSESSMENT" && data.userCodingAssessmentSubmissionDetails === null) {
                                        setUserCodingAssessmentSubmissionDetails(null);
                                    }
                                    if (data.type === "CODING_ASSESSMENT" && data.codingAssessmentConfiguration !== null) {
                                        if (data.codingAssessmentConfiguration.slotDetails !== null) {
                                            const slotDetails = data.codingAssessmentConfiguration.slotDetails;
                                            const { largestDate, startTime } = findLargestDateTime(slotDetails);
                                            const { nextDayFirstSlot } = filterSlotsByCurrentDate(slotDetails);

                                            let todayDate = moment().format("YYYY-MM-DD");
                                            // todayDate = todayDate.substring(0, 10);
                                            // todayDate = new Date(todayDate);
                                            if (todayDate <= largestDate) {
                                                for (let i = 0; i < slotDetails.length; i++) {
                                                    let assessmentStartTime = slotDetails[i].startTime;
                                                    let assessmentendTime = slotDetails[i].endTime;
                                                    let assessmentStartDate = slotDetails[i].assessmentDate;
                                                    assessmentStartDate = moment(assessmentStartDate).format("YYYY-MM-DD");
                                                    const startAssessmentTime = Number(assessmentStartTime.split(':')[0]) * 60 + Number(assessmentStartTime.split(':')[1]);
                                                    const endAssessmentTime = Number(assessmentendTime.split(':')[0]) * 60 + Number(assessmentendTime.split(':')[1]);
                                                    if (todayDate === assessmentStartDate) {
                                                        const currentDate = new Date();
                                                        const currentHour = currentDate.getHours();
                                                        const currentMinutes = currentDate.getMinutes();
                                                        const totalMinutes = (currentHour * 60) + currentMinutes;
                                                        if (totalMinutes >= startAssessmentTime && totalMinutes <= endAssessmentTime) {
                                                            setStartCodingAssessment(true);
                                                            setCodingAssessmentStartTime(assessmentStartTime);
                                                            setCodingAssessmentDate(assessmentStartDate);
                                                            // setCodingAssessmentAllover("Start Assessment");
                                                            //break;
                                                        }
                                                        else if (totalMinutes < startAssessmentTime) {
                                                            setCodingAssessmentStartTime(assessmentStartTime);
                                                            setCodingAssessmentDate(assessmentStartDate);
                                                            setCodingAssessmentAllover("Not Started");
                                                        }
                                                        else if (totalMinutes > endAssessmentTime) {
                                                            if (nextDayFirstSlot !== null) {
                                                                setCodingAssessmentStartTime(nextDayFirstSlot.startTime);
                                                                setCodingAssessmentDate(nextDayFirstSlot.assessmentDate.substring(0, 10));
                                                                setCodingAssessmentAllover("Next Slot");
                                                            }
                                                            else {
                                                                setCodingAssessmentStartTime(assessmentStartTime);
                                                                setCodingAssessmentAllover("All tries over");
                                                                setCodingAssessmentDate(assessmentStartDate);
                                                            }

                                                        }
                                                        // else {
                                                        //     setCodingAssessmentStartTime(assessmentStartTime);
                                                        // }


                                                    }
                                                    // else if (todayDate < assessmentStartDate) {
                                                    //     setCodingAssessmentStartTime(assessmentStartTime);
                                                    //     setCodingAssessmentAllover("Not Started");
                                                    // }
                                                    // else {
                                                    //     setCodingAssessmentStartTime(assessmentStartTime);
                                                    //     setCodingAssessmentAllover("All tries over");
                                                    // }
                                                }
                                            }
                                            // if (todayDate === largestDate) {
                                            // }
                                            else {
                                                setStartCodingAssessment(false);
                                                setCodingAssessmentDate(largestDate);
                                                setCodingAssessmentStartTime(startTime);
                                                setCodingAssessmentAllover("All tries over");
                                            }
                                        }
                                        else {
                                            setStartCodingAssessment(true);
                                        }


                                    }
                                }

                                if (data.type === "TRAINING_SESSION" && data.meetingRecording !== null) {
                                    setMeetingRecordingLink(data.meetingRecording)
                                }
                                if (data.type === "TRAINING_SESSION" && data.meetingRecording === null) {
                                    setMeetingRecordingLink('')
                                }
                                if (data.type === "LAB" && data.labContent.evaluatedLab && data.labContent.parent) {
                                    let parent = {
                                        "childLabId": data.labId,
                                        "childLabName": data.contentName,
                                        "childLabDescription": data.labContent.labDescription,
                                        "childLabOverview": data.labContent.labOverview,
                                        "childLabSolution": "Y",
                                        "pageNumber": 1,
                                        "child": false,
                                        "evaluatedLab": true
                                    }
                                    setPages(page => [parent, ...page]);
                                }
                                if (data.codingAssessmentTimeOverInSeconds > 0) {
                                    setCodingAssessmentTimeOverInSeconds(data.codingAssessmentTimeOverInSeconds / 60);
                                }
                                else {
                                    setCodingAssessmentTimeOverInSeconds(data.codingAssessmentTimeOverInSeconds);
                                }
                                if (data.codingAssessmentCheatingDetails !== null) {
                                    setCodingAssessmentCheatingDetails(data.codingAssessmentCheatingDetails)
                                }
                                else {
                                    setCodingAssessmentCheatingDetails(null);
                                }

                                // showFeedBack(data.last);
                                setContentSid(data.sectionSid);
                                setCompleteContent(data.completed);
                                modalF(data.last);
                                // setStart(data.instructorSpecific);
                                if (data.type === 'TRAINING_SESSION') {
                                    const currentDate = new Date();
                                    const currentHour = currentDate.getHours();
                                    const currentMinutes = currentDate.getMinutes();
                                    const startHour = data?.sessionStartTime?.split(':');
                                    const endHour = data?.sessionEndTime?.split(":");
                                    setSessionDate(data.sessionDate);
                                    setSessionStartTime(data.sessionStartTime);
                                    setSessionEndTime(data.sessionEndTime);

                                    setCurrentMinutesTrainingSession(currentHour * 60 + currentMinutes);
                                    setStartMinutesTrainingSession(Number(startHour[0]) * 60 + Number(startHour[1]));
                                    setEndMinutesTrainingSession(Number(endHour[0]) * 60 + Number(endHour[1]));
                                }
                                else {
                                    setSessionDate(null);
                                    setSessionStartTime(null);
                                    setSessionEndTime(null);

                                }
                                if (data.mcqSubmissionDetails !== null) {
                                    setMcqSubmissionDetails(data.mcqSubmissionDetails);
                                }
                                else {
                                    setMcqSubmissionDetails(null);
                                }
                                setZoomInfo(zoomInfo => ({
                                    ...zoomInfo,
                                    ...{
                                        "meetingId": data.meetingId,
                                        "password": data.meetingPwd,
                                        // "trainingSid": location.state.sid,
                                        "trainingSid": trainingSid,
                                        "trainingSessionSid": data.sid
                                    }
                                }))
                            }
                            else {
                                setShowcoursename(data.contentName);
                                if (data.type) {
                                    storeType(data.type);
                                }
                                setUnlocked(data.unlocked);
                                // setHasAsset(data.hasAsset);
                            }

                        }} style={{ cursor: "pointer", alignContent: "center", textAlign: "center", alignItems: "center" }}
                            data-tooltip-id="contentname" data-tooltip-content={data.contentName}
                        >
                            <input type="checkbox" checked={"checked" ? data.completed : ''} disabled ></input>
                            {(data.type === "VIDEO" || data.type === "EXTERNAL_LINK") ? <PlayCircleIcon /> : (data.type === "TRAINING_SESSION") ?
                                <DuoIcon /> : (data.type === "LAB" && data.labContent.evaluatedLab) ? <AssessmentIcon /> :
                                    (data.type === "LAB") ? <ScienceIcon /> :
                                        (data.type === "ASSESSMENT") ? ICN_MCQ
                                            : (data.type === "CODING_ASSESSMENT") ? <AssessmentIcon /> :
                                                (data.type === "CODING") ? <CodeIcon /> :
                                                    (data.type === "PROJECT") ?
                                                        <GroupWorkIcon /> : <SummarizeRoundedIcon />}
                            {data.contentName.length > 25 ? data.contentName.substring(0, 25) + "..." : data.contentName}

                        </Link >

                        <Tooltip
                            id="contentname"
                            place='right'
                            style={{ width: "50%", fontFamily: "Inter" }}
                        />






                        {
                            data.type !== "CODING" &&
                            <div style={{ float: "right", display: "flex" }}>
                                {/* <div class="dropdown-resources">
                                    <button class="dropbtn"><FolderOpenIcon style={{ fontSize: "medium" }} /><span className="px-1">Assets</span> <ArrowDropDownIcon /></button>
                                    <div class="dropdown-content-resources">
                                        {
                                            (user.role === ROLE.INSTRUCTOR || user.role === ROLE.SUPERVISOR) &&

                                            <div className="title-sm" onClick={() => {
                                                if (data.sid !== null) {
                                                    setSid(data.sid);
                                                }

                                                if (data.labId !== null) {
                                                    storeLabId(data.labId);
                                                }

                                                setShowcoursename(data.contentName);
                                                setType("UPLOAD");
                                                setAssetType(data.type);

                                            }}><CloudUploadIcon /> <span >Upload</span></div>
                                        }
                                        {
                                            data.type === "LAB" &&

                                            <div className="title-sm" >
                                                <div className="pl-1" onClick={() => {
                                                    viewContentAsset(data.labId, data.type);
                                                    setType("VIEW");
                                                    setShowcoursename(data.contentName);
                                                }}>
                                                    <RemoveRedEyeIcon /> <span >View</span></div>
                                            </div>
                                        }
                                        {
                                            data.type !== "LAB" &&

                                            <div className="title-sm" >
                                                <div className="pl-1" onClick={() => {
                                                    viewContentAsset(data.sid, data.type);
                                                    setType("VIEW");
                                                    setShowcoursename(data.contentName);
                                                }}>
                                                    <RemoveRedEyeIcon /> <span >View</span></div>
                                            </div>
                                        }

                                    </div>
                                </div> */}
                                {
                                    user.role === ROLE.LEARNER &&

                                    <div>
                                        {
                                            (data.type === "EXTERNAL_LINK" || data.type === "VIDEO" ||
                                                data.type === "DOCUMENTS" || data.type === "PHOTO" || (data.type === "LAB"
                                                    && !data.labContent.evaluatedLab)) &&
                                            <div className="title-sm">
                                                {
                                                    data.bookmarked ?
                                                        <StarIcon onClick={() => deleteBookmark(data.bookmarkSid)} />
                                                        :

                                                        <StarBorderIcon onClick={() => {
                                                            createNewBookmark(data.type, data.sid, data.labId, data.sectionSid, trainingSid);
                                                            // setBookmarkType(data.type); setSid(data.sid); setLabId(data.labId);
                                                            // setContentSid(data.sectionSid);
                                                        }} data-tooltip-id="bookmark" data-tooltip-content="Bookmark this Content" />
                                                }
                                                <Tooltip
                                                    id="bookmark"
                                                    place="right"


                                                />
                                            </div>

                                        }
                                    </div>
                                }
                                {
                                    user.role === ROLE.CONSUMER_LEARNER ?
                                        data.hasAsset &&

                                        <details>
                                            <summary class="dropbtn"><FolderOpenIcon style={{ fontSize: "medium" }} /><span className="px-1">Assets</span></summary>

                                            {
                                                (user.role === ROLE.INSTRUCTOR || user.role === ROLE.SUPERVISOR) &&

                                                <div className="title-sm" onClick={() => {
                                                    if (data.sid !== null) {
                                                        setSid(data.sid);
                                                    }

                                                    if (data.labId !== null) {
                                                        storeLabId(data.labId);
                                                    }

                                                    setShowcoursename(data.contentName);
                                                    setType("UPLOAD");
                                                    setAssetType(data.type);

                                                }}><CloudUploadIcon /> <span >Upload</span></div>
                                            }
                                            {
                                                data.type === "LAB" &&

                                                <div className="title-sm" >
                                                    <div className="pl-1" onClick={() => {
                                                        viewContentAsset(data.labId, data.type);
                                                        setType("VIEW");
                                                        setShowcoursename(data.contentName);
                                                    }}>
                                                        <RemoveRedEyeIcon /> <span >View</span></div>
                                                </div>
                                            }
                                            {
                                                data.type !== "LAB" &&

                                                <div className="title-sm" >
                                                    <div className="pl-1" onClick={() => {
                                                        viewContentAsset(data.sid, data.type);
                                                        setType("VIEW");
                                                        setShowcoursename(data.contentName);
                                                    }}>
                                                        <RemoveRedEyeIcon /> <span >View</span></div>
                                                </div>
                                            }


                                        </details>
                                        :
                                        <details>
                                            <summary class="dropbtn"><FolderOpenIcon style={{ fontSize: "medium" }} /><span className="px-1">Assets</span></summary>

                                            {
                                                (user.role === ROLE.INSTRUCTOR || user.role === ROLE.SUPERVISOR || user.role === ROLE.CONSUMER_SUPERVISOR) &&

                                                <div className="title-sm" onClick={() => {
                                                    if (data.sid !== null) {
                                                        setSid(data.sid);
                                                    }

                                                    if (data.labId !== null) {
                                                        storeLabId(data.labId);
                                                    }

                                                    setShowcoursename(data.contentName);
                                                    setType("UPLOAD");
                                                    setAssetType(data.type);

                                                }}><CloudUploadIcon /> <span >Upload</span></div>
                                            }
                                            {
                                                data.type === "LAB" &&

                                                <div className="title-sm" >
                                                    <div className="pl-1" onClick={() => {
                                                        viewContentAsset(data.labId, data.type);
                                                        setType("VIEW");
                                                        setShowcoursename(data.contentName);
                                                    }}>
                                                        <RemoveRedEyeIcon /> <span >View</span></div>
                                                </div>
                                            }
                                            {
                                                data.type !== "LAB" &&

                                                <div className="title-sm" >
                                                    <div className="pl-1" onClick={() => {
                                                        viewContentAsset(data.sid, data.type);
                                                        setType("VIEW");
                                                        setShowcoursename(data.contentName);
                                                    }}>
                                                        <RemoveRedEyeIcon /> <span >View</span></div>
                                                </div>
                                            }


                                        </details>
                                }

                            </div>
                        }
                    </>

            }
        },
        headerTextColor: '#454E50', // user can change table header text color
        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configuration.sortBy = sortKey;
            Object.keys(configuration.columns).map(key => configuration.columns[key].sortDirection = (key === sortKey) ? !configuration.columns[key].sortDirection : false);
            configuration.sortDirection = configuration.columns[sortKey].sortDirection;
            setConfiguration({ ...configuration });
        },
        // actions: [
        //     {
        //         "title": "Edit",
        //         "icon": ICN_EDIT,
        //         // "onClick": (data, i) => { setIsEdit(true); setShow(true); setInitialValues({ name: data.name, description: data.description, sid: data.sid }) }
        //     },
        //     {
        //         "title": "Delete",
        //         "icon": ICN_TRASH,
        //         // "onClick": (data) => deleteCourse(data.sid)
        //     }
        // ],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table1 sort-enabled", // table custom class
        // showCheckbox: true,
        clearSelection: false
    });
    const [configuration1, setConfiguration1] = useState({
        columns: {

            "language": {
                "title": "Language",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
            },
            "stdin": {
                "title": "Input",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
            },
            "stdout": {
                "title": "Output",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
            },
            "executedOnDate": {
                "title": "Date",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
                render: (data) =>
                    <>
                        {data.executedOnDate.substring(0, 10)}
                    </>
            },
            "memory": {
                "title": "Memory",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
            },
            "cpuTime": {
                "title": "Cpu Time",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
            }

        },
        headerTextColor: '#454E50', // user can change table header text color
        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configuration1.sortBy = sortKey;
            Object.keys(configuration1.columns).map(key => configuration1.columns[key].sortDirection = (key === sortKey) ? !configuration1.columns[key].sortDirection : false);
            configuration1.sortDirection = configuration1.columns[sortKey].sortDirection;
            setConfiguration1({ ...configuration1 });
        },
        // actions: [
        //     {
        //         "title": "Edit",
        //         "icon": ICN_EDIT,
        //         // "onClick": (data, i) => { setIsEdit(true); setShow(true); setInitialValues({ name: data.name, description: data.description, sid: data.sid }) }
        //     },
        //     {
        //         "title": "Delete",
        //         "icon": ICN_TRASH,
        //         // "onClick": (data) => deleteCourse(data.sid)
        //     }
        // ],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table1 sort-enabled", // table custom class
        // showCheckbox: true,
        clearSelection: false
    });
    const [configuration2, setConfiguration2] = useState({
        columns: {
            "index": {
                "title": "QNo.",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,

                render: (data) =>
                    <>
                        {data.index !== null ? data.index : '---'}
                    </>
            },
            "memory": {
                "title": "Memory",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
                render: (data) =>
                    <>
                        {data.memory !== null ? data.memory : '---'}
                    </>
            },
            "cpuTime": {
                "title": "Run Time (sec)",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
                render: (data) =>
                    <>
                        {data.cpuTime !== null ? data.cpuTime.toFixed(3) : '---'}
                    </>
            },
            "passed": {
                "title": "Passed Cases",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
                render: (data) =>
                    <>
                        {data.passed !== null ? data.passed : '---'}
                    </>
            },
            "failed": {
                "title": "Failed Cases",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
                render: (data) =>
                    <>
                        {data.failed !== null ? data.failed : '---'}
                    </>
            },
            "gainedMarks": {
                "title": "Marks Scored",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
                render: (data) =>
                    <>
                        {data.gainedMarks !== null ? data.gainedMarks : '---'}
                    </>
            },
            "questionMarks": {
                "title": "Question Mark",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
                render: (data) =>
                    <>
                        {data.questionMarks !== null ? data.questionMarks : '---'}
                    </>
            },
            "language": {
                "title": "Language",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
                render: (data) =>
                    <>
                        {data.language !== null ? data.language : '---'}
                    </>
            }

        },
        headerTextColor: '#454E50', // user can change table header text color
        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configuration2.sortBy = sortKey;
            Object.keys(configuration2.columns).map(key => configuration2.columns[key].sortDirection = (key === sortKey) ? !configuration2.columns[key].sortDirection : false);
            configuration2.sortDirection = configuration2.columns[sortKey].sortDirection;
            setConfiguration2({ ...configuration2 });
        },
        // actions: [
        //     {
        //         "title": "Edit",
        //         "icon": ICN_EDIT,
        //         // "onClick": (data, i) => { setIsEdit(true); setShow(true); setInitialValues({ name: data.name, description: data.description, sid: data.sid }) }
        //     },
        //     {
        //         "title": "Delete",
        //         "icon": ICN_TRASH,
        //         // "onClick": (data) => deleteCourse(data.sid)
        //     }
        // ],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table2 sort-enabled", // table custom class
        // showCheckbox: true,
        clearSelection: false
    });
    const [configuration3, setConfiguration3] = useState({
        columns: {

            "serialNumber": {
                "title": "QNo",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false
            },
            "scoredMarks": {
                "title": "Marks Scored",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
                // render: (data) => <>
                //     {data.imagesFolderExistence ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />}
                // </>
            },
            "questionMarks": {
                "title": "Question Marks",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false
            },
            "attemptStatus": {
                "title": "Status",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: true,
                render: (data) => <>



                    {data.attemptStatus === "NOT_ATTEMPTED" ?

                        <div className="fail-task" style={{
                            width: "50%", marginLeft: "-15px", color: "#101828",
                            background: "#F9FAFB", border: "1px solid rgb(249, 250, 251)"
                        }}>N/A</div>
                        :
                        data.attemptStatus === "CORRECT" ?

                            <div className="pass-task" style={{ width: "50%", marginLeft: "-15px" }}>{PASS_TASK} Pass</div>
                            :
                            data.attemptStatus === "INCORRECT" &&
                            <div className="fail-task" style={{ width: "50%", marginLeft: "-15px" }}>{FAIL_TASK} Fail</div>

                    }
                </>
            }


        },
        headerTextColor: '#454E50', // user can change table header text color
        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configuration3.sortBy = sortKey;
            Object.keys(configuration3.columns).map(key => configuration3.columns[key].sortDirection = (key === sortKey) ? !configuration3.columns[key].sortDirection : false);
            configuration3.sortDirection = configuration3.columns[sortKey].sortDirection;
            setConfiguration3({ ...configuration3 });
        },
        // actions: [
        //     {
        //         "title": "Edit",
        //         "icon": ICN_EDIT,
        //         // "onClick": (data, i) => { setIsEdit(true); setShow(true); setInitialValues({ name: data.name, description: data.description, sid: data.sid }) }
        //     },
        //     {
        //         "title": "Delete",
        //         "icon": ICN_TRASH,
        //         // "onClick": (data) => deleteCourse(data.sid)
        //     }
        // ],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table2 sort-enabled", // table custom class
        // showCheckbox: true,
        clearSelection: false
    });
    const [configuration4, setConfiguration4] = useState({
        columns: {

            // "serialNumber": {
            //     "title": "QNo",
            //     "sortDirection": null,
            //     "sortEnabled": false,
            //     isSearchEnabled: false
            // },
            "learnerName": {
                "title": "Learner",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false,
                // render: (data) => <>
                //     {data.imagesFolderExistence ? <CheckCircleOutlineIcon /> : <HighlightOffIcon />}
                // </>
            },
            "learnerEmail": {
                "title": "Email",
                "sortDirection": null,
                "sortEnabled": false,
                isSearchEnabled: false
            },
            "learnerScore": {
                "title": "Score",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: true,
                render: (data) => <>
                    {
                        data.learnerScore === null ? '---' : data.learnerScore
                    }


                    {/* {data.attemptStatus === "NOT_ATTEMPTED" ?

                        <div className="fail-task" style={{
                            width: "50%", marginLeft: "-15px", color: "#101828",
                            background: "#F9FAFB", border: "1px solid rgb(249, 250, 251)"
                        }}>N/A</div>
                        :
                        data.attemptStatus === "CORRECT" ?

                            <div className="pass-task" style={{ width: "50%", marginLeft: "-15px" }}>{PASS_TASK} Pass</div>
                            :
                            data.attemptStatus === "INCORRECT" &&
                            <div className="fail-task" style={{ width: "50%", marginLeft: "-15px" }}>{FAIL_TASK} Fail</div>

                    } */}
                </>
            },
            "learnerPercentage": {
                "title": "Percentage",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: true,
                render: (data) => <>
                    {
                        data.learnerPercentage === null ? '---' : data.learnerPercentage
                    }
                </>
            }


        },
        headerTextColor: '#454E50', // user can change table header text color
        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configuration4.sortBy = sortKey;
            Object.keys(configuration4.columns).map(key => configuration4.columns[key].sortDirection = (key === sortKey) ? !configuration4.columns[key].sortDirection : false);
            configuration4.sortDirection = configuration4.columns[sortKey].sortDirection;
            setConfiguration4({ ...configuration4 });
        },
        // actions: [
        //     {
        //         "title": "Edit",
        //         "icon": ICN_EDIT,
        //         // "onClick": (data, i) => { setIsEdit(true); setShow(true); setInitialValues({ name: data.name, description: data.description, sid: data.sid }) }
        //     },
        //     {
        //         "title": "Delete",
        //         "icon": ICN_TRASH,
        //         // "onClick": (data) => deleteCourse(data.sid)
        //     }
        // ],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table2 sort-enabled", // table custom class
        // showCheckbox: true,
        clearSelection: false
    });


    //get all section and content
    const getTrainingContentsByTrainingSid = async () => {
        try {
            let sum = 0;
            // let trainingSid = location.state.sid;
            // spinner.show();
            RestService.getTrainingContentsByTrainingSid(trainingSid).then(
                response => {

                    if (response.status === 200) {
                        if (typeof (response.data) === "string") {
                            setTrainingDetailsList([]);
                        }
                        else if (location?.state?.upcomingContent) {
                            setTrainingDetailsList(response.data.courseSectionResponseTO.filter(i => i.visibile));
                            setTrainingObjective(response.data.trainingObjective);
                            setMarkAsCompleted(response.data.completionProgress);
                            // setSessionDescriptions(response.data.trainingObjective.sessionDescriptions);
                            // setSid(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sid);
                            setSid(location.state.upcomingContent.contentSid !== null ?
                                location.state.upcomingContent.contentSid : '');
                            // setType(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type);
                            setType(location.state.upcomingContent.contentType !== null ? location.state.upcomingContent.contentType
                                : 'OBJECTIVE');
                            // setVdlink(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].contentLink !== null ?
                            //     response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].contentLink : '');
                            setVdlink(location.state.upcomingContent.contentLink !== null ?
                                location.state.upcomingContent.contentLink : "");
                            if (location.state.upcomingContent.contentType !== null
                                && location.state.upcomingContent.contentType === "EXTERNAL_LINK"
                                || location.state.upcomingContent.contentType === "VIDEO") {
                                setCall(true);
                                setCompleteContent(false);
                            }
                            if (location.state.upcomingContent.contentType !== null
                                && location.state.upcomingContent.contentType === "PHOTO"
                                || location.state.upcomingContent.contentType === "DOCUMENTS") {
                                markCourseAsCompleted(location.state.upcomingContent.contentSid
                                    , location.state.upcomingContent.sectionSid);
                            }


                            if (location.state.upcomingContent.contentType !== null
                                && location.state.upcomingContent.contentType === "TRAINING_SESSION") {
                                setZoomInfo(zoomInfo => ({
                                    ...zoomInfo,
                                    ...{
                                        "meetingId": location.state.upcomingContent.meetingId,
                                        "password": location.state.upcomingContent.meetingPwd,
                                        // "trainingSid": location.state.sid,
                                        "trainingSid": trainingSid,
                                        "trainingSessionSid": location.state.upcomingContent.contentSid
                                    }
                                }))
                            }
                            if (location.state.upcomingContent.contentType !== null
                                && location.state.upcomingContent.contentType === "LAB") {
                                getLearnerLabCredentials()
                            }
                            // setLabId(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labId !== null ?
                            //     response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labId : '');
                            setLabId(location.state.upcomingContent.labId !== null ?
                                location.state.upcomingContent.labId : '');
                            // setContentSid(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sectionSid);
                            setContentSid(location.state.upcomingContent.sectionSid !== null ?
                                location.state.upcomingContent.sectionSid : '');
                            // setLabDescription(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent
                            //     !== null ? response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.labDescription
                            //     : '');
                            setLabDescription(location.state.upcomingContent.labContent
                                !== null ? location.state.upcomingContent.labContent.labDescription
                                : '');
                            // setLabOverview(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent
                            //     !== null ? response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.labOverview
                            //     : '');
                            // setLabOverview(location.state.upcomingContent.labContent
                            //     !== null ? location.state.upcomingContent.labContent.labOverview
                            //     : '');

                            // setLabSolution(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent
                            //     !== null ? response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.labSolution : '');
                            setLabSolution(location.state.upcomingContent.labContent
                                !== null && location.state.upcomingContent.labContent.labSolution !== null ?
                                location.state.upcomingContent.labContent.labSolution
                                : '');
                            // setLabDuration(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].durationInMinutes !== null ?
                            //     response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].durationInMinutes : '');
                            setLabDuration(location.state.upcomingContent.duration !== null ?
                                Number(location.state.upcomingContent.duration.split('.')[0]) : '')

                            setEvaluatedLab(location.state.upcomingContent.labContent
                                !== null && location.state.upcomingContent.labContent.evaluatedLab !== null &&
                                location.state.upcomingContent.labContent.evaluatedLab);
                            // setLabRecordingLink(location.state.upcomingContent.labContent
                            //     !== null && location.state.upcomingContent.labContent.labRecordingLink !== null ?
                            //     location.state.upcomingContent.labContent.labRecordingLink : null);
                            // setLabRecordingFileName(location.state.upcomingContent.labContent
                            //     !== null && location.state.upcomingContent.labContent.labRecordingFileName !== null ?
                            //     location.state.upcomingContent.labContent.labRecordingFileName : null);
                            setLabAssessment(location.state.upcomingContent.labAssessment);

                            // setShowcoursename(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].contentName);
                            setShowcoursename(location.state.upcomingContent.contentName !== null ?
                                location.state.upcomingContent.contentName : '');

                        }

                        else {
                            setGroupProjectDetails(response.data.courseSectionResponseTO[0].groupProjectDetails);
                            setCourseSid(response.data.courseSectionResponseTO[0].courseSid);
                            if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === "PROJECT") {
                                setContentSid(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sectionSid);
                                if (user.role === ROLE.INSTRUCTOR || user.role === ROLE.SUPERVISOR) {
                                    setProjectId(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].projectId);
                                    // instructorAndSupervisorView(data.projectId, data.sectionSid, trainingSid);

                                }
                                else if (user.role === ROLE.LEARNER) {
                                    if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].projectContent !== null) {
                                        setProjectName(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].projectContent.projectName);
                                        setProjectDescription(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].projectContent.projectDescription);
                                        setDurationInDays(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].projectContent.durationInDays);
                                        setCategory(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].projectContent.category);
                                        setProjectMarks(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].projectContent.marks);
                                        setProjectId(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].projectId);
                                    }
                                    if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].projectSubmissionResponse !== null) {
                                        setProjectSubmissionResponse(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].projectSubmissionResponse);
                                    }
                                    if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].projectScoreResponse !== null) {
                                        setProjectScoreResponse(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].projectScoreResponse);
                                    }
                                }

                            }

                            if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].unlocked) {
                                // setHasAsset(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].hasAsset);
                                setUnlocked(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].unlocked);
                                setTrainingDetailsList(response.data.courseSectionResponseTO.filter(i => i.visibile));
                                setTrainingObjective(response.data.trainingObjective);
                                setMarkAsCompleted(response.data.completionProgress);

                                setShowCodingAssessmentResult(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].showCodingAssessmentResult);
                                setShowMCQAssessmentResult(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].showMCQAssessmentResult);
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].showMCQAssessmentResult) {
                                    localStorage.setItem("showMCQAssessmentResult", "resultVisible");
                                }
                                else {
                                    localStorage.setItem("showMCQAssessmentResult", "resultNotVisible");
                                }

                                setContentSid(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sectionSid);
                                setShowcoursename(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].contentName);
                                setReminderAdded(reminderAdded => ({
                                    ...reminderAdded,
                                    ...{
                                        "sid": response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sid,
                                        "value": response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].reminderAdded
                                    }
                                }));
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].urlBasedAssessment) {
                                    setUrlBasedAssessment(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].urlBasedAssessment);
                                    const token = username?.assessmentLoginBody;
                                    // const tokenStringify = JSON.stringify(token);
                                    const newUrl = `${response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].assessmentUrl}?newUser=${token}`
                                    // setUrlBasedAssessment(esponse.data.courseSectionResponseTO[0].courseContentResposeTOList[0]);
                                    setAssessmentUrl(newUrl);
                                    // setAssessmentUrl(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].assessmentUrl);
                                }
                                else {
                                    setUrlBasedAssessment(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].urlBasedAssessment);
                                    setAssessmentUrl("");
                                }
                                // setReminderAdded(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].reminderAdded);
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].contentLink) {
                                    Show(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].contentLink); //done
                                }
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sid !== null) {
                                    setSid(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sid);
                                    localStorage.setItem("sid", response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sid);
                                    localStorage.setItem("sectionSid", response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sectionSid);
                                }
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labId !== null) {

                                    if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.evaluatedLab) {
                                        //current time
                                        const currentDate = new Date();
                                        const currentHour = currentDate.getHours();
                                        const currentMinutes = currentDate.getMinutes();
                                        const totalMinutes = (currentHour * 60) + currentMinutes;
                                        setCurrentTimesInMinutes(totalMinutes);
                                        //Calculating end time for Lab

                                        const starttime = response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.labTimeDetails ?
                                            Number(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.labTimeDetails.split(':')[0]) * 60
                                            +
                                            Number(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.labTimeDetails.split(':')[1])
                                            : 0

                                        setLabTimeDetails(starttime)

                                        const totalLabTime = Number(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].durationInMinutes.split('.')[0]) + starttime

                                        setTotalLabTimeInMinutes(totalLabTime);
                                        const labDuration = totalLabTime - totalMinutes;
                                        setLabDuration(labDuration);
                                        setCloudLabUserUniqueId(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.cloudLabUserUniqueId);
                                        setCloudLabEvaluationLab(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.cloudLabEvaluationLab)
                                    }
                                    else if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.cloudLabUserUniqueId !== null) {
                                        setCloudLabUserUniqueId(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.cloudLabUserUniqueId);
                                    }
                                    else {
                                        setLabDuration(Number(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].durationInMinutes.split('.')[0]));
                                        setCloudLabUserUniqueId(null);
                                        setCloudLabEvaluationLab(null);
                                    }

                                    if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.buttonText !== null
                                        || response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.buttonText?.length > 0) {
                                        setButtonText(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.buttonText);
                                    }
                                    else {
                                        setButtonText(null);
                                    }
                                    setCloudLab(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.cloudLab);
                                    setDevLab(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.devLab);
                                    storeLabId(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labId);
                                    // setContentSid(data.sectionSid);
                                    setLabDescription(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.labDescription);
                                    // setLabOverview(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.labOverview);
                                    setLabSolution(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.labSolution);

                                    setEvaluatedLab(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.evaluatedLab);
                                    setParentLab(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.parent);
                                    setPageNo(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.pageNo);
                                    setPages(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.pages);
                                    setExternal(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].external);
                                    // setLabTimeDetails(data.labContent.labTimeDetails);
                                    // setLabRecordingLink(data.labContent.labRecordingLink);
                                    // setLabRecordingFileName(data.labContent.labRecordingFileName)
                                    getLearnerLabCredentials();
                                }
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type) {
                                    storeType(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type);
                                }
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].assessmentTimeline !== null) {
                                    const parse = JSON.parse(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].assessmentTimeline);
                                    compareTimes(parse?.startDate, parse?.endDate, parse?.startTime, parse?.endTime, parse?.timeZone);
                                    setAssessmentTimeline(parse);
                                }
                                else {
                                    setAssessmentTimeline(null);
                                }

                                if ((response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sid != null && (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === "DOCUMENTS" ||
                                    response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === "MS_OFFICE"))
                                    && response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].completed === false) {
                                    markCourseAsCompleted(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sid, response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sectionSid);
                                }
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].codingQuestionId !== null) {
                                    setCodingQuestionId(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].codingQuestionId);
                                    setCodingQuestiondesc(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].codingQuestionDescription);
                                    if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].codingQuestionScoreDetails !== null) {
                                        setCodingQuestionScoreDetails(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].codingQuestionScoreDetails);
                                    }
                                }
                                if (user.role === ROLE.INSTRUCTOR && response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labId !== null &&
                                    response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.evaluatedLab) {


                                    evaluateLabDetails(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labId, trainingSid)
                                }
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].durationInMinutes !== null) {
                                    setLabDuration(Number(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].durationInMinutes));
                                }
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labAssessment !== null) {
                                    setLabAssessment(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labAssessment);
                                }
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labAssessment === null) {
                                    setLabAssessment(null);
                                }
                                if (user.role === ROLE.INSTRUCTOR) {
                                    if ((response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === "CODING_ASSESSMENT"
                                        || response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === "ASSESSMENT"
                                        || (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === "LAB" &&
                                            response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.evaluatedLab))
                                    ) {
                                        getTrainingInstructorSubmissionDetails(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sid,
                                            response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labId, response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sectionSid,
                                            response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type);
                                    }
                                }
                                else if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === "CODING_ASSESSMENT" && user.role !== ROLE.INSTRUCTOR) {
                                    if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === "CODING_ASSESSMENT") {
                                        getCodingAssessmentDetails(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sid,
                                            response.data.courseSectionResponseTO[0].courseSid, response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sectionSid);
                                    }
                                    if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === "CODING_ASSESSMENT" &&
                                        response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].userCodingAssessmentSubmissionDetails !== null) {
                                        const detailsWithArray = response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].userCodingAssessmentSubmissionDetails.questionDetails.map((result, index) => ({
                                            ...result,
                                            "index": index + 1

                                        }))

                                        response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].userCodingAssessmentSubmissionDetails.questionDetails = detailsWithArray;

                                        setUserCodingAssessmentSubmissionDetails(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].userCodingAssessmentSubmissionDetails);
                                    }
                                    if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === "CODING_ASSESSMENT"
                                        && response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].userCodingAssessmentSubmissionDetails === null) {
                                        setUserCodingAssessmentSubmissionDetails(null);
                                    }
                                    if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === "CODING_ASSESSMENT" &&
                                        response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].codingAssessmentConfiguration !== null) {
                                        if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].codingAssessmentConfiguration.slotDetails !== null) {
                                            const slotDetails = response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].codingAssessmentConfiguration.slotDetails;
                                            const { largestDate, startTime } = findLargestDateTime(slotDetails);
                                            const { nextDayFirstSlot } = filterSlotsByCurrentDate(slotDetails);

                                            let todayDate = moment().format("YYYY-MM-DD");
                                            // todayDate = todayDate.substring(0, 10);
                                            // todayDate = new Date(todayDate);
                                            if (todayDate <= largestDate) {
                                                for (let i = 0; i < slotDetails.length; i++) {
                                                    let assessmentStartTime = slotDetails[i].startTime;
                                                    let assessmentendTime = slotDetails[i].endTime;
                                                    let assessmentStartDate = slotDetails[i].assessmentDate;
                                                    assessmentStartDate = moment(assessmentStartDate).format("YYYY-MM-DD");
                                                    const startAssessmentTime = Number(assessmentStartTime.split(':')[0]) * 60 + Number(assessmentStartTime.split(':')[1]);
                                                    const endAssessmentTime = Number(assessmentendTime.split(':')[0]) * 60 + Number(assessmentendTime.split(':')[1]);
                                                    if (todayDate === assessmentStartDate) {
                                                        const currentDate = new Date();
                                                        const currentHour = currentDate.getHours();
                                                        const currentMinutes = currentDate.getMinutes();
                                                        const totalMinutes = (currentHour * 60) + currentMinutes;
                                                        if (totalMinutes >= startAssessmentTime && totalMinutes <= endAssessmentTime) {
                                                            setStartCodingAssessment(true);
                                                            setCodingAssessmentStartTime(assessmentStartTime);
                                                            setCodingAssessmentDate(assessmentStartDate);
                                                            // setCodingAssessmentAllover("Start Assessment");
                                                            //break;
                                                        }
                                                        else if (totalMinutes < startAssessmentTime) {
                                                            setCodingAssessmentStartTime(assessmentStartTime);
                                                            setCodingAssessmentDate(assessmentStartDate);
                                                            setCodingAssessmentAllover("Not Started");
                                                        }
                                                        else if (totalMinutes > endAssessmentTime) {
                                                            if (nextDayFirstSlot !== null) {
                                                                setCodingAssessmentStartTime(nextDayFirstSlot.startTime);
                                                                setCodingAssessmentDate(nextDayFirstSlot.assessmentDate.substring(0, 10));
                                                                setCodingAssessmentAllover("Next Slot");
                                                            }
                                                            else {
                                                                setCodingAssessmentStartTime(assessmentStartTime);
                                                                setCodingAssessmentAllover("All tries over");
                                                                setCodingAssessmentDate(assessmentStartDate);
                                                            }

                                                        }
                                                        // else {
                                                        //     setCodingAssessmentStartTime(assessmentStartTime);
                                                        // }


                                                    }
                                                    // else if (todayDate < assessmentStartDate) {
                                                    //     setCodingAssessmentStartTime(assessmentStartTime);
                                                    //     setCodingAssessmentAllover("Not Started");
                                                    // }
                                                    // else {
                                                    //     setCodingAssessmentStartTime(assessmentStartTime);
                                                    //     setCodingAssessmentAllover("All tries over");
                                                    // }
                                                }
                                            }
                                            // if (todayDate === largestDate) {
                                            // }
                                            else {
                                                setStartCodingAssessment(false);
                                                setCodingAssessmentDate(largestDate);
                                                setCodingAssessmentStartTime(startTime);
                                                setCodingAssessmentAllover("All tries over");
                                            }
                                        }
                                        else {
                                            setStartCodingAssessment(true);
                                        }


                                    }
                                }

                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === "TRAINING_SESSION" &&
                                    response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].meetingRecording !== null) {
                                    setMeetingRecordingLink(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].meetingRecording)
                                }
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === "TRAINING_SESSION"
                                    && response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].meetingRecording === null) {
                                    setMeetingRecordingLink('')
                                }
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === "LAB" &&
                                    response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.evaluatedLab &&
                                    response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.parent) {
                                    let parent = {
                                        "childLabId": response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labId,
                                        "childLabName": response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].contentName,
                                        "childLabDescription": response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.labDescription,
                                        "childLabOverview": response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].labContent.labOverview,
                                        "childLabSolution": "Y",
                                        "pageNumber": 1,
                                        "child": false,
                                        "evaluatedLab": true
                                    }
                                    setPages(page => [parent, ...page]);
                                }
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].codingAssessmentTimeOverInSeconds > 0) {
                                    setCodingAssessmentTimeOverInSeconds(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].codingAssessmentTimeOverInSeconds / 60);
                                }
                                else {
                                    setCodingAssessmentTimeOverInSeconds(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].codingAssessmentTimeOverInSeconds);
                                }
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].codingAssessmentCheatingDetails !== null) {
                                    setCodingAssessmentCheatingDetails(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].codingAssessmentCheatingDetails)
                                }
                                else {
                                    setCodingAssessmentCheatingDetails(null);
                                }

                                // showFeedBack(data.last);
                                setContentSid(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sectionSid);
                                setCompleteContent(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].completed);
                                modalF(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].last);

                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type === 'TRAINING_SESSION') {
                                    const currentDate = new Date();
                                    const currentHour = currentDate.getHours();
                                    const currentMinutes = currentDate.getMinutes();
                                    const startHour = response.data.courseSectionResponseTO[0].courseContentResposeTOList[0]?.sessionStartTime?.split(':');
                                    const endHour = response.data.courseSectionResponseTO[0].courseContentResposeTOList[0]?.sessionEndTime?.split(":");
                                    setSessionDate(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sessionDate);
                                    setSessionStartTime(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sessionStartTime);
                                    setSessionEndTime(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sessionEndTime);

                                    setCurrentMinutesTrainingSession(currentHour * 60 + currentMinutes);
                                    setStartMinutesTrainingSession(Number(startHour[0]) * 60 + Number(startHour[1]));
                                    setEndMinutesTrainingSession(Number(endHour[0]) * 60 + Number(endHour[1]));
                                }
                                else {
                                    setSessionDate(null);
                                    setSessionStartTime(null);
                                    setSessionEndTime(null);

                                }
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].mcqSubmissionDetails !== null) {
                                    setMcqSubmissionDetails(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].mcqSubmissionDetails);
                                }
                                else {
                                    setMcqSubmissionDetails(null);
                                }
                                setZoomInfo(zoomInfo => ({
                                    ...zoomInfo,
                                    ...{
                                        "meetingId": response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].meetingId,
                                        "password": response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].meetingPwd,
                                        // "trainingSid": location.state.sid,
                                        "trainingSid": trainingSid,
                                        "trainingSessionSid": response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].sid
                                    }
                                }))
                            }
                            else {
                                setShowcoursename(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].contentName);
                                if (response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type) {
                                    storeType(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].type);
                                }
                                setUnlocked(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].unlocked);
                                // setHasAsset(response.data.courseSectionResponseTO[0].courseContentResposeTOList[0].hasAsset);
                            }

                        }

                        setTimeout(() => {
                            setLoader(false);
                        }, 2000)

                    }
                    else {
                        setTrainingDetailsList([]);
                        setIsLoading(false);
                    }

                    response.data.courseSectionResponseTO.map((i) => {
                        if (i.visibile) {
                            sum += i.courseContentResposeTOList.length;
                        }
                        setContentLength(sum);
                    })
                },
                err => {
                    console.error(err);
                    setTrainingDetailsList([]);
                    setIsLoading(false);
                    // spinner.hide();
                }
            ).finally(() => {
                // setLoader(false);
                // spinner.hide();

            });
        } catch (err) {
            console.error("error occur on getTrainingContentsByTrainingSid()", err)
        }
    }

    const findLargestDateTime = (slots) => {
        if (slots.length === 0) {
            return null;
        }

        // Sort the array based on assessmentDate in descending order
        const sortedSlots = [...slots].sort((a, b) => new Date(b.assessmentDate) - new Date(a.assessmentDate));

        // Get the largest date
        const largestDate = moment(sortedSlots[0].assessmentDate).format("YYYY-MM-DD");
        const startTime = sortedSlots[0].startTime;
        return { largestDate, startTime };
    };

    const filterSlotsByCurrentDate = (slots) => {
        const currentDate = moment();

        const nextDaySlots = slots
            .filter((slot) =>
                moment(slot.assessmentDate).isSameOrAfter(
                    currentDate.clone().add(1, "day"),
                    "day"
                )
            )
            .sort((a, b) => {
                const dateComparison = moment(a.assessmentDate).diff(
                    moment(b.assessmentDate)
                );
                if (dateComparison !== 0) {
                    return dateComparison;
                }
                return moment(a.startTime, "HH:mm").diff(moment(b.startTime, "HH:mm"));
            });

        const nextDayFirstSlot = nextDaySlots.length > 0 ? nextDaySlots[0] : null;

        return { nextDayFirstSlot };
    };


    //get all section and content when markascompleted triggers
    const getTrainingContentsByTrainingSidUpdated = async () => {
        try {
            setLoaderTraining(true);
            let sum = 0;
            // let trainingSid = location.state.sid;
            // spinner.show();
            RestService.getTrainingContentsByTrainingSid(trainingSid).then(
                response => {

                    if (response.status === 200) {
                        setTrainingObjective(response.data.trainingObjective);
                        setMarkAsCompleted(response.data.completionProgress);
                        setTrainingDetailsList(response.data.courseSectionResponseTO.filter(i => i.visibile));

                    } else {
                        setTrainingDetailsList([]);
                    }
                    response.data.courseSectionResponseTO.map((i) => {
                        if (i.visibile) {
                            sum += i.courseContentResposeTOList.length;
                        }
                        setContentLength(sum);
                    })
                    setTimeout(() => {
                        setLoaderTraining(false);
                    }, 1000);
                },
                err => {
                    console.error(err);
                    setLoaderTraining(false);
                    // spinner.hide();
                }
            ).finally(() => {

                spinner.hide();
                // setIsLoading(false)
            });
        } catch (err) {
            console.error("error occur on getTrainingContentsByTrainingSidUpdated()", err)
        }
    }

    //update content mark as completed
    const markCourseAsCompleted = (contentSid, sectionSid) => {
        try {
            // let trainingSid = location.state.sid;
            let payload = {
                "completedInDuration": 0,
                "totalDuration": 0
            }
            spinner.show();
            RestService.markCourseAsCompleted(contentSid, sectionSid, trainingSid, payload).then(
                response => {

                    if (response.status === 200) {
                        // setMarkAsCompleted(response.data);
                        getTrainingContentsByTrainingSidUpdated();

                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on markCourseAsCompleted()", err)
        }
    }

    //update content mark as completed
    const markCourseAsCompletedVideo = () => {
        if (call) {
            try {
                // let trainingSid = location.state.sid;
                let payload = {
                    "completedInDuration": duration,
                    "totalDuration": duration
                }
                spinner.show();
                RestService.markCourseAsCompleted(sid, contentSid, trainingSid, payload).then(
                    response => {

                        if (response.status === 200) {
                            // setMarkAsCompleted(response.data);
                            getTrainingContentsByTrainingSidUpdated();
                            setCall(false);
                            setDuration(0);
                        }
                    },
                    err => {
                        spinner.hide();
                    }
                ).finally(() => {
                    spinner.hide();
                });
            } catch (err) {
                console.error("error occur on markCourseAsCompleted()", err)
            }
        }

    }


    //get completed courses 
    const getCompletedCourses = () => {

        try {
            // let trainingSid = location.state.sid;
            // spinner.show();
            RestService.getCompletedCourses(trainingSid).then(
                response => {

                    if (response.status === 200) {
                        // setMarkAsCompleted(response.data);

                    }
                    // for (let i = 0; i < response.data.completedSection.length; i++) {
                    //     for (let j = 0; j < response.data.completedSection[i].completedCourseContentDetails.length;
                    //         j++) {
                    //         let sid = response.data.completedSection[i].completedCourseContentDetails[j].sid
                    //         setSectionSidArray(sectionArray => {
                    //             return[
                    //                 ...sectionArray, sid
                    //             ]

                    //         });
                    //     }
                    // }

                },
                err => {
                    console.error(err);
                    // spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getCompletedCourses()", err)
        }
    }

    //get training by sid
    const getTrainingBySid = () => {

        try {
            // let trainingSid = location.state.sid;
            // spinner.show();
            RestService.getTrainingBySid(trainingSid).then(
                response => {
                    if (response.status === 200) {
                        setTrainingBySid(response.data);

                    }
                },
                err => {
                    // spinner.hide();
                    console.error(err);
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getTrainingBySid()", err)
        }
    }

    //convert recording details to recording
    const convertRecordingDetailsToRecording = () => {

        const url = GLOBELCONSTANT.HARD_CODED_API.CONVERT_RECORDING_DETAIL_TO_RECORDING;
        axios.get(url)
            .then((response) => {
                if (response.status === 200) {
                }
            })
            .catch((error) => { console.error(error) });
    }

    //get InstructorScreenRecording Filter for exaluated labs
    const getInstructorScreenRecordingFilter = (labId) => {
        try {
            // let trainingSid = location.state.sid;
            // spinner.show();
            RestService.getInstructorScreenRecordingFilter(labId, trainingSid).then(
                response => {
                    if (response.status === 200) {
                        // setInstructorScreenRecording(response.data);
                        // setType('INSTRUCTOR_EVALUATION');

                    }
                },
                err => {
                    // spinner.hide();
                    console.error(err);
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getInstructorScreenRecordingFilter()", err)
        }
    }

    //get assets link or file
    const viewContentAsset = (labIdNSid, type) => {
        // if (type === 'LAB') {
        try {

            RestService.viewContentAsset(labIdNSid, type, trainingSid).then(
                response => {
                    if (response.status === 200) {
                        setViewAsset(response.data);

                        // setType('INSTRUCTOR_EVALUATION');

                    }
                    else {
                        setViewAsset([]);
                    }
                },
                err => {
                    // spinner.hide();
                    console.error(err);
                    setViewAsset([]);
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on viewContentAsset()", err)
        }
    }
    // else {
    //     try {

    //         RestService.viewContentAsset(labIdNSid, type, trainingSid).then(
    //             response => {
    //                 if (response.status === 200) {
    //                     setViewAsset(response.data);

    //                     // setType('INSTRUCTOR_EVALUATION');

    //                 }
    //             },
    //             err => {
    //                 // spinner.hide();
    //             }
    //         ).finally(() => {
    //             spinner.hide();
    //         });
    //     } catch (err) {
    //         console.error("error occur on viewContentAsset()", err)
    //     }
    // }


    //upload resource in asset
    const addAssetToContent = () => {
        let payload = null;
        let formData = new FormData();
        if (typeof assetResource === 'object') {
            formData.append("asset-file", assetResource);
            payload = formData
        }
        if (assetType === 'LAB') {
            try {
                spinner.show();


                RestService.addAssetToContent(assetName, labId, assetType, trainingSid, assetResource, payload).then(res => {
                    Toast.success({ message: `Asset Successfully Created`, time: 2000 });
                    setShowAsset(false);
                    setAssetName('');
                    setAssetResource();
                    setAssetType('')
                    spinner.hide();

                }, err => { spinner.hide(); Toast.error({ message: `Something wrong!!`, time: 2000 }); }
                );
            }
            catch (err) {
                spinner.hide();
                console.error('error occur on addAssetToContent', err)
                Toast.error({ message: `Something wrong!!`, time: 2000 });
            }
        }
        else {
            try {
                spinner.show();


                RestService.addAssetToContent(assetName, sid, assetType, trainingSid, assetResource, payload).then(res => {
                    Toast.success({ message: `Asset Created Successfully`, time: 2000 });
                    setShowAsset(false);
                    setAssetName('');
                    setAssetResource();
                    setAssetType('')
                    spinner.hide();

                }, err => { spinner.hide(); Toast.error({ message: `Something wrong!!`, time: 2000 }); }
                );
            }
            catch (err) {
                spinner.hide();
                console.error('error occur on addAssetToContent', err)
                Toast.error({ message: `Something wrong!!`, time: 2000 });
            }
        }

    }

    //get learner lab credentials
    const getLearnerLabCredentials = () => {

        try {
            // spinner.show();
            RestService.getLearnerLabCredentials().then(res => {
                // let jsonString = JSON.stringify(res.data);
                localStorage.setItem("GUAC_AUTH", res.data);
                spinner.hide();

            }, err => { spinner.hide() }
            );
        }
        catch (err) {
            spinner.hide();
            console.error('error occur on getLearnerLabCredentials', err)
        }

    }

    //add bookmark
    const createNewBookmark = (bookmarkType, sid, labId, contentSid, trainingSid) => {

        try {
            let payload = {
                "contentType": bookmarkType,
                "sectionSid": contentSid,
                "trainingSid": trainingSid
            };
            if (sid === null) {
                payload.contentSid = null;
                payload.labId = labId;
            }
            if (labId === null) {
                payload.contentSid = sid;
                payload.labId = 0;
            }

            spinner.show();
            RestService.createNewBookmark(payload).then(res => {
                setTrainingDetailsList(res.data.courseSectionResponseTO.filter(i => i.visibile));
                Toast.success({ message: `Content Bookmarked`, time: 2000 });
                spinner.hide();


            }, err => { spinner.hide(); Toast.error({ message: `Try Again`, time: 2000 }); }
            );
        }
        catch (err) {
            spinner.hide();
            console.error('error occur on createNewBookmark', err)
        }

    }

    //delete bookmark
    const deleteBookmark = (bookmarkSid) => {
        try {
            spinner.show();
            RestService.deleteBookmark(bookmarkSid).then(res => {
                setTrainingDetailsList(res.data.courseSectionResponseTO.filter(i => i.visibile));
                Toast.success({ message: `Bookmark Removed`, time: 2000 });
                spinner.hide();

            }, err => { spinner.hide(); }
            );
        }
        catch (err) {
            spinner.hide();
            console.error('error occur on deleteBookmark', err)
        }

    }

    //instructor and supervisor view
    const instructorAndSupervisorView = (projectId, sectionSid, trainingSid) => {
        try {
            // spinner.show();
            RestService.instructorAndSupervisorView(projectId, sectionSid, trainingSid).then(res => {
                if (res.status === 200) {
                    // setProjectCardDetails(res.data.cardDetails);
                    let groupDetails = res.data.groupDetails.map(r => {
                        return ({ label: r.groupName, value: r.groupSid })

                    });
                    // setProjectGroupDetails(groupDetails);
                }
                spinner.hide();

            }, err => { spinner.hide() }
            );
        }
        catch (err) {
            spinner.hide();
            console.error('error occur on instructorAndSupervisorView', err)
        }
    }

    //instructor evaluate list of learner
    const evaluateLabDetails = (labId, trainingSid) => {
        try {
            // spinner.show();
            RestService.evaluateLabDetails(labId, trainingSid).then(res => {
                if (res.status === 200) {
                    setInstructorEvaluationAll(res.data);
                }
                spinner.hide();

            }, err => { spinner.hide() }
            );
        }
        catch (err) {
            spinner.hide();
            console.error('error occur on evaluateLabDetails', err)
        }
    }

    //get training training-session
    const getTrainingsTrainingSession = () => {
        const url = GLOBELCONSTANT.HARD_CODED_API.GET_TRAININGS_TRAINING_SESSION;
        axios.post(url + `?Authorization=${token}&training_sid=${trainingSid}`)
            .then(({ data }) => {
                setTrainingSessionList(data);

            })
            .catch((error) => { console.error(error) });
    }

    //instructor evaluate list of learner
    const getTrainingSessionAttendanceCsv = () => {
        const url = GLOBELCONSTANT.HARD_CODED_API.GET_TRAINING_SESSION_ATTENDANCE_CSV;
        setAttendanceLoading(true)
        axios.patch(url + `?present_percentage=${presentPercentage}&training_session_sid=${sid}`,
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/csv'
                }
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'agenda.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
                setAttendanceLoading(false)
            })
            .catch((error) => { setAttendanceLoading(false); console.error(error) });
    }


    //Suppervisor Download Reports
    const getSupervisorAssessmentReport = () => {
        const url = GLOBELCONSTANT.HARD_CODED_API.GET_SUPERVISOR_ASSESSMENT_REPORT;
        setReportsLoading(true)
        axios
            .patch(url + `?training_sid=${trainingSid}`, null, {
                responseType: 'blob', // Set responseType in the Axios configuration
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/xlsx',
                },
            })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${trainingBySid.name}.xlsx`); // Specify the correct file name
                document.body.appendChild(link);
                link.click();
                setReportsLoading(false)
            })
            .catch((error) => {
                setReportsLoading(false)
                console.error(error);
            });
    };

    //add training calendar
    const addSessionToGoogleCalendar = () => {

        if (reminderAdded.value) {
            Toast.success({ message: `Session Link Already Sent`, time: 2000 });
        }
        else {
            try {
                setLoading(true);
                // spinner.show();
                const payload = {
                    "meetingSid": sid,
                    "sectionSid": contentSid,
                    "trainingSid": trainingSid,
                    "userSid": username?.sid
                }
                RestService.addSessionToGoogleCalendar(payload).then(res => {
                    if (res.status === 200) {
                        setLoading(false);
                        setReminderAdded(reminderAdded => ({
                            ...reminderAdded,
                            ...{
                                "sid": sid,
                                "value": true
                            }
                        }));

                        // getTrainingContentsByTrainingSidUpdated();
                        // setTrainingDetailsList(res.data.courseSectionResponseTO.filter(i => i.visibile));
                        spinner.hide();
                        Toast.success({ message: `Session Link Sent`, time: 2000 });
                    }


                }, err => {
                    spinner.hide()
                    setLoading(false);
                }
                );
            }
            catch (err) {
                spinner.hide();
                console.error('error occur on addSessionToGoogleCalendar', err)
            }
        }

    }

    //get labOverView
    const getLabOverview = () => {
        try {
            setLabLoading(true);
            RestService.getLabOverview(labId).then(
                response => {
                    if (response.status === 200) {
                        const overview = response.data?.labOverview;
                        setTimeout(() => {
                            setLabLoading(false);
                            navigate("/training/training-details/labs", {
                                state: {
                                    labDescription, labOverview: overview, labSolution, labId, contentSid, trainingSid, labDuration, showcoursename, type, codingQuestionId, codingQuestiondesc, evaluatedLab,
                                    parentLab, pageNo, pages, cloudLabUserUniqueId, buttonText, cloudLab, devLab, external
                                }
                            });
                        }, 1500);
                    }
                },
                err => {
                    // spinner.hide();
                    console.error(err);
                    setLabLoading(false);
                }
            )
        } catch (err) {
            setLabLoading(false);
            console.error("error occur on getLabOverview()", err)
        }
    }

    //get getCodingAssessmentDetails for coding assessment
    const getCodingAssessmentDetails = (assessmentSid, sectSid) => {
        setCodingAssessmentLoading(true);
        try {
            // const courseSid1 = courSid === undefined ? courseSid : courSid;
            RestService.getCodingAssessmentDetails(assessmentSid, courseSid, sectSid).then(
                response => {
                    if (response.status === 200) {
                        setCodingAssessmentDetails(response.data);
                        setTimeout(() => {
                            setCodingAssessmentLoading(false);
                        }, 1500);
                    }
                },
                err => {
                    console.error(err);
                    setCodingAssessmentLoading(false);
                    setCodingAssessmentDetails(null);


                }
            )
        } catch (err) {
            setCodingAssessmentLoading(false);
            setCodingAssessmentDetails(null);
            console.error("error occur on getCodingAssessmentDetails()", err)
        }
    }

    //get candidate submission details by trainer for coding assessments, mcq and capstone labs
    const getTrainingInstructorSubmissionDetails = (assessmentSid, labId, sectSid, type) => {
        setCodingAssessmentLoading(true);
        try {
            const payload = {
                "assessmentSid": assessmentSid,
                "labId": labId === null ? 0 : labId,
                "sectionSid": sectSid,
                "trainingSid": trainingSid,
                "type": type
            };
            // const courseSid1 = courSid === undefined ? courseSid : courSid;
            RestService.getTrainingInstructorSubmissionDetails(payload).then(
                response => {
                    if (response.status === 200) {
                        setSubmissionDetailsForTrainer(response.data);
                        setTimeout(() => {
                            setCodingAssessmentLoading(false);
                        }, 1500);
                    }
                },
                err => {
                    console.error(err);
                    setCodingAssessmentLoading(false);
                    setSubmissionDetailsForTrainer(null);
                }
            )
        } catch (err) {
            setCodingAssessmentLoading(false);
            setSubmissionDetailsForTrainer(null);
            console.error("error occur on getTrainingInstructorSubmissionDetails()", err)
        }
    }


    //initialize component
    useEffect(() => {
        localStorage.removeItem('zoom');
        const localStorageKeys = Object.keys(localStorage);
        // Iterate over the dynamic keys and remove corresponding items from localStorage
        localStorageKeys.map(key => {
            if (key.includes('codingquestionId') || key.includes('language')) {

                localStorage.removeItem(key);
                return null; // You need to return something when using map
            }
        });

        localStorage.removeItem('startAssessmentStatus');
        localStorage.removeItem('end_date');
        localStorage.removeItem("answer");
        // getTrainingContentsByTrainingSidUpdated();
        getTrainingContentsByTrainingSid();
        // getCompletedCourses();
        getTrainingBySid();
        convertRecordingDetailsToRecording();
        setTrainingId(location?.state?.sid ? location.state.sid : '');
        // getTrainingsTrainingSession();
    }, []);

    useEffect(() => {
        // Disable right click ;
        // document.addEventListener('contextmenu', (e) => {
        //     Toast.error({ message: `Right click not allowed` });
        //     e.preventDefault();
        // })

        // disable ctrl shift i 
        const disableConsole = (event) => {
            if (event.ctrlKey && event.shiftKey && event.keyCode === 73) {
                event.preventDefault();
            }
            else if (event.metaKey && event.altKey && event.keyCode === 73) {
                event.preventDefault();
            }
        };
        window.addEventListener('keydown', disableConsole);
        return () => {
            // Cleanup the event listener when the component is unmounted
            window.removeEventListener('keydown', disableConsole);
        };

    }, []);

    useEffect(() => {
        if ((Math.ceil(played) > Math.ceil(0.8 * duration)) && completeContent === false) {
            markCourseAsCompletedVideo();

        }
    }, [played]);

    if (user.role === ROLE.LEARNER) {
        for (let i = 0; i < trainingDetailsList.length; i++) {
            for (let j = 0; j < trainingDetailsList[i]["courseContentResposeTOList"].length; j++) {
                if (trainingDetailsList[i].courseContentResposeTOList[j]["instructorSpecific"] === true) {
                    trainingDetailsList[i].courseContentResposeTOList.splice(j, 1);

                }

            }
        }
    }

    const goBack = () => {
        localStorage.removeItem("trainingSid")
        navigate("/training", { state: { title: "Training" } });
    }
    const goBackToLearnlytica = () => {
        const url = `https://learnlytica.com/mycourses`;

        window.location.href = url;

        // window.open(url, '_blank');
    }

    const [openDropdowns, setOpenDropdowns] = useState({});
    const listRef = useRef();



    const toggleDropdown = useCallback((id, isOpen) => {
        setOpenDropdowns(prevState => {
            const newState = { ...prevState, [id]: isOpen };
            listRef.current.resetAfterIndex(0, true); // Recalculate item sizes after state change
            return newState;
        });
    }, []);

    const getItemSize = index => {
        const train = trainingDetailsList[index];
        const baseHeight = 100; // Base height for each item
        const additionalHeight = openDropdowns[train.sid] ? train.courseContentResposeTOList.length * 50 : 0; // Adjust based on content height
        return baseHeight + additionalHeight;
    };

    const Row = ({ index, style }) => {
        const train = trainingDetailsList[index];
        return (
            <div key={train.sid} style={{ ...style, height: "auto" }} onClick={() => { setGroupProjectDetails(train.groupProjectDetails); setCourseSid(train.courseSid); }}>
                {loader ? (
                    <SkeletonLoader skeletonCount={1} />
                ) : (
                    <DropdownItem

                        title={train.sectionName}
                        total={`${train.courseContentResposeTOList.length} Contents`}
                        theme="dark"
                        id={train.sid}
                        isOpen={openDropdowns[train.sid] || false}
                        toggleDropdown={(isOpen) => toggleDropdown(train.sid, isOpen)}
                    >

                        <div style={{ borderBottom: "1px solid #EAECF0" }}>   <DynamicTable configuration={configuration} sourceData={train.courseContentResposeTOList} loaderTraining={loaderTraining} noHeader='noHeader' />
                        </div>
                    </DropdownItem>
                )}
            </div>
        );
    };

    useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('MSFullscreenChange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
        };
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && isFullscreen) {
                toggleFullscreen();
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isFullscreen, toggleFullscreen]);



    useEffect(() => {
        // Function to disable right-click
        const disableRightClick = (event) => {
            event.preventDefault();
        };

        // Attach the event listener
        document.addEventListener('contextmenu', disableRightClick);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('contextmenu', disableRightClick);
        };
    }, []);

    // disable shortcut

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (
                e.key === 'F12' ||
                (e.ctrlKey && e.shiftKey && e.key === 'I') ||
                (e.ctrlKey && e.key === 'U') ||
                (e.ctrlKey && e.key === 'P') || // Ctrl+P
                (e.ctrlKey && e.key === 'S')   // Ctrl+S
            ) {
                e.preventDefault();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    // disable iframe save and print
    const iframeRef = useRef(null);

    useEffect(() => {
        const handleContextMenu = (e) => {
            e.preventDefault();
        };

        const handleSelectStart = (e) => {
            e.preventDefault();
        };

        const iframe = iframeRef.current;

        if (iframe) {
            // Add event listeners to disable context menu and text selection
            iframe.addEventListener('contextmenu', handleContextMenu);
            iframe.addEventListener('selectstart', handleSelectStart);
        }

        // Cleanup on component unmount
        return () => {
            if (iframe) {
                iframe.removeEventListener('contextmenu', handleContextMenu);
                iframe.removeEventListener('selectstart', handleSelectStart);
            }
        };
    }, []);

    return (
        <>
            <div className="no-print">
                {
                    // isLoading ?
                    //     <LoadingSpinner />
                    //     :
                    // trainingDetailsList.length === 0 ?
                    //     <ErrorComponent />

                    //     :
                    !isLoading ?
                        <ErrorComponent />
                        :
                        <>
                            <Modal show={show} handleClose={() => setShow(false)}  >
                                {/* {show && <WhiteBoard  /> } */}

                                {user.role === ROLE.INSTRUCTOR ? <WhiteBoard />
                                    :

                                    (user.role === ROLE.LEARNER || user.role === ROLE.CONSUMER_LEARNER) ? <ClassNotes trainingSid={trainingSid} contentSid={sid} sectionSid={contentSid} setShow={setShow} /> : ""}
                            </Modal>
                            {/* <Modal show={showRecording} handleClose={() => setShowRecording(false)}>
                            <div>
                                {RecordingPlayer(labRecordingLink)}
                            </div>

                        </Modal> */}

                            {/* <div className="row mb-3 ml-1 " >
                         
                           </div> */}
                            <nav aria-label="breadcrumb" style={{ padding: user.role === ROLE.CONSUMER_LEARNER && "0px 40px" }}  >
                                <ol class="breadcrumb mb-3" style={{ stylescontent, borderBottom: "2px solid  #F9F5FF" }}>
                                    {
                                        user.role === ROLE.CONSUMER_LEARNER ?
                                            <li class="breadcrumb-item"><Link onClick={() => goBackToLearnlytica()} style={{ cursor: "pointer" }} >Course</Link></li>
                                            :
                                            <li class="breadcrumb-item"><Link onClick={() => goBack()} style={{ cursor: "pointer" }} >Training</Link></li>
                                    }


                                    <li class="breadcrumb-item active">{trainingBySid.name}</li>

                                    {/* <li class="breadcrumb-item active" aria-current="page">  {showcoursename.length === 0 ? "" : (
                                    <>
                                        {showcoursename}
                                    </>

                                )}</li> */}
                                    {/* <li class="breadcrumb-item active" aria-current="page">Data</li> */}
                                </ol>
                            </nav>
                            {/* <button name="share" onClick={() => getTrainingSessionAttendanceCsv()}>Download</button> */}


                            {/* <div className='row mx-0 ' style={{ alignItems: "center", justifyContent: "space-between" }}>
                            <div className="card ml-0" style={{ borderRadius: "10px", cursor: "pointer" }}>
                                <div className="card-body mb-0">
                                    <div className="title-sm" onClick={() => setType('OBJECTIVE')}>
                                      
                                        Training Objective <ArrowDropDownIcon />
                                    </div>
                                </div>
                            </div>

                           

                            <div className="col-md-4 col-sm-12" style={{ display: "flex", alignItems: "center" }}>
                                <ProgressBar
                                    progress={
                                        markCompleted.totalCourseCompletedInTraining === null
                                            ? 0
                                            : markCompleted.totalCourseCompletedInTraining > contentLength
                                                ? contentLength
                                                : markCompleted.totalCourseCompletedInTraining
                                    }
                                    totalSection={contentLength}
                                    trainingSid={trainingSid}
                                />
                            </div>

                            {user.role === ROLE.INSTRUCTOR && (
                                <div className="col-md-1 col-sm-12 class-mode mt-1" onClick={() => setShow(true)} style={{ background: "#49167E", borderRadius: "10px" }}>
                                    Whiteboard
                                </div>
                            )}

                            {user.role === ROLE.LEARNER && (type === "OBJECTIVE" || type === "SECTION" || type === "VIEW") ?

                                <>
                                    <div disabled className="col-md-1 col-sm-12 class-mode mt-1 disabled-button"
                                        style={{ background: "#49167E", borderRadius: "10px" }}
                                        data-tooltip-id="notestooltip" data-tooltip-content="Select any content to continue...">
                                        Make Notes
                                    </div>
                                    <Tooltip
                                        id="notestooltip"
                                        data-tooltip-place="right"
                                        variant="info"
                                    />
                                </>
                                :
                                user.role === ROLE.LEARNER &&
                                <div className="col-md-1 col-sm-12 class-mode mt-1" onClick={() => setShow(true)} style={{ background: "#49167E", borderRadius: "10px" }}>
                                    Make Notes
                                </div>
                            }
                            {(user.role === ROLE.INSTRUCTOR || user.role === ROLE.SUPERVISOR) && type !== "TRAINING_SESSION" ?

                                <>

                                    <div disabled className="col-md-1 col-sm-12 class-mode mt-1 disabled-button"
                                        style={{ background: "#49167E", borderRadius: "10px" }}
                                        data-tooltip-id="notestooltip1" data-tooltip-content="Select any session to download...">
                                        Attendance
                                    </div>
                                    <Tooltip
                                        id="notestooltip1"
                                        data-tooltip-place="right"
                                        variant="info"
                                    />
                                </>
                                :
                                (user.role === ROLE.INSTRUCTOR || user.role === ROLE.SUPERVISOR) &&
                                <div className="col-md-1 col-sm-12 class-mode mt-1" onClick={() => getTrainingSessionAttendanceCsv()} style={{ background: "#49167E", borderRadius: "10px" }}>
                                    Attendance
                                </div>


                            }

                            {user.role === ROLE.SUPERVISOR &&
                                <>
                                    <div className="col-md-1 col-sm-12 class-mode mt-1" data-tooltip-id="AssessmentDownload" data-tooltip-content="Download Assessment Report..." onClick={() => getSupervisorAssessmentReport()} style={{ background: "#49167E", borderRadius: "10px" }}>

                                        <DownloadIcon />
                                    </div>
                                    <Tooltip
                                        id="AssessmentDownload"
                                        data-tooltip-place="right"
                                        variant="info"
                                    />
                                </>
                                

                            }
                        </div> */}





                            <div class="row " style={{ padding: user.role === ROLE.CONSUMER_LEARNER && "0px 40px" }}  >

                                <div className={`col-md-8 ${isExpanded ? 'col-md-12' : ''}`} >
                                    <div className="row" style={{ alignItems: "center" }}>
                                        <div className="col-md-6" >
                                            <div className="training-title" style={stylescontent}>{showcoursename.length === 0 ? "" : (
                                                <div data-tooltip-id="showcoursename" data-tooltip-content={showcoursename}>
                                                    {showcoursename}
                                                    <Tooltip
                                                        id="showcoursename"


                                                        style={{ fontSize: "14px", width: "50%" }}
                                                    />
                                                </div>

                                            )}</div>
                                        </div>
                                        <div className="col-md-6">
                                            {(type === "PHOTO" || type === "DOCUMENTS") ? (
                                                <button className="normalbtn mt-1" style={{ float: "inline-end", marginLeft: "15px", cursor: unlocked === false && 'not-allowed' }} id="toggle_fullscreen" onClick={toggleFullscreen} disabled={unlocked === false}>
                                                    {isExpanded ? 'Collapse Content' : 'Expand Content'} {ICN_EXPAND_NEW}
                                                </button>
                                            ) : null}

                                            {user.role === ROLE.CONSUMER_LEARNER ? null : (
                                                (type === "VIDEO" || type === "EXTERNAL_LINK") ? (
                                                    <button className="normalbtn" style={{ float: "inline-end", marginTop: "4px", cursor: unlocked === false && 'not-allowed' }} onClick={changeColor} disabled={unlocked === false}>
                                                        Dim Lights {ICN_DIM_LIGHT}
                                                    </button>
                                                ) : null
                                            )}


                                        </div>

                                    </div>



                                    {
                                        loader ?
                                            <SkeletonLoader skeletonCount={1} />

                                            :
                                            (sectionObjective !== null && type === "SECTION") ?

                                                <TrainingObjective trainingObjective={sectionObjective} />
                                                :

                                                (type === "EXTERNAL_LINK" || type === "VIDEO") ?
                                                    unlocked ?
                                                        VideoMediaPlayer(vdlink)
                                                        :
                                                        <LockedContent />
                                                    : (type === "PHOTO" || type === "DOCUMENTS") ?
                                                        unlocked ?

                                                            <div id="container">


                                                                <iframe title="Restricted Content" ref={iframeRef} className={isFullscreen ? "showcontentdiv-fullscreen" : "showcontentdiv"} src={`${vdlink}#toolbar=0`} width="100%" height="100%"
                                                                    style={{
                                                                        border: 'none',
                                                                        userSelect: 'none',
                                                                    }}
                                                                />
                                                            </div>

                                                            :
                                                            <LockedContent />
                                                        : (type === "LAB") ?
                                                            unlocked ?

                                                                <div className="p-2 labassessmentcloundcontent row mx-0" style={{ display: "flex", flexDirection: "column" }} >
                                                                    {
                                                                        labAssessment === null &&
                                                                            user.role !== ROLE.INSTRUCTOR &&
                                                                            cloudLabEvaluationLab !== null ?
                                                                            <>


                                                                                <div className=" assessment-session-parrent">
                                                                                    <div className="assessment-session-header">
                                                                                        {/* <div className="title-md">{cloudLabEvaluationLab["Lab Name"]}</div> */}
                                                                                        {cloudLabEvaluationLab["Lab Name"]}
                                                                                    </div>

                                                                                    <div className="row" style={{ width: "100%" }}>

                                                                                        <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                            <div className="assessment-session-card">
                                                                                                <div className="assessment-session-card-title">
                                                                                                    Total marks
                                                                                                </div>
                                                                                                <div className="assessment-session-card-score">
                                                                                                    {cloudLabEvaluationLab['Total Marks'].toFixed(2)}

                                                                                                </div>

                                                                                            </div>

                                                                                        </div>


                                                                                        <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                            <div className="assessment-session-card">
                                                                                                <div className="assessment-session-card-title">
                                                                                                    Marks Scored
                                                                                                </div>
                                                                                                <div className="assessment-session-card-score">
                                                                                                    {cloudLabEvaluationLab['Scored Marks'].toFixed(2)}
                                                                                                </div>

                                                                                            </div>

                                                                                        </div>

                                                                                        <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                            <div className="assessment-session-card">
                                                                                                <div className="assessment-session-card-title">
                                                                                                    Percentage
                                                                                                </div>
                                                                                                <div className="assessment-session-card-score">
                                                                                                    {`${cloudLabEvaluationLab.Percentage.toFixed(2)}%`}

                                                                                                </div>

                                                                                            </div>

                                                                                        </div>

                                                                                    </div>
                                                                                    {
                                                                                        cloudLabEvaluationLab['Evaluation Details'] === undefined &&

                                                                                        <div className="row" style={{ width: "100%" }}>

                                                                                            <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                <div className="assessment-session-card">
                                                                                                    <div className="assessment-session-card-title">
                                                                                                        Passed Test Cases
                                                                                                    </div>
                                                                                                    <div className="assessment-session-card-score">
                                                                                                        {cloudLabEvaluationLab['Passed Test Cases']}
                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>

                                                                                            <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                <div className="assessment-session-card">
                                                                                                    <div className="assessment-session-card-title">
                                                                                                        Failed Test Cases
                                                                                                    </div>
                                                                                                    <div className="assessment-session-card-score">
                                                                                                        {cloudLabEvaluationLab['Failed Test Cases']}

                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>

                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        cloudLabEvaluationLab['Evaluation Details'] !== undefined &&

                                                                                        <div style={{ width: "100%" }}>
                                                                                            <div className="d-flex assessment-task-deatls" style={{ width: "100%", justifyContent: "space-between", borderBottom: "1px solid #EAECF0" }}>
                                                                                                <div className="main-compile-section-heading">Tasks</div>
                                                                                                <div className="main-compile-section-heading">Status</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    }

                                                                                    {
                                                                                        cloudLabEvaluationLab['Evaluation Details'] !== undefined &&

                                                                                        <div className="assessment-task-parrent">
                                                                                            {
                                                                                                Object.keys(cloudLabEvaluationLab['Evaluation Details']).map(lab => {
                                                                                                    return (
                                                                                                        <div className="d-flex assessment-task-deatls" style={{ width: "100%", justifyContent: "space-between", borderBottom: "1px solid #EAECF0", background: "#F9FAFB" }}>

                                                                                                            <div className="task-perforamnce-name">{lab}</div>
                                                                                                            {
                                                                                                                cloudLabEvaluationLab['Evaluation Details'][`${lab}`] ?
                                                                                                                    <div className="pass-task">{PASS_TASK} Pass</div>
                                                                                                                    :
                                                                                                                    <div className="fail-task">{FAIL_TASK} Fail</div>

                                                                                                            }


                                                                                                        </div>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </div>
                                                                                    }

                                                                                </div>




                                                                            </>
                                                                            :
                                                                            labAssessment === null &&
                                                                            user.role !== ROLE.INSTRUCTOR &&



                                                                            <div className="coding-assessment-session-parrent m-auto">
                                                                                <div style={{ width: "auto", textAlign: "center", textDecoration: "none", padding: "16px 28px", margin: "auto", borderRadius: "8px" }}>


                                                                                    {

                                                                                        completeContent && evaluatedLab ?

                                                                                            <>
                                                                                                <p style={{ color: "#fff", fontSize: "15px", background: "#7F56D9" }}>Already Attempted</p>
                                                                                            </>
                                                                                            :
                                                                                            // (totalLabTimeInMinutes - currentTimeInMinutes) <= 0 && evaluatedLab ?
                                                                                            //     <>


                                                                                            //         <button style={{ color: "#fff", fontSize: "15px" }} disabled
                                                                                            //         >Time Over</button>

                                                                                            //     </>
                                                                                            //     :
                                                                                            assessmentTimeline !== null && evaluatedLab ?
                                                                                                <>
                                                                                                    <div className="d-flex mb-3">

                                                                                                        <div className=" assessment-session-header">
                                                                                                            Start Date -
                                                                                                        </div>

                                                                                                        <div className=" assessment-session-header mx-3">
                                                                                                            {assessmentTimeline?.startDate},

                                                                                                            <span className="mx-1">  {assessmentTimeline?.startTime}</span>
                                                                                                            <span className="mx-1">  {assessmentTimeline?.timeZone}</span>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className="d-flex mb-3">
                                                                                                        <div className=" assessment-session-header">
                                                                                                            End Date -
                                                                                                        </div>

                                                                                                        <div className=" assessment-session-header mx-3">
                                                                                                            {assessmentTimeline?.endDate},

                                                                                                            <span className="mx-1">  {assessmentTimeline?.endTime}</span>
                                                                                                            <span className="mx-1">  {assessmentTimeline?.timeZone}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {
                                                                                                        message === 'NOT_EXPIRED' ?


                                                                                                            <button style={{ width: "auto", textAlign: "center", textDecoration: "none", padding: "16px 28px", margin: "auto", borderRadius: "8px", color: "#fff", fontSize: "18px", background: "#7F56D9", fontWeight: "600", lineHeight: "28px" }}
                                                                                                                onClick={() => getLabOverview()}
                                                                                                            >
                                                                                                                {
                                                                                                                    labLoading ?

                                                                                                                        <Spinner
                                                                                                                            as="span"
                                                                                                                            animation="border"
                                                                                                                            size="md"
                                                                                                                            role="status"
                                                                                                                            aria-hidden="true"
                                                                                                                            className="mx-2 mt-1 loader-button" // Add margin to separate spinner from text
                                                                                                                            style={{ border: '2px dotted #101828' }}
                                                                                                                        />
                                                                                                                        :

                                                                                                                        <>
                                                                                                                            Open Sandbox {ICN_OPEN_SANDBOX}
                                                                                                                        </>
                                                                                                                }</button>

                                                                                                            :
                                                                                                            message === 'NOT_STARTED' ?
                                                                                                                <button style={{
                                                                                                                    width: "auto", textAlign: "center", textDecoration: "none", padding: "16px 28px", margin: "auto", borderRadius: "8px", color: "#fff",
                                                                                                                    fontSize: "18px", fontWeight: "600", background: "#7F56D9", lineHeight: "28px", cursor: "not-allowed", opacity: '0.6'
                                                                                                                }}

                                                                                                                >Not Started Yet</button>
                                                                                                                :
                                                                                                                message === 'EXPIRED' &&
                                                                                                                <button style={{
                                                                                                                    width: "auto", textAlign: "center", textDecoration: "none", padding: "16px 28px", margin: "auto", borderRadius: "8px", color: "#fff", fontSize: "18px",
                                                                                                                    fontWeight: "600", background: "#7F56D9", lineHeight: "28px", cursor: "not-allowed", opacity: '0.6'
                                                                                                                }}

                                                                                                                >
                                                                                                                    Assessment Over
                                                                                                                </button>
                                                                                                    }

                                                                                                </>
                                                                                                :
                                                                                                labTimeDetails <= currentTimeInMinutes && evaluatedLab ?

                                                                                                    <>

                                                                                                        <button style={{ width: "auto", textAlign: "center", textDecoration: "none", padding: "16px 28px", margin: "auto", borderRadius: "8px", color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px", background: "#7F56D9", }}
                                                                                                            onClick={() => getLabOverview()}

                                                                                                        > {
                                                                                                                labLoading ?

                                                                                                                    <Spinner
                                                                                                                        as="span"
                                                                                                                        animation="border"
                                                                                                                        size="md"
                                                                                                                        role="status"
                                                                                                                        aria-hidden="true"
                                                                                                                        className="mx-2 mt-1 loader-button" // Add margin to separate spinner from text
                                                                                                                        style={{ border: '2px dotted #101828' }}
                                                                                                                    />
                                                                                                                    :

                                                                                                                    <>
                                                                                                                        Open Sandbox {ICN_OPEN_SANDBOX}
                                                                                                                    </>
                                                                                                            }</button>

                                                                                                    </>
                                                                                                    :
                                                                                                    labTimeDetails > currentTimeInMinutes && evaluatedLab ?
                                                                                                        <>

                                                                                                            <button style={{ width: "auto", textAlign: "center", textDecoration: "none", padding: "16px 28px", margin: "auto", borderRadius: "8px", color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px", background: "#7F56D9", }}

                                                                                                            >Not Yet Started</button>
                                                                                                        </>

                                                                                                        :

                                                                                                        <>

                                                                                                            <button style={{ width: "auto", textAlign: "center", textDecoration: "none", padding: "16px 28px", margin: "auto", borderRadius: "8px", color: "#fff", fontSize: "18px", background: "#7F56D9", fontWeight: "600", lineHeight: "28px" }}
                                                                                                                onClick={() => getLabOverview()}

                                                                                                            >                             {
                                                                                                                    labLoading ?

                                                                                                                        <Spinner
                                                                                                                            as="span"
                                                                                                                            animation="border"
                                                                                                                            size="md"
                                                                                                                            role="status"
                                                                                                                            aria-hidden="true"
                                                                                                                            className="mx-2 mt-1 loader-button" // Add margin to separate spinner from text
                                                                                                                            style={{ border: '2px dotted #101828' }}
                                                                                                                        />
                                                                                                                        :

                                                                                                                        <>
                                                                                                                            Open Sandbox {ICN_OPEN_SANDBOX}
                                                                                                                        </>
                                                                                                                }</button>

                                                                                                        </>


                                                                                    }


                                                                                </div>
                                                                            </div>

                                                                    }
                                                                    {
                                                                        labAssessment !== null && user.role !== ROLE.INSTRUCTOR ?
                                                                            <>
                                                                                {/* <div style={{ textAlign: "center", textDecoration: "none", background: "rgb(73,22,126) ", padding: "15px 20px", marginBottom: "50px", marginTop: "10px", border: "1px solid rgb(73,22,126)", borderRadius: "10px" }}>

                                                                        <button style={{ color: "#fff", fontSize: "15px" }}
                                                                            onClick={() => { setShowRecording(true) }}
                                                                        >Show Recordings</button>

                                                                    </div> */}


                                                                                <div className="coding-assessment-session-parrent" style={{ margin: "auto 30px" }}>
                                                                                    <div class="assessment-session-header">{showcoursename}</div>
                                                                                    <div className='row ' style={{ width: "100%" }}>
                                                                                        <div className='col-12 col-sm-6 col-md-3'>

                                                                                            <div className="assessment-session-card h-100">
                                                                                                <div className="assessment-session-card-title">
                                                                                                    Pass Test Cases
                                                                                                </div>
                                                                                                <div className="assessment-session-card-score">

                                                                                                    {labAssessment.passed}
                                                                                                </div>

                                                                                            </div>


                                                                                        </div>
                                                                                        <div className='col-12 col-sm-6 col-md-3'>

                                                                                            <div className="assessment-session-card h-100">
                                                                                                <div className="assessment-session-card-title">
                                                                                                    Fail Test Cases
                                                                                                </div>
                                                                                                <div className="assessment-session-card-score">

                                                                                                    {labAssessment.failed}
                                                                                                </div>

                                                                                            </div>


                                                                                        </div>
                                                                                        <div className='col-12 col-sm-6 col-md-3'>


                                                                                            <div className="assessment-session-card h-100">
                                                                                                <div className="assessment-session-card-title">
                                                                                                    Percentage
                                                                                                </div>
                                                                                                <div className="assessment-session-card-score">

                                                                                                    {`${Math.round(labAssessment.percentage)} %`}
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className='col-12 col-sm-6 col-md-3'>

                                                                                            <div className="assessment-session-card h-100">
                                                                                                <div className="assessment-session-card-title">
                                                                                                    Your Output
                                                                                                </div>
                                                                                                <div className="assessment-session-card-score">
                                                                                                    <a href={labAssessment.consoleUrl} target="_blank" rel="noopener noreferrer" className="normalbtn mx-1" >Show
                                                                                                        <span className="mx-2"> <LinkIcon /></span>

                                                                                                    </a>
                                                                                                </div>

                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* // <div className="border">
                                                                        //     <div className="card-body row" >
                                                                        //         <div className="title-md col-5">Lab % <br />{labAssessment.percentage !== null && labAssessment.percentage.toFixed(2)}</div>

                                                                        //         <div className="col-6">
                                                                        //             <p className="card-text title-md">Your Remarks</p>
                                                                        //             <div className="card p-2 h-100 title-sm" style={{ background: "#E9ECEF", borderRadius: "10px" }}>{labAssessment.remarks !== null && labAssessment.remarks}</div>
                                                                        //         </div>
                                                                        //     </div>
                                                                        // </div> */}


                                                                            </>

                                                                            : ''
                                                                    }
                                                                    {
                                                                        user.role === ROLE.INSTRUCTOR && evaluatedLab ?
                                                                            codingAssessmentLoading ?
                                                                                <SkeletonLoader skeletonCount={5} />
                                                                                :
                                                                                <div className="showcontentdiv row mx-0 " style={{ display: "flex", flexDirection: "column" }}>
                                                                                    <div className="coding-assessment-session-parrent">
                                                                                        <div className="assessment-session-header">
                                                                                            {/* <div className="title-md">{cloudLabEvaluationLab["Lab Name"]}</div> */}
                                                                                            {showcoursename}
                                                                                        </div>


                                                                                        <div className="row" style={{ width: "100%" }}>

                                                                                            <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                <div className="assessment-session-card">
                                                                                                    <div className="assessment-session-card-title">
                                                                                                        Batch Count
                                                                                                    </div>

                                                                                                    <div className="assessment-session-card-score">

                                                                                                        {submissionDetailsForTrainer?.batchCount}
                                                                                                    </div>


                                                                                                </div>

                                                                                            </div>


                                                                                            <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                <div className="assessment-session-card">
                                                                                                    <div className="assessment-session-card-title">
                                                                                                        Submissions
                                                                                                    </div>

                                                                                                    <div className="assessment-session-card-score">
                                                                                                        {submissionDetailsForTrainer?.submissionsReceived}
                                                                                                    </div>


                                                                                                </div>

                                                                                            </div>

                                                                                            <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                <div className="assessment-session-card">
                                                                                                    <div className="assessment-session-card-title">
                                                                                                        Avg Batch (%)
                                                                                                    </div>

                                                                                                    <div className="assessment-session-card-score">
                                                                                                        {`${submissionDetailsForTrainer?.averageBatchPercentage}`}

                                                                                                    </div>


                                                                                                </div>

                                                                                            </div>

                                                                                        </div>


                                                                                        <div className="coding-assessment-task-parrent">
                                                                                            <DynamicTable {...{ configuration: configuration4, sourceData: submissionDetailsForTrainer?.submissionDetails }} />
                                                                                        </div>




                                                                                    </div>
                                                                                </div>
                                                                            :
                                                                            instructorEvaluationAll !== null && user.role === ROLE.INSTRUCTOR && evaluatedLab ?
                                                                                // instructorEvaluationAll !== null && user.role === ROLE.INSTRUCTOR &&

                                                                                <TrainingObjective instructorEvaluationAll={instructorEvaluationAll}
                                                                                    trainingSid={trainingSid} labId={labId} />
                                                                                :
                                                                                user.role === ROLE.INSTRUCTOR && !evaluatedLab &&
                                                                                <div className="coding-assessment-session-parrent m-auto">

                                                                                    <div style={{
                                                                                        width: "auto", textAlign: "center", textDecoration: "none", padding: "16px 28px", margin: "auto", borderRadius: "8px"


                                                                                    }}>

                                                                                        <button style={{
                                                                                            width: "auto", textAlign: "center", textDecoration: "none", padding: "16px 28px", margin: "auto", borderRadius: "8px",
                                                                                            color: "rgb(255,255,255)", fontSize: "18px",
                                                                                            background: "rgb(127,86,217)",
                                                                                            fontWeight: "600",
                                                                                            lineHeight: "28px"
                                                                                        }}
                                                                                            onClick={() => getLabOverview()}
                                                                                        >{
                                                                                                labLoading ?

                                                                                                    <Spinner
                                                                                                        as="span"
                                                                                                        animation="border"
                                                                                                        size="md"
                                                                                                        role="status"
                                                                                                        aria-hidden="true"
                                                                                                        className="mx-2 mt-1 loader-button" // Add margin to separate spinner from text
                                                                                                        style={{ border: '2px dotted #101828' }}
                                                                                                    />
                                                                                                    :

                                                                                                    <>
                                                                                                        Open Sandbox {ICN_OPEN_SANDBOX}
                                                                                                    </>
                                                                                            }</button>


                                                                                    </div>

                                                                                </div>

                                                                    }
                                                                </div>
                                                                :
                                                                <LockedContent />

                                                            : (type === "CODING") ?
                                                                unlocked ?
                                                                    <div className="showcontentdiv row mx-0" style={{ display: "flex", flexDirection: "column" }} >
                                                                        {

                                                                            <>
                                                                                {
                                                                                    codingQuestionScoreDetails === null ?

                                                                                        <div style={{ width: "160px", textAlign: "center", textDecoration: "none", background: "rgb(73,22,126) ", padding: "15px 20px", marginLeft: "240px", marginBottom: "50px", marginTop: "40px", border: "1px solid rgb(73,22,126)", borderRadius: "10px" }}>

                                                                                            <button style={{ color: "#fff", fontSize: "15px" }} onClick={() => navigate("/training/training-details/labs", {
                                                                                                state: {
                                                                                                    labDescription, labOverview, labSolution, labId, contentSid, trainingSid, labDuration, showcoursename, type, codingQuestionId, codingQuestiondesc, evaluatedLab
                                                                                                }
                                                                                            })
                                                                                            }>Open Sandbox</button>

                                                                                        </div>
                                                                                        :
                                                                                        <>

                                                                                            <div className='card-header title-md' style={{ marginBottom: "0px" }}>
                                                                                                Coding Results
                                                                                            </div>
                                                                                            <ToggleButtonGroup
                                                                                                color="primary"
                                                                                                value={alignment}
                                                                                                exclusive
                                                                                                onChange={handleChange}
                                                                                                aria-label="Platform"
                                                                                            >
                                                                                                <ToggleButton value="FINAL">Final Submission</ToggleButton>
                                                                                                <ToggleButton value="HISTORY">Submission History</ToggleButton>

                                                                                            </ToggleButtonGroup>
                                                                                            {
                                                                                                alignment === "FINAL" ?


                                                                                                    <>
                                                                                                        {
                                                                                                            codingQuestionScoreDetails.finalSubmission === null ?
                                                                                                                <>
                                                                                                                    <div className="title-md">User Not submitted</div>
                                                                                                                    <div style={{ width: "160px", textAlign: "center", textDecoration: "none", background: "rgb(73,22,126) ", padding: "15px 20px", marginLeft: "240px", marginBottom: "50px", marginTop: "40px", border: "1px solid rgb(73,22,126)", borderRadius: "10px" }}>

                                                                                                                        <button style={{ color: "#fff", fontSize: "15px" }} onClick={() => navigate("/training/training-details/labs", {
                                                                                                                            state: {
                                                                                                                                labDescription, labOverview, labSolution, labId, contentSid, trainingSid, labDuration, showcoursename, type, codingQuestionId, codingQuestiondesc, evaluatedLab
                                                                                                                            }
                                                                                                                        })
                                                                                                                        }>Open Sandbox</button>

                                                                                                                    </div>
                                                                                                                </>

                                                                                                                :
                                                                                                                <>
                                                                                                                    <div className='row mt-3'>

                                                                                                                        <div className='col-12 col-sm-6 col-md-2'>
                                                                                                                            <div className='card h-100 pt-2 border' style={{ background: "#C8A3DF", borderRadius: "10px" }}>
                                                                                                                                <div className='card-text title-sm text-center' >Fail Test Cases</div>
                                                                                                                                <div className='title-md text-center'>
                                                                                                                                    {codingQuestionScoreDetails.finalSubmission.failed}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className='col-12 col-sm-6 col-md-2'>
                                                                                                                            <div className='card h-100 pt-2 border' style={{ background: " #BFCBF7", borderRadius: "10px" }}>
                                                                                                                                <div className='card-text title-sm text-center'>Pass Test Cases</div>
                                                                                                                                <div className='title-md text-center'>
                                                                                                                                    {codingQuestionScoreDetails.finalSubmission.passed}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className='col-12 col-sm-6 col-md-2'>
                                                                                                                            <div className='card h-100 pt-2 border' style={{ background: "#D0EFFA", borderRadius: "10px" }}>
                                                                                                                                <div className='card-text title-sm text-center' >Percentage</div>
                                                                                                                                <div className='title-md text-center'>
                                                                                                                                    {`${codingQuestionScoreDetails.finalSubmission.percentage}%`}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className='col-12 col-sm-6 col-md-2'>
                                                                                                                            <div className='card h-100 pt-2 border' style={{ background: "#C8A3DF", borderRadius: "10px" }}>
                                                                                                                                <div className='card-text title-sm text-center' >Date</div>
                                                                                                                                <div className='title-md text-center'>
                                                                                                                                    {codingQuestionScoreDetails.finalSubmission.submittedOn.substring(0, 10)}
                                                                                                                                    {/* {codingQuestionScoreDetails.finalSubmission.cpuTime} */}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className='col-12 col-sm-6 col-md-2'>
                                                                                                                            <div className='card h-100 pt-2 border' style={{ background: " #BFCBF7", borderRadius: "10px" }}>
                                                                                                                                <div className='card-text title-sm text-center' >Language</div>
                                                                                                                                <div className='title-md text-center'>
                                                                                                                                    {codingQuestionScoreDetails.finalSubmission.languageCode}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className='col-12 col-sm-6 col-md-2'>
                                                                                                                            <div className='card h-100 pt-2 border' style={{ background: " #D0EFFA", borderRadius: "10px" }}>
                                                                                                                                <div className='card-text title-sm text-center' >CPU Time</div>
                                                                                                                                <div className='title-md text-center'>


                                                                                                                                    {codingQuestionScoreDetails.finalSubmission.cpuTime}
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>

                                                                                                                    </div>
                                                                                                                    <div style={{ width: "120px", textAlign: "center", textDecoration: "none", background: "rgb(73,22,126) ", padding: "12px 10px", marginLeft: "240px", marginTop: "40px", marginBottom: "20px", border: "1px solid rgb(73,22,126)", borderRadius: "10px" }}>

                                                                                                                        <button style={{ color: "#fff", fontSize: "12px" }} onClick={toggleCodeVisibility}> {isCollapsed ? "Show Code" : "Hide Code"}</button>

                                                                                                                    </div>
                                                                                                                    {!isCollapsed && (
                                                                                                                        <div style={{ backgroundColor: "#000" }}>
                                                                                                                            <pre>
                                                                                                                                <code>
                                                                                                                                    <div style={{ color: "#fff", margin: "10px" }}>
                                                                                                                                        {codingQuestionScoreDetails.finalSubmission.userCode}
                                                                                                                                    </div>
                                                                                                                                </code>
                                                                                                                            </pre>
                                                                                                                        </div>

                                                                                                                    )}
                                                                                                                </>
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    <>
                                                                                                        {codingQuestionScoreDetails.submissionHistory === null ?
                                                                                                            <div className="title-md">Nothing to show</div>
                                                                                                            :
                                                                                                            <DynamicTable {...{ configuration: configuration1, sourceData: codingQuestionScoreDetails.submissionHistory }} />
                                                                                                        }
                                                                                                    </>

                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </>

                                                                        }

                                                                    </div>
                                                                    :
                                                                    <LockedContent />
                                                                :
                                                                (type === "CODING_ASSESSMENT") ?
                                                                    unlocked ?

                                                                        <>
                                                                            {
                                                                                (user.role === ROLE.INSTRUCTOR) ?
                                                                                    codingAssessmentLoading ?
                                                                                        <SkeletonLoader skeletonCount={5.5} />
                                                                                        :
                                                                                        <div className="showcontentdiv row mx-0 " style={{ display: "flex", flexDirection: "column" }}>
                                                                                            <div className="coding-assessment-session-parrent">
                                                                                                <div className="assessment-session-header">
                                                                                                    {/* <div className="title-md">{cloudLabEvaluationLab["Lab Name"]}</div> */}
                                                                                                    {showcoursename}
                                                                                                </div>

                                                                                                <div className="row" style={{ width: "100%" }}>

                                                                                                    <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                        <div className="assessment-session-card">
                                                                                                            <div className="assessment-session-card-title">
                                                                                                                Batch Count
                                                                                                            </div>

                                                                                                            <div className="assessment-session-card-score">

                                                                                                                {submissionDetailsForTrainer?.batchCount}
                                                                                                            </div>


                                                                                                        </div>

                                                                                                    </div>


                                                                                                    <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                        <div className="assessment-session-card">
                                                                                                            <div className="assessment-session-card-title">
                                                                                                                Submissions
                                                                                                            </div>

                                                                                                            <div className="assessment-session-card-score">
                                                                                                                {submissionDetailsForTrainer?.submissionsReceived}
                                                                                                            </div>


                                                                                                        </div>

                                                                                                    </div>

                                                                                                    <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                        <div className="assessment-session-card">
                                                                                                            <div className="assessment-session-card-title">
                                                                                                                Avg Batch (%)
                                                                                                            </div>

                                                                                                            <div className="assessment-session-card-score">
                                                                                                                {`${submissionDetailsForTrainer?.averageBatchPercentage}`}

                                                                                                            </div>


                                                                                                        </div>

                                                                                                    </div>

                                                                                                </div>


                                                                                                <div className="coding-assessment-task-parrent">
                                                                                                    <DynamicTable {...{ configuration: configuration4, sourceData: submissionDetailsForTrainer?.submissionDetails }} />
                                                                                                </div>




                                                                                            </div>
                                                                                        </div>
                                                                                    :
                                                                                    (userCodingAssessmentSubmissionDetails === null && completeContent === false) ?


                                                                                        <div className="showcontentdiv row mx-0 " style={{ display: "flex", flexDirection: "column" }}>
                                                                                            <div className="coding-assessment-session-parrent">
                                                                                                <div className="assessment-session-header"> {showcoursename}</div>
                                                                                                <div className='row  ' style={{ width: "100%" }}>


                                                                                                    <div className='col-lg-4 col-md-4 col-md-4'>


                                                                                                        <div className="assessment-session-card h-100">
                                                                                                            <div className="assessment-session-card-title">
                                                                                                                Duration
                                                                                                            </div>
                                                                                                            <div className="assessment-session-card-score">
                                                                                                                {`${codingAssessmentDetails?.duration - codingAssessmentTimeOverInSeconds} mins`}
                                                                                                            </div>

                                                                                                        </div>




                                                                                                    </div>

                                                                                                    <div className='col-lg-4 col-md-4 col-md-4'>

                                                                                                        <div className="assessment-session-card h-100">
                                                                                                            <div className="assessment-session-card-title">
                                                                                                                Total Question
                                                                                                            </div>
                                                                                                            <div className="assessment-session-card-score">
                                                                                                                {codingAssessmentDetails?.questionCount}
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='col-lg-4 col-md-4 col-md-4'>


                                                                                                        <div className="assessment-session-card h-100">
                                                                                                            <div className="assessment-session-card-title">
                                                                                                                Total Marks
                                                                                                            </div>
                                                                                                            <div className="assessment-session-card-score">
                                                                                                                {codingAssessmentDetails?.totalMarks}
                                                                                                            </div>

                                                                                                        </div>


                                                                                                    </div>
                                                                                                </div>
                                                                                                {
                                                                                                    assessmentTimeline === null ?
                                                                                                        <div className="d-flex">

                                                                                                            <div className=" assessment-session-header">
                                                                                                                Start Time & Date -
                                                                                                            </div>

                                                                                                            <div className=" assessment-session-header mx-3">

                                                                                                                {codingAssessmentDetails?.startTime !== null ? codingAssessmentDetails.startTime : codingAssessmentStartTime} ,
                                                                                                                <span className="mx-2">  {codingAssessmentDate}</span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                        <>
                                                                                                            <div className="d-flex mb-3">

                                                                                                                <div className=" assessment-session-header">
                                                                                                                    Start Date -
                                                                                                                </div>

                                                                                                                <div className=" assessment-session-header mx-3">
                                                                                                                    {assessmentTimeline?.startDate},

                                                                                                                    <span className="mx-1">  {assessmentTimeline?.startTime}</span>
                                                                                                                    <span className="mx-1">  {assessmentTimeline?.timeZone}</span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="d-flex mb-3">
                                                                                                                <div className=" assessment-session-header">
                                                                                                                    End Date -
                                                                                                                </div>

                                                                                                                <div className=" assessment-session-header mx-3">
                                                                                                                    {assessmentTimeline?.endDate},

                                                                                                                    <span className="mx-1">  {assessmentTimeline?.endTime}</span>
                                                                                                                    <span className="mx-1">  {assessmentTimeline?.timeZone}</span>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                }



                                                                                                <div style={{ width: "auto", textAlign: "center", textDecoration: "none", background: "#7F56D9", padding: "16px 28px", margin: "auto", border: "1px solid #7F56D9", borderRadius: "8px" }}>
                                                                                                    {
                                                                                                        assessmentTimeline !== null ?
                                                                                                            <>
                                                                                                                {
                                                                                                                    message === 'NOT_EXPIRED' ?

                                                                                                                        <button style={{ color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px", textAlign: "center" }}

                                                                                                                            onClick={() => navigate("/coding", {
                                                                                                                                state: {
                                                                                                                                    sectionSid: contentSid, trainingSid, showcoursename, codingAssessmentDetails, type, codingAssessmentTimeOverInSeconds
                                                                                                                                    , codingAssessmentCheatingDetails
                                                                                                                                }
                                                                                                                            })
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <span style={{ marginRight: "10px" }}>{ICN_START_ASSESS}</span>
                                                                                                                            {codingAssessmentTimeOverInSeconds > 0 ? 'Resume Assessment' : 'Start Assessment'}

                                                                                                                        </button>
                                                                                                                        :
                                                                                                                        message === 'NOT_STARTED' ?
                                                                                                                            <button style={{ color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px", opacity: '0.6', cursor: 'not-allowed' }}>
                                                                                                                                Not Started {ICN_START_ASSESS}
                                                                                                                            </button>
                                                                                                                            :
                                                                                                                            message === 'EXPIRED' &&
                                                                                                                            <button style={{ color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px", opacity: '0.6', cursor: 'not-allowed' }}>
                                                                                                                                Assessment Over {ICN_START_ASSESS}
                                                                                                                            </button>
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            startCodingAssessment ?

                                                                                                                <button style={{ color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px", textAlign: "center" }}
                                                                                                                    // onClick={() => navigate("/labs", {
                                                                                                                    //     state: {
                                                                                                                    //         labDescription, labOverview, labSolution, labId, contentSid, trainingSid, labDuration, showcoursename, type, evaluatedLab, codingAssessmentDetails
                                                                                                                    //     }
                                                                                                                    // })
                                                                                                                    // }
                                                                                                                    onClick={() => navigate("/coding", {
                                                                                                                        state: {
                                                                                                                            sectionSid: contentSid, trainingSid, showcoursename, codingAssessmentDetails, type, codingAssessmentTimeOverInSeconds
                                                                                                                            , codingAssessmentCheatingDetails
                                                                                                                        }
                                                                                                                    })
                                                                                                                    }
                                                                                                                >
                                                                                                                    <span style={{ marginRight: "10px" }}>{ICN_START_ASSESS}</span>
                                                                                                                    {codingAssessmentTimeOverInSeconds > 0 ? 'Resume Assessment' : 'Start Assessment'}

                                                                                                                </button>
                                                                                                                :
                                                                                                                <button style={{ color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px", opacity: '0.6', cursor: 'not-allowed' }}>
                                                                                                                    {ICN_START_ASSESS} {codingAssessmentAllover}
                                                                                                                </button>
                                                                                                    }

                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        :
                                                                                        <>
                                                                                            <div className="showcontentdiv row mx-0 " style={{ display: "flex", flexDirection: "column" }}>

                                                                                                {showCodingAssessmentResult ?

                                                                                                    <div className="coding-assessment-session-parrent">
                                                                                                        <div className="assessment-session-header">
                                                                                                            {/* <div className="title-md">{cloudLabEvaluationLab["Lab Name"]}</div> */}
                                                                                                            {showcoursename}
                                                                                                        </div>

                                                                                                        <div className="row" style={{ width: "100%" }}>

                                                                                                            <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                                <div className="assessment-session-card">
                                                                                                                    <div className="assessment-session-card-title">
                                                                                                                        Total marks
                                                                                                                    </div>
                                                                                                                    <div className="assessment-session-card-score">

                                                                                                                        {userCodingAssessmentSubmissionDetails.totalMarks}
                                                                                                                    </div>

                                                                                                                </div>

                                                                                                            </div>


                                                                                                            <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                                <div className="assessment-session-card">
                                                                                                                    <div className="assessment-session-card-title">
                                                                                                                        Marks Scored
                                                                                                                    </div>
                                                                                                                    <div className="assessment-session-card-score">
                                                                                                                        {userCodingAssessmentSubmissionDetails.gainedMarks}
                                                                                                                    </div>

                                                                                                                </div>

                                                                                                            </div>

                                                                                                            <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                                <div className="assessment-session-card">
                                                                                                                    <div className="assessment-session-card-title">
                                                                                                                        Percentage
                                                                                                                    </div>
                                                                                                                    <div className="assessment-session-card-score">
                                                                                                                        {`${userCodingAssessmentSubmissionDetails.percentage.toFixed(2)}%`}

                                                                                                                    </div>

                                                                                                                </div>

                                                                                                            </div>

                                                                                                        </div>




                                                                                                        <div className="coding-assessment-task-parrent">
                                                                                                            <DynamicTable {...{ configuration: configuration2, sourceData: userCodingAssessmentSubmissionDetails.questionDetails }} />

                                                                                                        </div>

                                                                                                    </div>
                                                                                                    :
                                                                                                    <div className="coding-assessment-session-parrent m-auto">
                                                                                                        <div className="assessment-session-header">
                                                                                                            {/* <div className="title-md">{cloudLabEvaluationLab["Lab Name"]}</div> */}
                                                                                                            {showcoursename}
                                                                                                        </div>

                                                                                                        <div className="contentandresources-subtitle">
                                                                                                            Result will be shared by admin
                                                                                                        </div>

                                                                                                    </div>
                                                                                                }

                                                                                            </div>



                                                                                        </>
                                                                            }
                                                                        </>
                                                                        :
                                                                        <LockedContent />
                                                                    :
                                                                    (type === "ASSESSMENT") ?
                                                                        unlocked ?
                                                                            (user.role === ROLE.INSTRUCTOR) ?
                                                                                codingAssessmentLoading ?
                                                                                    <SkeletonLoader skeletonCount={5.5} />
                                                                                    :
                                                                                    <div className="showcontentdiv row mx-0 " style={{ display: "flex", flexDirection: "column" }}>
                                                                                        <div className="coding-assessment-session-parrent">
                                                                                            <div className="assessment-session-header">
                                                                                                {/* <div className="title-md">{cloudLabEvaluationLab["Lab Name"]}</div> */}
                                                                                                {showcoursename}
                                                                                            </div>

                                                                                            <div className="row" style={{ width: "100%" }}>

                                                                                                <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                    <div className="assessment-session-card">
                                                                                                        <div className="assessment-session-card-title">
                                                                                                            Batch Count
                                                                                                        </div>

                                                                                                        <div className="assessment-session-card-score">

                                                                                                            {submissionDetailsForTrainer?.batchCount}
                                                                                                        </div>


                                                                                                    </div>

                                                                                                </div>


                                                                                                <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                    <div className="assessment-session-card">
                                                                                                        <div className="assessment-session-card-title">
                                                                                                            Submissions
                                                                                                        </div>

                                                                                                        <div className="assessment-session-card-score">
                                                                                                            {submissionDetailsForTrainer?.submissionsReceived}
                                                                                                        </div>


                                                                                                    </div>

                                                                                                </div>

                                                                                                <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                    <div className="assessment-session-card">
                                                                                                        <div className="assessment-session-card-title">
                                                                                                            Avg Batch (%)
                                                                                                        </div>

                                                                                                        <div className="assessment-session-card-score">
                                                                                                            {`${submissionDetailsForTrainer?.averageBatchPercentage}`}

                                                                                                        </div>


                                                                                                    </div>

                                                                                                </div>

                                                                                            </div>


                                                                                            <div className="coding-assessment-task-parrent">
                                                                                                <DynamicTable {...{ configuration: configuration4, sourceData: submissionDetailsForTrainer?.submissionDetails }} />
                                                                                            </div>




                                                                                        </div>
                                                                                    </div>
                                                                                :
                                                                                <div className="showcontentdiv row mx-0 " style={{ display: "flex", flexDirection: "column" }} >
                                                                                    {
                                                                                        completeContent ?
                                                                                            showMCQAssessmentResult ?

                                                                                                <div className="coding-assessment-session-parrent">
                                                                                                    <div className="assessment-session-header">
                                                                                                        {/* <div className="title-md">{cloudLabEvaluationLab["Lab Name"]}</div> */}
                                                                                                        {showcoursename}
                                                                                                    </div>

                                                                                                    <div className="row" style={{ width: "100%" }}>

                                                                                                        <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                            <div className="assessment-session-card">
                                                                                                                <div className="assessment-session-card-title">
                                                                                                                    Total marks
                                                                                                                </div>
                                                                                                                <div className="assessment-session-card-score">

                                                                                                                    {mcqSubmissionDetails.totalMarksScored}
                                                                                                                </div>

                                                                                                            </div>

                                                                                                        </div>


                                                                                                        <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                            <div className="assessment-session-card">
                                                                                                                <div className="assessment-session-card-title">
                                                                                                                    Rank
                                                                                                                </div>
                                                                                                                <div className="assessment-session-card-score">
                                                                                                                    {mcqSubmissionDetails.rank}
                                                                                                                </div>

                                                                                                            </div>

                                                                                                        </div>

                                                                                                        <div className="col-md-4 col-lg-4 col-xl-4">

                                                                                                            <div className="assessment-session-card">
                                                                                                                <div className="assessment-session-card-title">
                                                                                                                    Percentage
                                                                                                                </div>
                                                                                                                <div className="assessment-session-card-score">
                                                                                                                    {`${mcqSubmissionDetails.percentage.toFixed(2)}%`}

                                                                                                                </div>

                                                                                                            </div>

                                                                                                        </div>

                                                                                                    </div>




                                                                                                    <div className="coding-assessment-task-parrent">
                                                                                                        <DynamicTable {...{ configuration: configuration3, sourceData: mcqSubmissionDetails.questionSubmissionDetails }} />

                                                                                                    </div>

                                                                                                </div>
                                                                                                :
                                                                                                <div className="coding-assessment-session-parrent m-auto">
                                                                                                    <div className="assessment-session-header">
                                                                                                        {/* <div className="title-md">{cloudLabEvaluationLab["Lab Name"]}</div> */}
                                                                                                        {showcoursename}
                                                                                                    </div>

                                                                                                    <div className="contentandresources-subtitle">
                                                                                                        Result will be shared by admin
                                                                                                    </div>

                                                                                                </div>

                                                                                            :
                                                                                            <div className="coding-assessment-session-parrent m-auto">
                                                                                                {
                                                                                                    assessmentTimeline !== null ?
                                                                                                        <>

                                                                                                            <div className="d-flex mb-3">

                                                                                                                <div className=" assessment-session-header">
                                                                                                                    Start Date -
                                                                                                                </div>

                                                                                                                <div className=" assessment-session-header mx-3">
                                                                                                                    {assessmentTimeline?.startDate},

                                                                                                                    <span className="mx-1">  {assessmentTimeline?.startTime}</span>
                                                                                                                    <span className="mx-1">  {assessmentTimeline?.timeZone}</span>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="d-flex mb-3">
                                                                                                                <div className=" assessment-session-header">
                                                                                                                    End Date -
                                                                                                                </div>

                                                                                                                <div className=" assessment-session-header mx-3">
                                                                                                                    {assessmentTimeline?.endDate},

                                                                                                                    <span className="mx-1">  {assessmentTimeline?.endTime}</span>
                                                                                                                    <span className="mx-1">  {assessmentTimeline?.timeZone}</span>
                                                                                                                </div>
                                                                                                            </div>


                                                                                                            <div style={{ width: "auto", textAlign: "center", textDecoration: "none", padding: "16px 28px", margin: "auto", }}>
                                                                                                                {/* <a href={vdlink} target="_blank" rel="noopener noreferrer" style={{ color: "#fff", fontSize: "15px" }}>Start Assessment</a> */}


                                                                                                                {
                                                                                                                    message === 'NOT_EXPIRED' ?
                                                                                                                        urlBasedAssessment ?
                                                                                                                            <a href={assessmentUrl} target="_blank" rel="noopener noreferrer" style={{ color: "#fff", fontSize: "15px", borderRadius: "8px", width: "auto", textAlign: "center", textDecoration: "none", background: "#7F56D9", padding: "16px 28px", color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px", textAlign: "center" }}>Start Assessment</a>
                                                                                                                            :
                                                                                                                            <button style={{ borderRadius: "8px", width: "auto", textAlign: "center", textDecoration: "none", background: "#7F56D9", padding: "16px 28px", color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px", textAlign: "center" }}
                                                                                                                                onClick={() => navigate(`/assessment/${vdlink.split('/')[4]}/${vdlink.split('/')[5]}/${vdlink.split('/')[6]}`)}>
                                                                                                                                <span style={{ marginRight: "10px" }}>{ICN_START_ASSESS}</span> Start Assessment
                                                                                                                            </button>
                                                                                                                        :
                                                                                                                        message === 'NOT_STARTED' ?
                                                                                                                            <button style={{
                                                                                                                                borderRadius: "8px", width: "auto", textAlign: "center", textDecoration: "none",
                                                                                                                                background: "#7F56D9", padding: "16px 28px", color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px", textAlign: "center",
                                                                                                                                cursor: 'not-allowed', opacity: '0.6'
                                                                                                                            }}
                                                                                                                            >
                                                                                                                                <span style={{ marginRight: "10px" }}>{ICN_START_ASSESS}</span> Not Started Yet
                                                                                                                            </button>
                                                                                                                            :
                                                                                                                            message === 'EXPIRED' &&
                                                                                                                            <button style={{
                                                                                                                                borderRadius: "8px", width: "auto", textAlign: "center", textDecoration: "none",
                                                                                                                                background: "#7F56D9", padding: "16px 28px", color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px", textAlign: "center",
                                                                                                                                cursor: 'not-allowed', opacity: '0.6'
                                                                                                                            }}
                                                                                                                            >
                                                                                                                                <span style={{ marginRight: "10px" }}>{ICN_START_ASSESS}</span> Assessment Over
                                                                                                                            </button>
                                                                                                                }


                                                                                                            </div>
                                                                                                        </>

                                                                                                        :
                                                                                                        <div style={{ width: "auto", textAlign: "center", textDecoration: "none", padding: "16px 28px", margin: "auto", }}>
                                                                                                            {/* <a href={vdlink} target="_blank" rel="noopener noreferrer" style={{ color: "#fff", fontSize: "15px" }}>Start Assessment</a> */}


                                                                                                            {urlBasedAssessment ?
                                                                                                                <a href={assessmentUrl} target="_blank" rel="noopener noreferrer" style={{ color: "#fff", fontSize: "15px", borderRadius: "8px", width: "auto", textAlign: "center", textDecoration: "none", background: "#7F56D9", padding: "16px 28px", color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px", textAlign: "center" }}>Start Assessment</a>
                                                                                                                :
                                                                                                                <button style={{ borderRadius: "8px", width: "auto", textAlign: "center", textDecoration: "none", background: "#7F56D9", padding: "16px 28px", color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px", textAlign: "center" }}
                                                                                                                    onClick={() => navigate(`/assessment/${vdlink.split('/')[4]}/${vdlink.split('/')[5]}/${vdlink.split('/')[6]}`)}>
                                                                                                                    <span style={{ marginRight: "10px" }}>{ICN_START_ASSESS}</span> Start Assessment
                                                                                                                </button>
                                                                                                            }



                                                                                                        </div>

                                                                                                }




                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                            :
                                                                            <LockedContent />
                                                                        :
                                                                        (type === "TRAINING_SESSION") ?
                                                                            unlocked ?
                                                                                <>
                                                                                    {meetingRecordingLink.length > 0 ?
                                                                                        <div className="showcontentdiv row mx-0">
                                                                                            <div className="mt-auto training-session-parrent ">
                                                                                                <div className="training-session-header">
                                                                                                    Session Recording
                                                                                                </div>
                                                                                                <div className="training-session-newsub-header">
                                                                                                    This session starts on {`${sessionDate}, ${sessionStartTime}`} and ends

                                                                                                    <div >
                                                                                                        on {sessionEndTime}.

                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="training-session-newsub-header"> Click to view recordings!</div>
                                                                                                <div className="mt-3 mb-3" style={{ width: "100%", textAlign: "center", textDecoration: "none", background: "#7F56D9", padding: "10px 18px", border: "1px solid #7F56D9", borderRadius: "8px" }}>
                                                                                                    <a href={meetingRecordingLink} target="_blank" rel="noopener noreferrer" style={{ width: "100%", color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px" }}>View Recordings</a>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        :

                                                                                        <div className="showcontentdiv row mx-0">
                                                                                            <div className="mt-auto training-session-parrent ">
                                                                                                <div className="d-flex" >
                                                                                                    <div className="training-session-header">
                                                                                                        {
                                                                                                            currentDate > sessionDate ?
                                                                                                                "Live Session Ended"
                                                                                                                :
                                                                                                                currentDate < sessionDate ?
                                                                                                                    "Upcoming Live Session"
                                                                                                                    :
                                                                                                                    currentDate === sessionDate &&
                                                                                                                        currentMinutesTrainingSession >= startMinutesTrainingSession && currentMinutesTrainingSession <= endMinutesTrainingSession ?
                                                                                                                        <>
                                                                                                                            Session is Live {ICN_LIVE}

                                                                                                                        </>
                                                                                                                        :
                                                                                                                        currentMinutesTrainingSession < startMinutesTrainingSession ?
                                                                                                                            "Upcoming Live Session"
                                                                                                                            :
                                                                                                                            currentMinutesTrainingSession > endMinutesTrainingSession &&
                                                                                                                            "Live Session Ended"



                                                                                                        }

                                                                                                    </div>
                                                                                                    <Tooltip
                                                                                                        id="notestooltip"
                                                                                                        place='left'

                                                                                                    />
                                                                                                    {currentDate < sessionDate ?
                                                                                                        <div className="normalbtn " data-tooltip-id="notestooltip" data-tooltip-content="Add to your Calendar for easy access" style={{ cursor: "pointer", margin: "2px 0px 2px 80px" }} onClick={() => addSessionToGoogleCalendar()} >
                                                                                                            {CALENDAR_ICON}

                                                                                                            {loading ? (
                                                                                                                <>
                                                                                                                    <Spinner
                                                                                                                        as="span"
                                                                                                                        animation="border"
                                                                                                                        size="md"
                                                                                                                        role="status"
                                                                                                                        aria-hidden="true"
                                                                                                                        className="mx-2 mt-1 loader-button" // Add margin to separate spinner from text
                                                                                                                        style={{ border: '2px dotted #101828' }}
                                                                                                                    />

                                                                                                                </>
                                                                                                            ) :
                                                                                                                ""}



                                                                                                        </div>
                                                                                                        :
                                                                                                        (currentDate === sessionDate) &&
                                                                                                        (currentMinutesTrainingSession < startMinutesTrainingSession) &&
                                                                                                        <div className="normalbtn " data-tooltip-id="notestooltip" data-tooltip-content="Add to your Calendar for easy access" style={{ cursor: "pointer", margin: "2px 0px 2px 80px" }} onClick={() => addSessionToGoogleCalendar()} >
                                                                                                            {CALENDAR_ICON}

                                                                                                            {loading ? (
                                                                                                                <>
                                                                                                                    <Spinner
                                                                                                                        as="span"
                                                                                                                        animation="border"
                                                                                                                        size="md"
                                                                                                                        role="status"
                                                                                                                        aria-hidden="true"
                                                                                                                        className="mx-2 mt-1 loader-button" // Add margin to separate spinner from text
                                                                                                                        style={{ border: '2px dotted #101828' }}
                                                                                                                    />

                                                                                                                </>
                                                                                                            ) :
                                                                                                                ""}



                                                                                                        </div>
                                                                                                    }

                                                                                                </div>
                                                                                                <div className="training-session-newsub-header">
                                                                                                    {
                                                                                                        currentDate > sessionDate ?
                                                                                                            <>
                                                                                                                This session has already ended at {sessionDate}  ,


                                                                                                                < div >
                                                                                                                    {sessionEndTime}

                                                                                                                </div>
                                                                                                            </>
                                                                                                            :
                                                                                                            currentDate < sessionDate ?
                                                                                                                <>
                                                                                                                    The session will start at the {`${sessionDate}, ${sessionStartTime}`} and


                                                                                                                    < div >
                                                                                                                        conclude at {sessionEndTime}

                                                                                                                    </div>
                                                                                                                </>
                                                                                                                :
                                                                                                                currentDate === sessionDate &&
                                                                                                                <>
                                                                                                                    The session started at {`${sessionDate}, ${sessionStartTime}`} and


                                                                                                                    < div >
                                                                                                                        is going to end at {sessionEndTime}

                                                                                                                    </div>
                                                                                                                </>
                                                                                                    }

                                                                                                </div>


                                                                                                {
                                                                                                    currentDate > sessionDate ?
                                                                                                        <div className="mt-3 mb-3" style={{ width: "100%", textAlign: "center", textDecoration: "none", background: "#7F56D9", padding: "10px 18px", border: "1px solid #7F56D9", borderRadius: "8px", opacity: 0.5, cursor: "not-allowed" }}>
                                                                                                            <button style={{ width: "100%", color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px" }} disabled>{ICN_STRAT_SESSION} Training Session Over</button>
                                                                                                        </div>
                                                                                                        :
                                                                                                        currentDate < sessionDate ?
                                                                                                            <>
                                                                                                                {/* <div className="training-session-newsub-header  d-flex" style={{ width:"100%",cursor: "pointer", alignItems: "center" , background: "#7F56D9", padding: "10px 18px", border: "1px solid #7F56D9", borderRadius: "8px"}} onClick={() => addSessionToGoogleCalendar()} > <div style={{ marginRight: '5px', marginTop: "-5px" }} >{CALENDAR_ICON}</div>
                                                                                                                <button style={{color: "#fff", fontSize: "18px", margin:"auto"}}>
                                                                                                                {loading ? (
                                                                                                                    <>
                                                                                                                        <Spinner
                                                                                                                            as="span"
                                                                                                                            animation="border"
                                                                                                                            size="md"
                                                                                                                            role="status"
                                                                                                                            aria-hidden="true"
                                                                                                                            className="mr-2 loader-button" // Add margin to separate spinner from text
                                                                                                                            style={{ border: '5px dotted #fff',width:"20px", height:"20px"}}
                                                                                                                        />
                                                                                                                        {''}
                                                                                                                      
                                                                                                                      
                                                                                                                    </>
                                                                                                                ) :
                                                                                                                "Add to your Calendar for easy access"} </button></div>*/}
                                                                                                                <div className="mt-3 mb-3" style={{ cursor: "pointer", width: "100%", textAlign: "center", textDecoration: "none", background: "#7F56D9", padding: "10px 18px", border: "1px solid #7F56D9", borderRadius: "8px", opacity: 0.5, cursor: "not-allowed" }}>
                                                                                                                    <button style={{ width: "100%", color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px" }} disabled>{ICN_STRAT_SESSION} Training Session Not Started</button>
                                                                                                                </div>
                                                                                                            </>
                                                                                                            :
                                                                                                            currentDate === sessionDate &&

                                                                                                                currentMinutesTrainingSession >= startMinutesTrainingSession && currentMinutesTrainingSession <= endMinutesTrainingSession ?
                                                                                                                <div className="mt-3 mb-3" style={{ width: "100%", textAlign: "center", textDecoration: "none", background: "#7F56D9", padding: "10px 18px", border: "1px solid #7F56D9", borderRadius: "8px" }}>
                                                                                                                    <OpenLinkInNewTab to="/class" state={zoomInfo} style={{ width: "100%", color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px" }}>
                                                                                                                        {ICN_STRAT_SESSION} Join Training Session
                                                                                                                    </OpenLinkInNewTab>
                                                                                                                </div>
                                                                                                                :
                                                                                                                currentMinutesTrainingSession < startMinutesTrainingSession ?
                                                                                                                    <>
                                                                                                                        {/*<div className="training-session-newsub-header  d-flex" style={{ cursor: "pointer", alignItems: "center" , background: "#7F56D9", padding: "10px 18px", border: "1px solid #7F56D9", borderRadius: "8px"}} onClick={() => addSessionToGoogleCalendar()} > <div style={{ marginRight: '5px', marginTop: "-5px" }} >{CALENDAR_ICON}</div>
                                                                                                                <button style={{color: "#fff", fontSize: "18px", margin:"auto"}}>
                                                                                                                        {loading ? (
                                                                                                                            <>
                                                                                                                                <Spinner
                                                                                                                                    as="span"
                                                                                                                                    animation="border"
                                                                                                                                    size="md"
                                                                                                                                    role="status"
                                                                                                                                    aria-hidden="true"
                                                                                                                                    className="mr-2 loader-button" // Add margin to separate spinner from text
                                                                                                                                    style={{  border: '5px dotted #fff',width:"20px", height:"20px" }}
                                                                                                                                />
                                                                                                                                
                                                                                                                            </>
                                                                                                                        ) :
                                                                                                                        "Add to your Calendar for easy access"}</button></div>*/}
                                                                                                                        <div className="mt-3 mb-3" style={{ cursor: "pointer", width: "100%", textAlign: "center", textDecoration: "none", background: "#7F56D9", padding: "10px 18px", border: "1px solid #7F56D9", borderRadius: "8px", opacity: 0.5, cursor: "not-allowed" }}>
                                                                                                                            <button style={{ width: "100%", color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px" }} disabled>{ICN_STRAT_SESSION} Training Session Not Started</button>
                                                                                                                        </div>
                                                                                                                    </>
                                                                                                                    :
                                                                                                                    currentMinutesTrainingSession > endMinutesTrainingSession &&
                                                                                                                    <div className="mt-3 mb-3" style={{ width: "100%", textAlign: "center", textDecoration: "none", background: "#7F56D9", padding: "10px 18px", border: "1px solid #7F56D9", borderRadius: "8px", opacity: 0.5, cursor: "not-allowed" }}>
                                                                                                                        <button style={{ width: "100%", color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px" }} disabled>{ICN_STRAT_SESSION} Training Session Over</button>
                                                                                                                    </div>

                                                                                                }



                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <LockedContent />




                                                                            :
                                                                            (type === 'UPLOAD') ?
                                                                                unlocked ?
                                                                                    <div className='border m-2 p-2'>
                                                                                        <>
                                                                                            <div className='card'>
                                                                                                <div className='card-header title-md' style={{ background: "#F7F7F7", marginBottom: "0px" }}>
                                                                                                    Upload Resources
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className='row mx-1 mb-3'>
                                                                                                <div className="col  ">
                                                                                                    <label className="mb-2 label form-label">Asset Name</label>
                                                                                                    <input type="text" value={assetName} onChange={(e) => setAssetName(e.target.value)} className="form-control" />
                                                                                                </div>

                                                                                                <div className="col mt-4" >


                                                                                                    <input type="radio" name="Option" value="LINK" onChange={(e) => (setUploadasset(e.target.value))} id="LINK" defaultChecked />
                                                                                                    <label className="form-check-label mx-3 title-md">Insert Link</label>

                                                                                                    <input type="radio" name="Option" value="FILE" onChange={(e) => (setUploadasset(e.target.value))} id="FILE" />
                                                                                                    <label className=" form-check-label mx-3 title-md">Upload File</label>


                                                                                                </div>
                                                                                            </div>

                                                                                            <div className="row mx-1 mb-3">


                                                                                                <div className="col">

                                                                                                    {uploadasset === "FILE" && <>
                                                                                                        <label className="mb-2 label form-label">Upload</label>

                                                                                                        <input name="file" onChange={(e) => { setAssetResource(e.target.files[0]) }} type="file" className="form-control" />


                                                                                                    </>}
                                                                                                    {
                                                                                                        uploadasset === "LINK" && <>
                                                                                                            <label className="mb-2 label form-label">Asset Link</label>
                                                                                                            <input className="form-control" type="text" value={assetResource} onChange={(e) => setAssetResource(e.target.value)} />
                                                                                                        </>
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="col">
                                                                                                    <button className="btn btn-primary py-2 mt-4" type="submit" disabled={assetName.length < 5}
                                                                                                        onClick={() => addAssetToContent()} style={{ float: "right", width: "100%" }}>Confirm</button>
                                                                                                </div>

                                                                                            </div>
                                                                                        </>

                                                                                    </div>
                                                                                    :
                                                                                    <LockedContent />

                                                                                :
                                                                                (type === "VIEW") ?
                                                                                    unlocked ?
                                                                                        <div className="border m-2 p-2" >
                                                                                            <div className='card card border'>


                                                                                                <div className="card-body">
                                                                                                    <div className="card-header title-md"> Resources</div>
                                                                                                    {
                                                                                                        viewAsset.length > 0 ? viewAsset.map((asset) => {
                                                                                                            return (
                                                                                                                <>
                                                                                                                    {
                                                                                                                        asset.assetType === "DOCUMENTS" ?



                                                                                                                            <div className="continue px-2 ">
                                                                                                                                <a href={asset.assetLink} className="title-sm" >{asset.assetName}
                                                                                                                                    <FileDownloadIcon />

                                                                                                                                </a>
                                                                                                                            </div>
                                                                                                                            :
                                                                                                                            <div className="continue px-2">
                                                                                                                                <a href={asset.assetLink} target="_blank" rel="noopener noreferrer" className="title-sm" >{asset.assetName}
                                                                                                                                    <LinkIcon />

                                                                                                                                </a>
                                                                                                                            </div>

                                                                                                                    }
                                                                                                                </>
                                                                                                            )
                                                                                                        })


                                                                                                            :
                                                                                                            <div className="title-sm text-center">No Assets Found</div>
                                                                                                    }

                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                        <LockedContent />
                                                                                    :

                                                                                    (type === "PROJECT" && user.role === ROLE.LEARNER) ?
                                                                                        <div className="showcontentdiv row mx-0" style={{ display: "flex", flexDirection: "column" }} >
                                                                                            {


                                                                                                projectSubmissionResponse !== null ?
                                                                                                    <div className='assessment-session-parrent'>

                                                                                                        <div className="assessment-session-header"> Project</div>


                                                                                                        <>
                                                                                                            {projectSubmissionResponse.repoLink !== null &&



                                                                                                                <div className="continue p-2">
                                                                                                                    <a href={projectSubmissionResponse.repoLink} target="_blank" rel="noopener noreferrer" className="title-sm" >Repo Link
                                                                                                                        <LinkIcon />

                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {projectSubmissionResponse.zipLink !== null &&

                                                                                                                <div className="continue p-2 ">
                                                                                                                    <a href={projectSubmissionResponse.zipLink} className="title-sm" >Asset
                                                                                                                        <FileDownloadIcon />

                                                                                                                    </a>
                                                                                                                </div>
                                                                                                            }
                                                                                                            {
                                                                                                                projectScoreResponse !== null &&
                                                                                                                <div className='row mt-3 mb-3' style={{ width: "100%" }}>
                                                                                                                    <div className=' col-md-6'>



                                                                                                                        <div className="assessment-session-card">
                                                                                                                            <div className="assessment-session-card-title">
                                                                                                                                Percentage
                                                                                                                            </div>
                                                                                                                            <div className="assessment-session-card-score">
                                                                                                                                {`${projectScoreResponse.percentage.toFixed(2)}%`}

                                                                                                                            </div>

                                                                                                                        </div>
                                                                                                                    </div>













                                                                                                                    <div className=' col-md-6'>


                                                                                                                        <div className="assessment-session-card">
                                                                                                                            <div className="assessment-session-card-title">
                                                                                                                                Scoring Type
                                                                                                                            </div>
                                                                                                                            <div className="assessment-session-card-score">
                                                                                                                                {projectScoreResponse.groupScoring ? "Group"
                                                                                                                                    : "Individual"}

                                                                                                                            </div>

                                                                                                                        </div>


                                                                                                                    </div>

                                                                                                                </div>
                                                                                                            }
                                                                                                        </>

                                                                                                    </div>

                                                                                                    :
                                                                                                    groupProjectDetails !== null &&
                                                                                                        currentDate >= groupProjectDetails?.startDate.substring(0, 10) && currentDate <= groupProjectDetails?.endDate.substring(0, 10)
                                                                                                        ?

                                                                                                        <div style={{ width: "auto", textAlign: "center", textDecoration: "none", background: "#7F56D9", padding: "16px 28px", margin: "auto", border: "1px solid #7F56D9", borderRadius: "8px" }}>


                                                                                                            <button style={{ color: "#fff", fontSize: "18px", fontWeight: "600", lineHeight: "28px", textAlign: "center" }}
                                                                                                                onClick={() => navigate("/training/training-details/labs", {
                                                                                                                    state: {
                                                                                                                        showcoursename: projectName, labDescription: projectDescription, durationInDays, contentSid, trainingSid, type, projectId
                                                                                                                    }
                                                                                                                })
                                                                                                                }
                                                                                                            >Start Project</button>


                                                                                                        </div>
                                                                                                        :
                                                                                                        groupProjectDetails !== null && currentDate > groupProjectDetails?.endDate.substring(0, 10) ?

                                                                                                            <div className="showcontentdiv row mx-0" style={{ display: "flex", flexDirection: "column" }} >

                                                                                                                <div className="mt-auto training-session-parrent text-center p-5 ">
                                                                                                                    <div className="training-session-header">

                                                                                                                        <span><LockIcon /></span>

                                                                                                                        Group project Submission ended on

                                                                                                                        <div>{groupProjectDetails?.endDate.substring(0, 10)}</div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                            groupProjectDetails !== null && currentDate < groupProjectDetails?.startDate.substring(0, 10) &&
                                                                                                            <div className="showcontentdiv row mx-0" style={{ display: "flex", flexDirection: "column" }} >

                                                                                                                <div className="mt-auto training-session-parrent text-center p-5 ">
                                                                                                                    <div className="training-session-header">

                                                                                                                        <span><LockIcon /></span>

                                                                                                                        Group project Submission starts on

                                                                                                                        <div>{groupProjectDetails?.startDate.substring(0, 10)}</div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>


                                                                                            }

                                                                                        </div>
                                                                                        :

                                                                                        type === "PROJECT" && (user.role === ROLE.SUPERVISOR || user.role === ROLE.INSTRUCTOR) ?
                                                                                            <div className="showcontentdiv mx-0" style={{ display: "flex", flexDirection: "column" }}>
                                                                                                <TrainingObjective
                                                                                                    showcoursename={showcoursename}
                                                                                                    trainingSid={trainingSid}
                                                                                                    projectId={projectId}
                                                                                                    sectionSid={contentSid} />
                                                                                            </div>

                                                                                            :
                                                                                            ''

                                    }

                                    <div class="tabset mt-2 mx-0" style={{ background: "#F9FAFB", borderRadius: "8px", border: "1px solid #EAECF0", boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)", ...stylescontent }}>

                                        <input
                                            type="radio"
                                            name="tabset"
                                            id="tab1"
                                            aria-controls="marzen"
                                            checked={activeTab === 'marzen'}
                                            onChange={() => handleTabChange('marzen')}
                                        />
                                        <label htmlFor="tab1" style={{ marginBottom: "0px" }}>Training Contents and Resources</label>

                                        <input
                                            type="radio"
                                            name="tabset"
                                            id="tab2"
                                            aria-controls="rauchbier"
                                            checked={activeTab === 'rauchbier'}
                                            onChange={() => handleTabChange('rauchbier')}
                                        />
                                        <label htmlFor="tab2" style={{ marginBottom: "0px" }}>Training Overview</label>

                                        <div class="tab-panels">
                                            <section id="marzen" className={`tab-panel ${activeTab === 'marzen' ? 'active' : ''}`}>
                                                {/* 
                                                {
                                                    trainingBySid.trainingOverview != null ?
                                                        <p className="title-md ">{trainingBySid.trainingOverview}</p>
                                                        : 'Overview not Provided'
                                                } */}




                                                <TrainingObjective trainingObjective={trainingObjective} />

                                            </section>
                                            <section id="rauchbier" className={`tab-panel ${activeTab === 'rauchbier' ? 'active' : ''}`}>
                                                <div>
                                                    <div className="card-body">
                                                        {/* <h5 className="card-title title-md">
                   
                      Training Description
                     
                   </h5> */}
                                                        {
                                                            loader ?
                                                                <SkeletonLoader skeletonCount={1} />
                                                                :
                                                                <div className="trainingoverview" style={{ textAlign: "justify" }}>
                                                                    {trainingObjective?.trainingDescription &&
                                                                        <ReactMarkdown>{trainingObjective.trainingDescription}</ReactMarkdown>
                                                                    }
                                                                </div>
                                                        }
                                                    </div>
                                                </div>
                                            </section>

                                        </div>

                                    </div>

                                </div>

                                <div className={`col-md-4 ${isExpanded ? '' : 'none'}`} style={stylescontent}  >
                                    <div className="row" style={{ alignItems: "center" }}>
                                        {user.role === ROLE.SUPERVISOR &&
                                            <div className="col-md-6">
                                                <button className="downloadbutton " data-tooltip-id="AssessmentDownload" data-tooltip-content="Download Assessment Reports..." onClick={() => getSupervisorAssessmentReport()}>

                                                    {reportsloading ? (
                                                        <>
                                                            <Spinner
                                                                as="span"
                                                                animation="border"
                                                                size="md"
                                                                role="status"
                                                                aria-hidden="true"
                                                                className="mr-2 loader-button" // Add margin to separate spinner from text
                                                                style={{ border: '5px dotted #fff', width: "20px", height: "20px" }}
                                                            />
                                                            {''}
                                                            Downloading...


                                                        </>
                                                    ) :
                                                        <>Reports {ICN_DOWNLOAD_BUTTON}</>}


                                                </button>

                                                <Tooltip
                                                    id="AssessmentDownload"

                                                    style={{ width: "80%", fontFamily: "Inter" }}
                                                />
                                            </div>
                                        }

                                        {user.role === ROLE.INSTRUCTOR && (

                                            <div className="col-md-6"><button className="downloadbutton " onClick={() => setShow(true)} style={{ float: "inline-end" }}>Whiteboard {ICN_EDIT}</button></div>
                                        )}



                                        <div className="col-md-6">
                                            {(user.role === ROLE.INSTRUCTOR || user.role === ROLE.SUPERVISOR) && type !== "TRAINING_SESSION" ?

                                                <>

                                                    {/* <div disabled className="col-md-1 col-sm-12 class-mode mt-1 disabled-button"
        style={{ background: "#49167E", borderRadius: "10px" }}
        data-tooltip-id="notestooltip1" data-tooltip-content="Select any session to download...">
        Attendance
    </div> */}
                                                    <div style={{ opacity: 0.5, cursor: "not-allowed" }} disabled data-tooltip-id="notestooltip1" data-tooltip-content="Select any session to download..."><button className="downloadbutton " style={{ float: "inline-end" }} >
                                                        Attendance {ICN_DOWNLOAD_BUTTON}</button></div>

                                                    <Tooltip
                                                        id="notestooltip1"
                                                        data-tooltip-place="right"

                                                        style={{ width: "80%", fontFamily: "Inter" }}
                                                    />
                                                </>
                                                :
                                                (user.role === ROLE.INSTRUCTOR || user.role === ROLE.SUPERVISOR) && <div onClick={() => getTrainingSessionAttendanceCsv()} >
                                                    <button className="downloadbutton " style={{ float: "inline-end" }} >
                                                        {attendanceloading ? (
                                                            <>
                                                                <Spinner
                                                                    as="span"
                                                                    animation="border"
                                                                    size="md"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                    className="mr-2 loader-button" // Add margin to separate spinner from text
                                                                    style={{ border: '5px dotted #fff', width: "20px", height: "20px" }}
                                                                />
                                                                {''}
                                                                Downloading...


                                                            </>
                                                        ) :
                                                            <>Attendance {ICN_DOWNLOAD_BUTTON}  </>}



                                                    </button>
                                                </div>



                                            }
                                        </div>
                                        {(user.role === ROLE.LEARNER) && (type === "OBJECTIVE" || type === "SECTION" || type === "VIEW" ||
                                            type === "LAB" || type === "CODING" || type === "CODING_ASSESSMENT" || type === "ASSESSMENT" || type === "TRAINING_SESSION" || type === "UPLOAD"
                                            || type === "PROJECT") ?

                                            <>
                                                <div disabled style={{ opacity: 0.6 }} className="col-md-6" data-tooltip-id="notes" data-tooltip-content="Select video or documents to continue..."><button className="downloadbutton "  >Make Notes {ICN_NOTE_EDIT}</button></div>

                                                <Tooltip
                                                    id="notes"
                                                    style={{ fontFamily: "Inter" }}
                                                />
                                            </>
                                            :


                                            (user.role === ROLE.LEARNER && (type === "EXTERNAL_LINK" || type === "VIDEO" || type === "DOCUMENTS")) &&
                                            <div className="col-md-6"><button className="downloadbutton " onClick={() => setShow(true)} >Make Notes {ICN_NOTE_EDIT}</button></div>

                                        }
                                    </div>
                                    <div className="overallprogress" >
                                        <ProgressBar
                                            // progress={
                                            //     markCompleted.totalCourseCompletedInTraining === null
                                            //         ? 0
                                            //         : markCompleted.totalCourseCompletedInTraining > contentLength
                                            //             ? contentLength
                                            //             : markCompleted.totalCourseCompletedInTraining
                                            // }
                                            progress={markCompleted}
                                            totalSection={contentLength}
                                            trainingSid={trainingSid}
                                            loaderTraining={loaderTraining}
                                        />
                                    </div>
                                    <div className="trainingnavigator" >
                                        <div className="d-flex" style={{ padding: "20px 24px", textAlign: "center", alignItems: "center", justifyContent: "space-between", borderBottom: "1px solid #EAECF0" }}><div className="trainingnavigatortext">Training Navigator</div>
                                            {/* <div className="trainingnavigatortext" >{ICN_MORE_DETAILS}</div> */}
                                        </div>

                                        <List
                                            ref={listRef}
                                            height={window.innerHeight} // 100vh
                                            itemCount={trainingDetailsList.length}
                                            itemSize={getItemSize}
                                            width="100%"
                                        >
                                            {Row}
                                        </List>
                                    </div>
                                    {/* {
                                    user.role === ROLE.INSTRUCTOR || user.role === ROLE.SUPERVISOR ?

                                        <DropdownItem title="Fun Activity" total="2" theme="dark">

                                            <div>
                                                <div className="py-3"> <AssessmentIcon /><a href="https://learnlytica.s3.ap-south-1.amazonaws.com/server-virtualization.html" target="_blank">Fun with Server-Virtualization</a></div>
                                                <div className="py-2"> <AssessmentIcon /><a href="https://learnlytica.s3.ap-south-1.amazonaws.com/windows-server11.html" target="_blank">Fun with Windows-Server</a></div>
                                            </div>
                                            <div>
                                                <div className="py-3"> <AssessmentIcon /><a href="https://course-content-storage.s3.amazonaws.com/cloud-native-jeopardy.html" target="_blank">Fun With Cloud Native</a></div>
                                                <div className="py-2"> <AssessmentIcon /><a href="https://course-content-storage.s3.amazonaws.com/kubernetes-jeopardy-game.html" target="_blank">Fun With Kubernetes</a></div>
                                            </div>
                                        </DropdownItem>
                                        : ''} */}

                                    {trainingBySid.name === "Cloud Computing, Docker And Kubernetes Journey" ?
                                        <>
                                            <DropdownItem title="Development Labs" total="5" theme="dark">


                                                <div>
                                                    <div className="py-3"> <ScienceIcon /><a href="https://gnosislabs.cloud/" target="_blank">Install and Configure K3s Cluster</a></div>
                                                    <div className="py-3"> <ScienceIcon /><a href="https://gnosislabs.cloud/" target="_blank">Install and Configure K9s</a></div>
                                                    <div className="py-3"> <ScienceIcon /><a href="https://gnosislabs.cloud/" target="_blank">Imperative Commands in Kubernetes</a></div>

                                                    <div className="py-3"> <ScienceIcon /><a href="https://gnosislabs.cloud/" target="_blank">Kubernetes Deployment</a></div>

                                                    <div className="py-3"> <ScienceIcon /><a href="https://gnosislabs.cloud/" target="_blank">Asssign Memory Request</a></div>

                                                </div>
                                            </DropdownItem>
                                            <DropdownItem title="Workshops" total="1" theme="dark">


                                                <div>
                                                    <div className="py-3"> <HomeRepairServiceIcon /><a href="https://gnosislabs.cloud/" target="_blank">Namespaces</a></div>

                                                </div>
                                            </DropdownItem>
                                        </>
                                        : ''
                                    }

                                </div>
                            </div>


                        </>

                }



            </div>

        </>
    )
}
export default TrainingDetails