import React, { useEffect, useState, useRef } from 'react'
import "./auth.css";
import RestService from '../../../Services/api.service';
import CryptoJS from 'crypto-js';
import { Link, useParams } from '@reach/router';
import useToast from '../../../Store/ToastHook';
import GLOBELCONSTANT from '../../../Constant/GlobleConstant';
import { Spinner } from 'react-bootstrap';


const ForgotPassword = () => {
    const secretKey = process.env.REACT_APP_SECRET;
    const [resetpassword, setResetpassword] = useState(true);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const hasCheckedLink = useRef(false);
    const [valid, setValid] = useState(false);
    const [buttonLoader, setButtonLoader] = useState(false);
    const Toast = useToast();
    let { uuid, timestamp } = useParams();

    const handlePasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Validation
        if (newPassword.length < 8) {
            setPasswordError('Password must be at least 8 characters long');
            setShowErrorMessage(true);
        } else if (newPassword !== confirmPassword) {
            setPasswordError('Passwords do not match');
            setShowErrorMessage(true);
        } else {
            // Passwords are valid, you can proceed with form submission or further actions
            // For now, just clear the error message and hide it
            // setPasswordError('');
            // setShowErrorMessage(false);
            changeForgotPassword();
            // Perform additional actions like form submission
        }
    };

    //change forgot password
    const changeForgotPassword = () => {
        try {
            // spinner.show();
            setButtonLoader(true);
            const confirmPasswordEncrypt = CryptoJS.AES.encrypt(confirmPassword, secretKey).toString();
            // const newPasswordEncrypt = CryptoJS.AES.encrypt(newPassword, secretKey).toString();

            const payload = {
                "confirmPassword": confirmPasswordEncrypt,
                "newPassword": confirmPasswordEncrypt,
                "uuid": uuid
            }
            RestService.changeForgotPassword(payload).then(
                response => {
                    if (response.status === 200) {
                        Toast.success({ message: 'Password Updated Successfully', time: 1500 });
                        setTimeout(() => {
                            setResetpassword(false);
                            setButtonLoader(false);
                        }, 2000);

                    }
                },
                err => {
                    console.error(err);
                    setButtonLoader(false);
                    Toast.error({ message: err.response.data.message, time: 2500 });
                }
            )
        } catch (err) {

            console.error("error occur on changeForgotPassword()", err)
        }
    }

    const checkValidResetLink = () => {

        const decodedTimestamp = atob(timestamp);
        const receivedTimestamp = parseInt(decodedTimestamp, 10);
        const currentTimestamp = Date.now();
        const difference = 15 * 60 * 1000;
        const timeDifference = currentTimestamp - receivedTimestamp;

        if (parseInt(timeDifference) <= parseInt(difference)) {
            setValid(false);
        } else {
            setValid(true);
            Toast.error({ message: 'Reset Password Link Expired', time: 2000 });
            return;

        }

    }


    useEffect(() => {
        if (!hasCheckedLink.current) {
            checkValidResetLink();
            hasCheckedLink.current = true;
        }
    }, []);

    //if login and user tries to login again, redirect to my enrolled courses






    return (
        <>

            {
                resetpassword ?
                    <div className="signup-form mt-5" style={{
                        margin: "0 auto",
                        maxWidth: "430px",
                        padding: "30px 0"
                    }}>
                        <form onSubmit={handleSubmit}>
                            <div className="text-center">
                                {GLOBELCONSTANT.BRANDING.ICON}
                            </div>
                            <h2 className="pt-4" style={{ fontSize: "30px", color: "#101828", fontWeight: "600" }}>Create a New Password</h2>
                            <p className="pt-3" style={{ textAlign: "center" }}>Create a new password for your account. Make sure it's strong and secure.</p>

                            <div className="form-group">
                                <label style={{ fontSize: "14px", color: "#344054", fontWeight: "500" }}>New Password</label>
                                <div className="input-group">
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="newPassword"
                                        placeholder="New Password"
                                        value={newPassword}
                                        onChange={handlePasswordChange}
                                        required
                                        disabled={valid}
                                        style={{ borderRadius: "8px", border: "1px solid #dod5dd", outline: "none", boxShadow: "0 1px 2px 0 #1018280d" }}
                                    />
                                </div>
                            </div>

                            <div className="form-group mt-3">
                                <label style={{ fontSize: "14px", color: "#344054", fontWeight: "500" }}>Confirm Password</label>
                                <div className="input-group">
                                    <input
                                        type="password"
                                        className="form-control"
                                        name="confirmPassword"
                                        placeholder="Confirm Password"
                                        value={confirmPassword}
                                        onChange={handleConfirmPasswordChange}
                                        required
                                        disabled={valid}
                                        style={{ borderRadius: "8px", border: "1px solid #dod5dd", outline: "none", boxShadow: "0 1px 2px 0 #1018280d" }}
                                    />
                                </div>
                            </div>

                            {passwordError && <p className="text-danger">{passwordError}</p>}

                            <div className="form-group">
                                <button type="submit" style={{ width: "100%" }} className="btn btn-primary btn-lg" disabled={valid}>
                                    {
                                        buttonLoader ?
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="md"
                                                role="status"
                                                aria-hidden="true"
                                                className="mr-2 loader-button" // Add margin to separate spinner from text
                                                style={{ border: '2px dotted #fff', width: "20px", height: "20px" }}
                                            />
                                            :
                                            'Reset Password'
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                    :
                    <div class="signup-form" style={{
                        margin: "0 auto",
                        maxWidth: "430px",
                        padding: "30px 0"
                    }}>
                        <form>
                        <div className="text-center">
                                {GLOBELCONSTANT.BRANDING.ICON}
                            </div>
                            <h2 className='pt-4' style={{ fontSize: "30px", color: "#101828", fontWeight: "600" }}>Password Successfully Reset</h2>
                            <p className='pt-3' style={{textAlign:"center"}}>Congratulations! Your password has been successfully reset. Now, you can confidently log in with your new credentials.</p>
                            {/* <hr/> */}




                            <div class="form-group">
                                <Link to="/" type="submit" style={{width:"100%"}} class="btn btn-primary btn-lg">Back to Login</Link>
                            </div>

                        </form>

                        {/* <div class="text-center alredyhv">Don’t have an account? <Link to="/signup">Sign up</Link></div> */}



                    </div>
            }

        </>
    )
}

export default ForgotPassword