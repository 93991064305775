import React, { useState } from 'react';
// import "./auth.css";
import CryptoJS from 'crypto-js';
import RestService from '../../../Services/api.service';
import useToast from '../../../Store/ToastHook';
import GLOBELCONSTANT from '../../../Constant/GlobleConstant';
import { Spinner } from 'react-bootstrap';


const ResetLink = () => {
    const secretKey = process.env.REACT_APP_SECRET;
    const [email, setEmail] = useState('')
    const [emailError, setEmailError] = useState('');
    const [buttonLoader, setButtonLoader] = useState(false);
    const Toast = useToast();
    const handleEmail = (e) => {
        const value = e.target.value;
        setEmail(value);

        // Validate email
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/.test(value);
        setEmailError(isValidEmail ? '' : 'Invalid email address');
    };

    //forgot password link
    const forgotPassword = () => {
        const emailEncrypt = CryptoJS.AES.encrypt(email, secretKey).toString();

        try {
            setButtonLoader(true);
            // spinner.show();
            const payload = {
                "email": emailEncrypt,
                "userType": "BUSINESS"
            }
            RestService.forgotPassword(payload).then(
                response => {
                    if (response.status === 200) {
                        Toast.success({ message: 'Email Sent' });
                        setTimeout(() => {
                            setButtonLoader(false);
                            setEmail('');
                        }, 1000);

                    }
                },
                err => {
                    setButtonLoader(false);
                    Toast.error({ message: err.response.data.message });
                }
            )
        } catch (err) {
            Toast.error({ message: 'Try Again' });
            console.error("error occur on forgotPassword()", err)
        }
    }

    return (
        <>
            <div className="signup-form mt-5" style={{
                margin: "0 auto",
                maxWidth: "430px",
                padding: "30px 0"
            }}>

                <div className="text-center">
                    {GLOBELCONSTANT.BRANDING.ICON}
                </div>
                <h2 className="pt-4" style={{ fontSize: "30px", color: "#101828", fontWeight: "600" }}>Enter email</h2>
                <p className="pt-3" style={{ textAlign: "center" }}>Enter your email. You can update your password after you click on the link.</p>

                <div className="form-group">
                    <label style={{ fontSize: "14px", color: "#344054", fontWeight: "500" }}>Email</label>
                    <div className="input-group">
                        <input
                            type="text"
                            className="form-control"
                            name="email"
                            placeholder="Enter Email"
                            value={email}
                            onChange={handleEmail}
                            style={{ borderRadius: "8px", border: "1px solid #dod5dd", outline: "none", boxShadow: "0 1px 2px 0 #1018280d" }}
                        />
                    </div>
                    {emailError && <p className="error-message" style={{color: "red"}}>{emailError}</p>}
                </div>

                <div className="form-group">
                    <button style={{ width: "100%" }} type="submit" disabled={emailError?.length > 0 || email?.length === 0 || buttonLoader} className="btn btn-primary btn-lg" onClick={forgotPassword}>
                        {
                            buttonLoader ?
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="md"
                                    role="status"
                                    aria-hidden="true"
                                    className="mr-2 loader-button" // Add margin to separate spinner from text
                                    style={{ border: '2px dotted #fff', width: "20px", height: "20px" }}
                                />
                                :
                                'Send'
                        }

                    </button>
                </div>

            </div>


        </>
    )
}

export default ResetLink