import { useEffect, useState, useContext } from "react";
import AppContext from "../../../Store/AppContext";
import { BsModal } from "../../Common/BsUtils";
import { BtnPrimary } from "../../Common/Buttons/Buttons";
import RestService from "../../../Services/api.service";
import Carousel from "react-bootstrap/Carousel";
import Screen1 from "../../../Assets/Images/screen1.png";
import useToast from "../../../Store/ToastHook";
import { Button } from "../../Common/Buttons/Buttons";
import SearchBox from "../../Common/SearchBox/SearchBox";
import { ICN_ON_GOING, ICN_STAR, ICN_TIME } from "../../Common/Icon";
import AddLab from "./AddLab";
import "./Styles.css";

const LabList = (props) => {
  const [show, setShow] = useState(false);
  // const myLab = false;
  // const list = [];
  const [labId, setLabId] = useState("");
  const [labName, setLabName] = useState("");
  const [labDescription, setLabDescription] = useState("");
  const [Imported, SetImported] = useState(0);
  const [labsFromCategory, setLabsFromCategory] = useState([]);
  const { user, spinner, setCourse, ROLE } = useContext(AppContext);
  const [clickid, setClickId] = useState([]);
  // const categoryName = location.state.categoryName;
  const wordShort = (str, maxLen, separator = " ") => {
    try {
      if (str.length <= maxLen) {
        return str;
      } else {
        return `${str.substr(
          0,
          str.lastIndexOf(separator, maxLen)
        )} ...Learn More`;
      }
    } catch (err) {
      console.error("error occur on wordShort()", err);
    }
  };

  const setStatus = (param) => {
    setClickId((prevArray) => [...prevArray, param]);
  };
  // get all labs from categories
  const filterLabs = () => {
    try {
      const categoryName = props.location.state.categoryName;
      spinner.show();
      RestService.filterLabs(categoryName)
        .then(
          (response) => {
            setLabsFromCategory(response.data.labDetails);
            SetImported(
              response.data.labDetails.filter(
                (item) => item.importStatus === "IMPORTED"
              )
            );
            // setLabsFromCategoryCount(response.data.count);
          },
          (err) => {
            spinner.hide();
          }
        )
        .finally(() => {
          spinner.hide();
        });
    } catch (err) {
      console.error("error occur on filterLabs()", err);
    }
  };

  useEffect(() => {
    if (!props.myLab) {
      filterLabs();
    }
  }, []);

  // useEffect(() => {

  //     const imptlabs = list.filter((item) => {
  //         return item.importStatus === "IMPORTED"
  //     });
  //     SetImported(imptlabs.length);
  // },)
  return (
    <>
      {/* <div className="title-sm ">Imported  <span>{Imported}</span></div>
 <div className="title-sm ">Total  <span>{list.length}</span></div> */}
      {!props.myLab ? (
        <div className="aic jcb">
          <div className="title-md">
            Total Labs<span className="mx-1">{labsFromCategory.length}</span>
          </div>
          <div className="aic">
            <div className="title-md">
              Imported Lab <span className="mx-1">{Imported.length}</span>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={props?.list?.length === 0 ? "" : "catalog-container"} style={{ margin: props?.list?.length === 0 && "0 auto" }}>
        {props.myLab
          ? props?.list?.length !== 0 ? props.list.map((res) => (
            <div className="labList">
              <div className="labList-info flx5">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="cat-title-md">{res.labName}</div>
                  {res.labSolution === "Y" ? (
                    <div className="assesmentlab cat-title-md px-3">
                      Assessment
                    </div>
                  ) : (
                    ""
                  )}

                  {/* <div className="cat-title-sm">{myLab ? wordShort(res.desc, 80) : res.labDescription}</div> */}
                </div>
                <div className="cat-title-sm">{res.labDescription}</div>
                <div className="text-md">
                  <div className="flx f12">
                    <div className="mr-3">45 mins</div>
                    <div className="mr-3">Intermediate</div>
                    <div className="mr-3 elps">on Demand</div>
                    <div className="mr-3">0.2$</div>
                  </div>
                </div>
              </div>
              <div className="text-center jcb-c">
                <div>
                  {
                    props.myLab ? (
                      <div>
                        {/* <BtnPrimary className="btn-block">
                          Select Training
                        </BtnPrimary> */}
                      </div>
                    ) :
                      res.importStatus === "IMPORTED" ? (
                        <BtnPrimary
                          className="bg-success imprtbtn"
                          disabled={true}
                        >
                          &#x2713; Lab Already Imported
                        </BtnPrimary>
                      ) : (
                        <BtnPrimary
                          className="btn-block"
                          onClick={() => {
                            setShow(true);
                            setLabId(res.labId);
                            setLabName(res.labName);
                            setLabDescription(res.labDescription);
                          }}
                        >
                          + Import Now
                        </BtnPrimary>
                      )}
                </div>
                {/* <div className="">
                    {
                      props.myLab ? (
                        <BtnPrimary className="btn-block my-2">
                          Assign Now
                        </BtnPrimary>
                      ) : (
                        ""
                      )
                      // <div className="title-sm text-white">500 + active imports</div>
                    }

                    <div className="star-icon">
                      <div>{ICN_STAR}</div>
                      <div>{ICN_STAR}</div>
                      <div>{ICN_STAR}</div>
                      <div>{ICN_STAR}</div>
                      <div>{ICN_STAR}</div>
                    </div>
                  </div> */}
              </div>
            </div>
          ))
            :
            <div className=' title-md'>Labs Collection is Empty !!</div>
          : labsFromCategory.map((res) => (
            <div className="labList">
              <div className="labList-info flx5">
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="cat-title-md">{res.labName}</div>
                  {res.labSolution === "Y" ? (
                    <div className="assesmentlab cat-title-md px-3">
                      Assessment
                    </div>
                  ) : (
                    ""
                  )}

                  {/* <div className="cat-title-sm">{myLab ? wordShort(res.desc, 80) : res.labDescription}</div> */}
                </div>
                <div className="cat-title-sm">{res.labDescription}</div>
                <div className="text-md">
                  <div className="flx f12">
                    <div className="mr-3">45 mins</div>
                    <div className="mr-3">Intermediate</div>
                    <div className="mr-3 elps">on Demand</div>
                    <div className="mr-3">0.2$</div>
                  </div>
                </div>
              </div>
              <div className="text-center jcb-c">
                <div>
                  {props.myLab ? (
                    <div>
                      <BtnPrimary className="btn-block">
                        Select Training
                      </BtnPrimary>
                    </div>
                  ) : res.importStatus === "IMPORTED" ||
                    clickid.includes(res.labId) ? (
                    <BtnPrimary
                      className="bg-success imprtbtn"
                      disabled={true}
                    >
                      &#x2713; Lab Already Imported
                    </BtnPrimary>
                  ) : (
                    <BtnPrimary
                      className="btn-block"
                      onClick={() => {
                        setShow(true);
                        setLabId(res.labId);
                        setLabName(res.labName);
                        setLabDescription(res.labDescription);
                      }}
                    >
                      + Import Now
                    </BtnPrimary>
                  )}
                </div>
                <div className="">
                  {
                    props.myLab ? (
                      <BtnPrimary className="btn-block my-2">
                        Assign Now
                      </BtnPrimary>
                    ) : (
                      ""
                    )
                    // <div className="title-sm text-white">500 + active imports</div>
                  }

                  <div className="star-icon">
                    <div>{ICN_STAR}</div>
                    <div>{ICN_STAR}</div>
                    <div>{ICN_STAR}</div>
                    <div>{ICN_STAR}</div>
                    <div>{ICN_STAR}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {
        <BsModal
          {...{ show, setShow, headerTitle: "Add This Lab", size: "xl" }}
        >
          {show && (
            <AddLab
              labId={labId}
              labName={labName}
              labDescription={labDescription}
              setStatus={setStatus}
            />
          )}
        </BsModal>
      }
    </>
  );
};

export default LabList;
