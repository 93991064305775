import React, { useEffect, useState, useContext } from 'react';

import "../NewDashboardLearner.css"
import DuoIcon from '@mui/icons-material/Duo';
import SummarizeIcon from '@mui/icons-material/Summarize';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SummarizeRoundedIcon from '@mui/icons-material/SummarizeRounded';
import Groups3Icon from '@mui/icons-material/Groups3';
import AppContext from '../../../../Store/AppContext';
import ScienceIcon from '@mui/icons-material/Science';
import AssessmentIcon from '@mui/icons-material/Assessment';
import CodeIcon from '@mui/icons-material/Code';
import RestService from '../../../../Services/api.service';
import { useNavigate } from '@reach/router';
import SkeletonLoader from '../../../Common/SkeletonLoader/SkeletonLoader';

const LinearProgressBar = () => {
  const [upcomingDocuments, setUpcomingDocuments] = useState({});
  const [upcomingVideo, setUpcomingVideo] = useState({});
  const [upcomingTrainingSession, setUpcomingTrainingSession] = useState({});
  const [upcomingAssessment, setUpcomingAssessment] = useState({});
  const [upcomingPracticeLab, setUpcomingPracticeLab] = useState({});
  const [upcomingCapstoneLab, setUpcomingCapstoneLab] = useState({});
  const { user, batches, course, ROLE, spinner, setCategory } = useContext(AppContext);
  const [continueLoader, setContinueLoader] = useState(true);

  const navigate = useNavigate();

  // function handleClick() {
  //   navigate("/training/training-details");
  // }

  // getLearnerUpcomingContent
  const getLearnerUpcomingContent = () => {
    try {
      RestService.getLearnerUpcomingContent().then(
        response => {
          if (response.status === 200) {
            setUpcomingDocuments(response.data.upcomingDocuments !== null ? response.data.upcomingDocuments : null);
            setUpcomingVideo(response.data.upcomingVideo !== null ? response.data.upcomingVideo : null);
            setUpcomingTrainingSession(response.data.upcomingTrainingSession !== null ? response.data.upcomingTrainingSession : null);
            setUpcomingAssessment(response.data.upcomingAssessment !== null ? response.data.upcomingAssessment : null);
            setUpcomingPracticeLab(response.data.upcomingPracticeLab !== null ? response.data.upcomingPracticeLab : null);
            setUpcomingCapstoneLab(response.data.upcomingCapstoneLab !== null ? response.data.upcomingCapstoneLab : null);
            // setTimeout(()=>{
              setContinueLoader(false);
            // }, 3000)
            
          }

        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getLearnerUpcomingContent()", err)
    }
  }
  useEffect(() => {
    getLearnerUpcomingContent()
  }, [])

  return (
    <>
      <div className='row'>


        <div className="col-md-4 col-sm-4 my-2">
          <div className="card p-3 mb-2 shadow-sm rounded h-100" style={{ background: "#F7F7F9" }}>
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex flex-row align-items-center">
                <div className="ms-2 c-details">
                  <div className="mb-0">
                    <DuoIcon /> Videos
                  </div>
                </div>
              </div>
              {

                continueLoader ?
                  <SkeletonLoader />
                  :
                  upcomingVideo !== null && upcomingVideo.contentName !== null &&
                  <button onClick={() => {
                    navigate('/training/training-details', {
                      state: {
                        upcomingContent: {
                          // "trainingSid": upcomingVideo.trainingSid,
                          "contentSid": upcomingVideo.contentSid,
                          "sectionSid": upcomingVideo.sectionSid,
                          "contentName": upcomingVideo.contentName,
                          "labId": upcomingVideo.labId,
                          "codingQuestionId": upcomingVideo.codingQuestionId,
                          "contentType": upcomingVideo.contentType,
                          "contentLink": upcomingVideo.contentLink,
                          "meetingId": upcomingVideo.meetingId,
                          "meetingPwd": upcomingVideo.meetingPwd,
                          "labContent": upcomingVideo.labContent,
                          "labAssessment": upcomingVideo.labAssessment,
                          "duration": upcomingVideo.duration
                        }
                      }
                    }); localStorage.setItem("trainingSid", upcomingVideo.trainingSid)
                  }}
                    className='continue cat-title-md px-3'> Continue </button>
              }

            </div>
            <div className="c-details1" style={{ textAlign: "justify" }}>
              <span className='title-sm' style={{ textTransform: "capitalize" }}>
                {upcomingVideo !== null && upcomingVideo.contentName !== null ?
                  continueLoader ?
                    <SkeletonLoader />
                    :
                    upcomingVideo.contentName : "Everything Caught Up!!"}
              </span>
              {/*        
       <span className='title-sm'>{upcomingVideo.trainingName }  </span> */}
            </div>
          </div>
        </div>
        <div className='col-md-4 col-sm-4 my-2'>
          <div class="card p-3 mb-2 shadow-sm   rounded h-100" style={{ background: "#F7F7F9" }}>
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-row align-items-center">

                <div class="ms-2 c-details">
                  <div class="mb-0"><SummarizeIcon />Document</div>
                </div>

              </div>
              {
                continueLoader ?
                  <SkeletonLoader />
                  :
                  upcomingDocuments !== null && upcomingDocuments.contentName !== null &&
                  <button onClick={() => {
                    navigate('/training/training-details', {
                      state: {
                        upcomingContent: {
                          // "trainingSid": upcomingDocuments.trainingSid,
                          "contentSid": upcomingDocuments.contentSid,
                          "sectionSid": upcomingDocuments.sectionSid,
                          "contentName": upcomingDocuments.contentName,
                          "labId": upcomingDocuments.labId,
                          "codingQuestionId": upcomingDocuments.codingQuestionId,
                          "contentType": upcomingDocuments.contentType,
                          "contentLink": upcomingDocuments.contentLink,
                          "meetingId": upcomingDocuments.meetingId,
                          "meetingPwd": upcomingDocuments.meetingPwd,
                          "labContent": upcomingDocuments.labContent,
                          "labAssessment": upcomingDocuments.labAssessment,
                          "duration": upcomingDocuments.duration
                        }
                      }
                    }); localStorage.setItem("trainingSid", upcomingDocuments.trainingSid)
                  }}
                    className='continue cat-title-md px-3'> Continue </button>
              }
            </div>

            <div className="c-details1" style={{ textAlign: "justify" }}>
              <span className='title-sm' style={{ textTransform: "capitalize" }}>
                {upcomingDocuments !== null && upcomingDocuments.contentName !== null ?
                  continueLoader ?
                    <SkeletonLoader />
                    :
                    upcomingDocuments.contentName : "Everything Caught Up!!"}</span>
            </div>


          </div>
        </div>
        <div className='col-md-4 col-sm-4 my-2'>
          <div class="card p-3 mb-2 shadow-sm   rounded h-100" style={{ background: "#F7F7F9" }}>
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-row align-items-center">

                <div class="ms-2 c-details">
                  <div class="mb-0"><ScienceIcon />Practice Lab</div>
                </div>

              </div>
              {
                continueLoader ?
                  <SkeletonLoader />
                  :
                  upcomingPracticeLab !== null && upcomingPracticeLab.contentName !== null &&
                  <button onClick={() => {
                    navigate('/training/training-details', {
                      state: {
                        upcomingContent: {
                          // "trainingSid": upcomingPracticeLab.trainingSid,
                          "contentSid": upcomingPracticeLab.contentSid,
                          "sectionSid": upcomingPracticeLab.sectionSid,
                          "contentName": upcomingPracticeLab.contentName,
                          "labId": upcomingPracticeLab.labId,
                          "codingQuestionId": upcomingPracticeLab.codingQuestionId,
                          "contentType": upcomingPracticeLab.contentType,
                          "contentLink": upcomingPracticeLab.contentLink,
                          "meetingId": upcomingPracticeLab.meetingId,
                          "meetingPwd": upcomingPracticeLab.meetingPwd,
                          "labContent": upcomingPracticeLab.labContent,
                          "labAssessment": upcomingPracticeLab.labAssessment,
                          "duration": upcomingPracticeLab.duration
                        }
                      }
                    }); localStorage.setItem("trainingSid", upcomingPracticeLab.trainingSid)
                  }}
                    className='continue cat-title-md px-3' > Continue </button>
              }
            </div>
            <div className="c-details1" style={{ textAlign: "justify" }}>
              <span className='title-sm' style={{ textTransform: "capitalize" }}>
                {upcomingPracticeLab !== null && upcomingPracticeLab.contentName !== null ?
                  continueLoader ?
                    <SkeletonLoader />
                    :
                    upcomingPracticeLab.contentName : "Everything Caught Up!!"}</span>
            </div>


          </div>
        </div>
        <div className='col-md-4 col-sm-4'>
          <div class="card p-3 mb-2 shadow-sm   h-100" style={{ background: "#F7F7F9" }}>
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-row align-items-center">

                <div class="ms-2 c-details">
                  <div class="mb-0"><AssessmentIcon />Assessment </div>
                </div>

              </div>
              {
                continueLoader ?
                  <SkeletonLoader />
                  : upcomingAssessment !== null && upcomingAssessment.contentName !== null &&
                  <button onClick={() => {
                    navigate('/training/training-details', {
                      state: {
                        upcomingContent: {
                          // "trainingSid": upcomingAssessment.trainingSid,
                          "contentSid": upcomingAssessment.contentSid,
                          "sectionSid": upcomingAssessment.sectionSid,
                          "contentName": upcomingAssessment.contentName,
                          "labId": upcomingAssessment.labId,
                          "codingQuestionId": upcomingAssessment.codingQuestionId,
                          "contentType": upcomingAssessment.contentType,
                          "contentLink": upcomingAssessment.contentLink,
                          "meetingId": upcomingAssessment.meetingId,
                          "meetingPwd": upcomingAssessment.meetingPwd,
                          "labContent": upcomingAssessment.labContent,
                          "labAssessment": upcomingAssessment.labAssessment,
                          "duration": upcomingAssessment.duration
                        }
                      }
                    }); localStorage.setItem("trainingSid", upcomingAssessment.trainingSid);
                  }}
                    className='continue cat-title-md px-3' > Continue </button>
              }
            </div>

            <div className="c-details1" style={{ textAlign: "justify" }}>
              <span className='title-sm' style={{ textTransform: "capitalize" }}>
                {upcomingAssessment !== null && upcomingAssessment.contentName !== null ?
                  continueLoader ?
                    <SkeletonLoader />
                    : upcomingAssessment.contentName : "Everything Caught Up!!"}</span>
            </div>


          </div>
        </div>
        <div className='col-md-4 col-sm-4'>
          <div class="card p-3 mb-2 shadow-sm   h-100" style={{ background: "#F7F7F9" }}>
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-row align-items-center">

                <div class="ms-2 c-details">
                  <div class="mb-0"><AssessmentIcon />Capstone </div>
                </div>

              </div>
              {upcomingCapstoneLab !== null && upcomingCapstoneLab.contentName !== null &&
                <button onClick={() => {
                  navigate('/training/training-details', {
                    state: {
                      upcomingContent: {
                        // "trainingSid": upcomingCapstoneLab.trainingSid,
                        "contentSid": upcomingCapstoneLab.contentSid,
                        "sectionSid": upcomingCapstoneLab.sectionSid,
                        "contentName": upcomingCapstoneLab.contentName,
                        "labId": upcomingCapstoneLab.labId,
                        "codingQuestionId": upcomingCapstoneLab.codingQuestionId,
                        "contentType": upcomingCapstoneLab.contentType,
                        "contentLink": upcomingCapstoneLab.contentLink,
                        "meetingId": upcomingCapstoneLab.meetingId,
                        "meetingPwd": upcomingCapstoneLab.meetingPwd,
                        "labContent": upcomingCapstoneLab.labContent,
                        "labAssessment": upcomingCapstoneLab.labAssessment,
                        "duration": upcomingCapstoneLab.duration
                      }
                    }
                  }); localStorage.setItem("trainingSid", upcomingCapstoneLab.trainingSid);
                }}
                  className='continue cat-title-md px-3' > Continue </button>
              }
            </div>

            <div className="c-details1" style={{ textAlign: "justify" }}>
              <span className='title-sm' style={{ textTransform: "capitalize" }}>
                {
                  continueLoader ?
                    <SkeletonLoader />
                    : upcomingCapstoneLab !== null && upcomingCapstoneLab.contentName !== null ?
                      continueLoader ?
                        <SkeletonLoader />
                        :
                        upcomingCapstoneLab.contentName : "Everything Caught Up!!"}</span>
            </div>



          </div>
        </div>
        <div className='col-md-4 col-sm-4'>
          <div class="card p-3 mb-2 shadow-sm   h-100" style={{ background: "#F7F7F9" }}>
            <div class="d-flex justify-content-between">
              <div class="d-flex flex-row align-items-center">

                <div class="ms-2 c-details">
                  <div class="mb-0"><Groups3Icon />Meeting </div>
                </div>

              </div>
              {
                continueLoader ?
                  <SkeletonLoader />
                  : upcomingTrainingSession !== null && upcomingTrainingSession.contentName !== null &&
                  <button onClick={() => {
                    navigate('/training/training-details', {
                      state: {
                        upcomingContent: {
                          // "trainingSid": upcomingTrainingSession.trainingSid,
                          "contentSid": upcomingTrainingSession.contentSid,
                          "sectionSid": upcomingTrainingSession.sectionSid,
                          "contentName": upcomingTrainingSession.contentName,
                          "labId": upcomingTrainingSession.labId,
                          "codingQuestionId": upcomingTrainingSession.codingQuestionId,
                          "contentType": upcomingTrainingSession.contentType,
                          "contentLink": upcomingTrainingSession.contentLink,
                          "meetingId": upcomingTrainingSession.meetingId,
                          "meetingPwd": upcomingTrainingSession.meetingPwd,
                          "labContent": upcomingTrainingSession.labContent,
                          "labAssessment": upcomingTrainingSession.labAssessment,
                          "duration": upcomingTrainingSession.duration
                        }
                      }
                    }); localStorage.setItem("trainingSid", upcomingTrainingSession.trainingSid);
                  }}
                    className='continue cat-title-md px-3'> Continue </button>
              }
            </div>
            <div className="c-details1" style={{ textAlign: "justify" }}>
              <span className='title-sm' style={{ textTransform: "capitalize" }}>
                {upcomingTrainingSession !== null && upcomingTrainingSession.contentName !== null ?
                  continueLoader ?
                    <SkeletonLoader />
                    : upcomingTrainingSession.contentName : "Everything Caught Up!!"} </span>
            </div>


          </div>
        </div>




      </div>
    </>
  );
}

export default LinearProgressBar