import { useContext, useRef, useState, useEffect, useCallback } from "react";
import ReactMarkdown from 'react-markdown';
import { useNavigate } from "@reach/router";
import useToast from "../../../../Store/ToastHook";
import AppContext from "../../../../Store/AppContext";
import RestService from "../../../../Services/api.service";
import CountdownTimer from "../../../Common/CountdownTimer/CountdownTimer";
import CodeEditor from "../../ClassLab/CodeEditor/CodeEditor";
import "./labs.css";
import { ICN_BACK } from "../../../Common/Icon";
import LabSpinner from "./LabSpinner";
import RecordRTC from "recordrtc";
import AWS from 'aws-sdk';
import axios from 'axios';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import FocusTrap from "focus-trap-react"
import CompleteProgressBar from "./CompleteProgressBar";
import * as faceapi from "face-api.js";
import { BsModal } from "../../../Common/BsUtils";

import { ICN_FULL_SCREEN } from "../../../Common/Icon";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import parse from 'html-react-parser';
import { Button } from "react-bootstrap";
import GLOBELCONSTANT from "../../../../Constant/GlobleConstant";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const bucketUrl = process.env.REACT_APP_BUCKET_URL;

AWS.config.update({
    accessKeyId: process.env.REACT_APP_ACCESSKEYID,
    secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
})

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
})

function Labs(props) {
    const [labDescription, setLabDescription] = useState(props.location.state.labDescription);
    const [codingQuestiondesc, setcodingQuestiondesc] = useState(false);
    const [labOverview, setLabOverview] = useState(props.location.state.labOverview);
    const [labType, setLabType] = useState(props.location.state.type);
    const { spinner } = useContext(AppContext)
    const [contentSid, setContentSid] = useState(props.location.state.contentSid);
    const [labId, setLabId] = useState(props.location.state.labId);
    const [startLabConnection, setStartLabConnection] = useState('');
    // const [labConnection, setLabConnection] = useState('');
    const [stopConnection, setStopConnection] = useState('');
    const [stopServer, setStopServer] = useState('');
    const [showButton, setShowButton] = useState(false);
    const [showEditor, setShowEditor] = useState(false);
    const [labDuration, setLabDuration] = useState(props.location.state.labDuration);
    const [labName, setLabName] = useState(props.location.state.showcoursename);
    const [evaluatedLab, setEvaluatedLab] = useState(props.location.state.evaluatedLab);
    const [isLoading, setIsLoading] = useState(true);
    const [offStartButton, setOffStartButton] = useState(true);
    const [starting, setStarting] = useState('');
    const [cloudLabUserUniqueId, setCloudLabUserUniqueId] = useState(props.location.state.cloudLabUserUniqueId);
    const labIdArray = [19, 20, 21, 24, 26, 27, 28, 29, 30, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
        51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 98,
        204, 205, 206, 265, 266, 267, 365, 366, 367, 368];
    const vsCodeLink = "https://gnosislabs.cloud/";
    const token = localStorage.getItem('GUAC_AUTH');
    const [startsubmit, setstartsubmit] = useState(false);
    const [completesubmit, setcompletesubmit] = useState(false);

    const [isFullscreen, setIsFullscreen] = useState(false);

    const [bookmark, setBookmark] = useState(props.location.state.bookmark);

    const [openedWindow, setOpenedWindow] = useState(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    localStorage.setItem("trainingSid", props.location.state.trainingSid);

    // show hide sidebar
    const [isContentVisible, setIsContentVisible] = useState(false);

    //multiple pages in labs
    const [parentLab, setParentLab] = useState(props.location.state.parentLab);
    const [pageNo, setPageNo] = useState(props.location.state.pageNo);
    const [pages, setPages] = useState(props.location.state.pages);
    const [startLabConnectionParentLabs, setStartLabConnectionParentLabs] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [localStorageIndex, setLocalStorageIndex] = useState(0);

    //projects
    const [durationInDays, setDurationInDays] = useState(props.location.state.durationInDays);
    const [projectId, setProjectId] = useState(props.location.state.projectId);
    const [repoLink, setRepoLink] = useState('');
    const [zipFile, setZipFile] = useState();

    const [isValid, setIsValid] = useState();
    const [isValidFile, setIsValidFile] = useState();

    //Coding Assessment
    const [startAssessmentStatus, setStartAssessmentStatus] = useState('');
    const [codingAssessmentDetails, setCodingAssessmentDetails] = useState(props.location.state.codingAssessmentDetails);
    const [currentIndexCodingAssessmentIndex, setCurrentIndexCodingAssessmentIndex] = useState(0);
    const [codingAssessmentQuestionId, setCodingAssessmentQuestionId] = useState(props.location.state.codingAssessmentDetails?.questionDetails[0].codingQuestionId);
    const [codingAssessmentQuestion, setCodingAssessmentQuestion] = useState(props.location.state.codingAssessmentDetails?.questionDetails[0].question);
    const [codingAssessmentDescription, setCodingAssessmentDescription] = useState(props.location.state.codingAssessmentDetails?.questionDetails[0].description);
    const [codingAssessmentMarks, setCodingAssessmentMarks] = useState(props.location.state.codingAssessmentDetails?.questionDetails[0].marks);
    const [userAnswers, setUserAnswers] = useState({});

    const handleCodingAssessmentNext = () => {
        if (currentIndexCodingAssessmentIndex < codingAssessmentDetails.questionDetails.length - 1) {
            setCurrentIndexCodingAssessmentIndex(currentIndexCodingAssessmentIndex + 1);
            setCodingAssessmentQuestionId(codingAssessmentDetails.questionDetails[currentIndexCodingAssessmentIndex + 1].codingQuestionId);
            setCodingAssessmentQuestion(codingAssessmentDetails.questionDetails[currentIndexCodingAssessmentIndex + 1].question);
            setCodingAssessmentDescription(codingAssessmentDetails.questionDetails[currentIndexCodingAssessmentIndex + 1].description);
            setCodingAssessmentMarks(codingAssessmentDetails.questionDetails[currentIndexCodingAssessmentIndex + 1].marks)
        }
    };

    const handleCodingAssessmentPrevious = () => {
        if (currentIndexCodingAssessmentIndex > 0) {
            setCurrentIndexCodingAssessmentIndex(currentIndexCodingAssessmentIndex - 1);
            setCodingAssessmentQuestionId(codingAssessmentDetails.questionDetails[currentIndexCodingAssessmentIndex - 1].codingQuestionId);
            setCodingAssessmentQuestion(codingAssessmentDetails.questionDetails[currentIndexCodingAssessmentIndex - 1].question);
            setCodingAssessmentDescription(codingAssessmentDetails.questionDetails[currentIndexCodingAssessmentIndex - 1].description);
            setCodingAssessmentMarks(codingAssessmentDetails.questionDetails[currentIndexCodingAssessmentIndex - 1].marks)
        }
    };

    //multiple tab switching in coding assessment
    const [countab, setcountab] = useState(0);

    //start assessment
    const startCodingAssessment = () => {
        try {
            const codingAssessmentSid = codingAssessmentDetails.codingAssessmentSid;
            const sectionSid = contentSid;
            const trainingSid = props.location.state.trainingSid;

            let payload = {
                "codingAssessmentSid": codingAssessmentSid,
                "sectionSid": sectionSid,
                "trainingSid": trainingSid
            }
            spinner.show();
            RestService.startCodingAssessment(payload).then(
                response => {
                    if (response.status === 200) {
                        Toast.success({ message: 'Assessment Started Successfully', time: 2000 });
                        setStartAssessmentStatus('Assessment Started Successfully');
                        startVideo();
                        setShowEditor(true);
                    }
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: 'Try Again', time: 2000 });
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on startCodingAssessment()", err)
        }
    }

    //submit coding assessment
    const submitCodingAssessment = () => {
        try {
            const codingAssessmentSid = codingAssessmentDetails.codingAssessmentSid;
            const sectionSid = contentSid;
            const trainingSid = props.location.state.trainingSid;

            let payload = {
                "codingAssessmentSid": codingAssessmentSid,
                "sectionSid": sectionSid,
                "trainingSid": trainingSid
            }
            spinner.show();
            RestService.submitCodingAssessment(payload).then(
                response => {
                    if (response.status === 200) {
                        stopProctorCamera();
                        Toast.success({ message: 'Assessment Submitted Successfully', time: 3000 });
                        if (faceviolationcount >= 5) {
                            markViolation();
                            // Toast.warning({ message: 'Multiple Faces Detected', time: 4000 });  
                        }
                        if (countab >= 10) {
                            markViolationCodingAssessment();
                            // Toast.warning({ message: 'Tab Switch Detected', time: 4000 });
                        }
                        // markCourseAsCompletedCode();
                        navigate('/training/training-details');
                    }
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: 'Try Again', time: 2000 });
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on submitCodingAssessment()", err)
        }
    }

    //validation for repo link for github only
    const validateRepoLink = (link) => {
        // Regular expression to match a GitHub repository URL
        const regex = /^(https:\/\/github\.com\/)([A-Za-z0-9_.-]+)\/([A-Za-z0-9_.-]+)$/;

        if (regex.test(link)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    };
    //handle repo link
    const handleInputChangeLink = (e) => {
        const inputValue = e.target.value;
        setRepoLink(inputValue);
        validateRepoLink(inputValue);
    };

    //handle file change and validation for zip format only
    const handleInputChangeFile = (e) => {
        const inputValue = e.target.files[0];
        if (inputValue !== undefined && inputValue.name.split('.')[1] !== 'zip') {
            setIsValidFile(false);
            setZipFile(inputValue);
        }
        else {
            setIsValidFile(true);
            setZipFile(inputValue);
        }

    };
    //for disable submit button
    const isDisabled = () => {

        if (isValid && isValidFile === undefined) {
            return false;
        }

        if (isValidFile) {
            if (isValid) {
                return false
            }
        }
        return true;
    }



    //submit project api for learner
    const submitProjectResponse = () => {
        try {
            spinner.show();
            let trainingSid = props.location.state.trainingSid;
            let formData = new FormData();
            const projectSubmission = {
                "projectId": projectId,
                "sectionSid": contentSid,
                "trainingSid": trainingSid,
                "repositoryLink": repoLink
            }
            const jsonProjectSubmission = JSON.stringify(projectSubmission)

            formData.append("project_submission", jsonProjectSubmission);

            // formData.append("zip_file", zipFile);
            typeof zipFile === 'object' && formData.append("zip_file", zipFile);

            RestService.submitProjectResponse(formData).then(res => {
                if (res.status === 200) {
                    Toast.success({ message: `Project Successfully Submitted`, time: 2000 });
                    spinner.hide();
                    markCourseAsCompletedProjects();
                    setRepoLink('');
                    navigate('/training/training-details');
                }

            }, err => {
                console.log(err);
                spinner.hide();
                Toast.error({ message: `Try Again`, time: 2000 });
            }
            );
        }
        catch (err) {
            console.error('error occur on submitProjectResponse', err)
        }
    }

    // const openWindow = () => {
    //     // Disable the button
    //     setIsButtonDisabled(true);

    //     // Open the window
    //     setOpenedWindow(window.open(startLabConnection));
    // }

    const handleNext = () => {
        if (currentIndex < pages.length - 1) {
            setCurrentIndex(currentIndex + 1);
            setLabName(pages[currentIndex + 1].childLabName);
            setLabDescription(pages[currentIndex + 1].childLabDescription);
            setLabOverview(pages[currentIndex + 1].childLabOverview);
            setEvaluatedLab(pages[currentIndex + 1].evaluatedLab);
            setStartLabConnection(startLabConnectionParentLabs[`${pages[currentIndex + 1].pageNumber}`]);
        }
    };

    const handlePrevious = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
            setLabName(pages[currentIndex - 1].childLabName);
            setLabDescription(pages[currentIndex - 1].childLabDescription);
            setLabOverview(pages[currentIndex - 1].childLabOverview);
            setEvaluatedLab(pages[currentIndex - 1].evaluatedLab);
            setStartLabConnection(startLabConnectionParentLabs[`${pages[currentIndex - 1].pageNumber}`]);
        }
    };

    //
    const handleToggleContent = () => {
        setIsContentVisible(!isContentVisible);
    };

    //for recording
    const [recordedVideoUrl, setRecordedVideoUrl] = useState(null);
    const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);
    const [screen, setScreen] = useState(null);
    const [camera, setCamera] = useState(null);
    const [recorder, setRecorder] = useState(null);
    const [startDisable, setStartDisable] = useState(false);
    const [stopDisable, setStopDisable] = useState(true);
    const [loadModal, setLoadModal] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [file, setFile] = useState(null);
    const [progress, setProgress] = useState(0);

    // variables for face detections 
    const [faceDetections, setFaceDetections] = useState(0);
    const [faceviolationcount, setFaceviolationcount] = useState(0);
    const [show, setShow] = useState(false);

    const videoRef = useRef();
    const streamRef = useRef();

    const Toast = useToast();
    const navigate = useNavigate();

    const iframeRef = useRef(null);

    let timestamp = new Date(localStorage.getItem("end_date") - Date.now());
    let splitMinutes = timestamp.toUTCString().slice(-11, -4).split(':');
    let minutes = (+splitMinutes[0]) * 60 + (+splitMinutes[1]);
    // strechable layout start
    const [leftWidth, setLeftWidth] = useState(30);
    const [speed, setSpeed] = useState("0");

    const handleMouseMove = (e) => {
        setLeftWidth(e.clientX / window.innerWidth * 100);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    const handleMouseDown = () => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    // strechable layout end

    // face detection methods starts
    const startVideo = () => {
        navigator.mediaDevices.getUserMedia({ video: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
                streamRef.current = stream;
                loadModels();
            })
            .catch((err) => {
                console.error(err);
                if (labType === "CODING_ASSESSMENT") {
                    submitCodingAssessment();
                    Toast.warning({ message: 'To access the assessment, please grant permission for camera access.', time: 4000 });
                }
            });
    };

    const loadModels = () => {
        Promise.all([
            faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
            faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
            faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
            faceapi.nets.faceExpressionNet.loadFromUri('/models'),
        ]).then(() => {
            faceDetection();
        })
    };

    const faceDetection = async () => {
        setInterval(async () => {
            const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();
            setFaceDetections(detections.length);

        }, 1000)
    }

    const stopProctorCamera = () => {
        if (streamRef.current) {
            const tracks = streamRef.current.getTracks();
            tracks.forEach((track) => track.stop());
        }
    };

    useEffect(() => {
        if (faceDetections >= 2) {
            setShow(true);
            setFaceviolationcount(prevCount => prevCount + 1);
            Toast.warning({ message: 'Multiple Faces Detected', time: 5000 });
        }
        if (faceviolationcount >= 5 && labType === "LAB") {
            terminateEC2InstanceAndTerminateGuacamoleServer();
        }
        if (faceviolationcount >= 5 && labType === "CODING_ASSESSMENT") {
            submitCodingAssessment();
            // navigate('/training/training-details');
        }

    }, [faceDetections])

    // face detection methods starts ends

    //recording method starts
    const captureCamera = (cb) => {
        navigator.mediaDevices
            .getUserMedia({
                audio: true,
                video: true, //make it  true for video
            })
            .then(cb)
            .catch((error) => {
                if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
                    console.error(
                        "You need to grant this page permission to access your camera and microphone.",
                    );
                    terminateLabsForDeniedPermission();
                    navigate('/training/training-details');
                    stop();
                } else if (error.name == "NotReadableError" || error.name == "TrackStartError") {

                    console.error('Webcam or mic are already in use');

                    terminateLabsForDeniedPermission();
                    navigate('/training/training-details');
                    stop();
                }
            });
    };

    const startScreenRecord = async () => {
        await setStopDisable(false);
        setStartDisable(true);

        captureScreen((screen) => {
            captureCamera(async (camera) => {
                screen.width = window.screen.width;
                screen.height = window.screen.height;
                screen.fullcanvas = true;
                camera.width = 320;
                camera.height = 240;
                camera.top = screen.height - camera.height;
                camera.left = screen.width - camera.width;

                setScreen(screen);
                setCamera(camera);

                if (camera !== null) {
                    const newRecorder = RecordRTC([screen, camera], {
                        type: "video",
                    });

                    newRecorder.startRecording();
                    newRecorder.screen = screen;

                    setRecorder(newRecorder);
                    startVideo();
                }

            });
        });
    };

    const captureScreen = (callback) => {
        invokeGetDisplayMedia(
            (screen) => {
                addStreamStopListener(screen, () => { });
                callback(screen);
            },
            (error) => {
                console.error(error);
                // alert(
                //     "Unable to capture your screen. Please check console logs.\n" + error
                // );
                setStopDisable(true);
                setStartDisable(false);
                terminateLabsForDeniedPermission();
                navigate('/training/training-details');
            }
        );
    };

    const stopLocalVideo = async (screen, camera) => {
        [screen, camera].forEach(async (stream) => {
            stream.getTracks().forEach(async (track) => {
                track.stop();
            });
        });
    };

    const invokeGetDisplayMedia = (success, error) => {
        var displaymediastreamconstraints = {
            video: {
                displaySurface: "monitor", // monitor, window, application, browser
                logicalSurface: true,
                cursor: "always", // never, always, motion
            },
        };
        displaymediastreamconstraints = {
            video: true,
            audio: true,
        };
        if (navigator.mediaDevices.getDisplayMedia) {
            navigator.mediaDevices
                .getDisplayMedia(displaymediastreamconstraints)
                .then(success)
                .catch(error);
        } else {
            navigator
                .getDisplayMedia(displaymediastreamconstraints)
                .then(success)
                .catch(error);
        }
    };

    const addStreamStopListener = (stream, callback) => {
        if (camera !== null) {
            stream.addEventListener(
                "ended",
                () => {
                    callback();
                    callback = () => { };
                },
                false
            );
            stream.addEventListener(
                "inactive",
                () => {
                    callback();
                    callback = () => { };
                },
                false
            );
            stream.getTracks().forEach((track) => {
                track.addEventListener(
                    "ended",
                    () => {
                        callback();
                        callback = () => { };
                    },
                    false
                );
                track.addEventListener(
                    "inactive",
                    () => {
                        callback();
                        callback = () => { };
                    },
                    false
                );
            });
            stream.getVideoTracks()[0].onended = () => {
                stop();
            };
        }
    };

    //stop share screen prompt
    const permission = (screen) => {
        [screen].forEach(async (stream) => {
            stream.getTracks().forEach(async (track) => {
                track.stop();
            });
        });
    }

    const stop = () => {
        if (camera === null) {
            recorder.stopRecording(permission(screen));
            recorder.stopRecording(permission(screen));
            recorder.screen.stop();
            recorder.destroy();
            recorder = null;
        }
        else {
            setStartDisable(true);
            recorder.stopRecording(stopRecordingCallback);
        }

    };

    const stopRecordingCallback = async () => {
        await stopLocalVideo(screen, camera);
        let recordedVideoUrl;
        let blob = recorder.getBlob();

        if (recorder.getBlob()) {
            setRecordedVideoUrl(URL.createObjectURL(recorder.getBlob()));
        }

        setScreen(null);
        setIsOpenVideoModal(true);
        setStartDisable(false);
        setStopDisable(true);
        setCamera(null);

        recorder.screen.stop();
        recorder.destroy();
        setRecorder(null);
        stopProctorCamera();

        var file = new File([blob], getFileName("mp4"), {
            type: "video/mp4",
        });

        uploadFile(file);
    };

    const videoModalClose = () => {
        setIsOpenVideoModal(false);
    };

    const openModal = async () => {
        await setLoadModal(false);
    };

    const getFileName = (fileExtension) => {
        let trainingSid = props.location.state.trainingSid;
        let userSid = JSON.parse(localStorage.getItem('user'))
        userSid = userSid.sid;

        return (
            `labrecording_${labId}_${trainingSid}_${userSid}.${fileExtension}`
        );
    };


    const uploadFile = async (file) => {
        const params = {
            ACL: 'public-read',
            Body: file,
            Bucket: S3_BUCKET,
            Key: file.name
        };

        myBucket.putObject(params)
            .on('httpUploadProgress', (evt) => {

                setstartsubmit(true)

                setProgress(Math.round((evt.loaded / evt.total) * 100));
                if (faceviolationcount >= 5) {
                    markViolation();
                }
                setTimeout(() => {
                    insertRecordingSeedDetails();
                    setcompletesubmit(true);

                    insertConnectionDetails();
                    navigate("/training", { state: { title: "Training" } })

                    console.log("Assessment Submitted")
                }, 5000);

            })
            .send((err, data) => {
                if (err) {
                    console.log("entered in Error block of S3 upload")

                    insertRecordingSeedDetails();
                    insertConnectionDetails();
                    setcompletesubmit(true);
                    navigate("/training", { state: { title: "Training" } })
                    console.log(err);
                }
            });
    };

    //save link to db

    const insertRecordingSeedDetails = () => {
        axios.post(GLOBELCONSTANT.HARD_CODED_API.INSERT_RECORDING_SEED_DETAILS, {}, {
            params: {
                recording_link: `${bucketUrl}${getFileName("mp4")}`
            }
        })
            .then(response => console.log(''))
            .catch(err => console.warn(err));
    }

    //start lab First Time or after completing means again attempting 
    const ec2GuacamolePOC = () => {
        // start lab for vscode 
        if (labIdArray.includes(labId)) {

            setStartLabConnection(vsCodeLink);
            setStopConnection(vsCodeLink);
            setStopServer('');
            localStorage.removeItem(`stopServer_${labId}_${labName}`);
            // setStarting('Starting');
            setTimeout(function () {

                setShowButton(true);
                setIsLoading(false);
                Toast.success({ message: 'Lab Started Successfully', time: 3000 });
                localStorage.setItem(`connectionString_${labId}_${labName}`, vsCodeLink);
                if (evaluatedLab) {
                    startScreenRecord();
                }

            }, 20000);
        }
        else {
            try {
                let trainingSid = props.location.state.trainingSid;
                let sectionSid = contentSid
                spinner.show();
                RestService.ec2GuacamolePOC(labId, sectionSid, trainingSid).then(
                    response => {
                        if (response.status === 200) {
                            console.log(typeof (response.data));
                            if (typeof(response.data) === "object" && 'loginUrl' in response.data) {
                                console.log("inside pratice");
                                setStartLabConnection(response.data.loginUrl);
                                setStopConnection(response.data.loginUrl);
                            }
                            else if (typeof (response.data) === "object") {
                                console.log("inside:#");
                                setStartLabConnection(response.data[`${currentIndex + 1}`]);
                                if (response.data[`${currentIndex + 1}`].indexOf('#') > -1) {
                                    setStopConnection(response.data[`${currentIndex + 1}`].split('#')[1]);
                                }
                                else {

                                    setStopConnection(response.data[`${currentIndex + 1}`].split(':')[1].substring(2));
                                }
                            }

                            else {
                                console.log("inside pratice");
                                setStartLabConnection(response.data);
                                if (response.data.indexOf('#') > -1) {
                                    setStopConnection(response.data.split('#')[1]);
                                }
                                else {

                                    setStopConnection(response.data.split(':')[1].substring(2));
                                }
                            }
                            setStopServer('');
                            localStorage.removeItem(`stopServer_${labId}_${labName}`);
                            // setStarting('Starting');
                            setTimeout(function () {

                                setShowButton(true);
                                setIsLoading(false);
                                if (typeof(response.data) === 'object' && 'loginUrl' in response.data) {
                                    localStorage.setItem(`connectionString_${labId}_${labName}`, response.data.loginUrl);
                                }
                                else if (typeof (response.data) === "object") {

                                    localStorage.setItem(`connectionString_${labId}_${labName}`, response.data[`${currentIndex + 1}`]);
                                    setLocalStorageIndex(currentIndex);
                                    setStartLabConnectionParentLabs(response.data);
                                }
                                else {
                                    localStorage.setItem(`connectionString_${labId}_${labName}`, response.data);
                                }
                                Toast.success({ message: 'Lab Started Successfully', time: 3000 });

                                // localStorage.setItem('appearButton', true);
                                if (evaluatedLab) {
                                    startScreenRecord();
                                }
                            }, 20000);
                            // setLabConnection(response.data.split('#')[1]);
                            spinner.hide();
                        }
                    },
                    err => {
                        spinner.hide();
                        Toast.error({ message: 'Try again', time: 2000 });
                    }
                )
            } catch (err) {
                spinner.hide();
                console.error("error occur on ec2GuacamolePOC()", err)
            }
        }

    }

    console.log(startLabConnection);

    //stop lab 
    const stopEC2InstanceAndTerminateGuacamoleServer = () => {
        try {
            spinner.show();
            if (startLabConnection.length > 0) {
                const connectionCheckString = startLabConnection.indexOf('#') > -1 ?
                    startLabConnection.split('#')[1] : startLabConnection.split(':')[1].substring(2);
                RestService.stopEC2InstanceAndTerminateGuacamoleServer(connectionCheckString).then(
                    response => {
                        Toast.success({ message: 'Lab paused successfully', time: 3000 });
                        setStartLabConnection('');

                        setStopServer(response.data);
                        localStorage.setItem(`stopServer_${labId}_${labName}`, response.data);

                        // setLabConnection('');
                        // localStorage.removeItem('appearButton');
                        // localStorage.removeItem('connectionString');
                    },
                    err => {
                        Toast.error({ message: 'Try again', time: 3000 });
                        spinner.hide();
                    }
                ).finally(() => {
                    spinner.hide();
                });
            }
            else if (stopConnection.length > 0) {
                RestService.stopEC2InstanceAndTerminateGuacamoleServer(stopConnection).then(
                    response => {
                        Toast.success({ message: 'Lab paused successfully', time: 3000 });
                        setStopServer(response.data);
                        localStorage.setItem(`stopServer_${labId}_${labName}`, response.data);
                        // localStorage.removeItem('appearButton');
                        // localStorage.removeItem('connectionString');
                    },
                    err => {
                        spinner.hide();
                        Toast.error({ message: 'Try again', time: 3000 });
                    }
                ).finally(() => {
                    spinner.hide();
                });

            }
            else {
                const connectionString = localStorage.getItem(`connectionString_${labId}_${labName}`);
                const connectionCheckString = connectionString.indexOf('#') > -1 ?
                    connectionString.split('#')[1] : connectionString.split(':')[1].substring(2);
                RestService.stopEC2InstanceAndTerminateGuacamoleServer(connectionCheckString).then(
                    response => {
                        Toast.success({ message: 'Lab paused successfully', time: 3000 });
                        setStopServer(response.data);
                        localStorage.setItem(`stopServer_${labId}_${labName}`, response.data);
                        // localStorage.removeItem('appearButton');
                        // localStorage.removeItem('connectionString');
                    },
                    err => {
                        spinner.hide();
                        Toast.error({ message: 'Try again', time: 3000 });
                    }
                ).finally(() => {
                    spinner.hide();
                });
            }

        } catch (err) {
            console.error("error occur on stopEC2InstanceAndTerminateGuacamoleServer()", err)
        }
    }

    //terminate lab 
    const terminateEC2InstanceAndTerminateGuacamoleServer = () => {
        const sectionSid = contentSid;
        const trainingSid = props.location.state.trainingSid;
        // stop lab for vscode 
        if (labIdArray.includes(labId)) {
            Toast.success({ message: 'Lab completed successfully', time: 3000 });
            setStartLabConnection('');
            setStopConnection('');
            setShowButton(false);
            markCourseAsCompletedLabs();
            localStorage.removeItem("end_date");
            localStorage.removeItem(`connectionString_${labId}_${labName}`);
            localStorage.removeItem(`stopServer_${labId}_${labName}`);
            // localStorage.removeItem('GUAC_AUTH');
            setOffStartButton(true);
            if (evaluatedLab) {
                stop();
            }
        }
        else {
            try {
                spinner.show();
                if (cloudLabUserUniqueId !== null) {

                    const connectionString = "1234";

                    RestService.terminateEC2InstanceAndTerminateGuacamoleServer(connectionString, labId, sectionSid, trainingSid).then(
                        response => {
                            Toast.success({ message: 'Lab completed successfully', time: 3000 });
                            setStopConnection('');
                            setShowButton(false);
                            markCourseAsCompletedLabs();
                            localStorage.removeItem(`connectionString_${labId}_${labName}`);
                            localStorage.removeItem('connectionString');
                            localStorage.removeItem("end_date");
                            localStorage.removeItem(`stopServer_${labId}_${labName}`);
                            // localStorage.removeItem('GUAC_AUTH');
                            setOffStartButton(true);
                            if (evaluatedLab) {
                                stop();
                            }
                            closeOpenedWindow();
                        },
                        err => {
                            spinner.hide();
                            Toast.error({ message: 'Try again', time: 3000 });
                        }
                    ).finally(() => {
                        spinner.hide();
                    });

                }
                else if (startLabConnectionParentLabs !== null) {
                    const connectionCheckString = startLabConnectionParentLabs['1'].indexOf('#') > -1 ?
                        startLabConnectionParentLabs['1'].split('#')[1] : startLabConnectionParentLabs['1'].split(':')[1].substring(2);
                    RestService.terminateEC2InstanceAndTerminateGuacamoleServer(connectionCheckString, labId, sectionSid, trainingSid).then(
                        response => {
                            Toast.success({ message: 'Lab completed successfully', time: 3000 });
                            setStartLabConnection('');
                            setStopConnection('');
                            setShowButton(false);
                            markCourseAsCompletedLabs();
                            localStorage.removeItem('appearButton');
                            localStorage.removeItem(`connectionString_${labId}_${pages[localStorageIndex].childLabName}`);
                            localStorage.removeItem("end_date");
                            localStorage.removeItem(`stopServer_${labId}_${labName}`);
                            // localStorage.removeItem('GUAC_AUTH');
                            setOffStartButton(true);
                            if (evaluatedLab) {
                                stop();
                            }

                            // setLabConnection('');
                        },
                        err => {
                            spinner.hide();
                            Toast.error({ message: 'Try again', time: 3000 });
                        }
                    ).finally(() => {
                        spinner.hide();
                    });
                }
                else if (startLabConnection.length > 0) {
                    const connectionCheckString = startLabConnection.indexOf('#') > -1 ?
                        startLabConnection.split('#')[1] : startLabConnection.split(':')[1].substring(2);
                    RestService.terminateEC2InstanceAndTerminateGuacamoleServer(connectionCheckString, labId, sectionSid, trainingSid).then(
                        response => {
                            Toast.success({ message: 'Lab completed successfully', time: 3000 });
                            setStartLabConnection('');
                            setStopConnection('');
                            setShowButton(false);
                            markCourseAsCompletedLabs();
                            localStorage.removeItem('appearButton');
                            localStorage.removeItem(`connectionString_${labId}_${labName}`);
                            localStorage.removeItem("end_date");
                            localStorage.removeItem(`stopServer_${labId}_${labName}`);
                            // localStorage.removeItem('GUAC_AUTH');
                            setOffStartButton(true);
                            if (evaluatedLab) {
                                stop();
                            }

                            // setLabConnection('');
                        },
                        err => {
                            spinner.hide();
                            Toast.error({ message: 'Try again', time: 3000 });
                        }
                    ).finally(() => {
                        spinner.hide();
                    });
                } else if (stopConnection.length > 0) {
                    RestService.terminateEC2InstanceAndTerminateGuacamoleServer(stopConnection, labId, sectionSid, trainingSid).then(
                        response => {
                            Toast.success({ message: 'Lab completed successfully', time: 3000 });
                            setStopConnection('');
                            setShowButton(false);
                            markCourseAsCompletedLabs();
                            localStorage.removeItem('appearButton');
                            localStorage.removeItem(`connectionString_${labId}_${labName}`);
                            localStorage.removeItem("end_date");
                            localStorage.removeItem(`stopServer_${labId}_${labName}`);
                            // localStorage.removeItem('GUAC_AUTH');
                            setOffStartButton(true);
                            if (evaluatedLab) {
                                stop();
                            }

                        },
                        err => {
                            spinner.hide();
                            Toast.error({ message: 'Try again', time: 3000 });
                        }
                    ).finally(() => {
                        spinner.hide();
                    });
                }
                else {
                    // const connectionString = localStorage.getItem(`connectionString_${labId}_${labName}`);
                    const connectionString = localStorage.getItem(`connectionString_${labId}_${labName}`);
                    const connectionCheckString = connectionString.indexOf('#') > -1 ?
                        connectionString.split('#')[1] : connectionString.split(':')[1].substring(2);
                    RestService.terminateEC2InstanceAndTerminateGuacamoleServer(connectionCheckString, labId, sectionSid, trainingSid).then(
                        response => {
                            Toast.success({ message: 'Lab completed successfully', time: 3000 });
                            setStopConnection('');
                            setShowButton(false);
                            markCourseAsCompletedLabs();
                            localStorage.removeItem(`connectionString_${labId}_${labName}`);
                            localStorage.removeItem('connectionString');
                            localStorage.removeItem("end_date");
                            localStorage.removeItem(`stopServer_${labId}_${labName}`);
                            // localStorage.removeItem('GUAC_AUTH');
                            setOffStartButton(true);
                            if (evaluatedLab) {
                                stop();
                            }
                        },
                        err => {
                            spinner.hide();
                            Toast.error({ message: 'Try again', time: 3000 });
                        }
                    ).finally(() => {
                        spinner.hide();
                    });
                }
            } catch (err) {
                console.error("error occur on terminateEC2InstanceAndTerminateGuacamoleServer()", err)
            }
        }
    }

    //stop lab and terminate for practice lab only
    const stopEC2InstanceAndTerminateGuacamoleServerPractice = async () => {
        try {
            spinner.show();
            if (startLabConnection.length > 0) {
                const connectionCheckString = startLabConnection.indexOf('#') > -1 ?
                    startLabConnection.split('#')[1] : startLabConnection.split(':')[1].substring(2);
                RestService.stopEC2InstanceAndTerminateGuacamoleServer(connectionCheckString).then(
                    response => {
                        Toast.success({ message: 'Lab completed successfully', time: 3000 });
                        setIsLoading(true);
                        setStartLabConnection('');
                        setStopConnection('');
                        setShowButton(false);
                        markCourseAsCompletedLabs();
                        localStorage.removeItem('appearButton');
                        localStorage.removeItem(`connectionString_${labId}_${labName}`);
                        localStorage.removeItem("end_date");
                        localStorage.removeItem(`stopServer_${labId}_${labName}`);
                        setStopServer('');
                        // localStorage.removeItem('GUAC_AUTH');
                        setOffStartButton(true);
                    },
                    err => {
                        Toast.error({ message: 'Try again', time: 3000 });
                        spinner.hide();
                    }
                ).finally(() => {
                    spinner.hide();
                });
            }
            else if (stopConnection.length > 0) {
                RestService.stopEC2InstanceAndTerminateGuacamoleServer(stopConnection).then(
                    response => {
                        Toast.success({ message: 'Lab completed successfully', time: 3000 });
                        setIsLoading(true);
                        setStopConnection('');
                        setShowButton(false);
                        markCourseAsCompletedLabs();
                        localStorage.removeItem('appearButton');
                        localStorage.removeItem(`connectionString_${labId}_${labName}`);
                        localStorage.removeItem("end_date");
                        localStorage.removeItem(`stopServer_${labId}_${labName}`);
                        setStopServer('');
                        // localStorage.removeItem('GUAC_AUTH');
                        setOffStartButton(true);
                    },
                    err => {
                        spinner.hide();
                        Toast.error({ message: 'Try again', time: 3000 });
                    }
                ).finally(() => {
                    spinner.hide();
                });

            }
            else {
                const connectionString = localStorage.getItem(`connectionString_${labId}_${labName}`);
                const connectionCheckString = connectionString.indexOf('#') > -1 ?
                    connectionString.split('#')[1] : connectionString.split(':')[1].substring(2);
                RestService.stopEC2InstanceAndTerminateGuacamoleServer(connectionCheckString).then(
                    response => {
                        Toast.success({ message: 'Lab completed successfully', time: 3000 });
                        setIsLoading(true);
                        setStopConnection('');
                        setShowButton(false);
                        markCourseAsCompletedLabs();
                        localStorage.removeItem(`connectionString_${labId}_${labName}`);
                        localStorage.removeItem('connectionString');
                        localStorage.removeItem("end_date");
                        localStorage.removeItem(`stopServer_${labId}_${labName}`);
                        setStopServer('');
                        // localStorage.removeItem('GUAC_AUTH');
                        setOffStartButton(true);
                    },
                    err => {
                        spinner.hide();
                        Toast.error({ message: 'Try again', time: 3000 });
                    }
                ).finally(() => {
                    spinner.hide();
                });
            }

        } catch (err) {
            console.error("error occur on stopEC2InstanceAndTerminateGuacamoleServerPractice()", err)
        }
    }
    //mark course as complete labs
    const markCourseAsCompletedLabs = () => {
        try {
            let trainingSid = props.location.state.trainingSid;
            let timestamp = new Date(localStorage.getItem("end_date") - Date.now());
            let splitMinutes = timestamp.toUTCString().match(/\d{2}:\d{2}:\d{2}/)[0];
            // let hour = Number(splitMinutes.split(':')[0]);
            // let minutes = Number(splitMinutes.split(':')[1]);
            // let minutes = timestamp.getMinutes();
            // const seconds = Number(splitMinutes.split(':')[2]);
            // let actualTimeInHour = hour === 0 ?  0 : hour * 60;
            // let actualTimeInMinutes = minutes > 0 ? minutes : 0;
            // let actualTime = actualTimeInHour + minutes;
            // let actualTimeInSeconds = minutes === 0 ? 0  : ;
            let minutes = Number(splitMinutes.split(':')[0])
                * 60 + Number(splitMinutes.split(':')[1]);
            let payload = {
                "completedInDuration": labDuration - minutes,
                "totalDuration": labDuration
            }

            spinner.show();
            RestService.markCourseAsCompletedLabs(labId, contentSid, trainingSid, payload).then(
                response => {

                    if (response.status === 200) {
                        console.log('');

                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on markCourseAsCompletedLabs()", err)
        }
    }

    //mark project as complete
    const markCourseAsCompletedProjects = () => {
        try {
            let trainingSid = props.location.state.trainingSid;
            let payload = {
                "completedInDuration": 0,
                "totalDuration": 0
            }
            spinner.show();
            RestService.markCourseAsCompletedProject(projectId, contentSid, trainingSid, payload).then(
                response => {

                    if (response.status === 200) {
                        console.log('');

                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on markCourseAsCompletedLabs()", err)
        }
    }

    //terminate the labs for assessment for users not giving sharing permission
    const terminateLabsForDeniedPermission = () => {
        const sectionSid = contentSid;
        const trainingSid = props.location.state.trainingSid;
        // stop lab for vscode 
        if (labIdArray.includes(labId)) {
            Toast.warning({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
            setStartLabConnection('');
            setStopConnection('');
            setShowButton(false);
            // markCourseAsCompletedLabs();
            localStorage.removeItem("end_date");
            localStorage.removeItem(`connectionString_${labId}_${labName}`);
            localStorage.removeItem(`stopServer_${labId}_${labName}`);
            // localStorage.removeItem('GUAC_AUTH');
            setOffStartButton(true);
            // if (evaluatedLab) {
            //     stop();
            // }
        }
        else {
            try {
                spinner.show();
                if (cloudLabUserUniqueId !== null) {

                    const connectionString = "1234";
                    RestService.terminateEC2InstanceAndTerminateGuacamoleServer(connectionString, labId, sectionSid, trainingSid).then(
                        response => {
                            Toast.success({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
                            setStopConnection('');
                            setShowButton(false);
                            // markCourseAsCompletedLabs();
                            localStorage.removeItem(`connectionString_${labId}_${labName}`);
                            localStorage.removeItem('connectionString');
                            localStorage.removeItem("end_date");
                            localStorage.removeItem(`stopServer_${labId}_${labName}`);
                            // localStorage.removeItem('GUAC_AUTH');
                            setOffStartButton(true);
                            closeOpenedWindow();
                            // if (evaluatedLab) {
                            //     stop();
                            // }
                        },
                        err => {
                            spinner.hide();
                            Toast.error({ message: 'Try again', time: 3000 });
                        }
                    ).finally(() => {
                        spinner.hide();
                    });
                }
                else if (startLabConnection.length > 0 && !parentLab) {
                    const connectionCheckString = startLabConnection.indexOf('#') > -1 ?
                        startLabConnection.split('#')[1] : startLabConnection.split(':')[1].substring(2);
                    RestService.terminateEC2InstanceAndTerminateGuacamoleServer(connectionCheckString, labId, sectionSid, trainingSid).then(
                        response => {
                            Toast.success({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
                            setStartLabConnection('');
                            setStopConnection('');
                            setShowButton(false);
                            // markCourseAsCompletedLabs();
                            localStorage.removeItem('appearButton');
                            localStorage.removeItem(`connectionString_${labId}_${labName}`);
                            localStorage.removeItem("end_date");
                            localStorage.removeItem(`stopServer_${labId}_${labName}`);
                            // localStorage.removeItem('GUAC_AUTH');
                            setOffStartButton(true);
                            // if (evaluatedLab) {
                            //     stop();
                            // }

                            // setLabConnection('');
                        },
                        err => {
                            spinner.hide();
                            Toast.error({ message: 'Try again', time: 3000 });
                        }
                    ).finally(() => {
                        spinner.hide();
                    });
                }
                else if (stopConnection.length > 0) {
                    RestService.terminateEC2InstanceAndTerminateGuacamoleServer(stopConnection, labId, sectionSid, trainingSid).then(
                        response => {
                            Toast.success({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
                            setStopConnection('');
                            setShowButton(false);
                            // markCourseAsCompletedLabs();
                            localStorage.removeItem('appearButton');
                            localStorage.removeItem(`connectionString_${labId}_${labName}`);
                            localStorage.removeItem("end_date");
                            localStorage.removeItem(`stopServer_${labId}_${labName}`);
                            // localStorage.removeItem('GUAC_AUTH');
                            setOffStartButton(true);
                            // if (evaluatedLab) {
                            //     stop();
                            // }

                        },
                        err => {
                            spinner.hide();
                            Toast.error({ message: 'Try again', time: 3000 });
                        }
                    ).finally(() => {
                        spinner.hide();
                    });
                }

                else {
                    // const connectionString = localStorage.getItem(`connectionString_${labId}_${labName}`);
                    const connectionString = localStorage.getItem(`connectionString_${labId}_${labName}`);
                    const connectionCheckString = connectionString.indexOf('#') > -1 ?
                        connectionString.split('#')[1] : connectionString.split(':')[1].substring(2);
                    RestService.terminateEC2InstanceAndTerminateGuacamoleServer(connectionCheckString, labId, sectionSid, trainingSid).then(
                        response => {
                            Toast.success({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
                            setStopConnection('');
                            setShowButton(false);
                            // markCourseAsCompletedLabs();
                            localStorage.removeItem(`connectionString_${labId}_${labName}`);
                            localStorage.removeItem('connectionString');
                            localStorage.removeItem("end_date");
                            localStorage.removeItem(`stopServer_${labId}_${labName}`);
                            // localStorage.removeItem('GUAC_AUTH');
                            setOffStartButton(true);
                            // if (evaluatedLab) {
                            //     stop();
                            // }
                        },
                        err => {
                            spinner.hide();
                            Toast.error({ message: 'Try again', time: 3000 });
                        }
                    ).finally(() => {
                        spinner.hide();
                    });
                }
            } catch (err) {
                console.error("error occur on terminateEC2InstanceAndTerminateGuacamoleServer()", err)
            }
        }
    }

    // mark violation for assessment labs
    const markViolation = () => {
        try {
            let trainingSid = props.location.state.trainingSid;
            let assessmentType = labType;
            let payload = {
                "trainingSid": trainingSid,
                "violationCode": "Multiple faces were detected for 5 times."
            }
            if (labType === "LAB") {
                payload["labId"] = labId
            }
            else {
                payload["labcodingAssessmentSidId"] = codingAssessmentDetails.codingAssessmentSid;
            }

            RestService.markViolation(assessmentType, payload).then(
                response => {

                    if (response.status === 200) {
                        console.log("Marked Violated")
                    }
                },
                err => {
                    console.log(err);
                }
            )
        } catch (err) {
            console.error("error occur on markViolation()", err)
        }
    }

    // mark violation for coding assessment
    const markViolationCodingAssessment = () => {
        try {
            const codingAssessmentSid = codingAssessmentDetails.codingAssessmentSid;
            let trainingSid = props.location.state.trainingSid;
            let assessmentType = labType;
            let payload = {
                "labId": codingAssessmentSid,
                "trainingSid": trainingSid,
                "violationCode": "More than 5 tab switches detected"
            }
            RestService.markViolation(assessmentType, payload).then(
                response => {

                    if (response.status === 200) {
                        // console.log("Marked Violated");
                        // navigate("/training/training-details");
                    }
                },
                err => {
                    console.log(err);
                }
            )
        } catch (err) {
            console.error("error occur on markViolationCodingAssessment()", err)
        }
    }

    //insertConnectionDetails for error block for s3
    const insertConnectionDetails = () => {
        try {
            let trainingSid = props.location.state.trainingSid;
            let payload = {
                "completionProgress": progress,
                "connectionBandwithDetails": "0",
                "connectionDeviceDetails": "0",
                "labId": labId,
                "systemDetails": "0",
                "trainingSid": trainingSid
            }
            RestService.insertConnectionDetails(payload).then(
                response => {

                    if (response.status === 200) {
                        console.log("error insertion for s3 upload")
                    }
                },
                err => {
                    console.log(err);
                }
            )
        } catch (err) {
            console.error("error occur on insertConnectionDetails()", err)
        }

    }


    useEffect(() => {
        props.location.state.codingQuestiondesc ?
            setcodingQuestiondesc(props.location.state.codingQuestiondesc) : setcodingQuestiondesc(false)

    }, []);

    //go back to training page
    const handleGoToTrainingDetails = () => {

        if (evaluatedLab && startLabConnection.length > 0) {
            const response = window.confirm("Are you sure you want to terminate this lab?");
            if (response) {
                terminateEC2InstanceAndTerminateGuacamoleServer();
                navigate("/training/training-details");

            }

            //this will terminate labs for recording assessment labs

        }
        else if (startAssessmentStatus.length > 0) {
            const response = window.confirm("Are you sure you want to submit this assessment?");
            if (response) {
                submitCodingAssessment();
            }
        }
        else if (bookmark) {
            navigate("/bookmarks");
        }
        else {
            navigate("/training/training-details");
        }
    }

    //restrict browser back button
    useEffect(() => {
        const handlePopstate = () => {
            // Redirect the user if they try to navigate back to the restricted page
            if (window.location.pathname === '/training/training-details' ||
                window.location.pathname === '/bookmarks'
            ) {
                navigate('/labs');
            }
        };

        // Listen for the 'popstate' event
        window.addEventListener('popstate', handlePopstate);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('popstate', handlePopstate);
        };
    }, []);

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (iframeRef.current && !iframeRef.current.contains(event.target)) {
                iframeRef.current.focus();
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    useEffect(() => {
        const connectionStringKey = `connectionString_${labId}_${labName}`;

        if (localStorage.getItem(connectionStringKey)) {
            ec2GuacamolePOC();
        }
    }, []);



    // FullScreen Lab 
    const toggleFullscreen = useCallback(() => {
        const element = document.getElementById('container');

        if (
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullScreenElement ||
            document.msFullscreenElement
        ) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) {
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) {
                document.msExitFullscreen();
            }
            setIsFullscreen(false);
        } else {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
            setIsFullscreen(true);
        }
    }, []);

    const openWindow = () => {
        // Disable the button
        setIsButtonDisabled(true);
    
        // Open the window
        const newWindow = window.open(startLabConnection);
        setOpenedWindow(newWindow);

        // Set up a timer to periodically check if the window is closed
        const checkClosedInterval = setInterval(() => {
          if (newWindow.closed) {
            // Enable the button when the window is closed
            setIsButtonDisabled(false);
            clearInterval(checkClosedInterval);
          }
        }, 1000);
      };

    const closeOpenedWindow = () => {
        // Close the window if it is open
        if (openedWindow) {
            openedWindow.close();
        }

        // Enable the button
        setIsButtonDisabled(false);
    }

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (labType === "CODING_ASSESSMENT") {
                if (document.visibilityState === 'visible') {
                    // Tab is visible

                } else {
                    // Tab is hidden
                    setcountab(prevCount => prevCount + 1);
                    Toast.warning({ message: 'Tab Switch Detected', time: 5000 });


                }
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [labType]);

    useEffect(() => {
        // const violationMessage = "More than 5 tab switches detected";
        if (countab >= 10 && labType === "CODING_ASSESSMENT") {
            submitCodingAssessment();

        }
    }, [countab, labType])

    const handleWindowUnload = () => {
        if (openedWindow) {
          setIsButtonDisabled(false);
          setOpenedWindow(null);
        }
      };

      useEffect(() => {
        window.addEventListener('beforeunload', handleWindowUnload);
    
        // Cleanup the event listener when the component unmounts
        return () => {
          window.removeEventListener('beforeunload', handleWindowUnload);
        };
      }, [openedWindow]);

    console.log(openedWindow);
    return (



        <div >
            {

                startsubmit && completesubmit === false ? <CompleteProgressBar />
                    :
                    faceDetections >= 2 ?
                        <BsModal {...{ show, setShow, headerTitle: "Attention: Multiple faces detected!", size: "md" }} />
                        :
                        <FocusTrap active={true}>

                            <div style={{ display: 'flex', height: '100vh', background: "#e9ecef" }} >

                                {/* <p className='text-center'><Link to="/training/training-details"  >{ICN_BACK}Go Back </Link></p> */}
                                <div style={{ cursor: "pointer", padding: "10px" }} className={`title-md ${isContentVisible ? 'mah' : ''}`} onClick={handleToggleContent}>

                                    <MenuTwoToneIcon />
                                </div>
                                {isContentVisible && (
                                    <div className="jumbotron pl-5 lab" style={{ width: `${leftWidth}%`, height: '100%', overflow: 'auto' }}>
                                        {/* {showButton || localStorage.getItem('appearButton') ?
              evaluatedLab && <ScreenRecording trainingSid={props.location.state.trainingSid} sectionSid={contentSid} labId={labId} />
              : ''} */}
                                        <div className=" title-sm" style={{ float: "right", cursor: "pointer", marginTop: "-50px" }} onClick={handleGoToTrainingDetails}>{ICN_BACK}Go back</div>
                                        <h3 className="text-center" style={{ fontSize: "18px", fontWeight: "bold" }}>{labName}</h3>
                                        {
                                            labType === "CODING_ASSESSMENT" &&
                                            <p className="title-md text-center">{codingAssessmentDetails.totalMarks} Marks</p>
                                        }

                                        {
                                            parentLab &&
                                            <>
                                                <hr />

                                                <div style={{ display: "flex", justifyContent: "space-between", bottom: "0px" }}>

                                                    <button className="btn btn-primary" onClick={handlePrevious} disabled={currentIndex === 0} style={{ float: "left" }}>Previous</button>
                                                    <button className="btn btn-primary" onClick={handleNext} disabled={currentIndex === pages.length - 1} style={{ float: "right" }}>Next</button>
                                                </div>
                                                {
                                                    cloudLabUserUniqueId !== null &&
                                                    <>
                                                        <div className='d-flex mt-3 title-sm p-2 ' style={{ border: "0.3px solid #fff", borderRadius: "10px", background: "#EDEDED", textAlign: "center" }}>
                                                            <div className="title-sm">UUID</div>
                                                            <div className="title-sm mx-3" style={{ textAlign: "right" }}>{cloudLabUserUniqueId}</div>
                                                        </div>

                                                    </>}
                                            </>
                                        }
                                        <hr />
                                        <br />
                                        <h5 style={{ fontSize: "18px", fontWeight: "bold" }}>{codingAssessmentDetails !== null ? codingAssessmentQuestion : "Lab Description"}</h5>
                                        {
                                            labType === "CODING_ASSESSMENT" &&
                                            <p className="title-md text-center">{codingAssessmentMarks} Marks</p>
                                        }
                                        {codingQuestiondesc !== false ? (
                                            <ReactMarkdown>{codingQuestiondesc}</ReactMarkdown>


                                        ) :
                                            labType === "PROJECT" ?
                                                parse(labDescription)
                                                :
                                                labType === "CODING_ASSESSMENT" ?
                                                    <>
                                                        <hr />
                                                        <div style={{ display: "flex", justifyContent: "space-between", bottom: "0px" }}>

                                                            <button className="btn btn-primary" onClick={handleCodingAssessmentPrevious} disabled={currentIndexCodingAssessmentIndex === 0} style={{ float: "left" }}>Previous</button>
                                                            <button className="btn btn-primary" onClick={handleCodingAssessmentNext} disabled={currentIndexCodingAssessmentIndex === codingAssessmentDetails.questionDetails.length - 1} style={{ float: "right" }}>Next</button>

                                                        </div>
                                                    </>
                                                    :
                                                    (
                                                        // <ReactMarkdown>{labDescription}</ReactMarkdown>
                                                        <>
                                                            <h5 style={{ fontSize: "18px", fontWeight: "bold" }}>Objective</h5>
                                                            {parse(labDescription)}
                                                        </>
                                                    )}
                                        <br />
                                        <hr />

                                        {codingQuestiondesc !== false || labType === "PROJECT" ?
                                            ""
                                            :
                                            labType === "CODING_ASSESSMENT" ?
                                                <>
                                                    <h5 style={{ fontSize: "18px", fontWeight: "bold" }}>Problem Statement</h5>
                                                    {/* <ReactMarkdown>{labOverview}</ReactMarkdown> */}

                                                    <ReactMarkdown>{codingAssessmentDescription}</ReactMarkdown>


                                                </>
                                                :
                                                <>

                                                    <h5 style={{ fontSize: "18px", fontWeight: "bold" }}>Lab Steps</h5>

                                                    {parse(labOverview)}


                                                </>
                                        }
                                        {/* <p>Lab Solution : &nbsp; {labSolution}</p><br /> */}





                                    </div>
                                )}
                                {
                                    labType === 'CODING' ?
                                        <div className="col-9 mainbody" >
                                            {
                                                !showEditor ? <button className="btn btn-primary mt-3" style={{ color: "#fff", fontSize: "15px" }} onClick={() => { setShowEditor(true) }}>Start Lab</button>
                                                    : <div className="btn btn-primary mt-3" style={{ color: "#fff", fontSize: "15px" }}>Started</div>
                                            }

                                            {
                                                showEditor ?
                                                    <CodeEditor {...{
                                                        trainingSid: props.location.state.trainingSid,
                                                        codingQuestionId: props.location.state.codingQuestionId,
                                                        sectionSid: props.location.state.contentSid,
                                                        labType: labType,
                                                        labDuration: labDuration
                                                    }} />
                                                    : <div className="title-sm mt-3">Please Click on Start Lab</div>
                                            }
                                        </div>
                                        :
                                        labType === 'CODING_ASSESSMENT' ?
                                            <div className="col-9 mainbody" >
                                                {
                                                    !showEditor ? <button className="btn btn-primary mt-3" style={{ color: "#fff", fontSize: "15px" }}
                                                        onClick={() => { startCodingAssessment(); }}>Start Assessment</button>
                                                        : <div className="btn btn-primary mt-3" style={{ color: "#fff", fontSize: "15px" }}>Started</div>
                                                }

                                                {
                                                    showEditor ?
                                                        <CodeEditor {...{
                                                            trainingSid: props.location.state.trainingSid,
                                                            codingQuestionId: codingAssessmentQuestionId,
                                                            sectionSid: props.location.state.contentSid,
                                                            labType: labType,
                                                            labDuration: labDuration,
                                                            codingAssessmentSid: codingAssessmentDetails.codingAssessmentSid,
                                                            submitCodingAssessment,
                                                            userAnswers,
                                                            setUserAnswers
                                                        }} />
                                                        : <div className="title-sm mt-3">Please Click on Start Assessment</div>
                                                }
                                            </div>
                                            :
                                            labType === "PROJECT" ?
                                                <div className=" mainbody ml-2" style={{ flex: '1', height: '100%', overflow: 'auto', background: "#F1F3F4" }}>
                                                    <div className=' p-5 h-75' >
                                                        <>

                                                            <div className="container">
                                                                <div className="row mx-1 mb-3">
                                                                    <div className="col-6">
                                                                        <label className="mb-2 title-sm">Repository Link*</label>
                                                                        <input
                                                                            type="text"
                                                                            className="form-control"
                                                                            placeholder="Enter Git repository URL"
                                                                            value={repoLink}
                                                                            onChange={handleInputChangeLink}
                                                                        />
                                                                        {isValid === undefined ?
                                                                            ""
                                                                            :
                                                                            isValid === false &&
                                                                            (
                                                                                <p style={{ color: "red" }}>Invalid GitHub Repository Link</p>
                                                                            )}
                                                                    </div>

                                                                </div>

                                                                <div className="row mx-1 mb-3">


                                                                    <div className="col-6">
                                                                        <label className="mb-2 title-sm">Project Asset</label>


                                                                        <div class="drop_box">


                                                                            <input type="file"

                                                                                id="customFile"
                                                                                accept=".zip"
                                                                                onChange={(e) => {
                                                                                    handleInputChangeFile(e)

                                                                                }} />
                                                                        </div>
                                                                        {
                                                                            isValidFile !== undefined && !isValidFile &&

                                                                            // <div class="alert alert-danger" role="alert">
                                                                            <p style={{ color: "red" }}>Upload Zip File Only</p>
                                                                            // </div>
                                                                        }
                                                                    </div>


                                                                </div>

                                                                <div className="row mx-1 mb-3">
                                                                    <div className="col">
                                                                        <button
                                                                            className="btn btn-primary py-2 mt-4"
                                                                            type="submit"
                                                                            disabled={isDisabled()}
                                                                            style={{ width: "100%" }}
                                                                            onClick={() => submitProjectResponse()}
                                                                        >
                                                                            Submit
                                                                        </button>
                                                                    </div>
                                                                    <div className="col"></div>
                                                                </div>
                                                            </div>


                                                        </>

                                                    </div>
                                                </div>
                                                :

                                                <>

                                                    <div style={{ width: '10px', background: '#ddd', cursor: 'col-resize' }} onMouseDown={handleMouseDown}></div>
                                                    <div className=" mainbody ml-2" style={{ flex: '1', height: '100%', overflow: 'auto', background: "black" }}>
                                                        {/* labbacimg */}
                                                        <div className=" row ml-1"  >





                                                            <div className="col-2" style={{ textAlign: "center", textDecoration: "none", background: "#471579 ", padding: "15px 20px", marginLeft: "18px", marginBottom: "50px", marginTop: "40px", border: "1px solid #471579", borderRadius: "10px" }}>

                                                                {
                                                                    (stopServer.length > 0 || `stopServer_${labId}_${labName}` in localStorage)
                                                                        ?
                                                                        <button style={{ color: "#fff", fontSize: "15px" }} onClick={() => {
                                                                            ec2GuacamolePOC();
                                                                        }
                                                                        }>Resume Lab</button>
                                                                        :
                                                                        `connectionString_${labId}_${labName}` in localStorage || startLabConnectionParentLabs !== null ?
                                                                            <div>
                                                                                <p className="text-white">Started</p>
                                                                            </div>
                                                                            :
                                                                            (startLabConnection.length === 0 && stopConnection.length === 0 &&
                                                                                `connectionString_${labId}_${labName}` in localStorage === false) ?
                                                                                <button style={{ color: "#fff", fontSize: "15px" }} onClick={() => {
                                                                                    ec2GuacamolePOC();
                                                                                }
                                                                                }>Start Lab</button>

                                                                                :
                                                                                <div className="new-chat-bubble" style={{ border: "none" }}>
                                                                                    <p className="text-white pl-2" style={{ display: "flex" }} >Starting

                                                                                        <div class="typing" style={{ border: "none" }}>
                                                                                            <div class="dot"></div>
                                                                                            <div class="dot"></div>
                                                                                            <div class="dot"></div>
                                                                                        </div>
                                                                                    </p>
                                                                                </div>
                                                                    // startLabConnection.length > 0 || stopConnection.length > 0 &&
                                                                    // <div>
                                                                    //     <p className="text-white">Started</p>
                                                                    // </div>

                                                                }
                                                            </div>
                                                            {
                                                                showButton || `connectionString_${labId}_${labName}` in localStorage ?
                                                                    <>
                                                                        {
                                                                            evaluatedLab || labIdArray.includes(labId) ? "" :
                                                                                <div className="col-2" style={{ textAlign: "center", textDecoration: "none", background: "#471579 ", padding: "15px 20px", marginLeft: "25px", marginBottom: "50px", marginTop: "40px", border: "1px solid #471579", borderRadius: "10px" }}>
                                                                                    {
                                                                                        stopServer.length === 0 && `stopServer_${labId}_${labName}` in localStorage === false ?

                                                                                            <button style={{ color: "#fff", fontSize: "15px" }} onClick={() => stopEC2InstanceAndTerminateGuacamoleServer()}>
                                                                                                Pause Lab
                                                                                            </button>
                                                                                            :

                                                                                            <div>
                                                                                                <p className="text-white">Paused</p>
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                        }

                                                                        <div className="col-2" style={{ textAlign: "center", textDecoration: "none", background: "#471579", padding: "15px 20px", marginLeft: "25px", marginBottom: "50px", marginTop: "40px", border: "1px solid #471579", borderRadius: "10px" }}>
                                                                            <button style={{ color: "#fff", fontSize: "15px" }}
                                                                                onClick={() =>
                                                                                    evaluatedLab ?
                                                                                        terminateEC2InstanceAndTerminateGuacamoleServer()
                                                                                        : stopEC2InstanceAndTerminateGuacamoleServerPractice()
                                                                                }
                                                                            >
                                                                                Complete Lab</button>
                                                                        </div>

                                                                        {/* <div className="col-2" style={{ textAlign: "center", textDecoration: "none", background: "#471579", padding: "15px 20px", marginLeft: "25px", marginBottom: "50px", marginTop: "40px", border: "1px solid #471579", borderRadius: "10px" }}>
                                                    <button style={{ color: "#fff", fontSize: "15px" }}>Start Recording</button>
                                                </div> */}
                                                                        {/* <ScreenRecording /> */}
                                                                    </>
                                                                    : ''}
                                                            <div className="col-2" style={{ marginLeft: "25px", marginBottom: "50px", marginTop: "50px" }}>
                                                                {
                                                                    startLabConnection.length > 0 && !isLoading ?
                                                                        <CountdownTimer {...{
                                                                            timeLimit: labDuration,
                                                                            callback: () => evaluatedLab ? terminateEC2InstanceAndTerminateGuacamoleServer()
                                                                                : stopEC2InstanceAndTerminateGuacamoleServerPractice()
                                                                        }} />
                                                                        : `connectionString_${labId}_${labName}` in localStorage ?
                                                                            <CountdownTimer {...{
                                                                                timeLimit: minutes,
                                                                                callback: () => evaluatedLab ? terminateEC2InstanceAndTerminateGuacamoleServer()
                                                                                    : stopEC2InstanceAndTerminateGuacamoleServerPractice()
                                                                            }} />
                                                                            : ''
                                                                }
                                                            </div>

                                                            {showButton &&
                                                                <div className="col-2" style={{ float: "right" }}>
                                                                    <button id="toggle_fullscreen" onClick={toggleFullscreen} className="btn-primary" style={{ float: "right", marginBottom: "50px", padding: "5px 15px", marginTop: "50px" }}>
                                                                        {<FullscreenIcon />}
                                                                    </button>
                                                                </div>
                                                            }


                                                            {/* <div   onClick={handleFullscreenChange} className="col-2" style={{ float:"right", marginBottom: "50px", marginTop: "50px" }}>
                                                  {ICN_FULL_SCREEN}
                                                    </div>

                                                    
                           */}




                                                        </div>

                                                        <div className="py-2 modal-content" style={{ marginTop: "-10px" }} id="container">{
                                                            (startLabConnection.length > 0 && stopConnection.length > 0) ?
                                                                // <iframe src={`https://lab.trainsoft.live/#${labConnection}`} width="100%" height="600px" />

                                                                isLoading ?
                                                                    <LabSpinner />

                                                                    :

                                                                    cloudLabUserUniqueId !== null ?
                                                                        <div style={{ textAlign: "center", alignItems: "center" }}>
                                                                            {/* <a  className="btn btn-primary p-2" href={startLabConnection} target="_blank" rel="noopener noreferrer" style={{ color: "#fff", fontSize: "15px" }}>Open Aws Console</a> */}
                                                                            <button className="btn btn-primary" onClick={openWindow} disabled={isButtonDisabled}>Open Aws Console</button>
                                                                        </div>
                                                                        :


                                                                        <iframe ref={iframeRef} id="modal-link" is="x-frame-bypass" src={`${startLabConnection}?token=${token}`} width="100%" height="900px" />

                                                                :
                                                                `connectionString_${labId}_${labName}` in localStorage ?
                                                                    <iframe ref={iframeRef} id="modal-link" is="x-frame-bypass" src={localStorage.getItem(`connectionString_${labId}_${labName}`) + `?token=${token}`} width="100%" height="900px" />
                                                                    :
                                                                    <div className="text-white title-sm ml-5" style={{ marginTop: "-40px" }}>Please Click on Start Lab</div>}
                                                        </div>


                                                    </div>
                                                </>
                                }


                            </div>
                        </FocusTrap>

            }
            <video crossOrigin='anonymous' ref={videoRef} height="1" width="1" autoPlay></video>

        </div>

    )
}

export default Labs