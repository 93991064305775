import { useContext, useRef, useState, useEffect, useCallback } from "react";
import ReactMarkdown from 'react-markdown';
import { useNavigate } from "@reach/router";
import useToast from "../../../../Store/ToastHook";
import AppContext from "../../../../Store/AppContext";
import RestService from "../../../../Services/api.service";
import CountdownTimer from "../../../Common/CountdownTimer/CountdownTimer";
import CodeEditor from "../../ClassLab/CodeEditor/CodeEditor";
import "./labs.css";
import { FAIL_TASK, ICN_BACK, PASS_TASK, UPLOAD_ICON_PROJECT } from "../../../Common/Icon";
import LabSpinner from "./LabSpinner";
import RecordRTC from "recordrtc";
import AWS from 'aws-sdk';
import axios from 'axios';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import FocusTrap from "focus-trap-react"
import CompleteProgressBar from "./CompleteProgressBar";
import * as faceapi from "face-api.js";
import { BsModal, Bsmodalvertically } from "../../../Common/BsUtils";

import { ICN_FULL_SCREEN } from "../../../Common/Icon";
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import parse from 'html-react-parser';
import { Button } from "react-bootstrap";
import GLOBELCONSTANT from "../../../../Constant/GlobleConstant";
import Split from 'react-split';
// import { ICN_COLLAPSED, ICN_EXPAND_ACCORDIAN, ICN_EXPAND_NEW, ICN_LEFT_ARR, ICN_RIGHT_ARR, ICN_TIMER } from '../../../Common/Icon';
import FadeLoader from "react-spinners/FadeLoader";
import LoadingSpinner from '../../../Common/LoadingSpinner/LoadingSpinner';
import NewLabspinner from './NewLabspinner';
import { ICN_COLLAPSED, ICN_EXPAND_ACCORDIAN, ICN_EXPAND_NEW, ICN_LEFT_ARR, ICN_RIGHT_ARR, ICN_TIMER } from '../../../Common/Icon';
import AlertDialog from "../../../Common/AlertDialog/AlertDialog";
import AssessmentContext from "../../../../Store/AssessmentContext";
import Webcam from "react-webcam";
import { Spinner } from 'react-bootstrap';
import { Tooltip } from "react-tooltip";


const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const bucketUrl = process.env.REACT_APP_BUCKET_URL;

AWS.config.update({
  accessKeyId: process.env.REACT_APP_ACCESSKEYID,
  secretAccessKey: process.env.REACT_APP_SECRETACCESSKEY
})

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
})



const NewLab = (props) => {

  const [loading, setLoading] = useState(false);
  const [loadingStart, setLoadingStart] = useState(false);

  let user = localStorage.getItem('user')
    ? JSON.parse(localStorage.getItem('user'))
    : '';
  const { trainingId } = useContext(AssessmentContext);
  const [activeTab, setActiveTab] = useState('task'); // Set the default active tab
  const [counter, setCounter] = useState(20);
  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    if (!isCollapsed) {
      setWordsPerPage(200);
    }
    else {
      setWordsPerPage(130);
    }
  };
  const [startLabs, setStartLabs] = useState('');
  const [disappearTimer, setDisAppearTimer] = useState(false);

  const handleStartAssessment = () => {

    setDisAppearTimer(true);

    setTimeout(() => {
      setDisAppearTimer(false);
      setStartLabs("Started");
    }, 5000);
  }

  const [wordsPerPage, setWordsPerPage] = useState(130);
  const exampleContent = `
  Paragraphs are the building blocks of papers. Many students define paragraphs in terms of length: a paragraph is a group of at least five sentences, a paragraph is half a page long, etc. In reality, though, the unity and coherence of ideas among sentences is what constitutes a paragraph. A paragraph is defined as “a group of sentences or a single sentence that forms a unit” (Lunsford and Connors 116). Length and appearance do not determine whether a section in a paper is a paragraph.
  Paragraphs are the building blocks of papers. Many students define paragraphs in terms of length: a paragraph is a group of at least five sentences, a paragraph is half a page long, etc. In reality, though, the unity and coherence of ideas among sentences is what constitutes a paragraph. A paragraph is defined as “a group of sentences or a single sentence that forms a unit” (Lunsford and Connors 116). Length and appearance do not determine whether a section in a paper is a paragraph.
  Paragraphs are the building blocks of papers. Many students define paragraphs in terms of length: a paragraph is a group of at least five sentences, a paragraph is half a page long, etc. In reality, though, the unity and coherence of ideas among sentences is what constitutes a paragraph. A paragraph is defined as “a group of sentences or a single sentence that forms a unit” (Lunsford and Connors 116). Length and appearance do not determine whether a section in a paper is a paragraph.
  Paragraphs are the building blocks of papers. Many students define paragraphs in terms of length: a paragraph is a group of at least five sentences, a paragraph is half a page long, etc. In reality, though, the unity and coherence of ideas among sentences is what constitutes a paragraph. A paragraph is defined as “a group of sentences or a single sentence that forms a unit” (Lunsford and Connors 116). Length and appearance do not determine whether a section in a paper is a paragraph.
  Paragraphs are the building blocks of papers. Many students define paragraphs in terms of length: a paragraph is a group of at least five sentences, a paragraph is half a page long, etc. In reality, though, the unity and coherence of ideas among sentences is what constitutes a paragraph. A paragraph is defined as “a group of sentences or a single sentence that forms a unit” (Lunsford and Connors 116). Length and appearance do not determine whether a section in a paper is a paragraph.
  `;

  const [currentPage, setCurrentPage] = useState(1);
  const [expand, setExpand] = useState();

  const words = exampleContent.split(/\s+/); // Use a regex to split by whitespace

  const indexOfLastWord = currentPage * wordsPerPage;
  const indexOfFirstWord = indexOfLastWord - wordsPerPage;
  const currentWords = words.slice(indexOfFirstWord, indexOfLastWord);

  const totalWords = words.length;
  const totalPages = Math.ceil(totalWords / wordsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [labDescription, setLabDescription] = useState(props.location.state.labDescription);
  const [codingQuestiondesc, setcodingQuestiondesc] = useState(false);
  const [labOverview, setLabOverview] = useState(props.location.state.labOverview);
  const [labType, setLabType] = useState(props.location.state.type);
  const { spinner } = useContext(AppContext)
  const [contentSid, setContentSid] = useState(props.location.state.contentSid);
  const [labId, setLabId] = useState(props.location.state.labId);
  const [startLabConnection, setStartLabConnection] = useState('');
  // const [labConnection, setLabConnection] = useState('');
  const [stopConnection, setStopConnection] = useState('');
  const [stopServer, setStopServer] = useState('');
  const [showButton, setShowButton] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [labDuration, setLabDuration] = useState(props.location.state.labDuration);
  const [labName, setLabName] = useState(props.location.state.showcoursename);
  const [evaluatedLab, setEvaluatedLab] = useState(props.location.state.evaluatedLab);
  const [isLoading, setIsLoading] = useState(true);
  const [offStartButton, setOffStartButton] = useState(true);
  const [cloudLabUserUniqueId, setCloudLabUserUniqueId] = useState(props.location.state.cloudLabUserUniqueId);
  const [buttonText, setButtonText] = useState(props.location.state.buttonText);
  const [cloudLab, setCloudLab] = useState(props.location.state.cloudLab);
  const [devLab, setDevLab] = useState(props.location.state.devLab);
  const [external, setExternal] = useState(props.location.state.external);
  const labIdArray = [19, 20, 21, 24, 26, 27, 28, 29, 30, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
    51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 98,
    204, 205, 206, 265, 266, 267, 365, 366, 367, 368];
  const vsCodeLink = "https://gnosislabs.cloud/";
  const token = localStorage.getItem('GUAC_AUTH');

  const [compileLabResponse, setCompileLabResponse] = useState({});
  // const [compileLabResponseVsCode, setCompileLabResponseVsCode] = useState('');
  const [startsubmit, setstartsubmit] = useState(false);
  const [completesubmit, setcompletesubmit] = useState(false);
  const [cameraAndMicPermission, setCameraAndMicPermission] = useState(false);

  const [isFullscreen, setIsFullscreen] = useState(false);

  const [bookmark, setBookmark] = useState(props.location.state.bookmark);

  const [openedWindow, setOpenedWindow] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  localStorage.setItem("trainingSid", props.location.state.trainingSid);

  // show hide sidebar
  const [isContentVisible, setIsContentVisible] = useState(false);

  //multiple pages in labs
  const [parentLab, setParentLab] = useState(props.location.state.parentLab);
  const [pageNo, setPageNo] = useState(props.location.state.pageNo);
  const [pages, setPages] = useState(props.location.state.pages);
  const [startLabConnectionParentLabs, setStartLabConnectionParentLabs] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [localStorageIndex, setLocalStorageIndex] = useState(0);

  //projects
  const [durationInDays, setDurationInDays] = useState(props.location.state.durationInDays);
  const [projectId, setProjectId] = useState(props.location.state.projectId);
  const [repoLink, setRepoLink] = useState('');
  const [zipFile, setZipFile] = useState();

  const [isValid, setIsValid] = useState();
  const [isValidFile, setIsValidFile] = useState();
  const [errorMessageCompileLab, setErrorMessageCompileLab] = useState('');

  // const handleExpand = (id) => {
  //   if(expand !== undefined && expand.length > 0){
  //     setExpand()
  //   }
  //   else if(id === 'task1'){
  //     setExpand('task1')
  //   }
  //   else if(id === 'vm'){
  //     setExpand('vm')
  //   }

  // }

  // useEffect(() => {
  //   if (disappearTimer && (startLabConnection.length === 0 && stopConnection.length === 0 &&
  //     `connectionString_${labId}_${labName}` in localStorage === false)) {
  //     counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  //   }
  // }, [counter, disappearTimer]);

  useEffect(() => {
    if (disappearTimer) {
      counter > 0 && setTimeout(() => setCounter(prevCounter => prevCounter - 1), 1000);
    }
  }, [counter, disappearTimer]);





  const override = {
    display: "block",
    // margin: "3px",
    background: "rgba(255, 255, 255, 0.5)", // Adjust the background color and opacity as needed.
  };

  //validation for repo link for github only
  const validateRepoLink = (link) => {
    // Regular expression to match a GitHub repository URL
    const regex = /^(https:\/\/github\.com\/)([A-Za-z0-9_.-]+)\/([A-Za-z0-9_.-]+)$/;

    if (regex.test(link)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };
  //handle repo link
  const handleInputChangeLink = (e) => {
    const inputValue = e.target.value;
    setRepoLink(inputValue);
    validateRepoLink(inputValue);
  };

  //handle file change and validation for zip format only
  const handleInputChangeFile = (e) => {
    const inputValue = e.target.files[0];
    if (inputValue !== undefined && inputValue.name.split('.')[1] !== 'zip') {
      setIsValidFile(false);
      setZipFile(inputValue);
    }
    else {
      setIsValidFile(true);
      setZipFile(inputValue);
    }

  };
  //for disable submit button
  const isDisabled = () => {

    if (isValid && fileTypeError.length === 0) {
      return false;
    }

    if (fileTypeError.length === 0) {
      if (isValid) {
        return false
      }
    }
    return true;
  }

  //convert multiline string to object
  function convertStringToObject(str) {
    const obj = {};
    const lines = str.split('\n'); // Split the string into lines

    lines.forEach(line => {
      const [key, value] = line.split(':'); // Split each line by the colon
      obj[key.trim()] = (value.trim()); // Trim and convert to number
    });

    return obj;
  }


  //submit project api for learner
  const submitProjectResponse = () => {
    try {
      spinner.show();
      let trainingSid = props.location.state.trainingSid;
      let formData = new FormData();
      const projectSubmission = {
        "projectId": projectId,
        "sectionSid": contentSid,
        "trainingSid": trainingSid,
        "repositoryLink": repoLink
      }
      const jsonProjectSubmission = JSON.stringify(projectSubmission)

      formData.append("project_submission", jsonProjectSubmission);

      // formData.append("zip_file", zipFile);
      typeof file === 'object' && formData.append("zip_file", file);

      RestService.submitProjectResponse(formData).then(res => {
        if (res.status === 200) {
          Toast.success({ message: `Project Successfully Submitted`, time: 2000 });
          spinner.hide();
          markCourseAsCompletedProject();
          setRepoLink('');
          navigate('/training/training-details');
        }

      }, err => {
        console.error(err);
        spinner.hide();
        Toast.error({ message: `Try Again`, time: 2000 });
      }
      );
    }
    catch (err) {
      console.error('error occur on submitProjectResponse', err)
    }
  }

  const handleNext = () => {
    if (currentIndex < pages.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setLabName(pages[currentIndex + 1].childLabName);
      setLabDescription(pages[currentIndex + 1].childLabDescription);
      setLabOverview(pages[currentIndex + 1].childLabOverview);
      setEvaluatedLab(pages[currentIndex + 1].evaluatedLab);
      setStartLabConnection(startLabConnectionParentLabs[`${pages[currentIndex + 1].pageNumber}`]);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setLabName(pages[currentIndex - 1].childLabName);
      setLabDescription(pages[currentIndex - 1].childLabDescription);
      setLabOverview(pages[currentIndex - 1].childLabOverview);
      setEvaluatedLab(pages[currentIndex - 1].evaluatedLab);
      setStartLabConnection(startLabConnectionParentLabs[`${pages[currentIndex - 1].pageNumber}`]);
    }
  };

  //
  const handleToggleContent = () => {
    setIsContentVisible(!isContentVisible);
  };

  //for recording
  const [recordedVideoUrl, setRecordedVideoUrl] = useState(null);
  const [isOpenVideoModal, setIsOpenVideoModal] = useState(false);
  const [screen, setScreen] = useState(null);
  const [camera, setCamera] = useState(null);
  const [recorder, setRecorder] = useState(null);
  const [startDisable, setStartDisable] = useState(false);
  const [stopDisable, setStopDisable] = useState(true);
  const [loadModal, setLoadModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);
  const inputRef = useRef(null);
  const [fileTypeError, setFileTypeError] = useState('');
  const [imagePreview, setImagePreview] = useState(null);
  // const [checkTimeLeftInMinutes, setCheckTimeLeftInMinutes] = useState(11);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // setFile(file);
    if (file) {
      const allowedExtensions = ['zip'];
      const fileExtension = file.name.split('.').pop().toLowerCase();

      if (allowedExtensions.includes(fileExtension)) {
        setFile(file);
        setFileTypeError('');
      } else {
        setFile(null);
        setFileTypeError('Invalid file type. Please upload a zip file.');
      }
      const reader = new FileReader();

      reader.onloadend = () => {
        setImagePreview(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {

    const inputElement = inputRef.current;

    const dropZoneElement = inputElement?.closest(".drop-zone");

    const updateThumbnail = (file) => {
      let thumbnailElement = dropZoneElement?.querySelector(".drop-zone__thumb");

      if (dropZoneElement.querySelector(".drop-zone__prompt")) {
        dropZoneElement.querySelector(".drop-zone__prompt").remove();
      }

      if (!thumbnailElement) {
        thumbnailElement = document.createElement("div");
        thumbnailElement.classList.add("drop-zone__thumb");
        dropZoneElement.appendChild(thumbnailElement);
      }

      thumbnailElement.dataset.label = file.name;

      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        // reader.onload = () => {
        //   thumbnailElement.style.backgroundImage = url('${reader.result}');
        // };
      }
      //    else {
      //     thumbnailElement.style.backgroundImage = null;
      //   }
    };

    const handleClick = () => {
      inputElement.click();
    };

    const handleChange = (e) => {
      if (inputElement.files.length) {
        updateThumbnail(inputElement.files[0]);
      }
    };

    const handleDragOver = (e) => {
      e.preventDefault();
      dropZoneElement.classList.add("drop-zone--over");
    };

    const handleDragLeave = () => {
      dropZoneElement.classList.remove("drop-zone--over");
    };

    const handleDrop = (e) => {
      e.preventDefault();

      if (e.dataTransfer.files.length) {
        const file = e.dataTransfer.files[0];
        const allowedExtensions = ['zip'];
        const fileExtension = file.name.split('.').pop().toLowerCase();
        if (allowedExtensions.includes(fileExtension)) {
          setFile(file);
          setFileTypeError('');
        } else {
          setFile(null);
          setFileTypeError('Invalid file type. Please upload a zip file.');
        }
        // setFile(file);
        updateThumbnail(file);

        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreview(reader.result);
        };
        reader.readAsDataURL(file);
      }

      dropZoneElement.classList.remove("drop-zone--over");
    };


    // Event listeners
    dropZoneElement?.addEventListener("click", handleClick);
    inputElement?.addEventListener("change", handleChange);
    dropZoneElement?.addEventListener("dragover", handleDragOver);
    ["dragleave", "dragend"].forEach((type) => {
      dropZoneElement?.addEventListener(type, handleDragLeave);
    });
    dropZoneElement?.addEventListener("drop", handleDrop);

    // Cleanup event listeners on component unmount
    return () => {
      dropZoneElement?.removeEventListener("click", handleClick);
      inputElement?.removeEventListener("change", handleChange);
      dropZoneElement?.removeEventListener("dragover", handleDragOver);
      ["dragleave", "dragend"].forEach((type) => {
        dropZoneElement?.removeEventListener(type, handleDragLeave);
      });
      dropZoneElement?.removeEventListener("drop", handleDrop);
    };



  }, []);

  //compile lab
  const compileLab = () => {
    setErrorMessageCompileLab('');
    try {
      setLoading(true);
      const trainingSid = props.location.state.trainingSid;
      const sectionSid = contentSid;
      RestService.compileLab(labId, sectionSid, trainingSid).then(res => {
        if (res.status === 200) {
          // const jsonArray = Object.keys(res.data).map(key => {
          //   return { [key]: res.data[key] };
          // });
          if (res.data) {
            if (typeof (res.data) === "object") {
              setCompileLabResponse(res.data);
            }
            else {
              const result = convertStringToObject(res.data);
              setCompileLabResponse(result);
            }
          }
          else {
            setCompileLabResponse({});
            setErrorMessageCompileLab('Your code has runtime errors, correct your code and try again');
          }
          // setCompileLabResponse(res.data);

          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }


      }, err => {
        if (err.response.status === 500) {
          setErrorMessageCompileLab('Your code has errors, correct them and compile again');
        }
        else if (err.response.status === 504) {
          setErrorMessageCompileLab('Compilation limit exceeds, compile after 10 seconds');
        }
        setTimeout(() => {
          setLoading(false);
          setCompileLabResponse({});
        }, 1000);
      }
      );
    }
    catch (err) {
      console.error('error occur on compileLab', err)
    }
  }

  //screenshot starts
  const webcamRef = useRef(null);
  const [progressScreenshot, setProgressScreenshot] = useState(0);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
  };

  const capture = async () => {
    try {
      const imageSrc = await getScreenshot();
      const blob = dataURLtoBlob(imageSrc);
      const newFile = getFileNameNoViolation(blob);
      uploadFile1(newFile);

    } catch (error) {
      console.error("Error capturing photo:", error);
    }
  };

  const captureViolation = async (type) => {
    try {
      const imageSrc = await getScreenshot();
      const blob = dataURLtoBlob(imageSrc);
      const newFile = getFileNameViolation(blob, type);
      uploadFile1(newFile);

    } catch (error) {
      console.error("Error capturing photo:", error);
    }
  };

  const getScreenshot = () => {
    return new Promise((resolve, reject) => {
      try {
        const imageSrc = webcamRef.current.getScreenshot();
        resolve(imageSrc);
      } catch (error) {
        reject(error);
      }
    });
  };

  const dataURLtoBlob = (dataurl) => {
    let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
  }

  const getFileNameNoViolation = (blob) => {
    const userSid = user?.sid;
    // const codingAssessmentSid = codingAssessmentDetails.codingAssessmentSid;
    const trainingSid = props.location.state.trainingSid;
    const sectionSid = contentSid;
    const timestamp = Date.now();
    const file = new File([blob], `lab_${userSid}_${labId}_${sectionSid}_${trainingSid}_${timestamp}.jpeg`, {
      type: "image/jpeg",
    });
    return file;
  };

  const getFileNameViolation = (blob, type) => {
    const userSid = user?.sid;
    // const codingAssessmentSid = codingAssessmentDetails.codingAssessmentSid;
    const trainingSid = props.location.state.trainingSid;
    const sectionSid = contentSid;
    const timestamp = Date.now();
    const file = new File([blob], `lab_${userSid}_${labId}_${sectionSid}_${trainingSid}_${timestamp}_${type}.jpeg`, {
      type: "image/jpeg",
    });
    return file;
  };
  const uploadFile1 = async (file) => {
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: file.name
    };

    myBucket.putObject(params)
      .on('httpUploadProgress', (evt) => {


        setProgressScreenshot(Math.round((evt.loaded / evt.total) * 100));

      })
      .send((err, data) => {
        if (err) {
          console.error(err);
        }
      });
  };


  const handleUserMedia = (stream) => {
    // Do something with the webcam stream
  };

  const handleUserMediaError = (error) => {
    Toast.warning({ message: 'Please grant Camera Access', time: 4000 });
    console.error('Error accessing webcam:', error);
    // Handle the error
  };


  // variables for face detections 
  const [faceDetections, setFaceDetections] = useState(0);
  const [faceviolationcount, setFaceviolationcount] = useState(0);
  const [show, setShow] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showPauseModal, setShowPauseModal] = useState(false);

  const videoRef = useRef();
  const streamRef = useRef();

  const Toast = useToast();
  const navigate = useNavigate();

  const iframeRef = useRef(null);

  let timestamp = new Date(localStorage.getItem(`end_date_${labId}_${labName}`) - Date.now());
  let splitMinutes = timestamp.toUTCString().slice(-11, -4).split(':');
  let minutes = (+splitMinutes[0]) * 60 + (+splitMinutes[1]);
  // strechable layout start
  const [leftWidth, setLeftWidth] = useState(30);
  const [speed, setSpeed] = useState("0");

  const handleMouseMove = (e) => {
    setLeftWidth(e.clientX / window.innerWidth * 100);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleMouseDown = () => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  // strechable layout end

  // face detection methods starts
  const startVideo = () => {
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        videoRef.current.srcObject = stream;
        streamRef.current = stream;
        loadModels();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const loadModels = () => {
    Promise.all([
      faceapi.nets.tinyFaceDetector.loadFromUri('/models'),
      faceapi.nets.faceLandmark68Net.loadFromUri('/models'),
      faceapi.nets.faceRecognitionNet.loadFromUri('/models'),
      faceapi.nets.faceExpressionNet.loadFromUri('/models'),
    ]).then(() => {
      faceDetection();
    })
  };

  const faceDetection = async () => {
    setInterval(async () => {
      const detections = await faceapi.detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceExpressions();
      setFaceDetections(detections.length);

    }, 1000)
  }

  const stopProctorCamera = () => {
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
    }
  };

  // useEffect(() => {
  //   if (faceDetections >= 2) {
  //     // setShow(true);
  //     captureViolation('FD');
  //     setFaceviolationcount(prevCount => prevCount + 1);
  //     Toast.warning({ message: 'Multiple Faces Detected', time: 5000 });
  //   }
  //   if (faceviolationcount >= 1000 && labType === "LAB") {
  //     terminateEC2InstanceAndTerminateGuacamoleServer();
  //   }

  // }, [faceDetections])

  // face detection methods starts ends

  //recording method starts
  const captureCamera = (cb) => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: true, //make it  true for video
      })
      .then(cb)
      .catch((error) => {
        if (error.name === "NotAllowedError" || error.name === "PermissionDeniedError") {
          console.error(
            "You need to grant this page permission to access your camera and microphone.",
          );
          terminateLabsForDeniedPermission();
          navigate('/training/training-details');
          stop();
        } else if (error.name == "NotReadableError" || error.name == "TrackStartError") {

          console.error('Webcam or mic are already in use');

          terminateLabsForDeniedPermission();
          navigate('/training/training-details');
          stop();
        }
      });
  };

  const startScreenRecord = async () => {
    await setStopDisable(false);
    setStartDisable(true);

    captureScreen((screen) => {
      captureCamera(async (camera) => {
        screen.width = window.screen.width;
        screen.height = window.screen.height;
        screen.fullcanvas = true;
        camera.width = 320;
        camera.height = 240;
        camera.top = screen.height - camera.height;
        camera.left = screen.width - camera.width;

        setScreen(screen);
        setCamera(camera);

        if (camera !== null) {
          const newRecorder = RecordRTC([screen, camera], {
            type: "video",
          });

          newRecorder.startRecording();
          newRecorder.screen = screen;

          setRecorder(newRecorder);
          startVideo();
        }

      });
    });
  };

  const captureScreen = (callback) => {
    invokeGetDisplayMedia(
      (screen) => {
        addStreamStopListener(screen, () => { });
        callback(screen);
      },
      (error) => {
        console.error(error);
        // alert(
        //     "Unable to capture your screen. Please check console logs.\n" + error
        // );
        setStopDisable(true);
        setStartDisable(false);
        terminateLabsForDeniedPermission();
        navigate('/training/training-details');
      }
    );
  };

  const stopLocalVideo = async (screen, camera) => {
    [screen, camera].forEach(async (stream) => {
      stream.getTracks().forEach(async (track) => {
        track.stop();
      });
    });
  };

  const invokeGetDisplayMedia = (success, error) => {
    var displaymediastreamconstraints = {
      video: {
        displaySurface: "monitor", // monitor, window, application, browser
        logicalSurface: true,
        cursor: "always", // never, always, motion
      },
    };
    displaymediastreamconstraints = {
      video: true,
      audio: true,
    };
    if (navigator.mediaDevices.getDisplayMedia) {
      navigator.mediaDevices
        .getDisplayMedia(displaymediastreamconstraints)
        .then(success)
        .catch(error);
    } else {
      navigator
        .getDisplayMedia(displaymediastreamconstraints)
        .then(success)
        .catch(error);
    }
  };

  const addStreamStopListener = (stream, callback) => {
    if (camera !== null) {
      stream.addEventListener(
        "ended",
        () => {
          callback();
          callback = () => { };
        },
        false
      );
      stream.addEventListener(
        "inactive",
        () => {
          callback();
          callback = () => { };
        },
        false
      );
      stream.getTracks().forEach((track) => {
        track.addEventListener(
          "ended",
          () => {
            callback();
            callback = () => { };
          },
          false
        );
        track.addEventListener(
          "inactive",
          () => {
            callback();
            callback = () => { };
          },
          false
        );
      });
      stream.getVideoTracks()[0].onended = () => {
        stop();
      };
    }
  };

  //stop share screen prompt
  const permission = (screen) => {
    [screen].forEach(async (stream) => {
      stream.getTracks().forEach(async (track) => {
        track.stop();
      });
    });
  }

  const stop = () => {
    if (camera === null) {
      recorder.stopRecording(permission(screen));
      recorder.stopRecording(permission(screen));
      recorder.screen.stop();
      recorder.destroy();
      recorder = null;
    }
    else {
      setStartDisable(true);
      recorder.stopRecording(stopRecordingCallback);
    }

  };

  const stopRecordingCallback = async () => {
    await stopLocalVideo(screen, camera);
    let recordedVideoUrl;
    let blob = recorder.getBlob();

    if (recorder.getBlob()) {
      setRecordedVideoUrl(URL.createObjectURL(recorder.getBlob()));
    }

    setScreen(null);
    setIsOpenVideoModal(true);
    setStartDisable(false);
    setStopDisable(true);
    setCamera(null);

    recorder.screen.stop();
    recorder.destroy();
    setRecorder(null);
    stopProctorCamera();

    var file = new File([blob], getFileName("mp4"), {
      type: "video/mp4",
    });

    uploadFile(file);
  };

  const videoModalClose = () => {
    setIsOpenVideoModal(false);
  };

  const openModal = async () => {
    await setLoadModal(false);
  };

  const getFileName = (fileExtension) => {
    let trainingSid = props.location.state.trainingSid;
    let userSid = JSON.parse(localStorage.getItem('user'))
    userSid = userSid.sid;

    return (
      `labrecording_${labId}_${trainingSid}_${userSid}.${fileExtension}`
    );
  };


  const uploadFile = async (file) => {
    const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: file.name
    };

    myBucket.putObject(params)
      .on('httpUploadProgress', (evt) => {

        setstartsubmit(true)

        setProgress(Math.round((evt.loaded / evt.total) * 100));
        if (faceviolationcount >= 1000) {
          markViolation();
        }
        setTimeout(() => {
          insertRecordingSeedDetails();
          setcompletesubmit(true);

          insertConnectionDetails();
          navigate("/training", { state: { title: "Training" } })
        }, 5000);

      })
      .send((err, data) => {
        if (err) {
          insertRecordingSeedDetails();
          insertConnectionDetails();
          setcompletesubmit(true);
          navigate("/training", { state: { title: "Training" } })
          console.error(err);
        }
      });
  };

  //save link to db

  const insertRecordingSeedDetails = () => {
    axios.post(GLOBELCONSTANT.HARD_CODED_API.INSERT_RECORDING_SEED_DETAILS, {}, {
      params: {
        recording_link: `${bucketUrl}${getFileName("mp4")}`
      }
    })
      .then(response => { })
      .catch(err => console.error(err));
  }

  //start lab First Time or after completing means again attempting 
  const ec2GuacamolePOC = async () => {
    if (evaluatedLab) {
      // const permission = await handlePermissionRequest()
      // if (permission) {
      // start lab for vscode 
      if (labIdArray.includes(labId)) {
        if (`connectionString_${labId}_${labName}` in localStorage === false) {
          setDisAppearTimer(true);
        }
        setStartLabConnection(vsCodeLink);
        setStopConnection(vsCodeLink);
        setStopServer('');
        localStorage.removeItem(`stopServer_${labId}_${labName}`);
        // setCounter(prevCounter => prevCounter - 1);
        // setStarting('Starting');
        setTimeout(function () {

          setShowButton(true);
          setIsLoading(false);
          // Toast.success({ message: 'Lab Started Successfully', time: 3000 });
          localStorage.setItem(`connectionString_${labId}_${labName}`, vsCodeLink);
          // startScreenRecord();
          // if (user.role !== "CONSUMER_LEARNER") {
          //   startVideo();
          // }

        }, 20000);
      }
      else {
        try {
          let trainingSid = props.location.state.trainingSid;
          let sectionSid = contentSid;
          setLoadingStart(true);
          // spinner.show();
          RestService.ec2GuacamolePOC(labId, sectionSid, trainingSid).then(
            response => {
              if (response.status === 200) {
                setLoadingStart(false);

                if (`connectionString_${labId}_${labName}` in localStorage === false) {
                  setDisAppearTimer(true);
                }
                // setDisAppearTimer(true);
                // setCounter(prevCounter => prevCounter - 1);
                if (typeof (response.data) === "object" && 'loginUrl' in response.data) {
                  setStartLabConnection(response.data.loginUrl);
                  setStopConnection(response.data.loginUrl);
                }
                else if (typeof (response.data) === "object") {
                  setStartLabConnection(response.data[`${currentIndex + 1}`]);
                  if (response.data[`${currentIndex + 1}`].indexOf('#') > -1) {
                    setStopConnection(response.data[`${currentIndex + 1}`].split('#')[1]);
                  }
                  else {

                    setStopConnection(response.data[`${currentIndex + 1}`].split(':')[1].substring(2));
                  }
                }

                else {
                  setStartLabConnection(response.data);
                  if (response.data.indexOf('#') > -1) {
                    setStopConnection(response.data.split('#')[1]);
                  }
                  else {

                    setStopConnection(response.data.split(':')[1].substring(2));
                  }
                }
                setStopServer('');
                localStorage.removeItem(`stopServer_${labId}_${labName}`);
                // setStarting('Starting');
                setTimeout(function () {

                  setShowButton(true);
                  setIsLoading(false);
                  setDisAppearTimer(false);
                  if (typeof (response.data) === 'object' && 'loginUrl' in response.data) {
                    localStorage.setItem(`connectionString_${labId}_${labName}`, response.data.loginUrl);
                  }
                  else if (typeof (response.data) === "object") {

                    localStorage.setItem(`connectionString_${labId}_${labName}`, response.data[`${currentIndex + 1}`]);
                    setLocalStorageIndex(currentIndex);
                    setStartLabConnectionParentLabs(response.data);
                  }
                  else {
                    localStorage.setItem(`connectionString_${labId}_${labName}`, response.data);
                  }
                  // Toast.success({ message: 'Lab Started Successfully', time: 3000 });

                  // localStorage.setItem('appearButton', true);
                  // startScreenRecord();
                  // if (user.role !== "CONSUMER_LEARNER") {
                  //   startVideo();
                  // }
                }, 20000);
                // setLabConnection(response.data.split('#')[1]);
                spinner.hide();
              }
            },
            err => {
              spinner.hide();
              if (err.response.status === 500) {
                setLoadingStart(false);
                Toast.error({ message: 'Lab not Configured' })
              }
              else {
                setLoadingStart(false);
                Toast.error({ message: 'Try again', time: 2000 });
              }

            }
          )
        } catch (err) {
          spinner.hide();
          console.error("error occur on ec2GuacamolePOC()", err)
        }
      }
      // }
      // else {
      //   if (user.role !== "CONSUMER_LEARNER") {
      //     alert("To access the assessment, please grant permission for camera and mic access.");
      //   }
      // }
    }
    else {
      // start lab for vscode 
      if (labIdArray.includes(labId)) {
        if (`connectionString_${labId}_${labName}` in localStorage === false) {
          setDisAppearTimer(true);
        }
        // setDisAppearTimer(true);
        setStartLabConnection(vsCodeLink);
        setStopConnection(vsCodeLink);
        setStopServer('');
        localStorage.removeItem(`stopServer_${labId}_${labName}`);
        // setCounter(prevCounter => prevCounter - 1);
        // setStarting('Starting');
        setTimeout(function () {

          setShowButton(true);
          setIsLoading(false);
          // Toast.success({ message: 'Lab Started Successfully', time: 3000 });
          localStorage.setItem(`connectionString_${labId}_${labName}`, vsCodeLink);
          // if (evaluatedLab) {
          //   // startScreenRecord();
          //   startVideo();
          // }

        }, 20000);
      }
      else {
        try {
          let trainingSid = props.location.state.trainingSid;
          let sectionSid = contentSid;
          setLoadingStart(true);
          // spinner.show();
          RestService.ec2GuacamolePOC(labId, sectionSid, trainingSid).then(
            response => {
              if (response.status === 200) {
                setLoadingStart(false);
                if (`connectionString_${labId}_${labName}` in localStorage === false) {
                  setDisAppearTimer(true);
                }
                // setDisAppearTimer(true);
                // setCounter(prevCounter => prevCounter - 1);
                if (typeof (response.data) === "object" && 'loginUrl' in response.data) {
                  setStartLabConnection(response.data.loginUrl);
                  setStopConnection(response.data.loginUrl);
                }
                else if (typeof (response.data) === "object") {
                  setStartLabConnection(response.data[`${currentIndex + 1}`]);
                  if (response.data[`${currentIndex + 1}`].indexOf('#') > -1) {
                    setStopConnection(response.data[`${currentIndex + 1}`].split('#')[1]);
                  }
                  else {

                    setStopConnection(response.data[`${currentIndex + 1}`].split(':')[1].substring(2));
                  }
                }

                else {
                  setStartLabConnection(response.data);
                  if (response.data.indexOf('#') > -1) {
                    setStopConnection(response.data.split('#')[1]);
                  }
                  else {

                    setStopConnection(response.data.split(':')[1].substring(2));
                  }
                }
                setStopServer('');
                localStorage.removeItem(`stopServer_${labId}_${labName}`);
                // setStarting('Starting');
                setTimeout(function () {

                  setShowButton(true);
                  setIsLoading(false);
                  setDisAppearTimer(false);
                  if (typeof (response.data) === 'object' && 'loginUrl' in response.data) {
                    localStorage.setItem(`connectionString_${labId}_${labName}`, response.data.loginUrl);
                  }
                  else if (typeof (response.data) === "object") {

                    localStorage.setItem(`connectionString_${labId}_${labName}`, response.data[`${currentIndex + 1}`]);
                    setLocalStorageIndex(currentIndex);
                    setStartLabConnectionParentLabs(response.data);
                  }
                  else {
                    localStorage.setItem(`connectionString_${labId}_${labName}`, response.data);
                  }
                  // Toast.success({ message: 'Lab Started Successfully', time: 3000 });

                  // localStorage.setItem('appearButton', true);
                  // if (evaluatedLab) {
                  //   // startScreenRecord();
                  //   startVideo();
                  // }
                }, 20000);
                // setLabConnection(response.data.split('#')[1]);
                spinner.hide();
              }
            },
            err => {
              spinner.hide();
              if (err.response.status === 500) {
                setLoadingStart(false);
                Toast.error({ message: 'Lab not Configured' })
              }
              else {
                setLoadingStart(false);
                Toast.error({ message: 'Try again', time: 2000 });
              }

            }
          )
        } catch (err) {
          spinner.hide();
          console.error("error occur on ec2GuacamolePOC()", err)
        }
      }
    }
    // else{
    //   alert("To access the assessment, please grant permission for camera and mic access.");
    // }

  }

  //stop lab 
  const stopEC2InstanceAndTerminateGuacamoleServer = () => {
    try {
      spinner.show();
      if (startLabConnection.length > 0) {
        const connectionCheckString = startLabConnection.indexOf('#') > -1 ?
          startLabConnection.split('#')[1] : startLabConnection.split(':')[1].substring(2);
        RestService.stopEC2InstanceAndTerminateGuacamoleServer(connectionCheckString).then(
          response => {
            Toast.success({ message: 'Lab paused successfully', time: 3000 });
            setStartLabConnection('');

            setStopServer(response.data);
            localStorage.setItem(`stopServer_${labId}_${labName}`, response.data);
            setShowPauseModal(false);

            // setLabConnection('');
            // localStorage.removeItem('appearButton');
            // localStorage.removeItem('connectionString');
          },
          err => {
            Toast.error({ message: 'Try again', time: 3000 });
            spinner.hide();
          }
        ).finally(() => {
          spinner.hide();
        });
      }
      else if (stopConnection.length > 0) {
        RestService.stopEC2InstanceAndTerminateGuacamoleServer(stopConnection).then(
          response => {
            Toast.success({ message: 'Lab paused successfully', time: 3000 });
            setStopServer(response.data);
            localStorage.setItem(`stopServer_${labId}_${labName}`, response.data);
            setShowPauseModal(false);
            // localStorage.removeItem('appearButton');
            // localStorage.removeItem('connectionString');
          },
          err => {
            spinner.hide();
            Toast.error({ message: 'Try again', time: 3000 });
          }
        ).finally(() => {
          spinner.hide();
        });

      }
      else {
        const connectionString = localStorage.getItem(`connectionString_${labId}_${labName}`);
        const connectionCheckString = connectionString.indexOf('#') > -1 ?
          connectionString.split('#')[1] : connectionString.split(':')[1].substring(2);
        RestService.stopEC2InstanceAndTerminateGuacamoleServer(connectionCheckString).then(
          response => {
            Toast.success({ message: 'Lab paused successfully', time: 3000 });
            setStopServer(response.data);
            setShowPauseModal(false);
            localStorage.setItem(`stopServer_${labId}_${labName}`, response.data);
            // localStorage.removeItem('appearButton');
            // localStorage.removeItem('connectionString');
          },
          err => {
            spinner.hide();
            Toast.error({ message: 'Try again', time: 3000 });
          }
        ).finally(() => {
          spinner.hide();
        });
      }

    } catch (err) {
      console.error("error occur on stopEC2InstanceAndTerminateGuacamoleServer()", err)
    }
  }

  //terminate lab 
  const terminateEC2InstanceAndTerminateGuacamoleServer = () => {
    if (external) {
      Toast.success({ message: 'Lab completed successfully', time: 3000 });
      setStopConnection('');
      setShowButton(false);
      localStorage.removeItem(`connectionString_${labId}_${labName}`);
      localStorage.removeItem('connectionString');
      localStorage.removeItem(`end_date_${labId}_${labName}`);;
      localStorage.removeItem(`stopServer_${labId}_${labName}`);
      // localStorage.removeItem('GUAC_AUTH');
      setOffStartButton(true);
      setShowSubmitModal(false);
      setShowSubmitModal(false);
      // if (evaluatedLab) {
      //   // stop();
      //   if (user.role !== "CONSUMER_LEARNER") {
      //     stopProctorCamera();
      //   }
      // }
      closeOpenedWindow();
      setLoadingStart(false);
      markCourseAsCompletedLabs();
    }
    else {
      const sectionSid = contentSid;
      const trainingSid = props.location.state.trainingSid;
      // stop lab for vscode 
      if (labIdArray.includes(labId)) {
        Toast.success({ message: 'Lab completed successfully', time: 3000 });
        setStartLabConnection('');
        setStopConnection('');
        setShowButton(false);
        markCourseAsCompletedLabs();
        localStorage.removeItem(`end_date_${labId}_${labName}`);;
        localStorage.removeItem(`connectionString_${labId}_${labName}`);
        localStorage.removeItem(`stopServer_${labId}_${labName}`);
        // localStorage.removeItem('GUAC_AUTH');
        setOffStartButton(true);
        setShowSubmitModal(false);
        // if (evaluatedLab) {
        //   // stop();
        //   if (user.role !== "CONSUMER_LEARNER") {
        //     stopProctorCamera();
        //   }

        // }
      }
      else {
        try {
          setLoadingStart(true);
          if (cloudLabUserUniqueId !== null) {

            const connectionString = "1234";

            RestService.terminateEC2InstanceAndTerminateGuacamoleServer(connectionString, labId, sectionSid, trainingSid).then(
              response => {
                Toast.success({ message: 'Lab completed successfully', time: 3000 });
                setStopConnection('');
                setShowButton(false);
                markCourseAsCompletedLabs();
                localStorage.removeItem(`connectionString_${labId}_${labName}`);
                localStorage.removeItem('connectionString');
                localStorage.removeItem(`end_date_${labId}_${labName}`);;
                localStorage.removeItem(`stopServer_${labId}_${labName}`);
                // localStorage.removeItem('GUAC_AUTH');
                setOffStartButton(true);
                setShowSubmitModal(false);
                setShowSubmitModal(false);
                // if (evaluatedLab) {
                //   // stop();
                //   if (user.role !== "CONSUMER_LEARNER") {
                //     stopProctorCamera();
                //   }
                // }
                closeOpenedWindow();
                setLoadingStart(false);
              },
              err => {
                Toast.error({ message: 'Try again', time: 3000 });
                setLoadingStart(false);
              }
            )

          }
          else if (startLabConnectionParentLabs !== null) {
            const connectionCheckString = startLabConnectionParentLabs['1'].indexOf('#') > -1 ?
              startLabConnectionParentLabs['1'].split('#')[1] : startLabConnectionParentLabs['1'].split(':')[1].substring(2);
            RestService.terminateEC2InstanceAndTerminateGuacamoleServer(connectionCheckString, labId, sectionSid, trainingSid).then(
              response => {
                Toast.success({ message: 'Lab completed successfully', time: 3000 });
                setStartLabConnection('');
                setStopConnection('');
                setShowButton(false);
                markCourseAsCompletedLabs();
                localStorage.removeItem('appearButton');
                localStorage.removeItem(`connectionString_${labId}_${pages[localStorageIndex].childLabName}`);
                localStorage.removeItem(`end_date_${labId}_${labName}`);;
                localStorage.removeItem(`stopServer_${labId}_${labName}`);
                // localStorage.removeItem('GUAC_AUTH');
                setOffStartButton(true);
                setShowSubmitModal(false);
                // if (evaluatedLab) {
                //   // stop();
                //   if (user.role !== "CONSUMER_LEARNER") {
                //     stopProctorCamera();
                //   }
                // }
                setLoadingStart(false);

                // setLabConnection('');
              },
              err => {
                setLoadingStart(false);
                Toast.error({ message: 'Try again', time: 3000 });
              }
            )
          }
          else if (startLabConnection.length > 0) {
            const connectionCheckString = startLabConnection.indexOf('#') > -1 ?
              startLabConnection.split('#')[1] : startLabConnection.split(':')[1].substring(2);
            RestService.terminateEC2InstanceAndTerminateGuacamoleServer(connectionCheckString, labId, sectionSid, trainingSid).then(
              response => {
                Toast.success({ message: 'Lab completed successfully', time: 3000 });
                setStartLabConnection('');
                setStopConnection('');
                setShowButton(false);
                markCourseAsCompletedLabs();
                localStorage.removeItem('appearButton');
                localStorage.removeItem(`connectionString_${labId}_${labName}`);
                localStorage.removeItem(`end_date_${labId}_${labName}`);;
                localStorage.removeItem(`stopServer_${labId}_${labName}`);
                // localStorage.removeItem('GUAC_AUTH');
                setOffStartButton(true);
                setShowSubmitModal(false);
                // if (evaluatedLab) {
                //   // stop();
                //   if (user.role !== "CONSUMER_LEARNER") {
                //     stopProctorCamera();
                //   }
                // }
                setLoadingStart(false);

                // setLabConnection('');
              },
              err => {
                Toast.error({ message: 'Try again', time: 3000 });
                setLoadingStart(false);
              }
            )
          } else if (stopConnection.length > 0) {
            RestService.terminateEC2InstanceAndTerminateGuacamoleServer(stopConnection, labId, sectionSid, trainingSid).then(
              response => {
                Toast.success({ message: 'Lab completed successfully', time: 3000 });
                setStopConnection('');
                setShowButton(false);
                markCourseAsCompletedLabs();
                localStorage.removeItem('appearButton');
                localStorage.removeItem(`connectionString_${labId}_${labName}`);
                localStorage.removeItem(`end_date_${labId}_${labName}`);;
                localStorage.removeItem(`stopServer_${labId}_${labName}`);
                // localStorage.removeItem('GUAC_AUTH');
                setOffStartButton(true);
                setShowSubmitModal(false);
                // if (evaluatedLab) {
                //   // stop();
                //   if (user.role !== "CONSUMER_LEARNER") {
                //     stopProctorCamera();
                //   }
                // }
                setLoadingStart(false);

              },
              err => {
                Toast.error({ message: 'Try again', time: 3000 });
                setLoadingStart(false);
              }
            )
          }
          else {
            // const connectionString = localStorage.getItem(`connectionString_${labId}_${labName}`);
            const connectionString = localStorage.getItem(`connectionString_${labId}_${labName}`);
            const connectionCheckString = connectionString.indexOf('#') > -1 ?
              connectionString.split('#')[1] : connectionString.split(':')[1].substring(2);
            RestService.terminateEC2InstanceAndTerminateGuacamoleServer(connectionCheckString, labId, sectionSid, trainingSid).then(
              response => {
                Toast.success({ message: 'Lab completed successfully', time: 3000 });
                setStopConnection('');
                setShowButton(false);
                markCourseAsCompletedLabs();
                localStorage.removeItem(`connectionString_${labId}_${labName}`);
                localStorage.removeItem('connectionString');
                localStorage.removeItem(`end_date_${labId}_${labName}`);;
                localStorage.removeItem(`stopServer_${labId}_${labName}`);
                // localStorage.removeItem('GUAC_AUTH');
                setOffStartButton(true);
                setShowSubmitModal(false);
                // if (evaluatedLab) {
                //   // stop();
                //   if (user.role !== "CONSUMER_LEARNER") {
                //     stopProctorCamera();
                //   }
                // }
              },
              err => {
                Toast.error({ message: 'Try again', time: 3000 });
                setLoadingStart(false);
              }
            )
          }
        } catch (err) {
          console.error("error occur on terminateEC2InstanceAndTerminateGuacamoleServer()", err)
        }
      }
    }
  }

  //stop lab and terminate for practice lab only
  const stopEC2InstanceAndTerminateGuacamoleServerPractice = async () => {
    try {
      setLoadingStart(true);
      if (external) {
        setIsLoading(true);

        setStartLabConnection('');
        setStopConnection('');
        setShowButton(false);
        localStorage.removeItem('appearButton');
        localStorage.removeItem(`connectionString_${labId}_${labName}`);
        localStorage.removeItem(`end_date_${labId}_${labName}`);;
        localStorage.removeItem(`stopServer_${labId}_${labName}`);
        setStopServer('');
        // localStorage.removeItem('GUAC_AUTH');
        setOffStartButton(true);
        setShowSubmitModal(false);
        setShowPauseModal(false);
        closeOpenedWindow();
        setLoadingStart(false);
        markCourseAsCompletedLabs();

      }
      else if (cloudLabUserUniqueId !== null) {

        const connectionString = "1234";
        let trainingSid = props.location.state.trainingSid;
        const sectionSid = contentSid;
        RestService.stopEC2InstanceAndTerminateGuacamoleServerForCloudlabs(connectionString, labId, sectionSid, trainingSid).then(
          response => {
            Toast.success({ message: 'Lab completed successfully', time: 3000 });
            setIsLoading(true);

            setStartLabConnection('');
            setStopConnection('');
            setShowButton(false);
            markCourseAsCompletedLabs();
            localStorage.removeItem('appearButton');
            localStorage.removeItem(`connectionString_${labId}_${labName}`);
            localStorage.removeItem(`end_date_${labId}_${labName}`);;
            localStorage.removeItem(`stopServer_${labId}_${labName}`);
            setStopServer('');
            // localStorage.removeItem('GUAC_AUTH');
            setOffStartButton(true);
            setShowSubmitModal(false);
            setShowPauseModal(false);
            closeOpenedWindow();
            setLoadingStart(false);
          },
          err => {
            Toast.error({ message: 'Try again', time: 3000 });
            setLoadingStart(false);
          }
        )
      }
      else if (startLabConnection.length > 0) {
        const connectionCheckString = startLabConnection.indexOf('#') > -1 ?
          startLabConnection.split('#')[1] : startLabConnection.split(':')[1].substring(2);
        RestService.stopEC2InstanceAndTerminateGuacamoleServer(connectionCheckString).then(
          response => {
            Toast.success({ message: 'Lab completed successfully', time: 3000 });
            setIsLoading(true);

            setStartLabConnection('');
            setStopConnection('');
            setShowButton(false);
            markCourseAsCompletedLabs();
            localStorage.removeItem('appearButton');
            localStorage.removeItem(`connectionString_${labId}_${labName}`);
            localStorage.removeItem(`end_date_${labId}_${labName}`);;
            localStorage.removeItem(`stopServer_${labId}_${labName}`);
            setStopServer('');
            // localStorage.removeItem('GUAC_AUTH');
            setOffStartButton(true);
            setShowSubmitModal(false);
            setShowPauseModal(false);
            setLoadingStart(false);
          },
          err => {
            Toast.error({ message: 'Try again', time: 3000 });
            setLoadingStart(false);
          }
        )
      }
      else if (stopConnection.length > 0) {
        RestService.stopEC2InstanceAndTerminateGuacamoleServer(stopConnection).then(
          response => {
            Toast.success({ message: 'Lab completed successfully', time: 3000 });
            setIsLoading(true);
            setStopConnection('');
            setShowButton(false);
            markCourseAsCompletedLabs();
            localStorage.removeItem('appearButton');
            localStorage.removeItem(`connectionString_${labId}_${labName}`);
            localStorage.removeItem(`end_date_${labId}_${labName}`);;
            localStorage.removeItem(`stopServer_${labId}_${labName}`);
            setStopServer('');
            // localStorage.removeItem('GUAC_AUTH');
            setOffStartButton(true);
            setShowPauseModal(false);
            setLoadingStart(false);
          },
          err => {
            Toast.error({ message: 'Try again', time: 3000 });
            setLoadingStart(false);
          }
        )

      }
      else {
        const connectionString = localStorage.getItem(`connectionString_${labId}_${labName}`);
        const connectionCheckString = connectionString.indexOf('#') > -1 ?
          connectionString.split('#')[1] : connectionString.split(':')[1].substring(2);
        RestService.stopEC2InstanceAndTerminateGuacamoleServer(connectionCheckString).then(
          response => {
            Toast.success({ message: 'Lab completed successfully', time: 3000 });
            setIsLoading(true);
            setStopConnection('');
            setShowButton(false);
            markCourseAsCompletedLabs();
            localStorage.removeItem(`connectionString_${labId}_${labName}`);
            localStorage.removeItem('connectionString');
            localStorage.removeItem(`end_date_${labId}_${labName}`);;
            localStorage.removeItem(`stopServer_${labId}_${labName}`);
            setStopServer('');
            // localStorage.removeItem('GUAC_AUTH');
            setOffStartButton(true);
            setShowPauseModal(false);
            setLoadingStart(false);
          },
          err => {
            Toast.error({ message: 'Try again', time: 3000 });
            setLoadingStart(false);
          }
        )
      }

    } catch (err) {
      console.error("error occur on stopEC2InstanceAndTerminateGuacamoleServerPractice()", err)
    }
  }
  //mark course as complete labs
  const markCourseAsCompletedLabs = () => {
    try {
      let trainingSid = props.location.state.trainingSid;
      let timestamp = new Date(localStorage.getItem(`end_date_${labId}_${labName}`) - Date.now());
      let splitMinutes = timestamp.toUTCString().match(/\d{2}:\d{2}:\d{2}/)[0];
      // let hour = Number(splitMinutes.split(':')[0]);
      // let minutes = Number(splitMinutes.split(':')[1]);
      // let minutes = timestamp.getMinutes();
      // const seconds = Number(splitMinutes.split(':')[2]);
      // let actualTimeInHour = hour === 0 ?  0 : hour * 60;
      // let actualTimeInMinutes = minutes > 0 ? minutes : 0;
      // let actualTime = actualTimeInHour + minutes;
      // let actualTimeInSeconds = minutes === 0 ? 0  : ;
      let minutes = Number(splitMinutes.split(':')[0])
        * 60 + Number(splitMinutes.split(':')[1]);
      let payload = {
        "completedInDuration": labDuration - minutes,
        "totalDuration": labDuration
      }

      spinner.show();
      RestService.markCourseAsCompletedLabs(labId, contentSid, trainingSid, payload).then(
        response => {

          // if (response.status === 200) {
          spinner.hide();
          navigate('/training/training-details');

          // }
        },
        err => {
          spinner.hide();
          navigate('/training/training-details');

        }
      )
      // .finally(() => {
      //   spinner.hide();
      // });
    } catch (err) {
      console.error("error occur on markCourseAsCompletedLabs()", err)
    }
  }

  //mark project as complete
  const markCourseAsCompletedProject = () => {
    try {
      let trainingSid = props.location.state.trainingSid;
      let payload = {
        "completedInDuration": 0,
        "totalDuration": 0
      }
      spinner.show();
      RestService.markCourseAsCompletedProject(projectId, contentSid, trainingSid, payload).then(
        response => {

          if (response.status === 200) {
          }
        },
        err => {
          spinner.hide();
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on markCourseAsCompletedProject()", err)
    }
  }

  //terminate the labs for assessment for users not giving sharing permission
  const terminateLabsForDeniedPermission = () => {
    const sectionSid = contentSid;
    const trainingSid = props.location.state.trainingSid;
    // stop lab for vscode 
    if (labIdArray.includes(labId)) {
      Toast.warning({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
      setStartLabConnection('');
      setStopConnection('');
      setShowButton(false);
      // markCourseAsCompletedLabs();
      localStorage.removeItem(`end_date_${labId}_${labName}`);;
      localStorage.removeItem(`connectionString_${labId}_${labName}`);
      localStorage.removeItem(`stopServer_${labId}_${labName}`);
      // localStorage.removeItem('GUAC_AUTH');
      setOffStartButton(true);
      // if (evaluatedLab) {
      //     stop();
      // }
    }
    else {
      try {
        spinner.show();
        if (cloudLabUserUniqueId !== null) {

          const connectionString = "1234";
          RestService.terminateEC2InstanceAndTerminateGuacamoleServer(connectionString, labId, sectionSid, trainingSid).then(
            response => {
              Toast.success({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
              setStopConnection('');
              setShowButton(false);
              // markCourseAsCompletedLabs();
              localStorage.removeItem(`connectionString_${labId}_${labName}`);
              localStorage.removeItem('connectionString');
              localStorage.removeItem(`end_date_${labId}_${labName}`);;
              localStorage.removeItem(`stopServer_${labId}_${labName}`);
              // localStorage.removeItem('GUAC_AUTH');
              setOffStartButton(true);
              setShowSubmitModal(false);
              closeOpenedWindow();
              // if (evaluatedLab) {
              //     stop();
              // }
            },
            err => {
              spinner.hide();
              Toast.error({ message: 'Try again', time: 3000 });
            }
          ).finally(() => {
            spinner.hide();
          });
        }
        else if (startLabConnection.length > 0 && !parentLab) {
          const connectionCheckString = startLabConnection.indexOf('#') > -1 ?
            startLabConnection.split('#')[1] : startLabConnection.split(':')[1].substring(2);
          RestService.terminateEC2InstanceAndTerminateGuacamoleServer(connectionCheckString, labId, sectionSid, trainingSid).then(
            response => {
              Toast.success({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
              setStartLabConnection('');
              setStopConnection('');
              setShowButton(false);
              // markCourseAsCompletedLabs();
              localStorage.removeItem('appearButton');
              localStorage.removeItem(`connectionString_${labId}_${labName}`);
              localStorage.removeItem(`end_date_${labId}_${labName}`);;
              localStorage.removeItem(`stopServer_${labId}_${labName}`);
              // localStorage.removeItem('GUAC_AUTH');
              setOffStartButton(true);
              setShowSubmitModal(false);
              // if (evaluatedLab) {
              //     stop();
              // }

              // setLabConnection('');
            },
            err => {
              spinner.hide();
              Toast.error({ message: 'Try again', time: 3000 });
            }
          ).finally(() => {
            spinner.hide();
          });
        }
        else if (stopConnection.length > 0) {
          RestService.terminateEC2InstanceAndTerminateGuacamoleServer(stopConnection, labId, sectionSid, trainingSid).then(
            response => {
              Toast.success({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
              setStopConnection('');
              setShowButton(false);
              // markCourseAsCompletedLabs();
              localStorage.removeItem('appearButton');
              localStorage.removeItem(`connectionString_${labId}_${labName}`);
              localStorage.removeItem(`end_date_${labId}_${labName}`);;
              localStorage.removeItem(`stopServer_${labId}_${labName}`);
              // localStorage.removeItem('GUAC_AUTH');
              setOffStartButton(true);
              setShowSubmitModal(false);
              // if (evaluatedLab) {
              //     stop();
              // }

            },
            err => {
              spinner.hide();
              Toast.error({ message: 'Try again', time: 3000 });
            }
          ).finally(() => {
            spinner.hide();
          });
        }

        else {
          // const connectionString = localStorage.getItem(`connectionString_${labId}_${labName}`);
          const connectionString = localStorage.getItem(`connectionString_${labId}_${labName}`);
          const connectionCheckString = connectionString.indexOf('#') > -1 ?
            connectionString.split('#')[1] : connectionString.split(':')[1].substring(2);
          RestService.terminateEC2InstanceAndTerminateGuacamoleServer(connectionCheckString, labId, sectionSid, trainingSid).then(
            response => {
              Toast.success({ message: 'To access the assessment, please grant permission for microphone and camera access.', time: 3000 });
              setStopConnection('');
              setShowButton(false);
              // markCourseAsCompletedLabs();
              localStorage.removeItem(`connectionString_${labId}_${labName}`);
              localStorage.removeItem('connectionString');
              localStorage.removeItem(`end_date_${labId}_${labName}`);;
              localStorage.removeItem(`stopServer_${labId}_${labName}`);
              // localStorage.removeItem('GUAC_AUTH');
              setOffStartButton(true);
              setShowSubmitModal(false);
              // if (evaluatedLab) {
              //     stop();
              // }
            },
            err => {
              spinner.hide();
              Toast.error({ message: 'Try again', time: 3000 });
            }
          ).finally(() => {
            spinner.hide();
          });
        }
      } catch (err) {
        console.error("error occur on terminateEC2InstanceAndTerminateGuacamoleServer()", err)
      }
    }
  }

  // mark violation for assessment labs
  const markViolation = () => {
    try {
      let trainingSid = props.location.state.trainingSid;
      let assessmentType = labType;
      let payload = {
        "trainingSid": trainingSid,
        "violationCode": "Multiple faces were detected for 5 times."
      }
      if (labType === "LAB") {
        payload["labId"] = labId
      }
      // else {
      //     payload["labcodingAssessmentSidId"] = codingAssessmentDetails.codingAssessmentSid;
      // }

      RestService.markViolation(assessmentType, payload).then(
        response => {

          if (response.status === 200) {
          }
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on markViolation()", err)
    }
  }

  //insertConnectionDetails for error block for s3
  const insertConnectionDetails = () => {
    try {
      let trainingSid = props.location.state.trainingSid;
      let payload = {
        "completionProgress": progress,
        "connectionBandwithDetails": "0",
        "connectionDeviceDetails": "0",
        "labId": labId,
        "systemDetails": "0",
        "trainingSid": trainingSid
      }
      RestService.insertConnectionDetails(payload).then(
        response => {

          if (response.status === 200) {
          }
        },
        err => {
          console.error(err);
        }
      )
    } catch (err) {
      console.error("error occur on insertConnectionDetails()", err)
    }

  }


  useEffect(() => {
    props.location.state.codingQuestiondesc ?
      setcodingQuestiondesc(props.location.state.codingQuestiondesc) : setcodingQuestiondesc(false)

  }, []);

  //go back to training page
  const handleGoToTrainingDetails = () => {

    if (evaluatedLab && (startLabConnection.length > 0 || `connectionString_${labId}_${labName}` in localStorage)
      && !showSubmitModal) {
      const response = window.confirm("Are you leaving the assessment area? Would you like to submit the assessment?");
      if (response) {
        terminateEC2InstanceAndTerminateGuacamoleServer();
        navigate("/training/training-details");

      }

      //this will terminate labs for recording assessment labs

    }
    // else if (startAssessmentStatus.length > 0) {
    //     const response = window.confirm("Are you sure you want to submit this assessment?");
    //     if (response) {
    //         submitCodingAssessment();
    //     }
    // }
    // else if (bookmark) {
    //   navigate("/bookmarks");
    // }
    // else {
    //   navigate("/training/training-details");
    // }
  }

  //restrict browser back button
  useEffect(() => {
    const handlePopstate = () => {
      // Redirect the user if they try to navigate back to the restricted page
      // window.location.pathname === '/training/training-details' ||
      if (window.location.pathname === '/bookmarks') {
        navigate('/training/training-details/labs');
      }
    };

    // Listen for the 'popstate' event
    window.addEventListener('popstate', handlePopstate);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handlePopstate);

    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (iframeRef.current && !iframeRef.current.contains(event.target)) {
        iframeRef.current.focus();
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  // useEffect(() => {
  //   const connectionStringKey = `connectionString_${labId}_${labName}` in localStorage;

  //   setTimeout(() => {
  //     if (connectionStringKey && evaluatedLab) {
  //       ec2GuacamolePOC();
  //     }
  //   }, 6000);

  // }, []);



  // FullScreen Lab 
  const toggleFullscreen = useCallback(() => {
    const element = document.getElementById('container');

    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
      setIsFullscreen(false);
    } else {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
      setIsFullscreen(true);
    }
  }, []);

  const openWindow = () => {
    // Disable the button
    setIsButtonDisabled(true);

    //open link 
    const openLink = startLabConnection.length === 0 ? localStorage.getItem(`connectionString_${labId}_${labName}`) : startLabConnection;
    // Open the window
    const newWindow = window.open(openLink);
    setOpenedWindow(newWindow);

    // Set up a timer to periodically check if the window is closed
    const checkClosedInterval = setInterval(() => {
      if (newWindow.closed) {
        // Enable the button when the window is closed
        setIsButtonDisabled(false);
        clearInterval(checkClosedInterval);
      }
    }, 1000);
  };

  const closeOpenedWindow = () => {
    // Close the window if it is open
    if (openedWindow) {
      openedWindow.close();
    }

    // Enable the button
    setIsButtonDisabled(false);
  }

  const handleWindowUnload = () => {
    if (openedWindow) {
      setIsButtonDisabled(false);
      setOpenedWindow(null);
    }
  };

  const mainRef = useRef();

  const handleMouseLeave = (e) => {
    // Check if the mouse is leaving the main component
    if (!mainRef.current.contains(e.relatedTarget)) {
      // if (user.role !== "CONSUMER_LEARNER") {
      handleGoToTrainingDetails();
      // }
      // Add your logic here
    }
  };

  // const handleClickOutside = (event) => {
  //   if (mainRef.current && !mainRef.current.contains(event.target)) {
  //     handleGoToTrainingDetails();
  //   }
  // };

  // useEffect(() => {
  //   // Add the event listener to document
  //   document.addEventListener('mousedown', handleClickOutside);

  //   // Cleanup function to remove the event listener
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [handleClickOutside]); // Including handleClickOutside in dependencies would normally cause a re-subscription on each render due to function identity changing. However, for demonstration purposes and clarity in this setup, it's listed here.

  useEffect(() => {
    window.addEventListener('beforeunload', handleWindowUnload);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleWindowUnload);
    };
  }, [openedWindow]);

  // useEffect(() => {
  //   if (evaluatedLab && (startLabConnection.length !== 0 ||
  //     `startLabConnection` in localStorage) && user.role !== "CONSUMER_LEARNER") {
  //     let intervalId;
  //     if (webcamRef !== null) {
  //       intervalId = setInterval(() => {
  //         capture();
  //       }, 40000);
  //     }

  //     return () => clearInterval(intervalId);
  //   }

  // }, [startLabConnection, localStorage]);

  const handlePermissionRequest = async () => {
    if (user.role !== "CONSUMER_LEARNER") {
      try {
        // Try to get user media to implicitly request permissions for camera and microphone
        await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        // If successful, permissions were granted
        return true; // Permissions granted
      } catch (error) {
        // If error, permissions were denied or there was another issue accessing media
        console.error('Error accessing camera and microphone:', error);
        return false; // Permissions denied or error occurred
      }
    }
    else {
      return true;
    }
  };


  // useEffect(() => {
  //   // Delay the permission request by 5 seconds
  //   const timeoutId = setTimeout(() => {
  //     if (!cameraAndMicPermission && evaluatedLab) {
  //       handlePermissionRequest();
  //     }
  //   }, 5000);

  //   // Cleanup timeout on component unmount
  //   return () => clearTimeout(timeoutId);
  // }, [cameraAndMicPermission, evaluatedLab]); // Include cameraAndMicPermission in the dependency array

  useEffect(() => {
    if ((cloudLabUserUniqueId !== null && evaluatedLab) || external) {
      if (`connectionString_${labId}_${labName}` in localStorage) {
        setIsLoading(false);
      }
    }
    else if (cloudLabUserUniqueId !== null) {
      if (`connectionString_${labId}_${labName}` in localStorage) {
        setIsLoading(false);
      }
    }
  }, [evaluatedLab, cloudLabUserUniqueId, external]);

  // const wrapperRef = useRef(null);
  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
  //       if (evaluatedLab && (startLabConnection.length > 0 || `connectionString_${labId}_${labName}` in localStorage)) {
  //         setShowSubmitModal(true);
  //       }
  //     }
  //   };

  //   // Attach the event listener
  //   document.addEventListener('mousedown', handleClickOutside);

  //   // Clean up the event listener
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, [wrapperRef]);

  // useEffect(() => {
  //   console.log("inside useeffect");
  //   const handleOutsideClick = (event) => {
  //     if (iframeRef.current && !iframeRef.current.contains(event.target)) {
  //       iframeRef.current.focus();
  //     }
  //   };

  //   const clickEnableTypingButton = () => {
  //     const typingButton = document.getElementById('typing');
  //     if (typingButton) {
  //       typingButton.click();
  //       console.log("inside typing")
  //     }
  //   };

  //   document.addEventListener('click', handleOutsideClick);

  //   // Set a timeout to click the "Enable Typing" button after 5 seconds
  //   const timeoutId = setTimeout(clickEnableTypingButton, 5000);

  //   return () => {
  //     document.removeEventListener('click', handleOutsideClick);
  //     clearTimeout(timeoutId); // Clear the timeout if the component unmounts before the 5 seconds
  //   };
  // }, [labId]);

  return (
    <>
      <div className="mainlabscreen" >
        <Split className='split' sizes={[30, 70]} gutterSize={18} style={{ height: "100vh" }}>
          {/*Task part  */}



          <div className='task'

          >
            <div style={{ background: "#fff", width: "100%", height: "100vh" }}>
              <div className='taskheader'>
                <div className='taskheader-taskname'>{labName}</div>
                {/* <div><button className="normalbtn " style={{ float: "inline-end", border: "none" }}  >Expand {ICN_EXPAND_NEW}</button></div> */}
              </div>

              {
                parentLab &&
                <>
                  <hr />
                  {pages.length > 1 ?
                    <div style={{ display: "flex", justifyContent: "space-between", bottom: "0px" }}>

                      <button className='ftr-icn ' onClick={handlePrevious} disabled={currentIndex === 0}>Previous Lab</button>
                      <button className='ftr-icn ' onClick={handleNext} disabled={currentIndex === pages.length - 1}>Next Lab</button>
                    </div>
                    :
                    <></>
                  }

                </>
              }
              {
                (cloudLabUserUniqueId !== null && cloudLabUserUniqueId !== undefined) &&
                <>
                  <div className='d-flex mt-3 title-sm p-2 ' style={{ border: "0.3px solid #fff", borderRadius: "10px", background: "#EDEDED", textAlign: "center" }}>
                    <div className="title-sm">UUID</div>
                    <div className="title-sm mx-3" style={{ textAlign: "right" }}>{cloudLabUserUniqueId}</div>
                  </div>

                </>}

              <div class="tabset" >

                <input
                  type="radio"
                  name="tabset"
                  id="tab1"
                  aria-controls="task"
                  checked={activeTab === 'task'}
                  onChange={() => handleTabChange('task')}
                />
                <label htmlFor="tab1" style={{ marginBottom: "0px" }}>Tasks</label>

                <input
                  type="radio"
                  name="tabset"
                  id="tab2"
                  aria-controls="rauchbier"
                  checked={activeTab === 'rauchbier'}
                  onChange={() => handleTabChange('rauchbier')}
                />
                {
                  evaluatedLab &&

                  <label htmlFor="tab2" style={{ marginBottom: "0px" }}>Compile your Lab</label>
                }

                <div class="tab-panels">
                  <section id="task" className={`tab-panel ${activeTab === 'task' ? 'active' : ''}`}>
                    <div className='assessment-objective-parrent' onClick={toggleCollapse}>

                      <div className='assessment-objective' >Objective</div>
                      <div className='assement-objective-collpase-icon'>  {isCollapsed ? <span>{ICN_EXPAND_ACCORDIAN}</span> : <span>{ICN_COLLAPSED}</span>}</div>
                    </div>
                    {/* {!isCollapsed && (

                      <div className='assessment-objective-content px-3'>
                      
                        {parse(labDescription)}
                      </div>
                    )} */}
                    {codingQuestiondesc !== false ? (
                      <ReactMarkdown>{codingQuestiondesc}</ReactMarkdown>


                    ) :
                      labType === "PROJECT" ?
                        parse(labDescription)
                        :
                        (
                          // <ReactMarkdown>{labDescription}</ReactMarkdown>
                          <>
                            {!isCollapsed && (

                              <div className='assessment-objective-content px-3'>
                                {/* Content of the collapsible div */}

                                {parse(labDescription)}
                              </div>
                            )}
                          </>
                        )}
                    {/* <br />
                    <hr /> */}

                    {codingQuestiondesc !== false || labType === "PROJECT" ?
                      ""
                      :
                      <div className='labstepsparent p-3'>
                        {/* <div className='labstepsheading'>Lab Steps</div> */}
                        <div className='labsteps'> {parse(labOverview)}</div>

                      </div>

                    }

                    {/* <div className='labstepsparent p-3'>
                      <div className='labstepsheading'>Lab Steps</div>
                      <div className='labsteps'> {parse(labOverview.join(" "))}</div>

                    </div> */}






                  </section>
                  {
                    evaluatedLab &&


                    <section id="rauchbier" className={`tab-panel ${activeTab === 'rauchbier' ? 'active' : ''}`}>

                      <button className="vmstart-button mt-3" style={{ width: "100%", opacity: (`connectionString_${labId}_${labName}` in localStorage) ? 1 : 0.5 }}
                        disabled={!(`connectionString_${labId}_${labName}` in localStorage)} onClick={() => compileLab()}>
                        {loading ? (
                          <>
                            <Spinner
                              as="span"
                              animation="border"
                              size="md"
                              role="status"
                              aria-hidden="true"
                              className="mr-2 loader-button" // Add margin to separate spinner from text
                              style={{ border: '5px dotted #fff', width: "20px", height: "20px" }}
                            />
                            {' '}
                            Compiling
                          </>
                        ) :
                          'Compile Lab'
                        }
                      </button>



                      <div className="mt-3" >

                        <div className="d-flex main-compile-section" style={{ width: "100%", justifyContent: "space-between", borderBottom: "1px solid #EAECF0" }}>
                          <div className="main-compile-section-heading">Tasks</div>
                          <div className="main-compile-section-heading">{
                            buttonText === 'Open VsCode' ?
                              'Count'
                              :
                              'Status'}</div>
                        </div>


                        {
                          compileLabResponse && Object.keys(compileLabResponse)?.length > 0
                            ? Object.keys(compileLabResponse).map(compile => {
                              return (
                                <div className="d-flex main-compile-section" style={{ width: "100%", justifyContent: "space-between", borderBottom: "1px solid #EAECF0", background: "#F9FAFB" }}>

                                  <div className="task-perforamnce-name">{compile}</div>
                                  {
                                    buttonText === 'Open VsCode' &&
                                    <div className="title-md">{compileLabResponse[compile]}</div>
                                  }

                                  {
                                    devLab && cloudLab ?
                                      <>
                                        {compileLabResponse[compile] ?
                                          <div className="pass-task">{PASS_TASK} True</div>
                                          :
                                          <div className="fail-task">{FAIL_TASK} False</div>
                                        }
                                      </>
                                      :
                                      evaluatedLab && buttonText !== 'Open VsCode' ?
                                        <>
                                          {compileLabResponse[compile] !== 0 ?
                                            <div className="pass-task">{compileLabResponse[compile]}</div>
                                            :
                                            <div className="fail-task">{compileLabResponse[compile]}</div>
                                          }
                                        </>
                                        :
                                        buttonText !== 'Open VsCode' &&
                                        <>
                                          {compileLabResponse[compile] ?
                                            <div className="pass-task">{PASS_TASK} Pass</div>
                                            :
                                            <div className="fail-task">{FAIL_TASK} Fail</div>
                                          }
                                        </>


                                  }

                                </div>
                              )
                            })
                            :
                            errorMessageCompileLab?.length !== 0 &&
                            <div className="fail-task p-3">{errorMessageCompileLab}</div>
                        }



                        {/* <div className="d-flex main-compile-section" style={{ width: "100%", justifyContent: "space-between", borderBottom: "1px solid #EAECF0" }}>

                        <div className="task-perforamnce-name">Images folder exixtance</div>
                        <div className="fail-task">{FAIL_TASK} Fail</div>
                      </div> */}







                      </div>


                    </section>
                  }

                </div>






              </div>








            </div>
            {/* {parentLab ? ''
              :
              <div className=' taskfooter'>

                <div className='ftr-icn ' onClick={handlePrevPage} disabled={currentPage === 1}>{ICN_LEFT_ARR}</div>
                <div className='taskfooter-pagination-text'> Page {currentPage} of {totalPages}</div>
                <div className='ftr-icn ' onClick={handleNextPage} disabled={currentPage === totalPages}>{ICN_RIGHT_ARR}</div>

              </div>
            } */}


          </div>


          {/* vm part */}


          {/* {faceDetections >= 2 ?
            <BsModal {...{ show, setShow, headerTitle: "Attention: Multiple faces detected!", size: "md" }} />
            : */}
          <div style={{ height: '100vh' }}>

            <div className='p-3' style={{ background: "#fff", height: "100vh" }}>
              {
                labType === "LAB" &&

                <div className='row pb-3 ' style={{ boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}>
                  <div className='col-md-9 d-flex'>
                    {/* {
                    startLabs.length === 0 && !disappearTimer &&

                    <button className='vmstart-button mx-2' onClick={handleStartAssessment}>Click here to Start Lab</button>
                  } */}
                    {
                      (stopServer.length > 0 || `stopServer_${labId}_${labName}` in localStorage)
                        ?
                        <button className='vmstart-button mx-2' onClick={() => {
                          ec2GuacamolePOC();
                        }
                        }>Resume Lab</button>
                        :
                        // `connectionString_${labId}_${labName}` in localStorage || startLabConnectionParentLabs !== null ?
                        //   <div>
                        //     <p className="text-white">Started</p>
                        //   </div>
                        //   :
                        (startLabConnection.length === 0 && stopConnection.length === 0 &&
                          `connectionString_${labId}_${labName}` in localStorage === false) ?
                          <button className='vmstart-button mx-2' onClick={() => {
                            ec2GuacamolePOC();
                            // setShowSubmitModal(true);
                          }
                          }>
                            {loadingStart ?

                              <Spinner
                                as="span"
                                animation="border"
                                size="md"
                                role="status"
                                aria-hidden="true"
                                className="mr-2 loader-button" // Add margin to separate spinner from text
                                style={{ border: '5px dotted #fff', width: "20px", height: "20px" }}
                              />
                              :

                              'Start Lab'}</button>

                          :
                          disappearTimer &&
                          <button className='vmstart-button ' >Starting in {counter} ....</button>


                    }
                    {/* {
                    disappearTimer &&
                    <button className='vmstart-button ' >Starting in {counter} ....</button>
                  } */}
                    {
                      showButton || `connectionString_${labId}_${labName}` in localStorage ?
                        <>
                          {
                            evaluatedLab || labIdArray.includes(labId) ? "" :
                              <></>
                            // <div>
                            //   {
                            //     stopServer.length === 0 && `stopServer_${labId}_${labName}` in localStorage === false ?
                            //       <button className='vmstart-button mx-2' onClick={() => setShowPauseModal(true)}>Pause</button>

                            //       :


                            //       <button className='vmstart-button mx-2'>Paused</button>

                            //   }
                            // </div>
                          }

                          <div >
                            <button className='vmstart-button'
                              // data-tooltip-id="submitForLabs" data-tooltip-content={evaluatedLab && checkTimeLeftInMinutes > 10 ? "The assessment can be submitted when the remaining time is 10 minutes or less." : ''}
                              // style={{ cursor: evaluatedLab && checkTimeLeftInMinutes > 10 && 'not-allowed', opacity: evaluatedLab && checkTimeLeftInMinutes > 10 && '0.5' }}
                              onClick={() => {
                                setShowSubmitModal(true);
                              }
                              }
                            // disabled={evaluatedLab && checkTimeLeftInMinutes > 10}
                            >
                              Submit and Exit</button>
                            {/* <Tooltip
                              id="submitForLabs"
                              place='bottom'
                              style={{ width: "30%", fontFamily: "Inter" }}
                            /> */}
                          </div>


                        </>
                        : ''}
                    {/* {
                    startLabs.length !== 0 &&
                    <>
                      <button className='btntimer'><span style={{ verticalAlign: "text-bottom" }} >T</span> 47:13</button>
                      <button className='vmstart-button mx-2'>Pause</button>
                      <button className='vmstart-button'>Submit and Exit</button>
                    </>
                  } */}
                    <div className="mx-2">
                      {
                        startLabConnection.length > 0 && !isLoading ?
                          <CountdownTimer {...{
                            timeLimit: labDuration,
                            type: 'LAB',
                            labId: labId,
                            labName: labName,
                            // evaluatedLab: evaluatedLab,
                            // setCheckTimeLeftInMinutes: setCheckTimeLeftInMinutes,
                            callback: () => evaluatedLab ? terminateEC2InstanceAndTerminateGuacamoleServer()
                              : stopEC2InstanceAndTerminateGuacamoleServerPractice()
                          }} />
                          : `connectionString_${labId}_${labName}` in localStorage ?
                            <CountdownTimer {...{
                              timeLimit: minutes,
                              type: 'LAB',
                              labId: labId,
                              labName: labName,
                              // evaluatedLab: evaluatedLab,
                              // setCheckTimeLeftInMinutes: setCheckTimeLeftInMinutes,
                              callback: () => evaluatedLab ? terminateEC2InstanceAndTerminateGuacamoleServer()
                                : stopEC2InstanceAndTerminateGuacamoleServerPractice()
                            }} />
                            : ''
                      }
                    </div>

                  </div>
                  <div className="col-md-3">
                    {
                      (startLabConnection.length > 0 && stopConnection.length > 0) ?
                        isLoading ?
                          <></>
                          :
                          (buttonText === null || buttonText === "Open VsCode" || buttonText === "Open Lab") &&
                          <div style={{ float: "right" }} data-tooltip-id="contentname" data-tooltip-content='Enable FullScreen' >
                            <button id="toggle_fullscreen" onClick={toggleFullscreen} className="btntimer">
                              {<FullscreenIcon />}
                            </button>
                            {/* <button className="ass-archive" title="Enable Typing" id="typing" style={{ display: 'none' }}>
                              Enable Typing
                            </button> */}
                          </div>
                        :
                        `connectionString_${labId}_${labName}` in localStorage ?

                          (buttonText === null || buttonText === "Open VsCode" || buttonText === "Open Lab") &&
                          <>

                            <div style={{ float: "right" }} data-tooltip-id="contentname" data-tooltip-content="Enable FullScreen">
                              <button id="toggle_fullscreen" onClick={toggleFullscreen} className="btntimer">
                                {<FullscreenIcon />}
                              </button>
                              {/* <button className="ass-archive" title="Enable Typing" id="typing" style={{ display: 'none' }}>
                                Enable Typing
                              </button> */}
                            </div>
                          </>
                          : ""

                    }
                    <Tooltip
                      id="contentname"
                      place='left'
                      style={{ fontFamily: "Inter" }}
                    />
                  </div>
                </div>
              }
              {/* {
                  disappearTimer &&
                  <div className='vmcontainer ' style={{ height: "100vh" }}>

                    <NewLabspinner />
                  </div>
                } */}

              {/* Start vm screen after loader */}
              <div style={{ height: "100vh" }}>
                {
                  labType === 'CODING' ?
                    <div className="col-9 mainbody" >
                      {
                        !showEditor ? <button className="btn btn-primary mt-3" style={{ color: "#fff", fontSize: "15px" }} onClick={() => { setShowEditor(true) }}>Start Lab</button>
                          : <div className="btn btn-primary mt-3" style={{ color: "#fff", fontSize: "15px" }}>Started</div>
                      }

                      {
                        showEditor ?
                          <CodeEditor {...{
                            trainingSid: props.location.state.trainingSid,
                            codingQuestionId: props.location.state.codingQuestionId,
                            sectionSid: props.location.state.contentSid,
                            labType: labType,
                            labDuration: labDuration
                          }} />
                          : <div className="title-sm mt-3">Please Click on Start Lab</div>
                      }
                    </div>
                    :
                    labType === "PROJECT" ?
                      <div className=" mainbody ml-2" style={{ flex: '1', height: '100%', overflow: 'auto', background: "#F1F3F4" }}>
                        <div className=' p-5 h-75' >
                          <>

                            <div className='assessment-session-parrent'>
                              <div className="row mx-1 mb-3" style={{ width: "100%" }}>

                                <label className="mb-2 title-sm">Repository Link*</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Git repository URL"
                                  value={repoLink}
                                  onChange={handleInputChangeLink}
                                />
                                {isValid === undefined ?
                                  ""
                                  :
                                  isValid === false &&
                                  (
                                    <p style={{ color: "red" }}>Invalid GitHub Repository Link</p>
                                  )}


                              </div>

                              <div className="row mx-1 " style={{ width: "100%", flexDirection: "column" }}>

                                <label className="mb-2 title-sm">Project Asset</label>


                                <div className="drop-zone">
                                  <div className='d-flex' style={{ flexDirection: "column", cursor: "pointer" }}>
                                    <div className='text-center dropzone_icon'>{UPLOAD_ICON_PROJECT}</div>
                                    <span className="drop-zone__prompt">
                                      <span className='click'>Click to upload</span> or Drop file here
                                    </span>
                                  </div>
                                  <input type="file" name="myFile" accept='.zip' className="drop-zone__input" ref={inputRef} onChange={handleFileChange} style={{ display: 'none' }} />

                                </div>
                                {fileTypeError && <div className="error-message text-danger">{fileTypeError}</div>}







                                {
                                  isValidFile !== undefined && !isValidFile &&

                                  // <div class="alert alert-danger" role="alert">
                                  <p style={{ color: "red" }}>Upload Zip File Only</p>
                                  // </div>
                                }



                              </div>

                              <div className="row mx-1 mb-3" style={{ width: "100%" }}>

                                <button
                                  className="btn btn-primary py-2 mt-4"
                                  type="submit"
                                  disabled={isDisabled()}
                                  style={{ width: "100%" }}
                                  onClick={() => submitProjectResponse()}
                                >
                                  Submit
                                </button>
                              </div>

                            </div>


                          </>

                        </div>
                      </div>
                      :

                      <>


                        <div className=" ml-2" >
                          {/* labbacimg */}
                          <div className=" row ml-1"  >





                            {/* <div className="col-2" style={{ textAlign: "center", textDecoration: "none", background: "#471579 ", padding: "15px 20px", marginLeft: "18px", marginBottom: "50px", marginTop: "40px", border: "1px solid #471579", borderRadius: "10px" }}>

                              {
                                (stopServer.length > 0 || `stopServer_${labId}_${labName}` in localStorage)
                                  ?
                                  <button style={{ color: "#fff", fontSize: "15px" }} onClick={() => {
                                    ec2GuacamolePOC();
                                  }
                                  }>Resume Lab</button>
                                  :
                                  `connectionString_${labId}_${labName}` in localStorage || startLabConnectionParentLabs !== null ?
                                    <div>
                                      <p className="text-white">Started</p>
                                    </div>
                                    :
                                    (startLabConnection.length === 0 && stopConnection.length === 0 &&
                                      `connectionString_${labId}_${labName}` in localStorage === false) ?
                                      <button style={{ color: "#fff", fontSize: "15px" }} onClick={() => {
                                        ec2GuacamolePOC();
                                      }
                                      }>Start Lab</button>

                                      :
                                      <div className="new-chat-bubble" style={{ border: "none" }}>
                                        <p className="text-white pl-2" style={{ display: "flex" }} >Starting

                                          <div class="typing" style={{ border: "none" }}>
                                            <div class="dot"></div>
                                            <div class="dot"></div>
                                            <div class="dot"></div>
                                          </div>
                                        </p>
                                      </div>
                       
                              }
                            </div> */}
                            {/* {
                              showButton || `connectionString_${labId}_${labName}` in localStorage ?
                                <>
                                  {
                                    evaluatedLab || labIdArray.includes(labId) ? "" :
                                      <div className="col-2" style={{ textAlign: "center", textDecoration: "none", background: "#471579 ", padding: "15px 20px", marginLeft: "25px", marginBottom: "50px", marginTop: "40px", border: "1px solid #471579", borderRadius: "10px" }}>
                                        {
                                          stopServer.length === 0 && `stopServer_${labId}_${labName}` in localStorage === false ?

                                            <button style={{ color: "#fff", fontSize: "15px" }} onClick={() => stopEC2InstanceAndTerminateGuacamoleServer()}>
                                              Pause Lab
                                            </button>
                                            :

                                            <div>
                                              <p className="text-white">Paused</p>
                                            </div>
                                        }
                                      </div>
                                  }

                                  <div className="col-2" style={{ textAlign: "center", textDecoration: "none", background: "#471579", padding: "15px 20px", marginLeft: "25px", marginBottom: "50px", marginTop: "40px", border: "1px solid #471579", borderRadius: "10px" }}>
                                    <button style={{ color: "#fff", fontSize: "15px" }}
                                      onClick={() =>
                                        evaluatedLab ?
                                          terminateEC2InstanceAndTerminateGuacamoleServer()
                                          : stopEC2InstanceAndTerminateGuacamoleServerPractice()
                                      }
                                    >
                                      Complete Lab</button>
                                  </div>

                                
                                </>
                                : ''} */}
                            {/* <div className="col-2" style={{ marginLeft: "25px", marginBottom: "50px", marginTop: "50px" }}>
                              {
                                startLabConnection.length > 0 && !isLoading ?
                                  <CountdownTimer {...{
                                    timeLimit: labDuration,
                                    callback: () => evaluatedLab ? terminateEC2InstanceAndTerminateGuacamoleServer()
                                      : stopEC2InstanceAndTerminateGuacamoleServerPractice()
                                  }} />
                                  : `connectionString_${labId}_${labName}` in localStorage ?
                                    <CountdownTimer {...{
                                      timeLimit: minutes,
                                      callback: () => evaluatedLab ? terminateEC2InstanceAndTerminateGuacamoleServer()
                                        : stopEC2InstanceAndTerminateGuacamoleServerPractice()
                                    }} />
                                    : ''
                              }
                            </div> */}

                          </div>

                          {
                            // external ?
                            //   <div className="vmcontainer" style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            //     {(startLabConnection.length > 0 && stopConnection.length > 0) || (`connectionString_${labId}_${labName}` in localStorage) ?
                            //       (isLoading ? (
                            //         <NewLabspinner />
                            //       ) :

                            //         <div style={{ margin: "auto" }}>
                            //           <button className='vmstart-button' onClick={openWindow} disabled={isButtonDisabled}>
                            //             {
                            //               buttonText !== null ?
                            //                 buttonText
                            //                 :
                            //                 'Open Lab'
                            //             }
                            //           </button>
                            //         </div>

                            //       )
                            //       : ""
                            //     }
                            //   </div>
                            //   :
                            cloudLabUserUniqueId !== null && buttonText !== 'Open VsCode' ?
                              <div className="vmcontainer" style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                {(startLabConnection.length > 0 && stopConnection.length > 0) || (`connectionString_${labId}_${labName}` in localStorage) ?
                                  (isLoading ? (
                                    <NewLabspinner />
                                  ) : (
                                    cloudLabUserUniqueId !== null && (
                                      <div style={{ margin: "auto" }}>
                                        <button className='vmstart-button' onClick={openWindow} disabled={isButtonDisabled}>
                                          {
                                            buttonText !== null ?
                                              buttonText
                                              :
                                              'Open Lab'
                                          }
                                        </button>
                                      </div>
                                    )
                                  ))
                                  : ""
                                }
                              </div>

                              :
                              <div style={{ height: "85vh" }} id="container">

                                {(startLabConnection.length > 0 && stopConnection.length > 0) ? (
                                  isLoading ? (
                                    <NewLabspinner />
                                  ) : (
                                    buttonText === 'Open VsCode' ?

                                      <iframe ref={iframeRef} id="modal-link" is="x-frame-bypass" src={`${startLabConnection}`} width="100%" height="100%" />
                                      :
                                      external ?
                                        <iframe ref={iframeRef} id="modal-link" is="x-frame-bypass" src={`${startLabConnection}`} width="100%" height="100%" />
                                        :

                                        <iframe ref={iframeRef} id="modal-link" is="x-frame-bypass" src={`${startLabConnection}?token=${token}`} width="100%" height="100%" />
                                  )
                                ) : `connectionString_${labId}_${labName}` in localStorage ? (
                                  buttonText === 'Open VsCode' ?
                                    <iframe ref={iframeRef} id="modal-link" is="x-frame-bypass" src={localStorage.getItem(`connectionString_${labId}_${labName}`)} width="100%" height="100%" /> :
                                    external ?
                                      <iframe ref={iframeRef} id="modal-link" is="x-frame-bypass" src={localStorage.getItem(`connectionString_${labId}_${labName}`)} width="100%" height="100%" />

                                      :
                                      <iframe ref={iframeRef} id="modal-link" is="x-frame-bypass" src={localStorage.getItem(`connectionString_${labId}_${labName}`) + `?token=${token}`} width="100%" height="100%" />


                                ) : (
                                  <div>

                                  </div>
                                )}
                              </div>
                          }
                        </div>
                        {/* {user.role !== "CONSUMER_LEARNER" &&
                          <video crossOrigin='anonymous' ref={videoRef} height="1" width="1" autoPlay></video>} */}
                        {/* {evaluatedLab && (startLabConnection.length !== 0 ||
                          `startLabConnection` in localStorage) &&
                          user.role !== "CONSUMER_LEARNER" &&
                          <div style={{ width: '1px', height: '1px', overflow: 'hidden', visibility: 'hidden' }}>
                            <Webcam
                              videoConstraints={videoConstraints}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              screenshotQuality={0.9}
                              onUserMedia={handleUserMedia}
                              onUserMediaError={handleUserMediaError}
                              audio={false}
                            />
                          </div>

                        } */}
                      </>
                }

              </div>
            </div>

          </div>
          {/* } */}

        </Split>
        {showSubmitModal && (
          <AlertDialog open={showSubmitModal} setOpen={setShowSubmitModal} title='Submit and Exit'
            loadingStart={loadingStart}
            body={`Once you submit " ${labName} ", you won't be able to edit your response`}
            apiMethod={evaluatedLab ?
              terminateEC2InstanceAndTerminateGuacamoleServer
              : stopEC2InstanceAndTerminateGuacamoleServerPractice} />
        )}
        {/* {
          showPauseModal && (
            <AlertDialog open={showPauseModal} setOpen={setShowPauseModal} title='Pause Your Lab'
              body={`Once you paused this " ${labName} ", you can resume and continue`} apiMethod={stopEC2InstanceAndTerminateGuacamoleServer} />
          )} */}

      </div>
    </>
  )
}

export default NewLab