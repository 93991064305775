import React, { useEffect, useState, useContext } from 'react'
import { BtnPrimary } from '../../Common/Buttons/Buttons';
import { BsModal } from '../../Common/BsUtils';
import AssignProject from './AssignProject';
import { useNavigate } from "@reach/router";
import { Link } from '@material-ui/core';
import RestService from '../../../Services/api.service';
import AppContext from '../../../Store/AppContext';
import SkeletonLoader from "../../Common/SkeletonLoader/SkeletonLoader";
import parse from 'html-react-parser';

const ProjectDetails = (props) => {
    const [show, setShow] = useState(false);
    const [filterCategory, setFilterCategory] = useState([]);
    const [projectId, setProjectId] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [loader, setLoader] = useState(true);
    const { spinner } = useContext(AppContext);
    const navigate = useNavigate();

    const goBack = () => {
        localStorage.removeItem("trainingSid");
        navigate("/project", { state: { title: "Project" } });
    };

    //filter categories list
    const filterProjectCategories = () => {
        try {
            const categoryName = props.location.state.categoryName;
            RestService.filterProjectCategories(categoryName).then(
                response => {
                    if (response.status === 200) {
                        setFilterCategory(response.data);
                       
                        setTimeout(() => {
                            setLoader(false);
                        }, 2000);
                    }
                },
                err => {
                    // spinner.hide();
                    console.error(err);
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on filterProjectCategories()", err)
        }
    }

    useEffect(() => {
        filterProjectCategories();
        // getSupervisorTrainings();
    }, []);

    return (
        <>
            <nav aria-label="breadcrumb mb-4">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <Link onClick={() => goBack()} style={{ cursor: "pointer" }}>
                            Project
                        </Link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                       {props.location.state.categoryName}
                    </li>
                </ol>
            </nav>

            <div className="catalog-container">
                {filterCategory.map((item) => (
                    <div key={item.projectId} className="labList" style={{height:"auto"}}>
                        <div className="labList-info flx5">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                {
                                    loader ?
                                        <SkeletonLoader skeletonCount={1} />
                                        :
                                        <div className="cat-title-md">{item.projectName}</div>
                                }

                            </div>
                            {
                                loader ?
                                    <SkeletonLoader skeletonCount={1} /> 
                                    :
                                    <div className="cat-title-sm py-2" style={{textAlign:"justify", fontSize:"15px"}}>{ item.projectDescription.length > 200 ?
                                        parse(item.projectDescription.substring(0, 200)) : parse(item.projectDescription)}</div>
                            }
                            <div className="text-md">
                                <div className="flx f12">
                                    {
                                        loader ?
                                            <SkeletonLoader skeletonCount={1} />
                                            :
                                            <div className="mr-3 cat-title-md">{item.durationInDays} Days</div>
                                    }
                                    {/* <div className="mr-3">Intermediate</div>
                      <div className="mr-3 elps">on Demand</div>
                      <div className="mr-3">0.2$</div> */}
                                </div>
                            </div>
                        </div>
                        <div className="text-center jcb-c">
                            <div>
                                {
                                    loader ?
                                        <SkeletonLoader skeletonCount={1} />
                                        :
                                        <BtnPrimary
                                            className="btn-block"
                                            onClick={() => {
                                                setShow(true);
                                                setProjectDescription(item.projectDescription);
                                                setProjectId(item.projectId);
                                            }}
                                        >
                                            + Assign
                                        </BtnPrimary>
                                }
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            {show && (
                <BsModal
                    {...{ show, setShow, headerTitle: "Assign Project", size: "xl" }}
                >
                    <AssignProject
                        projectDescription={projectDescription}
                        projectId={projectId}
                    />
                </BsModal>
            )}
        </>
    );
};



export default ProjectDetails