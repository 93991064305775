import React, { useEffect ,useState} from 'react';
import "./labs.css"
import GLOBELCONSTANT from '../../../../Constant/GlobleConstant';
const LabSpinner = () => {


  const [secondsRemaining, setSecondsRemaining] = useState(20);

  useEffect(() => {
    const timer = setInterval(() => {
      setSecondsRemaining((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const [facts, setFacts] = useState([
    "The first computer programmer was a woman named Ada Lovelace.",
    "The first graphical web browser was called Mosaic",
    "The first computer mouse was made of wood.",
    "The world's first computer was the size of a room.",
    "The concept of a computer virus was inspired by biology.",
    "The internet weighs approximately the same as a strawberry.",
    "The world's first website is still online.",
    "The first known computer game was created in 1962.",
    "The QWERTY keyboard layout was designed to slow typists down",
    "The term \"byte\" was coined by computer scientist Werner Buchholz.",
    "The world's first webcam was used to monitor a coffee pot.",
    "The concept of a computer virus was inspired by biology.",
    "The ENIAC computer consumed enough electricity to power a small town.",
    "The first domain name ever registered was symbolics.com.",
    "The first computer password was \"login.\"",
    "The first computer mouse had only one button.",
    "The world's first computer virus was created in 1986."
  ]);

  const [currentFact, setCurrentFact] = useState('');

  useEffect(() => {
    if (facts.length > 0) {
      const interval = setInterval(() => {
        const randomIndex = Math.floor(Math.random() * facts.length);
        const fact = facts[randomIndex];
        setCurrentFact(fact);
        setFacts(prevFacts => prevFacts.filter(item => item !== fact));
      }, 4000); // Change the interval duration (in milliseconds) as desired

      return () => {
        clearInterval(interval); // Clean up the interval on component unmount
      };
    }
  }, [facts]);
  useEffect(() => {
    const isLast = (word) => {
      return word.nextElementSibling ? false : true;
    };

    const getNext = (word) => {
      return word.nextElementSibling;
    };

    const getVisible = () => {
      return document.getElementsByClassName('is-visible')[0];
    };

    const getFirst = () => {
      return document.querySelector('.words-wrapper').firstElementChild;
    };

    const switchWords = (current, next) => {
      current.classList.remove('is-visible');
      current.classList.add('is-hidden');
      next.classList.remove('is-hidden');
      next.classList.add('is-visible');
    };

    const getStarted = () => {
      const first = getVisible();
      const next = getNext(first);

      if (next) {
        switchWords(first, next);
      } else {
        first.classList.remove('is-visible');
        first.classList.add('is-hidden');
        const newEl = getFirst();
        newEl.classList.remove('is-hidden');
        newEl.classList.add('is-visible');
      }
    };

    const init = () => {
      setInterval(() => {
        getStarted();
      }, 5000);
    };

    init();
  }, []);
  return (
    <>
    {/* <div id="timer">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
        <circle className="circle" cx="100" cy="100" r="90"></circle>
        <circle className="fill" cx="100" cy="100" r="90"></circle>
        <text className="text" x="50%" y="50%">
          {formatTime(secondsRemaining)}
        </text>
      </svg>
    </div> */}
<div id="circle">
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 80" fill="#fff">
    <g>
      <text className="text" x="50%" y="20%" dominantBaseline="middle" textAnchor="middle" fontFamily="Arial" fontSize="32px" fontWeight="bold">
        {formatTime(secondsRemaining)}
      </text>
      <text className="text" x="50%" y="80%" dominantBaseline="middle" textAnchor="middle" fontFamily="Arial" fontSize="24px" fontWeight="bold">
        {/* GnosisLabs */}
        {GLOBELCONSTANT.BRANDING.TITLE}
      </text>
    </g>
  </svg>
</div>




    <div className="newtext">
     
      <h2>
      <p className='mt-2 mb-2 title-md text-center'>Do You know ?</p>

        <span className="words-wrapper">
          <b className="fact is-visible " >{currentFact}</b>
        </span>
      </h2>
    </div>
  </>
  )
}

export default LabSpinner