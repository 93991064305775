import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';

const SpendTimer = ({ initialMinutes }) => {
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    const savedTime = localStorage.getItem('timer');

    if (savedTime) {
      setStartTime(Number(savedTime));
    } else if (initialMinutes != null) {
      const [hoursPart, minutesPart] = initialMinutes.split('H:').map(part => parseInt(part));
      const hours = isNaN(hoursPart) ? 0 : hoursPart;
      const minutes = isNaN(minutesPart) ? 0 : minutesPart;
      
      const initialTimeInSeconds = (hours * 60 * 60) + (minutes * 60);
      const calculatedStartTime = Date.now() - initialTimeInSeconds * 1000;
      
      localStorage.setItem('timer', calculatedStartTime);
      setStartTime(calculatedStartTime);
    }
  }, [initialMinutes]);

  useEffect(() => {
    if (startTime !== null) {
      const interval = setInterval(() => {
        const now = Date.now();
        localStorage.setItem('timer', startTime); // Persist the original start time
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [startTime]);

  if (startTime === null) return null;

  return (
    <Countdown
      date={startTime + 1000 * 60 * 60 * 24 * 365} // far future date to ensure it counts up
      renderer={({ total }) => {
        const elapsedSeconds = Math.floor((Date.now() - startTime) / 1000);
        const hours = Math.floor(elapsedSeconds / 3600);
        const minutes = Math.floor((elapsedSeconds % 3600) / 60);
        const seconds = elapsedSeconds % 60;
        return (
          <span>
            {hours.toString().padStart(2, '0')}H:{minutes.toString().padStart(2, '0')}M:{seconds.toString().padStart(2, '0')}S
          </span>
        );
      }}
    />
  );
};

export default SpendTimer;
