import React, { useMemo, useState, useEffect, useContext } from 'react'

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';

import { Checkbox } from '@mui/material';
import DynamicTable from '../../Common/DynamicTable/DynamicTable';
import { DataGrid, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid';
import { EMAIL_ICON, EXPORT_ICN, ICN_COLLAPSED, ICN_COMING_BATCHES, ICN_COPY, ICN_DROPDOW, ICN_EXPAND_ACCORDIAN } from '../../Common/Icon';
import { LinearProgress } from '@mui/material';
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Link, useNavigate } from '@reach/router';
import CardHeader from '../../Common/CardHeader';
import { TabBtn } from '../../Common/Buttons/Buttons';
import { navigate } from '../../Common/Router';
import ScienceIcon from '@mui/icons-material/Science';
import AssessmentIcon from '@mui/icons-material/Assessment';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import TimerIcon from '@mui/icons-material/Timer';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import RestService from '../../../Services/api.service';
import AppContext from '../../../Store/AppContext';
import DropdownHeader from './DropdownHeader';
import { Button } from '../../Common/Buttons/Buttons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import LoadingSpinner from '../Training/LoadingSpinner';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MailIcon from '@mui/icons-material/Mail';
import { WithContext as ReactTags } from "react-tag-input";
import useToast from '../../../Store/ToastHook';
import SendIcon from '@mui/icons-material/Send';
import SkeletonLoader from '../../Common/SkeletonLoader/SkeletonLoader';
import SendEmail from '../../Common/AlertDialog/SendEmail';
import TrainingPercentageTrendChart from './TrainingPercentageTrendChart';
import LoginHistoryChart from './LoginHistoryChart';
import { PieChart } from './NewCharts/PieChart';

//error component
const ErrorComponent = () => {
  return (
    <div class="row justify-content-center">
      <div class="col-md-12 col-sm-12">
        <div class="card shadow-lg border-0 rounded-lg mt-5 mx-auto" style={{ width: "30rem" }}>
          <h3 class="card-header display-1 text-muted text-center">
            404
          </h3>

          <span class="card-subtitle mb-2 text-muted text-center">
            Something went wrong
          </span>

          <div class="card-body mx-auto">
            <button class="btn btn-sm btn-info text-white" onClick={() => navigate("/report", { state: { title: "Reports" } })}>
              Back To Reports
            </button>

          </div>
        </div>
      </div>
    </div>
  )
}

const Modal = ({ handleClose, show, children }) => {
  const showHideClassName = show ? "modal d-block" : "modal d-none";

  return (

    <div className={showHideClassName}  >

      <div className="modal-container modal-xl">
        <div style={{ float: "right" }} className='circle-md'> <div className="modal-close" onClick={handleClose}>
          X
        </div></div>
        {children}

      </div>
    </div>
  );
};

const TrainingSpecificReport = ({ location }) => {
  const [selectionModel, setSelectionModel] = useState([]);
  const [trainingSpecificCard, setTrainingSpecificCard] = useState({});
  const [trainingSpecific, setTrainingSpecific] = useState([]);
  const [trainingAssessment, setTrainingAssessment] = useState({});
  const [allLearnerHeader, setAllLearnerHeader] = useState({});
  const [data, setData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [sumOfPieData, setSumOfPieData] = useState(0);
  const { setCourse, setBatches, ROLE, course, spinner, user, batches } = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(true);
  const trainingSid = location.state.trainingSid;

  const [selectedRows, setSelectedRows] = React.useState([]);
  const [learnerName, setLearnerName] = useState('');
  const progress = location.state.progress;

  localStorage.setItem("progress", progress);
  const navigate = useNavigate();
  const goBack = () => {
    localStorage.removeItem("progress");
  }
  const [showemailmodal, setShowEmailmodal] = useState(false)
  const [show, setShow] = useState(false);
  const [tags, setTags] = useState([]);
  const Toast = useToast();
  const [loader, setLoader] = useState(true);
  const [projectReport, setProjectReport] = useState({});
  const KeyCodes = {
    comma: 188,
    enter: 13
  };

  const newData = [22, 27, 25, 24, 27, 26];

  const loginData = [500, 502, 501, 506, 510, 511];

  const openProgress = () => {
    // Replace 'your_route_here' with the actual route you want to navigate to
    navigate(`/report/learner`, { state: { progress: selectedRows[0], learnerName: learnerName, subTitle: `${progress}`, subPath: `${progress}` } });
  };

  //send mail
  const handleDelete = i => {
    setTags(tags.filter((tag, index) => index !== i));
  };

  const handleAddition = tag => {
    setTags([...tags, tag]);
  };

  //export pdf
  const handleGeneratePdf = async () => {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(document.querySelector("#capture"));
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    spinner.hide();
    pdf.save(`report.pdf`);

    //   document.body.appendChild(canvas);  // if you want see your screenshot in body.
    //   const imgData = canvas.toDataURL('image/png');
    //   const pdf = new jsPDF({
    //     format: 'a4',
    //     unit: 'px',
    //   });
    //   pdf.addImage(imgData, 'PNG', 0, 0);
    //   pdf.save("download.pdf");
    // });
    // const doc = new jsPDF({
    //   format: 'a4',
    //   unit: 'px',
    // });

    // Adding the fonts.
    // doc.setFont('Inter-Regular', 'normal');

    // doc.html(reportTemplateRef.current, {
    //   async callback(doc) {
    //     await doc.save('document');
    //   },
    // });
    // doc.html(document.body, {
    //   callback: function () {
    //     doc.save('page');
    //   },
    // });
  };
  const handleSelectionModelChange = (selectionModel) => {
    // setSelectionModel(newSelectionModel);

    // Check if only one row is selected
    const selectedRowIds = selectionModel.map((index) => trainingDetails[index]?.id);
    // if (newSelectionModel.length === 1) {
    //   const selectedIndex = newSelectionModel[0];
    //   const selectedRowId = trainingDetails[selectedIndex]?.id;

    //   setSelectedRowId(selectedRowId);
    // } else {
    //   setSelectedRowId(null);
    // }
  };

  const handleButtonClick = (rowId) => {
    // Handle the button click for the selected row
  };
  function CustomToolbar() {
    return (
      <>
        <GridToolbarContainer>
          <GridToolbarColumnsButton />
          <GridToolbarFilterButton />

        </GridToolbarContainer>
        {/* {selectedRows && (
          <button style={{ position: 'absolute', top: '8px', right: '8px' }} onClick={() => handleButtonClick(selectedRows)}>
            Custom Button
          </button>
        )} */}

      </>

    );

  }




  const [configuration, setConfiguration] = useState({

    columns: {
      // "slno":{
      //     "title": "#",
      //     "sortDirection": null,
      //     "sortEnabled": false,
      //     isSearchEnabled: false,

      // },

      "learnerName": {
        "title": "Learner Name",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,



      },

      "avgAssessmentScore": {
        "title": "Avg Assessment Score",
        "sortDirection": null,
        "sortEnabled": false,
        isSearchEnabled: true,

        render: (data) =>
          <>
            {data.avgAssessmentScore === null ? "---" : data.avgAssessmentScore}
          </>





      },
      "avgCapstoneScore": {
        "title": "Avg Capstone Score",
        "sortDirection": null,
        "sortEnabled": false,
        isSearchEnabled: false,

        render: (data) =>
          <>
            {data.avgCapstoneScore === null ? "---" : data.avgCapstoneScore}
          </>

      },
      "proctoringScore": {
        "title": "Proctoring Score",
        "sortDirection": null,
        "sortEnabled": false,
        isSearchEnabled: false,
        render: (data) =>
          <>
            {data.proctoringScore === null ? "---" : data.proctoringScore}
          </>



      },
      "numberOfHoursCompleted": {
        "title": "Completed Lab Hours",
        "sortDirection": null,
        "sortEnabled": false,
        isSearchEnabled: false,
        render: (data) => <>

          {Math.round(data.numberOfHoursCompleted / 60)}
        </>


      },
      "learnerCompletionPercentage": {
        // "title": `${trainingName} Progress`,
        title: "Progress",
        "sortDirection": null,
        "sortEnabled": false,
        isSearchEnabled: false,
        render: (data) => <>


          {`${Math.round(data.learnerCompletionPercentage)}%`}  <LinearProgress color={Math.round(data.learnerCompletionPercentage) > 10 ? "success" : "secondary"} variant="determinate" value={Math.round(data.learnerCompletionPercentage)} />

        </>


      },


    },
    headerTextColor: '#454E50', // user can change table header text color
    sortBy: null,  // by default sort table by name key
    sortDirection: true, // sort direction by default true
    updateSortBy: (sortKey) => {
      configuration.sortBy = sortKey;
      Object.keys(configuration.columns).map(key => configuration.columns[key].sortDirection = (key === sortKey) ? !configuration.columns[key].sortDirection : true);
      configuration.sortDirection = configuration.columns[sortKey].sortDirection;
      setConfiguration({ ...configuration });

    },

    // user can pass their own custom className name to add/remove some css style on action button
    actionVariant: "", // user can pass action button variant like primary, dark, light,
    actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
    // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
    // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
    searchQuery: "",
    tableCustomClass: "reporttable sort-enabled", // table custom class

    clearSelection: true,
    showCheckbox: true,

  });
  // const columns = [
  //   // { field: "id", headerName: "Id" },
  //   {
  //     field: "learnerName",
  //     headerName: "Name",
  //     flex: 1,
  //     cellClassName: "name-column--cell",
  //     fixed:"left"


  //   },
  //   {
  //     field: "avgAssessmentScore", headerName: "Avg Assessment Score", align: "center", headerAlign: "center", type: "number"
  //   },
  //   {
  //     field: "avgCapstoneScore", headerName: "Avg Capstone Score", align: "center", headerAlign: "center", flex: 1,
  //   },
  //   { field: "proctoringScore", headerName: "Proctoring Score", align: "center", headerAlign: "center" },
  //   { field: "numberOfHoursCompleted", headerName: "Hours Completed", align: "center", headerAlign: "center" },
  //   { field: "learnerCompletionPercentage", headerName: "Progress", align: "center", headerAlign: "center" },
  //   {
  //     field: "avgAssessmentScore1", headerName: "Avg Assessment Score1", align: "center", headerAlign: "center", type: "number"
  //   },
  //   {
  //     field: "avgCapstoneScore11", headerName: "Avg Capstone Score11", align: "center", headerAlign: "center", flex: 1,
  //   },
  //   { field: "proctoringScore111", headerName: "Proctoring Score111", align: "center", headerAlign: "center" },
  //   { field: "numberOfHoursCompleted1111", headerName: "Hours Completed1111", align: "center", headerAlign: "center" },
  //   { field: "learnerCompletionPercentage11111", headerName: "Progress11111", align: "center", headerAlign: "center" },





  // ];

  const trainingDetails = [
    {
      "id": "1",
      "learnerName": "Hrithik",
      "avgAssessmentScore": 0,
      "avgCapstoneScore": 0,
      "proctoringScore": null,
      "numberOfHoursCompleted": 0,
      "learnerCompletionPercentage": 0
    },
    {
      "id": "2",
      "learnerName": "Apurv Learner",
      "avgAssessmentScore": 0,
      "avgCapstoneScore": 0,
      "proctoringScore": null,
      "numberOfHoursCompleted": 0,
      "learnerCompletionPercentage": 0
    },
    {
      "id": "3",
      "learnerName": "Apurv Learner Test",
      "avgAssessmentScore": 0,
      "avgCapstoneScore": 0,
      "proctoringScore": null,
      "numberOfHoursCompleted": 0,
      "learnerCompletionPercentage": 0
    },
    {
      "id": "4",
      "learnerName": "Kanagaraj K",
      "avgAssessmentScore": 0,
      "avgCapstoneScore": 0,
      "proctoringScore": null,
      "numberOfHoursCompleted": 0,
      "learnerCompletionPercentage": 0
    },
    {
      "id": "5",
      "learnerName": "Keerthipati",
      "avgAssessmentScore": 0,
      "avgCapstoneScore": 0,
      "proctoringScore": null,
      "numberOfHoursCompleted": 0,
      "learnerCompletionPercentage": 0
    },
    {
      "id": "6",
      "learnerName": "Bejawada Manohar Babu",
      "avgAssessmentScore": 0,
      "avgCapstoneScore": 0,
      "proctoringScore": null,
      "numberOfHoursCompleted": 0,
      "learnerCompletionPercentage": 0
    },
    {
      "id": "7",
      "learnerName": "Manoj",
      "avgAssessmentScore": 0,
      "avgCapstoneScore": 0,
      "proctoringScore": null,
      "numberOfHoursCompleted": 0,
      "learnerCompletionPercentage": 0
    },
    {
      "id": "8",
      "learnerName": "N P Sumanth",
      "avgAssessmentScore": 0,
      "avgCapstoneScore": 0,
      "proctoringScore": null,
      "numberOfHoursCompleted": 0,
      "learnerCompletionPercentage": 0
    },
    {
      "id": "9",
      "learnerName": "Dommu NaveenKumar",
      "avgAssessmentScore": 0,
      "avgCapstoneScore": 0,
      "proctoringScore": null,
      "numberOfHoursCompleted": 0,
      "learnerCompletionPercentage": 0
    },
    {
      "id": "10",
      "learnerName": "Muttasri Surya Nitesh",
      "avgAssessmentScore": 0,
      "avgCapstoneScore": 0,
      "proctoringScore": null,
      "numberOfHoursCompleted": 0,
      "learnerCompletionPercentage": 0
    }
  ]

  //get specific training report details
  const reprtingTrainingDetails = () => {
    try {

      RestService.reprtingTrainingDetails(trainingSid).then(
        response => {
          if (response.status === 200) {
            setTrainingSpecificCard(response.data.cardDetails);
            setTrainingSpecific(response.data.trainingDetails);
            setTimeout(() => {
              setLoader(false);
            }, 2000)
          }
          else {
            setTrainingSpecificCard({});
            setTrainingSpecific([]);
            setIsLoading(false);
          }

        },
        err => {
          // spinner.hide();
        }
      ).finally(() => {
        // spinner.hide();
      });
    } catch (err) {
      console.error("error occur on reprtingTrainingDetails()", err)
    }
  }

  //get no of assessment from training
  const getTrainingAssociatedAssessments = () => {
    try {

      // spinner.show();
      RestService.getTrainingAssociatedAssessments(trainingSid).then(
        response => {
          if (response.status === 200) {
            setTrainingAssessment(response.data);
          }
          else {
            setTrainingAssessment({});
          }
        },
        err => {
          // spinner.hide();
        }
      ).finally(() => {
        // spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getTrainingAssociatedAssessments()", err)
    }
  }

  //get group project reports
  const getProjectReportDetails = () => {
    try {

      // spinner.show();
      RestService.getProjectReportDetails(trainingSid).then(
        response => {
          if (response.status === 200) {
            setProjectReport(response.data);
          }
          else {
            setProjectReport({});
          }
        },
        err => {
          // spinner.hide();
        }
      ).finally(() => {
        // spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getProjectReportDetails()", err)
    }
  }

  //send mail
  const sendSupervisorReportMail = () => {
    try {
      spinner.show();
      let tagsCsv = tags.map((item) => item.text);
      const payload = {
        "recipients": tagsCsv,
        "reportType": "SUPERVISOR_TRAINING",
        "trainingSid": trainingSid
      }
      RestService.sendSupervisorReportMail(payload).then(
        response => {
          Toast.success({ message: `Mail Sent Successfully`, time: 2000 });
          setTags([])
        },
        err => {
          spinner.hide();
          Toast.error({ message: `Try Again`, time: 2000 });
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on sendSupervisorReportMail()", err)
    }
  }


  const getAllLearnerScreenDetails = () => {
    try {
      // spinner.show();

      RestService.getAllLearnerScreenDetails().then(
        response => {
          if (response.status === 200) {
            const updatedData = response.data.learnerDetails.map(item => ({
              ...item,
              id: item.learnerSid,
            }));
            const pieData = Object.values(response.data.headerDetails);
            const sumOfPieData = pieData.reduce((acc, curr) => acc + curr, 0);
            setAllLearnerHeader(response.data.headerDetails);
            setData(updatedData);
            setPieData(pieData);
            setSumOfPieData(sumOfPieData);
            setTimeout(() => {
              setLoader(false);
            }, 2000);
          }
        },
        err => {
          spinner.hide();
          Toast.error({ message: `Try Again`, time: 2000 });
        }
      )
    } catch (err) {
      console.error("error occur on getAllLearnerScreenDetails()", err)
    }
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'select',
        header: 'Select',
        Cell: ({ row }) => (
          <Checkbox
            checked={selectedRows.includes(row.original.id)}
            onChange={() => {
              const newSelectedRows = selectedRows.includes(row.original.id)
                ? selectedRows.filter((id) => id !== row.original.id)
                : [...selectedRows, row.original.id];

              setSelectedRows(newSelectedRows);
              setLearnerName(row.original.learnerName);
             
            }}
          />
        ),
        size: 50,
      },
      {
        accessorKey: 'learnerName',
        header: 'Name',
      },
      {
        accessorKey: 'learnerEmail',
        header: 'Email',
        size: 300,
      },
      {
        accessorKey: 'createdOn',
        header: 'CreatedOn',
        size: 300,
      },
      {
        accessorKey: 'userType',
        header: 'Type',
      },
      {
        accessorKey: 'passed',
        header: 'Passed',
      },
      {
        accessorKey: 'failed',
        header: 'Failed',
      },
      {
        accessorKey: 'inProgress',
        header: 'Progress',
      },
      {
        accessorKey: 'completed',
        header: 'Completed',
      },
      {
        accessorKey: 'overdue',
        header: 'Overdue',
      },
      {
        accessorKey: 'notStarted',
        header: 'Not Started',
      },
      {
        accessorKey: 'enrolledBy',
        header: 'Enrolled By',
      },
      {
        accessorKey: 'lastLoggedIn',
        header: 'Last LoggedIn',
        size: 300
      }
    ],
    [selectedRows],
  );
  // const data = [
  //   {
  //     id: '1',
  //     firstName: 'Dylan',
  //     middleName: 'Sprouse',
  //     lastName: 'Murray',
  //     address: '261 Erdman Ford',
  //     city: 'East Daphne',
  //     state: 'Kentucky',
  //     country: 'United States',
  //     city1: 'East Daphne',
  //     state1: 'Kentucky',
  //     country1: 'United States',

  //   },
  //   {
  //     id: '2',
  //     firstName: 'Raquel',
  //     middleName: 'Hakeem',
  //     lastName: 'Kohler',
  //     address: '769 Dominic Grove',
  //     city: 'Columbus',
  //     state: 'Ohio',
  //     country: 'United States',
  //   },
  //   {
  //     id: '3',
  //     firstName: 'Ervin',
  //     middleName: 'Kris',
  //     lastName: 'Reinger',
  //     address: '566 Brakus Inlet',
  //     city: 'South Linda',
  //     state: 'West Virginia',
  //     country: 'United States',
  //   },
  //   {
  //     id: '4',
  //     firstName: 'Brittany',
  //     middleName: 'Kathryn',
  //     lastName: 'McCullough',
  //     address: '722 Emie Stream',
  //     city: 'Lincoln',
  //     state: 'Nebraska',
  //     country: 'United States',
  //   },
  //   {
  //     id: '5',
  //     firstName: 'Branson',
  //     middleName: 'John',
  //     lastName: 'Frami',
  //     address: '32188 Larkin Turnpike',
  //     city: 'Charleston',
  //     state: 'South Carolina',
  //     country: 'United States',
  //   },
  //   {
  //     id: '6',
  //     firstName: 'Brandon',
  //     middleName: 'Joe',
  //     lastName: 'Kutch',
  //     address: '5660 Kuhn Village',
  //     city: 'Vancouver',
  //     state: 'British Columbia',
  //     country: 'Canada',
  //   },
  //   {
  //     id: '7',
  //     firstName: 'Brandon',
  //     middleName: 'Joe',
  //     lastName: 'Kutch',
  //     address: '5660 Kuhn Village',
  //     city: 'Vancouver',
  //     state: 'British Columbia',
  //     country: 'Canada',
  //   },
  //   {
  //     id: '8',
  //     firstName: 'Brandon',
  //     middleName: 'Joe',
  //     lastName: 'Kutch',
  //     address: '5660 Kuhn Village',
  //     city: 'Vancouver',
  //     state: 'British Columbia',
  //     country: 'Canada',
  //   },
  //   {
  //     id: '9',
  //     firstName: 'Brandon',
  //     middleName: 'Joe',
  //     lastName: 'Kutch',
  //     address: '5660 Kuhn Village',
  //     city: 'Vancouver',
  //     state: 'British Columbia',
  //     country: 'Canada',
  //   },
  //   {
  //     id: '10',
  //     firstName: 'Brandon',
  //     middleName: 'Joe',
  //     lastName: 'Kutch',
  //     address: '5660 Kuhn Village',
  //     city: 'Vancouver',
  //     state: 'British Columbia',
  //     country: 'Canada',
  //   },
  // ];

  // const data = [
  //   {
  //     "id": "E36531C3849F4F07B95701FE8529FF97FD824F64D26C450C973563D35DD7FF10",
  //     "learnerName": "Demo Learner-1",
  //     "learnerEmail": "dl@gmail.com",
  //     "createdOn": "9 Oct, 2023 11:27 AM",
  //     "userType": "BUSINESS",
  //     "lastLoggedIn": "29 Dec, 2023 10:31 AM",
  //     "passed": 1,
  //     "failed": 0,
  //     "inProgress": 0,
  //     "completed": 0,
  //     "overdue": 1,
  //     "notStarted": 0,
  //     "enrolledBy": "Sachin Supervisor"
  //   },
  //   {
  //     "id": "F3E6578EB3AB477C8A6E3A53ECBC37418C24809A5A4F41C6A12291E6468A4B5D",
  //     "learnerName": "Demo Learner-2",
  //     "learnerEmail": "dl2@gmail.com",
  //     "createdOn": "9 Oct, 2023 11:27 AM",
  //     "userType": "BUSINESS",
  //     "lastLoggedIn": "27 Dec, 2023 12:22 AM",
  //     "passed": 0,
  //     "failed": 1,
  //     "inProgress": 0,
  //     "completed": 0,
  //     "overdue": 1,
  //     "notStarted": 0,
  //     "enrolledBy": "Sachin Supervisor"
  //   },
  //   {
  //     "id": "724479FB560F4E50BCF85917567E8A34B3B6CF451CE341F6ADE7F34C17BD7733",
  //     "learnerName": "Demo Learner-3",
  //     "learnerEmail": "dl3@gmail.com",
  //     "createdOn": "9 Oct, 2023 11:28 AM",
  //     "userType": "BUSINESS",
  //     "lastLoggedIn": "20 Dec, 2023 12:49 PM",
  //     "passed": 0,
  //     "failed": 1,
  //     "inProgress": 0,
  //     "completed": 0,
  //     "overdue": 1,
  //     "notStarted": 0,
  //     "enrolledBy": "Sachin Supervisor"
  //   },
  //   {
  //     "id": "1290F510B00D47D698E1D5633CD7BE82FBF23ADE7D1D46BEAFCC7B5F8F3963CE",
  //     "learnerName": "Demo Learner-4",
  //     "learnerEmail": "dl4@gmail.com",
  //     "createdOn": "9 Oct, 2023 11:29 AM",
  //     "userType": "BUSINESS",
  //     "lastLoggedIn": "11 Dec, 2023 9:21 PM",
  //     "passed": 0,
  //     "failed": 1,
  //     "inProgress": 0,
  //     "completed": 0,
  //     "overdue": 1,
  //     "notStarted": 0,
  //     "enrolledBy": "Sachin Supervisor"
  //   },
  //   {
  //     "id": "1ED099194F614DDB8F0D10DDDE7F12EC1C6F1AF338834F978C9E78E944CEAC6D",
  //     "learnerName": "Demo Learner-5",
  //     "learnerEmail": "dl5@gmail.com",
  //     "createdOn": "9 Oct, 2023 11:30 AM",
  //     "userType": "BUSINESS",
  //     "lastLoggedIn": "28 Dec, 2023 11:39 AM",
  //     "passed": 1,
  //     "failed": 1,
  //     "inProgress": 0,
  //     "completed": 0,
  //     "overdue": 2,
  //     "notStarted": 0,
  //     "enrolledBy": "Sachin Supervisor"
  //   },
  //   {
  //     "id": "84EAE84A579C49F9B94E056378CACAA37D499C07038F4BE98BB99A76DD36ACE5",
  //     "learnerName": "Demo Learner-6",
  //     "learnerEmail": "dl6@gmail.com",
  //     "createdOn": "9 Oct, 2023 12:52 PM",
  //     "userType": "BUSINESS",
  //     "lastLoggedIn": "20 Dec, 2023 3:46 PM",
  //     "passed": 0,
  //     "failed": 1,
  //     "inProgress": 0,
  //     "completed": 0,
  //     "overdue": 1,
  //     "notStarted": 0,
  //     "enrolledBy": "Sachin Supervisor"
  //   },
  //   {
  //     "id": "1CDBADAFB01B4B318C94379152BD28B811586CB0D24043F397FB58AACCA76AC2",
  //     "learnerName": "Demo Learner-11",
  //     "learnerEmail": "dl11@gmail.com",
  //     "createdOn": "20 Nov, 2023 10:36 AM",
  //     "userType": "BUSINESS",
  //     "lastLoggedIn": "28 Dec, 2023 10:53 AM",
  //     "passed": 1,
  //     "failed": 0,
  //     "inProgress": 2,
  //     "completed": 0,
  //     "overdue": 1,
  //     "notStarted": 0,
  //     "enrolledBy": "Sachin Supervisor"
  //   },
  //   {
  //     "id": "1D308AE64793410F8D6685601C165F87E4EC65169A6D4475962B268C1DD40885",
  //     "learnerName": "Demo Learner-13",
  //     "learnerEmail": "dl13@gmail.com",
  //     "createdOn": "15 Dec, 2023 2:28 AM",
  //     "userType": "BUSINESS",
  //     "lastLoggedIn": "29 Dec, 2023 10:28 AM",
  //     "passed": 0,
  //     "failed": 0,
  //     "inProgress": 1,
  //     "completed": 0,
  //     "overdue": 0,
  //     "notStarted": 0,
  //     "enrolledBy": "Sachin Supervisor"
  //   }
  // ]
  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnPinning: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnFilters: false,
    layoutMode: 'grid-no-grow',
    initialState: {
      columnPinning: {
        left: ['select', 'learnerName'],
        //  right: ['city'] 
      },

    },
  });


  useEffect(() => {
    // reprtingTrainingDetails();
    // getTrainingAssociatedAssessments();
    // getProjectReportDetails();
    getAllLearnerScreenDetails();
  }, []);

  return (
    <>
      {
        !isLoading ?
          <ErrorComponent />
          // <LoadingSpinner />
          // :
          // trainingSpecific.length === 0 || trainingSpecificCard === null ?
          //   <ErrorComponent />
          :
          <>
            <div id="capture">
              <div className="p-3">

                <nav aria-label="breadcrumb " className='bbottom pb-2 ' >
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><Link to="/report" onClick={() => goBack()} state={{ title: "Report" }}>Reports</Link></li>
                    <li class="breadcrumb-item active" aria-current="page">{progress}</li>

                  </ol>
                </nav>
                <div className='specificreportheader my-4'>Learner Progress</div>
                {/* <div className='specificreportheader my-4'>{progress}</div> */}

                <div className='row ' style={{ justifyContent: "space-between" }}>

                  {/* <div className='col-md-3 '>

                    <div className='reportcard'>
                      <TrainingPercentageTrendChart data={newData} />
                    </div>

                  </div>
                  <div className='col-md-3'>

                    <div className='reportcard'>
                      <LoginHistoryChart data={loginData} />
                    </div>

                  </div> */}
                  <div className='col-md-6 '>
                    {
                      loader ?
                        <SkeletonLoader skeletonCount={2.7} />
                        :
                        <div className='reportcard'>
                          <PieChart sumOfPieData={sumOfPieData} pieData={pieData} />
                        </div>
                    }


                  </div>
                  <div className='col-md-6'>
                    {
                      loader ?
                        <SkeletonLoader skeletonCount={2.5} />
                        :
                        <div className='reportcard d-flex h-100 ' style={{ justifyContent: "space-between" }}>

                          <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                            <div className='reportcardheader'>
                              Passed

                            </div>
                            <div className='text-center reportcardtitle'>
                              {
                                allLearnerHeader?.passed
                              }
                            </div>
                          </div>


                          <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                            <div className='reportcardheader'>
                              Failed

                            </div>
                            <div className='text-center reportcardtitle'>
                              {
                                allLearnerHeader?.failed
                              }
                            </div>
                          </div>


                          <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                            <div className='reportcardheader'>
                              In Progress

                            </div>
                            <div className='text-center reportcardtitle'>
                              {
                                allLearnerHeader?.inProgress
                              }
                            </div>
                          </div>


                          <div className='d-flex m-auto px-1' style={{ flexDirection: "column" }}>
                            <div className='reportcardheader'>
                              Completed

                            </div>
                            <div className='text-center reportcardtitle'>
                              {
                                allLearnerHeader?.completed
                              }
                            </div>
                          </div>


                          {/* <div>
                        Overdue:
                        {
                          allLearnerHeader?.overdue
                        }
                      </div> */}

                          <div className='d-flex m-auto px-1 mx-1' style={{ flexDirection: "column" }}>
                            <div className='reportcardheader'>
                              Overdue

                            </div>
                            <div className='text-center reportcardtitle'>
                              {
                                allLearnerHeader?.overdue
                              }
                            </div>
                          </div>


                        </div>
                    }
                  </div>

                </div>







                <div className='my-4'>

                  <div className='d-flex' style={{ justifyContent: "space-between" }}>
                    <div className='mb-2'>
                      {
                        selectedRows.length === 1 &&

                        <>
                          <button className='emailbtn' onClick={openProgress}>Learner Results</button>
                          <button className='emailbtn mx-2'>Learner's Enrollment History</button>

                        </>
                      }
                    </div>
                    <div className='mb-2' >

                      <button className='emailbtn' onClick={() => setShowEmailmodal(true)}><span className='mr-2'>{EMAIL_ICON}</span>Email</button>
                      <button className='exportbtn  ml-2' onClick={() => handleGeneratePdf()}><span className='mr-2'>{EXPORT_ICN}</span>Export <span>{ICN_DROPDOW}</span> </button>

                    </div>
                  </div>

                  {/* 
             <DynamicTable  {...{ configuration, sourceData: trainingDetails, trainingAssessment, trainingSid }} />
              */}

                  {/* <DataGrid
                    rows={trainingDetails}
                    columns={columns}
                    checkboxSelection
                    // selectionModel={selectionModel}
                    onRowSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      const selectedRows = trainingDetails.filter((row) =>
                        selectedIDs.has(row.id),
                      );

                      setSelectedRows(selectedRows);
                    }}
                    pageSizeOptions={[5]}
                    localeText={{
                      toolbarColumns: "Display Columns",
                      toolbarFilters: "More Filters",

                    }}
                    slots={{
                      toolbar: CustomToolbar,
                    }}
                    stickyColumns={['learnerName']}
                  /> */}

                  {

                    loader ?
                      <SkeletonLoader skeletonCount={10} />
                      :
                      data.length !== 0 &&
                      <MaterialReactTable table={table} />
                  }


                </div>


              </div>
            </div>
            {
              showemailmodal &&

              <SendEmail open={showemailmodal} setOpen={setShowEmailmodal} title='Send by Email' />

            }
          </>















      }

    </>

  )
}

export default TrainingSpecificReport