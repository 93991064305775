import React, { useState, useEffect, useContext, useRef, useMemo } from 'react'
import { ICN_ASSESSMT, ICN_EDIT, ICN_EXPAND_NEW, ICN_BACK, ICN_MORE_DETAILS, ICN_NOTE_EDIT, ICN_OPEN_SANDBOX, ICN_START_ASSESS, ICN_STRAT_SESSION, ICN_COMING_BATCHES, ICN_COPY, REPORT_ICON_BATCH, REPORT_ICON_GO_SOMEWHERE, REPORT_ICON_GROUP, REPORT_ICON_LEARNER, ICN_STD_MATERIAL, ICN_VDO, ICN_LABASS, ICN_CODINGASS, EMAIL_ICON } from '../../Common/Icon';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Checkbox } from '@mui/material';
import { LinearProgress } from '@mui/material';
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Link, useNavigate } from '@reach/router';
import TimerIcon from '@mui/icons-material/Timer';
import ScienceIcon from '@mui/icons-material/Science';
import RestService from '../../../Services/api.service';
import AppContext from '../../../Store/AppContext';
import InfoIcon from '@mui/icons-material/Info';
import LoadingSpinner from '../Training/LoadingSpinner';
import { Button } from '../../Common/Buttons/Buttons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MailIcon from '@mui/icons-material/Mail';
import { WithContext as ReactTags } from "react-tag-input";
import useToast from '../../../Store/ToastHook';
import SendIcon from '@mui/icons-material/Send';
import SkeletonLoader from '../../Common/SkeletonLoader/SkeletonLoader';
import TotalAssessmentsChart from './TotalAssessmentsChart';
import DistributionBarChart from './DistributionBarChart';

//error component
const ErrorComponent = () => {
    return (
        <div class="row justify-content-center">
            <div class="col-md-12 col-sm-12">
                <div class="card shadow-lg border-0 rounded-lg mt-5 mx-auto" style={{ width: "30rem" }}>
                    <h3 class="card-header display-1 text-muted text-center">
                        404
                    </h3>

                    <span class="card-subtitle mb-2 text-muted text-center">
                        Something went wrong
                    </span>

                    {/* <div class="card-body mx-auto">
                      <button class="btn btn-sm btn-info text-white" onClick={() => navigate("/training", { state: { title: "Training" } })}>
                          Back To Training
                      </button>
                    
                  </div> */}
                </div>
            </div>
        </div>
    )
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal d-block" : "modal d-none";

    return (

        <div className={showHideClassName}  >

            <div className="modal-container modal-xl" >
                <div style={{ float: "right" }} className='circle-md'> <div className="modal-close" onClick={handleClose}>
                    X
                </div></div>
                {children}

            </div>
        </div>
    );
};

const AssessmentSpecificReport = (props) => {

    //learner
    const [data, setData] = useState([]);
    const [assessmentHeaderDetails, setAssessmentHeaderDetails] = useState({});
    const [assessmentDetails, setAssessmentDetails] = useState({});
    const [specificData, setSpecificData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const { setCourse, setBatches, ROLE, course, spinner, user, batches } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [tags, setTags] = useState([]);
    const [loader, setLoader] = useState(true);
    const Toast = useToast();
    const [avgData, setAvgData] = useState([]);
    const graphData = [50, 120, 70];
    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('learner'); // Set the default active tab
    const [counter, setCounter] = useState(20); const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    const openSpecificAssessment = () => {
        // Replace 'your_route_here' with the actual route you want to navigate to
        navigate(`/report/learner-details/${props.location.state.progress.trainingName}/${assessmentDetails.assessmentName}`, { state: { progress: assessmentDetails, trainingSid: assessmentHeaderDetails.trainingSid, subTitle: `${assessmentDetails.assessmentName}`, subPath: `${assessmentDetails.assessmentName}` } });
    };


    //send mail
    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    const goBack = () => {
        localStorage.removeItem("progress");
    }

    const previousroute = () => {
        // Navigate to the previous page
        navigate(-1);
    };

    //assessment table details
    const columns = useMemo(
        () => [
            // {
            //     accessorKey: 'id',
            //     enableColumnPinning: false,
            //     header: 'ID',
            //     size: 50,
            // },
            {
                accessorKey: 'select',
                header: 'Select',
                Cell: ({ row }) => (
                    <Checkbox
                        checked={selectedRows.includes(row.original.id)}
                        onChange={() => {
                       
                            const newSelectedRows = selectedRows.includes(row.original.id)
                                ? selectedRows.filter((id) => id !== row.original.id)
                                : [...selectedRows, row.original.id];

                            setSelectedRows(newSelectedRows);
                            setAssessmentDetails(row.original);
                          
                        }}
                    />
                ),
                size: 50,
            },
            {
                accessorKey: 'assessmentName',
                header: 'Assessment Name',
                size: 300
            },
            {
                accessorKey: 'assessmentType',
                header: 'Assessment Type',
                size: 300
            },
            {
                accessorKey: 'attempted',
                header: 'Attempted',
                size: 300
            },
            {
                accessorKey: 'avgPercentage',
                header: 'Avg Percentage',
                Cell: ({ renderedCellValue, row }) => <div

                >{`${renderedCellValue.toFixed(2)}%`}

                </div>,
                size: 300
            },
            {
                accessorKey: 'topper',
                header: 'Topper',
                size: 300
            },
            {
                accessorKey: 'topperPercentage',
                header: 'Topper Percentage',
                Cell: ({ renderedCellValue, row }) => <div

                >{`${renderedCellValue.toFixed(2)}%`}

                </div>,
                size: 300
            }

        ],
        [selectedRows],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnPinning: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        layoutMode: 'grid-no-grow',
        initialState: {
            columnPinning: {
                left: ['select', 'assessmentName'],
                //  right: ['city'] 
            },

        },
    });


    //export as pdf
    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = async () => {
        const pdf = new jsPDF("portrait", "pt", "a4");
        const data = await html2canvas(document.querySelector("#capture"));
        const img = data.toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        spinner.hide();
        pdf.save("overall_training_report.pdf");

        // html2canvas(document.querySelector("#capture")).then(canvas => {
        //   document.body.appendChild(canvas);  // if you want see your screenshot in body.
        //   const imgData = canvas.toDataURL('image/png');
        //   const pdf = new jsPDF({
        //     format: 'a4',
        //     unit: 'px',
        //   });
        //   pdf.addImage(imgData, 'PNG', 0, 0);
        //   pdf.save("download.pdf");
        // });
        // const doc = new jsPDF({
        //   format: 'a4',
        //   unit: 'px',
        // });

        // Adding the fonts.
        // doc.setFont('Inter-Regular', 'normal');

        // doc.html(reportTemplateRef.current, {
        //   async callback(doc) {
        //     await doc.save('document');
        //   },
        // });
        // doc.html(document.body, {
        //   callback: function () {
        //     doc.save('page');
        //   },
        // });
    };


    //send mail
    const sendSupervisorReportMail = () => {
        try {
            spinner.show();
            let tagsCsv = tags.map((item) => item.text);
            const payload = {
                "recipients": tagsCsv,
                "reportType": "SUPERVISOR_MAIN"
            }
            RestService.sendSupervisorReportMail(payload).then(
                response => {
                    Toast.success({ message: `Mail Sent Successfully`, time: 2000 });
                    setTags([])
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: `Try Again`, time: 2000 });
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on sendSupervisorReportMail()", err)
        }
    }

    //all assessment in a training details
    const getAssessmentAssessmentScreenDetails = () => {
        try {
            // spinner.show();
            const trainingSid = props.location.state.progress.trainingSid;
            RestService.getAssessmentAssessmentScreenDetails(trainingSid).then(
                response => {
                    if (response.status === 200) {
                        const updatedData = response.data.assessmentDetails.map((item, index) => ({
                            ...item,
                            id: index + 1,
                        }));
                        setAssessmentHeaderDetails(response.data.headerDetails);
                        const avgMcqAssessmentPercentage =  response.data.headerDetails.avgMcqAssessmentPercentage.toFixed(2);
                        const avgLabAssessmentPercentage =  response.data.headerDetails.avgLabAssessmentPercentage.toFixed(2);
                        const avgCodingAssessmentPercentage =  response.data.headerDetails.avgCodingAssessmentPercentage.toFixed(2);
                        setAvgData([avgMcqAssessmentPercentage, avgLabAssessmentPercentage, avgCodingAssessmentPercentage]);
                        setData(updatedData);
                        setTimeout(() => {
                            setLoader(false);
                        }, 2000);
              
                    }
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: `Try Again`, time: 2000 });
                }
            )
        } catch (err) {
            console.error("error occur on getAssessmentAssessmentScreenDetails()", err)
        }
    }



    useEffect(() => {
        getAssessmentAssessmentScreenDetails();
    }, []);


    return (
        <div>
            {
                !isLoading ?

                    <ErrorComponent />
                    :
                    <>
                        <div id="capture">
                            <div className="p-3">

                                <nav aria-label="breadcrumb " className='bbottom pb-2 ' >
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to="/report" onClick={() => goBack()} state={{ title: "Report" }}>Reports</Link></li>
                                        {/* <li class="breadcrumb-item"><Link  onClick={() => goBack()} state={{ title: "Learner Progress" }}>Learner Progress</Link></li> */}

                                        <li class="breadcrumb-item active" aria-current="page">{props.location.state.progress.trainingName}</li>
                                        

                                    </ol>
                                </nav>

                                <div className='specificreportheader my-4'><span onClick={previousroute} className='c-pointer'>{ICN_BACK}</span> Assessment Results</div>

                                <div className='row ' style={{ justifyContent: "space-between" }}>

                                    {/* <div className='col-md-3 '>
                                        {
                                            loader ?
                                                <SkeletonLoader skeletonCount={2.7} />
                                                :

                                                <div className='reportcard'>
                                                    <TotalAssessmentsChart newData={graphData} />
                                                </div>
                                        }

                                    </div> */}
                                    <div className='col-md-6 '>
                                        {
                                            loader ?
                                                <SkeletonLoader skeletonCount={2.7} />
                                                :

                                                <div className='reportcard'>
                                                    <DistributionBarChart assessmentDetails={assessmentDetails} avgData={avgData} />
                                                </div>
                                        }

                                    </div>
                                    <div className='col-md-6'>
                                        {
                                            loader ?
                                                <SkeletonLoader skeletonCount={2.7} />
                                                :

                                                <div className='reportcard d-flex h-100 ' style={{ justifyContent: "space-between" }}>
                                                    <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Training Duration Completion

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                `${assessmentHeaderDetails?.trainingCompletionByDuration.toFixed(2)}%`
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Enrolled Participants

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                assessmentHeaderDetails?.enrolledParticipants
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Mcq Assessments

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                assessmentHeaderDetails?.totalMcqAssessments
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='d-flex m-auto px-1' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Coding Assessments

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                assessmentHeaderDetails?.totalCodingAssessments
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className='d-flex m-auto px-1' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Lab Assessments

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                assessmentHeaderDetails?.totalLabAssessments
                                                            }
                                                        </div>
                                                    </div>


                                                </div>
                                        }
                                    </div>


                                </div>

                            </div>
                            <div className='my-4'>

                                <div className='d-flex' style={{ justifyContent: "space-between" }}>
                                    <div className='mb-2'>
                                        {
                                            selectedRows.length === 1 &&

                                            <>
                                                <button className='emailbtn' onClick={openSpecificAssessment}>Assessment Specific</button>
                                                {/* <button className='emailbtn mx-2'><span className='mr-2'>{EMAIL_ICON}</span>Learner's Enrollment History</button> */}

                                            </>
                                        }
                                    </div>

                                </div>
                                {

                                    loader ?
                                        <SkeletonLoader skeletonCount={10} />
                                        :
                                        data.length !== 0 &&
                                        <MaterialReactTable table={table} />
                                }


                            </div>
                        </div>
                    </>


            }

        </div>
    )
}

export default AssessmentSpecificReport
