import React from 'react';

import './zoom.css'
import { ZoomMtg } from '@zoom/meetingsdk';
import { useContext, useEffect } from "react";
import AppContext from "../../Store/AppContext";
import { API_PATH } from '../../Constant/GlobleConstant';

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

function Zoom({zoomInfo}) {
    const  {user,ROLE} = useContext(AppContext)

  var signatureEndpoint = 'https://evaluate.assessments.live'
  var sdkKey = '3RnUGNnpQ6CBuYhfvuNpJA'
  var meetingNumber = zoomInfo.meetingId
  var passWord = zoomInfo.password;
  var role  = ROLE.INSTRUCTOR ? 1 : 0
  var userName =  user.appuser.name;
  var userEmail =  user.appuser.emailId;
  var registrantToken = '';
  var zakToken = '';
  var leaveUrl = user.role === ROLE.CONSUMER_LEARNER ? 'https://lxp.learnlytica.com/training/training-details' : `${API_PATH}/training/training-details`;

  function getSignature(e) {
    // e.preventDefault();

    fetch(signatureEndpoint, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          meetingNumber: meetingNumber,
          role: role
        })
      }).then(res => res.json())
      .then(response => {
        startMeeting(response.signature)
      }).catch(error => {
        console.error(error)
      })
    }

  function startMeeting(signature) {
    document.getElementById('zmmtg-root').style.display = 'block'

    ZoomMtg.init({
        leaveUrl: leaveUrl,
        success: (success) => {
          ZoomMtg.join({
            signature: signature,
            meetingNumber: meetingNumber,
            userName: userName,
            sdkKey: sdkKey,
            userEmail: userEmail,
            passWord: passWord,
            tk: registrantToken,
            success: (success) => {
            },
            error: (error) => {
              console.error(error)
            }
          })
  
        },
        error: (error) => {
          console.error(error)
        }
      })
  }



  useEffect(() => {
    getSignature()
}, [])

  return (
    <></>
  );
}

export default Zoom;