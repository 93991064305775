import React, { useState, useEffect, useContext, useRef, useMemo } from 'react'
import { ICN_ASSESSMT, ICN_EDIT, ICN_EXPAND_NEW, ICN_BACK, ICN_MORE_DETAILS, ICN_NOTE_EDIT, ICN_OPEN_SANDBOX, ICN_START_ASSESS, ICN_STRAT_SESSION, ICN_COMING_BATCHES, ICN_COPY, REPORT_ICON_BATCH, REPORT_ICON_GO_SOMEWHERE, REPORT_ICON_GROUP, REPORT_ICON_LEARNER, ICN_STD_MATERIAL, ICN_VDO, ICN_LABASS, ICN_CODINGASS, EMAIL_ICON } from '../../Common/Icon';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Checkbox } from '@mui/material';
import { LinearProgress } from '@mui/material';
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Link, useNavigate } from '@reach/router';
import TimerIcon from '@mui/icons-material/Timer';
import ScienceIcon from '@mui/icons-material/Science';
import RestService from '../../../Services/api.service';
import AppContext from '../../../Store/AppContext';
import InfoIcon from '@mui/icons-material/Info';
import LoadingSpinner from '../Training/LoadingSpinner';
import { Button } from '../../Common/Buttons/Buttons';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MailIcon from '@mui/icons-material/Mail';
import { WithContext as ReactTags } from "react-tag-input";
import useToast from '../../../Store/ToastHook';
import SendIcon from '@mui/icons-material/Send';
import SkeletonLoader from '../../Common/SkeletonLoader/SkeletonLoader';
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import PerformanceComparisonChart from './PerformanceComparisonChart';
import { LearnerPerformanceOverview } from './LearnerPerformanceOverview';
import Histogram from './NewCharts/Histogram';

//error component
const ErrorComponent = () => {
    return (
        <div class="row justify-content-center">
            <div class="col-md-12 col-sm-12">
                <div class="card shadow-lg border-0 rounded-lg mt-5 mx-auto" style={{ width: "30rem" }}>
                    <h3 class="card-header display-1 text-muted text-center">
                        404
                    </h3>

                    <span class="card-subtitle mb-2 text-muted text-center">
                        Something went wrong
                    </span>

                    {/* <div class="card-body mx-auto">
                      <button class="btn btn-sm btn-info text-white" onClick={() => navigate("/training", { state: { title: "Training" } })}>
                          Back To Training
                      </button>
                    
                  </div> */}
                </div>
            </div>
        </div>
    )
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal d-block" : "modal d-none";

    return (

        <div className={showHideClassName}  >

            <div className="modal-container modal-xl" >
                <div style={{ float: "right" }} className='circle-md'> <div className="modal-close" onClick={handleClose}>
                    X
                </div></div>
                {children}

            </div>
        </div>
    );
};

const AssessmentAllSpecific = (props) => {

    //learner
    const [data, setData] = useState([]);
    const [assessmentHeaderDetails, setAssessmentHeaderDetails] = useState({});
    const [assessmentDetails, setAssessmentDetails] = useState({});
    const [specificData, setSpecificData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const { setCourse, setBatches, ROLE, course, spinner, user, batches } = useContext(AppContext);
    const [isLoading, setIsLoading] = useState(true);
    const [show, setShow] = useState(false);
    const [tags, setTags] = useState([]);
    const [loader, setLoader] = useState(true);
    const [histogramData, setHistogramData] = useState([]);
    const Toast = useToast();
    const KeyCodes = {
        comma: 188,
        enter: 13
    };

    const navigate = useNavigate();

    const [activeTab, setActiveTab] = useState('learner'); // Set the default active tab
    const [counter, setCounter] = useState(20); const handleTabChange = (tabId) => {
        setActiveTab(tabId);
    };

    //send mail
    const handleDelete = i => {
        setTags(tags.filter((tag, index) => index !== i));
    };

    const handleAddition = tag => {
        setTags([...tags, tag]);
    };

    const goBack = () => {
        localStorage.removeItem("progress");
    }

    const previousroute = () => {
        // Navigate to the previous page
        navigate(-1);
    };

    //assessment table details

    const columns = useMemo(
        () => [
            // {
            //     accessorKey: 'id',
            //     enableColumnPinning: false,
            //     header: 'ID',
            //     size: 50,
            // },
            {
                accessorKey: 'select',
                header: 'Select',
                Cell: ({ row }) => (
                    <Checkbox
                        checked={selectedRows.includes(row.original.id)}
                        onChange={() => {
                            const newSelectedRows = selectedRows.includes(row.original.id)
                                ? selectedRows.filter((id) => id !== row.original.id)
                                : [...selectedRows, row.original.id];

                            setSelectedRows(newSelectedRows);
                            setAssessmentDetails(row.original);
                        }}
                    />
                ),
                size: 50,
            },
            {
                accessorKey: 'learnerName',
                header: 'Learner Name',
            },
            {
                accessorKey: 'learnerEmail',
                header: 'Email',
            },
            {
                accessorKey: 'submittedOn',
                header: 'Submitted On',
            },
            {
                accessorKey: 'correct',
                header: 'Correct',
            },
            {
                accessorKey: 'total',
                header: 'Marks Obtain',
            },
            {
                accessorKey: 'gainedMarks',
                header: 'Gained Marks',
            },
            {
                accessorKey: 'totalMarks',
                header: 'Total Marks',
            },
            {
                accessorKey: 'percentage',
                header: 'Percentage',
            },
            // {
            //     accessorKey: 'assessmentScreenshots',
            //     header: 'Screenshots',
            // }

        ],
        [selectedRows],
    );

    function decodeString(str) {
        return str.replace(/%20/g, ' ');
    }

    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnPinning: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableColumnFilters: false,
        layoutMode: 'grid-no-grow',
        initialState: {
            columnPinning: {
                left: ['select', 'learnerName'],
                //  right: ['city'] 
            },

        },
    });

    const images = [
        {
            original: "https://picsum.photos/id/1018/1000/600/",
            thumbnail: "https://picsum.photos/id/1018/250/150/",
        },
        {
            original: "https://picsum.photos/id/1015/1000/600/",
            thumbnail: "https://picsum.photos/id/1015/250/150/",
        },
        {
            original: "https://picsum.photos/id/1019/1000/600/",
            thumbnail: "https://picsum.photos/id/1019/250/150/",
        },
    ];


    //export as pdf
    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = async () => {
        const pdf = new jsPDF("portrait", "pt", "a4");
        const data = await html2canvas(document.querySelector("#capture"));
        const img = data.toDataURL("image/png");
        const imgProperties = pdf.getImageProperties(img);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
        pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
        spinner.hide();
        pdf.save("overall_training_report.pdf");

        // html2canvas(document.querySelector("#capture")).then(canvas => {
        //   document.body.appendChild(canvas);  // if you want see your screenshot in body.
        //   const imgData = canvas.toDataURL('image/png');
        //   const pdf = new jsPDF({
        //     format: 'a4',
        //     unit: 'px',
        //   });
        //   pdf.addImage(imgData, 'PNG', 0, 0);
        //   pdf.save("download.pdf");
        // });
        // const doc = new jsPDF({
        //   format: 'a4',
        //   unit: 'px',
        // });

        // Adding the fonts.
        // doc.setFont('Inter-Regular', 'normal');

        // doc.html(reportTemplateRef.current, {
        //   async callback(doc) {
        //     await doc.save('document');
        //   },
        // });
        // doc.html(document.body, {
        //   callback: function () {
        //     doc.save('page');
        //   },
        // });
    };


    //send mail
    const sendSupervisorReportMail = () => {
        try {
            spinner.show();
            let tagsCsv = tags.map((item) => item.text);
            const payload = {
                "recipients": tagsCsv,
                "reportType": "SUPERVISOR_MAIN"
            }
            RestService.sendSupervisorReportMail(payload).then(
                response => {
                    Toast.success({ message: `Mail Sent Successfully`, time: 2000 });
                    setTags([])
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: `Try Again`, time: 2000 });
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on sendSupervisorReportMail()", err)
        }
    }

    //assessment specific details with learner for labs
    const getAssessmentAssessmentLearnerScreenDetailsLab = () => {
        try {
            // spinner.show();
            const labId = props.location.state.progress.labId;
            const type = props.location.state.progress.assessmentType;
            const trainingSid = props.location.state.trainingSid;
            RestService.getAssessmentAssessmentLearnerScreenDetailsLab(labId, type, trainingSid).then(
                response => {
                    if (response.status === 200) {
                        const updatedData = response.data.learnerDetails.map((item, index) => ({
                            ...item,
                            id: index + 1,
                        }));
                        const histogramData = response.data.learnerDetails.map(learner => [`${learner.learnerName}`, learner.percentage.toFixed(2)]);
                        setHistogramData(histogramData);
                        setAssessmentHeaderDetails(response.data.headerDetails);
                        setData(updatedData);
                        setTimeout(() => {
                            setLoader(false);
                        }, 2000);
                       
                    }
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: `Try Again`, time: 2000 });
                }
            )
        } catch (err) {
            console.error("error occur on getAssessmentAssessmentLearnerScreenDetailsLab()", err)
        }
    }

    //assessment specific details with learner for mcq, coding
    const getAssessmentAssessmentLearnerScreenDetailsAssessment = () => {
        try {
            // spinner.show();

            const assessmentSid = props.location.state.progress.assessmentSid;
            const type = props.location.state.progress.assessmentType;
            const trainingSid = props.location.state.trainingSid;
            RestService.getAssessmentAssessmentLearnerScreenDetailsAssessment(assessmentSid, type, trainingSid).then(
                response => {
                    if (response.status === 200) {
                        const updatedData = response.data.learnerDetails.map((item, index) => ({
                            ...item,
                            id: index + 1,
                        }));
                        const histogramData = response.data.learnerDetails.map(learner => [`${learner.learnerName}`, learner.percentage.toFixed(2)]);
                        setHistogramData(histogramData);
                        setAssessmentHeaderDetails(response.data.headerDetails);
                        setData(updatedData);
                        setTimeout(() => {
                            setLoader(false);
                        }, 2000);
                    }
                },
                err => {
                    spinner.hide();
                    Toast.error({ message: `Try Again`, time: 2000 });
                }
            )
        } catch (err) {
            console.error("error occur on getAssessmentAssessmentLearnerScreenDetailsAssessment()", err)
        }
    }


    useEffect(() => {
        const lab = props.location.state.progress.labId;
        const assessment = props.location.state.progress.assessmentSid;
        if (lab !== null) {
            getAssessmentAssessmentLearnerScreenDetailsLab();
        }
        else {
            getAssessmentAssessmentLearnerScreenDetailsAssessment();
        }


    }, []);


    return (
        <div>
            {
                !isLoading ?

                    <ErrorComponent />
                    :
                    <>
                        <div id="capture">
                            <div className="p-3">

                                <nav aria-label="breadcrumb " className='bbottom pb-2 ' >
                                    <ol class="breadcrumb">
                                        <li class="breadcrumb-item"><Link to="/report" onClick={() => goBack()} state={{ title: "Report" }}>Reports</Link></li>
                                        {/* <li class="breadcrumb-item"><Link  onClick={() => goBack()} state={{ title: "Learner Progress" }}>Learner Progress</Link></li> */}


                                        <li class="breadcrumb-item">{decodeString(props.location.pathname.split('/')[3])}</li>
                                        <li class="breadcrumb-item active" aria-current="page">{decodeString(props.location.state.progress.assessmentName)}</li>

                                    </ol>
                                </nav>

                                <div className='specificreportheader my-4'><span onClick={previousroute} className='c-pointer'>{ICN_BACK}</span> Learner Results</div>

                                <div className='row ' style={{ justifyContent: "space-between" }}>

                                    <div className='col-md-6'>
                                        {
                                            loader ?
                                                <SkeletonLoader skeletonCount={2.7} />
                                                :

                                                <div className='reportcard'>
                                                    <Histogram histogramData={histogramData} />
                                                </div>
                                        }

                                    </div>
                                    {/* <div className='col-md-3 '>
                                        {
                                            loader ?
                                                <SkeletonLoader skeletonCount={2.7} />
                                                :

                                                <div className='reportcard'>
                                                    <LearnerPerformanceOverview />
                                                </div>
                                        }

                                    </div> */}
                                    <div className='col-md-6'>
                                        {
                                            loader ?
                                                <SkeletonLoader skeletonCount={2.7} />
                                                :

                                                <div className='reportcard d-flex h-100 ' style={{ justifyContent: "space-between" }}>
                                                    <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Submitted By

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                `${assessmentHeaderDetails?.submittedBy}`
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Average Percentage

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                `${assessmentHeaderDetails?.averagePercentage.toFixed(2)}%`
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='d-flex m-auto px-1 ' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Topper

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                assessmentHeaderDetails?.topper
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className='d-flex m-auto px-1' style={{ flexDirection: "column" }}>
                                                        <div className='reportcardheader'>
                                                            Topper Percentage

                                                        </div>
                                                        <div className='text-center reportcardtitle'>
                                                            {
                                                                `${assessmentHeaderDetails?.topperPercentage.toFixed(2)}%`
                                                            }
                                                        </div>
                                                    </div>


                                                </div>
                                        }
                                    </div>


                                </div>

                            </div>
                            <div className='my-4'>

                                <div className='d-flex' style={{ justifyContent: "space-between" }}>
                                    <div className='mb-2'>
                                        {
                                            selectedRows.length === 1 &&

                                            <>
                                                <button className='emailbtn' onClick={() => setShow(true)}>Learner Specific</button>
                                                {/* <button className='emailbtn mx-2'><span className='mr-2'>{EMAIL_ICON}</span>Learner's Enrollment History</button> */}

                                            </>
                                        }
                                    </div>

                                </div>
                                {

                                    loader ?
                                        <SkeletonLoader skeletonCount={10} />
                                        : data.length !== 0 &&
                                        <MaterialReactTable table={table} />
                                }


                            </div>
                        </div>
                        {
                            show &&
                            <Modal show={show} handleClose={() => setShow(false)}>
                                <div className='mt-3'>
                                    <ImageGallery
                                        className='img-fluid image-gallery-left-nav image-gallery-svg image-gallery-right-nav image-gallery-svg'
                                        items={images}
                                        infinite={false}
                                        showFullscreenButton={false}
                                        slideInterval={2000}
                                    />
                                </div>
                            </Modal>
                        }
                    </>


            }

        </div>
    )
}

export default AssessmentAllSpecific
