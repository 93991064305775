import React, { useState, useEffect, useContext, useMemo } from 'react'

import { Link, useNavigate } from '@reach/router';
import { ICN_BACK } from '../../Common/Icon';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import RestService from '../../../Services/api.service';
import { Checkbox } from '@mui/material';
import useToast from '../../../Store/ToastHook';
import AppContext from '../../../Store/AppContext';
import { useParams } from "@reach/router";
import TrainingPercentageTrendChart from './TrainingPercentageTrendChart';
import { StackedBarChartComponent } from './StackedBarChartComponent';

const ReportLearnerDetails = (props) => {
  const params = useParams();

  // const progress = location.state.progress;
  // localStorage.setItem("progress", progress);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [learnerHeaderDetails, setLearnerHeaderDetails] = useState({});
  const [data, setData] = useState([]);
  const { setCourse, setBatches, ROLE, course, spinner, user, batches } = useContext(AppContext);
  const Toast = useToast();
  const navigate = useNavigate();

  const newData = [27, 29, 28, 30, 29, 30];
  const goBack = () => {
    localStorage.removeItem("progress");
  }

  const previousroute = () => {
    // Navigate to the previous page
    navigate(-1);
  };

  const getLearnerScreenDetails = () => {
    try {
      // spinner.show();
      const learnerSid = props.location.state.progress;
      RestService.getLearnerScreenDetails(learnerSid).then(
        response => {
          if (response.status === 200) {
            const updatedData = response.data.learnerDetails.map(item => ({
              ...item,
              ...item.completion,
              id: item.learnerSid,
              completion: null
            }));
            setLearnerHeaderDetails(response.data.headerDetails);
            setData(updatedData);

          }
        },
        err => {
          spinner.hide();
          Toast.error({ message: `Try Again`, time: 2000 });
        }
      )
    } catch (err) {
      console.error("error occur on getLearnerScreenDetails()", err)
    }
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        enableColumnPinning: false,
        header: 'ID',
        size: 50,
      },
      {
        accessorKey: 'select',
        header: 'Select',
        Cell: ({ row }) => (
          <Checkbox
            checked={selectedRows.includes(row.original.id)}
            onChange={() => {

              const newSelectedRows = selectedRows.includes(row.original.id)
                ? selectedRows.filter((id) => id !== row.original.id)
                : [...selectedRows, row.original.id];

              setSelectedRows(newSelectedRows);
            }}
          />
        ),
        size: 50,
      },
      {
        accessorKey: 'trainingName',
        header: 'Training',
      },
      {
        accessorKey: 'startDate',
        header: 'Start Date',
      },
      {
        accessorKey: 'endDate',
        header: 'End Date',
      },
      {
        accessorKey: 'trainingStatus',
        header: 'Status',
      },
      {
        accessorKey: 'trainingProgress',
        header: 'Progress',
      },
      {
        accessorKey: 'averageAssessmentPercentage',
        header: 'averageAssessmentPercentage',
      },
      {
        accessorKey: 'totalContent',
        header: 'Total Content',
      },
      {
        accessorKey: 'documents',
        header: 'Documents',
      },
      {
        accessorKey: 'documentCompletion',
        header: 'Document Completion',
      },
      {
        accessorKey: 'videos',
        header: 'Videos',
      },
      {
        accessorKey: 'videoCompletion',
        header: 'Video Completion'
      },
      {
        accessorKey: 'practiceLabs',
        header: 'Practice Labs',
      },
      {
        accessorKey: 'practiceLabCompletion',
        header: 'Practice Lab Completion'
      },
      {
        accessorKey: 'assessmentLabs',
        header: 'Assessment Labs',
      },
      {
        accessorKey: 'assessmentLabCompletion',
        header: 'Assessment Lab Completion',
      },
      {
        accessorKey: 'mcqAssessments',
        header: 'Mcq Assessments'
      },
      {
        accessorKey: 'mcqAssessmentCompletion',
        header: 'Mcq Assessment Completion'
      },
      {
        accessorKey: 'codingAssessments',
        header: 'Coding Assessments'
      },
      {
        accessorKey: 'codingAssessmentCompletion',
        header: 'Coding Assessment Completion'
      }
    ],
    [selectedRows],
  );

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnPinning: true,
    enableDensityToggle: false,
    enableFullScreenToggle: false,
    enableColumnFilters: false,
    layoutMode: 'grid-no-grow',
    initialState: {
      columnPinning: {
        left: ['select', 'trainingName'],
        //  right: ['city'] 
      },

    },
  });


  useEffect(() => {
    getLearnerScreenDetails();
  }, []);
  return (
    <>

      <div id="capture">
        <div className="p-3">

          <nav aria-label="breadcrumb " className='bbottom pb-2 ' >
            <ol class="breadcrumb">
              <li class="breadcrumb-item"><Link to="/report" onClick={() => goBack()} state={{ title: "Report" }}>Reports</Link></li>
              {/* <li class="breadcrumb-item"><Link  onClick={() => goBack()} state={{ title: "Learner Progress" }}>Learner Progress</Link></li> */}

              <li class="breadcrumb-item active" aria-current="page">{props.location.state.progress.learnerName}</li>

            </ol>
          </nav>

          <div className='specificreportheader my-4'><span onClick={previousroute} className='c-pointer'>{ICN_BACK}</span> Learner Results</div>

          <div className='row ' style={{ justifyContent: "space-between" }}>

            <div className='col-md-3 '>

              <div className='reportcard'>
                <TrainingPercentageTrendChart data={newData} />
              </div>

            </div>
            <div className='col-md-3 '>

              <div className='reportcard'>
                <StackedBarChartComponent />
              </div>

            </div>
            <div className='col-md-6 '>

              <div className='reportcard'>
                <div>
                  Passed:
                  {
                    learnerHeaderDetails?.passed
                  }
                </div>
                <div>
                  Failed:
                  {
                    learnerHeaderDetails?.failed
                  }
                </div>
                <div>
                  In Progress:
                  {
                    learnerHeaderDetails?.inProgress
                  }
                </div>
                <div>
                  Completed:
                  {
                    learnerHeaderDetails?.completed
                  }
                </div>
                <div>
                  Overdue:
                  {
                    learnerHeaderDetails?.overdue
                  }
                </div>

              </div>
            </div>

          </div>

        </div>
        {
          data.length !== 0 &&
          <MaterialReactTable table={table} />
        }
      </div>

    </>
  )
}

export default ReportLearnerDetails