import React, { useState, useContext, useEffect } from 'react'
import Select from 'react-select';
import ReactPlayer from 'react-player';
import useToast from '../../../Store/ToastHook';
import Remarks from './Instructor/Remarks';
import RestService from '../../../Services/api.service';
import AppContext from '../../../Store/AppContext';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SkeletonLoader from '../../Common/SkeletonLoader/SkeletonLoader';
import DynamicTable from '../../Common/DynamicTable/DynamicTable';
import LinkIcon from '@mui/icons-material/Link';
import GroupIcon from '@mui/icons-material/Group';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Tooltip } from "react-tooltip";
import InfoIcon from '@mui/icons-material/Info';
import { ICN_ASSESSMT, ICN_CODINGASS, ICN_LABASS, ICN_STD_MATERIAL, ICN_TRAINING_SESSION, ICN_VDO } from '../../Common/Icon';

const TrainingObjective = ({ trainingObjective, trainingSid, labId, projectId, sectionSid, instructorEvaluationAll, showcoursename }) => {
  const [show, setShow] = useState(false);
  const [showLearnerTable, setShowLearnerTable] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [userSubmissions, setUserSubmissions] = useState([]);
  const [score, setScore] = useState('');
  const [showValidationMessage, setShowValidationMessage] = useState(false);
  const [remarks, setRemarks] = useState('');
  const [allLearnersScore, setAllLearnersScore] = useState([]);
  const [wordCount, setWordCount] = useState(200);
  const [duration, setDuration] = useState('');
  const MAX_WORDS = 200;
  const Toast = useToast();
  const { course, batches, spinner, user, setBatches, ROLE } = useContext(AppContext);
  const [loader, setLoader] = useState(true);

  let learnerData = [];

  const [editMode, setEditMode] = useState(null);
  const [projectCardDetails, setProjectCardDetails] = useState(null);
  const [projectGroupDetails, setProjectGroupDetails] = useState([]);
  const [groupSid, setGroupSid] = useState('Select');
  const [groupName, setGroupName] = useState('');
  const [groupScore, setGroupScore] = useState(null);
  const [individualScore, setIndividualScore] = useState([]);
  const [individualScoring, setIndividualScoring] = useState(false);
  const [groupScoring, setGroupScoring] = useState(false);
  const [scoreType, setScoreType] = useState('GROUP');
  const [groupMarkScored, setGroupMarkScored] = useState(0);
  const [individualScoreList, setIndividualScoreList] = useState([]);
  const [learnerDetails, setLearnerDetails] = useState(null);

  const handleAdd = (e, learnerSid) => {
    let inputScore = e.target.value;
    if (inputScore !== '') {
      setIndividualScoreList([...individualScoreList,
      {
        "learnerSid": learnerSid,
        "scoredMarks": e.target.value
      }
      ]);
    }
  };

  const handleRemove = (index) => {
    const newArray = [...individualScoreList];
    newArray.splice(index, 1);
    setIndividualScoreList(newArray);
  };

  //for group project duration
  function formatDateRange(inputDateRangeString) {
    // Regular expression to match the date range format 'dd/mm/yyyy - dd/mm/yyyy'
    const dateRangePattern = /^\d{2}\/\d{2}\/\d{4} - \d{2}\/\d{2}\/\d{4}$/;

    // Check if the input string matches the expected date range format
    if (!dateRangePattern.test(inputDateRangeString)) {
      // If not, return an empty string or any other placeholder text you prefer
      return '';
    }

    // Split the input string into two dates
    const dates = inputDateRangeString.split(' - ').map(dateString => {
      const parts = dateString.split('/');
      // Note: months are 0-based in JavaScript Date
      return new Date(parts[2], parts[1] - 1, parts[0]);
    });

    // Check if both dates are in the same year
    const sameYear = dates[0].getFullYear() === dates[1].getFullYear();

    // Format the start date
    let startDate = dates[0].toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
    });

    // Format the end date (conditionally include year if different)
    let endDate = dates[1].toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'short',
      year: sameYear ? undefined : 'numeric',
    });

    // Construct the final date range string
    let dateRangeString;
    if (sameYear) {
      dateRangeString = `${startDate} - ${endDate} ${dates[0].getFullYear()}`;
    } else {
      startDate += `${dates[0].getFullYear()}`;
      dateRangeString = `${startDate} - ${endDate}`;
    }

    return dateRangeString;
  }


  const [configuration, setConfiguration] = useState({
    columns: {
      "learnerName": {
        "title": "Name",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,

      },
      // "learnerEmail": {
      //   "title": "Email Id",
      //   "sortDirection": null,
      //   "sortEnabled": true,
      //   isSearchEnabled: false
      // },
      "assetName": {
        "title": "Asset Name",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) =>
          <>
            {data.assetName === null ? "---" : data.assetName}
          </>
      },
      "assetlink": {
        "title": "Asset Link",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) =>
          <>
            {/* data.assetlink */}
            {data.assetlink === null ? "---" : <div className="continue px-2">
              <a href={data.assetlink} className="title-sm" >Asset
                <FileDownloadIcon />

              </a>
            </div>}
          </>
      },
      "scoredmarks": {
        "title": "Scored Marks",
        "sortDirection": null,
        "sortEnabled": true,
        isSearchEnabled: false,
        render: (data) =>
          <>
            {

              data.scoredmarks === null && data.assetlink === null ?

                "---"
                :
                data.scoredmarks === null && data.assetlink !== null ? <input type="text" className='form-control' onChange={(e) => handleAdd(e, data.learnerSid)} disabled={groupScoring} />
                  :
                  data.scoredmarks

            }
          </>
      }
    },
    headerTextColor: '#454E50', // user can change table header text color
    sortBy: null,  // by default sort table by name key
    sortDirection: false, // sort direction by default true
    updateSortBy: (sortKey) => {
      configuration.sortBy = sortKey;
      Object.keys(configuration.columns).map(key => configuration.columns[key].sortDirection = (key === sortKey) ? !configuration.columns[key].sortDirection : false);
      configuration.sortDirection = configuration.columns[sortKey].sortDirection;
      setConfiguration({ ...configuration });
    },
    actions: [],
    actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
    actionVariant: "", // user can pass action button variant like primary, dark, light,
    actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
    // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
    // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
    searchQuery: "",
    tableCustomClass: "ng-table sort-enabled", // table custom class
    showCheckbox: false,
    clearSelection: false
  });

  const handleEdit = (id) => {
    setEditMode(id);
  };

  const handleSave = (id) => {
    setEditMode(null);
    // editLearnerScoreAndRemarks(id);
    // Save the edited row data to the backend or perform other operations
  };

  const handleCancel = () => {
    setEditMode(null);
  };

  const handleInputChange = (id, field, value) => {
    if (field === "score") {
      setScore(value)
    } else {
      setRemarks(value)
    }
    setAllLearnersScore((prevData) =>
      prevData.map((row) =>
        row.learnerSid === id ? { ...row, [field]: value } : row
      )
    );
  };

  const AllLearnsScoreNRemarks = () => {
    return (
      <>
        <div class="card " >
          <div class="card-header title-md" style={{ background: "#F7F7F7", marginBottom: "0px" }}>
            Evaluated Submissions
          </div>
        </div>
        <table className='mb-3 p-3'>
          <thead style={{ background: "#F7F7F7", marginBottom: "0px" }}>
            <tr>

              <th>Name</th>
              <th>Score</th>
              <th>Remarks</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allLearnersScore.map((row) => (
              <tr key={row.learnerSid}>

                <td className='text-center'>
                  {

                    row.learnerName
                  }
                </td>
                <td className='text-center'>
                  {editMode === row.learnerSid ? (
                    <input
                      type="text"
                      value={row.score}
                      onChange={(e) =>
                        handleInputChange(row.learnerSid, 'score', e.target.value)

                      }

                    />
                  ) : (
                    row.score
                  )}
                </td>
                <td className='text-center'>
                  {editMode === row.learnerSid ? (
                    <input
                      type="text"
                      value={row.remarks}
                      onChange={(e) =>
                        handleInputChange(row.learnerSid, 'remarks', e.target.value)

                      }

                    />
                  ) : (
                    row.remarks
                  )}
                </td>
                <td className='text-center'>
                  {editMode === row.learnerSid ? (
                    <div>
                      <button onClick={() => handleSave(row.learnerSid)}>Save</button>
                      <button onClick={handleCancel}>Cancel</button>
                    </div>
                  ) : (
                    <button onClick={() => handleEdit(row.learnerSid)}>Edit</button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </>
    )
  }

  //get user submissions
  const getUserSubmissions = () => {
    try {
      const learnerSid = selectedOption.value
      spinner.show();
      RestService.getUserSubmissions(labId, learnerSid, trainingSid).then(
        response => {
          if (response.status === 200) {
            setUserSubmissions(response.data);
            setShow(true);

          }
        },
        err => {
          spinner.hide();
          console.error(err);
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getUserSubmissions()", err)
    }
  }

  //get all learners score
  const getAllLearnersScore = () => {
    try {
      spinner.show();
      RestService.getAllLearnersScore(labId, trainingSid).then(
        response => {
          if (response.status === 200) {
            setAllLearnersScore(response.data);

          }
        },
        err => {
          spinner.hide();
          console.error(err);
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on getAllLearnersScore()", err)
    }
  }

  //edit learners score and remarks
  const editLearnerScoreAndRemarks = (learnerSid) => {
    try {
      let payload = {
        "labId": 0,
        "learnerSid": "string",
        "remarks": "string",
        "score": 0,
        "trainingSid": "string"
      };
      spinner.show();
      RestService.editLearnerScoreAndRemarks(payload).then(
        response => {
          if (response.status === 200) {
            getAllLearnersScore();

          }
        },
        err => {
          spinner.hide();
          console.error(err);
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on editLearnerScoreAndRemarks()", err)
    }
  }

  //group wise details
  const groupWiseDetails = (groupSid) => {
    try {
      spinner.show();
      RestService.groupWiseDetails(groupSid, projectId, sectionSid, trainingSid).then(
        response => {
          if (response.status === 200) {
            setGroupSid(response.data.groupSid);
            setGroupName(response.data.groupName);
            setGroupScore(response.data.groupScore);
            setIndividualScore(response.data.individualScore);
            setIndividualScoring(response.data.individualScoring);
            setGroupScoring(response.data.groupScoring);
            if (response.data.groupScoring) {
              setScoreType("GROUP");
            }
            if (response.data.individualScoring) {
              setScoreType("INDIVIDUAL");
            }

          }
        },
        err => {
          spinner.hide();
          console.error(err);
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on groupWiseDetails()", err)
    }
  }

  //project score submission
  const scoreSubmission = () => {
    try {
      spinner.show();
      let payload = {
        "groupSid": groupSid,
        "projectId": projectId,
        "sectionSid": sectionSid,
        "trainingSid": trainingSid
      };
      if (scoreType === 'GROUP') {
        payload["groupScore"] = {
          "repoLink": groupScore.repoLink,
          "scoredMarks": groupMarkScored
        };
        payload["groupScoring"] = true;
        payload["individualScore"] = null;
        payload["individualScoring"] = false;

      }
      else {
        payload["groupScore"] = null;
        payload["groupScoring"] = false;
        payload["individualScore"] = individualScoreList;
        payload["individualScoring"] = true;
      }
      RestService.scoreSubmission(payload).then(
        response => {
          if (response.status === 200) {
            Toast.success({ message: `Score Updated`, time: 2000 });
            setGroupSid(response.data.groupSid);
            setGroupName(response.data.groupName);
            setGroupScore(response.data.groupScore);
            setIndividualScore(response.data.individualScore);
            setIndividualScoring(response.data.individualScoring);
            setGroupScoring(response.data.groupScoring);
            setGroupMarkScored(0);
          }
        },
        err => {
          spinner.hide();
          console.error(err);
          Toast.error({ message: `Try Again`, time: 2000 });
        }
      ).finally(() => {
        spinner.hide();
      });
    } catch (err) {
      console.error("error occur on scoreSubmission()", err)
    }
  }

  const learnerEvaluateLabDetails = (learnerSid) => {
    try {
      spinner.show();
      RestService.learnerEvaluateLabDetails(labId, learnerSid, trainingSid).then(res => {
        if (res.status === 200) {
          setLearnerDetails(res.data);
        }
        spinner.hide();

      }, err => { spinner.hide() }
      );
    }
    catch (err) {
      spinner.hide();
      console.error('error occur on learnerEvaluateLabDetails', err)
    }
  }

  if (trainingObjective === null || trainingObjective === undefined) {
    learnerData = [];
  }
  else {
    if ("submittedUnscoredLearnerDetails" in trainingObjective) {
      for (const key in trainingObjective.submittedUnscoredLearnerDetails) {
        learnerData.push(
          { label: trainingObjective.submittedUnscoredLearnerDetails[key], value: key }
        )
      }
    }
  }

  const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal d-block" : "modal d-none";

    return (

      <div className={showHideClassName} >

        <div className="modal-container modal-xl">
          <div style={{ float: "right" }} className='circle-md'> <div className="modal-close" onClick={handleClose}>
            X
          </div></div>
          {children}

        </div>
      </div>
    );
  };

  //instructor and supervisor view
  const instructorAndSupervisorView = () => {
    try {
      // spinner.show();
      RestService.instructorAndSupervisorView(projectId, sectionSid, trainingSid).then(res => {
        if (res.status === 200) {
          setGroupSid('Select');
          setGroupName('');
          setGroupScore(null);
          setIndividualScore([]);
          setScoreType('GROUP');
          setProjectCardDetails(res.data.cardDetails);
          const getDuration = formatDateRange(res.data.cardDetails.duration);
          setDuration(getDuration);
          let groupDetails = res.data.groupDetails.map(r => {
            return ({ label: r.groupName, value: r.groupSid })

          });
          setProjectGroupDetails(groupDetails);
        }
        spinner.hide();

      }, err => { spinner.hide() }
      );
    }
    catch (err) {
      spinner.hide();
      console.error('error occur on instructorAndSupervisorView', err)
    }
  }

  useEffect(() => {
    if (sectionSid !== undefined) {
      instructorAndSupervisorView();
    }
    setTimeout(() => {
      setLoader(false);
    }, 2000)
  }, [sectionSid]);

  return (

    <>
      <div>
        {
          trainingObjective !== undefined &&
            ("trainingDescription" in trainingObjective || "sectionDescription" in trainingObjective) ? (
            <>
              {/* <div className='card'>
                <div className='card-header title-md' style={{ background: "#F7F7F7", marginBottom: "0px" }}>
                  {"trainingDescription" in trainingObjective ? "Training Objective" : "Section Objective"}
                </div>
              </div> */}
              <div className='row mt-2 mb-4'>
                <div className='col-sm-6 col-lg-4'>
                  {/* <div className='mb-3'>
                   
                    <div className='card-text title-sm text-center'> {ICN_VDO} {trainingObjective.videos === '0/0' ? 'N/A' : trainingObjective.videos.split('/')[1]} Videos</div>
                    <div className='card-text text-center'>
                      {trainingObjective.videos === '0/0' ? 'N/A' : trainingObjective.videos.split('/')[0]} Wached
                    </div>
                  </div> */}
                  <div className='d-flex px-2 '>

                    <div className='mx-2'>{ICN_VDO} </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className='contentandresourcestitle'>{trainingObjective.videos === '0/0' ? 0 : trainingObjective.videos.split('/')[1]} Videos</div>
                      <div className='contentandresources-subtitle'> {trainingObjective.videos === '0/0' ? 0 : trainingObjective.videos.split('/')[0]} Watched</div>
                    </div>
                  </div>





                </div>
                <div className='col-sm-6 col-lg-4'>
                  {/* <div className='mb-3  h-75'>
                    <div className=' title-sm text-center'>{ICN_ASSESSMT}Assessment</div>
                    <div className='title-md text-center'>
                      {trainingObjective.assessments === '0/0' ? 'N/A' : trainingObjective.assessments}
                    </div>
                  </div> */}

                  <div className='d-flex px-2 '>

                    <div className='mx-2'>{ICN_ASSESSMT}</div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className='contentandresourcestitle'>{trainingObjective.assessments === '0/0' ? 0 : trainingObjective.assessments.split('/')[1]} Assessment</div>
                      <div className='contentandresources-subtitle'> {trainingObjective.assessments === '0/0' ? 0 : trainingObjective.assessments.split('/')[0]} Completed</div>
                    </div>
                  </div>




                </div>
                <div className='col-sm-6 col-lg-4'>
                  {/* <div className='mb-3 h-75'>
                    <div className='title-sm text-center'>{ICN_STD_MATERIAL}Study Material</div>
                    <div className='title-md text-center'>
                      {trainingObjective.studyMaterials === '0/0' ? 'N/A' : trainingObjective.studyMaterials}
                    </div>
                  </div> */}

                  <div className='d-flex px-2'>

                    <div className='mx-2'>{ICN_STD_MATERIAL}</div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className='contentandresourcestitle'>{trainingObjective.studyMaterials === '0/0' ? 0 : trainingObjective.studyMaterials.split('/')[1]} Study Material</div>
                      <div className='contentandresources-subtitle'> {trainingObjective.studyMaterials === '0/0' ? 0 : trainingObjective.studyMaterials.split('/')[0]} Completed</div>
                    </div>
                  </div>


                </div>


              </div>
              <div className='row mb-2'>
                <div className='col-sm-6 col-lg-4'>
                  {/* <div className='mb-3  h-75'>
                    <div className='title-sm text-center'>{ICN_TRAINING_SESSION}Training Session</div>
                    <div className='title-md text-center'>
                      {trainingObjective.meetingSessions === '0/0' ? "N/A" : trainingObjective.meetingSessions}
                    </div>
                  </div> */}


                  <div className='d-flex px-2'>

                    <div className='mx-2'>{ICN_TRAINING_SESSION}</div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className='contentandresourcestitle'>{trainingObjective.meetingSessions === '0/0' ? 0 : trainingObjective.meetingSessions.split('/')[1]} Training Session</div>
                      <div className='contentandresources-subtitle'> {trainingObjective.meetingSessions === '0/0' ? 0 : trainingObjective.meetingSessions.split('/')[0]} Watched</div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6 col-lg-4'>
                  {/* <div className='mb-3  h-75'>
                    <div className=' title-sm text-center'>{ICN_LABASS}Labs Assessment</div>
                    <div className='title-md text-center'>
                      {trainingObjective.labs === '0/0' ? 'N/A' : trainingObjective.labs}
                    </div>
                  </div> */}


                  <div className='d-flex px-2'>

                    <div className='mx-2'>{ICN_LABASS}</div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className='contentandresourcestitle'>{trainingObjective.labs === '0/0' ? 0 : trainingObjective.labs.split('/')[1]} Labs Assessment</div>
                      <div className='contentandresources-subtitle'> {trainingObjective.labs === '0/0' ? 0 : trainingObjective.labs.split('/')[0]} Completed</div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-6 col-lg-4'>
                  {/* <div className='mb-3 h-75'>
                    <div className=' title-sm text-center'>{ICN_CODINGASS}Coding Questions</div>
                    <div className='title-md text-center'>
                      {trainingObjective.codingQuestions === '0/0' ? 'N/A' : trainingObjective.codingQuestions}
                    </div>
                  </div> */}

                  <div className='d-flex px-2'>

                    <div className='mx-2'>{ICN_CODINGASS}</div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className='contentandresourcestitle'>{trainingObjective.codingQuestions === '0/0' ? 0 : trainingObjective.codingQuestions.split('/')[1]} Coding Assessment</div>
                      <div className='contentandresources-subtitle'> {trainingObjective.codingQuestions === '0/0' ? 0 : trainingObjective.codingQuestions.split('/')[0]} Completed</div>
                    </div>
                  </div>
                </div>
              </div>
              {
                projectCardDetails !== null &&
                <div className="card border">
                  <div className="card-body">
                    <h5 className="card-title title-md">
                      Project
                    </h5>
                    {
                      loader ?
                        <SkeletonLoader skeletonCount={1} />
                        :
                        <p className="card-text" style={{ textAlign: "justify" }}>
                          {trainingObjective.trainingDescription ? (
                            <ReactMarkdown>{trainingObjective.trainingDescription}</ReactMarkdown>
                          ) : (
                            <ReactMarkdown>{trainingObjective.sectionDescription}</ReactMarkdown>
                          )}
                        </p>
                    }
                  </div>
                </div>
              }

            </>
          ) :
            projectCardDetails !== undefined && projectCardDetails !== null ?
              <>

                <div className='assessment-session-parrent' style={{ maxHeight: "400px", overflowY: "auto" }}>

                  <div className='row' style={{ width: "100%" }}>

                    <div className=' col-md-4'>



                      <div className="assessment-session-card">
                        <div className="assessment-session-card-title">
                          Group Count
                        </div>
                        <div className="assessment-session-card-score">
                          {projectCardDetails.groupCount}

                        </div>

                      </div>
                    </div>

                    <div className='col-md-4'>


                      <div className="assessment-session-card">
                        <div className="assessment-session-card-title">
                          Marks
                        </div>
                        <div className="assessment-session-card-score">
                          {projectCardDetails.totalMarks}

                        </div>

                      </div>
                    </div>
                    <div className=' col-md-4'>

                      <div className="assessment-session-card">
                        <div className="assessment-session-card-title">
                          Submitted
                        </div>
                        <div className="assessment-session-card-score">
                          {projectCardDetails.numberOfSubmissions}

                        </div>

                      </div>



                    </div>
                    <div className='d-flex m-3' style={{ textAlign: "center", alignContent: "center", alignItems: "center" }}>
                      <div className='title-md'>Duration</div>
                      <div className='title-sm mx-3' style={{ marginBottom: "9px" }}>{projectCardDetails.duration}</div>

                    </div>
                    <Tooltip
                      id="contentname"
                      place='right'
                      style={{ fontFamily: "Inter" }}
                    />
                    {/*  <div className=' col-md-3'>
                 
                <div className="assessment-session-card">
                  <div className="assessment-session-card-title">
                  Duration 
                 
                  </div>
                  <div className="assessment-session-card-score">
                  
                  {duration}
                  </div>
                
            </div>
                                 
  


                </div>*/}
                  </div>


                  <div>
                    {
                      projectGroupDetails.length > 0 &&

                      <div className="row align-items-center" >
                        <div className="col-md-5 ">
                          <label className="label form-label">Select Group</label>
                          <div className="input-wrapper">
                            <div className="input-field" style={{ minWidth: "200px" }}>
                              <select
                                name="cars"
                                id="cars"
                                className="form-control form-control-sm"
                                value={groupSid}
                                onChange={(e) => {
                                  if (e.target.value !== 'Select') {
                                    setGroupSid(e.target.value);
                                    groupWiseDetails(e.target.value);
                                  }

                                }}
                              >
                                <option value="Select">Select</option>
                                {/* <option value='Select'selected>---Select Group---</option> */}
                                {/* <option value="Select">{showDefaultGroup}</option> */}
                                {/* <option value='---Select Group---'>---Select Group---</option> */}
                                {projectGroupDetails.map(function (key, index) {
                                  return <option value={key.value}>{key.label}</option>;
                                })}
                              </select>
                            </div>
                          </div>
                        </div>
                        {groupSid.length > 0 && groupSid !== 'Select' && (
                          <>
                            {groupScoring ?


                              <div className='row align-items-center mx-2 mb-2' style={{ border: "1px solid #EAECF0", padding: "0px 10px", borderRadius: "30px" }}>

                                <div className="col-md-6 " style={{ display: "contents" }}>
                                  <input
                                    type="radio"
                                    name="Option"
                                    value="GROUP"
                                    id="GROUP"
                                    defaultChecked
                                    onChange={(e) => setScoreType(e.target.value)}
                                  />
                                  <label className="form-check-label mx-3 mt-2 title-md">Group Scoring</label>
                                </div>


                              </div>
                              :
                              individualScoring ?

                                <div className="col-md-6 " style={{ display: "contents" }}>
                                  <input
                                    type="radio"
                                    name="Option"
                                    value="INDIVIDUAL"
                                    id="INDIVIDUAL"
                                    defaultChecked
                                    onChange={(e) => setScoreType(e.target.value)}
                                  />
                                  <label className="form-check-label mx-3 mt-2 title-md">Individual Scoring</label>
                                </div>
                                :
                                <>
                                  <div className='row align-items-center mx-2 mb-2' style={{ border: "1px solid #EAECF0", padding: "0px 10px", borderRadius: "30px" }}>

                                    <div className="col-md-6 " style={{ display: "contents" }}>
                                      <input
                                        type="radio"
                                        name="Option"
                                        value="GROUP"
                                        id="GROUP"
                                        defaultChecked
                                        onChange={(e) => setScoreType(e.target.value)}
                                      />
                                      <label className="form-check-label mx-3 mt-2 title-md">Group Scoring</label>
                                    </div>
                                    <div className="col-md-6 " style={{ display: "contents" }}>
                                      <input
                                        type="radio"
                                        name="Option"
                                        value="INDIVIDUAL"
                                        id="INDIVIDUAL"
                                        onChange={(e) => setScoreType(e.target.value)}
                                      />
                                      <label className="form-check-label mx-3 mt-2 title-md">Individual Scoring</label>
                                    </div>
                                  </div>

                                </>
                            }
                          </>
                        )}
                        {groupName.length > 0 && (
                          <>

                            {/* <label className="form-check-label mx-3 title-md">Group Name</label> */}
                            <div className=" title-sm ml-4 mb-3"> Group Name : <span className='title-sm'>{groupName}</span></div>
                          </>
                        )}
                      </div>



                    }
                    {
                      scoreType === 'GROUP' &&
                      <>
                        {groupScore !== null ?
                          groupScore.repoLink !== null &&
                          <div className="continue px-2">
                            <a href={groupScore.repoLink} target="_blank" rel="noopener noreferrer" className="title-sm" >Repo Link
                              <LinkIcon />

                            </a>
                          </div>
                          : ''
                        }
                        {
                          groupScore !== null &&
                            groupScore.scoredMarks === null && !individualScoring ?
                            <div className='row'>
                              <div className="col-md-6">
                                <label className="label form-label">Score</label>
                                {/* {showValidationMessage && <p style={{ color: 'red' }}>Only numbers are allowed.</p>} */}
                                <div className="input-wrapper">
                                  <div className="input-field">
                                    <input className="form-control form-control-sm" type="text"
                                      onChange={(e) => setGroupMarkScored(e.target.value)} />

                                  </div>
                                  {/* {score > totalscore && <div className='title-sm text-danger'>Score should not exceed {totalscore} </div>} */}
                                </div>

                              </div>

                              <div className="col-md-6">
                                <label className="label form-label">Total Score</label>
                                {/* {showValidationMessage && <p style={{ color: 'red' }}>Only numbers are allowed.</p>} */}
                                <div className="input-wrapper">
                                  <div className="input-field">
                                    <input className="form-control form-control-sm" type="text" value={projectCardDetails.totalMarks} disabled />
                                  </div>
                                </div>
                              </div>
                            </div>
                            :
                            groupScore !== null &&
                            groupScore.scoredMarks !== null &&
                            <div className='row'>
                              <div className="col-md-6">
                                <label className="label form-label">Score</label>
                                {/* {showValidationMessage && <p style={{ color: 'red' }}>Only numbers are allowed.</p>} */}
                                <div className="input-wrapper">
                                  <div className="input-field">
                                    <input className="form-control form-control-sm" type="text" value={groupScore.scoredMarks} disabled />

                                  </div>
                                  {/* {score > totalscore && <div className='title-sm text-danger'>Score should not exceed {totalscore} </div>} */}
                                </div>

                              </div>
                              <div className="col-md-6">
                                <label className="label form-label">Total Score</label>
                                {/* {showValidationMessage && <p style={{ color: 'red' }}>Only numbers are allowed.</p>} */}
                                <div className="input-wrapper">
                                  <div className="input-field">
                                    <input className="form-control form-control-sm" type="text" value={projectCardDetails.totalMarks} disabled />
                                  </div>
                                </div>
                              </div>
                            </div>

                        }
                        <hr />
                      </>


                    }

                    {
                      scoreType === 'INDIVIDUAL' &&
                      <div >

                        <DynamicTable {...{ configuration, sourceData: individualScore }} />
                      </div>

                    }
                  </div>

                  {groupScore !== null && groupScore.repoLink !== null && (user.role === ROLE.INSTRUCTOR) && scoreType === 'GROUP' &&
                    !groupScoring && (

                      <button className='btn btn-primary' style={{ width: '100%' }}
                        onClick={() => scoreSubmission()} disabled={groupMarkScored === 0}>
                        Score
                      </button>

                    )}
                  {groupScore !== null && groupScore.repoLink !== null && (user.role === ROLE.INSTRUCTOR) && scoreType === 'INDIVIDUAL' &&
                    !individualScoring && (

                      <button className='btn btn-primary' style={{ width: '100%' }}
                        onClick={() => scoreSubmission()} disabled={individualScoreList.length === 0}>
                        Score
                      </button>

                    )}
                  {
                    groupName.length > 0 && groupScore === null &&
                    <div >

                      <div className="title-md text-center">Submission Not Received Yet</div>
                    </div>

                  }
                </div>

              </>
              :
              (
                instructorEvaluationAll !== undefined &&
                <>
                  <div className='row'>
                    <div className='col-12 col-sm-6 col-md-3'>
                      <div className='card mb-3 pt-2 h-75' style={{ background: "#C8A3DF", borderRadius: "10px" }}>
                        <div className='card-text title-sm text-center'>Batch-Strength</div>
                        <div className='title-md text-center'>
                          {instructorEvaluationAll.batchStrength}
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-sm-6 col-md-3'>
                      <div className='card mb-3 pt-2 h-75' style={{ background: " #BFCBF7", borderRadius: "10px" }}>
                        <div className='card-text title-sm text-center'>Total Submissions</div>
                        <div className='title-md text-center'>
                          {instructorEvaluationAll.totalSubmissions}
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-sm-6 col-md-3'>
                      <div className='card mb-3 pt-2 h-75' style={{ background: "#D0EFFA", borderRadius: "10px" }}>
                        <div class="card-text title-sm text-center">Average Percentage</div>
                        <div className='title-md text-center'>
                          {`${instructorEvaluationAll.averagePercentage.toFixed(2)}%`}
                        </div>
                      </div>
                    </div>
                    <div className='col-12 col-sm-6 col-md-3'>
                      <div className='card mb-3 pt-2 h-75' data-tooltip-id="notestooltip" data-tooltip-content={`${instructorEvaluationAll.topperPercentage.toFixed(2)}%`}
                        style={{ background: "#C8A3DF", borderRadius: "10px" }}>
                        <div class="card-text title-sm text-center">Topper</div>
                        <div className='title-md text-center'>
                          <InfoIcon fontSize="small" /> {instructorEvaluationAll.topScorer}


                        </div>
                      </div>
                      <Tooltip
                        id="notestooltip"
                        data-tooltip-place="right"
                        variant="info"
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-sm-6'>
                      <div className='form-group mx-2'>
                        <label className='label form-label'>Select Learner</label>
                        <select
                          name="learner"
                          id="learner"
                          className="form-control form-control-sm"
                          onChange={(e) => {
                            learnerEvaluateLabDetails(e.target.value);
                          }}
                        >
                          <option hidden>Select Learner</option>
                          {Object.keys(instructorEvaluationAll.userMap).map(function (key, index) {
                            return <option value={key}>{instructorEvaluationAll.userMap[key]}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  {
                    learnerDetails !== null &&
                    <div className='row'>
                      <div className='col-12 col-sm-6 col-md-3'>
                        <div className='card mb-3 pt-2 border'>
                          <div class="card-text title-sm text-center">Passed</div>
                          <div className='title-md text-center'>
                            {learnerDetails.passed !== null ? learnerDetails.passed : 0}
                          </div>
                        </div>
                      </div>
                      <div className='col-12 col-sm-6 col-md-3'>
                        <div className='card mb-3 pt-2 border'>
                          <div class="card-text title-sm text-center">Failed</div>
                          <div className='title-md text-center'>
                            {learnerDetails.failed !== null ? learnerDetails.failed : 0}
                          </div>
                        </div>
                      </div>
                      <div className='col-12 col-sm-6 col-md-3'>
                        <div className='card mb-3 pt-2 border'>
                          <div class="card-text title-sm text-center">Percentage</div>
                          <div className='title-md text-center'>
                            {learnerDetails.percentage !== null ? `${learnerDetails.percentage.toFixed(2)}%` : `0%`}
                          </div>
                        </div>
                      </div>
                      <div className='col-12 col-sm-6 col-md-3'>
                        <div className='card mb-3 pt-2 border'>
                          <div class="card-text title-sm text-center">Show Output</div>
                          <div className='title-md text-center'>

                            {learnerDetails.fileName !== null && learnerDetails.fileUrl !== null ?
                              <a href={learnerDetails.fileUrl} target="_blank" rel="noopener noreferrer" className="title-sm" >
                                {/* {learnerDetails.fileName} */}
                                Output
                                <LinkIcon />
                              </a>
                              : "---"}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  {/* {selectedOption !== null && (
                    <div className='row'>
                      <div className='col-12 col-sm-6'>
                        <div className='mt-4'>
                          <button className='btn btn-primary' style={{ width: '100%' }} onClick={() => getUserSubmissions()}>
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  <Modal show={show} handleClose={() => setShow(false)}>
                    {selectedOption !== null && (
                      <Remarks
                        trainingSid={trainingSid}
                        labId={labId}
                        learnerSid={selectedOption.value}
                        assignmentLink={userSubmissions.link}
                        learner={userSubmissions.userName}
                        totalscore={Math.round(trainingObjective.labTotalScore)}
                      />
                    )}

                  </Modal>
                  <Modal show={showLearnerTable} handleClose={() => setShowLearnerTable(false)}  >
                    <AllLearnsScoreNRemarks />
                  </Modal> */}
                </>
              )
        }
      </div>

    </>

  )
}

export default TrainingObjective