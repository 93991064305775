import { useState, useEffect, useContext } from "react";
import DynamicTable from "../../../../Common/DynamicTable/DynamicTable";
import { Dropdown, Form } from 'react-bootstrap'
import { Formik } from 'formik';
import { ICN_EDIT, ICN_DELETE, ICN_TRASH } from "../../../../Common/Icon";
import { Button, Cancel } from "../../../../Common/Buttons/Buttons";
import { BsModal } from "../../../../Common/BsUtils";
import SearchBox from "../../../../Common/SearchBox/SearchBox";
import RestService from "../../../../../Services/api.service";
import AppContext from "../../../../../Store/AppContext";
import useToast from "../../../../../Store/ToastHook";
import AssessmentContext from "../../../../../Store/AssessmentContext";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


const QuestionModel = ({ show, setShow, sid, getParticipant, allQuestion, assesmentType, codingAssessmentSid }) => {
    const { assessmentVal, initialAssessment, setShowAddedCodingAssessmentQuestion } = useContext(AssessmentContext)
    const { user, spinner, ROLE } = useContext(AppContext)
    const [count, setCount] = useState(0)
    const Toast = useToast()
    const [participant, setParticipant] = useState([])
    const [selectedSid, setSelectedSid] = useState([])
    const [selectedCodingQuestions, setSelectedCodingQuestions] = useState([])
    const [selectedBeginnerQuestion, setSelectedBeginnerQuestion] = useState([])
    const [selectedIntermediateQuestion, setSelectedIntermediateQuestion] = useState([])
    const [selectedExpertQuestion, setSelectedExpertQuestion] = useState([])
    const [searchValue, setSearchValue] = useState([])
    const [question, setQuestion] = useState([])
    const [codingQuestionBeginner, setCodingQuestionBeginner] = useState([])
    const [codingQuestionIntermediate, setCodingQuestionIntermediate] = useState([])
    const [codingQuestionExpert, setCodingQuestionExpert] = useState([])

    const [alignment, setAlignment] = useState('BEGINNER');
    const handleChange = (event, newAlignment) => {
        setAlignment(newAlignment);
    };

    const [configuration, setConfiguration] = useState({
        columns: {
            "name": {
                "title": "Question",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
                render: (data) => <div className="elps " title={data.name}>{data.name}</div>
            },
            "questionType": {
                "title": "Type",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,

            },
            "technologyName": {
                "title": "Tag",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false
            },
            "difficulty": {
                "title": "Difficulty",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false
            },
        },
        headerTextColor: '#454E50', // user can change table header text color
        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configuration.sortBy = sortKey;
            Object.keys(configuration.columns).map(key => configuration.columns[key].sortDirection = (key === sortKey) ? !configuration.columns[key].sortDirection : false);
            configuration.sortDirection = configuration.columns[sortKey].sortDirection;
            setConfiguration({ ...configuration });
        },
        actions: [],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table sort-enabled", // table custom class
        showCheckbox: true,
        clearSelection: false
    });


    const [configurationBeginner, setConfigurationBeginner] = useState({
        columns: {
            "question": {
                "title": "Question",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
                render: (data) => <div className="elps " title={data.question}>{data.question}</div>
            },
            "difficulty": {
                "title": "Difficulty",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,

            },
            "marks": {
                "title": "Marks",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
                render: (data) =>
                    <>
                        {data.marks === null ? "---" : data.marks}
                    </>
            }
        },
        headerTextColor: '#454E50', // user can change table header text color
        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configurationBeginner.sortBy = sortKey;
            Object.keys(configurationBeginner.columns).map(key => configurationBeginner.columns[key].sortDirection = (key === sortKey) ? !configurationBeginner.columns[key].sortDirection : false);
            configurationBeginner.sortDirection = configurationBeginner.columns[sortKey].sortDirection;
            setConfigurationBeginner({ ...configurationBeginner });
        },
        actions: [],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table sort-enabled", // table custom class
        showCheckbox: true,
        clearSelection: false
    });

    const [configurationIntermediate, setConfigurationIntermediate] = useState({
        columns: {
            "question": {
                "title": "Question",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
                render: (data) => <div className="elps " title={data.question}>{data.question}</div>
            },
            "difficulty": {
                "title": "Difficulty",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,

            },
            "marks": {
                "title": "Marks",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
                render: (data) =>
                    <>
                        {data.marks === null ? "---" : data.marks}
                    </>
            }
        },
        headerTextColor: '#454E50', // user can change table header text color
        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configurationIntermediate.sortBy = sortKey;
            Object.keys(configurationIntermediate.columns).map(key => configurationIntermediate.columns[key].sortDirection = (key === sortKey) ? !configurationIntermediate.columns[key].sortDirection : false);
            configurationIntermediate.sortDirection = configurationIntermediate.columns[sortKey].sortDirection;
            setConfigurationIntermediate({ ...configurationIntermediate });
        },
        actions: [],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table sort-enabled", // table custom class
        showCheckbox: true,
        clearSelection: false
    });

    const [configurationExpert, setConfigurationExpert] = useState({
        columns: {
            "question": {
                "title": "Question",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
                render: (data) => <div className="elps " title={data.question}>{data.question}</div>
            },
            "difficulty": {
                "title": "Difficulty",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,

            },
            "marks": {
                "title": "Marks",
                "sortDirection": null,
                "sortEnabled": true,
                isSearchEnabled: false,
                render: (data) =>
                    <>
                        {data.marks === null ? "---" : data.marks}
                    </>
            }
        },
        headerTextColor: '#454E50', // user can change table header text color
        sortBy: null,  // by default sort table by name key
        sortDirection: false, // sort direction by default true
        updateSortBy: (sortKey) => {
            configurationExpert.sortBy = sortKey;
            Object.keys(configurationExpert.columns).map(key => configurationExpert.columns[key].sortDirection = (key === sortKey) ? !configurationExpert.columns[key].sortDirection : false);
            configurationExpert.sortDirection = configurationExpert.columns[sortKey].sortDirection;
            setConfigurationExpert({ ...configurationExpert });
        },
        actions: [],
        actionCustomClass: "no-chev esc-btn-dropdown", // user can pass their own custom className name to add/remove some css style on action button
        actionVariant: "", // user can pass action button variant like primary, dark, light,
        actionAlignment: true, // user can pass alignment property of dropdown menu by default it is alignLeft 
        // call this callback function onSearch method in input field on onChange handler eg: <input type="text" onChange={(e) => onSearch(e.target.value)}/>
        // this search is working for search enable fields(column) eg. isSearchEnabled: true, in tale column configuration
        searchQuery: "",
        tableCustomClass: "ng-table sort-enabled", // table custom class
        showCheckbox: true,
        clearSelection: false
    });
    // get All topic
    const getAllQuestion = async () => {
        spinner.show("Loading... wait");
        try {
            let { data } = await RestService.getNotAssociateQuestion(initialAssessment.sid)
            setQuestion(data);
            setSearchValue(data)
            spinner.hide();
        } catch (err) {
            spinner.hide();
            console.error("error occur on getAllTopic()", err)
        }
    }


    // get user count
    const associateQuestion = async () => {
        try {
            let payload;
            // let payload = {
            //     "questionSidList": selectedSid,
            //     "assessmentSid": assessmentVal.sid,
            //     "codingQuestionIdList": selectedSid
            // }
            if (selectedSid.length > 0 && selectedCodingQuestions.length > 0) {
                payload = {
                    "questionSidList": selectedSid,
                    "assessmentSid": assessmentVal.sid,
                    "codingQuestionIdList": selectedCodingQuestions
                }
            }
            else if (selectedSid.length > 0) {
                payload = {
                    "questionSidList": selectedSid,
                    "assessmentSid": assessmentVal.sid
                }
            }
            else if (selectedCodingQuestions.length > 0) {
                payload = {
                    "assessmentSid": assessmentVal.sid,
                    "codingQuestionIdList": selectedCodingQuestions
                }
            }
            RestService.associateQuestion(sid, payload).then(
                response => {
                    Toast.success({ message: "Question added successfully" })
                    allQuestion()
                    setShow(false)
                },
                err => {
                    spinner.hide();
                    setShow(false)
                }
            ).finally(() => {
                spinner.hide();
                setShow(false)
            });
        } catch (err) {
            setShow(false)
            console.error("error occur on associateQuestion()", err)
        }
    }

    //add coding question to assessment 
    const addQuestionToCodingAssessment = async () => {
        try {

            let codingIdArray = [...selectedBeginnerQuestion, ...selectedIntermediateQuestion, ...selectedExpertQuestion];
            let payload = {
                "codingAssessmentSid": codingAssessmentSid.codingAssessmentSid,
                "codingQuestionIds": codingIdArray
            }

            RestService.addQuestionToCodingAssessment(payload).then(
                response => {
                    Toast.success({ message: "Question added successfully" })
                    setShowAddedCodingAssessmentQuestion(response.data.questionDetails)
                    setShow(false);
                },
                err => {
                    spinner.hide();
                    setShow(false)
                }
            ).finally(() => {
                spinner.hide();
                setShow(false)
            });
        } catch (err) {
            setShow(false)
            console.error("error occur on addQuestionToCodingAssessment()", err)
        }
    }

    // get All question Beginner
    const filterCodingQuestionsBeginner = async () => {
        const difficulty = "BEGINNER";
        spinner.show("Loading... wait");
        try {
            let { data } = await RestService.filterCodingQuestions(difficulty)
            setCodingQuestionBeginner(data);
            spinner.hide();
        } catch (err) {
            spinner.hide();
            console.error("error occur on filterCodingQuestions()", err)
        }
    }

    // get All question Intermediate
    const filterCodingQuestionsIntermediate = async () => {
        const difficulty = "INTERMEDIATE";
        spinner.show("Loading... wait");
        try {
            let { data } = await RestService.filterCodingQuestions(difficulty)
            setCodingQuestionIntermediate(data);
            spinner.hide();
        } catch (err) {
            spinner.hide();
            console.error("error occur on filterCodingQuestions()", err)
        }
    }

    // get All question Expert
    const filterCodingQuestionsExpert = async () => {
        const difficulty = "EXPERT";
        spinner.show("Loading... wait");
        try {
            let { data } = await RestService.filterCodingQuestions(difficulty)
            setCodingQuestionExpert(data);
            spinner.hide();
        } catch (err) {
            spinner.hide();
            console.error("error occur on filterCodingQuestions()", err)
        }
    }

    const onSearch = (e) => {
        try {
            setSearchValue(question.filter(res => res.name.toUpperCase().indexOf(e.toUpperCase()) > -1))
        } catch (err) {
            console.error("error occur on onSearch()", err)
        }
    }

    useEffect(() => {
        if (assesmentType === "CODING") {
            filterCodingQuestionsBeginner();
            filterCodingQuestionsIntermediate();
            filterCodingQuestionsExpert();
        }
        else {
            getAllQuestion();
        }

    }, []);

    return (<>
        <BsModal {...{
            show,
            setShow,
            headerTitle: "Upload Question",
            headerAction: <SearchBox {...{ onChange: (e) => onSearch(e), onEnter: () => { }, clearField: () => { } }} />,
            size: "xl"
        }}>
            <div className="partiContainer">
                {
                    assesmentType === "CODING" ?
                        <>
                            <ToggleButtonGroup
                                color="primary"
                                value={alignment}
                                exclusive
                                onChange={handleChange}
                                aria-label="Platform"
                            >
                                <ToggleButton value="BEGINNER">Beginner</ToggleButton>
                                <ToggleButton value="INTERMEDIATE">Intermediate</ToggleButton>
                                <ToggleButton value="EXPERT">Expert</ToggleButton>

                            </ToggleButtonGroup>

                            {
                                alignment === "BEGINNER" ?
                                    <DynamicTable {...{
                                        configuration: configurationBeginner, sourceData: codingQuestionBeginner, onSelected: (e) => {

                                            let coding = e.map(function (val) {
                                                return val.codingQuestionId;
                                            })

                                            setSelectedBeginnerQuestion(coding)
                                        }
                                    }} />
                                    :
                                    alignment === "INTERMEDIATE" ?
                                        <DynamicTable {...{
                                            configuration: configurationIntermediate, sourceData: codingQuestionIntermediate, onSelected: (e) => {
                                                let coding = e.map(function (val) {
                                                    return val.codingQuestionId;
                                                })
                                                setSelectedIntermediateQuestion(coding);

                                            }
                                        }} />
                                        :
                                        <DynamicTable {...{
                                            configuration: configurationExpert, sourceData: codingQuestionExpert, onSelected: (e) => {

                                                let coding = e.map(function (val) {
                                                    return val.codingQuestionId;
                                                })
                                                setSelectedExpertQuestion(coding);
                                            }
                                        }} />
                            }

                        </>

                        :

                        <DynamicTable {...{
                            configuration, sourceData: searchValue, onSelected: (e) => {
                                // e.map(r=> r.sid !== null ? setSelectedSid(datas=>(
                                //     ...datas, r.sid)) :
                                let mcq = e.filter(function (sid) {
                                    return sid.sid !== null
                                }).map(function (val) {
                                    return val.sid;
                                })
                                let coding = e.filter(function (coding) {
                                    return coding.codingQuestionId !== null
                                }).map(function (val) {
                                    return val.codingQuestionId;
                                })
                                setSelectedCodingQuestions(coding)
                                setSelectedSid(mcq);
                            }
                        }} />
                }
            </div>
            <div className="jce mt-2">
                <Cancel className="mx-2" onClick={() => { setShow(false) }}>Cancel</Cancel>
                {
                    assesmentType === "CODING" ?
                        <Button className="mx-2" onClick={() => { setShow(true); addQuestionToCodingAssessment(); }}>
                            Add Question({selectedBeginnerQuestion.length + selectedIntermediateQuestion.length + selectedExpertQuestion.length})
                        </Button>
                        :
                        <Button className="mx-2" onClick={() => { setShow(true); associateQuestion() }}>Add Question({selectedSid.length + selectedCodingQuestions.length})</Button>
                }
            </div>
        </BsModal>
    </>)

}
export default QuestionModel