import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ToastMessage = ({ type, message, time = 3000 }) => {
  const showToast = () => {
    let formattedMessage;

    if (message.includes('\n')) {
      const messageLines = message.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
      formattedMessage = <div>{messageLines}</div>;
    } else {
      formattedMessage = message;
    }
    switch (type) {
      case 'success':
        toast.success(formattedMessage, {
          position: "top-center",
          autoClose: time,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        break;
      case 'error':
        toast.error(formattedMessage, {
          position: "top-center",
          autoClose: time,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        break;
      case 'warning':
        toast.warn(formattedMessage, {
          position: "top-center",
          autoClose: time,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        break;
      default:
        break;
    }
  };

  // Call the showToast function


  return (
    <>
      {/* Do not forget to call the ToastContainer outside of the function */}
      {/* <ToastContainer /> */}
      {showToast()}
    </>
  );
};

export default ToastMessage;
