import { useContext, useEffect } from "react";
import Login from "./Components/Screens/Auth/Login";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Assets/css/main.css'
import Dashboard from "./Components/Layout/Dashboard/Dashboard";
import { Router } from "./Components/Common/Router";
import ClassLab from "./Components/Screens/ClassLab/ClassLab";
import Spinner from "./Components/Common/Spinner/Spinner";
import AppContext from "./Store/AppContext";
import LandingHome from "./Components/LandingPage/Home/LandingHome";
import ResetPwd from "./Components/Screens/Auth/ResetPwd";
import Assessment from "./Components/Screens/Assessment/Assessment";
import { TrainingProvider } from "./Store/TrainingContext";
import VsCode from "./Components/Screens/VsCode/VsCode";
import MeetingClose from "./Components/Zoom/MeetingClose";
import { AssessmentProvider } from "./Store/AssessmentContext";
import PrivateRoute from "./Components/Common/PrivateRoute/PrivateRoute";
import { Redirect } from '@reach/router';
import Labs from "./Components/Screens/Training/Labs/Labs";
import IdealLogout from "./Components/Common/IdealLogout/IdealLogout";
// import { MsalProvider, AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import CodingAssessment from "./Components/Screens/Training/Labs/CodingAssessment";
import DisableBeforeUnloadWarning from './Components/Screens/Auth/DisableBeforeUnloadWarning';
import ForgotPassword from "./Components/Screens/Auth/ForgotPassword";
import ResetLink from "./Components/Screens/Auth/ResetLink";

function App() {
   const { setUserValue, spinner } = useContext(AppContext);
   const user = JSON.parse(localStorage.getItem('user'));

   return (<>
      <Spinner value={spinner} />
      <DisableBeforeUnloadWarning />
      {/* <MsalProvider instance={instance}> */}
      <AssessmentProvider>
         <TrainingProvider>
            <Router>
               <Assessment path="/assessment/:assessmentSid/:companySid/:virtualAccountSid" />
               <ResetPwd path="/reset/:token" />
               <ResetLink path="/resetlink" />
               <ForgotPassword path="/forgot-password/:uuid/:timestamp" />
               {

                  localStorage.getItem('REACTAPP.TOKEN') && user.role === "SUPER_USER" ? <Redirect from="/" to="/superuser" noThrow /> :
                     localStorage.getItem('REACTAPP.TOKEN') && user.role === "SUPERVISOR" ? <Redirect from="/" to="/dashboard" noThrow /> :
                        localStorage.getItem('REACTAPP.TOKEN') && user.role === "CONSUMER_SUPERVISOR" ? <Redirect from="/" to="/dashboard" noThrow /> :
                           localStorage.getItem('REACTAPP.TOKEN') && user.role === "INSTRUCTOR" ? <Redirect from="/" to="/instdashboard" noThrow /> :
                              localStorage.getItem('REACTAPP.TOKEN') && (user.role === "LEARNER" || user.role === "USER") ? <Redirect from="/" to="/home" noThrow /> :
                                 localStorage.getItem('REACTAPP.TOKEN') && user.role === "TECHNICAL_SUPPORT" ? <Redirect from="/" to="/techsupport" noThrow /> :
                                    localStorage.getItem('REACTAPP.TOKEN') && user.role === "OPERATION_SUPPORT" ? <Redirect from="/" to="/techsupport" noThrow /> :
                                       <Login path="/" />

               }

               <PrivateRoute component={MeetingClose} path="zoomclose" />
               {/* <PrivateRoute component={ResetPwd} path="/reset/:token" /> */}
               {/* <PrivateRoute component={Assessment} path="/assessment/:assessmentSid/:companySid/:virtualAccountSid" /> */}
               <PrivateRoute component={Dashboard} path="/*" />
               <PrivateRoute component={ClassLab} path="class/*" />
               <PrivateRoute component={VsCode} path="vscode" />
               <PrivateRoute component={Labs} path="labs" />
               <PrivateRoute component={CodingAssessment} path="coding" />
               {/* <PrivateRoute component={DisplayNotes} path="/notes/mynotes" /> */}

            </Router>
         </TrainingProvider>
      </AssessmentProvider >
      {/* </MsalProvider> */}
      {
         localStorage.getItem('REACTAPP.TOKEN') ?
            <IdealLogout /> : ''
      }
   </>);
}

export default App;
