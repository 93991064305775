import React, { useContext, useEffect, useState } from 'react'
import Charts from '../../Charts/Charts'
import Table from 'react-bootstrap/Table'
import { ICN_COPY, ICN_COMING_BATCHES } from '../../Common/Icon';
import { Progress, Card } from '../../Common/BsUtils';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import CalenderGraph from '../../Common/CalenderGraph/CalenderGraph';
import AppContext from '../../../Store/AppContext';
import useFetch from "../../../Store/useFetch";
import GLOBELCONSTANT from "../../../Constant/GlobleConstant";
import { Router } from '../../Common/Router';
import "react-circular-progressbar/dist/styles.css";
// import './home.css'
import RestService from '../../../Services/api.service';
import AssessmentContext from '../../../Store/AssessmentContext';
import WeeklyLogin from '../../Common/Graph/LineGraph/WeeklyLogin';
import AverageTrainerFeedback from '../../Common/Graph/BarGraph/AverageTrainerFeedback';
import AverageTrainingFeedback from '../../Common/Graph/BarGraph/AverageTrainingFeedback';
import AverageAssesmentscore from '../../Common/Graph/LineGraph/AverageAssesmentscore';
import Trainingpichart from '../../Common/Graph/PiChart/Trainingpichart';
import CircularProgress from '../../Layout/NewDashboardLearner/CircularProgress/CircularProgress';
import InstructorLeaderboard from '../../Layout/NewDashboardLearner/Leaderboard/InstructorLeaderboard';
// import DonutChart from '../Report/ReportDonutChar';
import ScienceIcon from '@mui/icons-material/Science';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SkeletonLoader from '../../Common/SkeletonLoader/SkeletonLoader';

const AdminSuperUser = () => {

    const { user, batches, course, ROLE, spinner, setCategory } = useContext(AppContext);
    const [batchCount, setBatchCount] = useState(0);
    const [ongoingTrainingCount, setongoingTrainingCount] = useState(0);
    const [showTraining, setShowTraining] = useState('');
    const [cardDetails, setCardDetails] = useState({});
    const [trainingList, setTrainingList] = useState({});
    const [trainingprogress, setTrainingprogress] = useState(null);
    const [cardLoader, setCardLoader] = useState(true);
    const [trainingListLoader, setTrainingListLoader] = useState(true);
    // const trainingList = [
    //     "Kubernetes Track",
    //     "Xampp Server",
    //     "ML and Data Science",
    //     "Kubernetes for Cloud Native",
    //     "Cloud Computing, Docker And Kubernetes Journey",
    //     "Windows Server",
    //     "Replicated Data Queue Manager and File Transfer",
    //     "GraphQL Training",
    //     "Hadoop and BigData Analytics",
    //     "Cucumber Testing Method",
    //     "Microservices with Spring and Spring Cloud"
    // ]

    // const getTrainingprogress = async () => {
    //     try {
    //         spinner.show();
    //         RestService.getOngoingTrainingProgress().then(
    //             response => {
    //                 setTrainingprogress(response.data.trainingDetails);
    //                 setongoingTrainingCount(response.data.ongoingTrainingCount);
    //             },
    //             err => {
    //                 spinner.hide();
    //             }
    //         ).finally(() => {
    //             spinner.hide();
    //         });
    //     } catch (err) {
    //         console.error("error occur on getTrainings()", err)
    //     }
    // }

    // get batches by sid
    // const getBatchCount = async () => {
    //     try {
    //         RestService.getCount("vw_batch").then(
    //             response => {
    //                 setBatchCount(response.data);
    //             },
    //             err => {
    //                 spinner.hide();
    //             }
    //         ).finally(() => {
    //             spinner.hide();
    //         });
    //     } catch (err) {
    //         console.error("error occur on getAllBatch()", err)
    //     }
    // }

    // // get All topic
    // const getAllCategory = async () => {
    //     spinner.show("Loading... wait");
    //     try {
    //         let { data } = await RestService.getAllCategory()
    //         setCategory(data)
    //         spinner.hide();
    //     } catch (err) {
    //         spinner.hide();
    //         console.error("error occur on getAllTopic()", err)
    //     }
    // }

    //get supervisor card details
    const getSupervisorCardTrainingDetails = async () => {
        try {
            // spinner.show();
            RestService.getSupervisorCardTrainingDetails().then(
                response => {
                    if (response.status === 200) {
                        setCardDetails(response.data);
                        setCardLoader(false);
                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getSupervisorCardTrainingDetails()", err)
        }
    }

    // get all training
    const getSupervisorTrainings = () => {
        try {
            // spinner.show();
            RestService.getSupervisorTrainings().then(
                response => {
                    if (response.status === 200) {
                        setTrainingList(response.data);
                        setTrainingListLoader(false);
                    }
                    else {
                        setTrainingList({});
                    }

                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getSupervisorTrainings()", err)
        }
    }

    //supervisor training average
    const getSupervisorTrainingAverageProgress = (trainingSid) => {
        try {
            spinner.show();
            RestService.getSupervisorTrainingAverageProgress(trainingSid).then(
                response => {
                    if (response.status === 200) {
                        setTrainingprogress(response.data);
                    }
                },
                err => {
                    spinner.hide();
                }
            ).finally(() => {
                spinner.hide();
            });
        } catch (err) {
            console.error("error occur on getSupervisorTrainingAverageProgress()", err)
        }
    }

    useEffect(() => {
        // getBatchCount();
        // getAllCategory();
        // getTrainingprogress();
        getSupervisorCardTrainingDetails();
        getSupervisorTrainings();

    }, [])

    return (<div>

        <Card title="Filter Training based on date">
            <div className='row'>
                <div className='col-sm-6 col-md-6 pb-3'>
                    {/* <SkillsLevelGraph/> */}
                    {/* <InstructorFeedback /> */}
                    {/* <NewInstructorfeedback/> */}
                    <Trainingpichart />
                </div>
                <div className='col-sm-6 col-md-6'>
                    <div className='row'>
                        <div className="col-sm-6 ">

                            <div className="grid-batch1 ">
                                <div className="mb10">{ICN_COMING_BATCHES}</div>
                                <div>
                                    {
                                        cardLoader ?
                                            <SkeletonLoader skeletonCount={1} />
                                            :
                                            <div className="batch-title title-md text-center">{cardDetails.supervisorOngoingTrainings}</div>
                                    }
                                    <div className="batch-label title-sm text-center">Ongoing Trainings</div>
                                </div>
                                <div className="jce">
                                    <div className="grid-batch-icon">
                                        <i className="bi bi-arrows-angle-expand"></i>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-sm-6 ">

                            <div className="grid-batch2">
                                <div className="mb10">{ICN_COPY}</div>
                                <div>
                                    {
                                        cardLoader ?
                                            <SkeletonLoader skeletonCount={1} />
                                            :
                                            <div className="batch-title title-md text-center">{cardDetails.supervisorCompletedTrainings}</div>
                                    }
                                    <div className="batch-label title-sm text-center">Completed Trainings</div>
                                </div>
                                <div className="jce">
                                    <div className="grid-batch-icon">
                                        <i className="bi bi-arrows-angle-expand"></i>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className="col-sm-6 ">

                            <div className="grid-batch3">
                                <div className="mb10">{ICN_COPY}</div>
                                <div>

                                    {
                                        cardLoader ?
                                            <SkeletonLoader skeletonCount={1} />
                                            :
                                            <div className="batch-title title-md text-center">{cardDetails.batchCount}</div>
                                    }
                                    <div className="batch-label title-sm text-center">Total batches</div>
                                </div>
                                <div className="jce">
                                    <div className="grid-batch-icon">
                                        <i className="bi bi-arrows-angle-expand"></i>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <div className="col-sm-6 ">

                            <div className="grid-batch1">
                                <div className="mb10">{ICN_COPY}</div>
                                <div>
                                 
                                    <div className="batch-title">{cardDetails.capstoneLabsCount} / {cardDetails.practiceLabsCount}</div>
                                    <div className="batch-label">Capstone Labs / Practice Labs</div>
                                </div>
                                <div className="jce">
                                    <div className="grid-batch-icon">
                                        <i className="bi bi-arrows-angle-expand"></i>
                                    </div>
                                </div>
                            </div>
                        </div> */}



                        <div className="col-sm-6 ">

                            <div className="grid-batch1 " style={{ padding: "10px 5px" }}>
                                {/* <div className="mb10">{ICN_COPY}</div> */}

                                <div >
                                    <div className='col pt-2' >
                                        {
                                            cardLoader ?
                                                <SkeletonLoader skeletonCount={1} />
                                                :
                                                <div className='title-md text-center'><AssessmentIcon fontSize='small' />{cardDetails.capstoneLabsCount}</div>
                                        }

                                        <div className='title-sm text-center'>Capstone Labs</div>
                                    </div>

                                </div>

                                <div style={{ borderLeft: "2px solid #BFCBF7" }}>
                                    <div className='col pt-2'>
                                        {
                                            cardLoader ?
                                                <SkeletonLoader skeletonCount={1} />
                                                :
                                                <div className='title-md text-center '><ScienceIcon fontSize='small' />{cardDetails.practiceLabsCount}</div>
                                        }

                                        <div className='title-sm text-center'>Practice Labs</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
        <Card title="" className='mt-2'>
            <label className="m-3 label form-label title-md ">Select Training</label>
            {
                trainingListLoader ?
                    <SkeletonLoader skeletonCount={1} />
                    :

                    <select className="form-control mb-3 mx-2" style={{ borderRadius: "30px", backgroundColor: "rgb(248, 250, 251)" }} onChange={(e) => {
                        getSupervisorTrainingAverageProgress(e.target.value.split(',')[0]);
                    }}>
                        <option hidden>Select Training</option>
                        {
                            Object.keys(trainingList).map(function (key, index) {
                                return (
                                    <option value={`${key},${trainingList[key]}`}>{trainingList[key]}</option>
                                )
                            })
                            // trainingList.map((item) => {
                            //     return (
                            //         <>
                            //             <option value={item.sid}>

                            //                 {item.name}

                            //             </option>
                            //         </>
                            //     )
                            // })
                        }
                    </select>
            }
            {
                trainingprogress !== null ?

                    <div className='row '>
                        <div className='col-sm-3 col-md-3'>
                            <div className='title-md'>
                                Current Lab Cost
                            </div>
                            {/* <InstructorLeaderboard /> */}
                            <div className="grid-batch2" style={{ marginTop: "35%" }}>
                                {/* <div className="mb10">{ICN_COPY}</div> */}
                                <div>
                                    <div className="batch-title text-center">{`$${trainingprogress.trainingLabCost.toFixed(2)}`}</div>
                                    <div className='title-lg pl-4'>Current Lab Cost</div>
                                </div>
                                <div className="jce">
                                    <div className="grid-batch-icon">
                                        <i className="bi bi-arrows-angle-expand"></i>
                                    </div>
                                </div>
                            </div>
                            {/* <DonutChart data={trainingprogress.trainingLabCost.toFixed(2)} /> */}

                        </div>
                        <div className='col-sm-3 col-md-3'>
                            <div className='title-md text-center'>
                                Progress
                            </div>
                            <div className='my-2 d-flex justify-content-between p-2 border ' style={{ width: "100%", borderRadius: "20px", background: "linear-gradient(180deg, #5CC9EE 0%, rgba(92, 201, 238, 0) 100%)" }}>
                                <div className='title-sm'>Lab</div>
                                <div >{trainingprogress.progressDetails.LAB !== null ? trainingprogress.progressDetails.LAB.toFixed(2) : 30}%</div>
                            </div>
                            <div className='my-2 d-flex justify-content-between p-2 border ' style={{ width: "100%", borderRadius: "20px", background: "linear-gradient(180deg, #7214AE 0%, rgba(114, 20, 174, 0) 100%)" }}>
                                <div className='title-sm'>Assesment</div>
                                <div >{trainingprogress.progressDetails.ASSESSMENT !== null ? trainingprogress.progressDetails.ASSESSMENT.toFixed(2) : 30}%</div>
                            </div>
                            <div className='my-2 d-flex justify-content-between p-2 border ' style={{ width: "100%", borderRadius: "20px", background: "linear-gradient(180deg, #5CC9EE 0%, rgba(92, 201, 238, 0) 100%)" }}>
                                <div className='title-sm'>Capstone</div>
                                <div >{trainingprogress.progressDetails.CAPSTONE !== null ? trainingprogress.progressDetails.CAPSTONE.toFixed(2) : 40}%</div>
                            </div>
                            <div className='my-2 d-flex justify-content-between p-2 border ' style={{ width: "100%", borderRadius: "20px", background: "linear-gradient(180deg, #7214AE 0%, rgba(114, 20, 174, 0) 100%)" }}>
                                <div className='title-sm'>Documents</div>
                                <div >{trainingprogress.progressDetails.DOCUMENTS !== null ? trainingprogress.progressDetails.DOCUMENTS.toFixed(2) : 35}%</div>
                            </div>
                            <div className='my-2 d-flex justify-content-between p-2 border ' style={{ width: "100%", borderRadius: "20px", background: "linear-gradient(180deg, #5CC9EE 0%, rgba(92, 201, 238, 0) 100%)" }}>
                                <div className='title-sm'>Video</div>
                                <div >{trainingprogress.progressDetails.VIDEO !== null ? trainingprogress.progressDetails.VIDEO.toFixed(2) : 40}%</div>
                            </div>
                            <div className='my-2 d-flex justify-content-between p-2 border ' style={{ width: "100%", borderRadius: "20px", background: "linear-gradient(180deg, #7214AE 0%, rgba(114, 20, 174, 0) 100%)" }}>
                                <div className='title-sm'>Coding</div>
                                <div >{trainingprogress.progressDetails.CODING !== null ? trainingprogress.progressDetails.CODING.toFixed(2) : 10}%</div>
                            </div>

                            {/* <div className='d-flex justify-content-between p-2 border ' style={{ width: "100%", borderRadius: "20px", background: "linear-gradient(180deg, #7214AE 0%, rgba(114, 20, 174, 0) 100%)" }}>
                                <div className='title-sm'>ILT</div>
                                <div >70%</div>
                            </div> */}
                        </div>
                        <div className='col-sm-3 col-md-3'>
                            <div className='title-md text-center'>
                                Average Assessments Score
                            </div>
                            <div className=" py-5 ml-5">
                                <div className="flx pb-3" style={{ marginTop: "-40px" }} >
                                    <div className="text-center " style={{ width: 100, height: 60 }}>
                                        <CircularProgressbar

                                            // maxValue="1000"
                                            // minValue="1" value="580"
                                            value={trainingprogress.progressDetails.CAPSTONE !== null ? trainingprogress.progressDetails.CAPSTONE.toFixed(2) : 40}
                                            text={trainingprogress.progressDetails.CAPSTONE !== null ? `${trainingprogress.progressDetails.CAPSTONE.toFixed(2)}%` : '40%'}
                                            styles={buildStyles({
                                                trailColor: "#F5FBFF",
                                                pathColor: "#5CC9EE",

                                            })} />
                                        <div className="mt-2">Capstone Project</div>
                                    </div>
                                    <div className="text-center assementdashboard" style={{ width: 100, height: 60 }}>
                                        <CircularProgressbar
                                            value={trainingprogress.progressDetails.ASSESSMENT !== null ? trainingprogress.progressDetails.ASSESSMENT.toFixed(2) : 30}
                                            text={trainingprogress.progressDetails.ASSESSMENT !== null ? `${trainingprogress.progressDetails.ASSESSMENT.toFixed(2)}%` : '30%'}
                                            styles={buildStyles({
                                                trailColor: "#F5FBFF",
                                                pathColor: "#5CC9EE",
                                            })} />
                                        <div className="mt-2">MCQs</div>
                                    </div>

                                    <div className="text-center" style={{ width: 100, height: 60, marginTop: "80px", marginLeft: "40px" }}>
                                        <CircularProgressbar
                                            // maxValue="1000"
                                            // minValue="1" value="789"
                                            value={trainingprogress.progressDetails.LAB !== null ? trainingprogress.progressDetails.LAB.toFixed(2) : 30}
                                            text={trainingprogress.progressDetails.LAB !== null ? `${trainingprogress.progressDetails.LAB.toFixed(2)}%` : '30%'}
                                            styles={buildStyles({
                                                trailColor: "#F5FBFF",
                                                pathColor: "#7D00B5",
                                            })} />
                                        <div className="mt-2">Practice Labs</div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        <div className='col-sm-3 col-md-3'>
                            <div className='title-md text-center'>
                                Overall Score/ Progress
                            </div>
                            <div >
                                <CircularProgress progress={trainingprogress.overallTrainingProgress.toFixed(2)} style={{ margintop: "60px" }} />
                            </div>
                        </div>
                    </div>
                    : ''
            }
        </Card>

    </div>)
}

const SuperUserDashboard = () => {
    const { setCourse, setBatches, setDepartment, spinner } = useContext(AppContext)
    const { setCategory } = useContext(AssessmentContext)

    // get All topic
    const getAllCategory = async () => {
        // spinner.show("Loading... wait");
        try {
            let { data } = await RestService.getAllCategory()
            setCategory(data)
            spinner.hide();
        } catch (err) {
            spinner.hide();
            console.error("error occur on getAllTopic()", err)
        }
    }
    // get all courses
    const allCourse = useFetch({
        method: "get",
        url: GLOBELCONSTANT.COURSE.GET_COURSE,
        errorMsg: 'error occur on get course'
    });
    // get all batches
    const allBatches = useFetch({
        method: "get",
        url: GLOBELCONSTANT.BATCHES.GET_BATCH_LIST,
        errorMsg: 'error occur on get Batches'
    });

    // get all batches
    const allDepartment = useFetch({
        method: "get",
        url: GLOBELCONSTANT.INSTRUCTOR.GET_INSTRUCTOR,
        errorMsg: 'error occur on get Batches'
    });




    useEffect(() => {
        getAllCategory()
        allCourse.response && setCourse(allCourse.response)
        allBatches.response && setBatches(allBatches.response)
        allDepartment.response && setDepartment(allDepartment.response)
    }, [allCourse.response, allBatches.response, allDepartment.response])

    return (<>
        <Router>
            <AdminSuperUser path="/" />
        </Router>
    </>)

}
export default SuperUserDashboard